<h2 mat-dialog-title class="!p-4">Delete Owner</h2>
<mat-dialog-content>
	<div class="flex">
		<div class="flex-1 !p-2">
			<p>	Are you sure you want to delete this owner: <span class="font-bold">{{ data.contact.first }} {{ data.contact.last }}</span>?</p>
		</div>
	</div>
	<div class="flex">
		<div class="flex-1 text-right">
			<button mat-button (click)="close()">No</button>
			<button mat-flat-button color="primary" class="!ml-2" (click)="deleteOwner()">Yes</button>
		</div>
	</div>
</mat-dialog-content>
