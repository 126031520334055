import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { FormOptionsService } from '@app/app/services/form-options.service';
import { Store } from '@ngxs/store';
import * as _ from 'underscore';
import { fadeIn } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ContactsActions } from '@app/app/store/contacts/contacts.actions';
import { Contact } from '@app/app/interfaces/contact.model';
import { ssnValidator } from '@app/app/directives/ssn-validator.directive';
import { ContactsState } from '@app/app/store/contacts/contacts.state';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-edit-contact-dialog',
  templateUrl: './edit-contact-dialog.component.html',
  styleUrls: ['./edit-contact-dialog.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
          useAnimation(fadeIn, { params: { timing: 0.5 }})
      )
    ])
  ],
})
export class EditContactDialogComponent implements OnInit, OnDestroy {

  @Output() onCreateSuccess = new EventEmitter<void>();
  @Output() onUpdateSuccess = new EventEmitter<void>();
  @Output() onDeleteSuccess = new EventEmitter<void>();

  form: FormGroup;
  // Mode can be Edit or Add.
  formMode: string;
  stateOptions: Array<any>;
  yesNoOptions: Array<any>;
  httpError: string;
  hideEditModal: boolean = false;
  loading: boolean = true;
  errored: boolean = false;
  ownershipPercentSub: Subscription = new Subscription();
  // Override mask to hide first five chars of SSN.
  ssnMaskPatterns = {
    'X': { pattern: new RegExp('\\d'), symbol: 'X' },
    '0': { pattern: new RegExp('\\d') }
  };
  todayDate = new Date();

  /**
   * Inject data from the client via `data`.
   */
  constructor (
    private store: Store,
    public formOptionsService: FormOptionsService,
    // Used for confirmation dialog when cancelling dirty form.
    public confirmDialog: MatDialog,
    public selfDialogRef: MatDialogRef<EditContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { borrowerId: number, contact: Contact, formMode: string },
  ) {}

  ngOnInit(): void {
    // Are we adding or editing a contact?
    this.formMode = this.data.formMode;

    // Load form options.
    this.getFormOptions();

    this.createForm(this.data.contact);

    // Check initial form values to highlight invalid data from server.
    if (this.formMode == 'Edit') {
      this.form.markAllAsTouched();
    }
    this.ownershipPercentSub = this.ownershipPercentControl.valueChanges.subscribe(
      this.handlePercentOwnershipChanges.bind(this)
    );
  }

  ngOnDestroy(): void {
    this.ownershipPercentSub.unsubscribe();
  }

  get ownershipPercentControl(): AbstractControl {
    return this.form.get('percentOwnership')!;
  }

  handlePercentOwnershipChanges(value): void {
    if (value < 0) {
      this.ownershipPercentControl.setValue(0);
    } else if  (value > 100) {
      this.ownershipPercentControl.setValue(100);
    }
  }

  createForm(c: Contact | null) {
    // If we don't provide a contact, create one with default values.
    if (!c) {
      c = {
        id: null,
        borrowerId: this.data.borrowerId,
        isPrimary: false,
        title: '',
        first: '',
        last: '',
        mobilePhone: '',
        workPhone: '',
        workPhoneExt: '',
        email: '',
        ssn: '',
        ssnLastFour: '',
        street: '',
        street2: '',
        city: '',
        stateId: undefined,
        zipId: undefined,
        contractSigner: false,
      }
    }

    this.form = new FormGroup({
      isPrimary: new FormControl({
        value: c.isPrimary ? '1' : '0',
        disabled: !!c.isPrimary,
      }, [
        Validators.required
      ]
      ),
      title: new FormControl(c.title),
      percentOwnership: new FormControl(c.percentOwnership, [
        Validators.min(0),
        Validators.max(100)
      ]),
      first: new FormControl(c.first, [
        Validators.required
      ]),
      last: new FormControl(c.last, [
        Validators.required
      ]),
      mobilePhone: new FormControl(c.mobilePhone),
      workPhone: new FormControl(c.workPhone),
      workPhoneExt: new FormControl(c.workPhoneExt),
      email: new FormControl(c.email, [
        Validators.required
      ]),
      birthDate: new FormControl(c?.birthDate),
      ssn: new FormControl(c.ssnLastFour ? '00000' + c.ssnLastFour : undefined, [
        ssnValidator()
      ]),
      street: new FormControl(c.street),
      street2: new FormControl(c.street2),
      city: new FormControl(c.city),
      stateId: new FormControl(c.stateId),
      zipId: new FormControl(c.zipId),
    });
  }

  // Load all the form options from the service.
  private getFormOptions() {
    this.stateOptions = this.formOptionsService.states();
    this.yesNoOptions = this.formOptionsService.yesNo();
  }

  /**
   * Save form data (update or create).
   * Update for existing contact in formMode 'Edit'.
   * Create for new contact in formMode 'Add'.
   */
  upsert(form: FormGroup): void {
    // When isPrimary is disabled, its not visible and should be true/1.
    let isPrimary = true;
    if ('isPrimary' in form.value) {
      // isPrimary is saving as '1' or '0' and '0' (string) evaluates to true so
      // use this to ensure it's null if it's not 1 (or 1, using !=, not !==0).
      isPrimary = form.value['isPrimary'] != 1 ? null : form.value['isPrimary'];
    }

    // Grab the contact fields from the form.
    const contact: Contact = {
      id: this.data.contact?.id ?? null,
      isPrimary,
      borrowerId: this.data.borrowerId,
      title: form.value['title'],
      percentOwnership: form.value['percentOwnership'],
      // If has any ownership percent, set to true.
      contractSigner: form.value['percentOwnership'] ? true : false,
      first: form.value['first'],
      last: form.value['last'],
      mobilePhone: form.value['mobilePhone'],
      workPhone: form.value['workPhone'],
      workPhoneExt: form.value['workPhoneExt'],
      email: form.value['email'],
      birthDate: form.value['birthDate'],
      ssn: form.value['ssn'],
      street: form.value['street'],
      street2: form.value['street2'],
      city: form.value['city'],
      stateId: form.value['stateId'],
      zipId: form.value['zipId'] ?? null,
    }

    // Due to back-end design, changing the primary contact requires two
    // actions: 1) unset the existing primary, then 2) set current to primary.
    // If this is the case, this first pass will unset. If successful, it will
    // recursively call this function again to set the new primary contact.

    // isPrimary is the new form value compared to the starting value in data.
    if (isPrimary && !this.data.contact?.isPrimary) {
      const contactsSnapshot = this.store.selectSnapshot(ContactsState.contacts);
      // Make copy of current primary contact.
      let currentPrimary = {...contactsSnapshot.filter(c => c.isPrimary)[0]};
      // It's possible this is the first contact. If so, we skip ahead.
      if (currentPrimary && currentPrimary.id != contact.id) {
        currentPrimary.isPrimary = null;
        if (currentPrimary.id) {
          this.store.dispatch(new ContactsActions.Update(currentPrimary.id, currentPrimary)).subscribe({
            next: () => {
              // Succeeded unsetting the current primary contact so now recall
              // this method to set the new one.
              this.upsert(form);
            },
            error: (e) => {
              this.handleError(e, 'updating');
            },
          });
          // Exit now. When observer fires, we'll re-call upsert to proceed.
          return;
        }
      }
    }

    if (this.formMode === 'Edit') {
      // Update existing contact.
      if (this.data.contact?.id) {
        this.store.dispatch(new ContactsActions.Update(this.data.contact.id, contact)).subscribe({
          next: () => {
            this.selfDialogRef.close();
            // Let the parent component know when this succeeds.
            this.onUpdateSuccess.emit();
          },
          error: (e) => {
            this.handleError(e, 'updating');
          },
        });
      }
    } else {
      // Add/Create contact.
      this.store.dispatch(new ContactsActions.Create(contact)).subscribe({
        next: () => {
          // Server post function doesn't have the same false positive issue.
          this.selfDialogRef.close();
          // Let the parent component know when this succeeds.
          this.onCreateSuccess.emit();
        },
        error: (e) => {
          // Use func to avoid repeating.
          this.handleError(e, 'adding');
        }
      });

    }
  }

  /**
   * Delete an existing contact. Can't delete primary.
   */
  delete(form: FormGroup): void {
    // Hide current modal to avoid modal-on-modal.
    this.hideEditModal = true;

    let confirmDialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
      data: {
        title: "Delete contact?",
        description: "Are you sure you want to delete " + form.value['first'] + " " + form.value['last'] + "?",
        cancelLabel: "Cancel",
        confirmLabel: "Delete",
        confirmStyles: "background-color: red;",
        width: "352px"
      }
    });

    // Cancel the delete.
    confirmDialogRef.componentInstance.onCancel.subscribe(() => {
      confirmDialogRef.close();
      this.hideEditModal = false;
    });
    // Proceed with delete.
    confirmDialogRef.componentInstance.onConfirm.subscribe(() => {
      confirmDialogRef.close();
      this.selfDialogRef.close();
      if (this.data.contact.isPrimary) {
        this.httpError = "You can't delete the primary contact. Make another contact primary first to do this.";
      }

      if (this.data.contact?.id) {
        this.store.dispatch(new ContactsActions.Delete(this.data.contact.id)).subscribe({
          next: () => {
            this.selfDialogRef.close();
            // Let the parent component know when this succeeds.
            this.onDeleteSuccess.emit();
          },
          error: (e) => {
            this.handleError(e, 'deleting');
          },
        });
      }
    });

  }

  cancel($event: Event) {
    if (this.form.dirty) {
      $event.stopPropagation();
      // Hide current modal to avoid modal-on-modal.
      this.hideEditModal = true;

      let confirmDialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
        data: {
          title: "Are you sure you want to leave without saving?",
          description: "If you leave this page, any changes you've made will be lost.",
          cancelLabel: "Keep editing",
          confirmLabel:"Discard changes",
          width: "352px"
        }
      });

      // Cancel the "cancel" of the form dialog and resume.
      confirmDialogRef.componentInstance.onCancel.subscribe(() => {
        confirmDialogRef.close();
        this.hideEditModal = false;
      });
      // Proceed with cancelling the form dialog.
      confirmDialogRef.componentInstance.onConfirm.subscribe(() => {
        confirmDialogRef.close();
        this.selfDialogRef.close();
      });
    } else {
      // If not dirty, just close.
      this.selfDialogRef.close();
    }
  }

  handleError(e: any, operation: string) {
    this.httpError = '';
    // See if it's a duplicate email.
    const emailError = e?.error?.errors?.find((err: any) => err?.code == 'EMAIL_IN_USE');
    // See if it's an invalid zip code.
    const zipError = e?.message == "ZIP_ERROR";

    if (emailError) {
      this.httpError = "Another contact has this email address.";
      this.form.get('email')?.setErrors({'duplicate-email': true});
    } else if (zipError) {
      this.httpError = "This is an invalid zip code.";
      this.form.get('zipId')?.setErrors({'invalid-zip': true});
    } else {
      // General error.
      this.httpError = "There was a problem " + operation + " this contact.";
    }
  }
}
