import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-turn-down-button-smart',
  templateUrl: './turn-down-button-smart.component.html',
  styleUrls: ['./turn-down-button-smart.component.scss']
})
export class TurnDownButtonSmartComponent implements OnInit {

  @Input() dealId: number;

  constructor() { }

  ngOnInit(): void {}
}
