import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DealActivity } from '@app/app/interfaces/deal-activity.model';
import { DealActivitiesState } from '@app/app/store/deal-activities/deal-activities.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { dealActivityFormatter } from '../deal-activities-grid/deal-activities-column-defs';
import { activityIconMap, iconClassMap } from '../deal-activities-grid/deal-activities-constants';
import { MatIconModule } from '@angular/material/icon';
import moment from 'moment';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-deal-activities-quick-view',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './deal-activities-quick-view.component.html',
  styleUrl: './deal-activities-quick-view.component.scss'
})
export class DealActivitiesQuickViewComponent implements OnInit {

  latest3Activities : {
    name: string,
    rowId: number,
    icon: string,
    iconClass?: string,
    timeAgo: string,
  }[] = [];

  @Output() openDealHistoryButtonClicked = new EventEmitter<void>();

  @Select( DealActivitiesState.activities ) activities$: Observable<DealActivity[]>;

  ngOnInit() {
    this.activities$.subscribe((activities: DealActivity[]) => {
      const latestActivities = [...activities].sort((a,b) => b.created - a.created).slice(0,3)
      this.latest3Activities = latestActivities.map(x => {
        const name = dealActivityFormatter({value: x.type})
        return {
          name: name,
          rowId: x.rowId,
          icon: activityIconMap(name),
          iconClass: iconClassMap(name),
          timeAgo: this.timeAgo(x.created)
        }
      })
    });
  }

  timeAgo(dateStamp: number) {
    return moment.unix(dateStamp).fromNow();
  }

  onOpenPageClick() {
    this.openDealHistoryButtonClicked.emit()
  }
}
