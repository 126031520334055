import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetInactiveReasons } from './dead-dispositions.actions';
import { DeadDispositionService } from '@app/app/services/dead-disposition.service';
import { catchError, tap } from 'rxjs';
import { InactiveReason } from '@app/app/interfaces/inactive-reason.model';

export class DeadDispositionsStateModel {
  inactiveReasons: InactiveReason[];
}

@State<DeadDispositionsStateModel>({
  name: 'inactiveReasons',
  defaults: {
    inactiveReasons: [],
  },
})
@Injectable()
export class DeadDispositionsState {
  constructor(private _deadDispositionService: DeadDispositionService) {}

  @Selector()
  static inactiveReasonsByStatusFn(state: DeadDispositionsStateModel) {
    return (status: string) =>
      state.inactiveReasons.filter(
        (inactiveReason) => inactiveReason.status === status
      );
  }

  @Action(GetInactiveReasons)
  getInactiveReasons(
    { patchState }: StateContext<DeadDispositionsStateModel>,
    {}: GetInactiveReasons
  ) {
    return this._deadDispositionService.getInactiveReasons().pipe(
      catchError((err) => {
        // TODO: Report to Rollbar
        throw err;
      }),
      tap((inactiveReasons) => patchState({ inactiveReasons }))
    );
  }
}
