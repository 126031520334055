import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewOfferButtonSmartComponent } from './new-offer-button-smart/new-offer-button-smart.component';
import { NewOfferButtonComponent } from './new-offer-button/new-offer-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    NewOfferButtonSmartComponent,
    NewOfferButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule
  ],
  exports: [
    NewOfferButtonSmartComponent
  ]
})
export class NewOfferButtonModule { }
