import { StatusOption } from '@app/app/interfaces/status-option';
import { DealStage } from './deal-stage.enum';

type DealStatusOptionsByStage = {
  [key in DealStage]: StatusOption[];
}

// Note: Commented statuses are to prevent users from manually setting a status
// that is not gathered by the Funnel tabs. See ApplicationsListController::$states in API.
export const DEAL_STATUS_OPTIONS_BY_STAGE: DealStatusOptionsByStage = {
  // Application
  [DealStage.Application]: [
    {
      value: 'appComplete',
      display: 'Application complete',
      stage: 'application',
      hidden: false,
      disabledReason: null,
    },
    // {
    //   value: 'appInProgress',
    //   display: 'Application in progress',
    //   stage: 'application',
    //   hidden: false,
    //   disabledReason: null,
    // },
  ],

  // Underwriting
  [DealStage.Underwriting]: [
    // {
    //   value: 'appInProgress',
    //   display: 'Application in progress',
    //   stage: 'underwriting',
    //   hidden: false,
    //   disabledReason: null,
    // },
    {
      value: 'awaitingResponse',
      display: 'Awaiting response',
      stage: 'underwriting',
      hidden: false,
      disabledReason: null,
    },
    // {
    //   value: 'declined',
    //   display: 'Declined',
    //   stage: 'underwriting',
    //   hidden: false,
    //   disabledReason: null,
    // },
    {
      value: 'offerAccepted',
      display: 'Offer accepted',
      stage: 'underwriting',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'offerDeclined',
      display: 'Offer declined',
      stage: 'underwriting',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'offerReceived',
      display: 'Offer received',
      stage: 'underwriting',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'offerToBorrower',
      display: 'Offer to borrower',
      stage: 'underwriting',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'pendingFundingManager',
      display: 'Pending funding manager',
      stage: 'underwriting',
      hidden: false,
      disabledReason: null,
    },
  ],

  // Closing
  [DealStage.Closing]: [
    {
      value: 'contractRequested',
      display: 'Contract requested',
      stage: 'closing',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'contractIn',
      display: 'Contract signed',
      stage: 'closing',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'contractOut',
      display: 'Contract out',
      stage: 'closing',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'funding',
      display: 'Funding',
      stage: 'closing',
      hidden: false,
      disabledReason: null,
    },
  ],

  // Funded
  [DealStage.Funded]: [
    {
      value: 'behind',
      display: 'Behind',
      stage: 'funded',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'current',
      display: 'Current',
      stage: 'funded',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'default',
      display: 'Default',
      stage: 'funded',
      hidden: false,
      disabledReason: null,
    },
    // {
    //   value: 'paidOff',
    //   display: 'Paid off',
    //   stage: 'funded',
    //   hidden: false,
    //   disabledReason: null,
    // },
    {
      value: 'renewed',
      display: 'Renewed',
      stage: 'funded',
      hidden: false,
      disabledReason: null,
    },
    // {
    //   value: 'stacked',
    //   display: 'Stacked',
    //   stage: 'funded',
    //   hidden: false,
    //   disabledReason: null,
    // },
  ],

  // Inactive
  [DealStage.Inactive]: [
    {
      value: 'dead',
      display: 'Dead',
      stage: 'inactive',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'doesNotQualify',
      display: 'Does not qualify',
      stage: 'inactive',
      hidden: false,
      disabledReason: null,
    },
    {
      value: 'lostContact',
      display: 'Lost contact',
      stage: 'inactive',
      hidden: false,
      disabledReason: null,
    },
  ],
};
