@if (!(loading$ | async)) {
<mat-card appearance="outlined" class="m-4 new-theme-typography">
    <mat-card-content>
        <div class="grid justify-between grid-flow-col">
            <p class="text-lg font-bold">Import history</p>
            <div matTooltip="Please wait for current import to finish processing" [matTooltipDisabled]="!(latestImportStillProcessing$ | async)" matTooltipPosition="before" class="lendio-density-1">
                <button *ngIf="permittedToImport$ | async" mat-button mat-flat-button color="primary" (click)="openDialog()" [disabled]="(latestImportStillProcessing$ | async)">Import</button>
            </div>
        </div>
        @if (!emptyTable()) {
            <mat-table [dataSource]="(tableDataSource$ | async)"
                   matSort
                   [matSortActive]="(sortBy$ | async)"
                   [matSortDirection]="(sortDirection$ | async)"
                   (matSortChange)="handleSortEvent($event)">
                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row class="hover:cursor-pointer"
                         *matRowDef="let row; columns: columnsToDisplay"
                         [routerLink]="row.status !== 'failed' ? [row.id]: []"
                         routerLinkActive="active"
                         (click)="viewFailReasonIfFailed(row)">
                </mat-row>

                <ng-container matColumnDef="created">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date & Time</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <span class="cell-no-wrap">
                            {{data.created | date:'MMM d, y, h:mm a'}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="importedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Imported by</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <span class="cell-no-wrap">
                            {{ data.importedBy | defaultValue }}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Import Type</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        {{data.type === 'bankAccounts' ? 'Bank Accounts' : data.type === 'borrowers' ? 'Businesses' : data.type  | titlecase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="totalRecords">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-end" justify="end" arrowPosition="before">Total</mat-header-cell>
                    <mat-cell *matCellDef="let data" class="justify-end">
                        {{data.totalRecords === null ? 0 : data.totalRecords | number}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="importedRecords">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-end" justify="end" arrowPosition="before">Imported</mat-header-cell>
                    <mat-cell *matCellDef="let data" class="justify-end">
                        {{data.importedRecords === null ? 0 : data.importedRecords | number}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="updatedRecords">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-end" justify="end" arrowPosition="before">Updated</mat-header-cell>
                    <mat-cell *matCellDef="let data" class="justify-end">
                        {{data.updatedRecords === null ? 0 : data.updatedRecords | number}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="skippedRecords">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-end" justify="end" arrowPosition="before">Skipped</mat-header-cell>
                    <mat-cell *matCellDef="let data" class="justify-end">
                        {{data.skippedRecords === null ? 0 : data.skippedRecords | number}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="failedRecords">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-end" justify="end" arrowPosition="before">Failed</mat-header-cell>
                    <mat-cell class="justify-end"
                        *matCellDef="let data">{{data.failedRecords === null ? 0 : data.failedRecords | number}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <div [ngClass]="data.status === 'complete' || data.status === 'failed' ? 'pr-2 mt-1' : 'pr-3 mt-1'">
                            <mat-spinner *ngIf="data.status === 'uploading' || data.status === 'uploaded' || data.status === 'processing' || data.status === 'pending' || data.status === 'awaitingFile'"
                                [diameter]="20"
                            ></mat-spinner>
                            <mat-icon *ngIf="data.status === 'complete'" class="material-symbols-outlined green-check" fontSet="material-symbols-outlined">done</mat-icon>
                            <mat-icon *ngIf="data.status === 'failed'" class="material-symbols-outlined red-close" fontSet="material-symbols-outlined">close</mat-icon>
                        </div>
                        {{ data.status === 'awaitingFile' ? (data.status | replace : 'awaitingFile' : 'Awaiting file' | capitalize ) : (data.status | titlecase) }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <span class="cell-no-wrap">{{data.description}} </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="noRecords">
                    <td mat-footer-cell *matFooterCellDef>Empty</td>
                </ng-container>
            </mat-table>

            <lendio-paginator #paginator
                            (page)="handlePageEvent($event)"
                            [length]="(total$ | async)!"
                            [pageSize]="(pageSize$ | async)!"
                            [pageSizeOptions]="pageSizeOptions"
                            [pageIndex]="(pageIndex$ | async)!"
                            [showFirstLastButtons]="true">
            </lendio-paginator>
        } @else {
            <app-empty-state [type]="emptyType" [label]="emptyLabelContent" [loading]="loading$ | async">
              <div class="text-center font-normal text-gray-900 mt-2" messageContent>
                Click <a class="text-blue-500 hover:text-blue-800 cursor-pointer" *ngIf="permittedToImport$ | async" (click)="openDialog()" [disabled]="(latestImportStillProcessing$ | async)">Import</a> to get started.
              </div>
            </app-empty-state>
        }
    </mat-card-content>
</mat-card>
} @else {
    <div class="text-center loading">
        <app-dot-loader-scale></app-dot-loader-scale>
    </div>
}
