export class LoanProductOperators {
  public operatorDisplays = {
    is: '= Equals',
    min: '≥ At Least',
    max: '≤ At Most',
    contains: 'Contains',
    notContains: 'Does Not Contain',
    in: 'Is In',
    notIn: 'Is Not In',
    range: ''
  };
}
