<div>
    <mat-card appearance="outlined" class="m-4">
        <mat-card-content>
            <div class="flex flex-row items-start justify-between p-4">
                <div class="flex flex-row gap-4 justify-start items-start lendio-density-1">
                    <div class="lendio-density-2">
                        <mat-form-field appearance="fill" class="lendio-no-hint-wrapper">
                            <mat-icon matPrefix>search</mat-icon>
                            <input 
                                matInput
                                type="text"
                                placeholder="Search history"
                                [(ngModel)]="quickFilterValue"
                                (ngModelChange)="onQuickFilterChange()">
                        </mat-form-field>
                    </div>
                    <button 
                        data-cy="deal-history-export-menu"
                        class="flex flex-row items-center"
                        mat-button
                        #exportActivitiesTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="exportAllMenu">
                        <span class="font-semibold">Export</span>
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    </button>
                    <mat-menu #exportAllMenu>
                        <button data-cy="deal-history-export-all-csv"
                                mat-menu-item
                                (click)="exportData({format: 'csv', onlySelected: false})">
                            <span>CSV</span>
                        </button>
                        <button data-cy="deal-history-export-all-excel"
                                mat-menu-item
                                (click)="exportData({format: 'excel', onlySelected: false})">
                            <span>Excel</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="deal-activities-grid-container" id="dealActivitiesGridContainer">
                <ag-grid-angular class="ag-theme-quartz deal-activities-grid"
                                [rowData]="dealActivities"
                                [gridOptions]="dealActivitiesGridOptions"
                                [initialState]="dealActivitiesGridInitialState"
                                (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
                                (firstDataRendered)="onFirstDataRendered($event)"
                                (filterChanged)="onFilterChanged($event)"
                                (gridReady)="onGridReady($event)"
                                (selectionChanged)="onSelectionChanged($event)"
                                (toolPanelVisibleChanged)="onToolPanelVisibilityChanged($event)">
                </ag-grid-angular>
            </div>
        </mat-card-content>
    </mat-card>
</div>
