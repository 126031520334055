import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'grid-activity-cell',
    standalone: true,
    imports: [
      MatIconModule
    ],
    templateUrl: './description-cell.component.html',
    styleUrls: ['./description-cell.component.scss']
})
export class DescriptionCellComponent implements ICellRendererAngularComp {
    public description?: string;
    public actor?: string;
    public action?: string;
    public params: ICellRendererParams;
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
      this.actor = this.params.data.actor;
      this.action = this.params.data.action;
      this.description = this.params.data.description;
    }
  
    value(): string {
      return this.params.value
    }
  
    refresh(params: ICellRendererParams) {
      return false;
    }
}
