@switch(pageDisplayState) {
    @case('loading') {
        <div class="overlay-loader">
            <app-dot-loader-scale></app-dot-loader-scale>
        </div>
    }
    @case('503') {
        <app-thoughtspot-service-unavailable></app-thoughtspot-service-unavailable>
    }
    @case('reportsTable') {
        <mat-card id="thoughtspot-reports-table" appearance="outlined" class="!m-4">
            <mat-card-content>
                <app-reports-list-table></app-reports-list-table>
            </mat-card-content>
        </mat-card>
    }
    @case('empty') {
        <mat-card class="!m-4" appearance="outlined">
            <app-empty-state [label]="'It looks like there aren’t any reports right now.'"></app-empty-state>
        </mat-card>
    }
}
