import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadContractComponent } from './upload-contract/upload-contract.component';
import { UploadContractSmartComponent } from './upload-contract-smart/upload-contract-smart.component';
import { UploadContractDialogComponent } from './upload-contract-dialog/upload-contract-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentUploadModule } from '../document-upload/document-upload.module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { AlertModule } from '../alert/alert.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";

@NgModule({
  declarations: [
    UploadContractComponent,
    UploadContractSmartComponent,
    UploadContractDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    DocumentUploadModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatButtonModule,
    AlertModule,
    LoadersModule,
    MatFormFieldModule
  ],
  exports: [
    UploadContractSmartComponent
  ]
})
export class UploadContractModule { }
