import { EventEmitter, Injectable, signal, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunnelEventsService {
  funnelSidebarIsVisible: WritableSignal<boolean> = signal(false);
  funnelEnabled: WritableSignal<boolean> = signal(false);

  constructor() {
  }

  setFunnelEnabled(isEnabled: boolean): void {
    this.funnelEnabled.set(isEnabled);
  }

  openFunnelSidebar(): void {
    this.funnelSidebarIsVisible.set(true);
  }

  closeFunnelSidebar(): void {
    this.funnelSidebarIsVisible.set(false);
  }

  resetFunnelViews: EventEmitter<void> = new EventEmitter();
}
