<div *ngIf="status.showReasons" class="text-sm">
    <p>The following information is required for SBA submission. If any information is incomplete, please contact the applicant.</p>
    <div *ngFor="let reason of status.reasons" class="flex">
        <div class="flex-col">
            <mat-icon style="width: 14px; height: 14px; font-size: 14px; margin-right: 4px; vertical-align: middle;" class="!text-red-400">cancel</mat-icon>
        </div>
        <div class="flex-col">
            {{ reason }}
        </div>

    </div>
</div>