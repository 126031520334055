import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetContractRequestOffers } from 'src/app/store/contract-requests/contract-requests.actions';
import { ContractRequestsState } from 'src/app/store/contract-requests/contract-requests.state';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-contract-requests-smart',
    templateUrl: './contract-requests-smart.component.html',
    styleUrls: ['./contract-requests-smart.component.scss']
})
export class ContractRequestsSmartComponent implements OnInit {
    contractRequest$: Observable<any> = this.store.select(ContractRequestsState.contractRequests);
    @Input() dealId: number;
    @Input() loanProductName: string;

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.store.dispatch(new GetContractRequestOffers(this.dealId));
    }
}
