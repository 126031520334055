import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import { Select, Store } from '@ngxs/store';
import { take, takeUntil, tap } from 'rxjs/operators';
import { ApplicationDetails } from '../../../../interfaces/application-details.model';
import { SbaStatuses, SbaStatusObject } from '../../../../interfaces/sba-statuses.model';
import { SbaStatusService } from '../../../../services/sba-status.service';
import { GetApplicationDetails } from '../../../../store/application-details/application-details.actions';
import { CreateNewAlert } from '../../../../store/global-alerts/global-alerts.actions';
import { WithdrawFromSbaDialogComponent } from './withdraw-from-sba-dialog/withdraw-from-sba-dialog.component';
import * as _ from 'underscore';
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";
import { AuthState } from '@app/app/store/auth/auth.state';
import { Observable, Subject } from 'rxjs';
import { AuthUser } from '@app/app/store/auth/auth-user';

@Component({
  selector: 'app-ppp-status',
  templateUrl: './ppp-status.component.html',
  styleUrls: ['./ppp-status.component.scss']
})
export class PppStatusComponent implements OnInit, OnDestroy {

  @Select(AuthState.user) currentUser$: Observable<AuthUser>;
  @Input() applicationDetails: ApplicationDetails;

  private sbaStatus: SbaStatuses = new SbaStatuses(false);

  localeCurrencySymbol: string | null;
  expanded = false;
  status: SbaStatusObject;
  borrowerId: number;
  dealId: number;
  naicsCode: any;
  businessStart: string;
  loanAmount: any;
  zipId: string | number;
  isFranchise: boolean;
  isSbaFranchise: boolean;
  sbaFranchiseId: any;
  ppp1LoanNumber: any;
  ppp1LoanAmount: any;
  eidlLoanNumber: any;
  eidlLoanAmount: any;
  saving = false;
  submitting = false;
  formattedErrorsList: any = [];
  disableSubmit = false;
  disableSubmitTooltip: string|null = null;
  isLsp2: boolean = false;
  user: AuthUser | null;
  private destroyed$ = new Subject<void>();

  constructor(
    private statusService: SbaStatusService,
    private store: Store,
    public dialog: MatDialog,
    private localeSettingService: LocaleSettingService,
    private _snackbarService: LendioSnackbarService
  ) {
    this.localeCurrencySymbol = this.localeSettingService.getCurrencySymbol();
  }

  ngOnInit(): void {
    const loanProductId = _.get(this.applicationDetails, ['deal', 'loan_product', 'id']);
    const lsp2LoanProductIds = [
      1039, // Ready Capital LSP 2.0 1st Draw
      1040  // Ready Capital LSP 2.0 2nd Draw
    ];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.isLsp2 = lsp2LoanProductIds.indexOf(loanProductId) >= 0;
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user: AuthUser) => {
      this.disableSubmit = !!(user && user?.institution?.pppSbaSubmissionDisabled) || this.isLsp2;
    });

    if (this.disableSubmit && !this.isLsp2) {
      this.disableSubmitTooltip = 'SBA submission is disabled for your institution.';
    } else if (this.isLsp2) {
      this.disableSubmitTooltip = 'SBA submission is disabled for LSP 2.0 products.';
    }

    if (this.applicationDetails && this.applicationDetails.hasOwnProperty('borrowerValues')) {
      if (this.applicationDetails.borrowerValues[670]) {
        this.naicsCode = this.applicationDetails.borrowerValues[670].value;
      }

      if (this.applicationDetails.borrowerValues[238]) {
        const startDate = this.applicationDetails.borrowerValues[238].value;

        if (startDate && startDate.length === 10) {
          this.businessStart = this.applicationDetails.borrowerValues[238].value + 'T00:00:00';
        } else {
          this.businessStart = this.applicationDetails.borrowerValues[238].value;
        }
      }

      if (this.applicationDetails.borrowerValues[866]) {
        this.loanAmount = this.applicationDetails.borrowerValues[866].value;
      }

      if (this.applicationDetails.borrowerValues[878]) {
        this.isSbaFranchise = this.applicationDetails.borrowerValues[878].value === '1';
      }

      if (this.applicationDetails.borrowerValues[894]) {
        this.sbaFranchiseId = this.applicationDetails.borrowerValues[894].value;
      }
    }

    if (this.applicationDetails && this.applicationDetails.hasOwnProperty('deal')) {
      this.status = this.sbaStatus.getStatusObject(this.applicationDetails.deal);
      this.dealId = this.applicationDetails.deal.id;

      if (this.applicationDetails.deal.hasOwnProperty('borrower')) {
        this.borrowerId = this.applicationDetails.deal.borrowerId;
        this.zipId = this.applicationDetails.deal.borrower.zipId;
      }
    }

    // filter "start date" and "end date" key/values that sometimes make it into the sba errors list
    if (this.applicationDetails.deal.sbaStatus && this.applicationDetails.deal.sbaStatus.errors){
      this.formattedErrorsList = this.applicationDetails.deal.sbaStatus.errors.filter((err: string) => {
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        return !datePattern.test(err);
      });
    }

    if (this.applicationDetails.borrowerValues[934]) {
      this.ppp1LoanAmount = this.applicationDetails.borrowerValues[934].value;
    }

    if (this.applicationDetails.borrowerValues[933]) {
      this.ppp1LoanNumber = this.applicationDetails.borrowerValues[933].value;
    }

    if (this.applicationDetails.borrowerValues[880]) {
      this.eidlLoanAmount = this.applicationDetails.borrowerValues[880].value;
    }

    if (this.applicationDetails.borrowerValues[961]) {
      this.eidlLoanNumber = this.applicationDetails.borrowerValues[961].value;
    }

    if (this.applicationDetails.borrowerValues[946]) {
      this.isFranchise = this.applicationDetails.borrowerValues[946].value === '1';
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  showSubmit(): boolean {
    return !!this.user?.institution?.sbaTokenGuarded && this.status.canSubmit;
  }

  showWithdraw(): boolean {
    return (
      !!this.user?.institution?.sbaTokenGuarded
      && ['Declined', 'Submitted', 'Under Review', 'On Hold'].includes(this.status.title)
    );
  }

  showCheckStatus(): boolean {
    return !!this.user?.institution?.sbaTokenGuarded &&
        !this.status.canSubmit &&
      !this.applicationDetails.deal.sbaStatus &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.applicationDetails.deal.sbaStatus.confirmationId &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        this.applicationDetails.deal.sbaStatus.confirmationId !== 'pending';
  }

  showErrors() {
    return (
      ['Failed Validation', 'Declined', 'Failed to Connect', 'On Hold'].includes(this.status.title)
      && this.applicationDetails.deal.sbaStatus
      && this.applicationDetails.deal.sbaStatus.errors.length
    );
  }

  toggleExpanded(): void {
    if (
      this.status.reasons.length < 1 && !this.showForm()
      && !this.status.showDetails
      && (this.applicationDetails.deal.sbaStatus && !this.applicationDetails.deal.sbaStatus.errors.length)
    ) {
      return;
    }

    this.expanded = !this.expanded;
  }

  save(): void {
    this.saving = true;

    const payload = {
      borrowerId: this.borrowerId,
      industry_naics: this.naicsCode,
      business_start_date: this.businessStart,
      pppLoanAmount: this.loanAmount,
      sbaFranchiseId: this.sbaFranchiseId || '',
      bizZip: this.zipId,
      bizZipExtra: '',
      isFranchise: this.isFranchise,
      eidlLoanNumber: this.eidlLoanNumber,
      eidlLoanAmount: this.eidlLoanAmount,
      ppp1LoanNumber: this.ppp1LoanNumber,
      ppp1LoanAmount: this.ppp1LoanAmount,
      isSbaFranchise: this.isSbaFranchise
    };

    this.statusService.saveStatusInfo(payload).pipe(take(1)).subscribe((res: any) => {
      this.store.dispatch(new CreateNewAlert({
        level: 'success',
        message: 'PPP information successfully updated.'
      }));
      this.store.dispatch(new GetApplicationDetails(this.dealId));
      this.saving = false;
    }, (err: any) => {
      this.saving = false;

      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to save updated PPP information. Please try again momentarily.'
      }));
    });
  }

  showForm(): boolean {
    return this.status.showForm;
  }

  submitToSba() {
    if (this.disableSubmit) {
      return;
    }
    this.submitting = true;
    const allowResubmit = !!(this.status && this.status.withdrawn);

    this.statusService.submitToSba(this.dealId, allowResubmit).pipe(take(1)).subscribe((res: any ) => {
      this._snackbarService.open({
        message: 'Application successfully queued for submission.',
        canDismiss: true,
        duration: 10000
      });
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.status = this.sbaStatus['Queued'];
      this.submitting = false;
    }, (res: any) => {
      if (res.error && res.error.errors) {
        res.error.errors.forEach((msg: { message: any; }) => {
          this.store.dispatch(new CreateNewAlert({
            level: 'error',
            message: msg.message
          }));
        });
      } else {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'An unknown error occurred when submitting this application. Please try again.'
        }));
      }
      this.submitting = false;
    });
  }

  withdraw() {
    const dialogRef = this.dialog.open(WithdrawFromSbaDialogComponent, {
      width: '400px',
      panelClass: 'no-overflow',
      data: { dealId: this.dealId }
    });

    dialogRef.componentInstance.withdrawn.pipe(
      tap(() => this.status = this.sbaStatus.ready)
    ).subscribe();
  }
}
