<h2>History</h2>
@for (activity of latest3Activities; track activity.rowId) {
    <div class="history-quickview-row">
        <div [class]="activity.iconClass" class='items-center justify-center activity-icon mr-2'>
            <mat-icon [class]="activity.iconClass" class="material-icons-outlined history-icon">{{activity.icon}}</mat-icon>
        </div>
        <div class="mat-body-2 !font-semibold">{{activity.name}}</div>
        <div class="mat-caption justify-self-end text-lendio-warm-gray-400">{{activity.timeAgo}}</div>
    </div>
}
<div class="mt-2">
    <button
        mat-button
        [color]="'primary'"
        (click)="onOpenPageClick()"
    >
        View Page
    </button>
</div>