import {
    CheckboxSelectionCallbackParams,
    ColDef,
    GetRowIdParams,
    GridOptions,
    HeaderCheckboxSelectionCallbackParams,
    ModelUpdatedEvent,
    SizeColumnsToContentStrategy,
    SizeColumnsToFitGridStrategy,
    SizeColumnsToFitProvidedWidthStrategy,
    ValueFormatterParams
  } from 'ag-grid-community';
  import { ColumnHeaderComponent } from '@app/app/components/grid-components/column-header/column-header.component';
  import { NoDealActivitiesOverlayComponent } from '../deal-activities-grid/deal-activities-overlay/no-deal-activities-overlay.component';

  export const dealActivities = {
    applicationCreated: 'Application created',
    dealCreated: 'Deal created',
    dealPassed: 'Deal sent to Marketplace',
    dealUpdated: 'Deal updated',
    lenderUpdatedDeal: 'Deal updated',
    deal: 'Deal funded',
    // applicationCreated: 'Package sent', @TODO: what's the differentiator between this and other applicationCreated activities
    status: 'Deal status change',
  }

  export const documentActivities = {
    documentAttached: 'Document attached',
    documentNameChange: 'Document name change',
    requestCommentAdded: 'Request comment made',
    requestCompleted: 'Request completed',
    deleted: 'Document deleted',
    docusignEnvelopeCreated: 'Contract created',
    docusignEnvelopeUpdated: 'Contract updated',
    docusignEnvelopeSigned: 'Contract signed',
  };

  export const offerActivities = {
    offerUpdated: 'Offer updated',
    offerAccepted: 'Offer accepted',
    offerBorrowerAccepted: 'Offer accepted',
    offerDeleted: 'Offer removed',
    offerCreated: 'Offer received',
    offerRemovedFromPortal: 'Offer removed from portal',
    offerOptionRemovedFromPortal: 'Offer option removed',
    sbaApproved: 'SBA loan approved',
    sbaNotApproved: 'SBA loan not approved',
    sbaPlpCancelationProcessed: 'SBA Plp canceled',
    offerPublishedToBorrower: 'Offer published to portal',
    offerOptionPublished: 'Offer option published',
  };

  export const otherActivities = {
    aanSent: 'AAN sent',
    decisioningQualifierOverride: 'Decision override',
  };

  export const noteActivities = [
    'Note added',
    'noteAdded'
  ];


  export const AUTO_SIZE_STRATEGY:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
    type: 'fitGridWidth',
    defaultMinWidth: 180,
    columnLimits: [
      {
        colId: 'description',
        minWidth: 400,
      },
      {
        colId: 'created',
        maxWidth: 224
      },
      {
        colId: 'activityType',
        maxWidth: 400
      }
    ],
  };

  export const  COMPONENTS: { [p: string]: any; } = {
    agColumnHeader: ColumnHeaderComponent,
  };

  export const GRID_BASE_CONFIG: GridOptions = {
    noRowsOverlayComponent: NoDealActivitiesOverlayComponent,
    onModelUpdated: (event: ModelUpdatedEvent) => {
      event.api.getDisplayedRowCount() === 0 ?
        event.api.showNoRowsOverlay() :
        event.api.hideOverlay();
    },
    animateRows: false,
    autoSizeStrategy: AUTO_SIZE_STRATEGY,
    autoSizePadding: 16,
    cacheQuickFilter: true,
    columnMenu: 'new',
    enableCellTextSelection: true,
    groupDisplayType: 'groupRows',
    popupParent: document.body,
    rowSelection: 'multiple',
    suppressAggFuncInHeader: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    getRowId: (params: GetRowIdParams) => (params.data.rowId.toString()),
    components: COMPONENTS,
    defaultExcelExportParams: {
      processCellCallback: params => {
        const columnId = params.column.getColId();
        const colDef = params.column.getColDef();

        if (columnId === 'created') {
          const date = new Date(params.value * 1000);
          return date.toISOString();
        }

        if (colDef.valueFormatter && typeof colDef.valueFormatter === 'function') {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params.node?.data,
            node: params.node!,
            colDef: params.column.getColDef()
          };

          return colDef.valueFormatter(valueFormatterParams);
        }

        return params.value;
      }
    }
  };

  function isFirstColumn(params:
      | CheckboxSelectionCallbackParams
      | HeaderCheckboxSelectionCallbackParams,
  ) {
    const displayedColumns = params.api.getAllDisplayedColumns();
    return displayedColumns[0] === params.column;
  }

  export const GRID_BASE_DEFAULT_COL_DEF: ColDef = {
    columnChooserParams: {suppressSyncLayoutWithGrid: true},
    suppressHeaderFilterButton: false,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    headerCheckboxSelectionFilteredOnly: true,
    filterParams: {
      maxNumConditions: 1
    }
  };

  export const activityIconMap = (activity: string) => {
      if ([
        'Application created',
        'applicationCreated',
        'Deal created',
        'dealCreated',
        'Contract created',
        'contractCreated',
        'docusignEnvelopeCreated',
      ].includes(activity))
      {
        return 'add_circle_outline'
      }

      if ([
        'Deal status change',
        'dealStatusChange',
        'status'
      ].includes(activity))
      {
        return 'keyboard_tab'
      }

      if ([
        'Deal updated',
        'dealUpdated',
        'Contract updated',
        'contractUpdated',
        'docusignEnvelopeUpdated',
        'Offer updated',
        'offerUpdated'
      ].includes(activity)) {
        return 'update'
      }

      if ([
        'Deal funded',
        'dealFunded',
        'funded'
      ].includes(activity)) {
        return 'attach_money'
      }

      if ([
        'Document attached',
        'documentAttached'
      ].includes(activity)) {
        return 'note_add_outline'
      }

      if ([
        'Document name change',
        'documentNameChange'
      ].includes(activity)) {
        return 'edit_outline'
      }

      if ([
        'Document deleted',
        'documentDeleted',
        'deleted'
      ].includes(activity)) {
        return 'delete'
      }

      if ([
        'Contract signed',
        'docusignEnvelopeSigned',
        'Offer accepted',
        'offerAccepted',
        'offerBorrowerAccepted',
        'SBA loan approved',
        'sbaApproved',
        'Request completed',
        'requestCompleted'
      ].includes(activity)) {
        return 'check'
      }

      if ([
        'SBA loan not approved',
        'sbaNotApproved',
        'SBA Plp canceled',
        'sbaPlpCancelationProcessed'
      ].includes(activity)) {
        return 'cancel_outlined'
      }

      if ([
        'Offer received',
        'offerCreated'
      ].includes(activity)) {
        return 'sell'
      }

      if ([
        'Offer removed',
        'offerDeleted',
        'Offer removed from portal',
        'offerRemovedFromPortal'
      ].includes(activity)) {
        return 'do_not_disturb_on_outline'
      }

      if ([
        'AAN sent',
        'aanSent',
        'Deal sent to Marketplace',
        'Deal sent to marketplace',
        'dealPassed',
        'Package sent',
        // 'packageSent' @TODO: currently set for applicationCreated
      ].includes(activity)) {
        return 'send'
      }

      if ([
        'Decision override',
        'decisioningQualifierOverride'
      ].includes(activity)) {
        return 'redo'
      }

      if ([
        'Note added',
        'note'
      ].includes(activity)) {
        return 'post_add'
      }

      if ([
        'Offer published to portal',
        'offerPublishedToPortal',
        'Offer option published',
        'offerOptionPublished'
      ].includes(activity)) {
        return 'publish_outline'
      }

      if ([
        'Request comment made',
        'requestCommentAdded'
      ].includes(activity)) {
        return 'add_comment_outline'
      }

      // Default
      return 'check'
  }

  export const iconClassMap = (activity: string) => {
    if ([...Object.keys(dealActivities), ...Object.values(dealActivities)].includes(activity)) {
      return 'deal-activity-icon';
    }

    if ([...Object.keys(otherActivities), ...Object.values(otherActivities)].includes(activity)) {
      return 'other-activity-icon';
    }

    if ([...Object.keys(offerActivities), ...Object.values(offerActivities)].includes(activity)) {
      return 'offer-activity-icon';
    }

    if ([...Object.keys(documentActivities), ...Object.values(documentActivities)].includes(activity)) {
      return 'document-activity-icon';
    }

    if ([...Object.keys(noteActivities), ...Object.values(noteActivities)].includes(activity)) {
      return 'note-activity-icon';
    }
  }
