import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AssignmentsSmartComponent } from './assignments-smart/assignments-smart.component';
import { AssignmentsComponent } from './assignments/assignments.component';


@NgModule({
    declarations: [AssignmentsSmartComponent, AssignmentsComponent],
    exports: [
        AssignmentsSmartComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatButtonModule
  ]
})
export class AssignmentsModule {
}
