import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GetLenderReportsList, GetAffiliateReportsList, GetPartnerReportList, ClearThoughtSpotReportsStore, LogInToThoughtSpot, LogOutOfThoughtSpot } from './thoughtspot-reports.actions';
import { ThoughtspotReportsService } from 'src/app/services/thoughtspot-reports.service';
import { ThoughtspotLiveboardModel } from '@app/app/interfaces/thoughtspot-liveboard.model';
import {
  ServiceUnavailableError
} from "@app/app/components/thoughtspot-liveboard/thoughtspot-error-handle.service";
import { ThoughtSpotService } from '@app/app/services/thoughtspot-service';
import { AuthStatus } from '@thoughtspot/visual-embed-sdk';
import { AuthState } from '../auth/auth.state';
import { SaasFeature } from '@app/app/interfaces/saas-feature.model';
import { environment } from '@app/environments/environment';

export class ThoughtspotReportsStateModel {
  reportList: ThoughtspotLiveboardModel[] | null;
  fetchReportListComplete: boolean;
  ts503ServiceUnavailable: boolean;
  tsAuthenticated: boolean | null;
  error: boolean;
}

@State<ThoughtspotReportsStateModel>({
  name: 'partnerReports',
  defaults: {
    reportList: null,
    fetchReportListComplete: false,
    ts503ServiceUnavailable: false,
    tsAuthenticated: null,
    error: false
  },
})
@Injectable()
export class ThoughtspotReportsState {
  constructor(
    private reportsService: ThoughtspotReportsService,
    private thoughtSpotService: ThoughtSpotService,
    private store: Store
  ) {}
  private _is503Response(response): boolean {
    return response instanceof HttpErrorResponse && response.status === 503;
  }

  @Selector()
  static reportList(state: ThoughtspotReportsStateModel) {
    return state.reportList;
  }

  @Selector()
  static ts503ServiceUnavailable(state: ThoughtspotReportsStateModel) {
    return state.ts503ServiceUnavailable;
  }

  @Selector()
  static fetchReportListComplete(state: ThoughtspotReportsStateModel) {
    return state.fetchReportListComplete;
  }

  @Selector()
  static tsAuthenticated(state: ThoughtspotReportsStateModel) {
    return state.tsAuthenticated;
  }

  @Selector()
  static error(state: ThoughtspotReportsStateModel) {
    return state.error;
  }

  @Action(GetLenderReportsList)
  getReports(
    { patchState }: StateContext<ThoughtspotReportsStateModel>,
    {}: GetLenderReportsList
  ) {
    patchState({fetchReportListComplete: false});
    return this.reportsService.getLenderReportList().pipe(
      catchError(error => {
        if (this._is503Response(error)) {
          patchState({
            ts503ServiceUnavailable: true,
            fetchReportListComplete: true
          });
          throw new ServiceUnavailableError(error);
        } else {
          patchState({
            fetchReportListComplete: true,
            error: true
          });
          return throwError(() => error);
        }
      }),
      tap((response: ThoughtspotLiveboardModel[] | HttpErrorResponse) => {
        if (this._is503Response(response)) {
          patchState({
            ts503ServiceUnavailable: true,
            fetchReportListComplete: true
          });
          throw new ServiceUnavailableError(response as HttpErrorResponse);
        } else {
          patchState({
            reportList: response as ThoughtspotLiveboardModel[],
            fetchReportListComplete: true
          });
        }
      })
    );
  }
  @Action(GetAffiliateReportsList)
  getAffiliateReports(
    { patchState }: StateContext<ThoughtspotReportsStateModel>,
    {}: GetAffiliateReportsList
  ) {
    patchState({fetchReportListComplete: false});
    return this.reportsService.getAffiliateReportList().pipe(
      catchError(error => {
        if (this._is503Response(error)) {
          patchState({
            ts503ServiceUnavailable: true,
            fetchReportListComplete: true
          });
          throw new ServiceUnavailableError(error);
        } else {
          patchState({
            fetchReportListComplete: true,
            error: true
          });
          return throwError(() => error);
        }
      }),
      tap((response: ThoughtspotLiveboardModel[] | HttpErrorResponse) => {
        if (this._is503Response(response)) {
          patchState({
            ts503ServiceUnavailable: true,
            fetchReportListComplete: true
          });
          throw new ServiceUnavailableError(response as HttpErrorResponse);
        } else {
          patchState({
            reportList: response as ThoughtspotLiveboardModel[],
            fetchReportListComplete: true
          });
        }
      })
    );
  }

  @Action(GetPartnerReportList)
  getPartnerReports(
    { patchState }: StateContext<ThoughtspotReportsStateModel>,
    {}: GetPartnerReportList
  ) {
    return this.reportsService.getPartnerReportList().pipe(
      catchError(error => {
        if (this._is503Response(error)) {
          patchState({
            ts503ServiceUnavailable: true,
            fetchReportListComplete: true
          });
          throw new ServiceUnavailableError(error);
        } else {
          patchState({
            fetchReportListComplete: true,
            error: true
          });
          return throwError(() => error);
        }
      }),
      tap((response: ThoughtspotLiveboardModel[] | HttpErrorResponse) => {
        if (this._is503Response(response)) {
          patchState({
            ts503ServiceUnavailable: true,
            fetchReportListComplete: true
          });
          throw new ServiceUnavailableError(response as HttpErrorResponse);
        } else {
          patchState({
            reportList: response as ThoughtspotLiveboardModel[],
            fetchReportListComplete: true
          });
        }
      })
    );
  }

  @Action(ClearThoughtSpotReportsStore)
  clearThoughtSpotReportsStore(
    { patchState }: StateContext<ThoughtspotReportsStateModel>,
    {}: ClearThoughtSpotReportsStore) {
      patchState({
        reportList: null,
        fetchReportListComplete: false,
        ts503ServiceUnavailable: false,
        tsAuthenticated: null,
        error: false
      });
  }

  @Action(LogInToThoughtSpot)
  logInToThoughtSpot(ctx: StateContext<ThoughtspotReportsStateModel>, { tsSaasFeatures }) {
    if (ctx.getState().tsAuthenticated || environment?.disableThoughtspot) {
      return;
    }

    const patchState = ctx.patchState;
    const user = this.store.selectSnapshot(AuthState.user)
    if (user?.email) {
      const emitter = this.thoughtSpotService.initDefault(user.email!);
      emitter.on(AuthStatus.SDK_SUCCESS, () => {
        patchState({
          tsAuthenticated: true,
          error: false
        })
        this.getReportsList(tsSaasFeatures)
      });
      emitter.on(AuthStatus.FAILURE, () =>
        patchState({
          tsAuthenticated: false,
          error: true
        })
      );
    } else {
      patchState({
        tsAuthenticated: false,
        error: true
      });
    }
  }

  @Action(LogOutOfThoughtSpot)
  logOutOfThoughtSpot({ patchState }: StateContext<ThoughtspotReportsStateModel>, {}) {
    this.thoughtSpotService.logout().then((loggedOut) =>
      patchState({
        tsAuthenticated: null
      })
    );
  }

  getReportsList(tsSaasFeatures: SaasFeature[]) {
    const featureNames = tsSaasFeatures.map(x => x.alias)
    if (featureNames.includes('reportsList')) {
      if (featureNames.includes('dashboardLenderBase')) {
        this.store.dispatch(new GetLenderReportsList());
      } else if (featureNames.includes('dashboardPartnerBase')) {
        this.store.dispatch(new GetPartnerReportList());
      } else if (featureNames.includes('dashboardAffiliateBase')) {
        this.store.dispatch(new GetAffiliateReportsList());
      }
    }
  }
}
