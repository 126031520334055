import { NgModule } from '@angular/core';
import { TeamMembersTableComponent } from '@app/app/components/administration/team/team-members-table/team-members-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { TimeAgoModule } from '@app/app/pipes/time-ago/time-ago-pipe.module';
import { CommonModule } from '@angular/common';
import { TeamMemberDialogComponent } from '@app/app/components/administration/team/team-member-dialog/team-member.dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TruncatePipeModule } from '@app/app/pipes/truncate/truncate-pipe.module';
import { CsvPipeModule } from '@app/app/pipes/csv/csv.pipe.module';
import { PluckPipeModule } from '@app/app/pipes/pluck/pluck.pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirectiveModule } from '@app/app/directives/let/let.directive.module';
import { MatListModule } from '@angular/material/list';
import { LoadersModule } from '@app/app/components/loaders/loaders.module';
import { DefaultValuePipeModule } from '@app/app/pipes/default-value/default-value-pipe.module';

const components = [
  TeamMembersTableComponent,
  TeamMemberDialogComponent,
]

@NgModule({
  declarations: components,
  exports: components,
  imports: [

    CommonModule,
    CsvPipeModule,
    LetDirectiveModule,
    LoadersModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    PluckPipeModule,
    ReactiveFormsModule,
    TimeAgoModule,
    TruncatePipeModule,
    DefaultValuePipeModule,
  ],
})
export class TeamMembersModule { }
