
import { Alert } from '../../interfaces/alert.model';

export class CreateNewAlert {
    static readonly type = '[Alerts] CreateNewAlert';
    constructor(public alert: Alert) {}
}

export class DismissAlert {
    static readonly type = '[Alerts] DismissAlert';
    constructor(public alert: Alert) {}
}

export class ClearAlertsStore {
    static readonly type = '[Alerts] ClearAlertsStore';
    constructor() {}
}
