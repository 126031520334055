<mat-card appearance="outlined" class="m-4">
    <mat-card-content>
    <div *ngIf="portalUsers$ | async">
        <mat-table [dataSource]="portalUsers$">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span data-cy="user-full-name">{{ user.fullName }}</span>
                    <!-- todo - remove wonky inline styles -->
                    <span *ngIf="userIsSelf(user)" class="flex items-center text-lendio-green-400">
                        <mat-icon class="scale-75 ml-1">star</mat-icon>
                        <span class="text-xs pt-1">Self</span>
                    </span>
                    <span *ngIf="user.isAdmin" class="flex items-center text-label-500">
                        <mat-icon class="scale-75 ml-1">star</mat-icon>
                        <span class="text-xs pt-1">Admin</span>
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell data-cy="user-email" *matCellDef="let user"> {{ user.user ? user.user.email : '' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
                <mat-cell *matCellDef="let user; let i = index" class="flex-right">
                    @if (newThemeEnabled()) {
                        <button mat-icon-button
                                *ngIf="canManageUsers"
                                data-cy="edit-user"
                                (click)="openNewEditUserDialog(user)">
                            <mat-icon >create</mat-icon>
                        </button>
                    } @else {
                        <mat-icon *ngIf="canManageUsers"
                                  data-cy="edit-user"
                                  class="!text-gray-600 cursor-pointer"
                                  (click)="openEditUserDialog(user)">
                            <span>create</span>
                        </mat-icon>
                    }
                    @if (newThemeEnabled()) {
                        <button mat-icon-button
                                    *ngIf="canDeleteUser(user)"
                                    data-cy="delete-user"
                                    class="!ml-8 cursor-pointer"
                                    (click)="openNewDeleteUserDialog(user)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    } @else {

                        <mat-icon *ngIf="canDeleteUser(user)"
                                  data-cy="delete-user"
                                  class="!text-gray-600 !ml-8 cursor-pointer"
                                  (click)="openDeleteUserDialog(user)">
                            <span>delete</span>
                        </mat-icon>
                    }
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="mt-3">
            @if (canManageUsers) {
                @if (newThemeEnabled()) {
                    <button data-cy="add-user"
                            mat-raised-button color="primary"
                            (click)="openNewAddUserDialog()">
                        <span>Add New User</span>
                    </button>
                } @else {
                    <button data-cy="add-user"
                            mat-raised-button
                            color="primary"
                            (click)="openAddUserDialog()">
                        <span>Add New User</span>
                    </button>
                }
            } @else {
                <div class="pl-5 flex items-center text-livid-200">
                    <mat-icon>error_outline</mat-icon>
                    <span class="leading-none text-sm">Contact your admin to add or change user information</span>
                </div>
            }
        </div>
    </div>
    </mat-card-content>
</mat-card>
