export class LeadStatusSubStep
{
    description: string;
    isComplete: boolean;

    public constructor(description: string, isComplete: boolean) {
        this.description = description;
        this.isComplete = isComplete;
    }
}
