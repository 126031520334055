import { NgModule } from '@angular/core';
import { CsvPipe } from '@app/app/pipes/csv/csv.pipe';

@NgModule({
  declarations: [
    CsvPipe,
  ],
  exports: [
    CsvPipe,
  ]
})
export class CsvPipeModule { }
