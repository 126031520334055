import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PortalUser } from '../../interfaces/portal-user.model';
import { GetPortalUsers, ClearPortalUsers, DeletePortalUser, CreatePortalUser, UpdatePortalUser } from './portal-users.actions';
import { PortalUsersService } from '../../services/portal-users.service';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { AuthState } from '../auth/auth.state';
import { UpdateAuthUser } from '../auth/auth.actions';

export class PortalUsersStateModel {
    portalUsers: PortalUser[] | null;
    portalUsersRetrieved: boolean;
}

@State<PortalUsersStateModel>({
    name: 'portalUsers',
    defaults: {
        portalUsers: null,
        portalUsersRetrieved: false
    }
})
@Injectable()

export class PortalUsersState {

    @Selector()
    static portalUsers(state: PortalUsersStateModel) {
        return state.portalUsers;
    }

    constructor(
        private portalUsersService: PortalUsersService,
        private store: Store,
    ) {}

    @Action(GetPortalUsers)
    getPortalUsers({ getState, patchState }: StateContext<PortalUsersStateModel>, {}: GetPortalUsers) {
        if (!getState().portalUsersRetrieved) {
            patchState({portalUsersRetrieved: true});
            return this.portalUsersService.getPortalUsers() ? this.portalUsersService.getPortalUsers()?.pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to retrieve users. Please refresh the page to try again.'
                }));
                return throwError(err);
            }), tap((res: any) => {
                if (res.data) {
                    const formattedUsers = res.data.map((user: PortalUser) => {
                        return {
                            ...user,
                            isAdmin: user.permissions ? user.permissions.includes('lpxManageLenderUsers') : false
                        }
                    })
                    patchState({portalUsers: formattedUsers});
                }
            })) : getState().portalUsers;
        }
        return getState().portalUsers;

    }

    @Action(CreatePortalUser)
    createPortalUser({ getState, patchState }: StateContext<PortalUsersStateModel>, { newPortalUser }: CreatePortalUser) {
        if (this.store.selectSnapshot(AuthState.user)?.isReferralPartner()) {
            const affiliateId: number | null | undefined = this.store.selectSnapshot(AuthState.user)?.role === 'affiliate' ? this.store.selectSnapshot(AuthState.user)?.affiliate?.id : null
            return this.portalUsersService.createAffiliateUser(affiliateId, newPortalUser).pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to create new user. Please refresh the page and try again.'
                }));
                return throwError(err);
            }), tap((res: any) => {
              if (res.data.userId) { // set id if user's id is sent back as userId
                res.data.id = res.data.userId;
                res.data.isAdmin = res.data.permissions ? res.data.permissions.includes('lpxManageLenderUsers') : false
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // the spread operator is currently not supported for asynchronous iterators.
              // Todo refactor to "for await" syntax
              patchState({portalUsers: [...getState().portalUsers, res.data]});
              this.store.dispatch(new CreateNewAlert({
                  level: 'success',
                  message: 'New user added!'
              }));
            }));
        }

        else {
            return this.portalUsersService.createLenderUser(newPortalUser).pipe(catchError((err: any) => {
                const dupeError: boolean = !!err.error.errors.filter( ({message}: any) => message === 'This user already exists.').length;
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: dupeError ? 'Unable to create user. Contact support for more details.' : 'Unable to create new user. Please refresh the page and try again.'
                }));
                return throwError(err);
            }), tap((res: any) => {
                patchState({portalUsers: [...getState().portalUsers, res.data]});
                this.store.dispatch(new CreateNewAlert({
                    level: 'success',
                    message: 'New user added!'
                }));
            }));
        }
    }

    @Action(UpdatePortalUser)
    updatePortalUser({ getState, patchState }: StateContext<PortalUsersStateModel>, { portalUserId, updates }: UpdatePortalUser) {
        if (this.store.selectSnapshot(AuthState.user)?.isReferralPartner()) {
            const affiliateId: number | null | undefined = this.store.selectSnapshot(AuthState.user)?.role === 'affiliate' ? this.store.selectSnapshot(AuthState.user)?.affiliate?.id : null
            return this.portalUsersService.updateAffiliateUser(affiliateId, portalUserId, updates).pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to update user. Please refresh the page and try again.'
                }));
                return throwError(err);
            }), tap((res: any) => {
                const currentPortalUsers = [ ...getState().portalUsers ];
                const updatedUserIndex = currentPortalUsers.findIndex(lu => lu.id === portalUserId);
                res.data.isAdmin = res.data.permissions ? res.data.permissions.includes('lpxManageLenderUsers') : false
                currentPortalUsers[updatedUserIndex] = res.data;
                patchState({ portalUsers: currentPortalUsers });

                const authUser = this.store.selectSnapshot(AuthState.user);

                if (authUser?.id === res.data.id) {
                    this.store.dispatch(new UpdateAuthUser({ permissionsList: res.data.permissions }));
                }

                this.store.dispatch(new CreateNewAlert({
                    level: 'success',
                    message: 'User updated successfully!'
                }));
            }));
        }

        else {
            return this.portalUsersService.updateLenderUser(portalUserId, updates).pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to update user. Please refresh the page and try again.'
                }));
                return throwError(err);
            }), tap((res: any) => {
                const currentPortalUsers = [...getState().portalUsers];
                const updatedUserIndex = currentPortalUsers.findIndex(lu => lu.id === portalUserId);
                currentPortalUsers[updatedUserIndex] = res.data;
                patchState({portalUsers: currentPortalUsers});
                this.store.dispatch(new CreateNewAlert({
                    level: 'success',
                    message: 'User updated successfully!'
                }));
            }));
        }
    }

    @Action(DeletePortalUser)
    deletePortalUser({ getState, patchState }: StateContext<PortalUsersStateModel>, { portalUserId }: DeletePortalUser) {
        if (this.store.selectSnapshot(AuthState.user)?.isReferralPartner()) {
            const affiliateId: number | null | undefined = this.store.selectSnapshot(AuthState.user)?.role === 'affiliate' ? this.store.selectSnapshot(AuthState.user)?.affiliate?.id : null
            return this.portalUsersService.deleteAffiliateUser(affiliateId, portalUserId).pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to delete user. Please refresh the page and try again.'
                }));
                return throwError(err);
            }), tap(() => {
                const currentPortalUsers = [ ...getState().portalUsers ];
                this.store.dispatch(new CreateNewAlert({
                    level: 'success',
                    message: 'User deleted successfully!'
                }));
                patchState({ portalUsers: currentPortalUsers.filter(lu => lu.id !== portalUserId) });
            }));
        }
        else {
            return this.portalUsersService.deleteLenderUser(portalUserId).pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to delete user. Please refresh the page and try again.'
                }));
                return throwError(err);
            }), tap(() => {
                const currentPortalUsers = [...getState().portalUsers];
                this.store.dispatch(new CreateNewAlert({
                    level: 'success',
                    message: 'User deleted successfully!'
                }));
                patchState({portalUsers: currentPortalUsers.filter(lu => lu.id !== portalUserId)});
            }));
        }
    }

    @Action(ClearPortalUsers)
    ClearPortalUsers({ patchState }: StateContext<PortalUsersStateModel>, {}: ClearPortalUsers) {
        patchState({portalUsers: null, portalUsersRetrieved: false});
    }
}
