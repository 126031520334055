<h2 mat-dialog-title>Delete All Qualifications</h2>
<mat-dialog-content class="mat-typography">
    <p>By deleting the last base qualification,
       you will be deleting all exception groups (if any) and their values.</p>
</mat-dialog-content>
<mat-dialog-actions class="!py-4" align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="warn"
            [mat-dialog-close]="true"
            FocusInitial
            data-cy="qualifier-component-confirm-delete-all-qualifiers">
        Delete
    </button>
</mat-dialog-actions>
