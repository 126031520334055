import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-borrower-values',
  templateUrl: './dynamic-borrower-values.component.html',
  styleUrls: ['../details.component.scss']
})
export class DynamicBorrowerValuesComponent implements OnInit {

  @Input() applicationFields: any;
  cleanedFields: any[] = [];
  skip = [
      'creditScore',
      'financeAmount',
      'annualRevenue',
      'annual_profits',
      'average_monthly_sales',
      'ownerBirthDate',
      'owner_street_address',
      'owner_city',
      'owner_state',
      'owner_zip',
      'bankruptcy',
      'ownerPercent',
      'doingBusinessAsName',
      'federalstate_tax_id',
      'entity_type',
      'lendioIndustry',
      'state_of_incorporation',
      'business_start_date',
      'loanPurpose',
      'user.email',
      'user.first',
      'user.last',
      'borrower.name',
      'borrower.phone',
      'borrower.street',
      'borrower.street2',
      'borrower.city',
      'borrower.stateId',
      'borrower.zipId',
    ];

  constructor() { }

  ngOnInit(): void {
    // We have to de-dup ApplicationFields, if loan product has multiple qualifiers for same field, the values get repeated
    const added: string[] = [];
    this.applicationFields.forEach((item: { alias: string; value: string; displayType: string; }) => {
      if (item === null || item.alias === undefined || added.includes(item.alias)){
        // skip
      } else {
        if (item.alias === 'contact.ssn' && item.value !== null) {
          item = { ...item };
          item.value = this.formatSSN(item.value);
        }
        // Map "Years Since Bankruptcy Discharge" to human-readable values
        if (
          item.alias === 'computed.bankruptcyDischargeYears' &&
          item.value !== null &&
          (item.value == '999' || item.value == '-1')
        ) {
          item = { ...item };
          item.displayType = 'text';
          item.value =
            item.value == '999'
              ? 'No bankruptcy'
              : 'Un-discharged bankruptcy';
        }
        if (this.skip.indexOf(item.alias) === -1) {
          this.cleanedFields.push(item);
          added.push(item.alias);
        }
      }
    });
  }

  formatSSN(ssn: string) {
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, 'xxx-xx-$3');
  }
}
