import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
    ExportApplications,
    GetApplicationsExports,
    ImportForgiveness,
    GetForgivenessDocuments,
    ChangePage
} from './csv.actions';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError, EMPTY } from 'rxjs';
import { CsvService } from '../../services/csv.service';
import { AuthState } from '../auth/auth.state';


export class CsvStateModel {
    applicationExports: [] | null;
    forgivenessImportsFullDataSet: [] | null;
    forgivenessImportsPaginated: [] | null;
    totalCount: number;
    status: string;
}

@State<CsvStateModel>({
    name: 'csv',
    defaults: {
        applicationExports: null,
        forgivenessImportsFullDataSet: null,
        forgivenessImportsPaginated: null,
        totalCount: 0,
        status: 'init'
    }
})
@Injectable()

export class CsvState {

    @Selector()
    static applicationExports(state: CsvStateModel) {
        return state.applicationExports;
    }

    @Selector()
    static totalCount(state: CsvStateModel) {
        return state.totalCount;
    }

    @Selector()
    static status(state: CsvStateModel) {
        return state.status;
    }

    @Selector()
    static forgivenessImportsFullDataSet(state: CsvStateModel) {
        return state.forgivenessImportsFullDataSet;
    }

    @Selector()
    static forgivenessImportsPaginated(state: CsvStateModel) {
        return state.forgivenessImportsPaginated;
    }

    constructor(
        private csvService: CsvService,
        private store: Store,
    ) {}

    @Action(GetApplicationsExports)
    getApplicationsExports({ patchState }: StateContext<CsvStateModel>, {}: GetApplicationsExports) {
        return this.csvService.getApplicationsExports().pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve your export data. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            const applicationExports = response.data.exports;
            patchState({ applicationExports });
        }));
    }

    @Action(ExportApplications)
    exportApplications({ }: StateContext<CsvStateModel>, {}: ExportApplications) {
        return this.csvService.exportApplications().pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to export your applications. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            this.store.dispatch(new GetApplicationsExports());
            if (response.data === 'QUEUED') {
                this.store.dispatch(new CreateNewAlert({
                    level: 'success',
                    message: 'Your export has been queued. When it\'s ready, you\'ll be able to download it in the list below.'
                }));
            }
            if (response.data === 'EXPORT_IN_PROGRESS') {
                this.store.dispatch(new CreateNewAlert({
                    level: 'warning',
                    message: 'An export is already in progress. Please try again later.'
                }));
            }
        }));
    }

    @Action(GetForgivenessDocuments)
    getForgivenessDocuments({ patchState }: StateContext<CsvStateModel>, {}: GetForgivenessDocuments) {
        const user = this.store.selectSnapshot(AuthState.user);
        const institutionId = (user && user?.institution && user?.institution?.id);
        patchState({
            status: 'GetForgivenessDocuments'
        });

        if (!institutionId) {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve your forgiveness documents. Please refresh the page to try again.'
            }));
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.csvService.getForgivenessDocuments(institutionId).pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve your forgiveness documents. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            const forgivenessImportsPaginated = this.paginate(response.data);
            patchState({
                forgivenessImportsPaginated,
                totalCount: response.data.length,
                forgivenessImportsFullDataSet: response.data,
                status: 'success'
            });
        }));
    }

    @Action(ChangePage)
    changePage({patchState, getState, dispatch}: StateContext<CsvStateModel>, pageData: any){
        const state = getState();
        const forgivenessImportsPaginated = this.paginate(state.forgivenessImportsFullDataSet!, pageData.pageSize, pageData.pageIndex);
        patchState({
            forgivenessImportsPaginated
        });
    }

    paginate(forgivenessImportsFullDataSet: [], pageSize: number = 50, pageIndex: number = 0) {
        const startingIndex = pageSize * pageIndex;
        const newPaginatedDataset: [] = [];
        for (let i = 0; i < pageSize; i++) {
            if (!forgivenessImportsFullDataSet[startingIndex + i]) {
                break;
            }
            newPaginatedDataset.push(forgivenessImportsFullDataSet[startingIndex + i]);
        }
        return newPaginatedDataset;
    }

    @Action(ImportForgiveness)
    importForgiveness({ }: StateContext<CsvStateModel>, { document }: ImportForgiveness) {
        const formData = new FormData();

        formData.append('importCSV', document, document.name || 'Import CSV');
        formData.append('folderName', this.store.selectSnapshot(AuthState.user)?.institution?.name || 'No Lender Name');

        return this.csvService.postForgivenessImport(formData).pipe(catchError((err: any) => {
            if (err.error && err.error.errors && err.error.errors[0] && err.error.errors[0].message) {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: err.error.errors[0].message + '. Unable to import your forgiveness CSV. Please refresh the page to try again.'
                }));
                return EMPTY;
            }
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to import your forgiveness CSV. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            // Get Import Documents
            this.store.dispatch(new GetForgivenessDocuments());
            this.store.dispatch(new CreateNewAlert({
                level: 'success',
                message: 'Your import has been queued.'
            }));
        })).subscribe();
    }
}
