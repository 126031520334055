import { DarkLaunchOpportunitiesStateModel, OpportunitiesStateModel } from "./opportunities-state-model";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { OpportunitiesService } from "src/app/services/opportunities.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { ClearOpportunitiesStore, GetOpportunities, DarkLaunchGetOpportunities, ClearDarkLaunchOpportunities } from "./opportunities.actions";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { CreateNewAlert } from "src/app/store/global-alerts/global-alerts.actions";
import { OpportunityDetailsResponse } from "src/app/interfaces/opportunity-details-response.model"

@State<OpportunitiesStateModel>({
  name: 'opportunities',
  defaults: {
    opportunities: null,
    opportunitiesCached: false,
    opportunitiesLoading: false
  }
})
@Injectable()
export class OpportunitiesState {

  @Selector() static opportunities(state: OpportunitiesStateModel) {
    return state.opportunities;
  }

  @Selector() static darkLaunchOpportunities(state: DarkLaunchOpportunitiesStateModel) {
    return state.darkLaunchOpportunities;
  }

  @Selector() static loading(state: OpportunitiesStateModel) {
    return state.opportunitiesLoading;
  }

  constructor(
    private _opportunitiesService: OpportunitiesService,
    private _store: Store,
    private _eventService: EventBusService
  ) { }

  @Action(GetOpportunities)
  getOpportunities({getState, patchState}: StateContext<OpportunitiesStateModel>, {}: GetOpportunities) {
    if (!getState().opportunitiesCached) {
      patchState({opportunitiesLoading: true});
      return this._opportunitiesService.getOpportunities().pipe(
        catchError((err: any) => {
          this._store.dispatch(new CreateNewAlert({
            level: 'error',
            message: 'Unable to retrieve borrower deals.'
          }))
          return throwError(err);
        }),
        tap({
          next: (response: OpportunityDetailsResponse) => {
            patchState({
              opportunities: response.data,
              opportunitiesCached: true,
            })
          },
          error: (err: any) => console.error(err.message || err),
          complete: () => {
            patchState({opportunitiesLoading: false})
          }
        })
      )
    } else {
      return getState().opportunities;
    }
  }

  @Action(ClearOpportunitiesStore)
  clearOpportunitiesStore({patchState}: StateContext<OpportunitiesStateModel>, {}: ClearOpportunitiesStore) {
    patchState({
      opportunities: null,
      opportunitiesCached: false,
    });
  }

  @Action(ClearDarkLaunchOpportunities)
  clearDarkLaunchOpportunities({patchState}: StateContext<DarkLaunchOpportunitiesStateModel>, {}: ClearDarkLaunchOpportunities) {
    patchState({
      darkLaunchOpportunities: null,
      darkLaunchOpportunitiesCached: false,
    });
  }

  @Action(DarkLaunchGetOpportunities)
  getDarkLaunchOpportunities({getState, patchState}: StateContext<DarkLaunchOpportunitiesStateModel>, {}: DarkLaunchGetOpportunities) {
    if (!getState().darkLaunchOpportunitiesCached) {
      patchState({darkLaunchOpportunitiesLoading: true});
      return this._opportunitiesService.darkLaunchGetOpportunities().pipe(
        catchError((err: any) => {
          this._store.dispatch(new CreateNewAlert({
            level: 'error',
            message: 'Unable to retrieve borrower deals.'
          }))
          return throwError(err);
        }),
        tap({
          next: (response: OpportunityDetailsResponse) => {
            patchState({
              darkLaunchOpportunities: response.data,
              darkLaunchOpportunitiesCached: true,
            })
          },
          error: (err: any) => console.error(err.message || err),
          complete: () => {
            patchState({darkLaunchOpportunitiesLoading: false})
          }
        })
      )
    } else {
      return getState().darkLaunchOpportunities;
    }
  }
}
