@if (teamMembersLoading$ | async) {
    <div class="mt-6" *ngIf="teamMembersLoading$ | async">
        <app-dot-loader-scale class="overlay-loader"></app-dot-loader-scale>
    </div>

} @else {
<div class="p-4" #containerRef>
    <mat-card appearance="outlined" class="!p-0">
        <mat-card-header class="grid grid-flow-col justify-between">
            <mat-card-title>Team members</mat-card-title>
            <button mat-flat-button
                    color="primary"
                    [disabled]="teamMembersLoading$ | async"
                    (click)="addTeamMember()">
                Add team member
            </button>

        </mat-card-header>
        <mat-card-content>
            <table
                *ngIf="dataSource$ | async as dataSource"
                mat-table
                matSort
                [dataSource]="dataSource"
            >
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <ng-container matColumnDef="name">
                    <mat-header-cell class="grow-0 basis-[15%]" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                    <mat-cell class="grow-0 basis-[15%]" *matCellDef="let lenderUser;"> {{ lenderUser?.fullName }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Email address </mat-header-cell>
                    <mat-cell *matCellDef="let lenderUser;"> {{ lenderUser?.user?.email }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="roles">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Roles </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let lenderUser;">
                        <span *ngIf="lenderUser.hasAdminRole else nonAdmin">Administrator</span>
                        <ng-template #nonAdmin>
                            <div class="whitespace-nowrap"
                                 matTooltip="{{ lenderUser.rolesCsv?.length >= 40 ? lenderUser.rolesCsv : '' }}"
                            >
                                {{ lenderUser.rolesCsv | truncate: 40 }}
                            </div>
                        </ng-template>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastActive">
                    <mat-header-cell
                        mat-header-cell
                        mat-sort-header
                        *matHeaderCellDef> Last active </mat-header-cell>
                    <mat-cell
                        *matCellDef="let lenderUser;">
                        <div *ngIf="!!lenderUser?.user?.last_login && lenderUser?.user?.last_login !== '0000-00-00 00:00:00' else noLoginDate">
                            <strong>{{ lenderUser?.user?.last_login | timeago }}</strong>
                            <div class="text-gray-400 text-xs whitespace-nowrap line-clamp-1">{{ lenderUser?.user?.last_login | date: 'MMMM d, y \'at\' h:mma' }}</div>
                        </div>
                        <ng-template #noLoginDate>
                            <span class="text-gray-400">&#8211;</span>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell
                        class="grow-0 basis-[8%]"
                        mat-header-cell
                        *matHeaderCellDef></mat-header-cell>
                    <mat-cell
                        mat-cell
                        class="grow-0 basis-[8%]"
                        *matCellDef="let lenderUser;">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="userActionsMenu">
                            <mat-icon class="text-gray-400">more_horiz</mat-icon>
                        </button>
                        <mat-menu #userActionsMenu="matMenu">
                            <button mat-menu-item (click)="editUser(lenderUser)">
                                <mat-icon>edit_outline</mat-icon>
                                Edit Team Member
                            </button>
                            <button mat-menu-item (click)="deleteUser(lenderUser)">
                                <mat-icon>delete_outline</mat-icon>
                                Delete Team Member
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

            </table>

        </mat-card-content>
    </mat-card>
</div>
}
