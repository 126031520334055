import { Injectable } from "@angular/core";
import { Esigner } from "@app/app/interfaces/esigner.model";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { GetStatusesForSigners } from "./esignature.actions";
import { EsignatureService } from "@app/app/services/esignature.service";
import { catchError, tap, throwError } from "rxjs";
import { CreateNewAlert } from "../global-alerts/global-alerts.actions";

export class  EsignatureStateModel {
    esigners: Esigner[];
    hasTemplate: boolean;
}

@State<EsignatureStateModel>({
    name: 'esigners',
    defaults: {
        esigners: [],
        hasTemplate: false
    }
})
@Injectable()
export class EsignatureState {

    constructor(
        private esignatureService: EsignatureService,
        private store: Store
    ) {}

    @Selector()
    static signingStatuses(state: EsignatureStateModel) {
        return state.esigners;
    }

    @Selector()
    static contractSent(state: EsignatureStateModel) {
        return state.esigners.length > 0;
    }

    @Selector()
    static hasTemplate(state: EsignatureStateModel) {
        return state.hasTemplate;
    }
    
    @Action(GetStatusesForSigners)
    getStatusesForSigners({ patchState }: StateContext<EsignatureStateModel>, { dealId }: GetStatusesForSigners) {
        return this.esignatureService.getEnvelopeSigners(dealId).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve esignature statuses for this deal. Please refresh the page to try again.'
            }));
            return [];
        }), tap(response => {
            const esigners = response.data.signers.map(x => ({
                name: x.name,
                email: x.email,
                hasSigned: x.status === 'completed',
                dateSent: new Date(x.sentDateTime),
                dateSigned: x.signedDateTime ? new Date(x.signedDateTime): null,
            }))
            const hasTemplate = response.data.hasTemplate ?? false;
            patchState({ esigners: esigners, hasTemplate });
        }));
    }
}