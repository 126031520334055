import { BusinessesListStateModel } from "./businesses-list.state";

export class FetchWithUpdatedParams {
  static readonly type = '[BusinessesList] FetchWithUpdatedParams';
  constructor(
    public paramChanges: Partial<BusinessesListStateModel>,
    public shouldReloadOnFetch: boolean,
    ) {}
}

export class GetBusinessCount {
  static readonly type = '[BusinessesList] GetBusinessesCount';
  constructor(public filterBy?: string) {}
}

export class RefreshBusinesses {
  static readonly type = '[Refresh Business List] Refresh Businesses'
  constructor(public force: boolean = false) {}
}

export class SetPageIndex {
  static readonly type = '[BusinessesList] SetPageIndex';
  constructor(public pageIndex: number) {}
}

export class SetCurrentBusiness {
  static readonly type = '[BusinessesList] Set Current Business'
  constructor(public id: number) {}
}

export class AddBusiness {
  static readonly type = '[Add Business Dialog] Add Business';
  constructor(public borrower: Object) {}
}
