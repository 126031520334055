<div class="font-bold mb-2">{{chartData.name}}</div>
<mat-card appearance="outlined" class="!px-0 !py-0 h-64 overflow-hidden" (mouseenter)="overlay = false"  (mouseleave)="overlay = true">
    <!-- Relative positioned catches/sizes the absolute positioned -->
    <div class="relative h-full w-full">
        <!-- Chart Overlay -->
        <div 
            *ngIf="overlay"
            [@fadeEaseInOut]
            class="absolute flex flex-wrap justify-around inset-0 z-10 py-6 px-12"
        >
            <div class="flex pr-2">
                <div class="h-12">
                    <span class="text-bold text-6xl leading-tight">{{ chartData.latest.value | number:'1.0-1' }}</span>
                    <span class="text-sm">{{ chartData.suffix }}</span>
                </div>
                <!-- <div class="ml-8 flex flex-col items-center"
                    *ngIf="chartData.change && chartData.change < 15 && chartData.change !== 0"
                >
                    <mat-icon *ngIf="chartData.change > 0" class="arrow-icon text-red-500">
                        arrow_drop_up
                    </mat-icon>
                    <span class="leading-none">
                        {{ chartData.change | number:'1.0-1' }}%
                    </span>
                    <mat-icon *ngIf="chartData.change < 0" class="-mt-1 arrow-icon text-green-500">
                        arrow_drop_down
                    </mat-icon>
                </div> -->
            </div>
            <div *ngIf="chartData.average" class="truncate mb-2 text-gray-700 text-right font-normal pt-10">
                <span class="text-xl">{{ chartData.average | number:'1.0-1' }}</span> {{ chartData.suffix }} competitive avg.
            </div>
        </div>

        <!-- Chart must be in absolute positioned element to use grids -->
        <div class="absolute inset-0 mr-3 mb-2">
            <ngx-charts-line-chart
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [showGridLines]="showGridLines"
                [showRefLabels]="showRefLabels"
                [showRefLines]="showRefLines"
                [referenceLines]="[{'name': 'Average', 'value': chartData.average}]"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [yScaleMin]="yScaleMin"
                [yScaleMax]="yScaleMax"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [timeline]="timeline" 
                [scheme]="colorScheme"
                [results]="chartData.ngxChartData"
                [curve]="curve"
            >
                <ng-template #seriesTooltipTemplate let-model="model">
                    <div class="p-2">
                        <div class="leading-tight">{{ model && model[0] && model[0].name | toDateObj | date:'shortDate' }}</div>
                        <div class="leading-tight">
                            <span class="font-bold">{{ model && model[0] && model[0].value | number:'1.0-1' }}</span>
                            {{ chartData.suffix }}
                        </div>
                    </div>
                </ng-template>
            </ngx-charts-line-chart>
        </div>
    </div>
</mat-card>

<!-- <pre>{{ chartData | json }}</pre> -->
