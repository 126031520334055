import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DecisioningState } from '@app/app/store/decisioning/decisioning.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { DecisioningBatch } from '@app/app/interfaces/decisioning-batch.model';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
import { BusinessesListState } from '@app/app/store/businesses/businesses-list.state';
import { FetchWithUpdatedParams } from '@app/app/store/businesses/businesses-list.actions';
import { DecisioningResult } from '@app/app/interfaces/decisioning-result.model';
import { first } from 'lodash';

@Component({
  selector: 'app-decisioning-snackbar',
  templateUrl: './decisioning-snackbar.component.html',
  styleUrls: ['./decisioning-snackbar.component.scss']
})
export class DecisioningSnackbarComponent implements OnInit, OnDestroy {
  _snackBarRef = inject(MatSnackBarRef);
  destroyed$ = new Subject<boolean>();

  completionPercent = 0;
  decisioningCount = 0;
  currentBatchId: number;
  canDismiss = false;
  status = 'inProgress'
  prefixBackgroundColor = '#2175EB';
  prefixIcon = 'fork_right';
  title = 'Decisioning in progress (' + this.completionPercent + '%)';
  message = 'Running decisioning on ' + this.decisioningCount + ' business' + (this.decisioningCount > 1 ? 'es' : '') + '.<br/>We\'ll alert you once this is complete.';
  complete = false;
  filterVal: string = '';

  @Select(DecisioningState.decisioningProgress) decisioningProgress$: Observable<DecisioningBatch[]>;
  @Select(DecisioningState.decisioningReRun) decisioningReRun$: Observable<DecisioningResult[]>;
  @Select(DecisioningState.isDecisioningReRunQueued) isDecisioningReRunQueued$: Observable<boolean>;
  @Select(BusinessesListState.filterBy) filterBy$: Observable<string>;

  constructor(
    private _store: Store,
    private lendioSnackbarService: LendioSnackbarService
  ) { }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    // Get filterBy value to determine if we show the refresh or not.
    if (this.filterBy$) {
      this.filterBy$.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(f => {
        if (f) {
          this.filterVal = f;
        }
      });
    }

    this.handleDecisioningReRun();
    this.handleDecisioningBatch();
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  /**
   * @return void
   */
  refreshBusinesses(): void {
    this._store.dispatch(new FetchWithUpdatedParams({ filterBy: this.filterVal }, true));
    this.snackBarDismiss();
  }

  /**
   * @return void
   */
  snackBarDismiss(): void {
    this.lendioSnackbarService.dismiss();
    const dismissList = JSON.parse(localStorage.getItem('DismissDecisioningSnackbarList') || '[]');
    dismissList.push(this.currentBatchId);
    const newDismissList = JSON.stringify(dismissList);
    localStorage.setItem('DismissDecisioningSnackbarList', newDismissList);
  }

  /**
   * @return boolean
   */
  get showRefreshBusinesses(): boolean {
    return (
      window.location.pathname === '/businesses'
      && this.filterVal != ''
      && this.complete);
  }

  /**
   * Decisioning batch snackbar content
   * @return void
   */
  handleDecisioningBatch(): void {
    this.decisioningProgress$.pipe(takeUntil(this.destroyed$)).subscribe((decisioningProgress: DecisioningBatch[]) => {
      const decisioningProgressForSort = [...decisioningProgress];
      const mostRecentDecisioningBatch = decisioningProgressForSort.sort(({ id: a }, { id: b }) => b - a)[0];
      // If no batch, get out
      if (!mostRecentDecisioningBatch) {
        return;
      }

      // If total is less than 1, get out
      if (mostRecentDecisioningBatch.total < 1) {
        this.lendioSnackbarService.dismiss();
        return;
      }
      this.currentBatchId = mostRecentDecisioningBatch.id;
      this.decisioningCount = mostRecentDecisioningBatch.total;
      this.completionPercent = Math.trunc(mostRecentDecisioningBatch.total ? ((mostRecentDecisioningBatch.totalSucceeded + mostRecentDecisioningBatch.totalFailed) / mostRecentDecisioningBatch.total) * 100 : 0);
      this.status = this.completionPercent < 100 ? 'inProgress'
        : mostRecentDecisioningBatch.totalFailed > 0 ? 'errors'
          : 'complete';
      switch (this.status) {
        case 'inProgress':
          this.prefixBackgroundColor = '#2175EB';
          this.prefixIcon = 'fork_right';
          this.title = 'Decisioning in progress (' + this.completionPercent + '%)';
          this.message = 'Running decisioning on ' + this.decisioningCount + ' business' + (this.decisioningCount > 1 ? 'es' : '') + '. We\'ll alert you once this is complete.';
          this.canDismiss = false;
          break;
        case 'complete':
          this.prefixBackgroundColor = '#1DCC1D';
          this.prefixIcon = 'check';
          this.title = 'Decisioning complete';
          this.message = 'Decisioning on ' + this.decisioningCount + ' business' + (this.decisioningCount > 1 ? 'es' : '') + ' has finished running with no errors.';
          this.canDismiss = true;
          this.complete = true;
          break;
        case 'errors':
          this.prefixBackgroundColor = '#EB8621';
          this.prefixIcon = 'warning_amber';
          this.title = 'Decisioning complete with errors';
          this.message = 'Decisioning has finished running but has found errors. Please reach out to your Lendio contact for support.';
          this.canDismiss = true;
          break;
      }
    });
  }

  /**
   * Decisioning re-run snackbar content
   * @return void
   */
  handleDecisioningReRun(): void {
    this.decisioningReRun$.pipe(takeUntil(this.destroyed$)).subscribe((decisioningResult: DecisioningResult[]) => {
      catchError(() => {
        return of();
      });

      this.isDecisioningReRunQueued$.pipe(takeUntil(this.destroyed$)).subscribe((isReRunQueued) => {
        // If no result, let's prompt the user that the job has been queued
        if (isReRunQueued && !decisioningResult.length) {
          this.prefixBackgroundColor = '#2175EB';
          this.prefixIcon = 'fork_right';
          this.completionPercent = 25;
          this.title = 'Overrides submitted';
          this.message = 'Queueing for decisioning...';
          this.canDismiss = false;
        }
      });

      const result = first(decisioningResult);
      // As the decisioningResult could possibly be undefined... thanks lodash
      if (result) {
        const type = result.decisioningTypeId == 1 ? 'Preapproval' : 'Approval';
        // Started
        if (result.decisioningStatusId == 1) {
          this.prefixBackgroundColor = '#2175EB';
          this.prefixIcon = 'fork_right';
          this.completionPercent = 50;
          this.title = 'Decisioning in progress';
          this.message = 'Decisioning based on new qualifiers for ' + type + '.';
          this.canDismiss = false;
        }

        // Complete
        if (result.decisioningStatusId == 2) {
          this.prefixBackgroundColor = '#1DCC1D';
          this.prefixIcon = 'check';
          this.completionPercent = 100;
          this.title = 'Decisioning complete';
          this.message = 'Decisioning has finished for ' + type + '.';
          this.canDismiss = true;
          this.complete = true;
        }

        // Failed
        if (result.decisioningStatusId == 3) {
          this.prefixBackgroundColor = '#EB8621';
          this.prefixIcon = 'warning_amber';
          this.completionPercent = 100;
          this.title = 'Decisioning complete with errors';
          this.message = 'Decisioning has finished running for ' + type + ' but has found errors. Please reach out to your Lendio contact for support.';
          this.canDismiss = true;
        }
      }
    })
  }
}
