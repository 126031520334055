import { Component, OnInit, Inject, computed } from '@angular/core';
import { PortalUsersState } from 'src/app/store/portal-users/portal-users-state.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PortalUser } from 'src/app/interfaces/portal-user.model';
import { NewPortalUser } from 'src/app/interfaces/new-portal-user.model';
import { debounceTime, take } from 'rxjs/operators';
import { CreateNewAlert } from 'src/app/store/global-alerts/global-alerts.actions';
import { CreatePortalUser } from 'src/app/store/portal-users/portal-users.actions';
import { UpdatePortalUser } from 'src/app/store/portal-users/portal-users.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';

@Component({
  templateUrl: './edit-user-dialog.component.html',
})
export class EditUserDialogComponent implements OnInit {
  newThemeEnabled = computed(() => this._themeService.newThemeEnabled());
  portalUsers$: Observable<PortalUser[] | null> = this.store.select(PortalUsersState.portalUsers);

  portalUser = {
    first: '',
    last: '',
    email: '',
    manageLenderUsers: false,
    manageStyles: false,
    manageLegal: false,
    manageWebhooks: false,
  };

  currentPortalUser: AuthUser | null;
  portalUserForm: FormGroup;
  isEmbedded: boolean;
  state: string;
  isDupeUser: boolean | undefined = false;
  componentValues: {
    title: string,
    submit: string
  }

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    private _themeService: LendioAngularMaterialThemeService,
    @Inject(MAT_DIALOG_DATA) public data: { portalUser: PortalUser | null }
  ) {
    this.state = 'create';
  }

  ngOnInit(): void {
    if (this.data.portalUser) {
      this.state = 'update';
      this.portalUser = {
        first: this.data.portalUser.first,
        last: this.data.portalUser.last,
        email: this.data.portalUser.user.email,
        manageLenderUsers: this.data.portalUser.permissions ? this.data.portalUser.permissions.includes('lpxManageLenderUsers') : false,
        manageStyles: this.data.portalUser.permissions ? this.data.portalUser.permissions.includes('embeddedConfig.write') : false,
        manageLegal: this.data.portalUser.permissions ? this.data.portalUser.permissions.includes('embeddedLegal.write') : false,
        manageWebhooks: this.data.portalUser.permissions ? this.data.portalUser.permissions.includes('embeddedWebhooks.write') : false,
      };
    }
    this.portalUserForm = new FormGroup ({
      first: new FormControl(this.portalUser.first, [
        Validators.required,
      ]),
      last: new FormControl(this.portalUser.last, [
        Validators.required,
      ]),
      email: new FormControl(this.portalUser.email, {
        validators: [
          Validators.required,
        ],
        updateOn: 'blur'
      }),
      manageLenderUsers: new FormControl(this.portalUser.manageLenderUsers, [
        Validators.required,
      ]),
      manageStyles: new FormControl(this.portalUser.manageStyles, [
        Validators.required,
      ]),
      manageLegal: new FormControl(this.portalUser.manageLegal, [
        Validators.required,
      ]),
      manageWebhooks: new FormControl(this.portalUser.manageWebhooks, [
        Validators.required,
      ]),
    });

    this.portalUserForm.valueChanges.pipe(
      debounceTime(400)
    ).subscribe(value => this.onValueChanges(value));

    this.currentPortalUser = this.store.selectSnapshot(AuthState.user);
    this.isEmbedded = !!this.currentPortalUser?.hasEmbeddedChildAffiliate();
    this.setComponentStateValues();
  }

  handleSubmit() {
    if (this.state === 'update') {
      this.updateUser()
    } else {
      this.addNewUser()
    }
  }

  addNewUser() {
    this.portalUserForm.value.institutionId = this.currentPortalUser!.institution!.id;
    this.portalUserForm.value.fullName = this.portalUserForm.value.first + ' ' + this.portalUserForm.value.last;
    if (!this.checkForDuplicate(this.portalUserForm.value)) {
      this.store.dispatch(new CreatePortalUser(this.portalUserForm.value));
      this.close();
    }
  }

  checkForDuplicate(newPortalUser: NewPortalUser) {
    let currentPortalUsers: PortalUser[] | null;
    this.portalUsers$.pipe(take(1)).subscribe(lenderUsers => { currentPortalUsers = lenderUsers; });
    const duplicate = currentPortalUsers!.findIndex(lu => lu.user.email === newPortalUser.email);
    if (duplicate !== -1) {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Duplicate. A user with this email address already exists.'
        }));
        this.close();
        return true;
    }
    return false;
  }

  setComponentStateValues () {
    if (this.state === 'update') {
      this.componentValues = {
        title: 'Update User',
        submit: 'Update User'
      }
    } else {
      this.componentValues = {
        title: 'Add New User',
        submit: 'Create User'
      }
    }
  }

  updateUser() {
    if (this.data.portalUser) {
      this.store.dispatch(new UpdatePortalUser(this.data.portalUser.id, this.portalUserForm.value));
    }

    this.close();
  }

  checkError(controlName: string, errorName: string) {
    return this.portalUserForm.controls[controlName].hasError(errorName);
  }

  close() {
    this.dialogRef.close();
  }

  onValueChanges(data?: any) {
    if (data?.email && this.portalUserForm.controls['email'].dirty && this.portalUserForm.controls['email'].touched) {
      this.checkForDupeEmailFromState(data)
    }
    return;
  }

  checkForDupeEmailFromState(data?: any) {
    const portalUsers = this.store.selectSnapshot(PortalUsersState.portalUsers);
      this.isDupeUser = portalUsers?.some((user: any) => {
        if (user.user.email === data.email) {
          this.portalUserForm.controls['email'].setErrors({invalid: true});
          return true;
        }
      })
  }
}
