import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular'
import { CellMouseOutEvent, CellMouseOverEvent, IHeaderParams } from 'ag-grid-community'
import { CommonModule } from '@angular/common';

export interface ICustomHeaderParams {
  menuIcon: string;
}

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})
export class ColumnHeaderComponent implements IHeaderAngularComp {
  @ViewChild('filterButton', { read: ElementRef }) public filterButton!: ElementRef;
  @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;
  @ViewChild('label', { read: ElementRef }) public label!: ElementRef;

  public params!: IHeaderParams & ICustomHeaderParams;
  public ascSort = false;
  public descSort = false;
  public noSort = false;
  public columnHover = false;
  public columnMenuVisible = false;
  public columnSortActive = false;
  public columnFilterActive = false;
  public get requestSort(): 'asc' | 'desc' | null {
    if (this.noSort) {
      return 'asc';
    } else if (this.ascSort) {
      return 'desc';
    } else {
      return null;
    }
  }

  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    params.api.addEventListener('cellMouseOut', this.onCellMouseOut.bind(this))
    params.api.addEventListener('cellMouseOver', this.onCellMouseOver.bind(this));
    params.column.addEventListener('filterChanged', this.onFilterChanged.bind(this));
    params.column.addEventListener('menuVisibleChanged', this.onMenuVisibleChanged.bind(this));
    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }

  onCellMouseOut(event: CellMouseOutEvent): void {
    if (event.column.getColId() === this.params.column.getColId()) {
      this.columnHover = false;
    }
  }

  onCellMouseOver(event: CellMouseOverEvent): void {
    if (event.column.getColId() === this.params.column.getColId()) {
      this.columnHover = true;
    }
  }

  onFilterChanged(): void {
    this.columnFilterActive = this.params.column.isFilterActive();
  }

  onFilterMenuClick(): void {
    this.params.showFilter(this.filterButton.nativeElement);
  }

  onMenuClicked(): void {
    this.params.showColumnMenu(this.menuButton.nativeElement);
    this.menuButton.nativeElement.ariaHidden = false;
  }

  onMenuVisibleChanged(event): void {
    this.columnMenuVisible = event.column.isMenuVisible();
  }

  onSortChanged(): void {
    this.columnSortActive = this.params.column.isSorting();
    this.ascSort = this.descSort = this.noSort = false;
    if (this.params.column.isSortAscending()) {
      this.ascSort = true;
    } else if (this.params.column.isSortDescending()) {
      this.descSort = true;
    } else {
      this.noSort = true;
    }
  }

  onSortRequested(sortDirection: 'asc' | 'desc' | null, event): void {
    this.params.setSort(sortDirection, event.shiftKey);
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }
}
