import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'camelToKebab'
})
export class CamelToKebabPipe implements PipeTransform {
  transform(value: string): string {
    const initialLowerCase = value.charAt(0).toLowerCase() + value.slice(1);
    return initialLowerCase.replace(/([A-Z])/g, '-$1').toLowerCase();
  }
}
