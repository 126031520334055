import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective } from '@app/app/directives/let/let.directive';

@NgModule({
  declarations: [
    LetDirective,
  ],
  imports: [CommonModule],
  exports: [
    LetDirective,
  ]
})
export class LetDirectiveModule { }
