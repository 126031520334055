import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardSmartComponent } from './dashboard-smart/dashboard-smart.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SmallLineChartComponent } from './small-line-chart/small-line-chart.component';
import { MediumLineChartComponent } from './medium-line-chart/medium-line-chart.component';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { AwaitingResponseTableComponent } from './awaiting-response-table/awaiting-response-table.component';
import { DealTableComponent } from './deal-table/deal-table.component';
import { ToDateObjPipeModule } from 'src/app/pipes/toDateObj/to-date-obj-pipe.module';
import { LoadersModule } from "@app/app/components/loaders/loaders.module";
import { ThoughtspotLiveboardComponent } from 'src/app/components/thoughtspot-liveboard/thoughtspot-liveboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardSmartComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    DashboardSmartComponent,
    DashboardComponent,
    SmallLineChartComponent,
    MediumLineChartComponent,
    AwaitingResponseTableComponent,
    DealTableComponent
  ],
    imports: [
      CommonModule,
      MatCardModule,
      MatIconModule,
      MatTableModule,
      LoadersModule,
      NgxChartsModule,
      RouterModule.forChild(routes),
      ToDateObjPipeModule,
      ThoughtspotLiveboardComponent
    ],
  exports: [
    DashboardSmartComponent,
    RouterModule
  ]
})
export class DashboardModule { }
