import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { SaasFeaturesState } from "../store/saas-features/saas-features.state";

@Injectable({
  providedIn: 'root'
})
export class RedirectionGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    const state = this.store.selectSnapshot(SaasFeaturesState.featuresConfig).filter((feature) => feature.permitted);
    const queryParams = route.queryParams;
    const redirectPath = state[0]?.link || '/dashboard';
    this.router.navigate([redirectPath], { queryParams });
    return false;
  }
}
