import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, map, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { StatusOption } from '@app/app/interfaces/status-option';
import { InactiveReason } from '@app/app/interfaces/inactive-reason.model';
import { ApplicationDetailsState } from '@app/app/store/application-details/application-details.state';
import { ApplicationDetails } from '../../../interfaces/application-details.model';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DealStage } from '../deal-stage.enum';
import {
  LendioStatusDisplayComponent
} from '@app/app/components/lendio-angular-material-theme/lendio-status-display/lendio-status-display.component';
import { Offer } from '@app/app/interfaces/offer.model';
import { MatIconModule } from "@angular/material/icon";
import { ShowAanService } from '../../deal-sidebar/show-aan-service';

export interface DealStatusChangeDialogData {
  readonly currentStatus: Readonly<StatusOption>;
  readonly newStatus: Readonly<StatusOption>;
  // Filtered reasons list that apply to the new status.
  readonly inactiveReasons$: Observable<InactiveReason[]>;
  readonly acceptedOffer?: Partial<Offer>;
}

export interface DealStatusChangeDialogOutput {
  // Comma-separated list of reason(s) selected from dropdown.
  reason: string;
  // User-provided reason from free-form text field.
  otherReason: string;
}

@Component({
  selector: 'app-deal-status-change-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    LendioStatusDisplayComponent,
    MatIconModule,
  ],
  templateUrl: './deal-status-change-dialog.component.html',
  styleUrls: ['./deal-status-change-dialog.component.scss'],
})
export class DealStatusChangeDialogComponent {
  @Output() saveChangesClicked =
    new EventEmitter<DealStatusChangeDialogOutput>();
  @Output() openAANReview = new EventEmitter<void>();
  @Select( ApplicationDetailsState.applicationDetails ) applicationDetails$: Observable<ApplicationDetails>;
  @Select(SaasFeaturesState.saasPermitted('canViewAANDialog')) canViewAANDialog$: Observable<boolean>;

  // Whether the "Save changes" button was clicked.
  protected _isSaving = false;
  protected readonly _DealStage = DealStage;
  public inactiveStatusChangeForm = new FormGroup({
    inactiveReason: new FormControl('', [Validators.required]),
    inactiveReasonDetail: new FormControl('', [Validators.required])
  })
  showAAN$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly _dialogData: DealStatusChangeDialogData,
    private showAanService: ShowAanService,
    public dialogRef: MatDialogRef<DealStatusChangeDialogComponent>,
  ) { 
    this.showAAN$ = combineLatest([
      this.canViewAANDialog$,
      this.applicationDetails$,
    ]).pipe(
      takeUntilDestroyed(),
      map(([
        canViewAANDialog,
        applicationDetails,
      ]) => {
        return canViewAANDialog
          && !applicationDetails.deal?.hasAdverseActionNotice
      }),
    );
  }

  get inactiveReasonControl(): AbstractControl {
    return this.inactiveStatusChangeForm.get('inactiveReason')!;
  }

  get inactiveReasonDetailControl(): AbstractControl {
    return this.inactiveStatusChangeForm.get('inactiveReasonDetail')!;
  }

  get inactiveReason(): string {
    return this.inactiveReasonControl.value;
  }

  get inactiveReasonDetail(): string {
    return this.inactiveReasonDetailControl.value;
  }

  get invalidInactiveStatusChange(): boolean {
    return this.inactiveStatusChangeForm.invalid && !this._dialogData.acceptedOffer;

  }

  protected _handleSaveChanges(): void {
    this._isSaving = true;
    this.saveChangesClicked.emit({
      reason: this.inactiveReason,
      otherReason: this.inactiveReasonDetail
    });
  }

  protected _handleSaveAndSendAAN(): void {
    this._handleSaveChanges();
    this.showAanService.showAanDialog.emit(true);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
