<div class="loading-overlay" *ngIf="(loading$ | async)">
    <app-dot-loader-scale></app-dot-loader-scale>
</div>
<div class="p-4 new-theme-typography"
    appDynamicScreenHeight
    *appLet="(personalCredit$ | async) as creditReport">
    <mat-card appearance="outlined" class="h-full">
        <mat-card-content>
            <mat-tab-group mat-stretch-tabs="false"
                        mat-align-tabs="start"
                        animationDuration="0"
                        class="lendio-secondary-tabs !p-0 h-full !grid grid-rows-[auto_1fr]">

                <!--    BUSINESS FINANCES    -->
                <mat-tab *ngIf="showCommercial" label="Business finances" [bodyClass]="'pt-4 h-full'">
                    <div [ngClass]="{'opacity-[.175]': loading$ | async}">
                        <div class="grid grid-cols-[1fr_450px_1fr] gap-4">
                            <mat-card appearance="outlined">
                                <mat-card-header>
                                    <mat-card-title class="!pl-0">Performance</mat-card-title>
                                </mat-card-header>
                                <div class="lendio-mat-card-divider"></div>
                                <mat-card-content>
                                    <app-business-values [values]="performanceValues$ | async"></app-business-values>
                                </mat-card-content>
                            </mat-card>
                            <div class="grid grid-rows-[auto_1fr]">
                                <mat-card appearance="outlined"
                                        class="mb-4 h-[280px]">
                                    <mat-card-header>
                                        <mat-card-title class="!pl-0">Business credit score</mat-card-title>
                                    </mat-card-header>
                                    <div class="lendio-mat-card-divider"></div>
                                    <mat-card-content>
                                    <app-credit-score-display
                                        class="h-full grid"
                                        [type]="'commercial'"
                                        [borrowerId]="(primaryContact$ | async)?.borrowerId">
                                    </app-credit-score-display>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card appearance="outlined" *appLet="(commercialCredit$ | async) as commercialCredit"
                                          class="mat-body-2"
                                          [ngClass]="{'opacity-[.175]': commercialCredit?.bankruptcy?.bankruptcyStatus === null}">
                                    <mat-card-header>
                                        <mat-card-title class="!pl-0">Bankruptcy</mat-card-title>
                                    </mat-card-header>
                                    <div class="lendio-mat-card-divider"></div>
                                    <mat-card-content>
                                        <div>
                                            <div class="grid grid-flow-col align-middle justify-between border-bottom py-2">
                                                <span class="mat-label-medium">Bankruptcies in last 9 yrs</span>
                                                <span>
                                                    {{ commercialCredit?.bankruptcy?.bankruptcyInPastNineYears || 0 }}
                                                </span>
                                            </div>
                                            <div class="grid grid-flow-col align-middle justify-between border-bottom py-2">
                                                <span class="mat-label-medium">Chapter</span>
                                                <span>-</span>
                                            </div>
                                            <div class="grid grid-flow-col align-middle justify-between py-2">
                                                <span class="mat-label-medium">Discharge date</span>
                                                <span>-</span>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <mat-card appearance="outlined"
                                      [ngClass]="{'opacity-[.175]': !(commercialCredit$ | async)?.creditScore}">
                                <mat-card-header>
                                    <mat-card-title class="!pl-0">Credit details</mat-card-title>
                                </mat-card-header>
                                <div class="lendio-mat-card-divider"></div>
                                <mat-card-content>
                                    <app-credit-report
                                        [type]="'commercial'"
                                        [showEmptyState]="true">
                                    </app-credit-report>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <mat-card appearance="outlined"
                                [ngClass]="{'opacity-[.175]': !creditReport?.creditScore}"
                                class="mt-4">
                            <mat-card-header>
                                <mat-card-title class="!pl-0">Outstanding loans</mat-card-title>
                            </mat-card-header>
                            <div class="lendio-mat-card-divider"></div>
                            <mat-card-content>
                                <mat-table [dataSource]="outstandingLoansDataSource$ | async">
                                    <ng-container matColumnDef="position">
                                        <mat-header-cell *matHeaderCellDef class="justify-end grow-0 basis-[10%]"> Position </mat-header-cell>
                                        <mat-cell *matCellDef="let loan" class="justify-end grow-0 basis-[10%]"> {{ loan.position }} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="lenderName">
                                        <mat-header-cell *matHeaderCellDef class="basis-[30%]"> Lender name </mat-header-cell>
                                        <mat-cell *matCellDef="let loan" class="basis-[30%]"> {{ loan.lenderName }} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="balance">
                                        <mat-header-cell *matHeaderCellDef class="justify-end"> Balance </mat-header-cell>
                                        <mat-cell *matCellDef="let loan" class="justify-end"> {{ loan.balance | currency }} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="updatedDate">
                                        <mat-header-cell *matHeaderCellDef class="justify-end"> As of </mat-header-cell>
                                        <mat-cell *matCellDef="let loan" class="justify-end"> {{ loan.updatedDate | date: 'MMM dd, yyyy' }} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="paidOff">
                                        <mat-header-cell *matHeaderCellDef class="justify-end"> Paid off </mat-header-cell>
                                        <mat-cell *matCellDef="let loan" class="justify-end"> {{ loan.paidOff ? 'Yes' : 'No' }} </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="outstandingLoansTableColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: outstandingLoansTableColumns;"></mat-row>
                                </mat-table>
                                <div class="py-4 text-center font-normal">
                                    No data
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-tab>

                <!--    BUSINESS FINANCES    -->
                <mat-tab *appLet="(financesStateLoading$ | async) as financesLoading"
                        label="Owner finances" [bodyClass]="financesLoading ? 'pt-4 opacity-[.3]' : 'pt-4'">
                    @if ((ownerContacts$ | async)?.length) {
                        <div class="grid grid-cols-3 gap-4">
                            @for (contact of (ownerContacts$ | async); track contact) {
                                <mat-card appearance="outlined" class="h-fit">
                                    <mat-card-content>
                                        <div>{{contact.first}} {{contact.last}}</div>
                                        <div class="text-lendio-warm-gray-300 font-normal">{{percentOwnership(contact)}}% owner</div>
                                        <div class="lendio-mat-card-divider"></div>
                                        <app-credit-score-display
                                            class="h-full grid"
                                            [type]="'consumer'"
                                            [contactId]="contact.id"
                                            [borrowerId]="(primaryContact$ | async)?.borrowerId"></app-credit-score-display>
                                        <app-credit-report [contactId]="contact.id" [type]="'consumer'"></app-credit-report>
                                    </mat-card-content>
                                </mat-card>
                            }
                        </div>
                    } @else {
                        <app-empty-state label="Business has no Owners."></app-empty-state>
                    }
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
