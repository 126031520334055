<div class="status-indicator flex items-center" [class]="stage">
    <!-- Dot -->
    <svg
        class="ellipse"
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
    >
        <circle [class]="stage" cx="4" cy="4" r="4" />
    </svg>

    <!-- Stage -->
    <span class="stage font-semibold"> {{ stage | titlecase }}:&nbsp;</span>

    <!-- Status -->
    <span class="status font-normal">{{
        formatForDisplay(status) | titlecase
    }}</span>
</div>
