export class GetScope {
  static readonly type = '[Scopes] GetScope'
  constructor(
    public scopeAlias: string,
  ) {}
}

export class ClearScopeStore {
  static readonly type = '[Scopes] ClearScopeStore'
  constructor() {}
}
