@if (!hideEditModal) {
    <div class="flex flex-row justify-between items-center">
        <div mat-dialog-title>Add business</div>
        <button mat-icon-button
                (click)="cancel($event)" class="mr-6 mt-6">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="!pb-0">
        <form [formGroup]="addBusinessForm" class="lendio-density-1">
            <div class="grid grid-cols-2 grid-flow-col justify-start">
                <p class="text-lg font-semibold !mb-0 pb-4">Business info</p>
                <p class="text-lg font-semibold !mb-0 pb-4 pl-4">Owner & contact info</p>
            </div>
            <div class="grid grid-flow-col grid-cols-2 gap-4">
                <div class="flex flex-col grid-rows-5 gap-4 content-start">

                    <mat-form-field floatLabel="auto" appearance="outline" class="w-full">
                        <mat-label>Business Name</mat-label>
                        <input
                            formControlName="name"
                            matInput
                            type="text"
                        >
                        <mat-error *ngIf="addBusinessForm.controls['name'].errors && addBusinessForm.touched">Please enter a business name</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" appearance="outline" class="w-full">
                        <mat-label>Business start date</mat-label>
                        <input
                            matInput
                            formControlName="businessStartDate"
                            placeholder="Ex: MM/DD/YYYY"
                            [matDatepicker]="picker"
                        >
                        <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                        <mat-error *ngIf="businessStartDateControl?.invalid && (businessStartDateControl?.dirty || businessStartDateControl?.touched)">
                            Use MM/DD/YYYY format
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" appearance="outline" class="w-full">
                        <mat-label>Average monthly sales</mat-label>
                        <input
                            formControlName="average_monthly_sales"
                            type="text"
                            matInput
                            prefix="$"
                            mask="separator.2"
                            thousandSeparator=","
                        >
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" appearance="outline" class="w-full">
                        <mat-label>Industry</mat-label>
                        <input
                            formControlName="industry_naics"
                            matInput
                            autocomplete="off"
                            [matAutocomplete]="results"
                            (keyup)="search(); hasSearched = false;"
                            (focus)="hasSearched = false"
                        >
                        <mat-icon
                                matPrefix
                                class="!text-red-500"
                                *ngIf="errored"
                                matTooltip="There was an error while attempting to retrieve NAICS codes.">
                            warning
                        </mat-icon>
                        <button mat-icon-button matSuffix (click)="clearNaics()" hasSearched = false; *ngIf="addBusinessForm.controls['industry_naics'].value !== null">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-autocomplete #results="matAutocomplete">
                        <!-- Loading results -->
                        <mat-option disabled *ngIf="searching">
                            <app-dot-loader-scale></app-dot-loader-scale>
                        </mat-option>

                        <!-- We have results -->
                        <mat-option
                                *ngFor="let result of industries"
                                [matTooltip]="getExamples(result.examples)"
                                [matTooltipClass]="'leading-tight whitespace-pre-line'"
                                [matTooltipShowDelay]="400"
                                (click)="setCode(result.code, result.title)">
                            {{ result.code + ' - ' + result.title }}
                        </mat-option>

                        <!-- No results -->
                        @if (!searching && industries && industries.length === 0 && hasSearched) {
                        <mat-option [disabled]="true">
                            No results for '{{ searchText }}'
                        </mat-option>
                        }
                    </mat-autocomplete>

                    <p>* required field</p>
                </div>
                <div class="flex flex-col grid-rows-5 gap-4 content-start">
                    <mat-form-field floatLabel="auto" appearance="outline" class="w-full">
                        <mat-label class="">First name</mat-label>
                        <input
                            formControlName="first"
                            matInput
                            type="text"
                        >
                        <mat-error *ngIf="addBusinessForm.controls['first'].errors && addBusinessForm.touched">Please enter a first name</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" appearance="outline" class="w-full">
                        <mat-label>Last name</mat-label>
                        <input
                            formControlName="last"
                            matInput
                            type="text"
                        >
                        <mat-error *ngIf="addBusinessForm.controls['first'].errors && addBusinessForm.touched">Please enter a last name</mat-error>
                    </mat-form-field>

                    <span>
                        <mat-form-field floatLabel="auto" appearance="outline" class="w-1/2">
                            <mat-label>Title</mat-label>
                            <input
                                formControlName="title"
                                matInput
                                type="text"
                            >
                        </mat-form-field>


                        <mat-form-field floatLabel="auto" appearance="outline" class="w-1/2 pl-2">
                            <mat-label>Personal credit score</mat-label>
                            <input
                                formControlName="creditScore"
                                placeholder="Ex: 650"
                                matInput
                                type="text"
                            >
                            <mat-error *ngIf="addBusinessForm.controls['creditScore'].errors && addBusinessForm.touched">Please enter 3 digits</mat-error>
                        </mat-form-field>
                    </span>

                    <mat-form-field floatLabel="auto" appearance="outline" class="w-full">
                        <mat-label>Email address</mat-label>
                        <input
                            formControlName="email"
                            matInput
                            type="email"
                        >
                        <mat-error *ngIf="addBusinessForm.controls['email'].errors && addBusinessForm.touched">Please use this format: yourname&#64;company.com</mat-error>
                    </mat-form-field>
                    <span>
                        <mat-form-field floatLabel="auto" appearance="outline" class="w-1/2">
                            <mat-label>Phone number</mat-label>
                            <input
                                formControlName="phoneNumber"
                                matInput
                                type="text"
                                mask="(000) 000-0000"
                                placeholder="Ex: 1234567890"
                            >
                        <mat-error *ngIf="addBusinessForm.controls['phoneNumber'].errors && addBusinessForm.touched">Please enter 10 digits</mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="auto" appearance="outline" class="w-1/2 pl-2">
                            <mat-label class="!pb-4">Phone type</mat-label>
                            <mat-select
                                formControlName="phoneType"
                                mat-select
                            >
                                    <mat-option *ngFor="let type of phoneTypes" [value]="type.value">{{ type.key }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span>
                </div>
            </div>
        </form>
    </div>
    <mat-divider></mat-divider>
    <span class="flex justify-between">
        <div mat-dialog-actions [align]="'start'" class="!p-4">
            <button mat-stroked-button color="accent" (click)="navToImportTool()">Import tool</button>
        </div>
        <div mat-dialog-actions [align]="'end'" class="!p-4">
            <button mat-stroked-button color="accent" (click)="cancel($event)">Cancel</button>
            <button mat-flat-button (click)="addBusiness()" color="primary" [disabled]="addBusinessForm.invalid">Add</button>
        </div>
    </span>
}
