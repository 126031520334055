import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { Observable, combineLatest, filter, map, skipWhile, tap } from 'rxjs';
import { SaasFeature, SaasFeatureResponse } from '../interfaces/saas-feature.model';
import { Store } from '@ngxs/store';
import { SaasFeaturesState } from '../store/saas-features/saas-features.state';
import { LendioResponse, ObsLendioResponse } from "@app/app/interfaces/lendio-response";

@Injectable({
  providedIn: 'root'
})
export class SaasFeaturesService {

  private _lenderApiBaseUrl = `${environment.apiUrl}/l/v2/internal`;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
    ) { }

  getSaasRoles() {
    // temporary hard coded array of IDs - to be replaced by controller fetch
    return [
      225, // executive
      226, // operations lead
      227, // salesperson
      228, // processor
      231, // developer
      232, // administrator
    ]
  }


  getSaasFeatures(): Observable<SaasFeatureResponse> {
    return this.httpClient.get<SaasFeatureResponse>(`${this._lenderApiBaseUrl}/saas-features`);
  }

/**
 * Checks if a given SaaS feature alias and optional permission are present in the state.
 *
 * @param saasFeatureAlias - The alias of the SaaS feature to check.
 * @param permission - Optional. The name of the permission to check.
 *
 * @returns True if the SaaS feature alias (and permission, if provided) are present in the state, false otherwise.
 */
  saasPermitted(saasFeatureAlias: string, permission?: string) {
    const saasFeatures = this.store.selectSnapshot(SaasFeaturesState.saasFeatures);
    const saasFeaturePermissions = this.store.selectSnapshot(SaasFeaturesState.permissions);
    if(saasFeatures && saasFeaturePermissions) {
    if (permission) {
      return saasFeatures.some((saasFeature: SaasFeature) => {
          return saasFeature.alias === saasFeatureAlias;
        }) &&
        saasFeaturePermissions.some((saasPermission: any) => {
          return saasPermission.name === permission;
        });
    } else {
      return saasFeatures.some((saasFeature: SaasFeature) => {
        return saasFeature.alias === saasFeatureAlias;
      });
    }
  }
  return false;
  }
}


