import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FundingDeskRequest } from '../interfaces/funding-desk-request.model';
import { Observable } from 'rxjs';

@Injectable()
export class FundingDeskRequestService {

    private apiUrl = environment.apiUrl + '/l/v1/internal';

    constructor(private http: HttpClient) {}

    public getRequests(dealId: number): Observable<FundingDeskRequest[]> {
        return this.http.get<FundingDeskRequest[]>(`${this.apiUrl}/deals/${dealId}/tasks`);
    }

    public createNewRequest(dealId: number, description: string, type: string): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/deals/${dealId}/tasks`, { description, type });
    }

    public createNewComment(dealId: number, requestId: number, description: string): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/deals/${dealId}/tasks/${requestId}/comments`, { description });
    }
}
