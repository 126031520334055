<mat-card appearance="outlined" class="m-4">
    <mat-card-content>
        <div class="flex justify-between">
            <h1>Lender Forgiveness CSV Import</h1>

            <div class="flex">
                <app-import-button-smart scope="forgiveness" *ngIf="!(currentUser$ | async)?.permittedTo('canManageLoanForgivenessCSV')">
                </app-import-button-smart>
            </div>
        </div>

        <div class="flex flex-col w-full items-center py-12">
            <div class="text-gray-500 leading-none select-none" style="font-size: 5rem">
                <mat-icon [inline]="true">
                    cloud_upload
                </mat-icon>
            </div>

            <div class="flex flex-wrap justify-center">
                <div class="w-full">
                    <h2 class="mat-display-title text-center">
                        CSV Upload Instructions
                    </h2>
                </div>
                <div class="w-full sm:w-1/2 pl-6 p-4">
                    <ol class="list-decimal">
                        <li>Download the the loan forgiveness excel sheet. <a href="/assets/Loan_Forgiveness_CSV.xlsx" class="text-blue-500 hover:text-blue-700">Click to download.</a></li>
                        <li>Following the validation rules, add borrower data to the tables using the provided format.</li>
                        <li>Save the excel sheet as a CSV and click import to upload borrower data.</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap px-2 sm:px-4 overflow-hidden overflow-x-auto">
            <app-data-list-forgiveness-smart [title]="'Imported Files'" class="w-full flex-grow p-2 mb-4" [data]="(imports$ | async)"></app-data-list-forgiveness-smart>
        </div>
    </mat-card-content>
</mat-card>
