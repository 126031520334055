<div class="details !mt-6" *ngIf="applicationDetails">
	<app-alert
	*ngIf="isAan"
	[message]= "message"
	[level]= "level"
	[aanDate] = "aanDate"
	[isAan] = "isAan"
	>

  </app-alert>
	<div class="business-details" data-cy="ppp-business-details">
		<h4 class="table-pipeline-header">Business Details</h4>
		<div class="flex">
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">Business Name</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrower.name">{{ borrower.name }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Business Primary Address</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrower.street">{{ borrower.street }}<br /></span>
							<span *ngIf="borrower.street2">{{ borrower.street2 }}<br /></span>
							<span *ngIf="borrower.city">{{ borrower.city }}, </span><span *ngIf="borrower.stateId">{{ borrower.stateId }} </span><span *ngIf="borrower.zipId">{{ borrower.zipId }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">DBA Name</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValues[226]">{{ borrowerValues[226].value }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Business TIN (EIN, SSN)</td>
						<td class="table-pipeline-value tax-id">
							<span *ngIf="borrowerValues[236]">{{ borrowerValues[236].value }}</span>
						</td>
					</tr>
				</table>
			</div>
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">Business Type</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValues[863]">{{ borrowerValues[863].valueLabel }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Business Phone</td>
						<td class="table-pipeline-value">
							<span *ngIf="primaryContact.workPhone">{{ formattedPhone(primaryContact.workPhone) }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Email Address</td>
						<td class="table-pipeline-value">
							<span *ngIf="primaryContact.email">{{ primaryContact.email }}</span>
						</td>
					</tr>
					<tr class="table-row" *ngIf="borrowerValues[878] && borrowerValues[878].value == true">
						<td class="table-pipeline-item">SBA Franchise ID</td>
						<td class="table-pipeline-value">{{ borrowerValues[894] ? borrowerValues[894].value : 'None' }}</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">NAICS Code</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValues[670]">{{ borrowerValues[670].value }}</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>

	<div class="financial-details" data-cy="ppp-financial-details">
		<h4 class="table-pipeline-header">Financial Details</h4>
		<div class="flex">
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">Average Monthly Payroll</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValues[862]">{{ borrowerValues[862].value | currency }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Requested Loan Amount</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValues[866]">{{ borrowerValues[866].value | currency }}</span>
						</td>
					</tr>
					<tr class="table-row" *ngIf="loanProductCategory === 'SBA PPP' && borrowerValuesKeyed.eidlGrant && borrowerValuesKeyed.eidlGrant.value > 0">
						<td class="table-pipeline-item">EIDL Loan Number</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValuesKeyed.eidlLoanNumber">
								{{ borrowerValuesKeyed.eidlLoanNumber.value }}
							</span>
						</td>
					</tr>
				</table>
			</div>
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">Number of Employees</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValues[658]">{{ borrowerValues[658].value }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Loan Purpose</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValues[864]">{{ formattedLoanPurposeArray(borrowerValues[864].value) }}</span>
						</td>
					</tr>
					<tr class="table-row" *ngIf="loanProductCategory === 'SBA PPP' && borrowerValuesKeyed.eidlGrant && borrowerValuesKeyed.eidlGrant.value > 0">
						<td class="table-pipeline-item">EIDL Refinance Amount</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValuesKeyed.eidlRefinanceAmount">
								{{ borrowerValuesKeyed.eidlRefinanceAmount.value | currency }}
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>

	<div class="owner-details" *ngIf="loanProductCategory === 'SBA PPP Second'" data-cy="ppp-owner-details">
		<h4 class="table-pipeline-header">PPP First Draw</h4>
		<div class="flex">
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">SBA Loan Number</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValuesKeyed.ppp1SbaNumber">
								{{ borrowerValuesKeyed.ppp1SbaNumber.value }}
							</span>
						</td>
					</tr>

					<tr class="table-row">
						<td class="table-pipeline-item">Loan Amount</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValuesKeyed.ppp1LoanAmount">
								{{ borrowerValuesKeyed.ppp1LoanAmount.value | currency: undefined:'symbol':'1.0' }}
							</span>
						</td>
					</tr>
				</table>
			</div>
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">Lender Name</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValuesKeyed.ppp1LenderName">
								{{ borrowerValuesKeyed.ppp1LenderName.value }}
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>

	<div class="owner-details" *ngIf="loanProductCategory === 'SBA PPP Second'" data-cy="ppp-owner-details">
		<h4 class="table-pipeline-header">Quarterly Gross Receipts</h4>
		<div class="flex">
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">2020 Quarter</td>
						<td class="table-pipeline-value">
							<span *ngIf="revReductionQuarters[1]">
								{{ revReductionQuarters[1] }}
							</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Gross Receipts</td>
						<td class="table-pipeline-value">
							<span *ngIf="revReductionQuarterValues[1]">
								{{ revReductionQuarterValues[1] | currency: undefined:'symbol':'1.0' }}
							</span>
						</td>
					</tr>
					<!-- <tr class="table-row" [ngClass]="{'bg-gray-100': borrowerValuesKeyed.quarterlyRevenue2019Q1 && usedReductionQuarter('2019 Q1')}">
						<td class="table-pipeline-item">2019 Q1</td>
						<td class="table-pipeline-value">
							<span *ngIf="borrowerValuesKeyed.quarterlyRevenue2019Q1">
								{{ borrowerValuesKeyed.quarterlyRevenue2019Q1.value | currency: undefined:'symbol':'1.0' }}
							</span>
						</td>
					</tr> -->
				</table>
			</div>
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">Reference Quarter</td>
						<td class="table-pipeline-value">
							<span *ngIf="revReductionQuarters[0]">
								{{ revReductionQuarters[0] }}
							</span>
						</td>
					</tr>

					<tr class="table-row">
						<td class="table-pipeline-item">Gross Receipts</td>
						<td class="table-pipeline-value">
							<span *ngIf="revReductionQuarterValues[0]">
								{{ revReductionQuarterValues[0] | currency: undefined:'symbol':'1.0' }}
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>

	<div class="owner-details" *ngIf="primaryContact" data-cy="ppp-owner-details">
		<h4 class="table-pipeline-header">Owner Details</h4>
		<div class="flex">
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">Primary Owner Name</td>
						<td class="table-pipeline-value">
							<span *ngIf="primaryContact.first">{{ primaryContact.first }} </span><span *ngIf="primaryContact.last">{{ primaryContact.last }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Title</td>
						<td class="table-pipeline-value">
							<span *ngIf="primaryContact.title">{{ primaryContact.title }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Ownership %</td>
						<td class="table-pipeline-value">
							<span *ngIf="primaryContact.percentOwnership">{{ primaryContact.percentOwnership }}%</span>
						</td>
					</tr>
				</table>
			</div>
			<div class="flex-1 px-3">
				<table class="table table-pipeline">
					<tr class="table-row">
						<td class="table-pipeline-item">TIN (EIN, SSN)</td>
						<td class="table-pipeline-value">
							<span *ngIf="primaryContact.ssnGuarded">{{ formattedSsn(primaryContact.ssnGuarded) }}</span>
						</td>
					</tr>
					<tr class="table-row">
						<td class="table-pipeline-item">Owner Address</td>
						<td class="table-pipeline-value">
							<span *ngIf="primaryContact.street">{{ primaryContact.street }} </span>
							<span *ngIf="primaryContact.street2">{{ primaryContact.street2 }} <br /></span>
							<span *ngIf="primaryContact.city">{{ primaryContact.city }}, </span><span *ngIf="primaryContact.stateId">{{ primaryContact.stateId }} </span><span *ngIf="primaryContact.zipId">{{ primaryContact.zipId }}</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>

	<div class="flex justify-end">
		<app-export-application-smart [dealId]="applicationDetails.deal.id"></app-export-application-smart>
		<app-export-application-smart
			[disabled]="!borrowerValues[863]"
			[type]="'xml'"
			[matTooltip]="!borrowerValues[863] ? 'Business Type cannot be blank to export XML Applications' : 'Export XML Application'"
			[dealId]="applicationDetails.deal.id">
		</app-export-application-smart>
	</div>
</div>


