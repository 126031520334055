import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store
} from '@ngxs/store';
import {
  ClearFunnelViewsStore,
  GetFunnelViews,
  UpdateFunnelViews,
} from './funnel-views.actions';
import { ColumnFilterModel } from '@app/app/components/grid-components/grid-types';
import { FunnelViewFiltersService } from '@app/app/services/funnel-view-filters.service';
import { AuthState } from '@app/app/store/auth/auth.state';

const dateStringFormatter = (date: Date): string => date.toISOString().slice(0, 19).replace('T', ' ');
const gettingColdFilterDate = new Date();
gettingColdFilterDate.setDate(gettingColdFilterDate.getDate() - 2);
const formattedGettingColdDate = dateStringFormatter(gettingColdFilterDate);

export interface AgGridViewFilter {
  agGridViewId?: number;
  name: string;
  filterModel: ColumnFilterModel;
  isCreatedByUser: boolean;
  isHiddenByUser: boolean;
}

export class FunnelViewsStateModel {
  funnelViews: AgGridViewFilter[];
  loading: boolean;
}

@State<FunnelViewsStateModel>({
  name: 'funnelViews',
  defaults: {
    funnelViews: [],
    loading: false
  },
})
@Injectable()
export class FunnelViewsState {
  @Selector()
  static funnelViews(state: FunnelViewsStateModel) {
    return state.funnelViews;
  }

  constructor(
    private _store: Store,
    private _viewFilterService: FunnelViewFiltersService
  ) { }

  @Action(GetFunnelViews)
  getFunnelViews({ patchState }: StateContext<FunnelViewsStateModel>) {
    const authUser = this._store.selectSnapshot(AuthState.user);
    return this._viewFilterService.getFunnelViews().pipe(
      catchError((error) => {
        patchState({ loading: false });
        return throwError(error);
      }),
      tap({
        next: (response) => {
          const funnelViews = response.data;
          const myDealsIndex = funnelViews?.findIndex(viewFilter => viewFilter.name === 'My deals');
          const gettingColdIndex = funnelViews?.findIndex(viewFilter => viewFilter.name === 'Getting cold');
          if (myDealsIndex !== - 1) {
            const userDealsFilter = {
              assignments: {
                filterType: 'text',
                type: 'includes',
                filter: `${authUser?.first} ${authUser?.last}`,
              }
            };
            funnelViews[myDealsIndex].filterModel = { ...userDealsFilter };
          }

          const gettingColdViewFilter = funnelViews[gettingColdIndex].filterModel;
          const gettingColdViewFilterModel = {
            modified: {
              filterType: 'date',
              type: 'lessThan',
              dateFrom: formattedGettingColdDate,
              dateTo: null,
            }
          }
          funnelViews[gettingColdIndex].filterModel = {
            ...gettingColdViewFilter,
            ...gettingColdViewFilterModel
          }
          patchState({
            funnelViews,
            loading: false
          })
        },
      })
    )
  }

  @Action(UpdateFunnelViews)
  updateFunnelViews(
    { patchState }: StateContext<FunnelViewsStateModel>,
    { funnelViews }: UpdateFunnelViews
  ) {
    this._viewFilterService.updateFunnelViews(funnelViews).pipe(
      catchError((error) => throwError(() => error)),
      tap({
        next: () => patchState({ funnelViews }),
      })
    ).subscribe();
  }

  @Action(ClearFunnelViewsStore)
  clearFunnelViewsStore({ patchState }: StateContext<FunnelViewsStateModel>) {
    this._viewFilterService.updateFunnelViews([]);
    patchState({ funnelViews: [] });
  }
}
