import { GridStateModel } from 'src/app/interfaces/grid-state.model';

export class GetFunnelGridState {
  static readonly type = '[FunnelGridState] GetFunnelGridState';
  constructor() { }
}

export class SaveFunnelGridState {
  static readonly type = '[FunnelGridState], SaveFunnelGridState';
  constructor(public gridState: GridStateModel) { }
}

export class ClearFunnelGridState {
  static readonly type = '[FunnelGridState] ClearFunnelGridState';
  constructor() { }
}
