<div class="flex justify-between content-center">
    <h2 mat-dialog-title class="!pb-2" data-cy="contact-form-modal-header">Notes</h2>
    <button [mat-dialog-close]="" class="pr-6">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <mat-form-field floatLabel="always" appearance="outline" class="w-full">
        <mat-label>Add a note</mat-label>
        <textarea matInput cdkFocusInitial [(ngModel)]="noteContent"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <div class="flex w-full justify-end pt-4">
        <button mat-stroked-button
            [mat-dialog-close]=""
        >Cancel</button>
        <button
            mat-flat-button
            color="primary"
            class="!ml-4"
            (click)="saveNote.emit(noteContent)"
        >
            Save
        </button>
    </div>
</div>
