<span mat-dialog-close class="close-dialog-circle">
    <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title class="!p-4">Cancel Submission?</h2>
<div mat-dialog-content>
    <div>
        <p>
            Application will be pulled back from the SBA. You can re-submit an application after it has been canceled.
        </p>
        <p>
            <strong>Note: </strong>The system will only attempt to cancel your submission. If the application is no longer in a submitted or pending state, we will be unable to cancel.
        </p>
    </div>

    <div class="text-right">
        <button mat-flat-button class="!text-gray-700 !mr-2" mat-dialog-close>Keep Submission</button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="submitting"
            [class.spinner]="submitting"
            (click)="submit()"
            autofocus>
            {{ submitting ? '&nbsp;' : 'Cancel Submission' }}
        </button>
    </div>
</div>
