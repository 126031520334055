import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AttachLabel,
  AttachMultipleLabels,
  ClearApplications,
  DetachLabel,
  DetachMultipleLabels,
  GetApplications,
  GetTabData,
  MarkAsQueued,
  SetPageNumber,
  SetSection,
  UnmarkAsQueued,
  UpdateAssignments
} from './applications-list.actions';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationsListService } from '../../services/applications-list.service';
import { ApplicationsList } from '../../interfaces/applications-list.model';
import { Injectable } from '@angular/core';
import { LabelsService } from '../../services/labels.service';
import { throwError } from 'rxjs';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';

export class ApplicationsListStateModel {
    applications: ApplicationsList | null;
    pageNumber: number | null;
}

@State<ApplicationsListStateModel>({
    name: 'applicationsList',
    defaults: {
        applications: null,
        pageNumber: 0
    }
})
@Injectable()

export class ApplicationsListState {

    @Selector()
    static applications(state: ApplicationsListStateModel) {
        return state.applications;
    }

    constructor(
        private appsListService: ApplicationsListService,
        private labelsService: LabelsService,
        private store: Store
    ) {}

    @Action(SetPageNumber)
    setPageNumber({ getState, patchState }: StateContext<ApplicationsListStateModel>, { pageNumber }: SetPageNumber) {
        patchState({ pageNumber });
    }

    @Action(GetApplications)
    // eslint-disable-next-line max-len
    getApplications({ getState, patchState }: StateContext<ApplicationsListStateModel>, { section, sortBy, sortDirection, page, limit }: GetApplications) {
        const pageSize = Number(localStorage.getItem('pageSize'));
        limit = pageSize ? pageSize : limit;
        const pageNumber = Number(getState().pageNumber);
        page =  pageNumber ? pageNumber : page;

        return this.appsListService.getApplications(section, sortBy, sortDirection, page, limit).pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to fetch applications. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            const currentState = { ...getState().applications };
            const currentSections = { ...currentState.sections };
            const resDeals = response.data.deals;

            currentState.page = response.data.page;
            currentState.totalCounts = response.data.totalCounts;
            currentState.counts = response.data.counts;
            currentState.sort = response.data.sort;
            currentState.urlPostfix = response.data.urlPostfix;
            currentState.renewals = response.data.renewals;

            currentSections[section] = {
                  deals: [],
                  assignments: []
              };

            resDeals.forEach((deal: any) => {
                const currentDeal = deal;

                if (currentDeal.assignments) {
                    currentDeal.assignmentArray = JSON.parse(currentDeal.assignments);
                } else {
                    currentDeal.assignmentArray = [];
                }

                if (currentDeal.labels) {
                    currentDeal.labelArray = JSON.parse(currentDeal.labels);
                } else {
                    currentDeal.labelArray = [];
                }

                currentSections[section].deals.push(currentDeal);
            });

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          currentState.sections = currentSections;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          patchState({ applications: currentState });
        }));
    }

    @Action(GetTabData)
    getTabData({ getState, patchState }: StateContext<ApplicationsListStateModel>, {}: GetTabData) {
        return this.appsListService.getTabData().pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to load tab counts. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            let currentState = JSON.parse(JSON.stringify(getState().applications));

            if (currentState === null) {
                currentState = {
                    totalCounts: 0,
                    counts: 0
                };
            }

            currentState.totalCounts = response.data.totalCounts;
            currentState.counts = response.data.counts;

            patchState({ applications: currentState });
        }));
    }

    @Action(SetSection)
    setSection({ getState, patchState }: StateContext<ApplicationsListStateModel>, { section }: SetSection) {
        const currentState = JSON.parse(JSON.stringify(getState().applications));
        currentState.page = section;
        patchState({ applications: currentState });
    }

    @Action(MarkAsQueued)
    markAsQueued({ getState, patchState }: StateContext<ApplicationsListStateModel>, { submitList }: MarkAsQueued) {
        const currentState = JSON.parse(JSON.stringify(getState().applications));
        const newApps = { ...currentState.sections['new-apps'] };
        const newAppsDeals = [ ...newApps.deals ];
        newAppsDeals.forEach(deal => {
            if (submitList.find(id => id === deal.id)) {
                deal.sbaStatus = 'Queued';
            }
        });
        newApps.deals = newAppsDeals;
        currentState.sections['new-apps'] = newApps;
        patchState({ applications: currentState });
    }

    @Action(UnmarkAsQueued)
    unmarkAsQueued({ getState, patchState }: StateContext<ApplicationsListStateModel>, { submitList }: UnmarkAsQueued) {
        const currentState = JSON.parse(JSON.stringify(getState().applications));
        const newApps = { ...currentState.sections['new-apps'] };
        const newAppsDeals = [ ...newApps.deals ];
        newAppsDeals.forEach(deal => {
            if (submitList.find(id => id === deal.id)) {
                deal.sbaStatus = null;
            }
        });
        newApps.deals = newAppsDeals;
        currentState.sections['new-apps'] = newApps;
        patchState({ applications: currentState });
    }

    @Action(ClearApplications)
    clearApplications({ patchState }: StateContext<ApplicationsListStateModel>, {}: ClearApplications) {
        patchState({ applications: null });
    }

    @Action(DetachLabel)
    detachLabel({ getState, patchState }: StateContext<ApplicationsListStateModel>, { labelId, dealId }: DetachLabel) {
        return this.labelsService.detachLabel(dealId, labelId).pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to detach label. Please refresh the page and try again.'
            }));
            return throwError(err);
        }), tap(() => {
            const currentState = JSON.parse(JSON.stringify(getState().applications));

            if (currentState && Object.keys(currentState).length > 0) {
                const deals = currentState.sections[currentState.page].deals;
                const dealIndex = deals.findIndex((x: { id: number; }) => x.id === dealId);
                const labels = deals[dealIndex] ? deals[dealIndex].labelArray : [];
                const labelIndex = labels.findIndex((x: { id: number; }) => x.id === labelId);

                if (labelIndex !== -1) {
                    labels.splice(labelIndex, 1);
                    patchState({ applications: currentState });
                }
            }
        }));
    }

    @Action(DetachMultipleLabels)
    detachMultipleLabels({ getState, patchState }: StateContext<ApplicationsListStateModel>, { labelIds, dealId }: DetachMultipleLabels) {
        return this.labelsService.detachMultipleLabels(dealId, labelIds).pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to detach labels. Please refresh the page and try again.'
            }));
            return throwError(err);
        }), tap(() => {
            const currentState = JSON.parse(JSON.stringify(getState().applications));

            if (currentState && Object.keys(currentState).length > 0) {
                const deals = currentState.sections[currentState.page].deals;
                const dealIndex = deals.findIndex((x: { id: number; }) => x.id === dealId);
                const labels = deals[dealIndex] ? deals[dealIndex].labelArray : [];
                let updated = false;

                labelIds.forEach((labelId) => {
                    const labelIndex = labels.findIndex((x: { id: number; }) => x.id === labelId);
                    if (labelIndex !== -1) {
                        labels.splice(labelIndex, 1);
                        updated = true;
                    }
                });

                if (updated) {
                    patchState({ applications: currentState});
                }
            }
        }));
    }

    @Action(AttachLabel)
    attachLabel({ getState, patchState }: StateContext<ApplicationsListStateModel>, { label, dealId }: AttachLabel) {
        return this.labelsService.attachLabel(dealId, label).pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to attach label. Please refresh the page and try again.'
            }));
            return throwError(err);
        }), tap(() => {
            const currentState = JSON.parse(JSON.stringify(getState().applications));

            if (currentState && Object.keys(currentState).length > 0) {
                const deals = currentState.sections[currentState.page].deals;
                const dealIndex = deals.findIndex((x: { id: number; }) => x.id === dealId);
                const labels = deals[dealIndex] ? deals[dealIndex].labelArray : [];

                labels.push(label);
                patchState({ applications: currentState});
            }
        }));
    }

    @Action(AttachMultipleLabels)
    attachMultipleLabels({ getState, patchState }: StateContext<ApplicationsListStateModel>, { labels, dealId }: AttachMultipleLabels) {
        return this.labelsService.attachMultipleLabels(dealId, labels).pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to detach labels. Please refresh the page and try again.'
            }));
            return throwError(err);
        }), tap(() => {
            const currentState = JSON.parse(JSON.stringify(getState().applications));

            if (currentState && Object.keys(currentState).length > 0) {
                if (currentState && 'sections' in currentState && currentState.page) {
                  const deals = currentState.sections[currentState.page].deals;
                  const dealIndex = deals.findIndex((x: { id: number; }) => x.id === dealId);
                  const currentLabels = deals[dealIndex] ? deals[dealIndex].labelArray : [];

                  labels.forEach((label) => {
                      currentLabels.push(label);
                  });

                  patchState({ applications: currentState});
                }
            }
        }));
    }

    @Action(UpdateAssignments)
    updateAssignments({ getState, patchState }: StateContext<ApplicationsListStateModel>, { newAssignments, dealId }: UpdateAssignments) {
        const currentState = JSON.parse(JSON.stringify(getState().applications));

        // If there is no current state the update was made outside of the list,
        /// applications is already null, so no need to set it like below when currentDealIndex === -1
        if (!currentState || !currentState.page) {
            return;
        }

        const currentPage = currentState.page;
        const currentDealIndex = currentState.sections[currentPage].deals.findIndex((x: { id: number; }) => x.id === dealId);

        if (currentDealIndex === -1) {
            // The update was made outside of the list -- force a new fetch if/when they hit the list again
            patchState({ applications: null });
            return;
        }

        const currentDeal = currentState.sections[currentPage].deals[currentDealIndex];

        currentDeal.assignmentArray = newAssignments;

        patchState({ applications: currentState });
    }
}
