<div mat-dialog-title>
    Select and send Adverse Action Notice
</div>
<mat-dialog-content class="!max-h-[80vh] !px-0">
    <mat-stepper class="gray-header" [linear]="true" #stepper>

        <form [formGroup]="formGroup">
            <mat-step [completed]="!!selectedTemplate && stepper.selectedIndex !== 0" [stepControl]="formGroup.controls.AANTemplateId">
                <ng-template matStepLabel>
                    <div class="!mb-0 ml-2 mat-subtitle-2">Select Template</div>
                </ng-template>

                @if(templatesLoading$ | async) {
                <div class="column-grid justify-center pt-4">
                    <mat-spinner [color]="'primary'"></mat-spinner>
                </div>
                } @else {
                <mat-selection-list [multiple]="false" (selectionChange)="handleTemplateSelectionChange($event)">
                    @for (template of (emailTemplates$ | async); track template) {
                        <mat-list-option [togglePosition]="'before'" [value]="template.id">
                        {{ template.title }}
                        </mat-list-option>
                    }
                </mat-selection-list>
                }
            </mat-step>
            <mat-step [completed]="stepper.selectedIndex >= 1">
                <ng-template matStepLabel>
                    <div class="!mb-0 ml-2 mat-subtitle-2">Reasons</div>
                </ng-template>
                @if(stepper.selectedIndex === 1) {
                <mat-selection-list [multiple]="true" (selectionChange)="handleReasonSelectionChange($event)" #reasonsList>
                    @for (reason of reasons; track reason) {
                    <mat-list-option [value]="reason" [togglePosition]="'before'">{{reason}}</mat-list-option>
                    }
                </mat-selection-list>
                }
            </mat-step>
        </form>

        <mat-step [completed]="false" state="number">
            <ng-template matStepLabel>
                <div class="!mb-0 ml-2 mat-subtitle-2">Finalize</div>
            </ng-template>
            <!--      Content in matStepContent is lazy-loaded      -->
            <ng-template matStepContent>
                <div class="grid py-2">
                    <mat-form-field>
                        <mat-label>Recipient(s)</mat-label>
                        <mat-select [formControl]="formGroup.controls.recipients"
                                    [multiple]="true"
                                    [value]="formGroup.controls.recipients.value" (selectionChange)="updateRecipients()">
                            @for(contact of (contacts$ | async); track contact) {
                            <mat-option [value]="contact.email">
                            {{ [contact.first, contact.last].join(' ') }} ({{ contact.email }})
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Subject</mat-label>
                        <input matInput [formControl]="formGroup.controls.subject" />
                    </mat-form-field>
                    <div>
                        Please review carefully and make any necessary adjustments to ensure accuracy.
                    </div>
                </div>
                <div class="max-w-[756px]" #editorContainer>
                    <quill-editor class="w-full"
                                  format="html"
                                  [(ngModel)]="editorContent"
                                  (onEditorCreated)="onEditorCreated($event)"
                                  [bounds]="editorContainer"
                                  [debug]="false"
                                  [defaultEmptyValue]="undefined"
                                  [debounceTime]="500">
                        <div quill-editor-toolbar class="terms-editor-toolbar">
                                    <span class="ql-formats terms-editor-toolbar-item center">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                    </span>
                            <span class="ql-formats terms-editor-toolbar-item center">
                                        <select class="ql-align">
                                            <option selected></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                        </select>
                                    </span>
                            <span class="ql-formats terms-editor-toolbar-item center">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                    </span>
                            <span class="ql-formats terms-editor-toolbar-item center">
                                        <button class="ql-indent" value="+1"></button>
                                        <button class="ql-indent" value="-1"></button>
                                    </span>
                            <span class="ql-formats terms-editor-toolbar-item center justify-end">
                                        <button class="ql-link"></button>
                                    </span>
                        </div>
                    </quill-editor>
                </div>
            </ng-template>
        </mat-step>

        <ng-template matStepperIcon="done">
            <mat-icon>check</mat-icon>
        </ng-template>
    </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'" class="!border-top !border-top-1 !border-lendio-warm-gray-100 !pt-4">

    @if(stepper.selectedIndex > 0) {
    <button mat-button (click)="stepper.previous()">Back</button>
    }
    <button
        mat-flat-button
        [color]="'primary'"
        [disabled]="stepper.selectedIndex === 2 && !formGroup.controls.recipients.valid"
        (click)="handleStepperNext(stepper)">
        {{ stepper.selectedIndex === 2 ? 'Confirm Inactivation and Send Notice' : 'Next' }}
    </button>

</mat-dialog-actions>
