import { Component, computed, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import { Label } from '../../../interfaces/label.model';
import { CreateLabel, DeleteLabel, EditLabel } from '../../../store/labels/labels.actions';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-label-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss', '../../../scss/_dialog.scss']
})
export class LabelEditDialogComponent implements OnInit {

  colors = [
    {
      name: 'Light Blue',
      value: '#2BABE2'
    },
    {
      name: 'Dark Blue',
      value: '#3D5A8C'
    },
    {
      name: 'Purple',
      value: '#7E6FBF'
    },
    {
      name: 'Green',
      value: '#45B84F'
    },
    {
      name: 'Red',
      value: '#DF4040'
    },
    {
      name: 'Orange',
      value: '#F97818'
    },
    {
      name: 'Yellow',
      value: '#FBB800'
    },
    {
      name: 'Black',
      value: '#000202'
    },
    {
      name: 'Grey',
      value: '#6D6D6E'
    },
    {
      name: 'Light Grey',
      value: '#AEAEAE'
    },
  ];

  myLabel: Label;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(public dialogRef: MatDialogRef<LabelEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public label: Label,
              public store: Store,
              private _themeService: LendioAngularMaterialThemeService
  ) {
    if (this.label) {
      this.myLabel = { ...this.label};
    } else {
      this.myLabel = {
        id: null,
        lenderId: null,
        name: null,
        color: '#2BABE2',
        secondaryColor: '#FFFFFF',
        created: null,
        modified: null,
        deleted: null
      };
    }
  }

  ngOnInit(): void {}

  save() {
    if (!this.myLabel.id) {
      this.store.dispatch(new CreateLabel(this.myLabel));
    } else {
      this.store.dispatch(new EditLabel(this.myLabel));
    }

    this.dialogRef.close(this.myLabel);
  }

  delete() {
    if (!confirm(`Are you sure you want to delete the label '${this.myLabel.name}'?`)) {
      return;
    }


    this.myLabel.id && this.store.dispatch(new DeleteLabel(this.myLabel.id));
    this.dialogRef.close();
  }

}
