<div class="lendio-density-1">
    <mat-form-field color="accent"
                    appearance="outline">
        <mat-icon matPrefix
                  [color]="errored ? 'warn' : 'accent'"
                  [matTooltip]="errored ? 'There was an error while attempting to search for applications. Please try again.' : ''"
                  matTooltipPosition="below">
            <span>{{ errored ? 'warning' : 'search' }}</span>
        </mat-icon>
        <input [(ngModel)]="searchTerm"
               [matAutocomplete]="results"
               (keyup)="search(); hasSearched = false;"
               (focus)="searchResults = []; searchTerm = ''; hasSearched = false"
               placeholder="Search..."
               autocomplete="off"
               matInput
               type="text">
        <mat-autocomplete #results>
            <!-- Loading results -->
            <mat-option disabled *ngIf="searching">
                <app-dot-loader-scale></app-dot-loader-scale>
            </mat-option>

            <!-- We have results -->
            <mat-option
                (click)="searchResults = []; hasSearched = false"
                *ngFor="let result of searchResults"
                [routerLink]="result.routerLink">
                <div>{{ result?.businessName }}</div>
                <div class="text-xs text-gray-400">{{ result?.primaryContactName }}</div>
            </mat-option>

            <!-- No results -->
            <mat-option disabled *ngIf="!searching && !searchResults && hasSearched && searchTerm">
                No results for '{{ searchTerm }}'
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
