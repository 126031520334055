<form [formGroup]="codeForm" (ngSubmit)="emitCode()" class="new-theme-typography">
    <div class="flex">
        @for (field of fields; track $index) {
        <mat-form-field style="max-width: 44px;" appearance="outline" class="mfa-form-field" [ngClass]="{
                        'mr-1': $first,
                        'ml-1': $last,
                        'mx-1': !$first && !$last
                    }" [@jiggle]="jiggleState">
            <input
                [formControlName]="'code' + $index"
                #codeInput
                required
                [attr.data-cy]="'mfa-code-input-' + $index"
                [name]="'code' + $index"
                [id]="'mfa-code-input' + $index"
                matInput
                type="number"
                style="text-align: center;"
                (input)="onInput($event, $index)"
                (keydown)="onKeydown($event, $index)"
                (keyup)="onKeyUp($event, $index)"
                (focus)="onFocus($event, $index)"
                pattern="[0-9]"
                maxlength="1"
            >
        </mat-form-field>
        }
    </div>
</form>
