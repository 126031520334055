import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Contact } from '../interfaces/contact.model';

/**
 * CRUD service for contacts, either by borrowerId (default) or dealId.
 */
@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http: HttpClient) { }

  // CRUD methods for contacts using main api ContactController routes in
  // oauth-routes.
  getIndex(borrowerId: number): Observable<Contact[]> {
    return this.http.get<any>(`${environment.apiUrl}/borrower/${borrowerId}/contacts`);
  }

  create(formData: FormData | Contact): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/contact`, formData);
  }

  update(id: number, formData: FormData | Contact): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/contact/${id}`, formData);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/contact/${id}`, {});
  }


  // CRUD methods for contacts by dealId from the
  // PublicLenderApi\ContactController routes in lender-routes.
  getIndexByDeal(dealId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/l/v2/internal/deals/${dealId}/contacts`);
  }

  createByDeal(dealId: number, formData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/l/v2/internal/deals/${dealId}/contacts`, formData);
  }

  updateByDeal(dealId: number, id: number, formData: FormData): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/l/v2/internal/deals/${dealId}/contacts/${id}`, formData);
  }

  deleteByDeal(dealId: number, id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/l/v2/internal/deals/${dealId}/contacts/${id}`, {});
  }
}
