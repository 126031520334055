import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { Partner } from '../../interfaces/embedded-partner.model';
import { Customization } from '../../interfaces/embedded-customization.model';
import { EmbeddedService } from '../../services/embedded.service';
import { tap } from 'rxjs/operators';
import { catchError, of } from 'rxjs';
import {
  // Embedded Service
  GetEmbeddedPartner,
  _UpdateCustomization,
  _CreateCustomization,
  CreateEmbeddedAffiliateUserToken,
  // Embedded API
  GetCustomizations,
  GetEmbeddedCredentials,
  UpdateCustomization,
  CreateCustomization,
  // Shared
  EmbeddedLoaded,
  ClearEmbeddedStore
} from './embedded.actions';

export class EmbeddedStateModel {
  partner: Partner | null;
  customizations: { [key: string]: Array<Customization> } | null;
  credentials: { clientId: number, secret: string } | null;
  embeddedJwt: { [key: string]: string | number } | null;
  loaded: boolean;
  lastActiveCustomization: Customization | null;
}

@Injectable()
@State<EmbeddedStateModel>({
  name: 'embedded',
  defaults: {
    partner: null,
    customizations: null,
    credentials: null,
    embeddedJwt: null,
    loaded: false,
    lastActiveCustomization: null
  }
})

export class EmbeddedState {

  @Selector()
  static partner(state: EmbeddedStateModel): Partner | null {
    return state.partner;
  }

  @Selector()
  static credentials(state: EmbeddedStateModel): { clientId: number, secret: string } | null {
    return state.credentials;
  }

  @Selector()
  static embeddedJwt(state: EmbeddedStateModel): { [key: string]: string | number } | null {
    if (state.embeddedJwt) {
      const timeNow = new Date().getTime();
      const expiration = typeof state.embeddedJwt.exp === 'string' ? parseInt(state.embeddedJwt.exp) : state.embeddedJwt.exp
      
      if (expiration < timeNow) {
        return null;
      }
    }

    return state.embeddedJwt;
  }

  @Selector()
  static customizations(state: EmbeddedStateModel): { [key: string]: Array<Customization> } | null {
    return state.customizations;
  }

  @Selector()
  static customizationsByType(type: string) {
    return createSelector([EmbeddedState], ({ embedded: state }) => {
      const typeArray: Array<Customization>|null = state.customizations ? state.customizations[type] : null;
        
      return typeArray || null;
    })
  }

  @Selector()
  static activeCustomizationByType(type: string) {
    return createSelector([EmbeddedState], ({ embedded: state }) => {
      const typeArray: Customization[]|null = state.customizations ? state.customizations[type] : null;

      if (typeArray) {
        const activeCustomization = typeArray.find(c => !!c.active);
  
        return activeCustomization ?? null;
      } 
        
      return null;
    })
  }

  @Selector()
  static loaded(state: EmbeddedStateModel): boolean {
    return state.loaded;
  }

  @Selector()
  static lastActiveCustomization(state: EmbeddedStateModel): Customization | null {
    return state.lastActiveCustomization;
  }

  constructor(
    private embeddedService: EmbeddedService,
  ) { }
  @Action(CreateEmbeddedAffiliateUserToken)
  createEmbeddedAffiliateUserToken({ patchState }: StateContext<EmbeddedStateModel>, { affiliateId }: CreateEmbeddedAffiliateUserToken) {
    return this.embeddedService.createEmbeddedAffiliateUserToken(affiliateId).pipe(
      catchError(() => {
        return of();
      }),
      tap(({ data }) => {
        const { exp, token } = data;
        if (!token) {
          return;
        }

        patchState({ embeddedJwt: { exp, token } });
      })
    );
  }
  @Action(GetEmbeddedPartner)
  getEmbeddedPartner({ getState, patchState }: StateContext<EmbeddedStateModel>, { affiliateId }: GetEmbeddedPartner) {
    const embeddedJwt = getState().embeddedJwt
  
    if (embeddedJwt) {
      const { token } = embeddedJwt

      return this.embeddedService.getPartner(token, affiliateId).pipe(
        catchError(() => {
          return of();
        }),
        tap(({ partner, customizations }) => {
  
          if (!partner) {
            return;
          }
  
          patchState({ partner });
  
          if (customizations) {
            patchState({ customizations });
          }
        })
      );
    }
  }

  @Action(GetEmbeddedCredentials)
  getEmbeddedCredentials({ getState, patchState }: StateContext<EmbeddedStateModel>, { affiliateId }: GetEmbeddedCredentials) {
      return this.embeddedService.getEmbeddedCredentials(affiliateId).pipe(
        catchError(() => {
          return of();
        }),
        tap(({ data: credentials }) => {
  
          if (!credentials) {
            return;
          }
  
          patchState({ credentials });
        })
      );
  }

  @Action(GetCustomizations)
  getEmbeddedCustomizations({ getState, patchState }: StateContext<EmbeddedStateModel>, { affiliateId, type}: GetCustomizations) {  
      return this.embeddedService.getCustomizations(affiliateId, type).pipe(
        catchError(() => {
          return of();
        }),
        tap(({ data: customizations }) => {
  
          if (customizations) {
            customizations = customizations.reduce((acc: any, customization: Customization) => {
              if (acc[customization.type]) {
                acc[customization.type].push(customization);
              } else {
                acc[customization.type] = [ customization ];
              }

              return acc;
            }, {})

            patchState({ customizations });
          }
        })
      );
  }

  @Action(EmbeddedLoaded)
  embeddedLoaded({ patchState }: StateContext<EmbeddedStateModel>, { bool }: EmbeddedLoaded) {
    patchState({ loaded: bool });
  }

  @Action(UpdateCustomization)
  updateCustomization({ getState, patchState }: StateContext<EmbeddedStateModel>, { affiliateId, customizationId, customizationRequest }: UpdateCustomization) {
    return this.embeddedService.updateCustomization(affiliateId, customizationId, customizationRequest).pipe(
      catchError(() => {
        return of();
      }),
      tap(({ data: customization }) => {
        if (!customization) {
          return;
        }
        let customizations = getState().customizations;
        const { type, id } = customization;

        if (!customizations) {
          patchState({ lastActiveCustomization: customization, customizations: { [type]: [ customization ]}});

          return;
        } else {
          customizations = { ...customizations };
        }
        let customizationTypeArr = customizations ? [ ...customizations[type] ] : [];
        const existingCustomizationIndex = customizationTypeArr.findIndex(c => c.id === id);

        if (existingCustomizationIndex >= 0) {
          customizationTypeArr[existingCustomizationIndex] = customization;
        } else {
          customizationTypeArr.push(customization);
        }

        if (customization.active) {
          customizationTypeArr = customizationTypeArr.map(c => {
            if (c.id !== customization.id && c.active) {
              return { ...c, active: 0 };
            }

            return c;
          })
        }

        customizations[type] = customizationTypeArr;
        patchState({ lastActiveCustomization: customization, customizations });
      })
    );
  }

  @Action(_UpdateCustomization)
  _updateCustomization({ getState, patchState }: StateContext<EmbeddedStateModel>, { affiliateId, customizationId, customizationRequest }: _UpdateCustomization) {
    const embeddedJwt = getState().embeddedJwt
  
    if (embeddedJwt) {
      const { token } = embeddedJwt

      return this.embeddedService._updateCustomization(token, affiliateId, customizationId, customizationRequest).pipe(
        catchError(() => {
          return of();
        }),
        tap(({ customization }) => {

          if (!customization) {
            return;
          }

          let customizations = getState().customizations;
          const { type, id } = customization;

          if (!customizations) {
            patchState({ lastActiveCustomization: customization, customizations: { [type]: [ customization ]}});

            return;
          } else {
            customizations = { ...customizations };
          }

          const customizationTypeArr = customizations ? [ ...customizations[type] ] : [];
          const existingCustomizationIndex = customizationTypeArr.findIndex(c => c.id === id);

          if (existingCustomizationIndex >= 0) {
            customizationTypeArr[existingCustomizationIndex] = customization;
          } else {
            customizationTypeArr.push(customization);
          }

          customizations[type] = customizationTypeArr;

          patchState({ lastActiveCustomization: customization, customizations });
        })
      );
    }
  }

  @Action(CreateCustomization)
  createCustomization({ getState, patchState }: StateContext<EmbeddedStateModel>, { affiliateId, customizationRequest }: CreateCustomization) {  
    return this.embeddedService.postCustomization(affiliateId, customizationRequest).pipe(
      catchError(() => {
        return of();
      }),
      tap(({ data: customization }) => {
        if (!customization) {
          return;
        }
        let customizations = getState().customizations;
        if (!customizations) {
          patchState({ lastActiveCustomization: customization, customizations: { type: [ customization ]}});
          return;
        } else {
          customizations = { ...customizations };
        }
        const { type } = customization;
        const customizationTypeArr = customizations && customizations[type] ? [ ...customizations[type] ] : [];
        customizationTypeArr.push(customization);
        customizations[type] = customizationTypeArr;
        patchState({ lastActiveCustomization: customization, customizations });
      })
    );
  }

  @Action(_CreateCustomization)
  _createCustomization({ getState, patchState }: StateContext<EmbeddedStateModel>, { affiliateId, customizationRequest }: _CreateCustomization) {
    const embeddedJwt = getState().embeddedJwt
  
    if (embeddedJwt) {
      const { token } = embeddedJwt
      return this.embeddedService._postCustomization(token, affiliateId, customizationRequest).pipe(
        catchError(() => {
          return of();
        }),
        tap(({ customization }) => {

          if (!customization) {
            return;
          }

          let customizations = getState().customizations;

          if (!customizations) {
            patchState({ lastActiveCustomization: customization, customizations: { type: [ customization ]}});

            return;
          } else {
            customizations = { ...customizations };
          }

          const { type } = customization;
          const customizationTypeArr = customizations && customizations[type] ? [ ...customizations[type] ] : [];
          customizationTypeArr.push(customization);

          customizations[type] = customizationTypeArr;

          patchState({ lastActiveCustomization: customization, customizations });
        })
      );
    }
  }

  @Action(ClearEmbeddedStore)
  clearEmbeddedStore({ patchState }: StateContext<EmbeddedStateModel>, { }: ClearEmbeddedStore) {
      patchState({
        partner: null,
        customizations: null,
        embeddedJwt: null,
        loaded: false
      });
  }
}
