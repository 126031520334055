import { DealNote } from '@app/app/interfaces/deal-note.model';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DealsService } from '@app/app/services/deals.service';
import { AddDealNote, ClearDealNotesStore, GetDealNotes } from '@app/app/store/deal-notes/deal-notes.actions';
import { catchError, tap } from 'rxjs/operators';
import { CreateNewAlert } from '@app/app/store/global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { isBefore, subMinutes } from 'date-fns';


export class DealNotesStateModel {
  dealId: number | null;
  dealNotes: DealNote[];
  loading: boolean;
  lastFetched?: Date;
}

@State<DealNotesStateModel>({
  name: 'dealNotes',
  defaults: {
    dealId: null,
    dealNotes: [],
    loading: false,
  }
})
@Injectable()
export class DealNotesState {

  @Selector()
  static dealId( state: DealNotesStateModel ) {
    return state.dealId;
  }

  @Selector()
  static dealNotes( state: DealNotesStateModel ) {
    return state.dealNotes;
  }

  @Selector()
  static loading( state: DealNotesStateModel ) {
    return state.loading;
  }

  constructor(
    private dealsService: DealsService,
    private store: Store,
  ) {}

  @Action( ClearDealNotesStore )
  clearDealNotesStore(
    { setState }: StateContext<DealNotesStateModel>,
    { }: ClearDealNotesStore
  ):void {
    setState({
      dealId: null,
      dealNotes: [],
      loading: false,
    });
  }

  @Action( GetDealNotes )
  getDealNotes(
    ctx: StateContext<DealNotesStateModel>,
    { dealId }: GetDealNotes
  ) {
    const current = ctx.getState();
    const dataStale = !current.lastFetched || isBefore(current.lastFetched, subMinutes(new Date(Date.now()), 5));

    if(dealId !== current.dealId || dataStale) {
      ctx.patchState({
        dealId,
        loading: true,
      });

      return this.dealsService.getDealNotes(dealId).pipe(
        catchError( ( err ) => {
          this.store.dispatch( new CreateNewAlert( {
            level: 'error',
            message: 'Unable to fetch Deal Notes'
          } ) );
          ctx.patchState( {
            loading: false,
          } );
          return throwError( err );
        } ),
        tap( response => {
          ctx.patchState({
            dealNotes: response.data,
            loading: false,
            lastFetched: new Date(Date.now()),
          });
        })
      );
    }
  }

  @Action( AddDealNote )
  addDealNote(
    ctx: StateContext<DealNotesStateModel>,
    { dealId, content }: AddDealNote,
  ) {
    const current = ctx.getState();
    return this.dealsService.addDealNote( dealId, content ).pipe(
      catchError( ( err ) => {
        this.store.dispatch( new CreateNewAlert( {
          level: 'error',
          message: 'Unable to fetch Deal Notes'
        } ) );
        ctx.patchState( {
          loading: false,
        } );
        return throwError( err );
      } ),
      tap( response => {
        ctx.patchState({
          dealNotes: [
            response.data,
            ...current.dealNotes,
          ]
        });
      }),
    )
  }
}
