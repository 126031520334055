import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadContractDialogComponent } from '../../upload-contract/upload-contract-dialog/upload-contract-dialog.component';

@Component({
  selector: 'app-upload-contract-button',
  templateUrl: './upload-contract-button.component.html',
  styleUrls: ['./upload-contract-button.component.scss']
})
export class UploadContractButtonComponent implements OnInit {

  @Input() dealId: number;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openUploadContractDialog(): void {
    this.dialog.open(UploadContractDialogComponent, {
      width: '80%',
      data: { dealId: this.dealId }
    });
  }
}
