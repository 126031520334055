<div mat-dialog-title class="pb-8">Import failed</div>
<div mat-dialog-content>
    <div *ngIf="data.page === 'imports'">
        <ng-container *ngIf="data.errorMessage; else noErrorMessageField">
            <p>This import failed. This was caused by the following:</p>
            <div *ngIf="data.errorFormat === 'string'; else importErrorObject" class="pl-2">
                <li>{{data.errorMessage}}</li>
            </div>
            <ng-template #importErrorObject>
                <div *ngFor="let error of data.errorMessage | keyvalue">
                    <li><b>{{ error.key }}:</b>
                        <div class="break-words indent-0.5">{{ error.value | json }}</div>
                    </li>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #noErrorMessageField>
            <p>This import failed. This could have been due to a number of reasons, including:</p>
            <div class="pl-2 pb-3">
                <li>Network issues</li>
                <li>The wrong file columns</li>
                <li>The incorrect file type</li>
                <li>A corrupted file</li>
            </div>
            <p>We recommend verifying the file for correctness and then trying again.</p>
        </ng-template>
    </div>
    <div *ngIf="data.page === 'details'">
        <ng-container *ngIf="data.errors; else noErrorsField">
            <p>This record failed to import, due to the following:</p>
            <div *ngIf="data.errorFormat === 'string'; else detailErrorObject" class="pl-2">
                <li>{{data.errors}}</li>
            </div>
            <ng-template #detailErrorObject>
                <div *ngFor="let error of data.errors | keyvalue">
                    <li><b>{{ error.key }}:</b>
                        <div class="break-words indent-0.5">{{ error.value | json }}</div>
                    </li>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #noErrorsField>
            <p>This record failed to import, possibly due to issues with the file or columns in the file.</p>
        </ng-template>
    </div>
</div>
<div mat-dialog-actions [align]="'end'">
    <button class="close-button" mat-stroked-button mat-dialog-close>Close</button>
</div>
