import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {LenderApprovalBenefit} from '../../interfaces/lender-approval-benefits.model';
import {
  CreateLenderApprovalBenefit,
  GetLenderApprovalBenefits,
  UpdateLenderApprovalBenefit
} from './lender-approval-benefits.actions';
import { Injectable } from '@angular/core';
import { LenderApprovalBenefitsService } from 'src/app/services/lender-approval-benefits.service';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';

export class LenderApprovalBenefitStateModel {
  lenderApprovalBenefits: LenderApprovalBenefit[];
}

@State<LenderApprovalBenefitStateModel>({
  name: 'LenderApprovalBenefits',
  defaults: {
    lenderApprovalBenefits: [],
  }
})
@Injectable()
export class LenderApprovalBenefitsState {

  @Selector()
  static lenderApprovalBenefits(state: LenderApprovalBenefitStateModel) {
    return state.lenderApprovalBenefits;
  }

  constructor(
    private store: Store,
    private lenderApprovalBenefitsService: LenderApprovalBenefitsService
  ) { }

  @Action(GetLenderApprovalBenefits)
  getLenderApprovalBenefits({ patchState }: StateContext<LenderApprovalBenefitStateModel>, { }: GetLenderApprovalBenefits) {
    return this.lenderApprovalBenefitsService.getLenderApprovalBenefits().pipe(catchError(err => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to fetch approval benefits. Please refresh the page to try again.'
      }));
      return throwError(err);
    }), tap(response => {
          patchState({
            lenderApprovalBenefits: response.data?.map(sub => {
            return { id: sub.id, lenderId: sub.lenderId, title: sub.title, description: sub.description, suggestedTitle: sub.suggestedTitle, suggestedDescription: sub.suggestedDescription };
          }) || []
        });
    }));
  }

  @Action(CreateLenderApprovalBenefit)
  createLenderApprovalBenefits({ dispatch }: StateContext<LenderApprovalBenefitStateModel>,
    sub: {title: string, description: string, suggestedTitle: string, suggestedDescription: string }){

    return this.lenderApprovalBenefitsService.createLenderApprovalBenefits({
      title: sub.title,
      description: sub.description,
      suggestedTitle: sub.suggestedTitle,
      suggestedDescription: sub.suggestedDescription
    }).pipe(
        catchError(err => {
          this.store.dispatch(new CreateNewAlert({
            level: 'error',
            message: 'Failed to create LenderApprovalBenefit. Please try again.'
          }));
          return throwError(err);
        }), tap(() => {
          return dispatch(new GetLenderApprovalBenefits());
        })
      );

  }

  @Action(UpdateLenderApprovalBenefit)
  updateLenderApprovalBenefits({ dispatch }: StateContext<LenderApprovalBenefitStateModel>, sub: {id: number, title: string, description: string, suggestedTitle: string, suggestedDescription: string }){
    return this.lenderApprovalBenefitsService.updateLenderApprovalBenefits({
      id: sub.id,
      title: sub.title,
      description: sub.description,
      suggestedTitle: sub.suggestedTitle,
      suggestedDescription: sub.suggestedDescription
    }).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Failed to update lender approval benefit. Please try again.'
        }));
        return throwError(err);
      }), tap(() => {
        return dispatch(new GetLenderApprovalBenefits());
      })
    );
  }
}
