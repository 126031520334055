<div [class.old-theme-add-question-dialog]="oldThemeEnabled()">
<span mat-dialog-close class="close-dialog-circle">
    <mat-icon>close</mat-icon>
</span>
<h3 mat-dialog-title class="!p-4"> {{ data.dialogTitle }} </h3>
<div mat-dialog-content>
  <div *ngFor="let section of data.sections">
    <h3 *ngIf="data.sections && data.sections.length > 1" class="!py-3 !my-0">
      {{ section.title }}
    </h3>
    <div class="text-sm font-light flex hover:text-blue-700" style="height: 32px; line-height: 32px;"
         [ngClass]="{'text-blue-500': selection.isSelected(row)}"
         *ngFor="let row of section.options">
      <mat-icon class="!mr-3 cursor-pointer" (click)="selection.toggle(row)">
        {{ selection.isSelected(row) ? 'check_box' : 'check_box_outline_blank' }}
      </mat-icon>
      <mat-icon class="text-yellow-500 cursor-pointer !text-xs" style="width: 12px; height: 12px; padding: 7px 0 0 0; margin-right: 4px"
          [matTooltip]="row.mustAllowNull ? 'For some borrowers this value will be blank.' : ''"
          *ngIf="row.mustAllowNull">
        warning
      </mat-icon>
      <span (click)="selection.toggle(row)" class="cursor-pointer">
        {{ row.display }}
      </span>
      <span *ngIf="row.field === 'bank_statement' && selection.isSelected(row)" class="text-xs pl-3">
        <select style="height: 24px" class="mr-2 border border-gray-300 rounded"
                [(ngModel)]="row.documentQuantity">
          <option *ngFor="let num of generateArray(18)" [value]="num">{{ num }}</option>
        </select>
        month(s)
      </span>
      <span *ngIf="(row.field === 'business_tax_returns' || row.field === 'personal_tax_returns')
              && selection.isSelected(row)"
            class="text-xs pl-3">
        <select style="height: 24px" class="mr-2 border border-gray-300 rounded"
                [(ngModel)]="row.documentQuantity">
          <option *ngFor="let num of generateArray(5)" [value]="num">{{ num }}</option>
        </select>
        year(s)
      </span>
    </div>
  </div>
</div>

<div mat-dialog-actions class="w-full flex justify-between text-right">
  <div class="flex-col">
    <span *ngIf="selection.selected.length > 0">
      Add {{ selection.selected.length }} requirement{{ selection.selected.length > 1 ? 's' : '' }}
    </span>
  </div>
  <div class="flex-col">
    <button mat-button mat-dialog-close color="warn" class="mr-2">
    Cancel
  </button>
  <button mat-flat-button color="primary" [disabled]="selection.selected.length === 0" (click)="addQuestions()">
    Add
  </button>
  </div>

</div>
</div>
