import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as types from './event-types';

/**
 * EventBusService
 *
 * A generic service to facilitate communication across components that don't have access to eachother.
 */
@Injectable( {
  providedIn: 'root'
} )
export class EventBusService {
  types = types;
  private events$: Subject<any>;

  constructor() {
    this.events$ = new Subject<any>();
  }

  publish( event: any ): void {
    this.events$.next( event );
  }

  subscribe( eventType: any ): Observable<any> {
    return this.events$.pipe(
      filter( m => m instanceof eventType )
    );
  }
}
