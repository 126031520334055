<div class="p-4">
    <div class="py-24 text-center absolute inset-0" *ngIf="!formattedStatistics && !lenderStatistics">
        <h1>Getting Statistics</h1>
        <app-dot-loader-scale class="mx-auto"></app-dot-loader-scale>
    </div>

    <div [@fadeInOut] class="m-4 absolute inset-0 top-[88px]" *ngIf="!hasData && (formattedStatistics || lenderStatistics)">
        <mat-card appearance="outlined" class="!py-24">
            <h1 class="text-center">There is no data at this time. Please check back soon.</h1>
        </mat-card>
    </div>
    <div [@fadeInOut] *ngIf="hasData && formattedStatistics && lenderStatistics">
        <mat-card appearance="outlined" class="mb-6" [ngClass]="{'!p-4': newThemeEnabled()}"
            *ngIf="applications || responseRate || appToOffer || offerToClose"
        >
            <h1>Funnel Stats</h1>

            <!-- Gutter of "2" - controlled by padding on each element, negative margin on the parent -->
            <div class="flex flex-wrap -mx-2">
                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/4 lg:w-1/2 w-full" *ngIf="applications">
                    <app-small-line-chart [chartData]="applications"></app-small-line-chart>
                </div>

                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/4 lg:w-1/2 w-full" *ngIf="responseRate">
                    <app-small-line-chart [chartData]="responseRate" [percent]="true"></app-small-line-chart>
                </div>

                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/4 lg:w-1/2 w-full" *ngIf="appToOffer">
                    <app-small-line-chart [chartData]="appToOffer" [percent]="true"></app-small-line-chart>
                </div>

                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/4 lg:w-1/2 w-full" *ngIf="offerToClose">
                    <app-small-line-chart [chartData]="offerToClose" [percent]="true"></app-small-line-chart>
                </div>
            </div>
        </mat-card>

        <mat-card appearance="outlined" class="mb-6"
                  [ngClass]="{'!p-4': newThemeEnabled()}"
            *ngIf="(lenderStatistics.awaitingResponse) ||
                (hoursToResponse) ||
                (hoursToOffer) ||
                (daysToClose)"
        >
            <h1>Time in Funnel</h1>

            <div class="flex flex-wrap -mx-2">
                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/2 w-full" *ngIf="lenderStatistics.awaitingResponse">
                    <app-awaiting-response-table [awaitingResponse]="lenderStatistics.awaitingResponse"></app-awaiting-response-table>
                </div>

                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/2 w-full" *ngIf="hoursToResponse">
                    <app-medium-line-chart [chartData]="hoursToResponse"></app-medium-line-chart>
                </div>

                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/2 w-full" *ngIf="hoursToOffer">
                    <app-medium-line-chart [chartData]="hoursToOffer"></app-medium-line-chart>
                </div>

                <div class="px-2 my-2 flex-grow flex-shrink xl:w-1/2 w-full" *ngIf="daysToClose">
                    <app-medium-line-chart [chartData]="daysToClose"></app-medium-line-chart>
                </div>
            </div>
        </mat-card>


        <mat-card
            [ngClass]="{'!p-4': newThemeEnabled()}"
            appearance="outlined"
            *ngIf="(lenderStatistics.lostDeals && lenderStatistics.lostDeals.length) ||
                (lenderStatistics.wonDeals && lenderStatistics.wonDeals.length)"
        >
            <h1>Competitive Intelligence</h1>

            <div class="mb-6"

                *ngIf="lenderStatistics.lostDeals && lenderStatistics.lostDeals.length"
            >
                <app-deal-table [header]="'Deals you lost'" [deals]="lenderStatistics.lostDeals"></app-deal-table>
            </div>
            <div class="mb-6"

                *ngIf="lenderStatistics.wonDeals && lenderStatistics.wonDeals.length"
            >
                <app-deal-table [header]="'Deals you funded'" [deals]="lenderStatistics.wonDeals"></app-deal-table>
            </div>
        </mat-card>
    </div>
</div>
