export class GetEmailTemplates {
  static readonly type = '[Templates] GetEmailTemplates';
  constructor() {}
}

export class SendEmailFromTemplate {
  static readonly type = '[Templates] SendEmailFromTemplate';
  constructor(
    public borrowerId: number,
    public dealId: number,
    public recipientEmails: string[],
    public templateId: number,
    public content: string,
    public subject: string,
  ) {}
}
