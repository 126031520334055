import {
  ColDef,
  GridApi,
  GridOptions,
} from 'ag-grid-community';

import {
  GRID_BASE_CONFIG,
  GRID_BASE_DEFAULT_COL_DEF,
} from './deal-activities-grid/deal-activities-constants';


export const sizeColsToFit = (api: GridApi) => {
  api.sizeColumnsToFit({
    defaultMinWidth: 200,
    columnLimits: [
      {
        key: 'description',
        minWidth: 400,
      },
      {
        key: 'created',
        maxWidth: 224
      },
      {
        key: 'activityType',
        maxWidth: 400
      }
    ]
  });
};


export const dealActivitiesGridConfig: GridOptions = {
  ...GRID_BASE_CONFIG,
}

export const dealActivitiesGridDefaultColDef: ColDef = {
  ...GRID_BASE_DEFAULT_COL_DEF,
}

