import { SaasFeature } from "@app/app/interfaces/saas-feature.model";

export class GetLenderReportsList {
  static readonly type = '[ThoughtspotReports] GetLenderReportsList';
  constructor() { }
}

export class GetAffiliateReportsList {
  static readonly type = '[ThoughtspotReports] GetAffiliateReportsList';
  constructor() { }
}

export class GetPartnerReportList {
  static readonly type = '[ThoughtspotReports] GetPartnerReportList';
  constructor() { }
}

export class ClearThoughtSpotReportsStore {
  static readonly type = '[ThoughtspotReports] ClearThoughtSpotReportsStore';
  constructor() { }
}

export class LogInToThoughtSpot {
  static readonly type = '[ThoughtspotReports] LogInToThoughtSpot';
  constructor(public tsSaasFeatures: SaasFeature[]) { }
}

export class LogOutOfThoughtSpot {
  static readonly type = '[ThoughtspotReports] LogOutOfThoughtSpot';
  constructor() { }
}
