<div [class.old-theme-discrete-slider]="oldThemeEnabled()">
  <div class="discrete-slider" [ngClass]="{'loading': loading}" style="display: flex;">
    <mat-slider
      style="width: 100%"
      color="primary"
      discrete="true"
      tickInterval="auto"
      [max]="marks.length - 1"
      [min]="0"
      [displayWith]="thumbLabelDisplay"
      [step]="1"
      [disabled]="disabled || loading"
      #ngSlider>
        <input matSliderThumb
            [(ngModel)]="sliderValue"
            (change)="changeSelectedValue($event.target.value)" />
    </mat-slider>
  </div>

  <div *ngIf="marks.length <= maxTicks" >
    <div class="slider-ticks">
      <div *ngFor="let mark of marks; let lastItem = last; let firstItem = first;">
        <div
          class="slider-tick"
          [ngClass]="{
            'slider-tick-last': lastItem,
            'slider-tick-first': firstItem
          }">
          {{ mark }}
        </div>
      </div>
    </div>
  </div>

  <div class="slider-min-max" *ngIf="marks.length > maxTicks">
    <div>{{ marks[0] }}</div>
    <div>{{ marks[marks.length - 1] }}</div>
  </div>

</div>
