import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LendioResponse, ObsLendioResponse } from '@app/app/interfaces/lendio-response';

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  private baseUrl = `${environment.apiUrl}/lender-portal`;

  constructor(
    private httpClient: HttpClient
  ) {}

  getSaasRolesOrder() {
    return [
      'lpxClientAdministrator',
      'lpxClientExecutive',
      'lpxClientOperationsLead',
      'lpxClientSalesperson',
      'lpxClientProcessor',
      'lpxClientDeveloper',
    ];
  }

  getScope(scopeAlias: string): ObsLendioResponse {
    return this.httpClient.get<LendioResponse>(`${this.baseUrl}/app-scopes/${scopeAlias}`);
  }
}
