<div *ngIf="documents && documents.length > 0; else NoDocs">
    <div [class.old-theme-old-documents]="oldThemeEnabled()">
    <div class="mb-6 space-x-3 mt-3">
        <button mat-flat-button [disabled]="selection.selected.length === 0" (click)="downloadSelected()">
            <mat-icon>cloud_download</mat-icon>
            Download {{ getDocsChecked() }}
        </button>
        <button mat-flat-button *ngIf="getShowZipAll()" (click)="zipAll()">
            <mat-icon>folder</mat-icon>
            Zip All
        </button>
    </div>
    <div class="documents-list">
        <div class="table !w-full">
            <div class="table-header-group border-t">
                <div class="table-cell w-1/12 py-2 px-3 sticky bg-gray-200">
                    <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="documents && selection.selected.length === documents.length"
                            [indeterminate]="getMasterIndeterminate()"
                    ></mat-checkbox>
                </div>
                <div class="table-cell w-8/12 py-2 pr-2 sticky bg-gray-200">
                    Title
                </div>
                <div class="table-cell w-3/12 py-2 sticky bg-gray-200">
                    Type
                </div>
            </div>
            <ng-container *ngFor="let document of documents; let i = index">
                <div *ngIf="document.scanStatus === 'CLEAN'" class="table-row">
                    <div class="table-cell w-1/12 px-3">
                        <mat-checkbox
                            (click)="$event.stopPropagation();"
                            (change)="$event ? selection.toggle(document) : null"
                            [checked]="selection.isSelected(document)"
                            [disabled]="document.scanStatus !== 'CLEAN'"
                        ></mat-checkbox>
                    </div>

                    <div class="table-cell w-8/12 pr-2">
                        <div class="leading-tight" [ngClass]="!document.lenderViewed ? 'font-bold' : ''">
                            <a class="cursor-pointer text-blue-500"
                            target="_blank"
                            *ngIf="document.scanStatus === 'CLEAN'"
                            (click)="viewDocument(document.id)">
                                {{ document.filename }}
                            </a>
                        </div>
                        <div class="text-xs">
                            Uploaded: {{ getDocUploaded(document.attached || document.created) }}
                        </div>
                    </div>
                    <div class="table-cell w-3/12 pt-6 text-sm">
                        {{ getDocType(document.category) }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    </div>
</div>

<ng-template #NoDocs>
    <div>
        <p class="text-center pt-10">This application does not have documents.</p>
    </div>
</ng-template>


