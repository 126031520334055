<mat-card appearance="outlined" class="mb-4" data-cy="ppp-status-component">
  <h2 [ngClass]="expanded ? 'border-b' : ''"
      (click)="toggleExpanded()"
      [ngStyle]="expanded ? {} : {'margin-bottom': 0, 'padding-bottom': 0}"
      class="flex justify-between pb-1 flex-no-wrap whitespace-no-wrap cursor-pointer">
        <span>
            <mat-icon *ngIf="showForm() || (status.reasons && status.reasons.length > 0) || status.showDetails || (applicationDetails.deal.sbaStatus && applicationDetails.deal.sbaStatus.errors.length) && status.title !== 'Queued'"
                      [ngStyle]="expanded ? {transform: 'rotate(180deg)'} : {}"
                      class="animate-rotate align-text-bottom">
                expand_more
            </mat-icon>
            SBA Submission
            <small [ngClass]="status.class"
                    class="text-xs"
                    [matTooltip]="status.tooltip">
              {{ status.title }}
            </small>
        </span>
    <span *ngIf="showSubmit()" class="pl-2" [matTooltip]="disableSubmitTooltip">
      <button color="primary"
              mat-flat-button
              [class.spinner]="submitting"
              (click)="submitToSba(); $event.stopPropagation()"
              [disabled]="submitting || disableSubmit">
              <span *ngIf="(status && status.withdrawn) else submit">{{ submitting ? '&nbsp;' : 'Resubmit' }}</span>
              <ng-template #submit>
                {{ submitting ? '&nbsp;' : 'Submit' }}
              </ng-template>
      </button>
    </span>
    <span *ngIf="showWithdraw()" class="pl-2">
      <button mat-flat-button
              class="withdraw-btn"
              (click)="withdraw(); $event.stopPropagation()">
        Cancel Submission
      </button>
    </span>
    <!-- <span *ngIf="showCheckStatus()">
      <a color="primary"
              mat-flat-button
              target="_blank"
              [href]="getStatusLink()">
        Check Status
      </a>
    </span> -->
  </h2>
  <mat-expansion-panel
      [(expanded)]="expanded"
      class="mat-elevation-z0"
      hideToggle="true">

    <app-ppp-incomplete [status]="status"></app-ppp-incomplete>

    <div *ngIf="showErrors()" class="text-sm">
      <p *ngIf="status.title === 'Failed Validation'">The following issues were returned when we attempted to submit this application to the SBA. Please address before attempting another submission.</p>
      <p *ngIf="status.title === 'Declined'">This application was declined by the SBA for the reason(s) below. If these are correctable, you may attempt to cancel this submission, correct the application, then re-submit.</p>
      <p *ngIf="status.title === 'On Hold'">This application was submitted, but there are issues with a prior SBA application which have put this application into 'On Hold' status. Further action is required in the SBA portal to correct these issues.</p>
      <div *ngFor="let error of formattedErrorsList" class="flex">
        <div class="flex-col">
          <mat-icon style="width: 14px; height: 14px; font-size: 14px; margin-right: 4px; vertical-align: middle;" class="!text-red-400">cancel</mat-icon>
        </div>
        <div class="flex-col">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="pt-3" *ngIf="status.showDetails">
      <p class="text-base font-bold !mb-0">SBA Loan Number</p>
      <p>{{ applicationDetails.deal.sbaLoanNumber }}</p>
      <p class="text-base font-bold !mb-0">Loan Amount</p>
      <span *ngIf="applicationDetails.deal.sbaStatus">
        <p>{{ applicationDetails.deal.sbaStatus.loanAmount | currency }}</p>
        <p class="text-base font-bold !mb-0">SBA Application Number</p>
        <p>{{ applicationDetails.deal.sbaStatus.sbaApplicationNumber }}</p>
      </span>
    </div>

    <div class="pt-6" *ngIf="showForm()">
      <app-naics-search [(code)]="naicsCode"></app-naics-search>

      <p class="text-base font-bold">Business Start Date</p>
      <mat-form-field appearance="fill" class="w-full !text-sm">
        <mat-label>Choose a date</mat-label>
        <input [(ngModel)]="businessStart"
                [matDatepicker]="businessStartPicker"
                matInput>
        <mat-datepicker-toggle [for]="businessStartPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #businessStartPicker
                        startView="multi-year"></mat-datepicker>
      </mat-form-field>

      <div class="w-full flex">
        <div class="w-1/2 flex-col pr-1">
          <p class="text-base font-bold">Loan Amount</p>
          <mat-form-field class="w-full !text-sm">
            <span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
            <mat-label>Loan amount</mat-label>
            <input [(ngModel)]="loanAmount"
                    autocomplete="off"
                    matInput
                    placeholder="Ex. 35000"
                    type="text">
            <button (click)="loanAmount = ''" *ngIf="loanAmount" mat-button mat-icon-button matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="w-1/2 flex-col pl-1">
            <p class="text-base font-bold">Business Zip</p>
            <mat-form-field class="w-full !text-sm">
              <mat-label>Zip</mat-label>
              <input [(ngModel)]="zipId"
                      autocomplete="off"
                      matInput
                      placeholder="Ex. 84070"
                      type="text">
              <button (click)="zipId = ''" *ngIf="zipId" mat-button mat-icon-button matSuffix>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
        </div>
      </div>

      <div class="py-4">
        <p class="text-base font-bold">Franchise</p>
        <section>
          <mat-checkbox [(ngModel)]="isFranchise">Franchise</mat-checkbox>
          <mat-checkbox [(ngModel)]="isSbaFranchise" *ngIf="isFranchise" class="pl-4">SBA Franchise</mat-checkbox>
        </section>
      </div>

      <div *ngIf="isSbaFranchise">
        <p class="text-base font-bold">SBA Franchise ID</p>
        <mat-form-field class="w-full !text-sm">
          <mat-label>SBA Franchise ID</mat-label>
          <input [(ngModel)]="sbaFranchiseId"
                  oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  autocomplete="off"
                  matInput
                  maxlength="5"
                  placeholder="Ex. 23746"
                  type="number">
          <button (click)="sbaFranchiseId = ''"
                  *ngIf="sbaFranchiseId"
                  mat-button
                  mat-icon-button
                  matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div *ngIf="applicationDetails.deal.loanProductCategoryId === 18">
        <p class="text-base font-bold">First Draw Loan Number</p>
        <mat-form-field class="w-full !text-sm">
          <mat-label>First Draw Loan Number</mat-label>
          <input [(ngModel)]="ppp1LoanNumber"
                  autocomplete="off"
                  matInput=""
                  placeholder="Ex. 23746"
                  type="number">
          <button (click)="ppp1LoanNumber = ''"
                  *ngIf="ppp1LoanNumber"
                  mat-button
                  mat-icon-button
                  matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <p class="text-base font-bold">First Draw Loan Amount</p>
        <mat-form-field class="w-full !text-sm">
          <mat-label>First Draw Loan Amount</mat-label>
          <input [(ngModel)]="ppp1LoanAmount"
                  autocomplete="off"
                  matInput=""
                  placeholder="Ex. 23746"
                  type="number">
          <span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
          <button (click)="ppp1LoanAmount = ''"
                  *ngIf="ppp1LoanAmount"
                  mat-button
                  mat-icon-button
                  matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div *ngIf="applicationDetails.deal.loanProductCategoryId === 17">
        <p class="text-base font-bold">EIDL Loan Number</p>
        <mat-form-field class="w-full !text-sm">
          <mat-label>EIDL Loan Number</mat-label>
          <input [(ngModel)]="eidlLoanNumber"
                  autocomplete="off"
                  matInput=""
                  placeholder="Ex. 23746"
                  type="number">
          <button (click)="eidlLoanNumber = ''"
                  *ngIf="eidlLoanNumber"
                  mat-button
                  mat-icon-button
                  matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <p class="text-base font-bold">EIDL Loan Amount</p>
        <mat-form-field class="w-full !text-sm">
          <mat-label>EIDL Loan Amount</mat-label>
          <input [(ngModel)]="eidlLoanAmount"
                  autocomplete="off"
                  matInput=""
                  placeholder="Ex. 23746"
                  type="number">
          <span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
          <button (click)="eidlLoanAmount = ''"
                  *ngIf="eidlLoanAmount"
                  mat-button
                  mat-icon-button
                  matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="text-right">
        <button (click)="save()"
                [class.spinner]="saving"
                [disabled]="saving"
                color="primary"
                mat-flat-button>
          {{ saving ? '&nbsp;' : 'Save' }}
        </button>
      </div>

    </div>

  </mat-expansion-panel>
</mat-card>
