import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  ErrorHandler,
  inject,
  computed
} from '@angular/core';
import { Subject, Observable, takeUntil, map, filter, take } from 'rxjs';
import { CreateNewAlert } from '@app/app/store/global-alerts/global-alerts.actions';
import { Business } from '@app/app/interfaces/business.model';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AddBusiness, FetchWithUpdatedParams, SetCurrentBusiness, SetPageIndex } from '@app/app/store/businesses/businesses-list.actions';
import { BusinessesListState } from '@app/app/store/businesses/businesses-list.state';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, SortDirection } from '@angular/material/sort';
import { CsvDownloadService } from '@app/app/services/csv-download.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { isNil, omitBy, startCase } from 'lodash';
import { StatusDisplayPipe } from '@app/app/pipes/status-display/status-display';
import { BusinessesListStateModel } from '@app/app/store/businesses/businesses-list.state';
import { FiltersState } from '@app/app/store/filters/filters.state';
import { FilterData } from '@app/app/interfaces/filters.model';
import { AddBusinessDialogComponent } from './add-business-dialog/add-business-dialog.component';
import { BorrowerToAdd } from '@app/app/interfaces/borrower.model';
import { DeletePathFilter, SetPathFilter } from '@app/app/store/filters/filters.actions';
import { BusinessesListService } from '@app/app/services/businesses-list.service';
import { BusinessesResponse } from '@app/app/interfaces/businesses-response.model';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('businessTable', {
    read: ElementRef
  }) businessTable: ElementRef;
  @Select(BusinessesListState.loading) businessesLoading$: Observable<boolean>;
  @Select(BusinessesListState.businesses) businesses$: Observable<Business[]>;
  @Select(BusinessesListState.filterBy) filterBy$: Observable<string>;
  @Select(BusinessesListState.pageIndex) pageIndex$: Observable<number>;
  @Select(BusinessesListState.pageSize) pageSize$: Observable<number>;
  @Select(BusinessesListState.sortBy) sortBy$: Observable<string>;
  @Select(BusinessesListState.sortDirection) sortDirection$: Observable<SortDirection>;
  @Select(BusinessesListState.totalCount) totalCount$: Observable<number>;
  @Select(FiltersState.filters) filters$: Observable<FilterData[]>;
  @Select(SaasFeaturesState.saasPermitted('businessOpportunityFilter')) hasBizOppFilterFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('businessDetails')) hasBusinessDetailsFeature$: Observable<boolean>;

  destroyed$ = new Subject<boolean>();
  public statusDisplayPipe: StatusDisplayPipe;
  viewLoaded = false;

  pageSize: string | number;
  pageSizeOptions = [25, 50, 100];
  businessesDataSource$: Observable<MatTableDataSource<Business>>;
  selection = new SelectionModel<Business>(true, []);
  selectAll = false;
  selectAllClicked = false; // We need to keep track of a select all click vs actual select all
  filterIndicatorText: string = '';
  filteredData: Business[];
  filterBy: string = '';
  displayedColumns = [
    'name',
    'owner',
    'status',
    'activity',
  ];
  exportLoading = false;
  currentState: Partial<BusinessesListStateModel> = {
    sortBy: '',
    sortDirection: '',
    filterBy: '',
    pageIndex: 0,
    pageSize: 25,
    countRequest: 'include' // TODO: for now we will need to grab the count - we can remove this once we find a solution to separate counts
  };
  selectAllState: Partial<BusinessesListStateModel> = {
    sortBy: '',
    sortDirection: '',
    filterBy: '',
    pageIndex: 0,
    pageSize: 25,
    countRequest: 'exclude'
  };
  totalCount: number;
  borrowerToAdd: BorrowerToAdd;

  hasBusinessDetailsFeature = false;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(
    private _store: Store,
    private _titleService: NavPageTitleService,
    private _csvDownloadService: CsvDownloadService,
    private _dialog: MatDialog,
    private _router: Router,
    private _businessesService: BusinessesListService,
    private _themeService: LendioAngularMaterialThemeService
  ) {
    this.statusDisplayPipe = new StatusDisplayPipe();
  }

  ngOnInit(): void {
    if(this.filters) {
      this.filters.pipe(
        takeUntil(this.destroyed$)
      ).subscribe(s => {
          if(s) {
            let tableFilters = s.find(f => f.name === 'table');
            this.filterBy = tableFilters?.value || '';
            this.currentState.filterBy = tableFilters?.value || '';
            this.filterIndicatorText = tableFilters?.label || '';
          }
      });
    }

    this._titleService.setTitle('Businesses');

    this.totalCount$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(tc => {
      this.totalCount = tc;
    });

    this.selectAllState['pageSize'] = this.totalCount;

    // If businessOpportunityFilter is turned on, show the check box column in the table
    this.hasBizOppFilterFeature$.pipe(
      filter(x => x!== undefined),
      take(1),
    ).subscribe((permitted: boolean) => {
      if (permitted) {
        this.displayedColumns.unshift('checkbox')
      }
    });

    this.hasBusinessDetailsFeature$.pipe(filter(x => !!x)).subscribe((permitted: boolean) => {
      this.hasBusinessDetailsFeature = permitted;
    });
  }

  ngAfterViewInit(): void {
    this.currentState.sortBy = this.sort.active;
    this.currentState.sortDirection = this.sort.direction;
    this.currentState.filterBy = this.filterBy;
    this.selectAllState = this.currentState;
    this.businessesDataSource$ = this.businesses$.pipe(
      map((businesses) => {
        const tableDataSource = new MatTableDataSource<Business>(businesses);
        tableDataSource.paginator = this.paginator;
        tableDataSource.sort = this.sort;
        tableDataSource.filteredData = businesses;
        this.filteredData = businesses;
        return tableDataSource;
      })
    );
    this.viewLoaded = true;
    this.fetchData({}, true);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  fetchData(params: Partial<BusinessesListStateModel> = {}, forceReload = false) {
    params = {...this.currentState, ...params};
    this._store.dispatch(new FetchWithUpdatedParams(params, forceReload));
  }

  handlePageEvent($event: { pageIndex: number, pageSize: number }) {
    this.currentState.pageIndex = $event.pageIndex;
    this.currentState.pageSize = $event.pageSize;
    this.fetchData($event);
  }

  handleSortEvent($event: { active: string, direction: SortDirection }) {
    this.selectAllClicked = false;
    this.selection.clear();
    this.selectAllState.sortBy = $event.active;
    this.selectAllState.sortDirection = $event.direction;
    this.currentState.sortBy = $event.active;
    this.currentState.sortDirection = $event.direction;
    this.fetchData({sortBy: $event.active, sortDirection: $event.direction});
  }

  businessStageStatus(business: Business): string {
    const separator = (business.stage && business.status) ? ': ' : '';
    return startCase(business.stage) + separator + startCase(business.status);
  }

  activityToolTip(business: Business): string {
    const activityString = business.lastActivity?.raw ? 'Activity: ' + business.lastActivity?.formatted : '';
    const dealString = business.dealLastActivity?.raw ? 'Deal: ' + business.dealLastActivity?.formatted : '';
    const offerString = business.offerLastActivity?.raw ? 'Offer: ' + business.offerLastActivity?.formatted : '';

    return `${activityString}
    ${dealString}
    ${offerString}`;
  }

  /** Whether the number of selected elements matches the total number of rows + Select all was clicked. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredData.length;
    return this.selectAll = (numSelected == numRows && this.selectAllClicked);
  }

  /**
   * Prepare table data for export and trigger download.
   */
  async exportToCsv() {
    this.exportLoading = true;
    let businesses = this.filteredData;

    // Check if some selected
    if (this.selection.hasValue() && !this.isAllSelected()) {
      businesses = this.selection.selected;
    }

    if (this.isAllSelected()) {
      this.selectAllState.pageSize = this.totalCount;
      let combinedParams = {...this.currentState, ...this.selectAllState};
      const params = omitBy(combinedParams, isNil);
      await this._businessesService.getBusinesses(params).toPromise()
      .then((response: BusinessesResponse) => {
          businesses = response.data.businesses || [];
      }).catch((err) => {
        inject(ErrorHandler).handleError(err);
        this._store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to export businesses. Please refresh and try again.'
        }));
      });
    }

    const csvData = businesses.map((business) => {
      return [
        business.name ?? '',
        business.first ?? '',
        business.last ?? '',
        business.phone ?? '',
        business.email ?? '',
        business.street ?? '',
        business.street2 ?? '',
        business.stateId ?? '',
        business.zipId ?? '',
        (business.preapprovalId ?? '').toString(),
        business.appLink ?? '',
        business.tmpPassword ?? '',
        (business.maxLineAmount ?? '').toString(),
        business.stage ?? '',
        business.status ?? '',
        business.lastActivity.raw ? new Date(
          business.lastActivity.raw * 1000 ?? Date.now() * 1000
        ).toLocaleDateString() : '',
      ];
    });

    // Add a header row
    csvData.unshift([
      'Name',
      'First name',
      'Last name',
      'Phone number',
      'Email',
      'Address line 1',
      'Address line 2',
      'State',
      'Zip',
      'Pre-approval id',
      'Application link',
      'Temporary password',
      'Max line amount',
      'Stage',
      'Status',
      'Last activity',
    ]);

    this._csvDownloadService.exportToCsv(
      `businesses-export-${new Date().toLocaleDateString()}`,
      csvData,
    );
    this.exportLoading = false;
  }

  /** Clear all rows if any are selected, otherwise select all. */
  toggleAllRows() {
    if (this.selection.selected.length > 0) {
      this.selectAll, this.selectAllClicked = false;
      this.selection.clear();
    } else {
      this.selectAll, this.selectAllClicked = true;
      this.filteredData.forEach((business) =>
        this.selection.select(business)
      );
    }
  }

  exportButtonDisabled() {
    return (
      (this.filteredData &&
        this.filteredData.length === 0) ||
      !this.selection.hasValue()
    );
  }

  applyFilter({label, value}): void {
    this.selection.clear();
    const filter: FilterData = {
      path: window.location.pathname,
      name: 'table',
      label,
      value
    };

    this._store.dispatch(new SetPathFilter(filter));
    this._store.dispatch(new SetPageIndex(0));

    this.selectAllState['filterBy'] = value;
    this.filterIndicatorText = label;
    this.fetchData({filterBy: this.selectAllState['filterBy'], countRequest: 'include'});
  }

  clearTableFilter(): void {
    this.selection.clear();
    const filter: FilterData = {
      path: window.location.pathname,
      name: 'table'
    };

    this._store.dispatch(new DeletePathFilter(filter));
    this._store.dispatch(new SetPageIndex(0));
    this.selectAllState['filterBy'] = '';
    this.filterIndicatorText = '';
    this.fetchData({filterBy: '', countRequest: 'include'});
  }

  addBusinessForm() {
    const _dialog = this._dialog.open(AddBusinessDialogComponent, {
      disableClose: true,
      data: this.borrowerToAdd,
      autoFocus: false
    });
    _dialog.afterClosed()
    .pipe(takeUntil(this.destroyed$))
    .subscribe((dialogData) => {
      if (!dialogData) {
        return;
      }
      this.borrowerToAdd = dialogData;
      this._store.dispatch(new AddBusiness(this.borrowerToAdd));
    });
  }

  handleRowClick($event: any, id: number) {
    $event.stopPropagation();
    if (!this.hasBusinessDetailsFeature) {
      return;
    }
    if (!['checkbox', 'button'].includes($event?.target?.type)) {
      // Set this business as selected so it's available elsewhere.
      this._store.dispatch(new SetCurrentBusiness(id));
      this._router.navigate([`/businesses/${id}`]);
    }
  }

  get selectionIds(): (number | undefined)[] {
    return this.selection?.selected?.map(business => business.id) || [];
  }

  get filters(): Observable<FilterData[]> {
    return this.filters$;
  }

  get pathName(): string {
    return window.location.pathname;
  }

  get tableYPosition(): number {
    if(!this.viewLoaded) {
      return 0
    }

    let matBox = this.businessTable.nativeElement;
    return matBox.querySelector('mat-table th:first-of-type')?.getBoundingClientRect().bottom - matBox.getBoundingClientRect().y || 0;
  }
}
