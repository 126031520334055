@if (newThemeEnabled()) {
    <nav class="min-h-[48px] new-theme-typography"
         mat-tab-nav-bar
         [tabPanel]="administrationNavTabPanelNew"
         *ngIf="!(importDetailsActive$ | async)">
        <ng-container *ngFor="let link of featuresConfig$ | async">
            <a mat-tab-link
                routerLinkActive
                #rla="routerLinkActive"
                *ngIf="link.permitted"
                [active]="rla.isActive"
                [routerLink]="link.link">
                {{ link.label }}
            </a>
        </ng-container>
        <a mat-tab-link
           routerLinkActive
           #rla="routerLinkActive"
           *ngIf="userCanViewDemoTab"
           [active]="rla.isActive"
           [routerLink]="'makeCsv'">
            Demo
        </a>
    </nav>
}

<div [class.new-theme]="newThemeEnabled()">
    <mat-tab-nav-panel #administrationNavTabPanelNew>
        <router-outlet></router-outlet>
    </mat-tab-nav-panel>
</div>
