<div class="app-loading__curtain">
    <mat-card class="inline app-loading__card !rounded-2xl">
        <div class="app-loading__logo-container">
            <svg class="app-loading__logo" [@pulse]="pulseState" [@slideInScaleFromBottomRight]="slideInState" width="197" height="148" viewBox="0 0 197 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M65.1543 3.79056L0.298659 143.029C-0.67247 145.105 0.843279 147.483 3.13331 147.483H74.3079C76.8538 147.483 79.1635 146.005 80.2396 143.693L145.095 4.45407C146.066 2.37813 144.551 0 142.261 0H71.0861C68.5402 0 66.2305 1.47812 65.1543 3.79056Z" fill="#192526"/>
                <path d="M193.921 85.6002C189.472 84.871 184.905 84.49 180.259 84.49C142.208 84.49 110.023 109.638 99.3667 144.239C98.8353 145.96 100.134 147.708 101.932 147.708H167.044C168.297 147.708 169.426 146.978 169.951 145.848L195.928 89.3842L195.915 89.3711C196.65 87.7681 195.659 85.8893 193.914 85.6002H193.921Z" fill="#192526"/>
            </svg>
        </div>
    </mat-card>
</div>
