// Adapted from pipeline/src/app/services/csv-data/csv-data.service.ts
import { ElementRef, Injectable } from '@angular/core';
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";
import {
  LendioSnackBarOptions
} from "@app/app/components/lendio-angular-material-theme/lendio-snackbar/lendio-snackbar-options";

@Injectable({
  providedIn: 'root',
})
export class CsvDownloadService {
  constructor(private _snackbarService: LendioSnackbarService) {
  }
  exportToCsv(filename: string, rows: string[][], notify?: LendioSnackBarOptions) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const csvContent = rows
      .map((row) =>
        row
          .map((value) => {
            // Escape commas and double quotes. Thanks, ChatGPT.
            if (value.includes(',') || value.includes('"')) {
              return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
          })
          .join(separator)
      )
      .join('\n');

    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    if (notify) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob'
      xhr.open('GET', url);
      xhr.onload = () => {
        this._snackbarService.open({
          message: notify.message,
          canDismiss: true,
          duration: 4000,
          prefix: {
            type: 'mat-icon',
            backgroundColor: '#7A8799',
            matIconLabel: 'done',
            matIconColor: '#FFFFFF'
          }
        })
      }
      xhr.send();
    }

    if (link.download !== undefined) {
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
