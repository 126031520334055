<h2 mat-dialog-title>Delete Group</h2>
<mat-dialog-content class="mat-typography">
    <p>This will delete the entire exception group, and
        the qualifications criteria listed beneath in
        the "Then, in order to qualify" section.</p>
</mat-dialog-content>
<mat-dialog-actions class="!py-4" align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="warn"
            [mat-dialog-close]="true"
            FocusInitial
            data-cy="delete-group-dialog-component-confirm-delete">
        Delete
    </button>
</mat-dialog-actions>
