import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadContractDialogComponent } from '../upload-contract-dialog/upload-contract-dialog.component';
import { Alert } from 'src/app/interfaces/alert.model';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeOut, fadeIn } from 'ng-animate';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';

@Component({
  selector: 'app-upload-contract',
  templateUrl: './upload-contract.component.html',
  styleUrls: ['../../new-offer-display/forms/offer-form-styles.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, { params: { timing: 0.05 } })
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, { params: { timing: 0.3 } })
      )
    ])
  ]
})
export class UploadContractComponent implements OnInit, OnChanges {

  @Input() dealId: number;
  @Input() sbaLoanNumber: string;
  @Input() approvedLoanAmount: number;
  @Input() loading: boolean;
  @Input() applicationDetails: ApplicationDetails;
  @Input() expiration: Date;
  @Input() localeCurrencySymbol: string;
  @Output() newPPPContract = new EventEmitter<any>();

  uploadContract: any = {
    sbaApproval: '',
    amount: undefined,
    expiration: new Date(),
    received: new Date(),
    documentIds: [],
  };
  uploadContractForm: FormGroup;
  alerts: Alert[] = [];
  submitting = false;

  constructor(public dialogRef: MatDialogRef<UploadContractDialogComponent>) {}

  ngOnInit(): void {
    this.uploadContract.sbaApproval = this.sbaLoanNumber;
    this.uploadContract.amount = this.approvedLoanAmount;
    this.uploadContract.expiration = new Date(this.expiration);
    this.uploadContractForm = new FormGroup ({
      amount: new FormControl(this.uploadContract.amount, [
        Validators.required,
      ]),
      sbaApproval: new FormControl(this.uploadContract.sbaApproval, [
        Validators.required,
      ]),
      received: new FormControl(this.uploadContract.received),
      documentIds: new FormControl(this.uploadContract.documentIds),
      expiration: new FormControl(this.uploadContract.expiration, [
        Validators.required
      ])
    });
    this.validateAllFormFields(this.uploadContractForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === false
      && changes.loading.currentValue === true
    ) {
      this.submitting = true;
    }
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === true
      && changes.loading.currentValue === false
    ) {
      setTimeout(() => this.close(), 0);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.uploadContractForm.value.send = 1;
    this.newPPPContract.emit(this.uploadContractForm.value);
  }

  save() {
    this.uploadContractForm.value.send = 0;
    this.newPPPContract.emit(this.uploadContractForm.value);
  }

  checkError(controlName: string, errorName: string) {
    return this.uploadContractForm.controls[controlName].hasError(errorName);
  }

  attachDocumentId(documentId: number) {
    this.uploadContract.documentIds.push(documentId);
  }

  detachDocumentId(documentId: number) {
    this.uploadContract.documentIds = this.uploadContract.documentIds.filter((d: number) => d !== documentId);
  }

  showAlert(alert: Alert) {
    this.alerts.push(alert);
  }
}
