<div class="grid-tooltip" *ngIf="!hideToolTip">
    <div class="grid-tooltip-text">
        This value displays total
        <br>
        commission from a funded
        <br>
        deal.
    </div>
    <div class="grid-tooltip-text">
        The earned percentage
        <br>
        of this amount will be
        <br>
        outlined in your agreement.
    </div>
</div>
