<a [routerLink]="'/deal/' + deal.id" class="clickable-card cursor-pointer">
    <mat-card appearance="outlined" class="mb-3">
        <mat-card-content>
            <div class="w-full !grid lg:grid-cols-3 grid-cols-2 auto-cols-max justify-items-start items-center">
                <div class="">
                    <div class="font-bold text-lg">{{ deal.loan_product.name }}</div>
                    <div class="font-normal">
                        {{ deal.loan_product_category.name || '-' }}
                    </div>
                </div>
                <div class="justify-self-end self-start lg:justify-self-start lg:self-center">
                    <lendio-status-display [status]="currentStatusOption">
                    </lendio-status-display>
                </div>
                <div class="hidden lg:block">
                    <div class="font-bold">Last Update</div>
                    <div class="font-normal">
                        {{ (deal.modified | date : "MMMM d, Y 'at' h:mma") || '-' }}
                    </div>
                </div>
                <div class="block lg:hidden">
                    <div class="mt-6"><span class="font-bold mr-6">Last Update</span>{{ (deal.modified | date : "MMMM d, Y 'at' h:mma") || '-' }}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</a>
