export class GetFundingDeskRequests {
    static readonly type = '[FundingDeskRequest] GetFundingDeskRequests';
    constructor(public dealId: number) {}
}

export class CreateFundingDeskRequest {
    static readonly type = '[FundingDeskRequest] CreateFundingDeskRequest';
    constructor(public dealId: number, public description: string, public type: string) {}
}

export class CreateFundingDeskRequestComment {
    static readonly type = '[FundingDeskRequest] CreateFundingDeskRequestComment';
    constructor(public dealId: number, public requestId: number, public description: string) {}
}

export class ClearFundingDeskRequestsStore {
    static readonly type = '[FundingDeskRequest] ClearFundingDeskRequestsStore';
    constructor() {}
}
