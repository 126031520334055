import { AfterViewInit, Component, HostListener, OnInit, signal, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Select, Store } from '@ngxs/store';
import { ImportsState } from '@app/app/store/lender-imports/import.state';
import { Observable, Subject, map } from 'rxjs';
import { Import } from '@app/app/interfaces/import.model';
import { FetchWithUpdatedParams, SubscribeToImportStateUpdates } from '@app/app/store/lender-imports/import.actions';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { FileImportDialogComponent } from '../file-import/file-import-dialog.component';
import { ImportFailDialogComponent } from '../import-fail-dialog/import-fail-dialog.component';
import { FormGroup } from '@angular/forms';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { Router } from "@angular/router";
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})

export class ImportsComponent implements OnInit, AfterViewInit {

destroyed$ = new Subject<boolean>();

@Select(ImportsState.filterBy) filterBy$: Observable<string>;
@Select(ImportsState.imports) imports$: Observable<Import[]>;
@Select(ImportsState.pageIndex) pageIndex$: Observable<number>;
@Select(ImportsState.pageSize) pageSize$: Observable<number>;
@Select(ImportsState.sortBy) sortBy$: Observable<string>;
@Select(ImportsState.sortDirection) sortDirection$: Observable<SortDirection>;
@Select(ImportsState.total) total$: Observable<number>;
@Select(ImportsState.loading) loading$: Observable<boolean>;
@Select(ImportsState.latestImportStillProcessing) latestImportStillProcessing$: Observable<boolean>;
@Select(SaasFeaturesState.saasPermitted('administrationImport', 'lpxAdministrationImport.write')) permittedToImport$: Observable<boolean>;

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort, {static: false}) sort: MatSort;

@HostListener('window:beforeunload', ['$event'])
    handleClose(event: Event) {
      if(this.fileUpload.canUnload) {
        return true;
      } else {
        event.preventDefault();
        return event.returnValue = false;
      }
    }

  columnsToDisplay = [
    'created',
    'importedBy',
    'type',
    'totalRecords',
    'importedRecords',
    'updatedRecords',
    'skippedRecords',
    'failedRecords',
    'status',
    'description'
  ];

  fileUpload = {
    type: '',
    description: '',
    fileName: '',
    presignedUrl: '',
    file: File,
    progress: 0,
    canUnload: true
  };

  file: File;

  tableDataSource$: Observable<MatTableDataSource<Import>>;
  emptyTable = signal(false);

  pageSizeOptions: number[] = [20, 50];
  defaultPageSize: number = 20;

  fileUploadForm: FormGroup;

  emptyType = 'documents';
  emptyLabelContent = "You don't have any past imports.";

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private _pageService: NavPageTitleService,
    private _router: Router

    ) {
    const user = this.store.selectSnapshot(AuthState.user);
    if (
      !user?.isLender()
      && !user?.permittedTo('lpxAdminPanel')
      && user?.hasEmbeddedChildAffiliate()
      && (
        user?.permittedTo('embeddedLegal.write')
        || user?.permittedTo('embeddedConfig.write')
        || user?.permittedTo('embeddedCTA.write')
      )
    ) {
      this._router.navigateByUrl('administration/embedded-app')
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchWithUpdatedParams({}, true));
    this._pageService.setTitle('Administration');
    this.store.dispatch(new SubscribeToImportStateUpdates());
  }

  ngAfterViewInit(): void {
    this.tableDataSource$ = this.imports$.pipe(
      map((imports) => {
        const tableDataSource = new MatTableDataSource<Import>(imports);
        tableDataSource.paginator = this.paginator;
        tableDataSource.sort = this.sort;
        if(imports.length === 0) {
          this.emptyTable.set(true);
        }
        return tableDataSource;
      })
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  handlePageEvent(e: PageEvent) {
    this.store.dispatch(new FetchWithUpdatedParams({
      pageIndex: e.pageIndex,
      pageSize: e.pageSize
    }, false
    ));
  }

  handleSortEvent(e: any) {
    const params = {
      pageIndex: 0,
      sortBy: e.active === 'importedBy' ? 'userId' : e.active,
      sortDirection: e.direction,
      filterBy: e.value,
    }
    this.store.dispatch(new FetchWithUpdatedParams(params, false));
  }

  openDialog() {
    const _dialog =  this.dialog.open(FileImportDialogComponent,
      {
        width: '400px',
        autoFocus: false,
        data: this.fileUpload = {
          type: '',
          description: '',
          fileName: '',
          presignedUrl: '',
          file: File,
          progress: 0,
          canUnload: true
        }
      });
    _dialog.afterClosed().subscribe(dialogData => {
      if(!dialogData) {
        return;
      }
      if(dialogData.canUnload) {
        this.fileUpload.canUnload = true;
      }
      this.fileUpload = dialogData;
      this.file = dialogData.file;
      this.emptyTable.set(false);
    });
  }

  openImportFailedDialog(rowData: any) {
    const _dialog =  this.dialog.open(ImportFailDialogComponent,
      {
        panelClass: 'custom-dialog-container',
        maxWidth: '320px',
        data: {...rowData, page: 'imports'}
      });
  }

  viewFailReasonIfFailed(row: any) {
    if(row.status === 'failed') {
      this.openImportFailedDialog(row);
    }
  }
}
