<button #dealStatusDropdownButton
        [matMenuTriggerFor]="statusMenu"
        class="lendio-status-change-menu-trigger"
        appMatchMenuWidth [trigger]="MenuWidthTrigger">
    <lendio-status-display [status]="currentStatusOption"
                           [showToggle]="true">
    </lendio-status-display>
</button>

<mat-menu #statusMenu="matMenu">
    <div #MenuWidthTrigger></div>
    <button mat-menu-item [matMenuTriggerFor]="application">Application</button>
    <button mat-menu-item [matMenuTriggerFor]="underwriting">
        Underwriting
    </button>
    <button mat-menu-item [matMenuTriggerFor]="closing">Closing</button>
    <button mat-menu-item [matMenuTriggerFor]="funded">Funded</button>
    <button mat-menu-item [matMenuTriggerFor]="inactive">Inactive</button>
</mat-menu>

<mat-menu #application="matMenu">
    <button
        mat-menu-item
        *ngFor="let option of _dealStatusOptionsByStage.application"
        (click)="_handleDealStatusMenuSelection(option)"
        [disabled]="option === currentStatusOption"
    >
        <span>{{ option.display }}</span>
    </button>
</mat-menu>
<mat-menu #underwriting>
    <button
        mat-menu-item
        *ngFor="let option of _dealStatusOptionsByStage.underwriting"
        (click)="_handleDealStatusMenuSelection(option)"
        [disabled]="option === currentStatusOption"
    >
        <span>{{ option.display }}</span>
    </button>
</mat-menu>
<mat-menu #closing>
    <button
        mat-menu-item
        *ngFor="let option of _dealStatusOptionsByStage.closing"
        (click)="_handleDealStatusMenuSelection(option)"
        [disabled]="option === currentStatusOption"
    >
        <span>{{ option.display }}</span>
    </button>
</mat-menu>
<mat-menu #funded>
    <button
        mat-menu-item
        *ngFor="let option of _dealStatusOptionsByStage.funded"
        (click)="_handleDealStatusMenuSelection(option)"
        [disabled]="option === currentStatusOption"
    >
        <span>{{ option.display }}</span>
    </button>
</mat-menu>
<mat-menu #inactive>
    <button
        mat-menu-item
        *ngFor="let option of _dealStatusOptionsByStage.inactive"
        (click)="_handleDealStatusMenuSelection(option)"
        [disabled]="option === currentStatusOption"
    >
        <span>{{ option.display }}</span>
    </button>
</mat-menu>
