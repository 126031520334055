import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AssignUserToDeal,
  AttachApplicationLabel,
  ChangeDealStatus,
  ClearApplicationDetailsStore,
  DetachApplicationLabel,
  GetApplicationDetails,
  GetApplicationFields,
  ResetSbaStatus,
  UnassignUserFromDeal,
  UpdateDealStatus
} from './application-details.actions';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationDetailsService } from '../../services/application-details.service';
import { ApplicationDetails } from '../../interfaces/application-details.model';
import { Injectable } from '@angular/core';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { DealsService } from '@app/app/services/deals.service';
import { patch } from '@ngxs/store/operators';

export class ApplicationDetailsStateModel {
  applicationDetails: ApplicationDetails;
  applicationFields: any | [];
}

@State<ApplicationDetailsStateModel>({
  name: 'applicationDetails',
})
@Injectable()

export class ApplicationDetailsState {

  @Selector()
  static applicationDetails(state: ApplicationDetailsStateModel) {
    return state.applicationDetails;
  }

  @Selector()
  static applicationFields(state: ApplicationDetailsStateModel) {
    return state.applicationFields;
  }

  /**
   * Determine app data associated w/ deal
   * @param state
   */
  @Selector()
  static dealApplicationDetails(state: ApplicationDetailsStateModel) {
    const applicationData = state.applicationDetails;
    const dealData = applicationData?.deal;
    if (!dealData) {
      return;
    }

    return dealData.current_application;
  }

  constructor(
    private appDetailsService: ApplicationDetailsService,
    private _dealsService: DealsService,
    private store: Store
  ) { }

  @Action(GetApplicationDetails)
  getApplicationDetails({ patchState }: StateContext<ApplicationDetailsStateModel>, { dealId }: GetApplicationDetails) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.appDetailsService.getApplicationDetails(dealId).pipe(catchError(err => {
      if (err.status === 403) {
        if (sessionStorage.getItem('Forbidden')) {
          sessionStorage.removeItem('Forbidden');
        }

        sessionStorage.setItem('Forbidden', 'True');
      } else {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to fetch this borrower\'s application details. Please refresh the page to try again.'
        }));
        return throwError(err);
      }
    }), tap(response => {
      patchState({ applicationDetails: response.data });
    }));
  }

  @Action(ResetSbaStatus)
  resetSbaStatus({ getState, patchState }: StateContext<ApplicationDetailsStateModel>, { }: ResetSbaStatus) {
    const currentState = { ...getState().applicationDetails };
    if (currentState && Object.keys(currentState).length > 0) {
      const currentStateDeal = { ...currentState.deal };
      const currentStateDealSbaStatus = { ...currentStateDeal.sbaStatus };
      currentStateDealSbaStatus.sbaStatus = null;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentStateDeal.sbaStatus = currentStateDealSbaStatus;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentState.deal = currentStateDeal;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      patchState({ applicationDetails: currentState });
    }
  }

  @Action(GetApplicationFields)
  getApplicationFields({ patchState }: StateContext<ApplicationDetailsStateModel>, { dealId }: GetApplicationFields) {
    return this.appDetailsService.getApplicationFields(dealId).pipe(catchError(err => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to fetch this borrower\'s application fields. Please refresh the page to try again.'
      }));
      return throwError(err);
    }), tap(response => {
      patchState({ applicationFields: response.data.applicationFields });
    }));
  }

  @Action(ClearApplicationDetailsStore)
  clearApplicationDetailsStore({ patchState }: StateContext<ApplicationDetailsStateModel>, { }: ClearApplicationDetailsStore) {
    patchState({ applicationDetails: undefined });
  }

  @Action(DetachApplicationLabel)
  // eslint-disable-next-line max-len
  detachApplicationLabel({ getState, patchState }: StateContext<ApplicationDetailsStateModel>, { labelId, dealId }: DetachApplicationLabel) {
    if (getState().applicationDetails && dealId === getState().applicationDetails!.deal.id) {
      const currentState = { ...getState().applicationDetails };
      if (currentState && Object.keys(currentState).length > 0) {
        const currentStateDeal = { ...currentState.deal };
        const labelIndex = currentStateDeal.lender_labels!.findIndex(x => x.id === labelId);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const currentStateDealLabels = [...currentStateDeal.lender_labels];
        currentStateDealLabels.splice(labelIndex, 1);
        currentStateDeal.lender_labels = currentStateDealLabels;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        currentState.deal = currentStateDeal;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        patchState({ applicationDetails: currentState });
      }
    }
  }

  @Action(AttachApplicationLabel)
  attachApplicationLabel({ getState, patchState }: StateContext<ApplicationDetailsStateModel>, { label, dealId }: AttachApplicationLabel) {
    if (getState().applicationDetails && dealId === getState().applicationDetails!.deal.id) {
      const currentState = { ...getState().applicationDetails };
      if (currentState && Object.keys(currentState).length > 0) {
        const currentStateDeal = { ...currentState.deal };
        const currentStateDealLabels = [...currentStateDeal.lender_labels];
        currentStateDealLabels.push(label);
        currentStateDeal.lender_labels = currentStateDealLabels;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        currentState.deal = currentStateDeal;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        patchState({ applicationDetails: currentState });
      }
    }
  }

  /**
   * Update a deal’s status using public lender API endpoint.
   */
  @Action(ChangeDealStatus)
  changeDealStatus(
    ctx: StateContext<ApplicationDetailsStateModel>,
    { dealId, status, fund }: ChangeDealStatus
  ) {
    const currentState = ctx.getState();
    if (currentState.applicationDetails !== null && dealId === currentState.applicationDetails.deal.id) {
      return this._dealsService.changeDealStatus(dealId, status, fund).pipe(
        catchError((err: any) => {
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message:
                'Unable to change deal status. Please refresh the page to try again.',
            })
          );
          // TODO: Report to Rollbar?
          return throwError(() => err);
        }),
        tap(() => {
          return ctx.setState(patch<ApplicationDetailsStateModel>({
            applicationDetails: patch({
              deal: patch({
                // When we send a status change to API with fund = true
                // we can't set the stage:status to 'funded:current' directly.
                // Instead, the status is set to "funding".
                // This patches stage:status to the correct values without having to
                // hit API again to pull the updated stage:status.
                status: fund === true ? 'current' : status,
                stage: fund === true ? 'funded' : ctx.getState().applicationDetails.deal.stage
              })
            })
          }));
        })
      );
    }
  }

  /**
   * Update a deal’s status using "Marketplace" API endpoint.
   * i.e. Not public lender API endpoint.
   */
  @Action(UpdateDealStatus)
  updateDealStatus(
    ctx: StateContext<ApplicationDetailsStateModel>,
    { dealId, statusOption, reason, otherReason }: UpdateDealStatus
  ) {
    const currentState = ctx.getState();
    if (currentState.applicationDetails !== null && dealId === currentState.applicationDetails.deal.id) {
      return this._dealsService.updateDealStatus(dealId, statusOption, reason, otherReason).pipe(
        catchError((err: any) => {
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Unable to change deal status.',
            })
          );
          // TODO: Report to Rollbar?
          throw err;
        }),
        tap((response) => {
          // API doesn’t respond with an error HTTP code, so we can’t solely
          // rely on the catchError block above to detect errors.
          if (response.status && response.status.toLowerCase() !== 'success') {
            this.store.dispatch(new CreateNewAlert({
              level: 'error',
              message: 'Unable to change deal status.'
            }));

            // TODO: Report to Rollbar?
            throw new Error('Unable to change deal status');
          }

          return ctx.setState(patch<ApplicationDetailsStateModel>({
            applicationDetails: patch({
              deal: patch({
                stage: statusOption.stage,
                status: statusOption.value,
              })
            })
          }));
        })
      );
    }
  }

  @Action(AssignUserToDeal)
  assignUserToDeal(
    ctx: StateContext<ApplicationDetailsStateModel>,
    { dealId, userId }: AssignUserToDeal,
  ) {
    return this._dealsService.assignUserToDeal(dealId, userId).pipe(
      catchError((err) => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to assign user to deal.'
        }));
        return throwError(err);
      }),
      tap((res) => {
        // Refresh details so assignment appears on other tabs.
        this.store.dispatch(new GetApplicationDetails(dealId));
      })
    )
  }

  @Action(UnassignUserFromDeal)
  unassignUserFromDeal(
    ctx: StateContext<ApplicationDetailsStateModel>,
    { dealId, userId }: AssignUserToDeal,
  ) {
    return this._dealsService.unassignUserFromDeal(dealId, userId).pipe(
      catchError((err) => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to unassign user from deal.'
        }));
        return throwError(err);
      }),
      tap(() => {
        // Refresh details so assignment appears on other tabs.
        this.store.dispatch(new GetApplicationDetails(dealId));
      })
    )
  }

}
