import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { AdverseActionNoticeService } from '../../services/adverse-action-notice.services';
import { GetAdverseActionNotice } from './adverse-action-notice.actions';
import { AdverseActionNotice } from '../../interfaces/adverse-action-notice.model';

export class AdverseActionNoticeStateModel {
    adverseActionNotice: AdverseActionNotice | null;
}

@State<AdverseActionNoticeStateModel>({
    name: 'adverseActionNotice',
    defaults: {
        adverseActionNotice: null,
    }
})
@Injectable()

export class AdverseActionNoticeState {

    @Selector()
    static adverseActionNotice(state: AdverseActionNoticeStateModel) {
        return state.adverseActionNotice;
    }

    constructor(
        private adverseActionNoticeService: AdverseActionNoticeService,
        private store: Store
    ) {}

    @Action(GetAdverseActionNotice)
    GetAdverseActionNotice({ patchState }: StateContext<AdverseActionNoticeStateModel>, {dealId}: GetAdverseActionNotice){
        return this.adverseActionNoticeService.postAdverseActionNotice(dealId)
        .pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to fetch adverse action notice.'
            }));
            return throwError(err);
        }), tap(response => {
            patchState({ adverseActionNotice: response });
        }));
    }

}
