import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Qualifier, QualifierValue } from '@app/app/interfaces/qualifier.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { DecisioningState } from '@app/app/store/decisioning/decisioning.state';
import { Observable, Subject, catchError, of, takeUntil } from 'rxjs';
import { Decisioning } from '@app/app/interfaces/decisioning.model';
import { AttributesMap } from '@app/app/services/decisioning.service';
import { DecisioningQualifierOverride } from '@app/app/interfaces/decisioning-qualifier-override.model';
import { format } from 'date-fns';
import { FormControl } from '@angular/forms';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { Business } from '@app/app/interfaces/business.model';
import { PostDecisioningOverrides } from '@app/app/store/decisioning/decisioning.actions';
import { Deal } from '@app/app/interfaces/deal.model';
import { first } from 'lodash';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';

@Component({
  selector: 'app-decisioning-overrides-dialog',
  templateUrl: 'decisioning-overrides-dialog.component.html',
  styleUrls: ['./decisioning-overrides-dialog.component.scss'],
})
export class DecisioningOverridesDialogComponent implements OnInit, OnDestroy {
  currentOverrideScreen: string;
  overrideDialogTitle: string;
  deal: Deal;
  rejectedQualifierValues: QualifierValue[];
  originalRejectedQualifierValues: QualifierValue[];
  qualifierData: Qualifier[] = [];
  attributesMap: AttributesMap[];
  currentOverrideQualifier: Qualifier;
  currentOverrideQualifierValue: QualifierValue;
  overridable: boolean;
  provideAlternativeValue: boolean = false;
  disableAlternativeValueCheckbox: boolean = false;
  alternativeValue?: string;
  alternativeValueDate = new FormControl(new Date());
  overrideReason: string = '';
  overrides: DecisioningQualifierOverride[] = [];
  overrideCount: number = 0;
  business: Business;
  user: AuthUser;
  private destroyed$ = new Subject<void>();

  @Select(DecisioningState.attributesMap) attributesMap$: Observable<AttributesMap[]>;
  @Select(DecisioningState.decisioning) decisioning$: Observable<Decisioning[]>;
  @Select(BusinessState.business) business$: Observable<Business>;
  @Select(AuthState.user) currentUser$: Observable<AuthUser>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      deal: Deal,
      rejectedQualifierValues: QualifierValue[],
    },
    private store: Store,
  ) {
    this.deal = data.deal;
    this.rejectedQualifierValues = data.rejectedQualifierValues;
    this.originalRejectedQualifierValues = data.rejectedQualifierValues;
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    this.toOverridesList();
    this.decisioning$.pipe(takeUntil(this.destroyed$)).subscribe((decisioning: Decisioning[]) => {
      catchError(() => {
        return of();
      })
      this.qualifierData = decisioning[0].product.decisioningQualifiers;
    });
    this.attributesMap$.pipe(takeUntil(this.destroyed$)).subscribe((attributesMap: AttributesMap[]) => {
      catchError(() => {
        return of();
      })
      this.attributesMap = attributesMap;
    });
    this.business$.pipe(takeUntil(this.destroyed$)).subscribe((business: Business) => {
      catchError(() => {
        return of();
      })
      this.business = business;
    });
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user: AuthUser) => {
      this.user = user;
    });
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * @param qualifierValue 
   */
  overrideQualifier(qualifierValue: QualifierValue) {
    if (qualifierValue.override) {
      this.overrideReason = qualifierValue.override.reason || '';
      if (qualifierValue.override.type === 'override') {
        this.provideAlternativeValue = true;
        this.alternativeValue = qualifierValue.actualValue;
        if (this.attributesMap[qualifierValue.qualifierField].type === 'date-input') {
          this.alternativeValueDate = new FormControl(new Date(qualifierValue.actualValue + 'T00:00:00'));
        }
        if ((this.attributesMap[qualifierValue.qualifierField].type === 'number' || this.attributesMap[qualifierValue.qualifierField].type === 'currency') && this.alternativeValue) {
          this.alternativeValue = this.alternativeValue.replace(/\D/g, '');
        }
      }
    }
    this.currentOverrideQualifierValue = qualifierValue;
    this.currentOverrideScreen = 'overrideQualifier';
    this.overrideDialogTitle = 'Override: ' + qualifierValue.qualifierLabel;
    this.currentOverrideQualifier = this.qualifierData.filter(qualifier => {
      return qualifier.field === qualifierValue.qualifierField;
    })[0];
    this.overridable = !(
      qualifierValue.qualifierField.includes('.') ||
      this.currentOverrideQualifier.loanProductPricingRuleSetId ||
      this.attributesMap[qualifierValue.qualifierField].type === 'bool' ||
      this.attributesMap[qualifierValue.qualifierField].type === 'longBool' ||
      this.attributesMap[qualifierValue.qualifierField].type === 'document'
    );
  }

  toggleProvideAlternativeValue() {
    this.provideAlternativeValue = !this.provideAlternativeValue;
  }

  cancelOverrides() {
    this.currentOverrideScreen = 'cancelOverrides';
    this.overrideDialogTitle = 'Cancel overrides?';
  }

  toOverridesList() {
    this.currentOverrideScreen = 'overrideList';
    this.overrideDialogTitle = 'Select values that need overrides';
    this.clearOverrideFields();
  }

  clearOverrideFields() {
    this.overrideReason = '';
    this.alternativeValue = undefined;
    this.alternativeValueDate = new FormControl(new Date());
    this.provideAlternativeValue = false;
  }

  resetQualifierValues() {
    this.rejectedQualifierValues = this.originalRejectedQualifierValues;
  }

  overrideCurrentQualifier() {
    if (this.attributesMap[this.currentOverrideQualifierValue.qualifierField].type === 'date-input' && this.alternativeValueDate.value) {
      this.alternativeValue = format(this.alternativeValueDate.value, 'yyyy-MM-dd');
    }
    const qualifierSource = this.identifySource(this.currentOverrideQualifierValue.qualifierField);
    this.overrides.push(
      {
        decisioningQualifierId: this.currentOverrideQualifier.id,
        decisioningQualifierField: this.currentOverrideQualifierValue.qualifierField,
        borrowerValueSourceId: qualifierSource.usedSource ? qualifierSource.usedSource : 1,
        type: this.provideAlternativeValue ? 'override' : 'exclusion',
        reason: this.overrideReason,
        decisioningOverrideValue: this.alternativeValue ? this.alternativeValue : null
      }
    );
    let rejectedQualifiersCopy = structuredClone(this.rejectedQualifierValues);
    this.rejectedQualifierValues.forEach((qualifierValue, index) => {
      if (qualifierValue.qualifierField === this.currentOverrideQualifierValue.qualifierField) {
        rejectedQualifiersCopy[index].override = {
          'type': this.provideAlternativeValue ? 'override' : 'exclusion',
          'reason': this.overrideReason,
          'oldBvValue': this.currentOverrideQualifierValue.actualValue,
          'createdByUserName': this.user.fullName
        };
        let formattedAlternativeValue = this.alternativeValue;
        if ((this.attributesMap[this.currentOverrideQualifierValue.qualifierField].type === 'number' || this.attributesMap[this.currentOverrideQualifierValue.qualifierField].type === 'currency') && this.alternativeValue) {
          formattedAlternativeValue = this.formatNumber(parseFloat(this.alternativeValue.replace(/\D/g, '') || ''), this.attributesMap[this.currentOverrideQualifierValue.qualifierField].type);
        }
        rejectedQualifiersCopy[index].actualValue = formattedAlternativeValue ? formattedAlternativeValue : this.currentOverrideQualifierValue.actualValue;
        rejectedQualifiersCopy[index].status = 'accepted'; //when we dynamically decision, this should be dynamic
      }
    })
    this.rejectedQualifierValues = rejectedQualifiersCopy;
    this.clearOverrideFields();
    this.toOverridesList();
    this.countOverrides();
  }

  countOverrides() {
    this.overrideCount = 0;
    this.rejectedQualifierValues.forEach(rejectedQualifier => {
      if (rejectedQualifier.override) {
        this.overrideCount++;
      }
    })
  }

  saveAndPostOverrides() {
    this.store.dispatch(new PostDecisioningOverrides(this.business.id, this.deal.id, this.overrides));
    this.clearOverrideFields();
  }

  /**
   * @param number 
   * @param type 
   * @return string
   */
  formatNumber(number: number, type: string): string {
    return (type === 'currency' ? '$' : '') + this.addCommas(number);
  }

  /**
   * @param number 
   * @return string
   */
  addCommas(number: number): string {
    const parts = (number * 1).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  /**
   * Identify original values derived source
   * @param qualifierField
   */
  identifySource(qualifierField: string): any {
    const source = this.rejectedQualifierValues.filter((qualifier: any) => {
      return qualifier.qualifierField === qualifierField;
    });

    return first(source);
  }
}
