<ng-container *ngFor="let row of rows; let i = index">
  <div class="flex text-sm font-light border-b border-gray-300 leading-none w-full py-1 items-center justify-between mb-1"
       (mouseenter)="rowHovered[i] = true"
       (mouseleave)="rowHovered[i] = false">
    <div class="leading-normal py-3 even-box">
      {{ row.display }}{{ getTimeDisplay(row) }}
    </div>
      <div class="even-box">
          <mat-checkbox
              [(ngModel)]="row.requiredForOffer"
              (change)="toggleRequiredForOffer(row)"
              [disabled]="!hasWriteAccess"
          >
              Required For Offer
          </mat-checkbox>
      </div>
    <div class="even-box" *ngIf="hasWriteAccess">
      <button mat-button color="warn"
        class="leading-none !py-0"
        (click)="removeRequirement(row)"
        *ngIf="row.removable"
        [ngClass]="{'invisible': !rowHovered[i]}"
      >
        Remove
      </button>
      <div class="text-red-400 py-3 text-sm pr-3"
        *ngIf="!row.removable"
        [ngClass]="{'invisible': !rowHovered[i]}"
      >
        Cannot be removed
      </div>
    </div>
  </div>
</ng-container>


<button mat-button color="primary" class="!px-1"
        [disabled]="!canAddRequirement()"
        *ngIf="hasWriteAccess && showButton" (click)="openAddRequirementsDialog()">
  <mat-icon matPrefix class="!text-green-400 !text-base pb-1" [inline]="true">add_circle</mat-icon>
  Add New Question
</button>
