interface DemoBorrower {
  businessStartDate: string;
  industryNaics: number;
  name: string;
  phone: string;
  street: string;
  street2: string;
  city: string;
  stateId: string;
  postalCode: string;
  postalCodeExtra: string;
  first: string;
  last: string;
  website: string;
  taxId: string;
  creditScore: string;
  businessType: string;
}

interface DemoContact {
  first: string;
  last: string;
  workPhoneCountryCodeId: number;
  workPhone: string;
  workPhoneExt: string;
  mobilePhoneCountryCodeId: number;
  mobilePhone: string;
  taxId: string;
  taxIdType: string;
  birthDate: string;
  street: string;
  street2: string;
  street3: string;
  city: string;
  branchId: string;
  stateId: string;
  postalCode: string;
  postalCodeExtra: string;
  creditScore: string;
  creditScoreSource: string;
  creditScoreDate: string;
  title: string;
  percentOwnership: number;
  businessId?: string;
}

export const BATCH_ROWS: number = 10000;

export const DEMO_BORROWERS: DemoBorrower[] = [
  {
    businessStartDate: '2007-08-27',
    industryNaics: 551112,
    name: 'Umbrella Corporation',
    phone: '9203457890',
    street: '110 N Bedford St',
    street2: '',
    city: 'Madison',
    stateId: 'WI',
    postalCode: '53703',
    postalCodeExtra: '',
    first: 'Oswell E.',
    last: 'Spencer',
    website: 'umbrellacorporation.com',
    taxId: '231875649',
    creditScore: '525',
    businessType: 'llc',
  },

  {
    businessStartDate: '2007-08-27',
    industryNaics: 332994,
    name: 'Stark Industries',
    phone: '6313764740',
    street: '369 Lexington Ave',
    street2: '59th Floor',
    city: 'New York',
    stateId: 'NY',
    postalCode: '10017',
    postalCodeExtra: '',
    first: 'Pepper',
    last: 'Potts',
    website: 'starkindustries.com',
    taxId: '796318524',
    creditScore: '789',
    businessType: 'llc',
  },

  {
    businessStartDate: '2015-06-29',
    industryNaics: 311813,
    name: 'Pastry Pete\'s Pie Shoppe',
    phone: '6313764741',
    street: '61 Delancey St',
    street2: '',
    city: 'New York',
    stateId: 'NY',
    postalCode: '10002',
    postalCodeExtra: '',
    first: 'Pete',
    last: 'Paradez',
    website: 'pastrypetespieshoppe.com',
    taxId: '165239478',
    creditScore: '331',
    businessType: 'llc',
  },

  {
    businessStartDate: '2009-06-16',
    industryNaics: 561710,
    name: 'Ghostbusters',
    phone: '6313764738',
    street: '14 N Moore St',
    street2: '',
    city: 'New York',
    stateId: 'NY',
    postalCode: '10013',
    postalCodeExtra: '',
    first: 'Peter',
    last: 'Venkman',
    website: 'ghostbusters.com',
    taxId: '635719482',
    creditScore: '697',
    businessType: 'llc',
  },

  {
    businessStartDate: '2014-09-03',
    industryNaics: 311920,
    name: 'Central Perk Coffee House',
    phone: '3190989832',
    street: '12 S 6th St',
    street2: 'A',
    city: 'Estherville',
    stateId: 'IA',
    postalCode: '51334',
    postalCodeExtra: '',
    first: 'Rachel',
    last: 'Gunther',
    website: 'centralperk.com',
    taxId: '149582736',
    creditScore: '645',
    businessType: 'llc',
  },

  {
    businessStartDate: '2020-04-11',
    industryNaics: 311340,
    name: 'Stay Puft Corporation',
    phone: '6518936751',
    street: '2703 Piedmont Ave',
    street2: '',
    city: 'Duluth',
    stateId: 'MN',
    postalCode: '55811',
    postalCodeExtra: '',
    first: 'Ray',
    last: 'Stantz',
    website: 'staypuftcorporation.com',
    taxId: '713692584',
    creditScore: '643',
    businessType: 'cCorp',
  },

  {
    businessStartDate: '2020-04-11',
    industryNaics: 611620,
    name: 'Rex Kwan Do',
    phone: '2086728371',
    street: '17 N State St',
    street2: '',
    city: 'Preston',
    stateId: 'ID',
    postalCode: '83263',
    postalCodeExtra: '',
    first: 'Rex',
    last: 'Dynamite',
    website: 'rexkwando.com',
    taxId: '718463925',
    creditScore: '595',
    businessType: 'cCorp',
  },

  {
    businessStartDate: '2009-06-16',
    industryNaics: 451220,
    name: 'Championship Vinyl',
    phone: '7738789019',
    street: '1514 N. Milwaukee Ave.',
    street2: 'Unit 1420',
    city: 'Chicago',
    stateId: 'IL',
    postalCode: '60622',
    postalCodeExtra: '',
    first: 'Rob',
    last: 'Gordon',
    website: 'championshipvinyl.com',
    taxId: '735628941',
    creditScore: '303',
    businessType: 'llc',
  },

  {
    businessStartDate: '2015-06-29',
    industryNaics: 326100,
    name: 'Wellman Plastics',
    phone: '9203457893',
    street: '3155 Commodity Ln',
    street2: '',
    city: 'Green Bay',
    stateId: 'WI',
    postalCode: '54304',
    postalCodeExtra: '',
    first: 'Rosanne',
    last: 'Barr',
    website: 'wellmansplastics.com',
    taxId: '436572918',
    creditScore: '530',
    businessType: 'llc',
  },

  {
    businessStartDate: '2007-08-27',
    industryNaics: 722410,
    name: 'Cheers',
    phone: '3398987612',
    street: '84 Beacon St',
    street2: '',
    city: 'Boston',
    stateId: 'MA',
    postalCode: '02108',
    postalCodeExtra: '',
    first: 'Sam',
    last: 'Malone',
    website: 'cheers.com',
    taxId: '892463157',
    creditScore: '727',
    businessType: 'llc',
  },

  {
    businessStartDate: '2020-04-11',
    industryNaics: 454110,
    name: 'Buy n Large',
    phone: '9734756614',
    street: '1 Buy n Large Plaza, BnL Ave',
    street2: '',
    city: 'Blackwood',
    stateId: 'NJ',
    postalCode: '08012',
    postalCodeExtra: '',
    first: 'Shelby',
    last: 'Forthright',
    website: 'buynlarge.com',
    taxId: '896317254',
    creditScore: '568',
    businessType: 'cCorp',
  },

  {
    businessStartDate: '2020-04-11',
    industryNaics: 452319,
    name: '33¢ Store',
    phone: '2708792109',
    street: '82 Carothers Rd',
    street2: 'Ste C',
    city: 'Newport',
    stateId: 'KY',
    postalCode: '41071',
    postalCodeExtra: '',
    first: 'Sherrell',
    last: 'Christopher',
    website: '33centstore.com',
    taxId: '486125739',
    creditScore: '512',
    businessType: 'cCorp',
  },

  {
    businessStartDate: '2015-06-29',
    industryNaics: 722513,
    name: 'Krusty Krab',
    phone: '2137874101',
    street: '831 Bottom Feeder Lane',
    street2: '',
    city: 'Bikini Bottom',
    stateId: 'CA',
    postalCode: '90210',
    postalCodeExtra: '',
    first: 'Spongebob',
    last: 'Squarepants',
    website: 'krustkykrab.com',
    taxId: '582134796',
    creditScore: '571',
    businessType: 'llc',
  },

  {
    businessStartDate: '2014-09-03',
    industryNaics: 524210,
    name: 'Crimson Permanent Assurance',
    phone: '3043784767',
    street: '130 Brookshire Ln',
    street2: '',
    city: 'Beckley',
    stateId: 'WV',
    postalCode: '25801',
    postalCodeExtra: '',
    first: 'Sydney',
    last: 'Arnold',
    website: 'crimsonpermanentassurance.com',
    taxId: '975426381',
    creditScore: '720',
    businessType: 'llc',
  },

  {
    businessStartDate: '2014-09-03',
    industryNaics: 454110,
    name: 'The Very Big Corporation of America',
    phone: '4799873743',
    street: '702 SW 8th St',
    street2: '',
    city: 'Bentonville',
    stateId: 'AR',
    postalCode: '72716',
    postalCodeExtra: '',
    first: 'Terry',
    last: 'Gilliam',
    website: 'theverybigcorporationofamerica.com',
    taxId: '126837594',
    creditScore: '828',
    businessType: 'llc',
  },

  {
    businessStartDate: '2015-06-29',
    industryNaics: 522110,
    name: 'El Banco Corrupto',
    phone: '7024514053',
    street: '4053 East Reno Avenue',
    street2: '',
    city: 'Las Vegas',
    stateId: 'NV',
    postalCode: '89120',
    postalCodeExtra: '',
    first: 'Thelma',
    last: 'Grande',
    website: 'elblancocorrupto.com',
    taxId: '259843176',
    creditScore: '323',
    businessType: 'llc',
  },

  {
    businessStartDate: '2009-06-16',
    industryNaics: 312111,
    name: 'Brawndo',
    phone: '6053428987',
    street: '47988 185th Street',
    street2: '',
    city: 'Brandt',
    stateId: 'SD',
    postalCode: '57218',
    postalCodeExtra: '',
    first: 'Thomas Haden',
    last: 'Church',
    website: 'brawndo.com',
    taxId: '519782643',
    creditScore: '314',
    businessType: 'llc',
  },

  {
    businessStartDate: '2007-08-27',
    industryNaics: 722513,
    name: 'Pizza Planet',
    phone: '7738789021',
    street: '1769 W Sunnyside Ave',
    street2: '',
    city: 'Chicago',
    stateId: 'IL',
    postalCode: '60640',
    postalCodeExtra: '',
    first: 'Tom',
    last: 'Hanks',
    website: 'pizzaplanet.com',
    taxId: '821594367',
    creditScore: '466',
    businessType: 'llc',
  },

  {
    businessStartDate: '2015-06-29',
    industryNaics: 722410,
    name: 'The Leaky Cauldron',
    phone: '3398987614',
    street: '2 Cauldron Ct',
    street2: '',
    city: 'Salem',
    stateId: 'MA',
    postalCode: '01970',
    postalCodeExtra: '',
    first: 'Tom',
    last: 'Riddle',
    website: 'theleakycauldron.com',
    taxId: '374825196',
    creditScore: '817',
    businessType: 'llc',
  },

  {
    businessStartDate: '2009-06-16',
    industryNaics: 441300,
    name: 'Callahan Auto Parts',
    phone: '2162345235',
    street: '414 W Perkins Ave',
    street2: '',
    city: 'Sandusky',
    stateId: 'OH',
    postalCode: '44870',
    postalCodeExtra: '',
    first: 'Tommy',
    last: 'Callahan',
    website: 'callahanautoparts.com',
    taxId: '287651934',
    creditScore: '344',
    businessType: 'llc',
  },

  {
    businessStartDate: '2015-06-29',
    industryNaics: 713210,
    name: 'Tropicana Club',
    phone: '7738789022',
    street: '6282 E Riverside Blvd',
    street2: '',
    city: 'Loves Park',
    stateId: 'IL',
    postalCode: '61111',
    postalCodeExtra: '',
    first: 'Tony',
    last: 'Montana',
    website: 'tropicanaclub.com',
    taxId: '682573149',
    creditScore: '767',
    businessType: 'llc',
  },

  {
    businessStartDate: '2007-08-27',
    industryNaics: 325611,
    name: 'Paper Street Soap Company',
    phone: '2319873648',
    street: '1113 W Beecher St',
    street2: '',
    city: 'Adrian',
    stateId: 'MI',
    postalCode: '49221',
    postalCodeExtra: '',
    first: 'Tyler',
    last: 'Duren',
    website: 'paperstreetsoapcompany.com',
    taxId: '967285341',
    creditScore: '776',
    businessType: 'llc',
  },

  {
    businessStartDate: '2007-08-27',
    industryNaics: 459999,
    name: 'ACME Corp.',
    phone: '7178938749',
    street: '75 Valley Stream Pkwy',
    street2: '#250',
    city: 'Malvern',
    stateId: 'PA',
    postalCode: '19355',
    postalCodeExtra: '',
    first: 'Wile E.',
    last: 'Coyote',
    website: 'acme.com',
    taxId: '927564183',
    creditScore: '810',
    businessType: 'llc',
  },

  {
    businessStartDate: '2009-06-16',
    industryNaics: 311300,
    name: 'Wonka Industries',
    phone: '2138789231',
    street: '1010 Decatur St',
    street2: '',
    city: 'New Orleans',
    stateId: 'LA',
    postalCode: '70116',
    postalCodeExtra: '',
    first: 'Willy',
    last: 'Wonka',
    website: 'wonkaindustries.com',
    taxId: '482756931',
    creditScore: '427',
    businessType: 'llc',
  },

  {
    businessStartDate: '2009-06-16',
    industryNaics: 511130,
    name: 'Megadodo Publications',
    phone: '2319873646',
    street: '498 Brush St',
    street2: '',
    city: 'Detroit',
    stateId: 'MI',
    postalCode: '48226',
    postalCodeExtra: '',
    first: 'Zarniwoop',
    last: 'Van Harl',
    website: 'megadodopublications.com',
    taxId: '248197365',
    creditScore: '340',
    businessType: 'llc',
  }
];

export const DEMO_CONTACTS: DemoContact[] = [
  {
    first: 'Oswell E.',
    last: 'Spencer',
    workPhoneCountryCodeId: 1,
    workPhone: '9203457890',
    workPhoneExt: '8659',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '9203457891',
    taxId: '231875649',
    taxIdType: 'ssn',
    birthDate: '1988-07-07',
    street: '110 N Bedford St',
    street2: '',
    street3: '',
    city: 'Madison',
    branchId: 'Madison',
    stateId: 'WI',
    postalCode: '53703',
    postalCodeExtra: '',
    creditScore: '710',
    creditScoreSource: 'transunion',
    creditScoreDate: '2022-05-07T07:49:55.176Z',
    title: 'District Intranet Producer',
    percentOwnership: 91,
  },

  {
    first: 'Pepper',
    last: 'Potts',
    workPhoneCountryCodeId: 1,
    workPhone: '6313764740',
    workPhoneExt: '2169',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '6313764747',
    taxId: '796318524',
    taxIdType: 'ssn',
    birthDate: '1998-01-17',
    street: '369 Lexington Ave',
    street2: '59th Floor',
    street3: '',
    city: 'New York',
    branchId: 'New York',
    stateId: 'NY',
    postalCode: '10017',
    postalCodeExtra: '',
    creditScore: '697',
    creditScoreSource: 'transunion',
    creditScoreDate: '2017-09-06T18:49:39.501Z',
    title: 'Customer Assurance Strategist',
    percentOwnership: 91,
  },

  {
    first: 'Pete',
    last: 'Paradez',
    workPhoneCountryCodeId: 1,
    workPhone: '6313764741',
    workPhoneExt: '5168',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '6313764748',
    taxId: '165239478',
    taxIdType: 'ssn',
    birthDate: '1948-08-11',
    street: '61 Delancey St',
    street2: '',
    street3: '',
    city: 'New York',
    branchId: 'New York',
    stateId: 'NY',
    postalCode: '10002',
    postalCodeExtra: '',
    creditScore: '473',
    creditScoreSource: 'transunion',
    creditScoreDate: '2017-09-04T10:18:56.689Z',
    title: 'International Data Administrator',
    percentOwnership: 91,
  },

  {
    first: 'Peter',
    last: 'Venkman',
    workPhoneCountryCodeId: 1,
    workPhone: '6313764738',
    workPhoneExt: '6454',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '6313764745',
    taxId: '635719482',
    taxIdType: 'ssn',
    birthDate: '1998-06-06',
    street: '14 N Moore St',
    street2: '',
    street3: '',
    city: 'New York',
    branchId: 'New York',
    stateId: 'NY',
    postalCode: '10013',
    postalCodeExtra: '',
    creditScore: '707',
    creditScoreSource: 'transunion',
    creditScoreDate: '2021-04-20T05:38:46.358Z',
    title: 'Legacy Branding Engineer',
    percentOwnership: 91,
  },

  {
    first: 'Rachel',
    last: 'Gunther',
    workPhoneCountryCodeId: 1,
    workPhone: '3190989832',
    workPhoneExt: '6703',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '3190989830',
    taxId: '149582736',
    taxIdType: 'ssn',
    birthDate: '1987-12-08',
    street: '12 S 6th St',
    street2: 'A',
    street3: '',
    city: 'Estherville',
    branchId: 'Estherville',
    stateId: 'IA',
    postalCode: '51334',
    postalCodeExtra: '',
    creditScore: '404',
    creditScoreSource: 'equifax',
    creditScoreDate: '2019-10-18T08:27:29.380Z',
    title: 'Principal Functionality Liaison',
    percentOwnership: 91,
  },

  {
    first: 'Ray',
    last: 'Stantz',
    workPhoneCountryCodeId: 1,
    workPhone: '6518936751',
    workPhoneExt: '',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '6518936750',
    taxId: '713692584',
    taxIdType: 'ssn',
    birthDate: '1953-07-25',
    street: '2703 Piedmont Ave',
    street2: '',
    street3: '',
    city: 'Duluth',
    branchId: 'Duluth',
    stateId: 'MN',
    postalCode: '55811',
    postalCodeExtra: '',
    creditScore: '594',
    creditScoreSource: 'transunion',
    creditScoreDate: '2023-01-15T08:48:04.661Z',
    title: 'Senior Identity Technician',
    percentOwnership: 91,
  },

  {
    first: 'Rex',
    last: 'Dynamite',
    workPhoneCountryCodeId: 1,
    workPhone: '2086728371',
    workPhoneExt: '5169',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '2086728370',
    taxId: '718463925',
    taxIdType: 'ssn',
    birthDate: '1993-07-28',
    street: '17 N State St',
    street2: '',
    street3: '',
    city: 'Preston',
    branchId: 'Preston',
    stateId: 'ID',
    postalCode: '83263',
    postalCodeExtra: '',
    creditScore: '599',
    creditScoreSource: 'equifax',
    creditScoreDate: '2023-09-03T01:50:31.099Z',
    title: 'Corporate Factors Administrator',
    percentOwnership: 91,
  },

  {
    first: 'Rob',
    last: 'Gordon',
    workPhoneCountryCodeId: 1,
    workPhone: '7738789019',
    workPhoneExt: 'x3129',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '7738989022',
    taxId: '735628941',
    taxIdType: 'ssn',
    birthDate: '1977-09-21',
    street: '1514 N. Milwaukee Ave.',
    street2: 'Unit 1420',
    street3: '',
    city: 'Chicago',
    branchId: 'Chicago',
    stateId: 'IL',
    postalCode: '60622',
    postalCodeExtra: '',
    creditScore: '737',
    creditScoreSource: 'experian',
    creditScoreDate: '2023-11-15T09:31:15.377Z',
    title: 'International Program Specialist',
    percentOwnership: 91,
  },

  {
    first: 'Rosanne',
    last: 'Barr',
    workPhoneCountryCodeId: 1,
    workPhone: '9203457893',
    workPhoneExt: '9068',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '9203457892',
    taxId: '436572918',
    taxIdType: 'ssn',
    birthDate: '1965-12-27',
    street: '3155 Commodity Ln',
    street2: '',
    street3: '',
    city: 'Green Bay',
    branchId: 'Green Bay',
    stateId: 'WI',
    postalCode: '54304',
    postalCodeExtra: '',
    creditScore: '811',
    creditScoreSource: 'transunion',
    creditScoreDate: '2020-01-11T02:57:22.528Z',
    title: 'National Functionality Analyst',
    percentOwnership: 91,
  },

  {
    first: 'Sam',
    last: 'Malone',
    workPhoneCountryCodeId: 1,
    workPhone: '3398987612',
    workPhoneExt: 'x8305',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '3398987613',
    taxId: '892463157',
    taxIdType: 'ssn',
    birthDate: '2000-04-11',
    street: '84 Beacon St',
    street2: '',
    street3: '',
    city: 'Boston',
    branchId: 'Boston',
    stateId: 'MA',
    postalCode: '02108',
    postalCodeExtra: '',
    creditScore: '617',
    creditScoreSource: 'transunion',
    creditScoreDate: '2019-07-09T15:55:32.349Z',
    title: 'National Directives Liaison',
    percentOwnership: 91,
  },

  {
    first: 'Shelby',
    last: 'Forthright',
    workPhoneCountryCodeId: 1,
    workPhone: '9734756614',
    workPhoneExt: '5345',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '9734756618',
    taxId: '896317254',
    taxIdType: 'ssn',
    birthDate: '1977-08-17',
    street: '1 Buy n Large Plaza, BnL Ave',
    street2: '',
    street3: '',
    city: 'Blackwood',
    branchId: 'Blackwood',
    stateId: 'NJ',
    postalCode: '08012',
    postalCodeExtra: '',
    creditScore: '580',
    creditScoreSource: 'transunion',
    creditScoreDate: '2022-09-02T15:21:12.031Z',
    title: 'Corporate Security Planner',
    percentOwnership: 91,
  },

  {
    first: 'Sherrell',
    last: 'Christopher',
    workPhoneCountryCodeId: 1,
    workPhone: '2708792109',
    workPhoneExt: 'x7619',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '2708792108',
    taxId: '486125739',
    taxIdType: 'ssn',
    birthDate: '1988-02-05',
    street: '82 Carothers Rd',
    street2: 'Ste C',
    street3: '',
    city: 'Newport',
    branchId: 'Newport',
    stateId: 'KY',
    postalCode: '41071',
    postalCodeExtra: '',
    creditScore: '309',
    creditScoreSource: 'equifax',
    creditScoreDate: '2023-11-07T10:36:48.046Z',
    title: 'Legacy Markets Director',
    percentOwnership: 91,
  },

  {
    first: 'Spongebob',
    last: 'Squarepants',
    workPhoneCountryCodeId: 1,
    workPhone: '2137874101',
    workPhoneExt: '9503',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '2137874112',
    taxId: '582134796',
    taxIdType: 'ssn',
    birthDate: '1950-10-21',
    street: '831 Bottom Feeder Lane',
    street2: '',
    street3: '',
    city: 'Bikini Bottom',
    branchId: 'Bikini Bottom',
    stateId: 'CA',
    postalCode: '90210',
    postalCodeExtra: '',
    creditScore: '819',
    creditScoreSource: 'equifax',
    creditScoreDate: '2023-11-17T00:12:49.767Z',
    title: 'Lead Creative Director',
    percentOwnership: 91,
  },

  {
    first: 'Sydney',
    last: 'Arnold',
    workPhoneCountryCodeId: 1,
    workPhone: '3043784767',
    workPhoneExt: '',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '3043784769',
    taxId: '975426381',
    taxIdType: 'ssn',
    birthDate: '1948-12-22',
    street: '130 Brookshire Ln',
    street2: '',
    street3: '',
    city: 'Beckley',
    branchId: 'Beckley',
    stateId: 'WV',
    postalCode: '25801',
    postalCodeExtra: '',
    creditScore: '471',
    creditScoreSource: 'experian',
    creditScoreDate: '2023-03-22T05:04:41.515Z',
    title: 'Investor Response Executive',
    percentOwnership: 91,
  },

  {
    first: 'Terry',
    last: 'Gilliam',
    workPhoneCountryCodeId: 1,
    workPhone: '4799873743',
    workPhoneExt: '2030',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '4799873743',
    taxId: '126837594',
    taxIdType: 'ssn',
    birthDate: '1946-04-10',
    street: '702 SW 8th St',
    street2: '',
    street3: '',
    city: 'Bentonville',
    branchId: 'Bentonville',
    stateId: 'AR',
    postalCode: '72716',
    postalCodeExtra: '',
    creditScore: '389',
    creditScoreSource: 'transunion',
    creditScoreDate: '2023-02-24T01:36:05.110Z',
    title: 'Human Functionality Developer',
    percentOwnership: 91,
  },

  {
    first: 'Thelma',
    last: 'Grande',
    workPhoneCountryCodeId: 1,
    workPhone: '7024514053',
    workPhoneExt: '9885',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '7024514052',
    taxId: '259843176',
    taxIdType: 'ssn',
    birthDate: '2005-09-17',
    street: '4053 East Reno Avenue',
    street2: '',
    street3: '',
    city: 'Las Vegas',
    branchId: 'Las Vegas',
    stateId: 'NV',
    postalCode: '89120',
    postalCodeExtra: '',
    creditScore: '525',
    creditScoreSource: 'experian',
    creditScoreDate: '2022-01-05T12:52:29.717Z',
    title: 'Central Identity Associate',
    percentOwnership: 91,
  },

  {
    first: 'Thomas Haden',
    last: 'Church',
    workPhoneCountryCodeId: 1,
    workPhone: '6053428987',
    workPhoneExt: '4504',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '6053428985',
    taxId: '519782643',
    taxIdType: 'ssn',
    birthDate: '1980-04-15',
    street: '47988 185th Street',
    street2: '',
    street3: '',
    city: 'Brandt',
    branchId: 'Brandt',
    stateId: 'SD',
    postalCode: '57218',
    postalCodeExtra: '',
    creditScore: '755',
    creditScoreSource: 'transunion',
    creditScoreDate: '2023-01-10T18:01:02.431Z',
    title: 'Corporate Directives Planner',
    percentOwnership: 91,
  },

  {
    first: 'Tom',
    last: 'Hanks',
    workPhoneCountryCodeId: 1,
    workPhone: '7738789021',
    workPhoneExt: '2977',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '7738989024',
    taxId: '821594367',
    taxIdType: 'ssn',
    birthDate: '2001-01-11',
    street: '1769 W Sunnyside Ave',
    street2: '',
    street3: '',
    city: 'Chicago',
    branchId: 'Chicago',
    stateId: 'IL',
    postalCode: '60640',
    postalCodeExtra: '',
    creditScore: '632',
    creditScoreSource: 'experian',
    creditScoreDate: '2020-07-13T17:18:34.652Z',
    title: 'Forward Security Administrator',
    percentOwnership: 91,
  },

  {
    first: 'Tom',
    last: 'Riddle',
    workPhoneCountryCodeId: 1,
    workPhone: '3398987614',
    workPhoneExt: '4533',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '3398987615',
    taxId: '374825196',
    taxIdType: 'ssn',
    birthDate: '1949-10-20',
    street: '2 Cauldron Ct',
    street2: '',
    street3: '',
    city: 'Salem',
    branchId: 'Salem',
    stateId: 'MA',
    postalCode: '01970',
    postalCodeExtra: '',
    creditScore: '671',
    creditScoreSource: 'equifax',
    creditScoreDate: '2018-08-18T05:33:21.955Z',
    title: 'Product Applications Officer',
    percentOwnership: 91,
  },

  {
    first: 'Tommy',
    last: 'Callahan',
    workPhoneCountryCodeId: 1,
    workPhone: '2162345235',
    workPhoneExt: '9634',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '2162345237',
    taxId: '287651934',
    taxIdType: 'ssn',
    birthDate: '1994-12-13',
    street: '414 W Perkins Ave',
    street2: '',
    street3: '',
    city: 'Sandusky',
    branchId: 'Sandusky',
    stateId: 'OH',
    postalCode: '44870',
    postalCodeExtra: '',
    creditScore: '787',
    creditScoreSource: 'transunion',
    creditScoreDate: '2023-02-11T12:56:25.586Z',
    title: 'Dynamic Security Executive',
    percentOwnership: 91,
  },

  {
    first: 'Tony',
    last: 'Montana',
    workPhoneCountryCodeId: 1,
    workPhone: '7738789022',
    workPhoneExt: '5587',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '7738989025',
    taxId: '682573149',
    taxIdType: 'ssn',
    birthDate: '1962-06-16',
    street: '6282 E Riverside Blvd',
    street2: '',
    street3: '',
    city: 'Loves Park',
    branchId: 'Loves Park',
    stateId: 'IL',
    postalCode: '61111',
    postalCodeExtra: '',
    creditScore: '525',
    creditScoreSource: 'equifax',
    creditScoreDate: '2020-02-21T01:15:56.777Z',
    title: 'Product Integration Architect',
    percentOwnership: 91,
  },

  {
    first: 'Tyler',
    last: 'Duren',
    workPhoneCountryCodeId: 1,
    workPhone: '2319873648',
    workPhoneExt: '3779',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '2319873652',
    taxId: '967285341',
    taxIdType: 'ssn',
    birthDate: '1947-07-21',
    street: '1113 W Beecher St',
    street2: '',
    street3: '',
    city: 'Adrian',
    branchId: 'Adrian',
    stateId: 'MI',
    postalCode: '49221',
    postalCodeExtra: '',
    creditScore: '576',
    creditScoreSource: 'experian',
    creditScoreDate: '2020-01-11T02:47:45.387Z',
    title: 'Internal Interactions Director',
    percentOwnership: 91,
  },

  {
    first: 'Wile E.',
    last: 'Coyote',
    workPhoneCountryCodeId: 1,
    workPhone: '7178938749',
    workPhoneExt: '',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '7178938753',
    taxId: '927564183',
    taxIdType: 'ssn',
    birthDate: '1992-07-19',
    street: '75 Valley Stream Pkwy',
    street2: '#250',
    street3: '',
    city: 'Malvern',
    branchId: 'Malvern',
    stateId: 'PA',
    postalCode: '19355',
    postalCodeExtra: '',
    creditScore: '819',
    creditScoreSource: 'equifax',
    creditScoreDate: '2022-07-02T21:49:25.190Z',
    title: 'Internal Mobility Producer',
    percentOwnership: 91,
  },

  {
    first: 'Willy',
    last: 'Wonka',
    workPhoneCountryCodeId: 1,
    workPhone: '2138789231',
    workPhoneExt: '',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '2138789230',
    taxId: '482756931',
    taxIdType: 'ssn',
    birthDate: '2005-07-20',
    street: '1010 Decatur St',
    street2: '',
    street3: '',
    city: 'New Orleans',
    branchId: 'New Orleans',
    stateId: 'LA',
    postalCode: '70116',
    postalCodeExtra: '',
    creditScore: '696',
    creditScoreSource: 'experian',
    creditScoreDate: '2020-10-16T08:22:46.761Z',
    title: 'International Group Assistant',
    percentOwnership: 91,
  },

  {
    first: 'Zarniwoop',
    last: 'Van Harl',
    workPhoneCountryCodeId: 1,
    workPhone: '2319873646',
    workPhoneExt: '9584',
    mobilePhoneCountryCodeId: 1,
    mobilePhone: '2319873650',
    taxId: '248197365',
    taxIdType: 'ssn',
    birthDate: '1971-10-02',
    street: '498 Brush St',
    street2: '',
    street3: '',
    city: 'Detroit',
    branchId: 'Detroit',
    stateId: 'MI',
    postalCode: '48226',
    postalCodeExtra: '',
    creditScore: '412',
    creditScoreSource: 'transunion',
    creditScoreDate: '2021-08-21T18:46:26.632Z',
    title: 'Principal Applications Developer',
    percentOwnership: 91,
  }
]
