import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-import-entity-detail-dialog',
  templateUrl: './import-entity-detail-dialog.component.html',
  styleUrls: ['./import-entity-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportEntityDetailDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if(this.data) {
      this.data = JSON.parse(this.data);
    }
  }
}
