<form
    [formGroup]="loginForm"
    (ngSubmit)="login()"
    (keydown.enter)="login($event)"
    class="mt-4">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Email address</mat-label>
            <input formControlName="email"
                required
                data-cy="login-email"
                name="email"
                id="login-email"
                matInput
                placeholder="Ex. email@domain.com"
                email
                type="email">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Password</mat-label>
            <input formControlName="password"
                required
                data-cy="login-password"
                name="password"
                id="login-password"
                matInput
                type="{{ showPassword ? 'text' : 'password' }}">
            <mat-icon matSuffix (click)="showPassword = !showPassword" class="cursor-pointer">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>
    <div class="flex justify-end items-center">
        <button
            data-cy="forgot-password"
            mat-button
            color="primary"
            class="mr-2"
            (click)="forgotPasswordDialog($event)">
            <span class="mat-body-1">Forgot password?</span>
        </button>
        <button
            type="submit"
            data-cy="login-button"
            id="login-button"
            mat-flat-button color="primary"
            [disabled]="!canSubmit()">
            <span *ngIf="loading else text" class="spinner">&nbsp;</span>
            <ng-template #text>
                <span class="mat-body-1">Log In</span>
            </ng-template>
        </button>
    </div>
</form>
