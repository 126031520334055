import { Component, computed, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DealNote } from '@app/app/interfaces/deal-note.model';
import { Select, Store } from '@ngxs/store';
import { DealNotesState } from '@app/app/store/deal-notes/deal-notes.state';
import { AddDealNote } from '@app/app/store/deal-notes/deal-notes.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CreateNoteDialogComponent
} from '@app/app/components/deal-notes/create-note-dialog/create-note-dialog.component';
import { take } from 'rxjs/operators';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-deal-notes',
  templateUrl: './deal-notes.component.html',
  styleUrls: ['./deal-notes.component.scss']
})
export class DealNotesComponent implements OnInit {

  @Select( DealNotesState.dealNotes ) notes$: Observable<DealNote[]>;
  @Input() dealId: number;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());


  constructor(
    public dialog: MatDialog,
    private store: Store,
    private _themeService: LendioAngularMaterialThemeService
  ) { }

  ngOnInit(): void {
  }

  openAddNote() {
     const dialogRef: MatDialogRef<CreateNoteDialogComponent> = this.dialog.open( CreateNoteDialogComponent, {
      width: '450px',
    });
     dialogRef.componentInstance.saveNote.pipe(
       take(1)
     ).subscribe( noteContent => {
       if(noteContent?.length) {
         this.store.dispatch( new AddDealNote(this.dealId, noteContent) );
       }

       dialogRef.close();
     })
  }

}
