import { ErrorHandler, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth/auth.state';

export const lpxPermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const permission = route.data?.permission;
  if (permission === undefined) {
    inject(ErrorHandler).handleError(
      `Tried to permission guard route: ${route.toString()} without passing a permission`
    );
    return false;
  }
  const store = inject(Store);
  return store.selectSnapshot(AuthState.user)?.permittedTo(permission) ?? false;
};
