<div mat-dialog-title class="!grid grid-flow-col place-items-center justify-between">
    <span>{{ editing ? 'Edit ' : 'Add ' }} team member</span>
    <button
        mat-icon-button
        [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form
        [formGroup]="form" class="lendio-density-1 pt-4">
        <div class="grid grid-flow-col gap-4">
            <!--     FIRST NAME     -->
            <mat-form-field
                appearance="outline"
                [hideRequiredMarker]="true"
            >
                <mat-label> First Name </mat-label>
                <input matInput
                       [formControlName]="'first'"
                       type="text"
                       name="first-name" />
            </mat-form-field>
            <!--     LAST NAME     -->
            <mat-form-field
                appearance="outline"
                [hideRequiredMarker]="true"
            >
                <mat-label> Last Name </mat-label>
                <input matInput
                       [formControlName]="'last'"
                       type="text"
                       name="last-name" />
            </mat-form-field>
        </div>
        <!--     EMAIL     -->
        <mat-form-field
            appearance="outline"
            class="w-full mt-4"
            [hideRequiredMarker]="true"
        >
            <mat-label> Email </mat-label>
            <input matInput
                   type="text"
                   name="email"
                   [formControlName]="'email'"
            />
        </mat-form-field>

        <div class="grid grid-flow-col grid-cols-2 grid-cols-[60%_auto] mt-4">
            <!--     ROLE     -->
            <div>
                <mat-label>
                    <span class="text-base font-semibold">Role</span>
                    <span class="text-gray-600 ml-2">(select all that apply)</span>
                </mat-label>
                <mat-selection-list
                    #rolesSelection
                    *appLet="(adminRoleId$ | async) as adminRoleId"
                    (selectionChange)="handleSelectionChange($event, adminRoleId)"
                >
                    <mat-list-option
                        lines="3"
                        *ngFor="let role of (roleOptions$ | async)"
                        color="primary"
                        [value]="role.id"
                        [selected]="data?.roles?.includes(role.id)"
                        [disabled]="form.controls.roles.value.includes(adminRoleId) && role?.id !== adminRoleId"
                    >
                        <span matListItemTitle>{{ role.name === 'Client Developer' ? 'Developer' : role.name }}</span>
                        <span class="text-gray-500">{{ role.description }}</span>
                    </mat-list-option>

                </mat-selection-list>
            </div>

            <!--     EMBEDDED PERMISSIONS     -->
            <div>
                <mat-label class="text-base font-semibold">
                    Embedded permissions
                </mat-label>
                <mat-selection-list>
                    <mat-list-option
                        color="primary"
                        (selectedChange)="form.controls.manageStyles.setValue($event)"
                        [selected]="data?.manageStyles">
                        <span matListItemTitle>Manage styles</span>
                    </mat-list-option>
                    <mat-list-option
                        color="primary"
                        (selectedChange)="form.controls.manageLegal.setValue($event)"
                        [selected]="data?.manageLegal">
                        <span matListItemTitle>Manage legal</span>
                    </mat-list-option>
                    <mat-list-option
                        color="primary"
                        (selectedChange)="form.controls.manageWebhooks.setValue($event)"
                        [selected]="data?.manageWebhooks">
                        <span matListItemTitle>Manage webhooks</span>
                    </mat-list-option>
                </mat-selection-list>

            </div>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button
            [color]="'accent'"
            [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button
            [color]="'primary'"
            [mat-dialog-close]="form.value"
            [disabled]="form.invalid">
        {{ editing ? 'Save' : 'Add' }}
    </button>
</mat-dialog-actions>
