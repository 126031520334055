import { Label } from '../../interfaces/label.model';

export class GetLabels {
    static readonly type = '[Labels] GetLabels';
    constructor() {}
}

export class ClearLabels {
    static readonly type = '[Labels] ClearLabels';
    constructor() {}
}

export class DeleteLabel {
    static readonly type = '[Labels] DeleteLabel';
    constructor(public labelId: number) {}
}

export class CreateLabel {
    static readonly type = '[Labels] CreateLabel';
    constructor(public label: Label) {}
}

export class EditLabel {
    static readonly type = '[Labels] EditLabel';
    constructor(public label: Label) {}
}
