export class ExportApplications {
    static readonly type = '[Csv] ExportApplications';
    constructor() {}
}

export class GetApplicationsExports {
    static readonly type = '[Csv] GetApplicationsExports';
    constructor() {}
}

export class ChangePage {
    static readonly type = '[Csv] ChangePage';
    constructor(public pageSize: any, public pageIndex: any) {}
}

export class GetForgivenessDocuments {
    static readonly type = '[Csv] GetForgivenessDocuments';
    constructor() {}
}

export class ImportForgiveness {
    static readonly type = '[Csv] ImportForgiveness';
    constructor(public document: any) {}
}
