<div data-cy="confirm-dialog" class="px-2 py-4" style="width: {{ data?.width }}">
    <mat-dialog-content class="!p-4">
        <h2>{{ data?.title }}</h2>

        <ng-container *ngIf="!!data?.customContent else default">
            <div [innerHTML]="data?.customContent"></div>
        </ng-container>

        <ng-template #default>
            <p>{{ data?.description }}</p>
        </ng-template>

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="!pb-0">
        <button data-cy="confirm-dialog-cancel-btn" class="text-blue-500 {{ data?.cancelButtonClass || '' }}" (click)="cancel()" [style]="data?.cancelStyles">
            {{ data?.cancelLabel }}
        </button>
        <button #confirmButtonRef mat-flat-button
                color="{{data.confirmButtonColor || 'primary'}}"
                data-cy="confirm-dialog-confirm-btn"
            class="ml-8 {{data.confirmButtonClass || ''}}"
            [style]="data.confirmStyles"
            (click)="confirm()"
        >
            {{ data.confirmLabel }}
        </button>
    </mat-dialog-actions>
</div>
