import { NgModule } from '@angular/core';
import { ReplacePipe } from './replace.pipe';

@NgModule({
    imports: [],
    exports: [ReplacePipe],
    declarations: [ReplacePipe],
    providers: [ReplacePipe],
})
export class ReplacePipeModule { }
