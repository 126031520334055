import { Component, OnInit, Output, EventEmitter, Input, computed } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-resend-contract-button',
  templateUrl: './resend-contract-button.component.html',
  styleUrls: ['./resend-contract-button.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, { params: { timing: 0.3 } })
      )
    ])
  ]
})
export class ResendContractButtonComponent implements OnInit {

  @Output() resendRequested = new EventEmitter();
  @Input() newContractRequested: boolean;
  @Input() pending: boolean;
  @Input() dealStatus: string;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) { }

  ngOnInit(): void {}

  resendContract() {
    this.resendRequested.emit();
  }
}
