import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LendioResponse, ObsLendioResponse } from '@app/app/interfaces/lendio-response';
import { Observable } from 'rxjs';
import {
  CommercialCreditReport,
  ConsumerCreditReport,
  SavedReportsResponse
} from '@app/app/interfaces/credit-reports/credit-report.model';

const baseUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CreditReportsService {

  constructor(
    private http: HttpClient
  ) { }

  getOwnerCreditReportByContactId(contactId: number): ObsLendioResponse {
    return this.http.get<LendioResponse>(`${baseUrl}/experian/credit-report/contact/${contactId}`);
  }

  getCommercialCreditReport(borrowerId?: number | null): ObsLendioResponse<CommercialCreditReport> {
    return this.http.post<LendioResponse>(`${baseUrl}/experian-commercial/premier-report/${borrowerId}`, {test: true, hardPull: true});
  }

  getSavedOwnerCreditReports(borrowerId: number): ObsLendioResponse<SavedReportsResponse> {
    return this.http.get<LendioResponse<SavedReportsResponse>>(`${baseUrl}/experian/saved-owner-reports/${borrowerId}`);
  }

}
