import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-account-locked',
  templateUrl: './account-locked.component.html',
  styleUrls: ['./account-locked.component.scss']
})
export class AccountLockedComponent {

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {}

  backToSignIn() {
    this.router.navigateByUrl('/auth/login');
  }

  forgotPasswordDialog(): void {
    this.dialog.open(ForgotPasswordDialogComponent);
  }
}
