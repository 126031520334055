import { Component, OnInit, Input, ViewChild, OnDestroy, computed } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { Select, Store } from '@ngxs/store';
import { CsvState } from 'src/app/store/csv/csv.state';
import { ChangePage } from 'src/app/store/csv/csv.actions';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { HttpClient } from '@angular/common/http';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-data-list-forgiveness',
  templateUrl: './data-list-forgiveness.component.html',
  styleUrls: ['./data-list-forgiveness.component.scss']
})
export class DataListForgivenessComponent implements OnInit, OnDestroy {
  @Select(AuthState.user) currentUser$: Observable<AuthUser>;
  @Input() data: object[];
  @Input() title: string;
  @Input() columns: string[];
  // @Select(CsvState.totalCount) totalCount$: Observable<number>;
  totalCount$: Observable<number> = this.store.select(CsvState.totalCount);
  // @Select(CsvState.status) status$: Observable<string>;
  status$: Observable<string> = this.store.select(CsvState.status);
  @ViewChild('paginator') paginator: MatPaginator;
  user: AuthUser | null;
  private destroyed$ = new Subject<void>();

  payload: any = null;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled);

  constructor(
    private store: Store,
    private router: Router,
    private http: HttpClient,
    private _themeService: LendioAngularMaterialThemeService
  ) { }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user;
      if (!user ||
        (
          !user?.permissions?.canUploadLoanForgivenessCSV &&
          !user.permittedTo('canManageLoanForgivenessCSV')
        )) {
        this.router.navigateByUrl('/');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }

  getDownloadLink(id: number): void {
    const url = environment.apiUrl + '/l/v2/internal/loan-forgiveness-csv?csvId=' + id;
    this.http.get(url, { responseType: 'blob' }).pipe(take(1)).subscribe((response: Blob) => {
      const fileURL = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'loan-forgiveness.csv';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(fileURL);
      document.body.removeChild(link);
    });
  }

  paging(event: PageEvent){
    this.store.dispatch(new ChangePage(event.pageSize, event.pageIndex));
  }
}
