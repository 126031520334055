<div [class.old-theme-old-decline-dialog]="oldThemeEnabled()">
	<h2 mat-dialog-title class="!p-4" data-cy="decline-modal-header">Decline Application</h2>
	<mat-dialog-content>
		<div class="loading" *ngIf="loading; else ready">
			<app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
		</div>
		<ng-template #ready>
			<p>Please tell us why you're declining this application.</p>
			<mat-form-field class="decline-reasons-select">
				<mat-label>Select one or more reasons...</mat-label>
				<mat-select [formControl]="declineReasonSelect" multiple [(value)]="selectedReasons" data-cy="decline-reason-select">
					<mat-option
						*ngFor="let reason of declineReasons; let i = index"
						[value]="reason.reason"
						attr.data-cy="reason-{{i}}"
					>{{ reason.reason }}</mat-option>
				</mat-select>
			</mat-form-field>
			<p>Additional Notes (optional)</p>
			<mat-form-field appearance="fill" class="decline-comment-text">
				<textarea
					rows="5"
					matInput
					data-cy="declineComment"
					placeholder="(i.e. How can we better prepare our borrowers?)"
					[(ngModel)]="comment"
					[ngModelOptions]="{standalone: true}"
					data-cy="additional-notes-input"
				></textarea>
			</mat-form-field>
		</ng-template>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button class="!mb-2" color="primary" mat-dialog-close>Cancel</button>
		<button
			mat-raised-button
			color="primary"
			class="!ml-3 !mb-2"
			[disabled]="(selectedReasons && selectedReasons.length === 0) || loading"
			(click)="submit()"
			[class.spinner]="loading"
			data-cy="decline-app-submit-btn">
			{{ loading ? '&nbsp;' : 'OK' }}
		</button>
	</mat-dialog-actions>
</div>
