<div class="text-2xl font-light pt-12 pb-2">Restriction Notes</div>
<mat-form-field appearance="fill" class="w-2/5">
    <input matInput
           placeholder="Enter your restriction notes here"
           [value]="restrictionNotes"
           [formControl]="myFormControlFromComponent"
           data-cy="restriction-note-input"/>
</mat-form-field>
<div class="h-10">
    <button mat-button color="warn"
            (click)="resetValue()"
            [disabled]="restrictionNotes == myFormControlFromComponent.value || !hasLoanProductWriteAccess"
            data-cy="restriction-note-cancel-button">Cancel</button>
    <button mat-flat-button color="primary"
            (click)="saveClicked(myFormControlFromComponent.value)"
            [disabled]="restrictionNotes == myFormControlFromComponent.value || !hasLoanProductWriteAccess"
            data-cy="restriction-note-save-button">Save</button>
</div>

