import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportsRoutingModule } from '@app/app/components/lender-imports/imports-routing.module';
import { ImportsComponent } from './imports/imports.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { ReplacePipeModule } from '@app/app/pipes/replace/replace-pipe.module';
import { CapitalizePipePipeModule } from '@app/app/pipes/capitalize/capitalize-pipe.module';
import { TruncatePipeModule } from '@app/app/pipes/truncate/truncate-pipe.module';
import { MatDialogModule } from '@angular/material/dialog';
import { FileImportDialogComponent } from './file-import/file-import-dialog.component';
import { ImportFailDialogComponent } from './import-fail-dialog/import-fail-dialog.component';
import { ImportEntityDetailDialogComponent } from './import-entity-detail-dialog/import-entity-detail-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { ImportDetailsComponent } from './import-details/import-details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadersModule } from '../loaders/loaders.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DefaultValuePipeModule } from '@app/app/pipes/default-value/default-value-pipe.module';
import { LendioPaginatorComponent } from '../lendio-angular-material-theme/lendio-paginator/lendio-paginator.component';
import { EmptyStateComponent } from '../empty-state/empty-state.component';


@NgModule({
  declarations: [
    ImportsComponent,
    FileImportDialogComponent,
    ImportFailDialogComponent,
    ImportDetailsComponent,
    ImportEntityDetailDialogComponent,
  ],

  imports: [
    CommonModule,
    ImportsRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatCardModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSelectModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    ReplacePipeModule,
    CapitalizePipePipeModule,
    TruncatePipeModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    LoadersModule,
    MatTooltipModule,
    DefaultValuePipeModule,
    LendioPaginatorComponent,
    EmptyStateComponent
  ],
})
export class ImportsModule { }
