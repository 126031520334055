<div mat-dialog-title class="mb-8">Import</div>
<div mat-dialog-content
     class="new-theme-typography"
     [class.old-theme-file-import-dialog]="oldThemeEnabled()">
    <form [formGroup]="uploadFileDialog" class="form form-upload-file lendio-density-1" >
        <mat-form-field appearance="outline" class="w-full mb-14">
            <mat-label>Entity Type</mat-label>
            <mat-select formControlName="type" required [(ngModel)]="fileUpload.type">
                <mat-option *ngFor="let type of entityTypes" [value]="type.value">{{ type.key }}</mat-option>
            </mat-select>
            <mat-hint>Types include businesses, owners, accounts, or transactions.</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full mb-10">
            <mat-label>Description</mat-label>
            <input formControlName="importDescription"
                   [(ngModel)]="fileUpload.description"
                   id="description"
                   matInput
                   type="text">
            <mat-hint>Provide any notes that help you and others remember what the data is (optional).</mat-hint>
        </mat-form-field>

        <div *ngIf="fileUpload.type" class="mt-4">
            <p class="mat-body-2">To ensure the right data is mapped to the right columns, we strongly recommend that you download and use the provided
                template for importing businesses. <br/><br/>
                Once you've added your data to the template, select the file to upload and import the data. <b>Only .csv files are allowed.</b>
            </p>
            <mat-dialog-actions [align]="'end'">
            <a class="pr-8" href="/assets/lender-import-templates/{{ fileUpload.type }}.csv">Download template</a>
            <span *ngIf="!file || file.type !== 'text/csv'" class="lendio-density-1">
                <button mat-raised-button color="primary" (click)="fileInput.click()">Select .csv</button>
                <input #fileInput type="file" accept="text/csv" hidden (change)="fileSelect($event)">
            </span>
            <span *ngIf="file && file.type === 'text/csv'" class="lendio-density-1">
                <button mat-button (click)="fileInput.click()">Change .csv</button>
                <input #fileInput type="file" hidden (change)="fileSelect($event)">
            </span>
            </mat-dialog-actions>
            <div *ngIf="file && file.type === 'text/csv'" class="!pb-6">
                <mat-card class="elevation-1">
                    <mat-card-content>
                        <div class="font-bold items-center flex gap-8">
                            <mat-icon fontSet="material-symbols-outlined">draft</mat-icon>
                            {{ fileUpload.fileName | truncate: ['30'] }}
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            @if (file && file?.type !== 'text/csv') {
                <div class="pb-6">
                    <mat-card class="!bg-red-600 !h-9 !items-center !justify-center elevation-1">
                        <mat-card-content>
                            <div class="mat-body-2 !text-white">
                                The file you selected is not a .csv file
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            }
        </div>
    </form>
</div>
    <mat-divider></mat-divider>
    <div mat-dialog-actions [align]="'end'" class="lendio-density-1 !pt-4">
        <button mat-stroked-button mat-dialog-close color="accent">Cancel</button>
        <button #uploadButton
                color="primary"
                mat-flat-button
                [disabled]="!file || file.type !== 'text/csv' || showLoadingStateOnUploadButton"
                (click)="uploadFile()">
            @if (showLoadingStateOnUploadButton) {
                <mat-spinner [diameter]="20"></mat-spinner>
            } @else {
                Upload
            }
        </button>
    </div>
