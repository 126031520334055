import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@app/environments/environment';
import { computed, Injectable, signal } from '@angular/core';
import { EarlyAccessFeature, EarlyAccessFeatureRequest, SaasFeatureResponse } from '@app/app/interfaces/saas-feature.model';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';
import cookies from '@app/app/libs/cookieManager';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { LogEvent } from '@app/app/store/tracking/tracking.actions'

const postUrl = `${environment.apiUrl}/l/v2/internal/early-access-features`;
const themeFeature: EarlyAccessFeature = {
  id: 'eaTheme',
  name: 'new-theme',
  alias: 'newTheme',
  saasFeatureId: null,
  permissionId: null,
  partnerRestrictionType: null,
  visible: true,
  userEnrollment: false,
  type: 'new',
  heading: 'Enable a new interface.',
  description: 'Introducing a brand new interface',
  subtexts: [
    'Get better insights, more customizations, and new visualizations.',
    'Opt in to the new look now, or continue with the classic theme.'
  ],
  disabled: true
}
const AUTO_ENROLLMENT_ALIAS = 'automaticEnrollment';
@Injectable({
  providedIn: 'root',
})

export class EarlyAccessFeatureService {
  private _features: EarlyAccessFeature[] = [ themeFeature ];
  private _themeFeatureIndex = this._features.findIndex(feature => feature.alias === 'newTheme');
  public earlyAccessFeatures = signal(this._features);
  public earlyAccessShowBadge = signal(false);
  public earlyAccessShowDialog = signal(false);
  public newThemeEnabled = computed(() => this._themeService.newThemeEnabled());
  public oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());
  public autoEnrollmentAlias = AUTO_ENROLLMENT_ALIAS;

  constructor(private _themeService: LendioAngularMaterialThemeService, private http: HttpClient, private store: Store) {
    this._themeService.enabled.pipe(takeUntilDestroyed()).subscribe(themeEnabled => {
      this._features[this._themeFeatureIndex].disabled = !this.newThemeEnabled();
      this._features[this._themeFeatureIndex].userEnrollment = this.newThemeEnabled();
    });
  }


  public setFeatures(features: EarlyAccessFeature[]) {
    const automaticEnrollment = features.find(feature => feature.alias === this.autoEnrollmentAlias);
    const autoEnrolled = automaticEnrollment && !automaticEnrollment.disabled;

    if (autoEnrolled) {
      this._features[this._themeFeatureIndex].disabled = false;
      this.triggerLocalFeatureUpdates();
    }

    this.earlyAccessFeatures.set([ ...features, ...this._features ]);
  }

  public triggerLocalFeatureUpdates(): void {
    if (this._features[this._themeFeatureIndex]) {
      if (this._features[this._themeFeatureIndex].disabled && this._themeService.newThemeEnabled()) {
        this.store.dispatch(new LogEvent('materialThemeDisabled', {}));
      }

      this._features[this._themeFeatureIndex].disabled
        ? this._themeService.disable()
        :  this._themeService.enable();
    }
  }

  public upsertEarlyAccessFeature(feature: EarlyAccessFeature): Observable<EarlyAccessFeature> {
    return this.http.post<EarlyAccessFeature>(`${postUrl}/${feature.id}/enroll'`, {feature});
  }

  public upsertEarlyAccessFeatures(features: EarlyAccessFeatureRequest): Observable<SaasFeatureResponse> {
    return this.http.post<SaasFeatureResponse>(`${postUrl}/enroll`, features);
  }
}
