import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MakeCsvService } from '@app/app/components/makecsv/make-csv.service';
import { MatButtonModule } from '@angular/material/button';
import { DEMO_BORROWERS, DEMO_CONTACTS } from '@app/app/components/makecsv/make-csv-constants';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-makecsv',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatTooltipModule],
  providers: [MakeCsvService],
  templateUrl: './makecsv.component.html',
  styleUrls: ['./makecsv.component.scss']
})
export class MakecsvComponent {
  constructor(private _makeCsvService: MakeCsvService) {
  }

  generateContacts() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateContact,
      name: 'Contacts',
      dataSetId: null,
      parentRows: DEMO_CONTACTS,
      perParentMin: 10,
      perParentMax: 10
    });
  }

  generateBankAccounts() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateBankAccount,
      name: 'Bank Accounts',
      dataSetId: null,
      parentRows: DEMO_CONTACTS,
      perParentMin: 10,
      perParentMax: 10
    })
  }

  generateTransactions() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateTransaction,
      name: 'Transactions',
      dataSetId: null,
      parentRows: DEMO_BORROWERS,
      perParentMin: 10,
      perParentMax: 10
    })
  }

  generateBusinesses() {
    this.generate({
      count: 25,
      rowGenerator: this._makeCsvService.generateBusiness,
      name: 'Business',
      dataSetId: null,
      parentRows: DEMO_BORROWERS,
      perParentMin: 10,
      perParentMax: 10
    })
  }

  async generateAll() {
    await this._makeCsvService.generate({
      prefix: 'demo',
      businessCount: 25,
      contactsPerBusinessMin: 1,
      contactsPerBusinessMax: 1,
      accountsPerBusinessMin: 1,
      accountsPerBusinessMax: 1,
      transactionsPerAccountMin: 300,
      transactionsPerAccountMax: 300,
    })
  }

  async generate({count, rowGenerator, name, dataSetId, parentRows, perParentMin, perParentMax}) {
    this._makeCsvService.generateCsv({
      prefix: 'demo',
      count,
      rowGenerator,
      name,
      dataSetId,
      parentRows,
      perParentMin,
      perParentMax,
    })
  }

}
