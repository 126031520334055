<ng-container *ngIf="((opportunitiesLoading$ | async) && (sassFeaturesLoaded$ | async)) || !showClients; else clientsView">
    <app-dot-loader-scale></app-dot-loader-scale>
</ng-container>
<ng-template #clientsView>
    <ng-container *ngIf="opportunityDetails$ | async as opportunities; else NoDeals">
        <mat-card appearance="outlined" class="m-4 !p-4">
            <div class="funnel-chart-container">
                <ag-charts-angular [options]="clientGridChartOptions">
                </ag-charts-angular>
            </div>
            <div class="flex-container">
                <div class='flex-container-body'>
                    <div class="action-buttons">
                        <app-lead-submission-button *ngIf="allowLeadSubmission"></app-lead-submission-button>
                        <button mat-flat-button
                                color="primary"
                                class="ml-3 lendio-mat-button-small"
                                [matMenuTriggerFor]="exportMenu">
                            <mat-icon class="export-icon" color="primary">get_app_icon</mat-icon>
                            <span class="export-btn-text">Export</span>
                            <mat-menu #exportMenu="matMenu"
                                    class="export-select"
                                    yPosition="below"
                                    xPosition="before">
                                <button mat-menu-item
                                        (click)="btnExportToExcel()">
                                    <span>Excel Export</span>
                                </button>
                                <button mat-menu-item
                                        (click)="btnExportToCsv()">
                                    <span>CSV Export</span>
                                </button>
                            </mat-menu>
                        </button>
                    </div>
                    <ag-grid-angular class="ag-theme-alpine clients-grid"
                                    [rowData]="opportunities"
                                    [columnDefs]="clientGridColumnDefs"
                                    [defaultColDef]="clientGridDefaultColDef"
                                    [sideBar]="clientGridSideBarDef"
                                    [icons]="clientGridIcons"
                                    [gridOptions]="clientGridOptions"
                                    [groupDisplayType]="'singleColumn'"
                                    [getMainMenuItems]="getClientGridMainMenuItems"
                                    [autoGroupColumnDef]="clientGridAutoGroupColumnDef"
                                    [getChartToolbarItems]="getClientGridChartToolbarItems"
                                    [customChartThemes]="lendioCustomChartsTheme"
                                    [chartThemes]="clientGridChartThemes"
                                    [rowSelection]="'single'"
                                    [aggFuncs]="clientGridAggregators"
                                    [getLocaleText]="getClientGridLocaleText"
                                    (gridSizeChanged)="onClientGridSizeChange()"
                                    (rowSelected)="onClientGridRowSelected($event)"
                                    (columnRowGroupChanged)="onClientGridGroupChange($event)"
                                    (gridReady)="onClientGridReady($event)"
                                    (firstDataRendered)="onClientFirstData($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </mat-card>
    </ng-container>
    <ng-template #NoDeals>
        <mat-card appearance="outlined" class="m-4 !p-4">
            <div class="flex-container m-4 !p-4">
                <div class="no-deals">
                    <div class="null-state-text">Leads will show here after they are submitted</div>
                </div>
            </div>
        </mat-card>
    </ng-template>
</ng-template>

