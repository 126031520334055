import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BusinessActions as BA } from './business.actions';
import { Business } from '@app/app/interfaces/business.model';
import { BusinessService } from '@app/app/services/business.service';
import { BorrowerValue } from '@app/app/interfaces/borrower-value.model';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

/**
 * Business state (aka, Borrower).
 */
export interface BusinessStateModel {
  business: Business;
  loading: boolean;
}

@State<Partial<BusinessStateModel>>({
  name: 'business',
  defaults: {
    loading: false,
  }
})

@Injectable()
export class BusinessState {

  @Selector()
  static loading(state: BusinessStateModel) {
    return state.loading;
  }

  @Selector()
  static business(state: BusinessStateModel) {
    return state.business;
  }

  @Selector()
  static businessName(state: BusinessStateModel) {
    return state.business?.name;
  }

  @Selector()
  static businessLenderId(state: BusinessStateModel) {
    return state.business?.lenderId;
  }

  @Selector()
  static businessTenantId(state: BusinessStateModel) {
    return state.business?.tenantId;
  }

  @Selector()
  static borrowerValueByAlias(alias: string) {
    return createSelector([BusinessState], ({business}) => {
      const values = business?.business?.borrowerValues || {};
      return Object.values<BorrowerValue>(values).find( val => val.alias === alias);
    });
  }

  @Selector()
  static borrowerValuesByAliases(borrowerValueAliases: string[]) {
    return createSelector([BusinessState], ({business}) => {
      const values = business?.business?.borrowerValues || {};
      return Object.values(values).filter( (val: BorrowerValue) => !!val?.alias && borrowerValueAliases.includes(val.alias) ) as BorrowerValue[];
    });
  }

  constructor(
    private businessService: BusinessService,
  ) {}

  @Action(BA.ClearBusinessState)
  ClearBusinessState(
    ctx: StateContext<BusinessStateModel>,
    { }: BA.ClearBusinessState,
  ) {
    ctx.patchState({business: {}} as Partial<BusinessStateModel>);
  }

  @Action(BA.GetBusiness)
  GetBusiness(
    ctx: StateContext<BusinessStateModel>,
    { businessId }: BA.GetBusiness,
    ) {
    ctx.patchState({loading: true});
    return this.businessService.getBusiness(businessId).pipe(
      catchError( (err: any) => {
        ctx.patchState({loading: false});
        return throwError(() => err);
      }),
      tap((fetchedBusiness) => {
        ctx.patchState({
          business: fetchedBusiness,
        })
      }),
    );
  }

  @Action(BA.UpdateBusiness)
  UpdateBusiness(
    ctx: StateContext<BusinessStateModel>,
    { business }: BA.UpdateBusiness
    ) {
    ctx.patchState({loading: true});
    return this.businessService.updateBusiness(business).pipe(
      catchError( (err: any) => {
        ctx.patchState({loading: false});
        return throwError(() => err);
      }),
      tap((updatedBusiness) => {
        ctx.patchState({
          business: updatedBusiness,
        })
      }),
    );
  }
}
