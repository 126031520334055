<div class="offer-container" #approvalComponent>

    <mat-expansion-panel class="" [ngClass]="offer?.benefits.length > 0 ? 'has-benefits' : ''" [expanded]="mainExpanded"
        (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header data-cy="offer-field-container"
            class="w-full flex justify-between items-center !mr-12">
            <div *ngIf="isTest">
                <div class="grow-0 test-indicator-label px-2
                        bg-gray-500 text-white font-bold">
                    Test
                </div>
            </div>
            <div class="approval-title w-full flex justify-between items-center !mr-12"
                [ngClass]="'grid-cols-' + maxOptionDisplay.length">
                <div *ngFor="let key of maxOptionDisplay" class="col-span-1" data-cy="offer-field">
                    <div class="!font-bold max-amount-header text-center mat-body-2">{{key | titlecase}}</div>
                    <div class="!font-normal text-center mat-body-2">
                        <div *ngIf="key === 'amount' || key === 'payback'; else noPipe">
                            {{ _maxOption[key] | currency: undefined: 'symbol': '1.0-0' }}
                        </div>
                        <ng-template #noPipe>
                            {{_maxOption[key] || '-'}}
                        </ng-template>
                    </div>
                </div>
            </div>
        </mat-expansion-panel-header>
        <!-- Content -->

        <div class="new-theme">
            <div *ngIf="errored" class="error-message">
                <div>
                    <p>
                        There is currently an integration error with <strong>{{ loanProductLender }}</strong>.
                        <span *ngIf="!offer.offerLink">Calculations for this approval are unavailable at this
                            time.</span>
                        Please contact Lendio product and development.
                    </p>
                    <div *ngIf="offer.offerLink">
                        To continue calculations for this approval, follow this link:
                        <div>
                            <a href="{{ offer.offerLink }}" ref="noopener noreferrer nofollow" target="_blank">{{
                                offer.offerLink }}</a>
                        </div>
                    </div>
                </div>
            </div>



            <div [ngClass]="{'error-blur': errored}" *ngIf="!initializing">
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title>Approval</mat-card-title>
                    </mat-card-header>
                    <div class="lendio-mat-card-divider"></div>
                    <mat-card-content>
                        <ng-container *ngIf="!readOnly else readOnlyView">
                            <div class="approval-content pr-3 flex-grow" data-cy="approval-calculator-content">

                                <div class="flex flex-row">
                                    <div class="grow">
                                        <!-- Term -->
                                        <div class="flex">
                                            <app-restricted-input class="restricted-input" [label]="'Term'"
                                                (valueChange)="changeTerm($event); calculate()" [value]="term"
                                                [step]="1" [loading]="loading" data-cy="term-restricted-input-field"
                                                [disabled]="disabled || (terms && terms.length <= 1)" [type]="'number'"
                                                [min]="minTerm" [max]="maxTerm">
                                            </app-restricted-input>

                                            <app-discrete-slider *ngIf="terms && terms.length > 1"
                                                class="w-100 flex-grow pl-4"
                                                (valueChange)="changeTerm($event); calculate()" [loading]="loading"
                                                [disabled]="disabled" [thumbLabel]="true" [value]="term"
                                                [marks]="terms">
                                            </app-discrete-slider>
                                        </div>

                                        <!-- Frequency -->
                                        <div class="flex">
                                            <app-restricted-input class="restricted-input" [label]="'Frequency'"
                                                [loading]="loading" [disabled]="disabled || true"
                                                [value]="paymentFrequencyFriendly" data-cy="frequency-restricted-input-field">
                                            </app-restricted-input>

                                            <app-restricted-radio class="w-100 ml-1 pt-1 pl-4"
                                                *ngIf="frequencyOptions && frequencyOptions.length > 1"
                                                [options]="frequencyOptions" [availableOptions]="availableFrequencies"
                                                [disabled]="disabled" [loading]="loading"
                                                (valueChange)="changePaymentFreq($event); calculate()"
                                                [value]="paymentFrequency"></app-restricted-radio>
                                        </div>

                                        <!-- Points -->
                                        <div class="flex">
                                            <app-restricted-input class="restricted-input" data-cy="points-restricted-input-field"
                                                (valueChange)="changePoints($event); calculate()" [value]="points"
                                                [label]="'Points'" [type]="'number'" [loading]="loading"
                                                [disabled]="disabled" [step]="1" [min]="currentOptionParams.points.min"
                                                [max]="currentOptionParams.points.max">
                                            </app-restricted-input>

                                            <app-restricted-slider class="w-100 flex-grow pl-4"
                                                [updateContinuously]="true"
                                                (valueChange)="changePoints($event); calculationType !== 'custom' && calculate();"
                                                (mouseup)="calculate()" [value]="points" [showValues]="true"
                                                [tickInterval]="0" [thumbLabel]="true" [loading]="loading"
                                                [disabled]="disabled" [step]="1" [min]="approvalBoundaries.points.min"
                                                [availableMin]="currentOptionParams.points.min"
                                                [max]="approvalBoundaries.points.max"
                                                [availableMax]="currentOptionParams.points.max">
                                            </app-restricted-slider>
                                        </div>

                                        <!-- Amount -->
                                        <div class="flex">
                                            <app-restricted-input class="restricted-input"
                                                (valueChange)="changeAmount($event); calculate()" [value]="amount"
                                                [fixedDecimal]="2" [type]="'number'" [label]="'Amount'"
                                                [loading]="loading"
                                                [disabled]="disabled || currentOptionParams.amount.min === availableAmount"
                                                [step]="100" [min]="currentOptionParams.amount.min"
                                                [max]="currentOptionParams.amount.max" data-cy="amount-restricted-input-field">
                                            </app-restricted-input>

                                            <div class="animate-opacity flex-grow pl-4"
                                                [ngStyle]="{'opacity': currentOptionParams.amount.min !== availableAmount ? 1 : 0}">
                                                <app-restricted-slider class="w-100 flex-grow"
                                                    [updateContinuously]="true"
                                                    (valueChange)="changeAmount($event); calculationType !== 'custom' && calculate();"
                                                    (mouseup)="calculate()" [value]="amount" [showValues]="true"
                                                    [currency]="true" [showCurrencyDecimals]="false" [tickInterval]="0"
                                                    [thumbLabel]="true" [loading]="loading"
                                                    [disabled]="disabled || currentOptionParams.amount.min === currentOptionParams.amount.max"
                                                    [step]="1000" [min]="approvalBoundaries.amount.min"
                                                    [availableMin]="currentOptionParams.amount.min"
                                                    [max]="approvalBoundaries.amount.max"
                                                    [availableMax]="currentOptionParams.amount.max">
                                                </app-restricted-slider>
                                            </div>
                                        </div>

                                        <!-- Origination -->
                                        <div class="flex">
                                            <app-restricted-input class="restricted-input" [label]="'Origination'"
                                                (valueChange)="changeOriginationPercent($event); calculate()"
                                                [(value)]="originationPercent" [loading]="loading"
                                                [disabled]="disabled || !validOriginationRange" [step]="0.1"
                                                [type]="'number'" [min]="curOriginParams.originationPercent.absMin"
                                                [max]="curOriginParams.originationPercent.absMax"
                                                [suffix]="originationInputSuffix" data-cy="origination-restricted-input-field">
                                            </app-restricted-input>

                                            <div class="animate-opacity flex-grow pl-4" *ngIf="validOriginationRange">
                                                <app-restricted-slider class="w-100 flex-grow"
                                                    [updateContinuously]="true"
                                                    (valueChange)="changeOriginationPercent($event); calculate();"
                                                    (mouseup)="calculate()" [(value)]="originationPercent"
                                                    [availableMax]="curOriginParams.originationPercent.absMax"
                                                    [availableMin]="curOriginParams.originationPercent.absMin"
                                                    [showValues]="true" [tickInterval]="0" [strictSteps]="false"
                                                    [thumbLabel]="true" [customThumbLabel]="originationThumbLabel"
                                                    [loading]="loading" [disabled]="disabled || !validOriginationRange"
                                                    [step]="0.1" [min]="curOriginParams.originationPercent.min"
                                                    [max]="curOriginParams.originationPercent.max">
                                                </app-restricted-slider>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Calculated Data -->
                                    <div class="flex approval-bullet-list justify-end" [ngClass]="{'loading': loading}">
                                        <div class="grid grid-cols-2" *ngFor="let key of optionDataKeys"
                                            data-cy="approval-bullet-item">
                                            <div class="grid grid-flow-col items-center text-secondary text-right w-50">
                                                {{ key }}
                                            </div>
                                            <div
                                                class="grid gap-2 grid-flow-col ml-3 items-center font-bold w-50 animate-loading">
                                                <span>
                                                    {{ optionData[key] }}
                                                </span>
                                                <ng-container *ngIf="hasVariablePayment && key === 'Payment'"
                                                    #variablePayment>
                                                    <mat-chip fontSet="material-icons-outlined" class="variable-chip">
                                                        <mat-icon
                                                            [matTooltip]="'This approval has a variable payment calculated as a percentage of sales.'"
                                                            class="material-symbols-outlined icon-class align-middle">
                                                            info
                                                        </mat-icon>
                                                        Variable
                                                    </mat-chip>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>

                <mat-card appearance="outlined" class="mt-4">
                    <!-- Stips -->
                    <mat-card-header>
                        <mat-card-title>Stipulations</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        @if(stips.length) {
                        <mat-list>
                            @for(stip of stips; track stip; let i = $index) {
                            <div data-cy="approval-stip" class="mb-1">
                                <span class="mr-0.5">{{ i + 1 }}.</span>
                                <span>{{ stip.name }}</span>
                                @if(stip.type === 'conditional') {
                                <span class="ml-0.5">({{ stip.minAmount | currency }}+)</span>
                                }
                            </div>
                            }
                        </mat-list>
                        } @else {
                        <!-- Test empty state here and in other places. Method in length?-->
                        <ng-template [ngTemplateOutlet]="emptyState"></ng-template>
                        }
                    </mat-card-content>
                    <span>
                        <mat-card-header>
                            <mat-card-title>Notes</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div data-cy="approval-note" *ngIf="notes else emptyState">
                                <p class="mat-body-1">{{ notes }}</p>
                            </div>
                        </mat-card-content>
                    </span>
                </mat-card>

                <!--Additional Details-->
                <mat-card appearance="outlined" class="mt-4">
                    <mat-card-header>
                        <mat-card-title>Additional details</mat-card-title>
                    </mat-card-header>
                    <div class="lendio-mat-card-divider"></div>
                    <mat-card-content>

                        <div class="flex flex-col gap-2">
                            <div data-cy="additional-details-field"
                                class="!w-full flex flex-row justify-between items-center">
                                <div class="mat-body-2">Net requirement</div>
                                <div class="mat-body-2">{{ position }}</div>
                            </div>
                            <div data-cy="additional-details-field"
                                class="!w-full flex flex-row justify-between items-center">
                                <div class="mat-body-2">Position</div>
                                <div class="mat-body-2">{{ netMinimum / 100 | percent }}</div>
                            </div>
                            <div data-cy="additional-details-field"
                                class="!w-full flex flex-row justify-between items-center">
                                <div class="mat-body-2">Expiration</div>

                                @if (expires && expires !== '0000-00-00 00:00:00') {
                                <div class="mat-body-2"> {{ expires | date }}</div>
                                }
                            </div>
                            @if (_offer.offerLink) {
                            <div data-cy="additional-details-field"
                                class="!w-full flex flex-row justify-between items-center">
                                <div class="mat-body-2">Offer link</div>
                                <a class="font-bold text-lendio-blue-400 hover:text-lendio-blue-500"
                                    href="{{ _offer.offerLink }}" target="_blank">
                                    View
                                </a>
                            </div>
                            }
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<ng-template #readOnlyView>
    <div class="p-4">
        <!-- Calculated Data -->
        <div [ngClass]="{'loading': loading}">
            <div class="grid grid-flow-col justify-between" *ngFor="let key of optionDataKeys">
                <span>{{ key }}</span>
                <span>{{ optionData[key] }}</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #emptyState>
    <p class="mat-body-1">-</p>
</ng-template>