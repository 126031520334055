<div>
    <h2 class="mat-headline-4 !m-0 !mb-4">Your account has been locked.</h2>
    <p class="mat-body-1 pb-4">
        To unlock your account, you can reset your password or reach out to <a class="text-blue-400" href="mailto:lenderoperations@lendio.com">our support team</a> for assistance.
    </p>
    <div class="flex justify-end">
        <button mat-button color="primary" class="mr-2" (click)="backToSignIn()">
            <span class="mat-body-1">Return to Log In</span>
        </button>
        <button mat-flat-button color="primary" (click)="forgotPasswordDialog()">
            <span class="mat-body-1">Reset Password</span>
        </button>
    </div>
</div>
