import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
@Component({
  selector: 'group-header',
  templateUrl: './group-header.component.html',
  styleUrls: ['./group-header.component.scss']
})
export class GroupHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;
  public expandState = 'expanded';

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  get hasCollapsedRow(): boolean {
    let collapsedRow = false;
    this.params.api.forEachNode((node) => {
      if (node?.group && !node.expanded) {
        collapsedRow = true;
      }
    });
    return collapsedRow;
  }

  expandAll(): void {
    this.params.api.forEachNode((node) => {
      if (node?.group) {
        node.setExpanded(true);
      }
    })
    this.expandState = 'expanded'
  }

  collapseAll(): void {
    this.params.api.forEachNode((node) => {
      if (node?.group) {
        node.setExpanded(false);
      }
    })
    this.expandState = 'collapsed'
  }

  expandToggle(): void {
    this.hasCollapsedRow ? this.expandAll() : this.collapseAll();
  }

}
