import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { LendioResponse } from '../interfaces/lendio-response';
import { Observable } from 'rxjs';
import { DealActivity } from '@app/app/interfaces/deal-activity.model';
import { DealNote } from '@app/app/interfaces/deal-note.model';
import { StatusOption } from '../interfaces/status-option';
import { formatISO } from 'date-fns';
import { DealStage } from '../components/deal-status-change-dropdown/deal-stage.enum';

export interface DealActivitiesResponse {
  data: DealActivity[];
}

export interface DealNotesResponse {
  data: DealNote[];
}

export interface NewDealNoteResponse {
  data: DealNote;
}

@Injectable({ providedIn: 'root' })
export class DealsService {
  constructor(private _http: HttpClient) {}

  /**
   * Update deal status using Public Lender API endpoint
   * 
   * @param dealId 
   * @param status 
   * @param fund 
   * @returns Observable<LendioResponse>
   */
  changeDealStatus(dealId: number, status: string, fund: boolean = false): Observable<LendioResponse> {
    const body = fund ? {
      status,
      fund
    } : {
      status,
    }
    return this._http.put<LendioResponse>( `${environment.apiUrl}/l/v2/internal/deals/${dealId}/status`, body );
  }

  /**
   * Update deal status using main API deal endpoint (i.e. not Public Lender API)
   * 
   * @param dealId 
   * @param statusOption
   * @param reason
   * @param otherReason
   * @returns Observable<LendioResponse>
   */
  updateDealStatus(
    dealId: number,
    statusOption: StatusOption,
    reason: string,
    otherReason: string
  ): Observable<LendioResponse> {
    return this._http.put<LendioResponse>(`${environment.apiUrl}/deal/${dealId}`, {
      stage: statusOption.stage,
      status: statusOption.value,
      reason,
      otherReason,
      dateClosed: statusOption.stage === DealStage.Funded ? formatISO(new Date()) : null,
    });
  }

  getDealActivities(dealId: number): Observable<DealActivitiesResponse> {
    return this._http.get<DealActivitiesResponse>(`${ environment.apiUrl }/lender-portal/deal/${dealId}/activities`, {});
  }

  getDealNotes(dealId: number): Observable<DealNotesResponse> {
    return this._http.get<DealNotesResponse>(`${ environment.apiUrl }/l/v2/internal/deals/${dealId}/notes`, {})
  }

  addDealNote(dealId: number, content: string): Observable<NewDealNoteResponse> {
    return this._http.post<NewDealNoteResponse>(`${ environment.apiUrl }/l/v2/internal/deals/${dealId}/notes`, {
      content
    })
  }

  assignUserToDeal(deal: number, user: number) {
    return this._http.post(`${environment.apiUrl}/l/v2/internal/deals/assign-user`, {
      deal,
      user,
    });
  }

  unassignUserFromDeal(deal: number, user: number) {
    return this._http.delete(`${environment.apiUrl}/l/v2/internal/deals/assign-user`, {
      body: {
        deal,
        user,
      }
    });
  }
}
