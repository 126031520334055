import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  LendioSnackbarComponent
} from '@app/app/components/lendio-angular-material-theme/lendio-snackbar/lendio-snackbar.component';
import {
  LendioSnackBarOptions,
} from '@app/app/components/lendio-angular-material-theme/lendio-snackbar/lendio-snackbar-options';

@Injectable({
  providedIn: 'root'
})
export class LendioSnackbarService {

  constructor(private _snackBar: MatSnackBar) { }

  open(options: LendioSnackBarOptions): MatSnackBarRef<any> {
    this.dismiss();

    if (!options.duration && !options.canDismiss) {
      options.duration = 3000;
    }

    if (!options.horizontalPosition) {
      options.horizontalPosition = 'end';
    }

    if (!options.verticalPosition) {
      options. verticalPosition = 'bottom'
    }

    if (options.undismissable) {
      options.duration = undefined;
      options.canDismiss = false;
    }

    const component = options.component || LendioSnackbarComponent;
    return this._snackBar.openFromComponent(component, {
      duration: options.duration,
      horizontalPosition: options.horizontalPosition,
      verticalPosition: options.verticalPosition,
      data: options
    });
  }

  dismiss() {
    this._snackBar.dismiss();
  }
}
