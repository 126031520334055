import { Component, Input, OnInit } from '@angular/core';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';
import { Borrower } from 'src/app/interfaces/borrower.model';
import { orderBy } from 'lodash';
import { Store } from '@ngxs/store';

@Component( {
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: [ './details.component.scss' ]
} )
export class DetailsComponent implements OnInit {

  @Input() applicationDetails: ApplicationDetails;
  @Input() applicationFields: any;

  locale: string;
  borrowerValues: any;
  primaryContact: any;
  borrower: Borrower;
  borrowerLoans: any;

  constructor(
    private store: Store,
    private localeSettingService: LocaleSettingService
  ) {
    this.locale = this.localeSettingService.getLocale();
  }

  ngOnInit(): void {
    this.borrowerValues = this.applicationDetails.borrowerValues;
    this.primaryContact = this.applicationDetails.deal.borrower.primary_contact;
    this.borrower = this.applicationDetails.deal.borrower;

    const unsortedBorrowerLoans = this.applicationDetails.borrowerLoans;
    if ( unsortedBorrowerLoans && unsortedBorrowerLoans.length > 0 ) {
      this.borrowerLoans = orderBy( unsortedBorrowerLoans, 'position', 'asc' );
    }
  }
}
