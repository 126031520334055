<div *ngIf="submitting" class="loading text-center" [@fadeIn] [@fadeOut]>
    <h2>Submitting Offer...</h2>
    <app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
</div>

<form [formGroup]="offerForm" [ngClass]="(submitting ? 'hidden' : '')">
    <ng-container *ngIf="useNewTheme; else OldThemeForm">
        <div class="lendio-density-1 flex flex-col gap-8">
            <div class="flex flex-row items-center gap-4">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Date received</mat-label>
                    <input matInput
                           [matDatepicker]="dateReceivedPicker3"
                           required
                           formControlName="received"
                           data-cy="received-input"
                           [max]="today">
                    <mat-datepicker-toggle matSuffix [for]="dateReceivedPicker3"></mat-datepicker-toggle>
                    <mat-datepicker #dateReceivedPicker3></mat-datepicker>
                    <mat-error data-cy="received-required"
                               *ngIf="dateReceivedControl.invalid && (dateReceivedControl.dirty || dateReceivedControl.touched)">
                        Date received is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Offer URL</mat-label>
                    <input matInput type="text" required formControlName="offerLink" data-cy="offer-link-input">
                    <mat-error data-cy="offer-link-required" *ngIf="offerLinkControl.hasError('required')">Offer link is required</mat-error>
                    <mat-error data-cy="offer-link-invalid" *ngIf="offerLinkControl.hasError('pattern')">Please enter a valid url</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-row justify-between items-center mt-4">
            <span class="mat-body-2"><span class="align-sub">*</span> required fields</span>
            <div>
                <button mat-button (click)="close()" class="mr-2">Cancel</button>
                <button mat-flat-button
                        color="primary"
                        data-cy="offer-link-submit-btn"
                        (click)="submit()"
                        [class.spinner]="submitting"
                        [disabled]="offerForm.invalid || submitting">
                    {{ submitting ? '&nbsp;' : 'Add offer' }}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-template #OldThemeForm>
        <div class="row">
            <div class="col">
                <p class="col-header">Date Received</p>
                <mat-form-field appearance="fill" class="w-full">
                    <input matInput [matDatepicker]="dateReceivedPicker3" required formControlName="received" data-cy="received-input">
                    <mat-datepicker-toggle matSuffix [for]="dateReceivedPicker3"></mat-datepicker-toggle>
                    <mat-datepicker #dateReceivedPicker3></mat-datepicker>
                    <mat-error data-cy="received-required" *ngIf="checkError('received', 'required')">Date received is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col">
                <p class="col-header">Offer Url</p>
                <mat-form-field appearance="fill" class="w-full">
                    <input matInput type="text" required formControlName="offerLink" data-cy="offer-link-input">
                    <mat-error data-cy="offer-link-required" *ngIf="checkError('offerLink', 'required')">Offer link is required</mat-error>
                    <mat-error data-cy="offer-link-invalid" *ngIf="checkError('offerLink', 'pattern')">Please enter a valid url</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col text-right">
                <button mat-button class="cancel-new-offer" (click)="close()">Cancel</button>
                <button mat-button data-cy="offer-link-submit-btn" class="send-offer" (click)="submit()" [class.spinner]="submitting" [disabled]="offerForm.invalid || submitting">
                    {{ submitting ? '&nbsp;' : 'Send Offer' }}
                </button>
            </div>
        </div>
    </ng-template>
</form>
