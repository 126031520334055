import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadersModule } from '../loaders/loaders.module';
import { DocumentsSmartComponent } from './documents-smart/documents-smart.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentsQuickViewComponent } from './documents-quick-view/documents-quick-view.component';
import { DocumentsComponent } from './documents/documents.component';
import { OldDocumentsComponent } from './documents/old-documents.component';
import { DocumentEditDialogComponent } from './document-edit-dialog/document-edit-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';


@NgModule( {
  declarations: [
    OldDocumentsComponent,
    DocumentEditDialogComponent,
    DocumentsSmartComponent,
    DocumentsQuickViewComponent,
  ],
  exports: [
    DocumentsSmartComponent,
    DocumentsQuickViewComponent
  ],
  imports: [
    CommonModule,
    // DocumentsComponent is standalone so imported like a module.
    DocumentsComponent,
    FormsModule,
    ReactiveFormsModule,
    LoadersModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    [provideNgxMask()],
  ]
} )
export class DocumentsModule {
}
