import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoanProductQuestion } from 'src/app/interfaces/loan-product-question.model';
import { LoanProductService } from 'src/app/services/loan-product.service';
import { Question } from 'src/app/interfaces/question.model';
import {
  RequiredInformation,
  CompoundField,
  RequiredInfoObject, Designation
} from '../../../interfaces/required-information.model';
import {
  AddQuestionDataObject, AddQuestionDialogComponent, OptionObject
} from '../add-question-dialog/add-question-dialog.component';

@Component({
  selector: 'app-required-information',
  templateUrl: './required-information.component.html',
  styleUrls: ['./required-information.component.scss']
})
export class RequiredInformationComponent implements OnInit, OnChanges {

  @Input() showButton = true;
  @Input() displayedSection: string;
  @Input() dialogSections: string[];
  @Input() loanProductQuestions: LoanProductQuestion[];
  @Input() questions: Question[];
  @Input() hasWriteAccess: boolean;

  @Output() requirementRemoved = new EventEmitter<RemoveRequirementPayload>();
  @Output() requirementsAdded = new EventEmitter<[]>();
  @Output() requiredForOfferToggled = new EventEmitter<[]>();

  requiredInfo: RequiredInformation;
  compoundFields: CompoundField[] | null;
  displayedFields: string[];
  hiddenFields: string[];
  rows: RequiredInfoRow[] = [];
  rowHovered: any = [];
  dialogData: AddQuestionDataObject;

  private initialized = false;

  constructor(private loanProductService: LoanProductService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.initializeFields();
    this.initialized = true;
  }

  initializeFields(): void {
    if (!this.displayedSection) {
      return;
    }

    this.requiredInfo = new RequiredInformation();
    this.rows = [];
    this.hiddenFields = this.requiredInfo.hiddenFields;

    if (this.displayedSection === 'ownerInfo' || this.displayedSection === 'businessInfo') {
      this.loanProductService.getRequiredInformation(this.displayedSection).pipe(take(1)).subscribe((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.initializePopulation(res.data[this.displayedSection]);
      }, (res) => {
        // Use default hardcoded questions on fail
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.initializePopulation(this.requiredInfo[this.displayedSection]);
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.initializePopulation(this.requiredInfo[this.displayedSection]);
    }

    this.rows.sort((a, b) => {
      return a.display > b.display ? 1 : -1;
    });
  }

  initializePopulation(info: RequiredInfoObject): void {
    this.compoundFields = info.compoundFields;
    this.displayedFields = info.fields;
    this.populateCompoundFields();
    this.populateStandardFields();
    this.populateDialogOptions();
  }

  populateCompoundFields(): void {
    if (this.compoundFields && this.compoundFields.length > 0) {
      this.compoundFields.forEach((cf) => {
        const index = this.rows.findIndex((x) => x.display === cf.display);

        if (index === -1) {
          const fieldIds: (string | number)[] = [];
          const fieldsChecked = [];

          let requiredForOffer: boolean;
          const field = cf.fields[0];

          this.loanProductQuestions.forEach((q) => {
            if (cf.fields.includes(q.field)) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fieldIds.push(q.id);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fieldsChecked.push(q.field);
            }
            if (field === q.field) {
              requiredForOffer = q.requiredForOffer;
            }
          });

          if (fieldsChecked.length === cf.fields.length) {
            this.rows.push({
              display: cf.display,
              section: this.displayedSection,
              isCompound: true,
              fieldIds,
              fieldId: null,
              fields: cf.fields,
              field,
              removable: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              requiredForOffer
            });
          }
        }
      });
    }
  }

  populateStandardFields(): void {
    if (this.loanProductQuestions) {
      this.loanProductQuestions.forEach((lpQuestion) => {
        const rowIndex = this.rows.findIndex(
          (x) => {
            if (x.fieldIds) {
              return x.fieldIds.includes(lpQuestion.id);
            }

            return x.fieldId === lpQuestion.id;
          });
        const cfIndex = this.compoundFields ? this.compoundFields.findIndex(
          (x) => x.fields.includes(lpQuestion.field)) : -1;

        if (cfIndex !== -1) {
          return;
        }

        if (rowIndex === -1) {
          const qIndex = this.questions.findIndex(
            (x) => x.alias === lpQuestion.field || x.id === lpQuestion.field);
          const question = this.questions[qIndex];

          if (question) {
            if (
              (
                this.displayedSection === 'ownerInfo'
                && question.appSection === 'personalInfo'
                && question.type !== 'document'
                && question.offerAskable === true
              )
              || (
                this.displayedSection === 'businessInfo'
                && question.appSection !== 'personalInfo'
                && question.type !== 'document'
                && question.offerAskable === true
              )
              || (
                this.displayedSection === 'documentsRequiredForOffer'
                && question.type === 'document'
                && question.offerAskable === true
                && lpQuestion.requiredForOffer
              )
              || (
                this.displayedSection === 'documentsRequiredForContract'
                && question.type === 'document'
                && question.contractAskable === true
                && lpQuestion.requiredForContract
              )
            ) {
              this.rows.push({
                display: question?.shortName ? question.shortName : question.name,
                section: this.displayedSection,
                isCompound: false,
                fieldIds: null,
                fieldId: question.id,
                fields: null,
                field: question.alias,
                removable: !this.hiddenFields.includes(question.alias),
                requiredForOffer: lpQuestion.requiredForOffer
              });
            }
          }
        }
      });

      this.rows.sort((a, b) => (a.display > b.display) ? 1 : -1);
    }
  }

  removeRequirement(row: RequiredInfoRow): void {
    const requiredInfo = new RequiredInformation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const info: RequiredInfoObject = requiredInfo[row.section];
    const designations: Designation[] | null = info.designations;
    const payload: RemoveRequirementPayload = {
      fields: []
    };


    if (row.isCompound) {
      payload.fields = row.fields;
    } else {
      payload.fields = [row.field];
    }

    if (designations) {
      payload.designations = designations;
    }

    this.requirementRemoved.emit(payload);
  }

  getTimeDisplay(row: RequiredInfoRow): string {
    if (row.isCompound) {
      return '';
    }

    if (row.field === 'bank_statement') {
      const index = this.loanProductQuestions.findIndex((x) => x.field === row.field);

      if (index === -1) {
        return '';
      }

      return ' - ' + this.loanProductQuestions[index].documentQuantity + ' months';
    } else if (row.field === 'business_tax_returns' || row.field === 'personal_tax_returns') {
      const index = this.loanProductQuestions.findIndex((x) => x.field === row.field);

      if (index === -1) {
        return '';
      }

      return ' - ' + this.loanProductQuestions[index].documentQuantity + ' years';
    }

    return '';
  }

  populateDialogOptions(): void {
    if (!this.showButton) {
      return;
    }

    const requiredInfo = new RequiredInformation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const info: RequiredInfoObject = requiredInfo[this.displayedSection];
    const data: AddQuestionDataObject = {
      dialogTitle: this.dialogSections.length > 1 ? 'Documents' : info.title,
      sections: [],
      questions: this.questions
    };

    this.dialogSections.forEach((section) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const sectionInfo: RequiredInfoObject = requiredInfo[section];
      const designations: Designation[] | null = sectionInfo.designations;
      const fields = sectionInfo.fields;
      const options: OptionObject[] = [];

      const compoundSubArrays: string[][] = [];
      let compoundSubFields = [];
      this.questions.forEach((question) => {
        const index = this.loanProductQuestions.findIndex(
          (x) => (x.field === question.alias || x.id === question.alias)
        );

        const lpQuestion = this.loanProductQuestions[index];

        let currentOption: OptionObject;
        if (question.group) {
          if (this.compoundFields && this.compoundFields.length > 0) {
            this.compoundFields.forEach((cf) => {
              const compoundIndex = options.findIndex((x) => x.display === cf.display);

              if (compoundIndex === -1) {
                const fieldIds: any[] = [];
                const fieldsChecked = [];

                let requiredForOffer: boolean;
                const field = cf.fields[0];

                this.questions.forEach((q) => {
                  if (cf.fields.includes(q.alias)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    fieldIds.push(q.id);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    fieldsChecked.push(q.alias);
                  }
                  if (field === q.alias) {
                    const subIndex = this.loanProductQuestions.findIndex(
                      (x) => (x.field === question.alias || x.id === question.alias)
                    );

                    const LPQ = this.loanProductQuestions[subIndex];
                    requiredForOffer = LPQ ? LPQ.requiredForOffer : false;
                  }
                });
                if (fieldsChecked.length === cf.fields.length) {
                  currentOption = {
                    display: cf.display,
                    section: this.displayedSection,
                    isCompound: true,
                    fieldIds,
                    fieldId: null,
                    fields: cf.fields,
                    field,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    requiredForOffer,
                    requiredForContract: false,
                    mustAllowNull: question.mustAllowNull
                  };
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  compoundSubArrays.push(cf.fields);
                }
              }
            });
          }
        }
        compoundSubFields = [].concat.apply([], compoundSubArrays);
        const fieldInCompound = compoundSubFields.findIndex((x: string) => x === question.alias);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!currentOption && fieldInCompound === -1) {
          currentOption = {
            display: question.shortName || question.name,
            section,
            isCompound: false,
            field: question.alias,
            requiredForContract: lpQuestion ? lpQuestion.requiredForContract : false,
            requiredForOffer: lpQuestion ? lpQuestion.requiredForOffer : false,
            mustAllowNull: question.mustAllowNull
          };
        }

        if (question.alias === 'bank_statement' || question.alias === 'business_tax_returns' || question.alias === 'personal_tax_returns') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          currentOption.documentQuantity = 1;
        }

        if (designations) {
          designations.forEach((designation) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            currentOption[designation.field] = designation.value;
          });
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (currentOption){
          if (
            (
              section === 'ownerInfo'
              && question.appSection === 'personalInfo'
              && question.type !== 'document'
              && question.offerAskable === true
            )
            || (
              section === 'businessInfo'
              && question.appSection !== 'personalInfo'
              && question.type !== 'document'
              && question.offerAskable === true
            )
            || (
              section === 'documentsRequiredForOffer'
              && question.type === 'document'
              && question.offerAskable === true
            )
            || (
              section === 'documentsRequiredForContract'
              && question.type === 'document'
              && question.contractAskable === true
            )
          ) {
            options.push(currentOption);
          }
        }
      });

      options.sort((a, b) => (a.display > b.display) ? 1 : -1);

      data.sections.push({
        title: sectionInfo.title,
        options
      });
    });

    this.dialogData = data;
  }

  openAddRequirementsDialog(): void {
    this.dialogData.questions = this.questions;

    const dialogRef = this.dialog.open(AddQuestionDialogComponent, {
      width: '400px',
      panelClass: 'no-overflow',
      data: this.dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.addRequirements(result);
    });
  }

  addRequirements(payload: any): void {
    this.requirementsAdded.emit(payload);
  }

  toggleRequiredForOffer(payload: any): void {
    this.requiredForOfferToggled.emit(payload);
  }

  canAddRequirement(): boolean {
    let optionCount = 0;

    this.dialogData?.sections.forEach((section) => {
      optionCount += section.options.length;
    });

    return optionCount > 0;
  }

  designationsMet(field: string, section: string = this.displayedSection): boolean {
    const requiredInfo = new RequiredInformation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sectionInfo: RequiredInfoObject = requiredInfo[section];
    const designations: Designation[] | null= sectionInfo.designations;
    let designationRestricted = false;

    if (!designations) {
      return true;
    }

    designations.forEach((designation) => {
      const index = this.loanProductQuestions.findIndex((x) => x.field === field);

      if (index === -1) {
        designationRestricted = true;
        return;
      }

      const question = this.loanProductQuestions[index];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (question[designation.field] !== designation.value) {
        designationRestricted = true;
      }
    });

    return !designationRestricted;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.loanProductQuestions || changes.questions) && this.initialized) {
      this.initializeFields();
    }
  }
}

export interface RequiredInfoRow {
  display: string;
  section: string;
  isCompound: boolean;
  fieldIds: (number | string)[] | null;
  fieldId: number | string | null;
  fields: string[] | null;
  field: string;
  removable: boolean;
  requiredForOffer: boolean;
}

export interface RemoveRequirementPayload {
  fields: string[] | null;
  designations?: Designation[];
}
