import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/l/v1/internal`;

@Injectable({
  providedIn: 'root'
})
export class ApplicationDetailsService {

  constructor(private http: HttpClient) {}

  getApplicationDetails(dealId: number): Observable<any> {
    return this.http.get<any>(`${baseUrl}/deals/show/${dealId}`, {
      params: {
        counts: '0'
      }
    });
  }

  getApplicationFields(dealId: number): Observable<any> {
    return this.http.get<any>(`${baseUrl}/deals/${dealId}`);
  }
}
