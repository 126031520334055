import { Component, OnInit, Inject, computed } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-lead-submission-dialog',
  templateUrl: './lead-submission-dialog.component.html',
  styleUrls: ['./lead-submission-dialog.component.scss']
})
export class LeadSubmissionDialogComponent implements OnInit {

  loading = false;
  submissionState = 'form';

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(
    private _themeService: LendioAngularMaterialThemeService,
    public dialogRef: MatDialogRef<LeadSubmissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { partnerId: number },
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.loading = true;
    return;
  }

  setState(state: string): void {
    this.submissionState = state;
  }
}
