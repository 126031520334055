import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { NaicsCodeResponse } from '../interfaces/naics-code-response.model';
import { NaicsTermResponse } from '../interfaces/naics-term-response.model';

@Injectable({
    providedIn: 'root'
})
export class NaicsSearchService {

    private url = environment.apiUrl + '/naics';

    constructor(private httpClient: HttpClient) {}

    searchByTerms(terms: string): Observable<NaicsTermResponse> {
        return this.httpClient.get<NaicsTermResponse>(this.url, {
            params: {
                endpoint: 's',
                year: '2017',
                terms
            }
        });
    }

  searchByCode(code: number): Observable<NaicsCodeResponse> {
        return this.httpClient.get<NaicsCodeResponse>(this.url + '/find', {
            params: {
                endpoint: 'q',
                year: '2017',
                code
            }
        });
    }
}
