
import { ListSortData } from '@app/app/interfaces/list-sort.model';

export class InitSorts {
    static readonly type = '[Sorts] InitSorts';
    constructor(
        public path: string
    ) {}
}

export class UpdateSort {
    static readonly type = '[Sorts] UpdateSort';
    constructor(
        public list: ListSortData
    ) {}
}
