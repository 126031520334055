import { NgModule } from '@angular/core';
import {
  DynamicScreenHeightDirective
} from '@app/app/directives/dynamic-screen-height/dynamic-screen-height.directive';

@NgModule( {
  declarations: [
    DynamicScreenHeightDirective,
  ],
  exports: [
    DynamicScreenHeightDirective,
  ]
} )
export class DynamicScreenHeightModule {
}
