@if (applicationDetails) {
<div class="flex flex-row">
    <div class="flex flex-col flex-auto pr-5 max-w-[50%] financial-details" data-cy="mp-financial-details">
        <h4 class="table-pipeline-header">Financial details</h4>
        <div class="px-3">
            <table class="table table-pipeline">
                <tr class="table-row">
                    <td class="table-pipeline-item">Amount requested</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[68]">{{ borrowerValues[68].value | currency }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Annual revenue</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[72]">{{ borrowerValues[72].value | currency }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Annual profits</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[4]">{{ borrowerValues[4].value | currency }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Avg monthly revenue</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[466]">{{ borrowerValues[466].value | currency }}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="flex flex-col flex-auto pl-2.5 max-w-[50%]" data-cy="mp-outstanding-loans">
        <h4 class="table-pipeline-header">Outstanding loans</h4>
        <div class="px-3">
            <table *ngIf="borrowerLoans && borrowerLoans.length > 0" class="table table-pipeline">
                <tr class="table-row text-left">
                    <th>Position</th>
                    <th>Lender</th>
                    <th>Balance</th>
                    <th>Will payoff</th>
                </tr>
                <tr *ngFor="let borrowerLoan of borrowerLoans" class="table-row">
                    <td class="font-thin">{{ borrowerLoan.position }}</td>
                    <td class="font-thin">{{ borrowerLoan.lenderName }}</td>
                    <td class="font-thin">
                        <span>{{ borrowerLoan.amount | currency }}</span>
                    </td>
                    <td class="font-thin">{{ borrowerLoan.willPayoff == 1 ? 'Yes' : borrowerLoan.willPayoff == 0 ? 'No' : ''}}</td>
                </tr>
            </table>
            <div *ngIf="!borrowerLoans || borrowerLoans.length == 0" class="mt-2">
                <span class="table-pipeline-item">None</span>
            </div>
        </div>
    </div>
</div>

<div class="owner-details" data-cy="mp-owner-details">
    <h4 class="table-pipeline-header">Owner details</h4>
    <div class="flex">
        <div class="flex-1 px-3">
            <table class="table table-pipeline">
                <tr class="table-row">
                    <td class="table-pipeline-item">Name</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="primaryContact?.first">{{ primaryContact.first }} </span><span *ngIf="primaryContact?.last">{{ primaryContact.last }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Email address</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="primaryContact?.email">{{ primaryContact.email }}</span>
                    </td>
                </tr>
                <tr *ngIf="primaryContact" class="table-row">
                    <td class="table-pipeline-item">Title/Role</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="primaryContact?.title">{{ primaryContact.title }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Birth date</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[250]">{{ borrowerValues[250].value | toDateObj | date }}</span>
                    </td>
                </tr>
                <tr [ngSwitch]="locale" class="table-row">
                    <td class="table-pipeline-item">Address</td>
                    <td *ngSwitchCase="'en-GB'" class="table-pipeline-value">
                        <span *ngIf="borrowerValues[254]">{{ borrowerValues[254].value }}<br /></span>
                        <span *ngIf="borrowerValues[318]">{{ borrowerValues[318].value }}<br /></span>
                        <span *ngIf="borrowerValues[988]">{{ borrowerValues[988].value }}<br /></span>
                        <span *ngIf="borrowerValues[256] && borrowerValues[256].value && borrowerValues[256].value.length">{{ borrowerValues[256].value | uppercase }}<br /></span>
                        <span *ngIf="borrowerValues[260]">{{ borrowerValues[260].value | uppercase }}</span>
                    </td>
                    <td *ngSwitchDefault class="table-pipeline-value">
                        <span *ngIf="borrowerValues[254]">{{ borrowerValues[254].value }}<br /></span>
                        <span *ngIf="borrowerValues[256] && borrowerValues[256].value && borrowerValues[256].value.length">{{ borrowerValues[256].value }}, </span>
                        <span *ngIf="borrowerValues[258]">{{ borrowerValues[258].value }} </span>
                        <span *ngIf="borrowerValues[260]">{{ borrowerValues[260].value }}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="flex-1 px-3">
            <table class="table table-pipeline">
                <tr class="table-row">
                    <td class="table-pipeline-item">Credit range</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[66]">{{ borrowerValues[66].value }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Bankruptcy</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[80]">{{ borrowerValues[80].value == 0 ? 'No' : 'Yes' }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">% Ownership</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[248]">{{ borrowerValues[248].value }}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div class="business-details" data-cy="mp-business-details">
    <h4 class="table-pipeline-header">Business details</h4>
    <div class="flex">
        <div class="flex-1 px-3">
            <table *ngIf="borrower" class="table table-pipeline">
                <tr class="table-row">
                    <td class="table-pipeline-item">Business name</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrower.name">{{ borrower.name }}</span>
                    </td>
                </tr>
                <tr [ngSwitch]="locale" class="table-row">
                    <td class="table-pipeline-item">Address</td>
                    <td *ngSwitchCase="'en-GB'" class="table-pipeline-value">
                        <span *ngIf="borrower.street">{{ borrower.street }}<br /></span>
                        <span *ngIf="borrower.street2">{{ borrower.street2 }}<br /></span>
                        <span *ngIf="borrower.street3">{{ borrower.street3 }}<br /></span>
                        <span *ngIf="borrower.city">{{ borrower.city | uppercase }}<br /></span>
                        <span *ngIf="borrower.zipId" class="intl-post-code">{{ borrower.zipId }}</span>
                    </td>
                    <td *ngSwitchDefault class="table-pipeline-value">
                        <span *ngIf="borrower.street">{{ borrower.street }}<br /></span>
                        <span *ngIf="borrower.street2">{{ borrower.street2 }}<br /></span>
                        <span *ngIf="borrower.city">{{ borrower.city }}, </span><span *ngIf="borrower.stateId">{{ borrower.stateId }} </span><span *ngIf="borrower.zipId">{{ borrower.zipId }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">DBA name</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[226]">{{ borrowerValues[226].value }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Fed/State tax id</td>
                    <td class="table-pipeline-value tax-id">
                        <span *ngIf="borrowerValues[236]">{{ borrowerValues[236].value }}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="flex-1 px-3">
            <table class="table table-pipeline">
                <tr class="table-row">
                    <td class="table-pipeline-item">Entity type</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[230]">{{ borrowerValues[230].value }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">State of incorporation</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[232]">{{ borrowerValues[232].value }}</span>
                    </td>
                </tr>
                <tr *ngIf="borrower" class="table-row">
                    <td class="table-pipeline-item">Industry</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrower.industryName">{{ borrower.industryName }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Business start date</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[238]">{{ borrowerValues[238].value | toDateObj | date }}</span>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="table-pipeline-item">Loan purpose</td>
                    <td class="table-pipeline-value">
                        <span *ngIf="borrowerValues[82]">{{ borrowerValues[82].value }}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<app-dynamic-borrower-values [applicationFields]="applicationFields"></app-dynamic-borrower-values>
}
