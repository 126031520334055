<div *ngIf="(documents$ | async) as documents">
    <h2>Documents <span class="!font-light !text-gray-400">({{documents?.length}})</span></h2>

    <div data-cy="documents-qv-document" class="p-2 file-summary mb-2"
         *ngFor="let document of latest3Documents"
         (click)="viewDocument(document)"
         [class.file-clickable] = "document.scanStatus === 'CLEAN'"
         [class.text-gray-400] = "document.scanStatus !== 'CLEAN'"
    >
        <mat-icon fontSet="material-icons-outlined">description</mat-icon>
        <div class="file-name">{{document.filename}}</div>
    </div>

    <div *ngIf="documents?.length" class="mt-2">
        <button
            mat-button
            [color]="'primary'"
            (click)="viewAllDocuments()"
        >
            View All
        </button>
        <!--        <button-->
        <!--            mat-button-->
        <!--            [color]="'primary'">-->
        <!--            Add Document-->
        <!--        </button>-->
    </div>
</div>

