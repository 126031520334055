<div class="notification-icon flex h-full pt-2">
    <a href="javascript:;" data-cy="notificationIcon"
        class="notification-icon__icon py-2 px-4 text-gray-300 hover:text-white text-xl cursor-pointer relative"
        (click)="toggleOpen()"
    >
        <mat-icon >notifications</mat-icon>
        <span
            class="absolute top-0 right-0 h-5 w-5 select-none bg-blue-500 rounded-full
                    text-white text-center text-xs font-semibold tracking-tight leading-5"
            *ngIf="count > 0 && !expanded"
            [@growInOut]
            data-cy="notificationCount"
        >
         {{ count }}
        </span>
    </a>
</div>
