
<div  *ngIf="newTheme; else oldFundingDesk">
	<app-funding-desk
	[fundingDeskRequests]="fundingDeskRequests$ | async"
	(createNew)="createNewRequest($event)"
	(newComment)="createNewComment($event)"
></app-funding-desk>
</div>

<ng-template #oldFundingDesk>
	<app-funding-desk-old
		[fundingDeskRequests]="fundingDeskRequests$ | async"
		(createNew)="createNewRequest($event)"
		(newComment)="createNewComment($event)"
	></app-funding-desk-old>
</ng-template>
