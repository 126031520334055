import { Component, OnInit, Input, computed } from '@angular/core';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-old-lendio-rep',
  templateUrl: './old-lendio-rep.component.html',
  styleUrls: ['./old-lendio-rep.component.scss']
})
export class OldLendioRepComponent implements OnInit {
  @Input() applicationDetails: ApplicationDetails;
  @Input() isLendioTeamLead = false;
  @Input() isFundingDeskRep = false;
  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) { }

  ngOnInit(): void {}
}
