import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LendioResponse } from '../interfaces/lendio-response';
import { environment } from '@app/environments/environment';
import { InactiveReason } from '../interfaces/inactive-reason.model';

@Injectable({ providedIn: 'root' })
export class DeadDispositionService {
  constructor(private _http: HttpClient) {}

  /**
   * Get a list of reasons from `deadDispositions` table which
   * correspond to setting a deal’s stage to "inactive".
   */
  getInactiveReasons(): Observable<InactiveReason[]> {
    return this._http
      .get<LendioResponse>(
        `${environment.apiUrl}/l/v2/internal/inactive-reasons`
      )
      .pipe(map((response) => response.data as InactiveReason[]));
  }
}
