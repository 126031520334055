import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DocumentsActions as DA, DocumentsActions } from '@app/app/store/documents/documents.actions';
import { DocumentsState } from '../../../store/documents/documents.state';
import { Document } from '../../../interfaces/document.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { DocumentCategory } from '@app/app/interfaces/document-category.model';

@Component({
  selector: 'app-document-edit-dialog',
  templateUrl: './document-edit-dialog.component.html',
  styleUrls: ['./document-edit-dialog.component.scss']
})
export class DocumentEditDialogComponent implements OnInit {
  @Output() onSave = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  @Select(DocumentsState.categories) categories$: Observable<DocumentCategory[]>;

  form: FormGroup;

  /**
   * Inject data from the client via `data`.
   */
  constructor(
    private store: Store,
    // @Inject(MAT_DIALOG_DATA) public data: { borrowerId: number, contact: Contact, formMode: string },
    @Inject(MAT_DIALOG_DATA) public data: {
      // Dialog data.
      document: Document,
    },
    public selfDialogRef: MatDialogRef<DocumentEditDialogComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data.document) {
      this.store.dispatch(new DA.GetDocumentCategories())
    }

    this.form = new FormGroup({
      title: new FormControl(this.data.document.title),
      category: new FormControl(this.data.document.category),
      monthsString: new FormControl(this.safeMonthsString()),
    });

  }

  save(form: FormGroup) {
    // Make a copy of the current document.
    let doc: Document = {...this.data.document};
    doc.title = form.value.title;
    doc.category = form.value.category;
    if (form.value.monthsString) {
      // First, check the category. Only applies to bankStatement.
      if (doc.category != 'bankStatement') {
        doc.monthsString = undefined;
      } else {
        // Split by the / and ensure it's a valid month/year.
        const mthStr = form.value.monthsString.split("/");
        if (mthStr[0] > 12 || mthStr[0] < 1) {
          form.controls.monthsString.setErrors({'invalid-month': true });
          return;
        }
        // Get current year. Docs should always be less than current year.
        const year = new Date().getFullYear();
        if (mthStr[1] > year || mthStr[1] < (year - 2)) {
          form.controls.monthsString.setErrors({'invalid-year': true });
          return;
        }
        doc.monthsString = form.value.monthsString;
      }
    }
    this.store.dispatch(new DocumentsActions.Put(doc)).subscribe({
      next: () => {
        this.selfDialogRef.close();
        // Let the parent component know when this succeeds.
        this.onSave.emit();
        this.selfDialogRef.close();
      },
    });
  }

  cancel($event: Event) {
    $event.stopPropagation();
    this.selfDialogRef.close();
  }

  safeMonthsString() {
    return this.data.document?.months ? this.data.document.months[0] : '';
  }
}
