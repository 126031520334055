import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';
import { activityIconMap, iconClassMap } from '../../deal-activities-constants'
import { dealActivityFormatter } from '../../deal-activities-column-defs';

@Component({
    selector: 'grid-activity-cell',
    standalone: true,
    imports: [
      MatIconModule
    ],
    templateUrl: './activity-cell.component.html',
    styleUrls: ['./activity-cell.component.scss']
})
export class ActivityCellComponent implements ICellRendererAngularComp {
    public icon?: string;
    public iconClass?: string = '.deal-activity-icon';
    public params: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
      this.params = params;
      this.icon = activityIconMap(this.value());
      this.iconClass = iconClassMap(this.value());
    }

    value(): string {
      return dealActivityFormatter(this.params);
    }

    refresh(params: ICellRendererParams) {
      return false;
    }
}
