import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs';

@Component({
  selector: 'app-export-application',
  templateUrl: './export-application.component.html',
  styleUrls: ['./export-application.component.scss']
})
export class ExportApplicationComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() type: 'csv' | 'xml';
  @Input() icon = 'cloud_download';
  @Input() color = 'primary';
  @Input() dealId: number;

  baseUrl = environment.apiUrl + '/l/v2/internal/application';

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit(): void {}

  export(): void {
    this.http.get(`${this.baseUrl}/${this.type}/${this.dealId}`, { responseType: 'blob' }).pipe(take(1)).subscribe((response: Blob) => {
      const fileURL = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${this.dealId}_application.${this.type}`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(fileURL);
      document.body.removeChild(link);
    });
  }

  getButtonText(): string {
    return 'Export Application (' + this.type.toUpperCase() + ')';
}

}
