import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NewOfferDialogComponent } from '../../new-offer-dialog/new-offer-dialog.component';
import { OfferToSubmit } from '../../../../interfaces/offer-to-submit.model';
import { Alert } from 'src/app/interfaces/alert.model';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeOut, fadeIn } from 'ng-animate';
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['../offer-form-styles.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, { params: { timing: 0.05 } })
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, { params: { timing: 0.3 } })
      )
    ])
  ]
})
export class OfferFormComponent implements OnInit, OnChanges {
  @Input() loading: boolean;
  @Input() localeCurrencySymbol: string;
  @Input() useNewTheme: boolean;
  @Input() isFundingInterstitial: boolean;
  @Output() newOffer = new EventEmitter<OfferToSubmit>();
  private _torpedoSubs: Subject<void> = new Subject<void>();

  amountSub: Subscription = new Subscription();
  costSub: Subscription = new Subscription();
  pointsSub: Subscription = new Subscription();
  paymentsSub: Subscription = new Subscription();
  paymentAmountSub: Subscription = new Subscription();
  originationFeeSub: Subscription = new Subscription();
  positionSub: Subscription = new Subscription();
  netFundedSub: Subscription = new Subscription();

  costType = 'apr';
  rateType = 'sell';
  originationFeeType = '%';
  additionalTerms: '';
  today: Date = new Date();
  offer = {
    amount: '',
    payback: '',
    cost: '',
    commission: '',
    points: '',
    numPayments: '',
    paymentFrequency: '',
    paymentAmount: '',
    term: '',
    originationFee: '',
    position: '',
    received: new Date(),
    expiration: '',
    netFundedAmount: '',
  };
  offerForm: FormGroup;
  alerts: Alert[] = [];
  submitting = false;

  constructor(public dialogRef: MatDialogRef<NewOfferDialogComponent>) { }

  ngOnInit(): void {
    this.offerForm = new FormGroup ({
      amount: new FormControl(this.offer.amount, [
        Validators.required
      ]),
      payback: new FormControl(this.offer.payback, [
        Validators.required
      ]),
      cost: new FormControl(this.offer.cost, [
        Validators.max(100)
      ]),
      commission: new FormControl(this.offer.commission, []),
      points: new FormControl(this.offer.points, []),
      numPayments: new FormControl(this.offer.numPayments, []),
      paymentFrequency: new FormControl(this.offer.paymentFrequency, [
        Validators.required
      ]),
      paymentAmount: new FormControl(this.offer.paymentAmount, [
        Validators.required
      ]),
      term: new FormControl(this.offer.term, [
        Validators.required
      ]),
      originationFee: new FormControl(this.offer.originationFee, []),
      position: new FormControl(this.offer.position, []),
      received: new FormControl(this.offer.received, [
        Validators.required
      ]),
      expiration: new FormControl(this.offer.expiration, []),
      netFundedAmount: new FormControl(this.offer.netFundedAmount, []),
    });

    this.amountSub = this.amountControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe((value: number) => this.handleNegativeInput(value, this.amountControl));

    this.costSub = this.costControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe(this.handleCostInput.bind(this));

    this.pointsSub = this.pointsControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe((value: number) => this.handleNegativeInput(value, this.pointsControl));

    this.paymentsSub = this.paymentsControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.paymentsControl));

    this.paymentAmountSub = this.paymentAmountControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.paymentAmountControl));

    this.originationFeeSub = this.originationFeeControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.originationFeeControl));

    this.positionSub = this.positionControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.positionControl));

    this.netFundedSub = this.netFundedControl.valueChanges
      .pipe(takeUntil(this._torpedoSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.netFundedControl));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === false
      && changes.loading.currentValue === true
    ) {
      this.submitting = true;
    }
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === true
      && changes.loading.currentValue === false
    ) {
      setTimeout(() => this.close(), 0);
    }
  }

  ngOnDestroy(): void {
    this._torpedoSubs.next();
    this._torpedoSubs.complete();
  }

  get amountControl(): AbstractControl {
    return this.offerForm.get('amount')!;
  }

  get paybackControl(): AbstractControl  {
    return this.offerForm.get('payback')!;
  }
  get costControl(): AbstractControl {
    return this.offerForm.get('cost')!;
  }
  get pointsControl(): AbstractControl {
    return this.offerForm.get('points')!;
  }
  get paymentsControl(): AbstractControl {
    return this.offerForm.get('numPayments')!;
  }
  get paymentFrequencyControl(): AbstractControl {
    return this.offerForm.get('paymentFrequency')!;
  }
  get paymentAmountControl(): AbstractControl {
    return this.offerForm.get('paymentAmount')!;
  }
  get originationFeeControl(): AbstractControl {
    return this.offerForm.get('originationFee')!;
  }
  get positionControl(): AbstractControl {
    return this.offerForm.get('position')!;
  }
  get dateReceivedControl(): AbstractControl {
    return this.offerForm.get('received')!;
  }
  get netFundedControl(): AbstractControl {
    return this.offerForm.get('netFundedAmount')!;
  }
  get termControl(): AbstractControl {
    return this.offerForm.get('term')!;
  }

  handleNegativeInput(value: number, control: AbstractControl): void {
    if (value < 0) {
      control.setValue(0);
    }
  }

  handleCostInput(value: number): void {
    if(value > 100) {
      this.costControl.setValue(100);
    }
    if(value < 0) {
      this.costControl.setValue(0)
    }
  }

  close(wasCanceled = false) {
    this.dialogRef.close(wasCanceled);
  }

  submit() {
    this.newOffer.emit({
      formData: this.offerForm.value,
      additionalTerms: this.additionalTerms,
      originationFeeType: this.originationFeeType,
      costType: this.costType,
      rateType: this.rateType,
    });
  }

  checkError(controlName: string, errorName: string) {
    return this.offerForm.controls[controlName].hasError(errorName);
  }

  showAlert(alert: {level: string, message: string}) {
    this.alerts.push(alert as Alert);
  }
}
