import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddSnackbarError,
  AddSnackbarNotification,
  AddSnackbarSuccess,
  ClearAllSnackbarNotifications,
  ClearSnackbarNotification,
  UpdateSnackbarNotification,
} from '@app/app/store/snackbar/snackbar.actions';
import { append, iif, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { SnackbarNotification } from '@app/app/interfaces/snackbar-notification.model';

export interface SnackbarStateModel {
  notifications: Partial<SnackbarNotification>[];
}

@State<Partial<SnackbarStateModel>>({
  name: 'Snackbar',
  defaults: {
    notifications: []
  }
})
@Injectable()
export class SnackbarState {

  @Selector()
  static notifications(state: SnackbarStateModel) {
    return state.notifications;
  }

  @Selector()
  static temporaryNotifications(state: SnackbarStateModel) {
    return state.notifications.filter( notification => !notification.permanent);
  }

  @Selector()
  static permanentNotifications(state: SnackbarStateModel) {
    return state.notifications.filter( notification => notification.permanent);
  }

  constructor() {}

  @Action(AddSnackbarError)
  AddSnackbarError(
    ctx: StateContext<SnackbarStateModel>,
    { error }: AddSnackbarError,
  ) {
    const defaults: Partial<SnackbarNotification> = {
      identifier: `${Date.now()}`,
      dismissible: true,
      badge: {
        type: 'icon',
        value: 'warning',
        class: 'bg-lendio-red-400',
      },
    };
    const notification = {
      ...defaults,
      ...error,
    }
    ctx.setState(patch({
      notifications: append([notification]),
    }));
  }

  @Action(AddSnackbarSuccess)
  AddSnackbarSuccess(
    ctx: StateContext<SnackbarStateModel>,
    { notification }: AddSnackbarSuccess,
  ) {
    const defaults: Partial<SnackbarNotification> = {
      identifier: `${Date.now()}`,
      dismissible: true,
      badge: {
        type: 'icon',
        value: 'check',
        class: 'bg-lendio-green-400',
      },
    };
    const successNotification = {
      ...defaults,
      ...notification,
    }
    ctx.setState(patch({
      notifications: append([successNotification]),
    }));
  }

  @Action(AddSnackbarNotification)
  AddSnackbarNotification(
    ctx: StateContext<SnackbarStateModel>,
    { notification }: AddSnackbarNotification,
  ) {
    const identifier = notification.identifier || `${notification.title?.trim()}-${Date.now()}`,
      byIdentifier = (existingNotification) => existingNotification.identifier === notification.identifier;
    const exists = !!notification.identifier && !!ctx.getState().notifications.find( byIdentifier );

    ctx.setState(patch({
      notifications: iif(exists,
          updateItem( byIdentifier, notification),
          append([
            {
              ...notification,
              identifier,
            }
          ]),
        ),
    }));
  }

  @Action(ClearAllSnackbarNotifications)
  ClearAllSnackbarNotifications(
    ctx: StateContext<SnackbarStateModel>,
    {  }: ClearAllSnackbarNotifications,
  ) {
    ctx.setState(patch({
      notifications: ctx.getState().notifications.filter( notification => !notification.dismissible ),
    }));
  }

  @Action(ClearSnackbarNotification)
  ClearSnackbarNotification(
    ctx: StateContext<SnackbarStateModel>,
    { identifier }: ClearSnackbarNotification,
  ) {
    ctx.setState(patch({
      notifications: removeItem( notification => notification.identifier === identifier),
    }));
  }

  @Action(UpdateSnackbarNotification)
  UpdateSnackbarNotification(
    ctx: StateContext<SnackbarStateModel>,
    { identifier, updates }: UpdateSnackbarNotification,
  ) {
    ctx.setState(patch({
      notifications: updateItem(
        notification => notification.identifier === identifier,
        patch(updates)),
    }));
  }
}
