<h2 mat-dialog-title class="!p-4">Terms and Conditions</h2>
<mat-dialog-content class="text-xs">
	<div class="mb-6">
		<h1 class="font-bold">LENDER TERMS AND CONDITIONS</h1>
		<p>
			In addition to any agreement entered into by and between you and Lendio, Inc. or any of its subsidiaries (collectively, “Lendio”), these Lender Terms and Conditions (the “Lender Terms”) apply to your access and use of any information, whether presented via a web-based portal (the “Lender Portal”) or any other means from Lendio, you receive from Lendio (the “Information”). The term “You” includes any entity accessing the Information and/or any person accessing the Information on his or her behalf or on behalf of an entity.
		</p>
		<p>
			The Lender Portal is presented to allow to allow secure sharing of the Information and you will be presented with potential funding/lending opportunities with Lendio customers (each a “Lendio Customer”) and the opportunity to engage with the Lendio Customers (collectively, the “Services”).
		</p>
		<p>
			You acknowledge that the Information is extremely sensitive and personal information and will treat it with such care as is customary with such sensitive information. The Information pertains to Lendio Customers seeking business financing options. You agree to store, handle, and treat all Information in a secure manner in accordance with all applicable law and customary business practices. You do not and may not rely on Lendio’s knowledge and advice in treating the Information in accordance with applicable law and customary business practices. You agree to employ the use of proper resources to ensure your ability to adhere to these provisions.
		</p>
		<p>
			You agree to only use the Information for business purposes and will not assist any Lendio Customer apply for, access, or receive financing for any personal, household, or family use.
		</p>
		<p>
			You acknowledge and agree that you will not share, transmit, sell, or provide in any other manner, the Information to any third parties without the prior, written consent and authorization of the individual and/or business to whom the Information pertains and without the prior notice to Lendio of such authorization. You will not rely on any consent provided by Lendio.
		</p>
		<p>
			You agree not to contact any individual or business in any manner that is inconsistent with applicable law, including, but not limited to, the Telephone Consumer Protection Act. You will not contact Business Customers via automated telephone dialing systems and/or through the use of automated or prerecorded voice messages.
		</p>
	</div>
	<div>
		<h1 class="font-bold">DISCLAIMER OF WARRANTIES. YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</h1>

		<p>
			YOUR USE OF THE INFORMATION AND/OR SERVICES IS AT YOUR SOLE RISK. THE INFORMATION AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. LENDIO EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND AS TO THE INFORMATION, SERVICE AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICE, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
		</p>
		<p>
			LENDIO MAKES NO WARRANTY THAT (i) THE INFORMATION AND/OR SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE INFORMATION AND/OR SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE INFORMATION AND/OR SERVICE WILL MEET YOUR EXPECTATIONS, OR (V) ANY ERRORS IN THE TECHNOLOGY WILL BE CORRECTED.
		</p>
		<p>
			ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM LENDIO THROUGH OR FROM THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
		</p>
		<p>
			LIMITATION OF LIABILITY. YOU AGREE THAT LENDIO NOR ANY OF ITS AFFILIATES, ACCOUNT PROVIDERS OR ANY OF THEIR AFFILIATES WILL BE LIABLE FOR ANY HARMS, WHICH LAWYERS AND COURTS OFTEN CALL DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, EVEN IF LENDIO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE INFORMATION AND/OR SERVICE; (ii) THE COST OF GETTING SUBSTITUTE GOODS AND SERVICES, (iii) ANY PRODUCTS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE INFORMATION AND/OR SERVICE; (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (v) STATEMENTS OR CONDUCT OF ANYONE ON THE SERVICE; (vi) THE USE, INABILITY TO USE, UNAUTHORIZED USE, PERFORMANCE OR NON-PERFORMANCE OF ANY THIRD PARTY ACCOUNT PROVIDER SITE, EVEN IF THE PROVIDER HAS BEEN ADVISED PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES; OR (vii) ANY OTHER MATTER RELATING TO THE INFORMATION AND/OR SERVICE.
		</p>
		<p>
			Indemnification. You agree to protect and fully compensate Lendio and its affiliates from any and all third party claims, liability, damages, expenses and costs (including, but not limited to, reasonable attorneys’ fees) caused by or arising from your use of the service, your violation of these terms or your infringement, or infringement by any other user of your account, of any intellectual property or other right of anyone.
		</p>
	</div>
</mat-dialog-content>