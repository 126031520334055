import { Integration } from '@app/app/interfaces/integration.model';
import { Injectable } from '@angular/core';
import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import { IntegrationsService } from '@app/app/services/integrations.service';
import {
  CreateIntegrationCredentials,
  GetIntegrationCredentials,
  GetIntegrations,
  UpdateIntegrationCredentials
} from '@app/app/store/integrations/integrations.actions';
import {of, tap, throwError} from 'rxjs';
import {addMinutes, isBefore, subMinutes} from 'date-fns';
import {catchError} from "rxjs/operators";
import {EventBusService} from "@app/app/services/event-bus.service";
import {AddSnackbarError, AddSnackbarNotification} from "@app/app/store/snackbar/snackbar.actions";
import {patch, updateItem} from "@ngxs/store/operators";

export interface IntegrationsStateModel {
  integrations: Integration[];
  lastFetch: number;
  loading: boolean;
  credentialsLoading: boolean;
  credentialsFetchExpirationsMap: {[key: number]: number};
}

@State<Partial<IntegrationsStateModel>> ({
  name: 'Integrations',
  defaults: {
    integrations: [],
    credentialsFetchExpirationsMap: {},
  }
})
@Injectable()
export class IntegrationsState {

  @Selector()
  static integrations(state: IntegrationsStateModel) {
    return state.integrations;
  }

  @Selector()
  static loading(state: IntegrationsStateModel) {
    return state.loading;
  }

  @Selector()
  static credentialsLoading(state: IntegrationsStateModel) {
    return state.credentialsLoading;
  }

  @Selector()
  static byId(integrationId: number) {
    return createSelector([IntegrationsState], state => {
      return state.Integrations?.integrations.find( integration => integration.customerIntegrationId === integrationId );
    });
  }

  constructor(
    private integrationsService: IntegrationsService,
    private eventBusService: EventBusService,
    private store: Store,
  ) {}

  @Action( GetIntegrations )
  GetIntegrations(
    ctx: StateContext<IntegrationsStateModel>,
    { }: GetIntegrations
  ) {
    const lastFetch = ctx.getState().lastFetch;
    const stale = !lastFetch || isBefore(lastFetch, subMinutes(new Date(Date.now()), 5));
    if(stale) {
      ctx.patchState({loading: true});
      return this.integrationsService.getIntegrations()
        .pipe(
          catchError( err => {
            ctx.patchState({loading: false});
            throw err;
          }),
          tap( response => {
            ctx.patchState({
              integrations: response.data,
              lastFetch: Date.now(),
              loading: false,
            });
          }),
        );
    }
  }

  @Action( CreateIntegrationCredentials )
  CreateIntegrationCredentials(
    ctx: StateContext<IntegrationsStateModel>,
    { customerIntegrationId, credentials }: CreateIntegrationCredentials
  ) {
    ctx.patchState({credentialsLoading: true});
    return this.integrationsService
      .createIntegrationCredentials(customerIntegrationId, credentials)
      .pipe(
        catchError(err => {
          ctx.patchState({credentialsLoading: false});
          this.store.dispatch(new AddSnackbarError({
            identifier: 'integrationCredentialsError',
            subTitle: `Error saving Integration Credentials.`,
          }));
          throw err;
        }),
        tap( response => {
          this.patchIntegration(ctx, customerIntegrationId, {
            maskedCredentials: response.data,
            status: 'active',
          });
          const status = (response.statusCode === 200 || response.statusCode === 201) ? 'success' : 'error';
          this.store.dispatch( new AddSnackbarNotification({
            badge: {
              type: 'icon',
              value: status === 'success' ? 'check' : 'error',
              class: status === 'success' ? 'bg-lendio-lime-green-400' : 'bg-lendio-red-400',
            },
            subTitle: status === 'success' ? 'Credentials saved successfully' : 'Error saving Integration Credentials',
          }));
          this.eventBusService.publish( new this.eventBusService.types.CloseCredentialsDialogEvent() );
        }),
      );
  }

  @Action( UpdateIntegrationCredentials )
  UpdateIntegrationCredentials(
    ctx: StateContext<IntegrationsStateModel>,
    { customerIntegrationId, credentials }: UpdateIntegrationCredentials
  ) {
    ctx.patchState({credentialsLoading: true});
    return this.integrationsService
      .updateIntegrationCredentials(customerIntegrationId, credentials)
      .pipe(
        catchError(err => {
          ctx.patchState({credentialsLoading: false});
          this.store.dispatch(new AddSnackbarError({
            identifier: 'integrationCredentialsError',
            subTitle: `Error saving Integration Credentials.`,
          }));
          return of(err);
        }),
        tap( response => {
          this.patchIntegration(ctx, customerIntegrationId, response.data);
          this.store.dispatch( new AddSnackbarNotification({
            badge: {
              type: 'icon',
              value: 'check',
              class: 'bg-lendio-lime-green-400',
            },
            subTitle: 'Credentials saved successfully',
          }));
          this.eventBusService.publish( new this.eventBusService.types.CloseCredentialsDialogEvent() );
        }),
      );
  }

  @Action( GetIntegrationCredentials )
  GetIntegrationCredentials(
    ctx: StateContext<IntegrationsStateModel>,
    { customerIntegrationId }: GetIntegrationCredentials
  ) {
    const current = ctx.getState();
    if(this.shouldPreventCredentialsFetch(current, customerIntegrationId)) {
      return;
    }
    const expiration: {[x: number]: number} = {[customerIntegrationId]: addMinutes(Date.now(), 5).getTime()};
    ctx.setState(patch({
      credentialsLoading: true,
      credentialsFetchExpirationsMap: patch({...expiration})
    }));
    return this.integrationsService
      .getIntegrationCredentials(customerIntegrationId)
      .pipe(
        tap( response => {
          this.patchIntegration(ctx, customerIntegrationId, {
            maskedCredentials: response.data,
            status: 'active',
          });
        }),
        catchError((err) => {
          this.store.dispatch(new AddSnackbarError({
            identifier: 'integrationCredentialsError',
            subTitle: `Error retrieving Integration Credentials.`,
          }));
          this.patchIntegration(ctx, customerIntegrationId, {status: 'error'});
          throw err;
        }),
      );
  }

  patchIntegration(ctx: StateContext<IntegrationsStateModel>, customerIntegrationId: number, changes: Partial<Integration>) {
    const byCxIntegrationId = (integration: Integration) => integration.customerIntegrationId === customerIntegrationId;
    ctx.setState(patch({
      integrations: updateItem(
        byCxIntegrationId,
        patch(changes),
      ),
      credentialsLoading: false,
    }));
  }

  shouldPreventCredentialsFetch(state: IntegrationsStateModel, customerIntegrationId: number): boolean {
    if(state.credentialsLoading) {
      return true;
    }
    const byCxIntegrationId = (integration: Integration) => integration.customerIntegrationId === customerIntegrationId;
    const credentialsFetchExpiration = state.credentialsFetchExpirationsMap[customerIntegrationId],
      credentialsExpired = !credentialsFetchExpiration || isBefore(new Date(credentialsFetchExpiration), Date.now())
    const hasCredentials = !!state.integrations.find(byCxIntegrationId)?.maskedCredentials
    return hasCredentials || !credentialsExpired;
  }
}
