import { Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { CreateAndAcceptOffer, CreateOffer } from 'src/app/store/offers/offers.actions';
import { OfferToSubmit } from '../../../interfaces/offer-to-submit.model';
import { Observable } from 'rxjs';
import { OffersState } from 'src/app/store/offers/offers.state';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';
import { Offer } from '@app/app/interfaces/offer.model';

@Component({
  selector: 'app-new-offer-smart',
  templateUrl: './new-offer-smart.component.html',
  styleUrls: ['./new-offer-smart.component.scss']
})
export class NewOfferSmartComponent {
  loading$: Observable<boolean> = this.store.select(OffersState.loading);
  newThemeEnabled = computed(() => this._themeService.newThemeEnabled());

  @Input() link: boolean;
  @Input() dealId: number;
  @Input() loanProductCategory: string;
  @Input() localeCurrencySymbol: string | null;
  @Input() isFundingInterstitial: boolean;
  @Output() newOfferCreated = new EventEmitter<Partial<Offer>>();

  constructor(
    private store: Store,
    private localeSettingService: LocaleSettingService,
    private _themeService: LendioAngularMaterialThemeService
  ) {
    this.localeCurrencySymbol = this.localeSettingService.getCurrencySymbol();
  }

  createNewOffer(offer: OfferToSubmit) {
    let packagedOffer: any;
    if (offer.loanProductCategory && offer.loanProductCategory === 'factoring') {
      packagedOffer = this.packageFactoringOffer(offer);
    } else {
      packagedOffer = this.packageRegularOffer(offer);
    }

    if (this.isFundingInterstitial) {
      this.store.dispatch(new CreateAndAcceptOffer(this.dealId, packagedOffer));
      this.newOfferCreated.emit({
        amount: offer.formData?.amount ?? 0,
        type: offer.costType ?? '',
        apr: offer.formData?.cost ?? 0,
        paymentFrequency: offer.formData?.paymentFrequency ?? '',
      });
    } else {
      this.store.dispatch(new CreateOffer(this.dealId, packagedOffer));
    }
  }

  packageFactoringOffer(offer: OfferToSubmit) {
    const packagedOffer = offer.formData;
    packagedOffer.dealId = this.dealId;
    packagedOffer.offerLink = null;
    if (offer.originationFeeType === this.localeCurrencySymbol) {
      const calculatedOriginationFee = (offer.formData.originationFee / offer.formData.amount) * 100;
      packagedOffer.originationFee = calculatedOriginationFee;
    }
    if (offer.additionalTerms && offer.additionalTerms !== '') {
      packagedOffer.additionalTerms = offer.additionalTerms;
    }
    return packagedOffer;
  }

  packageRegularOffer(offer: OfferToSubmit) {
    const packagedOffer = offer.formData;
    packagedOffer.dealId = this.dealId;
    packagedOffer.offerLink = null;
    if (offer.costType) {
      packagedOffer[offer.costType] = packagedOffer.cost;
    }
    if (offer.rateType === 'buy') {
      packagedOffer.buyRate = packagedOffer.interestRate || packagedOffer.apr || packagedOffer.factor;
      if (packagedOffer.interestRate) {
        packagedOffer.interestRate = packagedOffer.interestRate + packagedOffer.points;
      } else if (packagedOffer.apr) {
        packagedOffer.apr = packagedOffer.apr + packagedOffer.points;
      } else if (packagedOffer.factor) {
        packagedOffer.factor = packagedOffer.factor + (packagedOffer.points / 100);
      }
    }
    if (offer.originationFeeType === this.localeCurrencySymbol) {
      packagedOffer.originationFee = (offer.formData.originationFee / offer.formData.amount) * 100;
    }
    if (offer.additionalTerms && offer.additionalTerms !== '') {
      packagedOffer.additionalTerms = offer.additionalTerms;
    }
    if (packagedOffer.expiration === '') {
      packagedOffer.expiration = null;
    }
    return packagedOffer;
  }

  createOfferLink(offer: { formData: any }) {
    this.store.dispatch(new CreateOffer(this.dealId, offer.formData));
  }
}
