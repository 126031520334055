import { Business } from "@app/app/interfaces/business.model";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BusinessActions {

  export class ClearBusinessState {
    static readonly type = '[Business] ClearBusinessState';
    constructor() {}
  }

  export class GetBusiness {
    static readonly type = '[Business] GetBusiness';
    constructor(public businessId: number) {}
  }

  export class UpdateBusiness {
    static readonly type = '[Business] UpdateBusiness';
    constructor(public business: Business) {}
  }

}
