import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatButtonModule} from "@angular/material/button";
import {IntegrationCredentials} from "@app/app/interfaces/integrations/integration-credentials.model";
import {
  CreateIntegrationCredentials,
  UpdateIntegrationCredentials
} from "@app/app/store/integrations/integrations.actions";
import {Store} from "@ngxs/store";
import {Integration} from "@app/app/interfaces/integration.model";
import {EventBusService} from "@app/app/services/event-bus.service";
import {take} from "rxjs/operators";
import {MatFormFieldModule} from "@angular/material/form-field";
import {Observable} from "rxjs";
import {IntegrationsState} from "@app/app/store/integrations/integrations.state";

export interface CredentialsDialogData {
  credentials: IntegrationCredentials;
  customerIntegration: Integration;
  type: string;
  title: string;
}

@Component({
  selector: 'app-credentials-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  templateUrl: './credentials-dialog.component.html',
  styleUrl: './credentials-dialog.component.scss'
})
export class CredentialsDialogComponent {

  formGroup: FormGroup;
  credentialsLoading$: Observable<boolean> = this.store.select( IntegrationsState.credentialsLoading );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CredentialsDialogData,
    private dialogRef: MatDialogRef<CredentialsDialogComponent>,
    private eventBusService: EventBusService,
    private store: Store,
  ) {
    const credentials = this.data.credentials || {};
    this.formGroup = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      client_id: new FormControl('', [Validators.required]),
      client_secret: new FormControl('', [Validators.required]),
    });
  }

  handleSave() {
    const StoreAction = this.data.type === 'create' ? CreateIntegrationCredentials : UpdateIntegrationCredentials;
    const credentials = this.formGroup.value;
    const { customerIntegrationId } = this.data.customerIntegration;
    this.store.dispatch( new StoreAction(customerIntegrationId, credentials) );
    this.eventBusService.subscribe( this.eventBusService.types.CloseCredentialsDialogEvent ).pipe(
      take(1),
    ).subscribe( () => {
      this.dialogRef.close();
    });
  }

}
