import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class OpportunitiesService {
  private _opportunitiesUrl = environment.apiUrl + '/lender-portal/opportunities';
  private _darkLaunchOpportunitiesUrl = environment.apiUrl + '/lender-portal/partner-affiliate-ownership-report';

  constructor(private _httpClient: HttpClient) { }

  public getOpportunities() {
    return this._httpClient.get(this._opportunitiesUrl);
  }

  public darkLaunchGetOpportunities() {
    return this._httpClient.get(this._darkLaunchOpportunitiesUrl);
  }
}
