import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { ThoughtspotTokenResponse } from '../interfaces/thoughtspot-token-response.model';
import * as TSEmbedSDK from '@thoughtspot/visual-embed-sdk';
import { Observable, firstValueFrom } from 'rxjs';
import { Action } from '@thoughtspot/visual-embed-sdk';

@Injectable({ providedIn: 'root' })
export class ThoughtSpotService {
  private readonly _apiBaseUrl = `${environment.apiUrl}/lender-portal`;
  private readonly _thoughtSpotHost = 'https://thoughtspot.lendio.com';

  constructor(private _http: HttpClient, private _ngZone: NgZone) {}

  // Expose ThoughtSpot SDK methods/properties
  public logout = TSEmbedSDK.logout;
  public AuthStatus = TSEmbedSDK.AuthStatus;
  public EmbedEvent = TSEmbedSDK.EmbedEvent;

  /**
   * Make a post to API to retrieve a ThoughtSpot token.
   * @returns Observable<ThoughtspotTokenResponse>
   */
  public getToken(): Observable<ThoughtspotTokenResponse> {
    return this._http.post<ThoughtspotTokenResponse>(
      `${this._apiBaseUrl}/ts-token`,
      null
    );
  }

  /**
   * Init the ThoughtSpot Visual Embed SDK with default settings.
   * @param userEmail Email address of the Partner Portal user.
   * @returns AuthEventEmitter
   */
  public initDefault(userEmail: string): TSEmbedSDK.AuthEventEmitter {
    return this._ngZone.runOutsideAngular(() => {
      return TSEmbedSDK.init({
        thoughtSpotHost: this._thoughtSpotHost,
        authType: TSEmbedSDK.AuthType.TrustedAuthToken,
        username: userEmail,
        autoLogin: true,
        disableLoginRedirect: true,
        callPrefetch: true,
        customizations: {
          style: {
            customCSS: {
              variables: {
                '--ts-var-button--icon-border-radius': '8px',
                '--ts-var-button-border-radius': '8px',
                '--ts-var-chip-border-radius': '8px',
                '--ts-var-viz-box-shadow': 'none',
                '--ts-var-viz-border-radius': '16px',
                '--ts-var-chip-background': '#FFF',
                '--ts-var-chip--hover-background': '#F7F9FC',
                '--ts-var-chip--hover-color': '#15171A',
                '--ts-var-chip--active-color': '#15171A',
                '--ts-var-chip--active-background': '#E9EDF2',
                '--ts-var-chip-color': '#15171A',
              },
              rules_UNSTABLE: {
                '.pinboard-content-module__tile': {
                  'box-shadow': 'none',
                  border: '1px #D3DBE5 solid',
                },
                '.pinboard-header-module__embedPinboardHeader': {
                  margin: '0',
                  'border-radius': '0px',
                  'border-bottom': '1px #D3DBE5 solid',
                },
                '.ag-theme-alpine .ag-header-viewport': {
                  'border-bottom': '1px #D3DBE5 solid',
                },
                '.parameter-chip-panel-module__parameterChip': {
                  'background-color': '#FFF !important',
                  border: '1px #15171A solid',
                  padding: '12px 6px',
                },
                '.parameter-chip-panel-module__parameterChip:focus span,.parameter-chip-panel-module__parameterChip:hover span, .parameter-chip-panel-module__parameterChip span':
                  {
                    color: '#15171A',
                  },
                '.chip-base-module__filter:hover': {
                  'background-color': '#F7F9FC !important',
                  border: '1px #15171A solid !important',
                },
                '.chip-base-module__filter:focus': {
                  'background-color': '#E9EDF2 !important',
                  border: '1px #15171A solid !important',
                },
                '.chip-base-module__filter:active': {
                  'background-color': '#E9EDF2 !important',
                  border: '1px #15171A solid !important',
                },
                '.pinboard-header-module__stickyPinboardHeaderVisible': {
                  visibility: 'hidden',
                },
                '.pinboard-header-module__pinboardHeader': {
                  'flex-flow': 'row-reverse !important',
                  'justify-content': 'space-between',
                },
                '.pinboard-header-module__pinboardHeader .flex-layout-module__vertical,.pinboard-header-module__pinboardHeader .filter-panel-module__pinboardFilterPanelBody':
                  {
                    'align-self': 'end',
                    'padding-top': '0',
                  },
                '.chip-base-module__chip.chip-base-module__filter.filter-panel-module__filterPanelItem':
                  {
                    border: '1px #15171A solid',
                  },
              },
            },
          },
        },
        getAuthToken: () =>
          firstValueFrom(this.getToken()).then((response) => response.data),
      });
    });
  }

  /**
   * Embed a liveboard with default settings.
   * gives a chance to register event callbacks before calling render() manually.
   * @returns TSEmbedSDK.LiveboardEmbed
   * @param params
   * - liveboardId ID of the liveboard to embed
   * - embedDiv Div in which to embed liveboard
   * - shouldCallRender Whether to call render() by default. Not calling it
   */
  public embedLiveboardDefault(params: {
    liveboardId: string;
    embedDiv: HTMLDivElement;
    shouldCallRender: boolean;
    visibleActions?: Action[];
  }): TSEmbedSDK.LiveboardEmbed {
    return this._ngZone.runOutsideAngular(() => {
      const thoughtSpotDiv = params.embedDiv;
      const liveboardEmbed = new TSEmbedSDK.LiveboardEmbed(thoughtSpotDiv, {
        liveboardId: params.liveboardId,
        frameParams: {
          height: '100vh',
        },
        visibleActions: params.visibleActions,
      });

      if (params.shouldCallRender) {
        liveboardEmbed.render();
      }

      return liveboardEmbed;
    });
  }
}
