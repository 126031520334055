import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { RouterModule, Routes } from '@angular/router';
import { ReportsListTableComponent } from './reports-list-table/reports-list-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ThoughtspotReportsService } from '@app/app/services/thoughtspot-reports.service';
import { MatCardModule } from '@angular/material/card';
import { ReportSingleViewComponent } from './report-single-view/report-single-view.component';
import { LoadersModule } from '@app/app/components/loaders/loaders.module';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import {
    ThoughtspotServiceUnavailableComponent
} from "@app/app/components/thoughtspot-liveboard/thoughtspot-service-unavailable/thoughtspot-service-unavailable.component";

const routes: Routes = [
  {
    path: ':id',
    component: ReportSingleViewComponent,
  },
  {
    path: '',
    component: ReportsListComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [
    ReportsListComponent,
    ReportsListTableComponent,
    ReportSingleViewComponent,
  ],
    imports: [
        CommonModule,
        EmptyStateComponent,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatCardModule,
        RouterModule.forChild(routes),
        LoadersModule,
        ThoughtspotServiceUnavailableComponent,
    ],
  providers: [ThoughtspotReportsService],
})
export class ReportsModule {}
