<div [class.old-theme-labels]="oldThemeEnabled()">
<mat-chip-listbox class="application-labels">
    <ng-container *ngFor="let label of labels; let i = index; trackBy: trackByFn">
        <mat-chip-option
                [@inOutAnimation]
                [@.disabled]="!isMenuOpen"
                *ngIf="!deletedLabels.includes(label.id)"
                class="label"
                [ngStyle]="getStyle(label)"
                [selectable]="false"
                [removable]="true"
                matTooltipShowDelay="350"
                [matTooltip]="getText(label)"
                disableRipple="true"
                (removed)="detach(label.id)">
                    <span class="truncate">{{ getText(label) }}</span>
                    <mat-icon class="!ml-1 !-mr-1" matChipRemove>cancel</mat-icon>
        </mat-chip-option>
    </ng-container>
    <mat-chip-option color="primary" style="background-color:#e0e0e0;" data-cy="label-settings" class="!cursor-pointer pl-1 pr-0" #labelMenuTrigger="matMenuTrigger" (keypress)="handleCogKeypress($event)" [matMenuTriggerFor]="labelMenu" (menuOpened)="menuOpenChanged(true)" (menuClosed)="menuOpenChanged(false)">
        <mat-icon class="add-label-icon">settings</mat-icon>
    </mat-chip-option>
</mat-chip-listbox>
<mat-menu #labelMenu="matMenu" (click)="$event.stopPropagation()">
    <div class="add-label-menu" (click)="$event.stopPropagation()">
        <ng-template matMenuContent>
            <h3>Labels</h3>
            <div class="label-container" *ngIf="lenderLabels && lenderLabels.length > 0" (click)="$event.stopPropagation()">
                <mat-chip-listbox class="mat-chip-list-stacked" aria-orientation="vertical">
                    <div *ngFor="let lenderLabel of lenderLabels" style="height: 36px; margin-bottom: 4px;">
                        <mat-chip-option data-cy="label" [attr.data-cy-extra]="isLenderLabelAdded(lenderLabel.id) ? 'label-checked' : 'label-unchecked'" color="#fdee77" [ngStyle]="getStyle(lenderLabel)" (click)="$event.stopPropagation(); attach(lenderLabel)">
                            <span #tooltip="matTooltip" matTooltipShowDelay="350" [matTooltip]="lenderLabel.name">
                                {{ lenderLabel.name }}
                            </span>
                            <mat-icon style="height: 18px;" *ngIf="isLenderLabelAdded(lenderLabel.id)">check</mat-icon>
                        </mat-chip-option>
                        <button data-cy="label-edit" mat-flat-button class="edit-label" (click)="$event.stopPropagation(); openEditDialog(lenderLabel)">Edit</button>
                    </div>
                </mat-chip-listbox>
            </div>
            <div class="actions-container">
                <button mat-flat-button data-cy="label-create" color="primary" (click)="$event.stopPropagation(); openEditDialog()">Create Label</button>
            </div>
        </ng-template>
    </div>
</mat-menu>
</div>
