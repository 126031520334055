<div class="single-request mt-2"
     [class.old-theme-single-request]="oldThemeEnabled()">

  <div class="request-text">

    <app-user-avatar-component
      [full]="requestChain.creator_user.first + ' ' + requestChain.creator_user.last"
      [avatarUrl]="requestChain.creator_user.avatarUrl || ''">
    </app-user-avatar-component>

    <div class="name">{{ requestChain.creator_user.first }} {{ requestChain.creator_user.last }}</div>

    <span class="pull-right time-ago">{{ timeAgo(requestChain.created) }}</span>

    <div class="description" attr.data-cy="fd-request-{{requestIndex}}">
      {{ requestChain.description }}
    </div>

    <div *ngIf="oldComments && oldComments.length >= 2">
      <button type="button" class="more-comments-button" (click)="moreComments = !moreComments">
        <span *ngIf="!moreComments">{{ oldComments.length }} More Comments </span>
        <span *ngIf="moreComments">View Less </span> <span class="fa" [ngClass]="{'fa-caret-down': !moreComments, 'fa-caret-up': moreComments}"></span>
      </button>
    </div>

    <div *ngIf="moreComments || (oldComments && oldComments.length < 2)" class="more-comments" [@slideUpDown]>
      <div *ngFor="let comment of oldComments; let i = index" class="request-comment">
        <div class="comment-text">
          <div class="name">
            {{ comment.creator_user.first }} {{ comment.creator_user.last }}
          </div>
          <span class="pull-right time-ago">{{ timeAgo(comment.created) }}</span>
          <div class="comment-description" attr.data-cy="fd-request-comment-{{i}}">
            {{ comment.description }}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="mostRecentComment" class="request-comment">
      <div class="comment-text">
        <div class="name">
          {{ mostRecentComment.creator_user.first }} {{ mostRecentComment.creator_user.last }}
        </div>
        <span class="pull-right time-ago">{{ timeAgo(mostRecentComment.created) }}</span>
        <div class="comment-description" data-cy="most-recent-fd-request-comment">
          {{ mostRecentComment.description }}
        </div>
      </div>
    </div>

    <div *ngIf="addNewComment" class="row mb-4" [@slideUpDown]>
        <mat-form-field appearance="fill" class="add-new-comment">
          <textarea
            matInput
            data-cy="fd-request-comment-input"
            class="form-control"
            placeholder="What can I help you with?"
            [(ngModel)]="newComment"
            [ngModelOptions]="{standalone: true}"
          ></textarea>
        </mat-form-field>
        <div class="pull-right">
          <button mat-button color="primary" (click)="cancelComment()" class="cancel-comment">Cancel</button>
          <button
            mat-flat-button
            color="primary"
            data-cy="submitComment"
            [disabled]="newComment === ''"
            type="button"
            (click)="createNewComment(newComment)"
            class="!ml-3"
            data-cy="fd-request-comment-submit-btn"
          >Request</button>
        </div>
    </div>

      <div *ngIf="addRequestTypeLabel" class="request-type-label">
        <span class="bullet-point">&bull;</span>
        <span class="label"> {{ requestType }}</span>
      </div>
      <div class="status" *ngIf="!addNewComment" [@fadeOut] [@fadeIn]>
        <span class="status-text">
          <span *ngIf="!requestChain.completed">In Progress</span>
          <span *ngIf="requestChain.completed"><i class="fa fa-check"></i> Request complete</span>
        </span>
        <a
          (click)="addNewComment = true"
          mat-flat-button
          color="primary"
          class="!ml-3"
          *ngIf="!addNewComment && !requestChain.completed"
          data-cy="fd-request-comment-btn"
        >Comment</a>
      </div>
  </div>
</div>
