import { Component, computed, Input } from '@angular/core';
import { Approval } from '../../../interfaces/approval/approval.model';
// @ts-ignore: Ignoring because we don't provide types in this library below
import * as ApprovalCalculatorUtility from '@lendiodevs/lendio-js-utilities/lib/approval/ApprovalCalculator.utility.js';
import { configureCalculator } from '@lendiodevs/lendio-js-utilities';
import * as locCalculatorGeneralFormulas from '@lendiodevs/lendio-js-utilities/dist/formulas/general-formulas';
import { LocTermApprovalOption } from '@lendiodevs/lendio-js-utilities/dist/formulas/general-formulas';
import { CalculateScheduleParams } from '@lendiodevs/lendio-js-utilities/dist/payment-schedule';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

// type definition for data format required by restricted-radio-component
type OptionData = {
  label: string;
  value: string;
};

/**
 * Map of frequency name to value
 */
const frequencyMap = new Map([
  ['daily', 1],
  ['weekly', 7],
  ['semimonthly', 14],
  ['monthly', 30],
]);

@Component({
  selector: 'app-loc-term-approval-old',
  templateUrl: './old-loc-term-approval.component.html',
  styleUrls: ['./old-loc-term-approval.component.scss'],
})
export class OldLocTermApprovalComponent {
  // Error state
  errored: boolean = false;

  //Working values
  workingValues: any = {};

  //Calculation Type 'loc' or 'term'
  calculationType: string;

  ///////////////////
  // APPROVAL OPTIONS
  ///////////////////
  options: LocTermApprovalOption[] = [];
  // the option with the max/highest values (based on drawAmount and paymentFrequency)
  maxOption: LocTermApprovalOption | undefined;

  //////////////
  // TERM VALUES
  //////////////
  minTerm: number = 0;
  maxTerm: number = 0;
  // the list of available term values pulled from the provided options
  availableTerms: number[] = [];
  selectedTerm: number = 0;

  ///////////////////////////
  // PAYMENT FREQUENCY VALUES
  ///////////////////////////
  paymentFrequencyDisplay: string = '';
  frequencyOptions: OptionData[] = [];
  termRestrictedFrequencyOptions: OptionData[] = [];
  selectedPaymentFrequency: string = '';

  //////////////
  // DRAW AMOUNT
  //////////////
  selectedDrawAmount: number = 0;
  minDrawAmount: number = 0;
  maxDrawAmount: number = 0;

  ///////////
  // DRAW FEE
  ///////////
  drawFeePercent: number = 0.0;
  drawFee: number = 0;

  ////////////////
  // PAYOFF MONTHS
  ////////////////
  minPayoff: number = 0;
  maxPayoff: number = 0;
  // the list of available payoff values for the slider
  availablePayoffMonths: number[] = [];
  selectedPayoffMonths: number = 0;

  ///////////////
  // DISBURSEMENT
  ///////////////
  disbursement: number = 0;

  ///////////
  // POINTS
  ///////////
  points: number = 0;

  /////////////
  // COMMISSION
  /////////////
  commission: number = 0;

  //////////
  // PAYBACK
  //////////
  payback: number = 0;
  paybackChanged: number = 0;
  paybackMax: number = 0;

  ///////////
  // APR
  ///////////
  apr: number = 0;
  aprMax: number = 0;

  ///////////
  // CENTS ON THE DOLLAR
  ///////////
  staticCentsOnTheDollar: number = 0;
  centsOnTheDollar: number = 0;
  centsOnTheDollarChanged: number = 0;
  centsOnTheDollarMax: number = 0;

  ///////////
  // PAYMENT AMOUNT
  ///////////
  paymentAmount: number = 0;

  ///////////
  // NUMBER OF PAYMENTS
  ///////////
  numberOfPayments: number = 0;
  numberOfPaymentsChanged: number = 0;
  effectivePaymentCount: number = 0;

  ///////////
  // CALCULATE SCHEDULE
  ///////////
  calculateScheduleParams: CalculateScheduleParams | undefined;

  ///////////
  // COST OF CAPITAL
  ///////////
  costOfCapital: number = 0;
  costOfCapitalChanged: number = 0;
  costOfCapitalMax: number = 0;

  ///////////
  // ANNUAL RATE
  ///////////
  annualRate: number = 0;

  ///////////
  // PAYMENT RATE
  ///////////
  paymentRate: number = 0;

  ///////////
  // PERIODIC RATE
  ///////////
  periodicRate: number = 0;
  periodicLabel: string = '';

  //////////////
  // VIEW STATES
  //////////////
  // to indicate when the approval data has been initialized and is ready for display
  initializing: boolean = true;
  // side info drawer open/closed state
  drawerActive: boolean = true;
  // early payoff state
  isEarlyPayoff: boolean = false;
  // can optionally be expanded on initialization
  @Input() initializeExpanded = false;
  // disable the component completely
  @Input() disabled = false;

  // Stores the sum of stipulation requests. Only needs to be calculated once
  stipulationCount: number = 0;

  //////////////
  // CALCULATOR
  //////////////
  calculator: any;

  //////////////
  // TOTAL INTEREST
  //////////////
  totalInterest: number = 0;

  /////////////////
  // OFFER/APPROVAL
  /////////////////
  @Input() set offer(v: Approval) {
    this._offer = v;
    this.calculationType = v.calculationType;
    this.options = v.options as LocTermApprovalOption[];

    this.stipulationCount = (v.requestNames?.conditional.length || 0) + (v.requestNames?.nonConditional.length || 0);
    this.calculate(true);
  }

  get offer() {
    return this._offer;
  }

  protected _offer: Approval;

  //used to as display for early payback scenarios
  displayedColumns: string[] = ['payoffType', 'payback', 'costOfCapital', 'cents', 'payments'];
  paybackDataSource: any[] = [];

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());
  constructor(private _themeService: LendioAngularMaterialThemeService) { }

  /**
   * Set/Calculate some needed values after the offer and options have been provided
   */
  calculate(initialize: boolean = false, resetMaxValues: boolean = false) {
    if (initialize) {
      // Depending on the if the approval is 'term' or 'loc' we need too look at different fields in the options object
      if (this.calculationType === 'term') {
        this.options = this.options.map(item => {
          return {
            ...item,
            drawFeePercent: item.originationFeePercent,
            limits: {
              ...item.limits,
              drawAmount: item.limits.amount

            }
          }
        })
      }

      this.determineBoundaries();
      this.determineMaxOption();
      this.setInitialAndMaxOptionValues();

      this.workingValues = this.maxOption;

    } else {
      // find the option associated with the selected term and frequency
      this.workingValues = this.options.find(
        (o: LocTermApprovalOption) => o.term === this.selectedTerm && o.paymentFrequency === this.selectedPaymentFrequency
      );


      // Some frequencies aren't available with certain terms
      const newPaymentFrequency = locCalculatorGeneralFormulas.findNewPaymentFrequency(
        this.selectedTerm, this.options, this.selectedPaymentFrequency);
      this.changePaymentFreq(newPaymentFrequency);

      // Are we evaluating early payoff?
      this.isEarlyPayoff = this.selectedPayoffMonths < this.selectedTerm;
    }

    // Find the frequency options restricted by the provided term
    this.termRestrictedFrequencyOptions = ApprovalCalculatorUtility.getAvailablePaymentFrequencies(
      this.options,
      this.selectedTerm
    );

    this.periodicRate = locCalculatorGeneralFormulas.toFixedNumber((this.workingValues?.periodicRate || 0.0), 10);
    this.periodicLabel = locCalculatorGeneralFormulas.getPeriodicLabel(this.workingValues?.periodsPerYear || 0);

    this.paymentAmount =
      locCalculatorGeneralFormulas.getPaymentAmount({
        drawAmount: this.selectedDrawAmount,
        periodicRate: this.periodicRate,
        paymentCount: this.workingValues?.payments || 0,
      }) || 0.0;

    this.annualRate =
      locCalculatorGeneralFormulas.getAnnualRate({
        periodicRate: this.periodicRate,
        periodsPerYear: this.workingValues?.periodsPerYear || 0,
      }) || 0;

    const dailyRate = locCalculatorGeneralFormulas.getDailyRate({ annualRate: this.annualRate }) || 0;
    this.paymentRate =
      locCalculatorGeneralFormulas.getPaymentRate({
        dailyRate: dailyRate,
        daysInPeriod: Number(this.workingValues?.daysInPeriod) || 0,
      }) || 0;

    this.numberOfPayments = this.workingValues?.payments || 0;
    this.effectivePaymentCount = locCalculatorGeneralFormulas.calculateEffectivePaymentCount(
      this.selectedPayoffMonths, this.numberOfPayments, this.selectedTerm);
    this.numberOfPaymentsChanged = this.numberOfPayments - this.effectivePaymentCount;

    this.calculateScheduleParams = {
      paymentCount: this.effectivePaymentCount,
      paymentRate: this.paymentRate,
      paymentAmount: this.paymentAmount,
      startBalance: this.selectedDrawAmount,
    };

    // configure the calculator
    try {
      this.calculator = this.initializeCalculator();
      this.totalInterest = locCalculatorGeneralFormulas.findTotalInterest(
        this.calculator.paymentSchedule, this.selectedPayoffMonths, this.numberOfPayments, this.selectedTerm);
      const finalPaymentAmount = locCalculatorGeneralFormulas.findFinalPaymentAmount(this.calculator.paymentSchedule);

      // calculator functions
      this.drawFeePercent = this.workingValues?.drawFeePercent || this.drawFeePercent;
      this.drawFee = this.calculator.drawFeeAmount(this.selectedDrawAmount, this.drawFeePercent);
      this.disbursement = this.calculator.disbursement(this.selectedDrawAmount, this.drawFee);

      this.points = this.workingValues?.limits.points.max || this.points;
      this.commission = locCalculatorGeneralFormulas.toFixedNumber((this.selectedDrawAmount * this.points) / 100, 2);

      this.payback = this.calculator.payback(this.selectedDrawAmount, this.totalInterest);
      this.costOfCapital = this.calculator.totalCostOfCapital(this.totalInterest, this.drawFee);
      this.centsOnTheDollar = this.calculator.centsOnTheDollar(this.payback, this.selectedDrawAmount);

      //reset max values when changeDrawAmount is called because new calculations need to be based of the slider adjusted amount
      if (resetMaxValues) {
        this.resetMax();
      }

      this.paybackChanged = locCalculatorGeneralFormulas.toFixedNumber(this.paybackMax - this.payback, 2);
      this.costOfCapitalChanged = locCalculatorGeneralFormulas.toFixedNumber(
        Math.round(this.costOfCapitalMax - this.costOfCapital),
        2
      );

      this.centsOnTheDollarChanged = locCalculatorGeneralFormulas.toFixedNumber(
        this.centsOnTheDollarMax - this.centsOnTheDollar,
        2
      );

      if (initialize) {
        this.aprMax = this.apr = this.calculator.apr(
          this.workingValues?.limits.drawAmount.max || 0,
          this.drawFee,
          this.paymentAmount,
          this.effectivePaymentCount,
          this.workingValues?.periodsPerYear || 0,
          finalPaymentAmount
        );
        this.costOfCapitalMax = this.calculator.totalCostOfCapital(this.totalInterest, this.drawFee);
        this.paybackMax = this.calculator.payback(this.selectedDrawAmount, this.totalInterest);
        this.centsOnTheDollarMax = this.calculator.centsOnTheDollar(this.payback, this.selectedDrawAmount);
        this.staticCentsOnTheDollar = this.calculator.centsOnTheDollar(
          this.paybackMax,
          this.workingValues?.limits.drawAmount.max || 0
        );
      } else {
        this.apr = this.calculator.apr(
          this.selectedDrawAmount,
          this.drawFee,
          this.paymentAmount,
          this.effectivePaymentCount,
          this.workingValues?.periodsPerYear || 0,
          finalPaymentAmount
        );
      }

      //reset datasource each time to reflect changes from early payoff slider.
      this.paybackDataSource = [];
      this.paybackDataSource.push({'payoffType': 'Full Term:', 'payments': this.numberOfPayments, 'cents': this.centsOnTheDollarMax, 'payback': this.paybackMax, 'costOfCapital': this.costOfCapitalMax })
      this.paybackDataSource.push({'payoffType': 'Selected Payoff:', 'payments': this.effectivePaymentCount, 'cents': this.centsOnTheDollar, 'payback': this.payback, 'costOfCapital': this.costOfCapital })

    } catch (e) {
      console.error('Error initializing LOC Calculator: ', e);
      this.errored = true;
      this.initializing = false;
      return;
    }

    // finished initializing
    this.initializing = false;
  }

  /**
  * change handler for term values
  */
  changeTerm(newTerm: number) {
    if (this.selectedTerm === newTerm) {
      return;
    }
    // find the closest term in the available terms list
    this.selectedTerm = this.availableTerms.reduce((prev, curr) => {
      return Math.abs(curr - newTerm) < Math.abs(prev - newTerm) ? curr : prev;
    });
    this.maxPayoff = this.selectedTerm;

    // reset early payoff
    this.changePayoffMonths(this.selectedTerm);
    this.calculate(false, true);

    // Update drawAmount boundaries and selected amount
    this.selectedDrawAmount = this.maxDrawAmount = this.workingValues?.limits.drawAmount.max || this.maxDrawAmount;
    this.minDrawAmount = this.workingValues?.limits.drawAmount.min || this.minDrawAmount;
  }

  /**
 * change handler for payoff months
 */
  changePayoffMonths(newTerm: number) {
    if (this.selectedPayoffMonths === newTerm) {
      return;
    }

    // find the closest available payoff month value
    this.selectedPayoffMonths = this.availablePayoffMonths.reduce((prev, curr) => {
      return Math.abs(curr - newTerm) < Math.abs(prev - newTerm) ? curr : prev;
    });

    this.calculate();

  }
  /**
   * change handler for payment frequency values
   */
  changePaymentFreq(freq: string) {
    if (this.selectedPaymentFrequency === freq) {
      return;
    }

    this.paymentFrequencyDisplay = locCalculatorGeneralFormulas.prettyFrequency(freq);
    this.selectedPaymentFrequency = freq;

    this.calculate();

    this.changeDrawAmount(this.workingValues.limits.drawAmount.max, false, true);
    //}
  }

  /**
   * change handler for draw amount
   */
  changeDrawAmount(newDrawAmount: number, termChanged: boolean = false, frequencyChanged: boolean = false) {
    if (this.selectedDrawAmount === newDrawAmount) {
      return;
    }

    if (termChanged || frequencyChanged) {
      this.maxDrawAmount = newDrawAmount;
    }

    this.selectedDrawAmount = newDrawAmount;

    // reset early payoff
    this.changePayoffMonths(this.selectedTerm);

    this.calculate(false, true);
  }

  //reset max values when changeDrawAmount is called because new calculations need to be based of the slider adjusted amount
  resetMax() {
    this.costOfCapitalMax = this.calculator.totalCostOfCapital(this.totalInterest, this.drawFee);
    this.paybackMax = this.calculator.payback(this.selectedDrawAmount, this.totalInterest);
    this.centsOnTheDollarMax = this.calculator.centsOnTheDollar(this.payback, this.selectedDrawAmount);
  }

  /**
   * Finds the min/max boundaries for the all options
   */
  private determineBoundaries() {
    // term
    this.maxTerm = this.options.reduce((max: number, o: LocTermApprovalOption) => (o.term > max ? o.term : max), 0);
    this.minTerm = this.options.reduce((min: number, o: LocTermApprovalOption) => (o.term < min ? o.term : min), 1000);

    // Draw Amount
    this.maxDrawAmount = this.options.reduce(
      (max: number, o: LocTermApprovalOption) => (o.limits.drawAmount.max > max ? o.limits.drawAmount.max : max),
      0
    );
    this.minDrawAmount = this.options.reduce(
      (min: number, o: LocTermApprovalOption) => (o.limits.drawAmount.min < min ? o.limits.drawAmount.min : min),
      9999999999
    );

    // Payoff Months
    this.maxPayoff = this.maxTerm;
    this.minPayoff = 1;
  }

  /**
   * Find the "max" of the available options by find the option with the highest term, drawAmount, and paymentFrequency
   */
  private determineMaxOption() {
    const maxPaymentFrequency = this.options
      .filter((o: LocTermApprovalOption) => o.term === this.maxTerm)
      .reduce((max: string, o: LocTermApprovalOption) => {
        const optionFreq = frequencyMap.get(o.paymentFrequency.toLowerCase()) || 0;
        const maxFreq = frequencyMap.get(max) || 0;
        return optionFreq > maxFreq ? o.paymentFrequency : max;
      }, 'daily');

    this.maxOption = this.options.find(
      (o: LocTermApprovalOption) =>
        o.limits.drawAmount.max === this.maxDrawAmount &&
        o.paymentFrequency === maxPaymentFrequency &&
        o.term === this.maxTerm
    );
  }

  private setInitialAndMaxOptionValues() {
    // initialize to max option
    this.selectedDrawAmount = this.maxOption?.limits.drawAmount.max || 0;

    // default to max option term
    this.selectedTerm = this.maxOption?.term || 0;

    // initialize to max option
    this.selectedPaymentFrequency = this.maxOption?.paymentFrequency || '';

    // initialize to max option
    this.drawFeePercent = this.maxOption?.drawFeePercent || 0.0;

    // term values
    this.availableTerms = Array.from(
      this.options.reduce((acc: Set<number>, o: LocTermApprovalOption) => acc.add(o.term), new Set())
    );

    // initialize to max option
    this.paymentFrequencyDisplay = locCalculatorGeneralFormulas.prettyFrequency(this.maxOption?.paymentFrequency || '');

    // initialize payoff months to max value
    this.selectedPayoffMonths = this.maxPayoff;

    // set the available payoff months
    this.availablePayoffMonths = Array.from(Array(this.maxTerm).keys()).map((x) => x + 1);

    // initialize number of payments
    this.numberOfPayments = this.maxOption?.payments || 0;
    this.effectivePaymentCount = this.numberOfPayments;
    this.numberOfPaymentsChanged = this.numberOfPayments;

    // Create the payment frequency values based on what's available in the options in the correct format that's
    // required by the `app-restricted-radio` component
    this.frequencyOptions = Array.from(frequencyMap.keys())
      .filter((freq: string) =>
        this.options.some((o: LocTermApprovalOption) => o.paymentFrequency.toLowerCase() === freq.toLowerCase())
      )
      .map((freq: string) => ({ label: locCalculatorGeneralFormulas.prettyFrequency(freq), value: freq }));
  }

  /**
   * Initialize the Approval Calculator
   */
  private initializeCalculator() {
    const { drawFeeAmount, disbursement, totalCostOfCapital, paymentSchedule } = this.offer.calculatorConfig;

    // configure calculator
    return configureCalculator({
      drawFeeAmount,
      disbursement,
      totalCostOfCapital,
      paymentSchedule,
      calculateScheduleParams: this.calculateScheduleParams,
    });
  }
}
