import { AgGridViewFilter } from './funnel-views.state';

export class GetFunnelViews {
  static readonly type = '[Funnel Views] GetFunnelViews';
  constructor() {}
}

export class UpdateFunnelViews {
  static readonly type = '[Funnel Views] UpdateFunnelViews';
  constructor(public funnelViews: AgGridViewFilter[]) {}
}

export class ClearFunnelViewsStore {
  static readonly type = '[Funnel Views] ClearFunnelViewsStore';
  constructor() {}
}
