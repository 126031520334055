import { Injectable } from '@angular/core';
import { FilterData } from '../interfaces/filters.model';
import { findIndex } from 'lodash';
import { LocalStorageRefService } from './local-storage-ref.service';

const KEY = 'listFilters';

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    private filterObjectData: FilterData[];

    constructor(private localStorageService: LocalStorageRefService) {
        this.filterObjectData = JSON.parse(this.localStorageService.localStorage.getItem(KEY) || '[]');
    }

    deletePathFilter(filter: FilterData): void {
        if(!this.filterObjectData) {
            return;
        }
        let filters = this.filterObjectData.filter((x: { path: string, name: string }) => (x.path !== filter.path && x.name !== filter.name));
        this.setFilters(filters);
    }

    getFiltersByPath(path: string = window.location.pathname): FilterData[] | [] {
        if(this.filterObjectData) {
            return this.filterObjectData.filter(filter => filter.path === path);
        }
        return [];
    }

    /**
     * 
     * @param filter
     * @returns All filters for this filter path
     */
    setPathFilter(filter: FilterData): FilterData {
        const filterData = this.filterObjectData.find(fs => { fs.path === filter.path && fs.name === filter.name });

        var index = findIndex(this.filterObjectData, filter);

        var newFilterObj = (index === -1) ? this.filterObjectData.push(filter) : this.filterObjectData.splice(index, 1, filter);

        this.setFilters(this.filterObjectData);
        return filter;
    }

    setFilters(filters: FilterData[]): FilterData[] {
        this.localStorageService.localStorage.setItem(KEY, JSON.stringify(filters));
        return filters;
    }
}
