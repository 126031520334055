import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ViewChild, computed
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { takeUntil } from 'rxjs/operators';
import { Label } from '../../interfaces/label.model';
import { Select, Store } from '@ngxs/store';
import {
    DetachApplicationLabel as DetachFromDetails,
    AttachApplicationLabel as AttachFromDetails
} from '../../store/application-details/application-details.actions';
import {
    DetachMultipleLabels as DetachFromList,
    AttachMultipleLabels as AttachFromList
} from '../../store/applications-list/applications-list.actions';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LabelsState } from '../../store/labels/labels.state';
import { LabelEditDialogComponent } from './edit-dialog/edit-dialog.component';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
    selector: 'app-labels',
    templateUrl: './labels.component.html',
    styleUrls: ['./labels.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({ transform: 'scale(0)' }),
                        animate('.25s ease-out',
                            style({ transform: 'scale(1.1)' })),
                        animate('.1s ease-out',
                            style({ transform: 'scale(.9)' })),
                        animate('.15s ease-out',
                            style({ transform: 'scale(1)' }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ transform: 'scale(1) translate(0,0)', opacity: 1 }),
                        animate('.25s ease-in',
                            style({ transform: 'scale(1.25) translate(8px,8px)', opacity: 0 }))
                    ]
                )
            ]
        )
    ]
})
export class LabelsComponent implements OnInit, OnDestroy {

    // @Select(LabelsState.deletedLabels) deletedLabels$: Observable<[]>;
    deletedLabels$: Observable<any> = this.store.select(LabelsState.deletedLabels);

    @ViewChild('labelMenuTrigger') labelMenuTrigger: MatMenuTrigger;
    @ViewChild('labelMenu') labelMenu: MatMenu;

    @Input() labels: Label[];
    @Input() dealId: number;
    @Input() lenderLabels: Label[];

    @Output() menuOpenChange = new EventEmitter<boolean>();

    @Input()
    get menuOpen(){
        return this.isMenuOpen;
    }

    set menuOpen(val) {
        this.isMenuOpen = val;
        this.menuOpenChange.emit(this.isMenuOpen);
    }

    isMenuOpen = false;
    deletedLabels: number[] = [];

    private labelsToAttach: Label[] = [];
    private labelsToDetach: number[] = [];
    private destroyed$ = new Subject<void>();

    oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

    constructor(public store: Store,
                public dialog: MatDialog,
                private cdRef: ChangeDetectorRef,
                private _themeService: LendioAngularMaterialThemeService
    ) {
        this.deletedLabels$.pipe(takeUntil(this.destroyed$))
            .subscribe(data => {
                if (data) {
                    this.deletedLabels = data;
                }
            });
    }

    ngOnInit(): void {}

    getStyle(label: Label) {
        if (label && this.lenderLabels) {
            const index = this.lenderLabels.findIndex(x => x.id === label.id);

            if (index !== -1) {
                return {
                    'background-color': this.lenderLabels[index].color,
                    color: this.lenderLabels[index].secondaryColor
                };
            }

            return {
                'background-color': label.color,
                color: label.secondaryColor
            };
        }
    }

    public getText(label: Label) {
        if (label && this.lenderLabels) {
            const index = this.lenderLabels.findIndex(x => x.id === label.id);

            if (index !== -1) {
                return this.lenderLabels[index].name;
            }

            return label.name;
        }
    }

    public detach(labelId: number) {
        const index = this.labels.findIndex(x => x.id === labelId);
        const currentLabels = JSON.parse(JSON.stringify(this.labels));

        if (index !== -1) {
            currentLabels.splice(index, 1);
            this.labels = currentLabels;
            this.labelsToDetach.push(labelId);
        }

        this.store.dispatch(new DetachFromDetails(labelId, this.dealId));
        this.store.dispatch(new DetachFromList([labelId], this.dealId));
    }

    public attach(label: Label) {
        const currentLabels = JSON.parse(JSON.stringify(this.labels));
        const index = currentLabels.findIndex((x: { id: number; }) => x.id === label.id);

        if (index === -1) {
            this.labelsToAttach.push(label);

            currentLabels.push(label);
            this.labels = currentLabels;

            this.store.dispatch(new AttachFromDetails(label, this.dealId));
        } else {
            label.id && this.detach(label.id);
        }
    }

    public isLenderLabelAdded(labelId: number) {
        const index = this.labels.findIndex(x => x.id === labelId);

        return index !== -1;
    }

    public menuOpenChanged(val: boolean) {
        this.isMenuOpen = val;
        this.menuOpenChange.emit(this.isMenuOpen);

        // Remove intersection of attach and detach.
        // If they're both being attached and detached, nothing needs to be done.
        this.labelsToAttach = this.labelsToAttach.filter((label) => {
            const detachIndex = label.id && this.labelsToDetach.indexOf(label.id);

            // TODO check with Luke
            if (detachIndex && detachIndex > -1) {
                this.labelsToDetach.splice(detachIndex, 1);
                return false;
            }
            return true;
        });

        if (val === false) {
            if (this.labelsToAttach.length > 0) {
                this.store.dispatch(new AttachFromList(this.labelsToAttach, this.dealId));
                this.labelsToAttach = [];
            }

            if (this.labelsToDetach.length > 0) {
                this.store.dispatch(new DetachFromList(this.labelsToDetach, this.dealId));
                this.labelsToDetach = [];
            }
        }
    }

    public openEditDialog(label?: Label) {
        const dialogRef = this.dialog.open(LabelEditDialogComponent, {
            width: '300px',
            panelClass: 'no-overflow',
            data: label
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.name && result.color) {
                const newLabel = result;
                const tempLabels = [ ...this.lenderLabels];

                if (newLabel.id) {
                    const index = tempLabels.findIndex(x => x.id === newLabel.id);

                    tempLabels[index] = newLabel;

                    this.lenderLabels = tempLabels;
                    this.cdRef.detectChanges();
                }
            }
        });
    }

    trackByFn(index: any, item: { id: any; }) {
        return item.id;
    }

    handleCogKeypress(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.labelMenuTrigger.openMenu();
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
