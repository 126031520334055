import { Component, Input, OnInit } from '@angular/core';
import { SbaStatusObject } from '../../../../../interfaces/sba-statuses.model';

@Component({
  selector: 'app-ppp-incomplete',
  templateUrl: './ppp-incomplete.component.html',
  styleUrls: ['./ppp-incomplete.component.scss']
})
export class PppIncompleteComponent implements OnInit {

  @Input() status: SbaStatusObject;

  constructor() { }

  ngOnInit(): void {
  }

}
