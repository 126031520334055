import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewOfferDialogComponent } from '../../new-offer-display/new-offer-dialog/new-offer-dialog.component';

@Component({
  selector: 'app-new-offer-button',
  templateUrl: './new-offer-button.component.html',
  styleUrls: ['./new-offer-button.component.scss']
})
export class NewOfferButtonComponent implements OnInit {

  @Input() dealId: number;
  @Input() loanProductCategory: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openNewOfferDialog(link: boolean = false): void {
    this.dialog.open(NewOfferDialogComponent, {
      width: '80%',
      data: {
        link,
        dealId: this.dealId,
        loanProductCategory: this.loanProductCategory
      }
    });
  }
}
