import { CustomizationRequest, _CustomizationRequest } from 'src/app/interfaces/embedded-customization.model'

export class ClearEmbeddedStore {
	static readonly type = '[Embedded] ClearEmbeddedStore';
	constructor() {}
}

export class GetEmbeddedPartner {
	static readonly type = '[Embedded] GetEmbeddedPartner';
	constructor(public affiliateId: number | string) {}
}

export class GetEmbeddedCredentials {
	static readonly type = '[Embedded] GetEmbeddedCredentials';
	constructor(public affiliateId: number) {}
}

export class GetCustomizations {
	static readonly type = '[Embedded] GetEmbeddedCustomizations';
	constructor(public affiliateId: number, public type?: string) {}
}

export class _GetCustomizations {
	static readonly type = '[Embedded] GetEmbeddedCustomizations';
	constructor(public affiliateId: number | string) {}
}

export class GetCustomization {
	static readonly type = '[Embedded] GetEmbeddedCustomization';
	constructor(public affiliateId: number, public customizationId: number) {}
}

export class _GetCustomization {
	static readonly type = '[Embedded] _GetEmbeddedCustomization';
	constructor(public affiliateId: number | string, public customizationId: string) {}
}

export class CreateEmbeddedAffiliateUserToken {
	static readonly type = '[Embedded] CreateEmbeddedAffiliateUserToken';
	constructor(public affiliateId: number | string) {}
}
export class CreateCustomization {
	static readonly type = '[Embedded] CreateEmbeddedCustomization';
	constructor(public affiliateId: number, public customizationRequest: CustomizationRequest) {}
}

export class _CreateCustomization {
	static readonly type = '[Embedded] _CreateEmbeddedCustomization';
	constructor(public affiliateId: number | string, public customizationRequest: _CustomizationRequest) {}
}

export class UpdateCustomization {
	static readonly type = '[Embedded] UpdateEmbeddedCustomization';
	constructor(public affiliateId: number, public customizationId: string | number, public customizationRequest: CustomizationRequest) {}
}

export class _UpdateCustomization {
	static readonly type = '[Embedded] _UpdateEmbeddedCustomization';
	constructor(public affiliateId: number | string, public customizationId: string | number, public customizationRequest: _CustomizationRequest) {}
}
export class EmbeddedLoaded {
	static readonly type = '[Embedded] EmbeddedLoaded';
	constructor(public bool: boolean) {}
}
