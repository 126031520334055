import { NgModule, importProvidersFrom } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Routes, RouterModule } from '@angular/router';
import { LoadersModule } from '../loaders/loaders.module';
import { LabelsModule } from '../labels/labels.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ToDateObjPipeModule } from 'src/app/pipes/toDateObj/to-date-obj-pipe.module';
import { MatDividerModule } from '@angular/material/divider';
import { TruncatePipeModule } from '@app/app/pipes/truncate/truncate-pipe.module';
import { BusinessesComponent } from './businesses.component';
import { RouterLink } from '@angular/router';
import { BusinessDetailsComponent } from './business-details/business-details.component';
import { OverviewComponent } from './business-details/overview/overview.component';
import { BusinessesListService } from '@app/app/services/businesses-list.service';
import { BusinessService } from '@app/app/services/business.service';
import { PhoneModule } from '@app/app/pipes/phone/phone-pipe.module';
import { EditBusinessDialogComponent } from './business-details/overview/edit-business-dialog/edit-business-dialog.component';
import { EditContactDialogComponent } from './business-details/contacts/edit-contact-dialog/edit-contact-dialog.component';
import { FormOptionsService } from '@app/app/services/form-options.service';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BorrowerStatusIndicatorComponent } from './business-details/borrower-status-indicator/borrower-status-indicator.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AddBusinessDialogComponent } from './add-business-dialog/add-business-dialog.component';
import { ContactsService } from '@app/app/services/contacts.service';
import { ContactsComponent } from './business-details/contacts/contacts.component';
import { DocumentsModule } from '../documents/documents.module';
import { saasFeatureGuard } from '@app/app/guards/saas-features.guard';
import { LendioPaginatorComponent } from '@app/app/components/lendio-angular-material-theme/lendio-paginator/lendio-paginator.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FinancesModule } from '@app/app/components/businesses/finances/finances.module';
import { ColumnSortCacheModule } from '@app/app/directives/column-sort-cache/column-sort-cache.module';
import { MatChipsModule } from '@angular/material/chips';
import { DealSimpleComponent } from '@app/app/components/deal-simple/deal-simple.component';
import { EmptyStateComponent } from '../empty-state/empty-state.component';

const routes: Routes = [
  {
    path: '', // /businesses - business-table
    component: BusinessesComponent,
    pathMatch: 'full',
  },
  {
    path: ':id', // /businesses/1 - business-details > overview
    component: BusinessDetailsComponent,
    canActivate: [saasFeatureGuard],
    data: { saasFeature: 'businessDetails' }
  }
];

@NgModule({
  declarations: [
    BusinessesComponent,
    BusinessDetailsComponent,
    OverviewComponent,
    ContactsComponent,
    EditBusinessDialogComponent,
    EditContactDialogComponent,
    AddBusinessDialogComponent
  ],
  imports: [
    BorrowerStatusIndicatorComponent,
    CommonModule,
    ColumnSortCacheModule,
    DocumentsModule,
    PhoneModule,
    FormsModule,
    ReactiveFormsModule,
    LabelsModule,
    LoadersModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    LoadersModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    RouterLink,
    RouterModule.forChild( routes ),
    ToDateObjPipeModule,
    TruncatePipeModule,
    LendioPaginatorComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    FinancesModule,
    DealSimpleComponent,
    EmptyStateComponent
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    BusinessService,
    BusinessesListService,
    ContactsService,
    FormOptionsService,
    [provideNgxMask()],
  ]
})
export class BusinessesModule {}
