import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { lpxPermissionGuard } from './guards/lpx-permission.guard';
import { lendioOnlyGuard } from './guards/lendio-only.guard';
import { NotFoundComponent } from './components/errors/not-found/not-found.component';
import { LogoutComponent } from './components/logout/logout.component';
import { saasFeatureGuard } from './guards/saas-features.guard';
import { funnelDarkLaunchGuard } from '@app/app/components/funnel/funnel-dark-launch.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'applications',
        loadChildren: () => import('./components/applications/applications.module').then(m => m.ApplicationsModule),
        canActivate: [authGuard],
    },
    {
        path: 'renewals',
        loadChildren: () => import('./components/renewals-list/applications.module').then(m => m.ApplicationsModule),
        canActivate: [authGuard],
    },
    {
        path: 'csv',
        loadChildren: () => import('./components/csv-page/csv-page.module').then(m => m.CsvPageModule),
        canActivate: [authGuard],
    },
    {
        path: 'deal',
        loadChildren: () => import('./components/applications/application-details/application-details.module').then(m => m.ApplicationDetailsModule),
        canActivate: [authGuard],
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [authGuard]
    },
    {
        path: 'clients',
        loadChildren: () => import('./components/clients/clients.module').then(m => m.ClientsModule),
        canActivate: [authGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [authGuard],
    },
    {
        path: 'loan-product',
        loadChildren: () => import('./components/loan-product/loan-product.module').then(m => m.LoanProductModule),
        canActivate: [authGuard, lendioOnlyGuard]
    },
    {
        path: 'funnel',
        loadChildren: () => import('./components/funnel/funnel.module').then(m => m.FunnelModule),
        canActivate: [authGuard, funnelDarkLaunchGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [authGuard, saasFeatureGuard,],
        data: { saasFeature: 'reportsList' }
    },
    {
        path: 'administration',
        title: 'Import',
        loadChildren: () => import('./components/administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [authGuard],
        data:
        {
          saasFeature: 'navigationAdministration',
          permission: 'lpxNavigationAdministration.view'
        }
    },
    {
        path: 'businesses',
        loadChildren: () => import('./components/businesses/businesses.module').then(m => m.BusinessesModule),
        canActivate: [authGuard, saasFeatureGuard],
        data: { saasFeature: 'businessList' }
    },
    {
        path: 'marketing',
        loadComponent: () => import('./components/marketing/marketing.component').then(c => c.MarketingComponent),
        canActivate: [authGuard, lpxPermissionGuard,],
        data: { permission: 'lpxCanSeeMarketingModule' },
    },
    {
        path: 'login',
        redirectTo: 'auth/login',
    },
    {
      path: 'dark-launch',
      redirectTo: 'auth/dark-launch',
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'reset-password',
        redirectTo: 'auth/reset-password',
    },
    {
        path: 'account-locked',
        redirectTo: 'auth/account-locked',
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    {
        path: 'partner-dashboard',
        loadChildren: () => import('./components/partner-dashboard/partner-dashboard.module').then(m => m.PartnerDashboardModule),
        canActivate: [authGuard]
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule {
}
