<h2 mat-dialog-title>Action Required</h2>
<mat-dialog-content class="mat-typography">
    <p *ngIf="data['toggleOn']">This product will be <b>included</b> in the filtration of the submission strategy.</p>
    <p *ngIf="!data['toggleOn']">This product will be <b>excluded</b> from the filtration of the submission strategy.</p>
</mat-dialog-content>
<mat-dialog-actions class="!py-4" align="end">
    <button mat-button mat-dialog-close
            data-cy="credit-box-rules-dialog-cancel">Cancel</button>
    <button mat-flat-button color="primary"
            [mat-dialog-close]="true"
            FocusInitial
            data-cy="credit-box-rules-dialog-confirm">
        Continue
    </button>
</mat-dialog-actions>
