import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@app/environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class EsignatureService {

    constructor(private http: HttpClient) {}

    public getEnvelopeSigners(dealId: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/deal/${dealId}/sign-now-document-signers`);
    }
}