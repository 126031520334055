<ng-container *ngIf="(approvals$ | async) as approvals; else NoApproval">
@if (approvals.length) {
    @if (newThemeEnabled()) {
        <div class="p-4">
            @if (locTermApprovals.length) {
                <app-approvals [approvals]="locTermApprovals"></app-approvals>
            }
            <mat-accordion class="approvals-accordion">
                <div *ngFor="let offer of otherApprovals; let index = index;" class="mb-2 first:pt-5">
                    <app-ach-approvals
                        [initializeExpanded]="!locTermApprovals.length && index === 0"
                        [readOnly]="readOnly"
                        [disabled]="offer.calculationType === 'custom' && customControlsDisabled" [offer]="offer"
                        [loading]="getLoading(offer.id)" [errored]="getErrored(offer.id)" (error)="setErrored($event)"
                        (approvalAdjusted)="handleAdjustedApproval($event)">
                    </app-ach-approvals>
                </div>
            </mat-accordion>
        </div>
    } @else {
        <div *ngFor="let offer of approvals; let index = index;" class="mb-2 first:pt-5">
            <div [ngSwitch]="offer.calculationType | lowercase">
                <app-loc-term-approval-old *ngSwitchCase="'loc'" [initializeExpanded]="approvals && approvals.length === 1"
                                           [offer]="offer">
                </app-loc-term-approval-old>
                <app-loc-term-approval-old *ngSwitchCase="'term'" [initializeExpanded]="approvals && approvals.length === 1"
                                           [offer]="offer">
                </app-loc-term-approval-old>
                <app-old-approval *ngSwitchDefault [readOnly]="readOnly"
                                  [initializeExpanded]="approvals && approvals.length === 1"
                                  [disabled]="offer.calculationType === 'custom' && customControlsDisabled"
                                  [channel]="getCalcChannel(offer.id)" [offer]="offer" [loading]="getLoading(offer.id)"
                                  [errored]="getErrored(offer.id)" (error)="setErrored($event)"
                                  (approvalAdjusted)="handleAdjustedApproval($event)"
                                  (pusherPayloadReceived)="handlePusherPayloadReceived($event)">
                </app-old-approval>
            </div>
        </div>
    }
} @else {
    <ng-template [ngTemplateOutlet]="NoApproval"></ng-template>
}
</ng-container>
<ng-template #NoApproval>
  <mat-card class="m-4" appearance="outlined">
    <mat-card-content>
      <app-empty-state
        [type]="emptyType"
        [label]="emptyLabelContent"
        [loading]="approvalsLoading$ | async">
      </app-empty-state>
    </mat-card-content>
  </mat-card>
</ng-template>
