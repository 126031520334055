import { Component } from '@angular/core';
import { LendioAngularMaterialThemeService } from 'src/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './application-details-resolver.component.html',
})
export class ApplicationDetailsResolverComponent {
  newThemeEnabled$: Observable<boolean> = this._themeService.enabled;

  constructor(private _themeService: LendioAngularMaterialThemeService) { }
}
