import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContractRequestsService
{
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getContractRequestOffers(dealId: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/l/v2/internal/deals/${dealId}/contract-requests-lender`);
    }
}
