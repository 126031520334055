import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { IntegrationsState } from '@app/app/store/integrations/integrations.state';
import { Integration } from '@app/app/interfaces/integration.model';
import { Observable } from 'rxjs';
import { StatusConfigMap } from '@app/app/components/integrations/integrations-status-config-map.model';

@Component({
  selector: 'app-integrations-page',
  templateUrl: 'integrations-page.component.html',
})
export class IntegrationsPageComponent {

  @Select( IntegrationsState.integrations ) integrations$: Observable<Integration[]>;


  statusMap = StatusConfigMap;

}
