@if (newThemeEnabled()) {
<nav mat-tab-nav-bar [tabPanel]="settingsNavTabPanel">
    @for (tab of tabs; track tab.name) {
        <a mat-tab-link
           routerLinkActive
           #rla="routerLinkActive"
           *ngIf="tab.permitted"
           [active]="rla.isActive"
           [routerLink]="tab.path"
           [disableRipple]="true"
           data-cy="email-notifications-tab">
            {{ tab.label }}
            @if (tab.label === 'Early access' && showEarlyAccessBadge()) {
                <span class="early-access-tab-badge"></span>
            }
        </a>
    }
</nav>
<mat-tab-nav-panel #settingsNavTabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>
} @else {
<mat-tab-group mat-stretch-tabs="false"
               mat-align-tabs="start"
               [(selectedIndex)]="selectedIndex"
               animationDuration="0ms">
    <!-- Notifications tab -->
    @if (canManageNotifications) {
    <mat-tab>
        <ng-template mat-tab-label><span data-cy="email-notifications-tab" [ngClass]="{ 'font-bold' : selectedIndex === 0 }">Email Notifications</span></ng-template>
        <mat-card class="m-4" appearance="outlined">
            <app-old-email-settings
                    [emailSettings]="(emailSettings$ | async)!"
                    [emailSettingsUpdateStatus]="emailSettingsUpdateStatus$ | async"
                    (updateSetting)="updateSetting($event)">
            </app-old-email-settings>
        </mat-card>
    </mat-tab>
    }
    <!-- New pw tab -->
    <mat-tab>
        <ng-template mat-tab-label><span data-cy="new-password-tab" [ngClass]="{ 'font-bold' : selectedIndex === 1 }">Update Password</span></ng-template>
            <app-new-password></app-new-password>
    </mat-tab>
    <!-- Users tab -->
    <mat-tab >
        <ng-template mat-tab-label><span data-cy="manage-users-tab" [ngClass]="{ 'font-bold' : selectedIndex === 2 }">Users</span></ng-template>
            <app-manage-users></app-manage-users>
    </mat-tab>
    <!-- Sba token tab -->
    @if (canManageSbaToken) {
    <mat-tab>
        <ng-template mat-tab-label><span data-cy="sba-token-tab" [ngClass]="{ 'font-bold' : selectedIndex === 3 }">SBA Token</span></ng-template>
        <app-manage-sba-token></app-manage-sba-token>
    </mat-tab>
    }
    <!-- Early access tab -->
    <mat-tab aria-label="early access">
        <ng-template mat-tab-label>
            <span data-cy="early-access-tab" [ngClass]="{ 'font-bold' : selectedIndex === 4 }">Early Access</span>
            @if (showEarlyAccessBadge()) {
                <span class="early-access-tab-badge"></span>
            }
        </ng-template>
        <app-early-access></app-early-access>
    </mat-tab>
</mat-tab-group>
}
