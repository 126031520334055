import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FundingDeskRequest } from '../../interfaces/funding-desk-request.model';
import { FundingDeskRequestService } from '../../services/funding-desk-request.service';
import { GetFundingDeskRequests, CreateFundingDeskRequest, CreateFundingDeskRequestComment, ClearFundingDeskRequestsStore } from './funding-desk-requests.actions';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';

export class  FundingDeskRequestsStateModel {
    requests: FundingDeskRequest[];
}

@State<FundingDeskRequestsStateModel>({
    name: 'fundingDeskRequests',
    defaults: {
        requests: []
    }
})
@Injectable()

export class FundingDeskRequestsState {

    @Selector()
        static fundingDeskRequests(state: FundingDeskRequestsStateModel) {
        return state.requests;
    }

    constructor(
        private fundingDeskRequestService: FundingDeskRequestService,
        private store: Store
    ) {}

    @Action(GetFundingDeskRequests)
    getApplicationDetails({ patchState }: StateContext<FundingDeskRequestsStateModel>, { dealId }: GetFundingDeskRequests) {
        return this.fundingDeskRequestService.getRequests(dealId).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve this borrower\'s funding desk requests. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap(response => {
            patchState({ requests: response.sort((a, b) => (new Date(a.created) < new Date(b.created)) ? 1 : -1) });
        }));
    }

    @Action(CreateFundingDeskRequest)
    // eslint-disable-next-line max-len
    createFundingDeskRequest({ getState, patchState }: StateContext<FundingDeskRequestsStateModel>, { dealId, description, type }: CreateFundingDeskRequest) {
        return this.fundingDeskRequestService.createNewRequest(dealId, description, type).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to create funding desk request. Please try again.'
            }));
            return throwError(err);
        }), tap(response => {
            const fundingDeskRequests = getState().requests;
            patchState({ requests: [ response.data, ...fundingDeskRequests ]});
        }));
    }

    @Action(CreateFundingDeskRequestComment)
    createFundingDeskRequestComment({ getState, patchState }: StateContext<FundingDeskRequestsStateModel>, {
        dealId,
        requestId,
        description
    }: CreateFundingDeskRequestComment) {
        return this.fundingDeskRequestService.createNewComment(dealId, requestId, description).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to create funding desk request comment. Please try again.'
            }));
            return throwError(err);
        }), tap(response => {
            const requests = [ ...getState().requests ];
            const requestIndex = requests.findIndex(req => req.id === requestId);
            const request = { ...requests[requestIndex] };
            const comments = [ ...request.task_comments ];
            comments.push(response.data);
            request.task_comments = comments;
            requests[requestIndex] = request;
            patchState({ requests });
        }));
    }

    @Action(ClearFundingDeskRequestsStore)
    clearFundingDeskRequestsStore({ patchState }: StateContext<FundingDeskRequestsStateModel>, {}: ClearFundingDeskRequestsStore) {
        patchState({ requests: [] });
    }
}
