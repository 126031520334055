import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadersModule } from '../loaders/loaders.module';
import { ContractRequestsComponent } from './contract-requests/contract-requests.component';
import { ContractRequestsSmartComponent } from './contract-requests-smart/contract-requests-smart.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { EmptyStateComponent } from '@app/app/components/empty-state/empty-state.component';
@NgModule({
    declarations: [
        ContractRequestsSmartComponent,
        ContractRequestsComponent
    ],
    exports: [
        ContractRequestsSmartComponent,
        ContractRequestsComponent
    ],
    imports: [
        CommonModule,
        LoadersModule,
        MatCardModule,
        MatButtonModule,
        EmptyStateComponent,
    ]
})
export class ContractRequestsModule { }
