import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavPageTitleService {
  private _importDetailsActive = new BehaviorSubject<boolean>(false);
  public importDetailsActive$ = this._importDetailsActive.asObservable();

  public pageTitle = signal('');
  public secondaryPageTitle = signal('');
  constructor() { }

  setTitle(title: string, secondaryTitle: string | null = null) {
    this.pageTitle.set(title);
    this.secondaryPageTitle.set(secondaryTitle ?? '');
  }

  setImportDetailsActive(hide: boolean) {
    this._importDetailsActive.next(hide);
  }

  getImportDetailsActive(): Observable<boolean> {
    return this.importDetailsActive$;
  }
}
