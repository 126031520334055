export class ClearSearchResults {
  static readonly type = '[Search] ClearSearchResults';
}

export class ClearRecentSearches {
  static readonly type = '[Search] ClearRecentSearches';
}

export class GetRecentSearchLocalStorage {
  static readonly type = '[Search] GetRecentSearchLocalStorage';
}

export class Search {
  static readonly type = '[Search] Search'
  constructor(
    public term: string,
  ) {}
}
