import { Component, OnInit, Input, computed } from '@angular/core';

import * as moment from 'moment-timezone';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-awaiting-response-table',
  templateUrl: './awaiting-response-table.component.html',
  styleUrls: ['./awaiting-response-table.component.scss']
})
export class AwaitingResponseTableComponent implements OnInit {

  @Input() awaitingResponse: any;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) { }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }
}
