import { NewPortalUser } from 'src/app/interfaces/new-portal-user.model';

export class GetPortalUsers {
    static readonly type = '[PortalUser] GetPortalUsers';
    constructor() {}
}

export class CreatePortalUser {
    static readonly type = '[PortalUser] CreatePortalUser';
    constructor(public newPortalUser: NewPortalUser) {}
}

export class UpdatePortalUser {
    static readonly type = '[PortalUser] UpdatePortalUser';
    constructor(public portalUserId: number, public updates: any) {}
}

export class DeletePortalUser {
    static readonly type = '[PortalUser] DeletePortalUser';
    constructor(public portalUserId: number) {}
}

export class ClearPortalUsers {
    static readonly type = '[PortalUser] ClearPortalUsers';
    constructor() {}
}
