import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
    imports: [],
    exports: [TruncatePipe],
    declarations: [TruncatePipe],
    providers: [TruncatePipe],
})
export class TruncatePipeModule { }
