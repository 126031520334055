import { ChangeDetectorRef, Component, computed, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngxs/store';
import {retry, take} from 'rxjs/operators';
import { LoanProductQuestion } from '../../interfaces/loan-product-question.model';
import { LoanProduct } from '../../interfaces/loan-product.model';
import { QualifierUnsaved } from '../../interfaces/qualifier-unsaved.model';
import { Question } from '../../interfaces/question.model';
import { State } from '../../interfaces/state.model';
import { LoanProductService } from '../../services/loan-product.service';
import { CreateNewAlert } from '../../store/global-alerts/global-alerts.actions';
import { RemoveRequirementPayload } from './required-information/required-information.component';
import { QualifierChanged } from './qualifier/qualifier.component';
import { Qualifier } from 'src/app/interfaces/qualifier.model';
import { QualifierExceptionGroup } from 'src/app/interfaces/qualifier-exception-group.model';
import {DeleteAllQualifiersDialogComponent} from 'src/app/components/loan-product/delete-all-qualifiers-dialog/delete-all-qualifiers-dialog.component';
import {ConfirmCreditBoxRulesDialogComponent} from 'src/app/components/loan-product/confirm-credit-box-rules-dialog/confirm-credit-box-rules-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { AuthState } from '@app/app/store/auth/auth.state';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-loan-product',
  templateUrl: './loan-product.component.html',
  styleUrls: ['./loan-product.component.scss'],
})
export class LoanProductComponent implements OnInit {
  loanProductId;
  qualifierQuestions: Question[] | null = null;
  loanProduct: LoanProduct | any = null;
  states: State[] | null = null;
  infoQuestions: Question[] | null = null;
  loanProductQuestions: LoanProductQuestion[] | null = null;
  requiredForContractOffer: LoanProductQuestion[] | null = null;
  addingQualifier = false;
  pendingGroupQualifier: { qualifierExceptionGroupId: any; } | null= null; // qualifier that is pending group creation
  isQualifierDirty = false;
  dirtyQualifierId: number | null = null;
  modifiedQualifier: QualifierUnsaved | null = null;
  outdatedQualifier: Qualifier | null | undefined = null;
  isInactive = false;
  isIncludedInSubmissionStrategy = false;
  canSave = false;
  updatingQualifier = false;
  errored = false;
  disabledBaseAttributeFields: string[] = [];
  disabledBaseOperatorFilterTypes: Map<string, string> = new Map<
    string,
    string
  >();
  addingQualifierExceptionGroup = false;
  updatingQualifierExceptionGroup = false;
  showBaseQualificationRequirementWarning = false;
  defaultGroupName = 'GROUP 1';
  hasWriteAccess = false;
  hasLoanProductWriteAccess = false;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled);

  constructor(
    private loanProductService: LoanProductService,
    private actRoute: ActivatedRoute,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private _themeService: LendioAngularMaterialThemeService
  ) {
    this.loanProductId = parseInt(this.actRoute.snapshot.params.id, 10);
  }

  ngOnInit(): void {
    this.getLoanProductInfo();
    this.getQuestions();
    this.getLoanProduct();
    const permissions: any = this.store.selectSnapshot(AuthState.user)?.permissionsList;
    this.hasWriteAccess =
      permissions.includes('loanProductQualifier.write') &&
      permissions.includes('manageLenders');

    this.hasLoanProductWriteAccess =
      permissions.includes('loanProduct.write') &&
      permissions.includes('manageLenders');
      //This is for the Credit Box Rules section

    this.loanProductService
      .getStates()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.states = res.data;
        },
        (res) => {
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message:
                'Error retrieving states. Adding a state qualifier will not work.',
            })
          );
        }
      );
  }

  /**
   * @description modal to provide additional warning before deleting the last base qualifier and all groups
   */
  openDeleteAllDialog(lastBaseQualifierToRemove: Qualifier) {
    const dialogRef = this.dialog.open(DeleteAllQualifiersDialogComponent, {
      width: '370px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteQualifier(lastBaseQualifierToRemove);
        this.removeAllGroupsAndChildren();
      }
    });
  }

  /**
   * @description modal to confirm that you want to toggle the credit box rules on
   */
  openConfirmCreditBoxRulesDialog(value: boolean) {
    const dialogRef = this.dialog.open(ConfirmCreditBoxRulesDialogComponent, {
      width: '420px',
      data: {
        toggleOn: value
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result){
        this.updateLoanProductIncludeInSubmissionStrategy(value);
      } else {
        this.isIncludedInSubmissionStrategy = !value;
      }
    });
  }

  /**
   * @description method to determine whether or not the response data is inactive
   */
  getLoanProductInfo(): void {
    this.loanProductService
      .getLoanProductInfo(this.loanProductId)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res?.data?.inactive) {
            this.isInactive = true;
          }
        },
        (res) => {
          this.errored = true;

          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Error retrieving loan product.',
            })
          );
        }
      );
  }

  getQuestions() {
    this.loanProductService
      .getQuestions()
      .pipe(take(1), retry(3))
      .subscribe(
        (res) => {
          this.errored = false;

          this.qualifierQuestions = res.data
            .filter((item) => {
              return item.filterable && !!item.allowedQualifiers;
            })
            .sort((a, b) => {
              return a.shortName > b.shortName ? 1 : -1;
            });

          this.infoQuestions = res.data.filter((item) => {
            return item.contractAskable || item.offerAskable;
          });
        },
        (res) => {
          this.errored = true;

          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Error retrieving loan product.',
            })
          );
        }
      );
  }

  /**
   * @description method to get and organize the loan product response data
   */
  getLoanProduct() {
    this.loanProductService.getLoanProduct(this.loanProductId).pipe(take(1)).subscribe((res) => {
      this.errored = false;
      this.loanProduct = res.data;
      this.isIncludedInSubmissionStrategy = this.loanProduct.isIncludedInSubmissionStrategy;
      this.loanProductQuestions = res.data.questions;
      if (this.loanProduct?.qualifier_exception_groups.length === 0) {
        this.loanProduct.qualifier_exception_groups.push({
          id: 0,
          conditions: [],
          exceptions: [],
          name: this.defaultGroupName,
          baseExceptionsCounter: 0
        });
      }

          this.requiredForContractOffer = res.data.questions.filter((item) => {
            return item.requiredForContract || item.requiredForOffer;
          });

          this.setDisabledValuesForBaseQualifiers();
          this.setOverridesForLoanProduct();
        },
        (res) => {
          this.errored = true;

          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Error retrieving questions.',
            })
          );
        }
      );
  }

  /**
   * @description generate the overrides map and counter based on the loan product on load
   */
  setOverridesForLoanProduct(): void {
    if (
      this.loanProduct?.qualifier_exception_groups &&
      this.loanProduct.qualifier_exception_groups.length > 0
    ) {
      this.loanProduct.qualifier_exception_groups.forEach((exceptionGroup: any) => {
        const tempMap = new Map();

        if (exceptionGroup.exceptions && exceptionGroup.exceptions.length > 0) {
          exceptionGroup.exceptions.forEach((exceptionQualifier: any) => {
            if (exceptionQualifier.overriddenBaseQualifierId) {
              tempMap.set(
                exceptionQualifier,
                exceptionQualifier.overriddenBaseQualifierId
              );
            }
          });
        }

        exceptionGroup.baseExceptionsMap = tempMap;
        exceptionGroup.baseExceptionsCounter = exceptionGroup.baseExceptionsMap
          ? exceptionGroup.baseExceptionsMap.size
          : 0;
      });
    }
  }

  /**
   * @description each time we modify a base qualifier or a group, recheck and update the overrides map and counter
   */
  checkQualifiersForOverrides(): void {
    if (
      this.loanProduct?.qualifier_exception_groups &&
      this.loanProduct.qualifier_exception_groups.length > 0
    ) {
      this.loanProduct.qualifier_exception_groups.forEach((individualGroup: any) => {
        const tempMap = new Map();

        if (
          individualGroup.exceptions &&
          individualGroup.exceptions.length > 0
        ) {
          individualGroup.exceptions.forEach((exceptionQualifier: any) => {
            this.loanProduct?.qualifiers.forEach((baseQualifier: any) => {
              if (
                exceptionQualifier.field === baseQualifier.field &&
                exceptionQualifier.filterType === baseQualifier.filterType
              ) {
                exceptionQualifier.overriddenBaseQualifierId = baseQualifier.id;
                tempMap.set(exceptionQualifier, baseQualifier.id);
              }
            });
          });
        }

        individualGroup.baseExceptionsMap = tempMap;
        individualGroup.baseExceptionsCounter =
          individualGroup.baseExceptionsMap
            ? individualGroup.baseExceptionsMap.size
            : 0;
      });
    }
  }

  /**
   * @description method responsible for setting and updating the disabled fields for base qualifiers
   */
  setDisabledValuesForBaseQualifiers(): void {
    if (
      this.loanProduct?.qualifiers &&
      this.loanProduct.qualifiers.length > 0
    ) {
      this.disabledBaseAttributeFields = [];
      this.disabledBaseOperatorFilterTypes = new Map<string, string>();

      this.loanProduct.qualifiers.forEach((qualifier: any) => {
        if (qualifier.filterType !== 'min' && qualifier.filterType !== 'max') {
          this.disabledBaseAttributeFields.push(qualifier.field);
        }

        if (this.disabledBaseOperatorFilterTypes && this.disabledBaseOperatorFilterTypes.size > 0 &&
          this.disabledBaseOperatorFilterTypes.has(qualifier.field)) {
          this.disabledBaseAttributeFields.push(qualifier.field);
          this.disabledBaseOperatorFilterTypes.delete(qualifier.field);
        } else if (qualifier.filterType === 'min') {
          this.disabledBaseOperatorFilterTypes.set(qualifier.field, 'min');
        } else if (qualifier.filterType === 'max') {
          this.disabledBaseOperatorFilterTypes.set(qualifier.field, 'max');
        }
      });
    }
  }

  /**
   * @description sends a post request creating a new qualifier.
   * @param qualifier - qualifier to add, important note, this qualifier doesn't have an id yet
   *                         after the qualifier is put in qualifiers through the loanProductService,
   *                         it is given an id. This is returned from the endpoint.
   */
  createQualifier(qualifier: any): void {
    if (qualifier.qualifierExceptionGroupId === 0) {
      this.pendingGroupQualifier = qualifier;
      this.createExceptionGroup();
      return;
    }
    this.addingQualifier = true;
    this.showBaseQualificationRequirementWarning = false;
    qualifier.loanProductId = this.loanProductId;
    this.loanProductService
      .postQualifier(qualifier)
      .pipe(take(1))
      .subscribe((res) => {
        qualifier.id = res.data.id;
        if (qualifier.qualifierExceptionGroupId) {
          this.loanProduct?.qualifier_exception_groups.forEach((group: any) => {
            if (group.id === qualifier.id) {
              if (qualifier.isExceptionCondition) {
                group.conditions.push(qualifier);
              } else {
                group.exceptions.push(qualifier);
              }
            }
          });
        } else {
          this.loanProduct?.qualifiers.push(qualifier);
        }
        this.checkQualifiersForOverrides();
      })
      .add(() => {
        this.setDisabledValuesForBaseQualifiers();
        this.addingQualifier = false;
      });
  }

  /**
   * @description sends a put request modifying a qualifier.
   */
  putQualifier(qualifierToUpdate: Qualifier | QualifierUnsaved | null = null): void {
    this.updatingQualifier = true;
    if (!qualifierToUpdate) {
      qualifierToUpdate = this.modifiedQualifier
    }
    // qualifierToUpdate = qualifierToUpdate
    //   ? qualifierToUpdate
    //   : this.modifiedQualifier;

    this.loanProductService.putQualifier(qualifierToUpdate!).pipe(take(1)).subscribe((res) => {
      if (this.modifiedQualifier) {
        this.loanProduct?.qualifiers.forEach((qualifier: any) => {
          if (qualifier.id === qualifierToUpdate?.id) {
            qualifier = qualifierToUpdate;
          }
        });
      } else {
        this.loanProduct?.qualifier_exception_groups.forEach((group: any) => {
          if (qualifierToUpdate?.isExceptionCondition) {
            group.conditions.forEach((condition: any) => {
              if (condition.id === qualifierToUpdate?.id) {
                condition = qualifierToUpdate;
              }
            });
          } else {
            group.exceptions.forEach((exception: any) => {
              if (exception.id === qualifierToUpdate?.id) {
                exception = qualifierToUpdate;
              }
            });
          }
        });
      }
        this.getLoanProduct();
        this.setDisabledValuesForBaseQualifiers();
        this.checkQualifiersForOverrides();
      })
      .add(() => {
        this.dirtyQualifierId = null;
        this.modifiedQualifier = null;
        this.updatingQualifier = false;
      });
  }

  /**
   * @description method to track and update the qualifier that is being modified
   *              (modified method of previous code for tracking dirty qualifiers)
   * @param changedQualifier - contains an isDirty flag, the new and old data associated with the qualifier id
   * @param qualifierId - id of qualifier
   */
  qualifierIsDirty(changedQualifier: QualifierChanged, qualifierId: number) {
    const isDirtyQualifier = this.dirtyQualifierId === qualifierId;
    if (changedQualifier.isDirty) {
      if (isDirtyQualifier) {
        const isModifiedQualifierTheChangedQualifier =
          this.modifiedQualifier!.id === changedQualifier!.data!.id;

        if (!isModifiedQualifierTheChangedQualifier) {
          this.modifiedQualifier = null;
        }

        if (changedQualifier.data) {
          this.modifiedQualifier = changedQualifier.data;
        }
      } else {
        const qualifiers = [...this.loanProduct.qualifiers];
        const qualifiersFallback = [...this.loanProduct.qualifiers];
        if (this.dirtyQualifierId && this.dirtyQualifierId > 0) {
          qualifiers.forEach((x, i) => {
            if (x.id !== qualifierId) {
              delete qualifiers[i];
            }
          });
          this.loanProduct.qualifiers = qualifiers;
          this.cdRef.detectChanges();
          this.loanProduct.qualifiers = qualifiersFallback;
          this.cdRef.detectChanges();
        }

        if (changedQualifier.data) {
            this.modifiedQualifier = changedQualifier.data;
        }
        this.dirtyQualifierId = qualifierId;
      }
      this.outdatedQualifier = changedQualifier.oldData;
    } else {
      if (isDirtyQualifier) {
        this.dirtyQualifierId = null;
        const isModifiedQualifierCurrentQualifier =
          this.modifiedQualifier?.id === qualifierId;

        if (!isModifiedQualifierCurrentQualifier) {
          this.modifiedQualifier = null;
        }
      }
    }

    if (this.dirtyQualifierId !== null) {
      this.isQualifierDirty = true;
    } else {
      this.isQualifierDirty = false;
    }

    if (this.modifiedQualifier !== null) {
      this.canSave = true;

      if (
        (!this.modifiedQualifier?.contains ||
          this.modifiedQualifier.contains.length === 0) &&
        (!this.modifiedQualifier?.in ||
          this.modifiedQualifier.in.length === 0) &&
        (!this.modifiedQualifier?.notIn ||
          this.modifiedQualifier.notIn.length === 0) &&
        !this.modifiedQualifier?.is &&
        !this.modifiedQualifier?.min &&
        !this.modifiedQualifier?.max
      ) {
        this.canSave = false;
      }
    }
  }

  /**
   * @description method to show update and cancel buttons only by current dirty qualifier
   * @param qualifierId - id of qualifier
   */
  showUpdateButtonByCurrentQualifier(qualifierId: number): boolean {
    return this.dirtyQualifierId === qualifierId;
  }

  /**
   * @description sends a delete request for a qualifier.
   * @param qualifierToRemove - the qualifier we are deleting
   */
  deleteQualifier(qualifierToRemove: Qualifier): void {
    this.loanProductService.deleteQualifier(qualifierToRemove.id).pipe(take(1)).subscribe((res) => {
      if (qualifierToRemove.qualifierExceptionGroupId) {
        this.loanProduct.qualifier_exception_groups.forEach((group: any) => {
          if (qualifierToRemove.isExceptionCondition) {
            group.conditions = group.conditions.filter((condition: any) => condition.id !== qualifierToRemove.id);
          } else {
            group.exceptions = group.exceptions.filter((exception: any) => exception.id !== qualifierToRemove.id);
          }
        });
      } else {
        this.loanProduct.qualifier_exception_groups.forEach((distinctGroup: any) => {
          if (distinctGroup.baseExceptionsMap) {
            for (const [exceptionQualifier, baseQualifierId] of distinctGroup.baseExceptionsMap) {
              if (baseQualifierId === qualifierToRemove.id) {
                distinctGroup.baseExceptionsMap.delete(exceptionQualifier);
                distinctGroup.baseExceptionsCounter = distinctGroup.baseExceptionsMap.size;
              }
            }
          }
        });

          this.loanProduct.qualifiers = this.loanProduct.qualifiers.filter(
            (qualifier: any) => qualifier.id !== qualifierToRemove.id
          );
        }
      })
      .add(() => {
        this.checkQualifiersForOverrides();
        this.setDisabledValuesForBaseQualifiers();
        this.updatingQualifierExceptionGroup = false;
      });
  }

  /**
   * @description method cancel any updates made to a base qualifier
   * @param qualifier: [Qualifier] qualifier that needs modifications canceled, reverted
   * @param type: [string] type of qualifier, 'base' or 'partOfExceptionGroup'
   */
  cancelQualifierUpdates(qualifier: Qualifier, type: string): void {
    if (type === 'base') {
      const baseQualifiers = [...this.loanProduct.qualifiers];
      const baseQualifiersIndex = baseQualifiers.findIndex((x) => x.id === qualifier.id);
      const q = baseQualifiers[baseQualifiersIndex];
      this.loanProduct.qualifiers.splice(baseQualifiersIndex, 1);
      this.cdRef.detectChanges();
      this.loanProduct.qualifiers.splice(baseQualifiersIndex, 0, q);
      this.cdRef.detectChanges();
    }

    const qualifiers = [...this.loanProduct.qualifiers];
    const index = qualifiers.findIndex((x) => x.id === qualifier.id);
    const currentQualifier = qualifiers[index];
    this.loanProduct.qualifiers.splice(index, 1);
    this.dirtyQualifierId = null;
    this.modifiedQualifier = null;
    this.isQualifierDirty = false;
    this.loanProduct.qualifiers.splice(index, 0, currentQualifier);
  }

  /**
   * @description method to cancel the values of a qualifier before saving,
   * used when in blank state for IF and THEN qualifiers from an exception group to reset to blank state on cancel
   */
  cancelSavingQualifier(): void {
    const fallbackBaseQualifiers = [...this.loanProduct.qualifiers];
    const exceptionGroups = [...this.loanProduct.qualifier_exception_groups];

    this.loanProduct.qualifiers = [];
    this.loanProduct.qualifier_exception_groups = [];

    this.cdRef.detectChanges();
    this.loanProduct.qualifiers = fallbackBaseQualifiers;
    this.setDisabledValuesForBaseQualifiers();
    this.loanProduct.qualifier_exception_groups = exceptionGroups;
    this.cdRef.detectChanges();
  }

  createExceptionGroup(): void {
    if (
      this.loanProduct.qualifiers &&
      this.loanProduct.qualifiers.length === 0
    ) {
      this.showBaseQualificationRequirementWarning = true;
    } else {
      this.showBaseQualificationRequirementWarning = false;
    }

    this.addingQualifierExceptionGroup = true;

    if (this.loanProduct && this.loanProduct.qualifier_exception_groups) {
      this.defaultGroupName = `GROUP ${
        this.loanProduct.qualifier_exception_groups.length + 1
      }`;
    }

    this.loanProductService.postQualifierExceptionGroup(this.loanProduct.lenderId, this.loanProductId, this.defaultGroupName)
      .pipe(take(1)).subscribe((res) => {
      if (this.pendingGroupQualifier !== null) {
        this.loanProduct.qualifier_exception_groups.forEach((group: any) => {
          if (group.id === 0) {
            group.id = res.data.id;
          }
        });
        this.pendingGroupQualifier.qualifierExceptionGroupId = res.data.id; // just update ID of existing empty group
        this.createQualifier(this.pendingGroupQualifier); // first group, add initial qualifier that is pending.
        this.pendingGroupQualifier = null;
      } else {
        const newGroup: any = {
          id: res.data.id,
          conditions: [],
          exceptions: [],
          name: this.defaultGroupName,
          baseExceptionsMap: new Map(),
          baseExceptionsCounter: 0
        };
        this.loanProduct.qualifier_exception_groups.push(newGroup);
      }

          this.addingQualifierExceptionGroup = false;
        },
        (res) => {
          this.addingQualifierExceptionGroup = false;
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Adding exception group failed',
            })
          );
        }
      );
  }

  putExceptionGroup(renamedGroup: QualifierExceptionGroup) {
    this.updatingQualifierExceptionGroup = true;
    this.loanProductService.putQualifierExceptionGroup({name: renamedGroup.name}, renamedGroup.id)
      .pipe(take(1)).subscribe((res) => {
      this.updatingQualifierExceptionGroup = false;
    }, (res) => {
      const groupIndex = this.loanProduct.qualifier_exception_groups
        .findIndex((exGroup: any) => exGroup.id === renamedGroup.id);

          if (groupIndex === -1) {
            return;
          }
          this.loanProduct.qualifier_exception_groups[groupIndex].name =
            res.data.name;
          renamedGroup.name = res.data.name;
          this.updatingQualifierExceptionGroup = false;
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Renaming exception group failed',
            })
          );
        }
      );
  }

  deleteExceptionGroup(exceptionGroupToRemove: QualifierExceptionGroup) {
    this.updatingQualifierExceptionGroup = true;

    if (exceptionGroupToRemove.index === -1) {
      return;
    }

    this.loanProduct?.qualifier_exception_groups.splice(exceptionGroupToRemove.index, 1);
    if (this.loanProduct.qualifier_exception_groups.length === 0) {
      this.loanProduct.qualifier_exception_groups.push({
        id: 0,
        conditions: [],
        exceptions: [],
        name: this.defaultGroupName,
        baseExceptionsCounter: 0,
      });
    }

    this.loanProductService.deleteQualifierExceptionGroup(exceptionGroupToRemove.id).pipe(take(1)).subscribe((res) => {
      this.updatingQualifierExceptionGroup = false;
    }, (res) => {
      this.updatingQualifierExceptionGroup = false;
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Removing exception group failed, try refreshing!'
      }));
    });
  }

  /**
   * @description function used to iterate through all of our qualifierExceptionGroups
   * and delete all of the groups and their children from our local lists and from the db
   */
  removeAllGroupsAndChildren(): void {
    if (
      this.loanProduct.qualifier_exception_groups &&
      this.loanProduct.qualifier_exception_groups.length > 0
    ) {
      const tempGroups = [...this.loanProduct.qualifier_exception_groups];
      tempGroups.forEach(remainingGroup => {
        if (remainingGroup.id !== 0) {
          this.deleteExceptionGroup(remainingGroup);
        }
      });
      this.loanProduct.qualifier_exception_groups = [
        {
          id: 0,
          conditions: [],
          exceptions: [],
          name: this.defaultGroupName,
          baseExceptionsCounter: 0,
        },
      ];
    }
  }

  updateProductQuestions(lpQuestions: LoanProductQuestion[]): void {
    this.loanProductQuestions = null;
    this.requiredForContractOffer = null;
    this.loanProduct = null;

    this.loanProductService.putQuestions(this.loanProductId, lpQuestions).pipe(take(1)).subscribe((res) => {
      // Success, nothing further necessary
      this.getLoanProduct();
    }, (res) => {
      // Failed, revert
      this.getLoanProduct();
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Adding requirements failed'
      }));
    });
  }

  updateLoanProductIncludeInSubmissionStrategy(value: boolean) {
    this.loanProduct.isIncludedInSubmissionStrategy = value;
    let payload = {
      'isIncludedInSubmissionStrategy': value
    };
    this.loanProductService
      .patchLoanProduct(this.loanProductId, payload)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.getLoanProduct();
        },
        (res) => {
          // Failed, revert
          this.loanProduct.isIncludedInSubmissionStrategy = !value;
          this.isIncludedInSubmissionStrategy = !value;
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Toggling Credit Box Rules failed',
            })
          );
        }
      );
  }

  updateRestrictionNotes(newNotes: string) {
    this.loanProduct.restrictionNotes = newNotes;
    let payload = {
      'restrictionNotes': newNotes
    };
    this.loanProductService
      .patchLoanProduct(this.loanProductId, payload)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.getLoanProduct();
        },
        (res) => {
          // Failed, revert
          this.loanProduct.restrictionNotes = !newNotes;
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'saving new restriction note failed',
            })
          );
        }
      );
  }

  addRequirements(payload: []): void {
    this.updateProductQuestions(payload);
  }

  toggleRequiredForOffer(payload: { field: string; fields: any[]; requiredForOffer: boolean; }): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const lpQuestions = [...this.loanProductQuestions];
    const index = lpQuestions.findIndex((item) => item.field === payload.field);
    if (index !== -1) {
      if (payload.fields && payload.fields.length !== 0) {
        payload.fields.forEach((field) => {
          const i = lpQuestions.findIndex((item) => item.field === field);
          lpQuestions[i].requiredForOffer = payload.requiredForOffer;
        });
      } else {
        lpQuestions[index].requiredForOffer = payload.requiredForOffer;
      }
    }
    this.updateProductQuestions(lpQuestions);
  }

  removeRequirement(payload: RemoveRequirementPayload) {
    const fallbackQuestions = [...this.loanProductQuestions];

    // Eagerly update
    this.loanProductQuestions = null;
    this.requiredForContractOffer = null;

    this.loanProductService
      .deleteQuestions(this.loanProductId, payload)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.getLoanProduct();
        },
        (res) => {
          // Failed, revert
          this.loanProductQuestions = fallbackQuestions;
          this.store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Removing requirement failed',
            })
          );
        }
      );
  }
}
