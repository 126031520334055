import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class PageTitleService extends TitleStrategy {

  constructor(private readonly _pageTitle: Title) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot) {
    // This is for the title shown in the browser tab, not the app
    const pageTitle = this.buildTitle(snapshot);
    if (pageTitle !== undefined) {
      this._pageTitle.setTitle(`Partner Portal - ${pageTitle}`);
    }
  }
}
