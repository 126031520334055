<div style="height: 48px;">
  <button mat-flat-button color="primary"
          *ngIf="!addingBaseQualifier && hasWriteAccess"
          (click)="addingBaseQualifier = true"
          [disabled]="!addingBaseQualifier && this.qualifiers && this.qualifiers.length === 0"
          data-cy="qualifier-exception-groups-component-add-base-qualifier">
    Add Base Qualifier
  </button>
  <div class="" *ngIf="addingBaseQualifier && this.qualifiers && this.qualifiers.length > 0">
    <app-qualifier (cancel)="addingBaseQualifier = false"
                   (save)="save($event)"
                   [disabledAttributeFields]="disabledBaseAttributeFields"
                   [disabledOperatorFilterTypes]="disabledBaseOperatorFilterTypes"
                   [questions]="questions"
                   [states]="states"
                   [loanProductId]="loanProductId"
                   [hasWriteAccess]="hasWriteAccess"
                   [qualifiers]="qualifiers">
    </app-qualifier>
  </div>
</div>

