<div class="lendio-density-2">
    <mat-form-field
        *appLet="(errored$ | async) as errored"
        appearance="outline">
        <mat-icon
            matPrefix
            [color]="errored ? 'warn' : ''"
            [matTooltip]="errored ? 'There was an error while attempting to search. Please try again.' : ''"
            matTooltipPosition="below">
            <span>{{ errored ? 'warning' : 'search' }}</span>
        </mat-icon>
        <input matInput
               #searchInput
               [(ngModel)]="searchTerm"
               [matAutocomplete]="resultsAutocomplete"
               (keyup)="handleKeyup($event)"
               placeholder="Search businesses..."
               autocomplete="off"
               type="text"/>

            <div class="w-6 !mr-3" matSuffix>
                <mat-spinner *ngIf="(loadingState$ | async) else clearButton"
                             class="!mr-3"
                             [style]="{'--mdc-circular-progress-active-indicator-color': '#1062d5'}"
                             [diameter]="24"></mat-spinner>
                <ng-template #clearButton>
                    <button mat-icon-button
                            class="!pr-2"
                            *ngIf="searchTerm?.length > 1"
                            (click)="$event.stopPropagation(); $event.preventDefault(); resetSearch();">
                        <mat-icon [fontSet]="'material-icons-outlined'"
                                  [color]="'accent'">
                            cancel
                        </mat-icon>
                    </button>

                </ng-template>
            </div>

        <mat-autocomplete
            #resultsAutocomplete="matAutocomplete"
            [hideSingleSelectionIndicator]="true"
            [class]="'!max-h-[456px] py-2'"
        >
            <!--    RECENT SEARCHES    -->
            <ng-container *ngIf="!searchTerm?.length && (showRecentSearchState$ | async)">
                <div class="grid grid-flow-col justify-between place-items-center px-4 py-2 lendio-density-1">
                    <h2 class="!mb-0">Recent searches</h2>
                    <a class="mat-body-2 !text-lendio-blue-400 align-middle cursor-pointer"
                       (click)="$event.preventDefault(); $event.stopPropagation(); clearRecentSearches();">
                        Clear
                    </a>
                </div>
                <mat-option
                    *ngFor="let search of (recentSearches$ | async)"
                    (click)="handleRecentSearchClick(search.term);"
                >
                    <div class="mat-body-2">{{ search.term }}</div>
                    <div class="mat-caption text-lendio-cool-gray-300">{{ search.timestamp | timeago }}</div>
                </mat-option>
            </ng-container>

            <!-- RESULTS -->
            <mat-option
                *ngFor="let result of (results$ | async)"
                (click)="handleResultClick(result.resourceId)">
                <div class="mat-body-2" [innerHTML]="result.title"></div>
                <div *ngIf="result.subTitle" class="mat-caption text-lendio-cool-gray-300" [innerHTML]="result.subTitle"></div>
            </mat-option>

            <!-- No results -->
            <mat-option disabled *ngIf="(noResultsState$ | async) && !!searchTerm?.length">
                No results for '{{ searchTerm }}'
            </mat-option>

        </mat-autocomplete>
    </mat-form-field>
</div>
