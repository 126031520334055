interface LendioBreakpoints {
  extraSmall: string;
  small: string;
  medium: string;
  large: string;
  extraLarge: string;
}


export const LENDIO_BREAKPOINTS: LendioBreakpoints = {
  extraSmall: '(max-width: 429.98px)',
  small: '(min-width: 430px) and (max-width: 768.98px)',
  medium: '(min-width: 769px) and (max-width: 1023.98px)',
  large: '(min-width: 1024px) and (max-width: 1439.98px)',
  extraLarge: '(min-width: 1440px)'
}
