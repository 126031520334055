import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { DocumentUploadSmartComponent } from './document-upload-smart/document-upload-smart.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";

@NgModule({
  declarations: [
    DocumentUploadComponent,
    DocumentUploadSmartComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    LoadersModule,
    MatChipsModule,
    MatIconModule
  ],
  exports: [
    DocumentUploadSmartComponent
  ]
})
export class DocumentUploadModule { }
