import {Optional} from '@angular/core';
import {Deal} from './deal.model';

export class SbaStatuses {

  constructor(@Optional() private fromList = true) {
  }

  'Approved by SBA': SbaStatusObject = {
    title: this.fromList ? 'Approved by SBA' : 'Approved',
    class: null,
    tooltip: 'This application has been approved by the SBA and a PLP number has been assigned.',
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: true
  };

  'Not Approved by SBA': SbaStatusObject = {
    title: this.fromList ? 'Declined by SBA' : 'Declined',
    class: null,
    tooltip: null,
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: false
  };

  'Under Review': SbaStatusObject = {
    title: 'Under Review',
    class: null,
    tooltip: 'Successfully received by E-Tran and being reviewed by the SBA.',
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: false
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  'Queued': SbaStatusObject = {
    title: 'Queued',
    class: null,
    tooltip: 'Queued for submission to the SBA.',
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: false
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  'Failed to Connect': SbaStatusObject = {
    title: 'Failed to Connect',
    class: 'text-red-600',
    tooltip: 'Failed to connect to the SBA. Please try again in a few minutes. ',
    reasons: [],
    canSubmit: true,
    showReasons: false,
    showForm: false,
    showDetails: false
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  'Duplicate Submission': SbaStatusObject = {
    title: 'Duplicate Submission',
    class: null,
    tooltip: 'The SBA reported this application as a duplicate submission.',
    reasons: [],
    canSubmit: true,
    showReasons: false,
    showForm: false,
    showDetails: false
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  'Failed Validation': SbaStatusObject = {
    title: 'Failed Validation',
    class: 'text-red-600',
    tooltip: 'Validation failed. Review validation errors.',
    reasons: [],
    canSubmit: true,
    showReasons: false,
    showForm: true,
    showDetails: false
  };

  'Further Research Required': SbaStatusObject = {
    title: 'On Hold',
    class: 'text-red-600',
    tooltip: 'Issues with a prior SBA application have put this application into \'On Hold\' status.',
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: true
  };

  'Withdrawn': SbaStatusObject = {
    title: 'Withdrawn from SBA',
    withdrawn: null,
    class: null,
    tooltip: 'This submission has been withdrawn from the SBA platform.',
    reasons: [],
    canSubmit: true,
    showReasons: true,
    showForm: false,
    showDetails: false
  };

  'Canceled': SbaStatusObject = {
    title: 'Loan Canceled',
    withdrawn: null,
    class: null,
    tooltip: 'This submission has been withdrawn from the SBA platform.',
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: true
  };

  'Api Error': SbaStatusObject = this['Failed to Connect'];

  'System Error': SbaStatusObject = this['Failed to Connect'];

  'Pending Validation': SbaStatusObject = {
    title: 'Submitted',
    class: null,
    tooltip: 'Sent to the SBA',
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: false
  };

  unvalidated: SbaStatusObject = {
    title: 'Payroll Unvalidated',
    class: null,
    tooltip: 'Payroll validation must take place before this can be submitted.',
    reasons: [],
    canSubmit: false,
    showReasons: false,
    showForm: false,
    showDetails: false
  };

  ready: SbaStatusObject = {
    title: 'Ready to Submit',
    class: 'text-orange-400',
    tooltip: 'This application is ready to submit.',
    reasons: [],
    canSubmit: true,
    showReasons: false,
    showForm: true,
    showDetails: false
  };

  incomplete: SbaStatusObject = {
    title: 'Incomplete',
    class: 'text-red-600',
    tooltip: 'This file is missing required information that must be corrected before submission.',
    reasons: [],
    canSubmit: false,
    showReasons: true,
    showForm: true,
    showDetails: false
  };

  private static createBaseObject(): SbaStatusObject {
    return {
      title: 'Unknown',
      class: null,
      tooltip: 'Unable to retrieve status.',
      reasons: [],
      canSubmit: false,
      showReasons: false,
      showForm: true,
      showDetails: false
    };
  }

  public getStatusObject(deal: Deal) {
    const returnObject = !this.fromList ? this.getFromDetails(deal) : this.getFromList(deal);

    returnObject.reasons = this.getReasons(deal);

    if (deal.status === 'dead' || deal.stage === 'funded' || deal.etranConfirmationId) {
      returnObject.showForm = false;
    }

    return returnObject;
  }

  public getReasons(deal: any): [] {
    if (!this.fromList) {
      return deal.isWalleReady.reasons;
    }

    if (!deal.reasons || deal.reasons === '') {
      return [];
    }

    return deal.reasons.split(',');
  }

  private getFromList(deal: any): SbaStatusObject {
    let returnObject = SbaStatuses.createBaseObject();
    const isWithdrawn = !!deal.withdrawn;

    if (isWithdrawn) {
      /* eslint-disable */
      returnObject = (
        deal.sbaLoanNumber || deal.sbaStatus === 'Approved by SBA'
      ) ? this['Canceled'] : this['Withdrawn']; /* eslint-enable */
      returnObject.withdrawn = deal.sbaStatus.withdrawn;
    } else if (deal.sbaLoanNumber && !isWithdrawn) {
      returnObject = this['Approved by SBA'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (deal.sbaStatus && this[deal.sbaStatus]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      returnObject = this[deal.sbaStatus];
    } else if (!deal.isWalleReady && !deal.etranConfirmationId) {
      returnObject = this.incomplete;
    } else if (deal.labelArray.findIndex((item: { name: string; }) => item.name === 'Payroll Unvalidated') !== -1) {
      returnObject = this.unvalidated;
    } else if (deal.isWalleReady && !deal.etranConfirmationId) {
      returnObject = this.ready;
    }

    return returnObject;
  }

  private getFromDetails(deal: any): SbaStatusObject {
    let returnObject = SbaStatuses.createBaseObject();

    const isWithdrawn = deal.sbaStatus && !!deal.sbaStatus.withdrawn;

    if (isWithdrawn) {
      /* eslint-disable */
      returnObject = (
        deal.sbaLoanNumber ||
        deal.sbaStatus && deal.sbaStatus.sbaLoanNumber ||
        deal.sbaStatus && deal.sbaStatus.sbaStatus === 'Approved by SBA'
      ) ? this['Canceled'] : this['Withdrawn']; /* eslint-enable */
      returnObject.withdrawn = deal.sbaStatus.withdrawn;
    } else if (deal.sbaLoanNumber || (deal.sbaStatus && deal.sbaStatus.sbaLoanNumber)) {
      returnObject = this['Approved by SBA'];
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (deal.sbaStatus && deal.sbaStatus.sbaStatus && this[deal.sbaStatus.sbaStatus]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        returnObject = this[deal.sbaStatus.sbaStatus];
      } else if (!deal.isWalleReady.isReady && (!deal.sbaStatus || !deal.sbaStatus.confirmationId)) {
        returnObject = this.incomplete;
      } else if (deal.lender_labels.findIndex((item: { name: string; }) => item.name === 'Payroll Unvalidated') !== -1) {
        returnObject = this.unvalidated;
      } else if (deal.isWalleReady.isReady && (!deal.sbaStatus || !deal.sbaStatus.confirmationId)) {
        returnObject = this.ready;
      }
    }

    return returnObject;
  }
}

export interface SbaStatusObject {
  title: string;
  class: string | null;
  tooltip: string | null;
  withdrawn?: string | null;
  reasons: [];
  canSubmit: boolean;
  showReasons: boolean;
  showForm: boolean;
  showDetails: boolean;
}
