import { ErrorHandler, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { MessagePayload } from '@thoughtspot/visual-embed-sdk';

@Injectable({
  providedIn: 'root'
})
export class ThoughtspotErrorHandleService implements ErrorHandler {
  handleError(error: any): void {
    if (error instanceof HttpErrorResponse && error.status === 503) {
      throw new ServiceUnavailableError(error);
    } else {
      /**
       * todo: handle other errors:
       *  - auth errors
       *  - Thoughtspot has a MessagePayload type
       **/
    }
  }
}

export class ServiceUnavailableError extends Error {
  constructor(public originalError: HttpErrorResponse) {
    super('Service Unavailable');
  }
}
