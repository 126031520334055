import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwnersSmartComponent } from './owners-smart/owners-smart.component';
import { OwnersComponent } from './owners/owners.component';
import { EditOwnerDialogComponent } from './edit-owner-dialog/edit-owner-dialog.component';
import { CreateOwnerDialogComponent } from './create-owner-dialog/create-owner-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { DeleteOwnerDialogComponent } from './delete-owner-dialog/delete-owner-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    OwnersSmartComponent,
    OwnersComponent,
    EditOwnerDialogComponent,
    CreateOwnerDialogComponent,
    DeleteOwnerDialogComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule
  ],
  exports: [
    OwnersSmartComponent
  ]
})
export class OwnersModule { }
