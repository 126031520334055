import { AgChartTheme } from "ag-grid-community";

export const LendioChartsTheme: AgChartTheme = {
  baseTheme: 'ag-vivid',
  palette: {
    fills: [
      '#29b6f6',
      '#0082FF',
      '#DBEAFC',
      '#004AB7',
    ],
    strokes: ['#FFFFFF'],
  },
  overrides: {
    line: {
      background: {
        visible: false
      },
      legend: {
        enabled: true,
        position: 'bottom'
      },
      series: {
        marker: {
          enabled: true,
          shape: 'circle',
          size: 12,
          strokeWidth: 4,
        },
        highlightStyle: {
          item: {
            fill: '#405972',
            stroke: '#0082FF',
            fillOpacity: 0.8,
          }
        },
      },
      axes: {
        category: {
          label: {
            formatter: () => ''
          }
        }
      }
    },
    pie: {
      background: {
        visible: false
      },
      legend: {
        enabled: true,
        position: 'bottom'
      },
      series: {
        fillOpacity: 0.9,
        strokeOpacity: 0.9,
        strokeWidth: 2,
        shadow: {},
        highlightStyle: {
          item: {
            fill: '#405972',
            stroke: '#0082FF',
            fillOpacity: 0.8,
          }
        },
        title: {
          enabled: false,
          fontSize: 22,
          fontFamily: 'proxima-soft, Helvetica, Arial, sans-serif'
        },
      }
    }
  }
}
