import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RestrictedInputComponent } from './restricted-input.component';

@NgModule({
  declarations: [ RestrictedInputComponent ],
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    MatIconModule
  ],
  exports: [
    RestrictedInputComponent,
  ]
})
export class RestrictedInputModule { }
