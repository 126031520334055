
<ng-template #details>
    <div *ngIf="!(loading$ | async) || isLoaded" class="m-4">
        <mat-card appearance="outlined" class="mb-4">
            <mat-card-header class="text-lg font-bold">
                <mat-card-title>Details</mat-card-title>
            </mat-card-header>
            <div class="lendio-mat-card-divider"></div>
            <mat-card-content>
                <div class="flex justify-between gap-2">
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Date & Time</p>
                        <p class="text-sm font-normal !mr-2">{{ created$ | async | date:'MMMM d, y \'at\' h:mm a' }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Imported by</p>
                        <p class="text-sm font-normal !mr-2">{{ (importedBy$ | async) | defaultValue }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Type</p>
                        <p class="text-sm font-normal">{{ (type$ | async) === 'bankAccounts' ? 'Bank Accounts' : (type$ | async) === 'borrowers' ? 'Businesses' : (type$ | async) | titlecase }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Status</p>
                        <p class="text-sm font-normal">{{ (status$ | async) ==='awaitingFile' ? 'Awaiting file' : (status$ | async) | titlecase }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Total Records</p>
                        <p class="text-sm font-normal">{{ (totalRecords$ | async) === null ? 0 : (totalRecords$ | async) | number }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Updated Records</p>
                        <p class="text-sm font-normal">{{ (updatedRecords$ | async) === null ? 0 : (updatedRecords$ | async) | number }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Imported Records</p>
                        <p class="text-sm font-normal">{{ (importedRecords$ | async) === null ? 0 : (importedRecords$ | async) | number }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Skipped Records</p>
                        <p class="text-sm font-normal">{{ (skippedRecords$ | async) === null ? 0 : (skippedRecords$ | async) | number }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Failed Records</p>
                        <p class="text-sm font-normal">{{ (failedRecords$ | async) === null ? 0 : (failedRecords$ | async) | number }}</p>
                    </div>
                    <div class="w-32">
                        <p class="text-sm font-bold !m-0">Description</p>
                        <p class="whitespace-normal font-normal text-sm">{{ (description$ | async) | truncate:75 }}</p>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined">
            <mat-card-header class="justify-between items-start flex">
                <mat-card-title>Records</mat-card-title>
                <div class="lendio-density-2">
                    <mat-form-field [floatLabel]="'auto'" appearance="outline">
                        <mat-select placeholder="All Statuses" [(ngModel)]="filterBy" (selectionChange)="handleSortEvent($event)">
                            <mat-option *ngFor="let type of filterTypes" [value]="type.value">{{ type.key }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-header>
                <mat-card-content>
                    <table mat-table
                            [dataSource]="(tableDataSource$ | async)"
                            matSort
                            (matSortChange)="handleSortEvent($event)">
                        <mat-header-row *matHeaderRowDef="getColumnsToDisplay((type$ | async))"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: getColumnsToDisplay((type$ | async))"></mat-row>

                        <ng-container *ngIf="(type$ | async) === 'borrowers' || (type$ | async) === 'contacts'" matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                            <ng-container *ngIf="(type$ | async ) === 'borrowers'">
                                <mat-cell *matCellDef="let data">
                                    <span class="cell-no-wrap">
                                        {{(data.entity !== null || data.entity?.name !== null ? data.entity?.name : '-') | titlecase }}
                                    </span>
                                </mat-cell>
                            </ng-container>
                            <ng-container *ngIf="(type$ | async ) === 'contacts'">
                                <mat-cell *matCellDef="let data">{{(data.entity !== null || data.entity?.first !== null ? data.entity?.first + ' ' + data.entity?.last : 'unknown') | titlecase }}</mat-cell>
                            </ng-container>
                        </ng-container>

                        <ng-container matColumnDef="externalId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>External ID</mat-header-cell>
                            <mat-cell *matCellDef="let data">
                                <span class="cell-no-wrap">
                                    {{(data.externalId !== null ? data.externalId : '-')}}
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="result">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Result</mat-header-cell>
                            <mat-cell *matCellDef="let data">
                                <div class="pr-2 pt-1">
                                    <mat-icon class="material-symbols-outlined green-check" *ngIf="data.result === 'imported'" fontSet="material-symbols-outlined">done</mat-icon>
                                    <mat-icon class="material-symbols-outlined blue-check" *ngIf="data.result === 'updated'" fontSet="material-symbols-outlined">done</mat-icon>
                                    <mat-icon class="material-symbols-outlined red-close" *ngIf="data.result === 'failed'"  fontSet="material-symbols-outlined">close</mat-icon>
                                    <mat-icon class="material-symbols-outlined gray-skipped" *ngIf="data.result === 'skipped'"  fontSet="material-symbols-outlined">step_over</mat-icon>
                                </div>
                                {{data.result | titlecase }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="errors">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Reason</mat-header-cell>
                            <mat-cell *matCellDef="let data">
                                <span class="cell-no-wrap">
                                    {{(data.errors !== null ? getFormattedErrors(data.errors) : '-')}}
                                </span>
                                <a class="text-blue-500 pl-1 hover:cursor-pointer pt-5 mr-4" *ngIf="data.errors?.length >= 66" (click)="openFailedEntityDialog(data)">more</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="entityId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Internal ID</mat-header-cell>
                            <mat-cell *matCellDef="let data">{{(data.entityId !== null || data.entityId == 'undefined' ? data.entityId : '-')}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="entityData">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let data">
                                <a class="text-blue-500 hover:cursor-pointer" (click)="openEntityDialog(data)">View data</a>
                            </mat-cell>
                        </ng-container>
                        <tr class="mat-row" *matNoDataRow>
                            No records to show
                        </tr>
                    </table>

                    <lendio-paginator #paginator
                        *ngIf="(tableDataSource$ | async)?.length !== 0"
                        [filterBy]="filterBy$ | async"
                        (page)="handlePageEvent($event)"
                        [length]="total$ | async"
                        [pageSize]="pageSize$ | async"
                        [pageSizeOptions]="pageSizeOptions"
                        [pageIndex]="pageIndex$ | async"
                        [showFirstLastButtons]="true">
                    </lendio-paginator>
                </mat-card-content>
        </mat-card>
    </div>
</ng-template>

<div class="text-center loading" *ngIf="(loading$ | async) else details">
    <app-dot-loader-scale></app-dot-loader-scale>
</div>
