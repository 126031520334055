import { Component, OnInit, Input, computed } from '@angular/core';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';
import { Label } from '../../../../interfaces/label.model';
import { PortalUser } from '../../../../interfaces/portal-user.model';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {

  @Input() applicationDetails: ApplicationDetails;
  @Input() loanProductCategory: string;
  @Input() isRenewal: boolean;
  @Input() lenderLabels: Label[];
  @Input() portalUsers: PortalUser[];

  public assignmentArray: [] = [];

  declinePages = ['new-apps', 'offer-sent', 'contract-sent', 'contract-signed'];
  newOfferPages = ['new-apps', 'offer-sent'];
  pppActionPages = ['new-apps', 'completed-apps', 'contract-sent', 'contract-signed', 'offer-sent', 'offer-back'];

  shouldDisplayDeclineBtn: boolean;
  shouldDisplayMakeOfferBtn: boolean;
  shouldDisplayPPPActions: boolean;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) { }

  ngOnInit(): void {
    this.shouldDisplayDeclineBtn = this.declinePages.indexOf(this.applicationDetails.page) !== -1;
    this.shouldDisplayMakeOfferBtn = this.newOfferPages.indexOf(this.applicationDetails.page) !== -1;
    this.shouldDisplayPPPActions = this.pppActionPages.indexOf(this.applicationDetails.page) !== -1;
    // Always need to pass at least an empty array in to the assignment dropdown
    if (this.applicationDetails.deal && this.applicationDetails.deal.assignments) {
      this.assignmentArray = JSON.parse(this.applicationDetails.deal.assignments);
    }
    else {
      this.assignmentArray = [];
    }
  }

  formatForDisplay(words: string) {
    return words.replace(/([a-z](?=[A-Z]))/g, '$1 ');
  }
}
