<mat-card appearance="outlined"
          class="lendio-rep"
          [class.old-theme-lendio-rep]="oldThemeEnabled()"
          *ngIf="isLendioTeamLead && applicationDetails && applicationDetails.teamLead">
	<div class="row">
		<div class="col">
			<span *ngIf="applicationDetails.teamLead.user.gravatar; else noAvatar">
				<img class="avatar-circle" src="{{applicationDetails.teamLead.user.gravatar}}" alt="avatar" />
			</span>
			<ng-template #noAvatar>
				<span class="initials">{{ applicationDetails.teamLead.user.first }} {{ applicationDetails.teamLead.user.last }}</span>
			</ng-template>
		</div>
		<div class="col">
			<div class="rep-details">
				<h3 class="no-margin bold">Lendio Team Lead</h3>
				<h4 class="no-margin">{{applicationDetails.teamLead.user.first}} {{applicationDetails.teamLead.user.last}}</h4>
				<a href="mailto:{{ applicationDetails.teamLead.user.email }}" class="email-address no-margin">{{ applicationDetails.teamLead.user.email }}</a>
			</div>
		</div>
	</div>
</mat-card>
<mat-card appearance="outlined"
          class="lendio-rep"
          [class.old-theme-lendio-rep]="oldThemeEnabled()"
          *ngIf="isFundingDeskRep && applicationDetails && applicationDetails.fundingDesk">
	<div class="row">
		<div class="col">
			<span *ngIf="applicationDetails.fundingDesk.gravatar; else noAvatar">
				<img class="avatar-circle" src="{{applicationDetails.lendioRep.gravatar}}" alt="avatar" />
			</span>
			<ng-template #noAvatar>
				<span class="initials">{{ applicationDetails.fundingDesk.first }} {{ applicationDetails.fundingDesk.last }}</span>
			</ng-template>
		</div>
		<div class="col">
			<div class="rep-details">
				<h3 class="no-margin bold">Funding Desk</h3>
				<h4 class="no-margin">{{applicationDetails.fundingDesk.first}} {{applicationDetails.fundingDesk.last}}</h4>
				<a href="mailto:{{ applicationDetails.fundingDesk.email }}" class="email-address no-margin">{{ applicationDetails.fundingDesk.email }}</a>
			</div>
		</div>
	</div>
</mat-card>
