import { Injectable } from '@angular/core';
import { ListSortData } from '../interfaces/list-sort.model';
import { findIndex } from 'lodash';
import { LocalStorageRefService } from './local-storage-ref.service';

const KEY = 'lendioColumnMatSorting';

@Injectable({
  providedIn: 'root'
})
export class ColumnSortService {

  private colObjectData: ListSortData[];

  constructor(private localStorageService: LocalStorageRefService) {
    this.colObjectData = JSON.parse(this.localStorageService.localStorage.getItem(KEY) || '[{}]');
  }

  getColumnSortByPath(path: string = window.location.pathname): ListSortData {
    const listData = this.colObjectData.find(col => col.path = path);
    return listData || {
      path,
      active: '',
      direction: ''
    };
  }

  setColumnSort(sort: ListSortData): ListSortData {
    const listData = this.colObjectData.find(col => col.path === sort.path);
    
    var index = findIndex(this.colObjectData, sort);

    var newSortObj = this.colObjectData.splice(index, 1, sort);

    this.localStorageService.localStorage.setItem(KEY, JSON.stringify(newSortObj));
    return sort;
  }

  setColumnSorts(sorts: ListSortData[]): ListSortData[] {
    this.localStorageService.localStorage.setItem(KEY, JSON.stringify(sorts));
    return sorts;
  }
}
