import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  LendioResponse,
  ObsLendioResponse,
  ObsPaginatedLendioResponse,
  PaginatedLendioResponse
} from '@app/app/interfaces/lendio-response';
import { Integration, IntegrationActivity } from '@app/app/interfaces/integration.model';
import {IntegrationCredentials} from "@app/app/interfaces/integrations/integration-credentials.model";

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  private baseUrl = environment.apiUrl + '/lender-portal/integrations';

  constructor(
    private httpClient: HttpClient,
  ) {}

  getIntegrations(): ObsLendioResponse<Integration[]> {
    return this.httpClient.get<LendioResponse<Integration[]>>(this.baseUrl);
  }

  getIntegrationDetail(customerIntegrationId: number): ObsLendioResponse<Integration> {
    return this.httpClient.get<LendioResponse<Integration>>(`${this.baseUrl}/${customerIntegrationId}`)
  }

  getIntegrationActivities(customerIntegrationId: number, page: number, pageSize: number): ObsPaginatedLendioResponse<IntegrationActivity> {
    const params =
        {
          'page': page + 1,
          'limit': pageSize,
        };
    return this.httpClient.get<PaginatedLendioResponse<IntegrationActivity>>(`${this.baseUrl}/${customerIntegrationId}/activity`, {params})
  }

  updateIntegrationCredentials(customerIntegrationId: number, credentials: IntegrationCredentials): ObsLendioResponse {
    return this.httpClient.put<LendioResponse>(`${this.baseUrl}/${customerIntegrationId}/secrets`, {
      credentials,
    });
  }

  createIntegrationCredentials(customerIntegrationId: number, credentials: IntegrationCredentials): ObsLendioResponse {
    return this.httpClient.post<LendioResponse>(`${this.baseUrl}/${customerIntegrationId}/secrets`, {
      credentials,
    });
  }

  /**
   * Gets a masked preview version of the current integration credentials for a given
   * Customer Integration. Does NOT fetch the full credentials value, and never should.
   * @param customerIntegrationId
   */
  getIntegrationCredentials(customerIntegrationId: number): ObsLendioResponse {
    return this.httpClient.get<LendioResponse>(`${this.baseUrl}/${customerIntegrationId}/secrets`);
  }

}
