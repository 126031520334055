    <div>
        <h2 class="mat-headline-4 !m-0 !mb-4">Choose a strong password.</h2>
        <p class="mat-body-1">Your password must be at least 14 characters long, with at least 1 number and 1 uppercase letter.</p>
    </div>
    <div *ngIf="showForm">
        <form [formGroup]="resetForm" (ngSubmit)="submit()" class="reset-password-form mt-4">

            <!-- token -->
            <mat-form-field class="{{ givenToken ? '!hidden' : '' }}">
                <mat-label>Token</mat-label>
                <input formControlName="token"
                required
                name="token"
                matInput
                type="text">
            </mat-form-field>

            <!-- email address -->
            <mat-form-field class="{{ givenEmail ? '!hidden' : '' }}">
                <mat-label>Email Address</mat-label>
                <input formControlName="email"
                required
                name="email"
                matInput
                placeholder="Ex. email@domain.com"
                email
                type="email">
            </mat-form-field>
            <mat-error *ngIf="email.dirty && email.errors && email.errors.required && !givenEmail" [@fadeIn]>
                Email address is required.
            </mat-error>
            <mat-error *ngIf="email.dirty && email.errors && email.errors.email && !givenEmail" [@fadeIn]>
                Please enter a valid email address.
            </mat-error>

            <!-- password -->
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Create Password</mat-label>
                <input formControlName="password"
                required
                name="password"
                matInput
                type="{{ showPassword ? 'text' : 'password' }}">
                <mat-icon matSuffix (click)="showPassword = !showPassword" class="cursor-pointer">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </mat-form-field>
            <mat-error class="mat-caption pl-2" *ngIf="password.dirty && password.errors && password.errors.required" [@fadeIn]>Please enter a valid password.</mat-error>
            <mat-error class="mat-caption pl-2" *ngIf="password.dirty && password.errors && password.errors.minlength" [@fadeIn]>Password must be at least 14 characters.</mat-error>
            <mat-error class="mat-caption pl-2" *ngIf="password.dirty && password.errors && password.errors.complexity" [@fadeIn]>Password must contain at least one number, one uppercase letter, and one lowercase letter.</mat-error>

            <!-- confirm password -->
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Repeat Password</mat-label>
                <input formControlName="passwordConfirm"
                required
                name="passwordConfirm"
                matInput
                type="{{ showPassword ? 'text' : 'password' }}">
                <mat-icon matSuffix (click)="showPassword = !showPassword" class="cursor-pointer">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </mat-form-field>
            <mat-error class="mat-caption pl-2" *ngIf="password.value !== passwordConfirm.value && password.dirty && passwordConfirm.dirty" [@fadeIn]>
                Passwords do not match.
            </mat-error>

            <!-- button -->
            <div class="flex justify-end !my-1">
                <button mat-flat-button color="primary" [disabled]="!canSubmit()">
                    <span *ngIf="submitting else text" class="spinner">&nbsp;</span>
                    <ng-template #text>
                        <span class="mat-body-1">Set Password</span>
                    </ng-template>
                </button>
            </div>
        </form>
    </div>
    @if (!showForm) {
        <p class="mat-body-1">
            The link you've followed appears to be incorrect. If you continue to experience issues, please manually paste the
            link into your browser.
        </p>
    }
