import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Alert } from 'src/app/interfaces/alert.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';

declare var hbspt: any;
@Component({
  selector: 'app-lead-submission-form',
  templateUrl: './lead-submission-form.component.html',
  styleUrls: ['./lead-submission-form.component.scss']
})
export class LeadSubmissionFormComponent implements OnInit, AfterViewInit {

  @Output() state = new EventEmitter<string>();
  @Select(AuthState.user) currentUser$: Observable<AuthUser>;

  destroyed$ = new Subject<boolean>();
  affiliateId: number;
  submitted = false;
  loading = true;
  leadSubmission: any = {
    owner: '',
    business: '',
    email: ''
  };
  alerts: Alert[] = [];
  nameRegex = /\w+\s\w+/;
  hubspotForm: any;
  
  leadSubmissionForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.leadSubmissionForm = new FormGroup({
      owner: new FormControl(this.leadSubmission.owner, [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(this.nameRegex)
      ]),
      business: new FormControl(this.leadSubmission.business, [
        Validators.required,
        Validators.minLength(3)
      ]),
      email: new FormControl(this.leadSubmission.email, [
        Validators.email,
        Validators.required,
      ])
    });

    this.currentUser$.pipe(
      take(1)
    ).subscribe(user => {
      // Need to load form on ngOnInit. Hubspot will populate after view initiated
      hbspt.forms.create({
        portalId: "3012772",
        region: "na1",
        formId: "db730e55-ddb0-4f1c-865a-7f6fa3476a25",
        target: "#hubspotForm",
        formInstanceId: 'lead-submit-form',
        onFormReady($form) {
          $form.elements["partner_affiliate_id"].value = user.affiliate?.id;
          $form.elements["lead_submitted_by_user_id"].value = user.id;
          $form.elements["marketing_first_affiliate_name"].value = user.affiliate?.name;
          $form.elements["marketing_first_affiliate_partner_medium"].value = user.affiliate?.medium;
          var event = new CustomEvent('hubspotReady', { "detail": true });
          document.dispatchEvent(event);
        },
        onFormSubmit() {
          var successEvent = new CustomEvent('hubspotSuccess', { "detail": true });
          document.dispatchEvent(successEvent);
        }
        });
    });

    
  }

  ngAfterViewInit(): void {
    document.addEventListener('hubspotReady', () => {
      this.loading = false;
    });
    document.addEventListener('hubspotSuccess', () => {
      this.submitted = true;
    });
  }

  setLoading(bool: boolean) {
    this.loading = bool;
  }

  dismissAlert() {
    this.alerts = [];
  }

  canSubmit() {
    return (this.leadSubmissionForm.valid && !this.loading);
  }

  showAlert(alert: Alert) {
    this.alerts.push(alert);
  }

  setState(state: string) {
    this.state.emit(state);
  }
}
