import { RecentSearch, SearchResponseResult, SearchResult } from '@app/app/interfaces/search/search-result.model';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { SearchService } from '@app/app/services/search.service';
import {
  ClearRecentSearches,
  ClearSearchResults,
  GetRecentSearchLocalStorage,
  Search,
} from '@app/app/store/search/search.actions';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { get, groupBy } from 'lodash';
import { Injectable } from '@angular/core';
import { SearchModule } from '@app/app/components/search/search.module';

export interface SearchStateModel {
  loading: boolean;
  error: boolean;
  recentSearches: RecentSearch[];
  searchResults: SearchResult[];
  pagination: {
    pageIndex: number;
    total: number;
  }
}
@Injectable()
@State<Partial<SearchStateModel>>({
  name: 'Search',
  defaults: {
    recentSearches: [],
    loading: false,
  }
})
export class SearchState {

  private static localStorageKey = 'recentSearches';
  private static maxRecentSearchStorageLength = 10;
  /**
   * In the case that a search returns more than one result for a single Business,
   * this array is used to group results based on the source of the match
   * @private
   */
  private static matchTypeSortOrder = [
    'borrowerName',
    'dbaName',
    'primaryUserName',
    'primaryUserEmail',
    'primaryPhone',
    'contactName',
    'contactEmail',
    'contactPhone',
    'borrowerId',
    'dealId'
  ];

  @Selector()
  static error(state: SearchStateModel) {
    return state.error;
  }

  @Selector()
  static lastSearchTerm(state: SearchStateModel) {
    return state.recentSearches[0]?.term;
  }

  @Selector()
  static loading(state: SearchStateModel) {
    return state.loading;
  }

  @Selector()
  static recentSearches(state: SearchStateModel) {
    return state.recentSearches;
  }

  @Selector()
  static searchResults(state: SearchStateModel) {
    return state.searchResults;
  }

  @Selector()
  static selectorFromKey(key: string) {
    return createSelector([this], ({Search}) => {
      return get(Search, key);
    });
  }

  constructor(
    private searchService: SearchService,
  ) {}

  @Action(ClearRecentSearches)
  ClearRecentSearches(
    ctx: StateContext<SearchStateModel>,
    {}: ClearSearchResults,
  ) {
    ctx.patchState({
      recentSearches: [],
    });
    SearchState.setRecentSearchesLocalStorage([]);
  }

  @Action(ClearSearchResults)
  ClearSearchResults(
    ctx: StateContext<SearchStateModel>,
    {}: ClearSearchResults,
  ) {
    ctx.patchState({
      searchResults: [],
      loading: false,
    });
  }

  @Action(GetRecentSearchLocalStorage)
  GetRecentSearchLocalStorage(
    ctx: StateContext<SearchStateModel>,
    {}: GetRecentSearchLocalStorage,
  ) {
    const storageVal = localStorage.getItem(
      SearchState.localStorageKey
    ) || '[]' as string;
    ctx.patchState({
      recentSearches: JSON.parse(storageVal),
    });
  }

  @Action(Search)
  Search(
    ctx: StateContext<SearchStateModel>,
    { term }: Search,
  ) {

    ctx.patchState( {loading: true} );

    return this.searchService.search(term).pipe(
      catchError( (err: any) => {
        ctx.patchState({
          loading: false,
          error: true
        });
        return throwError(err);
      }),
      tap( results => {
        const current = ctx.getState();
        const timestamp = Date.now();
        const recentSearches = SearchState.appendRecentSearches(
            [...current.recentSearches],
          {
            term,
            timestamp,
          }
        );
        SearchState.setRecentSearchesLocalStorage(recentSearches);
        const resultsData = results.data;
        const groupedResults = Object.values(groupBy(resultsData, 'borrowerId')).map( byBorrower => {
          return byBorrower.sort((a, b) => {
            return SearchState.matchTypeSortOrder.indexOf(a.matchedAttribute) - SearchState.matchTypeSortOrder.indexOf(b.matchedAttribute)
          })[0];
        });
        const formattedSearchResults = SearchState.formatSearchResults(groupedResults);

        ctx.patchState({
          loading: false,
          error: false,
          recentSearches,
          searchResults: formattedSearchResults,
        })
      })
    )
  }

  private static formatSearchResults( results: SearchResponseResult[] ) {
    return results?.map( result => {
      const {
        borrowerId,
        borrowerName,
        fullName,
        matchedAttribute,
        matchedValue,
      } = result;
      const title = matchedValue;
      const subTitle = matchedAttribute === 'borrowerName' ? fullName : borrowerName;
      return {
        resourceId: borrowerId,
        title,
        subTitle,
        resourceNavUrl: `businesses/${borrowerId}`,
      }
    });
  }

  private static setRecentSearchesLocalStorage( recentSearches: RecentSearch[] ) {
    const storageVal = JSON.stringify(recentSearches);
    localStorage.setItem(
      SearchState.localStorageKey,
      storageVal
    );
  }

  private static appendRecentSearches(recentSearches: RecentSearch[], newSearch: RecentSearch) {
    if(recentSearches[0]?.term === newSearch.term) {
      recentSearches[0] = newSearch;
      return recentSearches;
    }
    const filteredSearches = recentSearches.filter( ({term}) => !term.includes(newSearch.term));
    const searches = filteredSearches.length >= SearchState.maxRecentSearchStorageLength
      ? filteredSearches.slice(1, filteredSearches.length)
      : filteredSearches;

    return [
      newSearch,
      ...searches,
    ];
  }

}
