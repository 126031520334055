@if (form && !hideEditModal) {
    <form [formGroup]="form" data-cy="edit-business-contact-dialog" class="lendio-density-1">
        <div class="flex justify-between items-center pr-4">
            <div mat-dialog-title class="!pb-2" data-cy="contact-form-modal-header">{{ formMode }} Contact</div>
            <button mat-icon-button
                    (click)="cancel($event)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-dialog-content class="p-4">
            <div class="grid grid-flow-col grid-cols-2 gap-4 mb-4">
                <div class="flex flex-col grid-rows gap-4 content-start">
                    <span>
                    <mat-form-field appearance="outline" class="w-1/2 pr-1"> <mat-label>Primary contact</mat-label>
                            <mat-select formControlName="isPrimary" data-cy="is-primary">
                                <mat-option *ngFor="let option of yesNoOptions" [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-1/2 pl-1">
                            <mat-label>Ownership %</mat-label>
                            <input matInput
                                formControlName="percentOwnership"
                                type="number"
                                [min]="0"
                                [max]="100"
                                data-cy="percentOwnership">
                            <mat-error *ngIf="form.get('percentOwnership')?.errors">
                                Between 0 and 100
                            </mat-error>
                        </mat-form-field>
                    </span>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>First name</mat-label>
                        <input matInput formControlName="first" type="text" data-cy="first">
                        <mat-error *ngIf="form.get('first')?.errors">
                            Please enter a first name
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Last name</mat-label>
                        <input matInput formControlName="last" type="text" data-cy="last">
                        <mat-error *ngIf="form.get('last')?.errors">
                            Please enter a last name
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Job title</mat-label>
                        <input matInput formControlName="title" type="text" data-cy="title">
                    </mat-form-field>

                    <span>
                        <mat-form-field appearance="outline" class="w-5/12 pr-1">
                            <mat-label>Mobile phone</mat-label>
                            <input matInput formControlName="mobilePhone"
                                type="text"
                                data-cy="mobile-phone"
                                mask="(000) 000-0000"
                                placeholder="Ex: 1234567890"
                            >
                            <mat-error *ngIf="form.get('mobilePhone')?.errors">
                                Please use 10 digits
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-5/12 pr-1 pl-1">
                            <mat-label>Work phone</mat-label>
                            <input matInput formControlName="workPhone"
                                type="text"
                                data-cy="work-phone"
                                mask="(000) 000-0000"
                                placeholder="Ex: 1234567890"
                            >
                            <mat-error *ngIf="form.get('workPhone')?.errors">
                                Please use 10 digits
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-2/12 pl-1">
                            <mat-label>Ext.</mat-label>
                            <input matInput formControlName="workPhoneExt" type="text" data-cy="work-phone-ext">
                        </mat-form-field>
                    </span>

                    <div class="text-sm mt-2">* required field</div>
                </div>

                <div class="flex flex-col grid-rows gap-4 content-start">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" type="text" data-cy="email" email />
                        <mat-error *ngIf="form.get('email')?.errors">
                            Please use this format: yourname&#64;company.com
                        </mat-error>
                    </mat-form-field>

                    <span>
                        <mat-form-field appearance="outline" class="w-1/2 pr-1">
                            <mat-label>Birth-date</mat-label>
                            <input matInput
                                [matDatepicker]="birthDateDatePicker"
                                [max]="todayDate"
                                formControlName="birthDate"
                                data-cy="birth-date"
                                placeholder="Ex: MM/DD/YY">
                            <mat-datepicker-toggle matSuffix [for]="birthDateDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #birthDateDatePicker></mat-datepicker>
                            <mat-error *ngIf="form.get('birthDate')?.errors">
                                Use MM/DD/YYYY format
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-1/2 pl-1">
                            <mat-label>Social security</mat-label>
                            <input matInput formControlName="ssn"
                                type="text"
                                [hiddenInput]="true"
                                [patterns]="ssnMaskPatterns"
                                mask="XXX-XX-0000"
                                [validation]="true"
                                data-cy="ssn"
                                placeholder="Ex: 123456789"
                            >
                            <mat-error *ngIf="form.get('ssn')?.errors">
                                Please enter 9 digits
                            </mat-error>
                        </mat-form-field>
                    </span>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Street address 1</mat-label>
                        <input matInput formControlName="street" type="text" data-cy="street">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Street address 2</mat-label>
                        <input matInput formControlName="street2" type="text" data-cy="street2">
                    </mat-form-field>

                    <span>
                        <mat-form-field appearance="outline" class="w-5/12 pr-1">
                            <mat-label>City</mat-label>
                            <input matInput formControlName="city" type="text" data-cy="city">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-3/12 pl-1 pr-1">
                            <mat-label>State</mat-label>
                            <mat-select formControlName="stateId" data-cy="stateId" panelWidth="min-content">
                                <mat-select-trigger>
                                    {{ form.get('stateId')?.value }}
                                </mat-select-trigger>
                                <mat-option *ngFor="let option of stateOptions" [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-4/12 pl-1">
                            <mat-label>Zip code</mat-label>
                            <input matInput formControlName="zipId"
                                type="text"
                                placeholder="Ex: 12345"
                                mask="00000"
                                [validation]="true"
                                data-cy="zipId"
                            >
                            <mat-error *ngIf="form.get('zipId')?.errors">
                                Please use 5 digits
                            </mat-error>
                        </mat-form-field>
                    </span>
                </div>
            </div>

        </mat-dialog-content>
        <mat-divider class=""></mat-divider>
        <mat-dialog-actions>
            <!-- Only show enabled Delete button in Edit mode. -->
            <div class="w-1/3 flex justify-start mt-4 pl-4">
                <!--
                    Disable Delete and show tooltip in parent object (since
                    it won't show for a disabled button) for primary contact.
                    Cast isPrimary to int with leading +.
                -->
                <span *ngIf="formMode === 'Edit'"
                    [matTooltip]="+form.get('isPrimary')?.value === 1 ? 'Primary contacts cannot be deleted' : ''"
                    matTooltipPosition="right">
                    <button mat-button color="warn"
                            data-cy="contact-delete-btn"
                            [disabled]="+form.get('isPrimary')?.value === 1"
                            (click)="delete(form)">
                        Delete
                    </button>
                </span>
            </div>
            <div class="w-2/3 flex justify-end">
                <mat-error *ngIf="httpError" class="self-center mt-4">
                    {{ httpError }}
                </mat-error>
                <button data-cy="business-details-contact-cancel-btn" mat-stroked-button class="!mt-4 !ml-6" (click)="cancel($event)">
                    Cancel
                </button>
                <!-- Decide which "save" button based on formMode. -->
                <button mat-flat-button color="primary" class="!ml-4 !mt-4"
                    *ngIf="formMode === 'Edit'"
                    data-cy="business-details-contact-update-btn"
                    [disabled]="!form.dirty || !form.valid"
                    (click)="upsert(form)"
                >
                    Save
                </button>
                <button mat-flat-button color="primary" class="!ml-4 !mt-4"
                    *ngIf="formMode === 'Add'"
                    data-cy="business-details-contact-create-btn"
                    [disabled]="!form.dirty || !form.valid"
                    (click)="upsert(form)"
                >
                    Add
                </button>
            </div>
        </mat-dialog-actions>
    </form>
}
