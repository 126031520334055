import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { SbaStatusService } from 'src/app/services/sba-status.service';
import { Store } from '@ngxs/store';
import { GetApplicationDetails, ResetSbaStatus } from 'src/app/store/application-details/application-details.actions';
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";

@Component({
  selector: 'app-withdraw-from-sba-dialog',
  templateUrl: './withdraw-from-sba-dialog.component.html',
  styleUrls: ['./withdraw-from-sba-dialog.component.scss', '../../../../../scss/_dialog.scss']
})
export class WithdrawFromSbaDialogComponent implements OnInit {

  @Output() withdrawn = new EventEmitter();

  dealId: number;
  submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number },
    public dialogRef: MatDialogRef<WithdrawFromSbaDialogComponent>,
    private statusService: SbaStatusService,
    private store: Store,
    private _snackbarService: LendioSnackbarService
  ) {
    this.dealId = data.dealId;
  }

  ngOnInit(): void {}

  submit() {
    this.submitting = true;
    this.statusService.withdraw(this.dealId).pipe(take(1)).subscribe((res) => {
      this._snackbarService.open({
        message: res.data,
        canDismiss: true,
        duration: 10000
      });
      if (res.data === 'SBA submission withdrawn successfully') {
        this.store.dispatch(new ResetSbaStatus());
        this.withdrawn.emit();
      }
      this.submitting = false;
      this.store.dispatch(new GetApplicationDetails(this.dealId));
      this.dialogRef.close();
    }, (err) => {
      this._snackbarService.open({
          message: 'Unable to cancel this SBA submission. Please wait a moment and try again.',
          canDismiss: true,
          duration: 10000
      });
      this.submitting = false;
    });
  }
}
