<mat-dialog-content>
    <div class="flex justify-center content-center" *ngIf="loading else ready">
        <mat-spinner
            [color]="'primary'">
        </mat-spinner>
    </div>
    <ng-template #ready>
        <app-alert
            *ngFor="let alert of alerts"
            [level]="alert.level"
            [message]="alert.message"
            [dismissible]="true"
            (dismissed)="dismissAlert(alert)"
        ></app-alert>
        <h3 *ngIf="!submitted"><strong>Business Details</strong></h3>
    </ng-template>
    <div id="hubspotForm"></div>
</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-stroked-button class="lead-submit-button !mb-2 !px-6" (click)="setState('cancel')">Cancel</button>
</mat-dialog-actions>