import { Component, computed, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { Document } from '../../../interfaces/document.model';
import { environment } from '../../../../environments/environment';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { Observable, Subject, forkJoin } from 'rxjs';
import { DocumentsService } from '@app/app/services/documents.service';
import * as JSZip from 'jszip';
import { HttpClient } from '@angular/common/http';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-old-documents',
  templateUrl: './old-documents.component.html',
  styleUrls: ['./old-documents.component.scss'],
})
export class OldDocumentsComponent implements OnInit, OnDestroy {

  @Select(AuthState.user) currentUser$: Observable<AuthUser>;
  @Input() documents: Document[];
  @Input() dealId: number;

  selection = new SelectionModel<Document>(true, []);
  baseUrl = `${environment.apiUrl}/l/v1/internal/deals`;
  user: AuthUser | null;
  destroyed$ = new Subject<void>();

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled())

  constructor(
    private doc: DocumentsService,
    private http: HttpClient,
    private _themeService: LendioAngularMaterialThemeService
  ) { }

  ngOnInit(): void {
    this.currentUser$.subscribe(user => {
      this.user = user;
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  getDocsChecked() {
    if (this.selection.selected.length === 0) {
      return '';
    }

    if (this.documents && this.selection.selected.length === this.documents.length) {
      return 'all';
    }

    return this.selection.selected.length;
  }

  getDocUploaded(uploaded: number): string {
    return moment.unix(uploaded).fromNow();
  }

  getDocType(type: string): string {
    if (!type) {
      return '';
    }
    return type.replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();
  }

  getMasterIndeterminate(): boolean {
    if (!this.documents) {
      return false;
    }

    return this.documents && this.selection.selected.length !== this.documents.length && this.selection.selected.length > 0;
  }

  getShowZipAll(): boolean {
    if (!this.documents) {
      return true;
    }

    return this.documents && this.selection.selected.length !== this.documents.length;
  }

  masterToggle(): void {
    if (this.documents && this.selection.selected.length === this.documents.length) {
      this.selection.deselect(...this.documents);
    } else {
      this.selection.select(...this.documents);
    }
  }

  viewDocument(documentId: number): void {
    this.doc.openInNewTab(`${environment.apiUrl}/l/v2/internal/document/${documentId}/stream?dealId=${this.dealId}`, documentId);
  }

  downloadSelected(documents?: Document[]): void {
    const zip = new JSZip();
    const downloadObservables: Observable<Blob>[] = [];
    if (documents) {
      documents.forEach((document) => {
        downloadObservables.push(this.http.get(`${environment.apiUrl}/l/v2/internal/document/${document.id}/stream?dealId=${this.dealId}`, { responseType: 'blob' }));
      });
    } else {
      this.selection.selected.forEach((document) => {
        downloadObservables.push(this.http.get(`${environment.apiUrl}/l/v2/internal/document/${document.id}/stream?dealId=${this.dealId}`, { responseType: 'blob' }));
      });
    }
    forkJoin(downloadObservables).subscribe(responses => {
      responses.forEach((response, index) => {
        zip.file(`document_${documents ? documents[index].filename : this.selection.selected[index].filename}`, response, { binary: true });
      });
      zip.generateAsync({ type: 'blob' }).then(content => {
        const fileURL = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `documents.zip`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
      });
      this.selection.clear();
    });
  }

  zipAll(): void {
    this.downloadSelected(this.documents);
  }
}
