import moment from 'moment/moment';
import {
  ColumnFilterModel,
  CountRowsMatchingFiltersParams,
  DateFilterModelComparisonParams,
  SetFilterModelComparisonParams,
  TextOrNumberFilterModelComparisonParams,
} from '@app/app/components/grid-components/grid-types';

export const dateFilterComparator = (filterLocalDateAtMidnight: Date, cellValue: number | string): number | undefined => {
  let cellValDate;
  if (typeof cellValue === 'number') {
    cellValDate = new Date(cellValue * 1000);
  } else {
    cellValDate = cellValue;
  }
  if (cellValDate === null) {
    return 0;
  }
  const year = cellValDate.getFullYear();
  const month = cellValDate.getMonth();
  const day = cellValDate.getUTCDate();
  const cellCompareDate = new Date(year, month, day);

  if (cellCompareDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellCompareDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const countRowsMatchingFilters = async (
  { gridApi, filterModel, isInitialCount }: CountRowsMatchingFiltersParams,
): Promise<number | void> => {
  if (!gridApi || Object.keys(filterModel).length === 0) {
    return;
  }
  let filteredCount = 0;
  const currentFilterModel = isInitialCount
    ? null
    : gridApi.getFilterModel();

  gridApi.setFilterModel(null);
  gridApi.setFilterModel(filterModel);
  gridApi.forEachNodeAfterFilter(() => filteredCount += 1);
  gridApi.setFilterModel(currentFilterModel);
  gridApi.onFilterChanged();
  gridApi.refreshCells();

  return filteredCount;
}

export const viewFilterTypeEquality = (
  { gridColumnFilter, viewColumnFilter }: {
    gridColumnFilter: ColumnFilterModel,
    viewColumnFilter: ColumnFilterModel,
    strictSetMatching: boolean
  }): boolean => {

  if (viewColumnFilter?.filterType === 'set') {
    const valuesA = gridColumnFilter.values;
    const valuesB = viewColumnFilter.values;
    return setFilterModelComparison({ valuesA, valuesB });
  }

  if (viewColumnFilter?.filterType === 'date') {
    const dateFromA = gridColumnFilter.dateFrom;
    const dateToA = gridColumnFilter.dateTo;
    const dateFromB = viewColumnFilter.dateFrom;
    const dateToB = viewColumnFilter.dateTo;
    return dateFilterModelComparison({ dateFromA, dateToA, dateFromB, dateToB });
  }

  if (viewColumnFilter?.filterType === 'number' || viewColumnFilter?.filterType === 'text') {
    const filterA = gridColumnFilter.filter;
    const filterB = viewColumnFilter.filter;
    return textOrNumberFilterModelComparison({ filterA, filterB });
  }

  return false;
}

export const setFilterModelComparison = (
  { valuesA, valuesB }: SetFilterModelComparisonParams
): boolean => {
  const viewValuesSet = new Set(valuesA);
    return valuesB.every(value => viewValuesSet.has(value));
}

export const textOrNumberFilterModelComparison = (
  { filterA, filterB }: TextOrNumberFilterModelComparisonParams
): boolean => filterA === filterB;

export const dateFilterModelComparison = (
  { dateFromA, dateToA, dateFromB, dateToB }: DateFilterModelComparisonParams
): boolean => {
  let dateFromMatching: boolean;
  let dateToMatching: boolean;
  if (dateFromA && dateFromB) {
    const dateFromAMoment = moment(dateFromA);
    const dateFromAStartOfDay = dateFromAMoment.startOf('day');
    const dateFromBMoment = moment(dateFromB);
    const dateFromBStartOfDay = dateFromBMoment.startOf('day');
    dateFromMatching = dateFromAStartOfDay.isSame(dateFromBStartOfDay);
  } else {
    dateFromMatching = dateFromA === dateFromB;
  }

  if (dateToA && dateToB) {
    const dateToAMoment = moment(dateToA);
    const dateToAStartOfDay = dateToAMoment.startOf('day');
    const dateToBMoment = moment(dateToB);
    const dateToBStartOfDay = dateToBMoment.startOf('day');
    dateToMatching = dateToAStartOfDay.isSame(dateToBStartOfDay);
  } else {
    dateToMatching = dateToA === dateToB;
  }

  return dateFromMatching && dateToMatching;
}
