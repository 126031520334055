import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import { DynamicScreenHeightModule } from '@app/app/directives/dynamic-screen-height/dynamic-screen-height.module';
import { FinancesPageComponent } from '@app/app/components/businesses/finances/finances-page.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { LetDirectiveModule } from '@app/app/directives/let/let.directive.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { CreditReportComponent } from '@app/app/components/businesses/finances/credit-report/credit-report.component';
import { BusinessValuesComponent } from '@app/app/components/businesses/business-values/business-values.component';
import {
  CreditScoreDisplayComponent
} from '@app/app/components/businesses/finances/credit-score-display/credit-score-display.component';
import { LoadersModule } from '@app/app/components/loaders/loaders.module';
import { EmptyStateComponent } from '@app/app/components/empty-state/empty-state.component';

@NgModule({
  declarations: [
    CreditReportComponent,
    CreditScoreDisplayComponent,
    FinancesPageComponent,
  ],
    imports: [
        BusinessValuesComponent,
        CommonModule,
        DynamicScreenHeightModule,
        LetDirectiveModule,
        MatCardModule,
        MatIconModule,
        MatTabsModule,
        MatButtonModule,
        MatTableModule,
        LoadersModule,
        EmptyStateComponent,
    ],
  exports: [
    CreditReportComponent,
    CreditScoreDisplayComponent,
    FinancesPageComponent,
  ],
  providers: [
    PercentPipe,
    CurrencyPipe,
  ]
})
export class FinancesModule {}
