import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * FileUploadDialogComponent wraps the FileUploadComponent when you want to
 * use it within a modal.
 */
@Component({
  selector: 'file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent {
  @Output() onClose = new EventEmitter<void>();
  @Output() fileInput = new EventEmitter<File>();

  /**
   * Inject data from the client via `data`.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      // Dialog data.
      title?: string,
      closeLabel?: string,
      // Pass through to file upload component.
      requiredFileTypes?: Array<string>,
      fileSizeLimit?: number,
      fileCount?: number,
      label?: string
      inline?: boolean,
      fileSelectText?: string,
      buttonText?: string,
    },
    public selfDialogRef: MatDialogRef<FileUploadDialogComponent>,
  ) {}

  handleFileInput(selection: File) {
    this.fileInput.emit(selection);
  }

  close($event: Event) {
    $event.stopPropagation();
    this.selfDialogRef.close();
  }
}
