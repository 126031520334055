export class GetCommercialCreditReport {
  static readonly type = '[Finances] GetCommercialCreditReport';
  constructor(
    public borrowerId: number,
  ) {}
}
export class GetOwnerCreditReport {
  static readonly type = '[Finances] GetOwnerCreditReport';
  constructor(
    public contactId: number,
  ) {}
}

  export class GetSavedOwnerReports {
    static readonly type = '[Finances] GetSavedOwnerReports';
    constructor(
      public borrowerId: number,
    ) {}
}
