import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { EmbeddedService } from '../../services/embedded.service';
import { Customization } from '../../interfaces/embedded-customization.model';
import { tap } from 'rxjs/operators';
import { catchError, of } from 'rxjs';
import {
  // Embedded API
  GetCustomizations,
  UpdateCustomization,
  CreateCustomization,
  // Shared
  IntelligentLoaded,
  ClearIntelligentStore
} from './intelligent.actions';

export class IntelligentStateModel {
  customizations: { [key: string]: Array<Customization> } | null;
  loaded: boolean;
  lastActiveCustomization: Customization | null;
}

@Injectable()
@State<IntelligentStateModel>({
  name: 'intelligent',
  defaults: {
    customizations: null,
    loaded: false,
    lastActiveCustomization: null
  }
})

export class IntelligentState {
  @Selector()
  static customizations(state: IntelligentStateModel): { [key: string]: Array<Customization> } | null {
    return state.customizations;
  }

  @Selector()
  static customizationsByType(type: string) {
    return createSelector([IntelligentState], ({ intelligent: state }) => {
      const typeArray: Array<Customization>|null = state.customizations ? state.customizations[type] : null;
        
      return typeArray || null;
    })
  }

  @Selector()
  static activeCustomizationByType(type: string) {
    return createSelector([IntelligentState], ({ intelligent: state }) => {
      const typeArray: Customization[]|null = state.customizations ? state.customizations[type] : null;

      if (typeArray) {
        const activeCustomization = typeArray.find(c => !!c.active);
  
        return activeCustomization ?? null;
      } 
        
      return null;
    })
  }

  @Selector()
  static loaded(state: IntelligentStateModel): boolean {
    return state.loaded;
  }

  @Selector()
  static lastActiveCustomization(state: IntelligentStateModel): Customization | null {
    return state.lastActiveCustomization;
  }

  constructor(
    private embeddedService: EmbeddedService
  ) { }
  @Action(GetCustomizations)
  getIntelligentCustomizations({ getState, patchState }: StateContext<IntelligentStateModel>, { affiliateId, type}: GetCustomizations) {  
      return this.embeddedService.getCustomizations(affiliateId, type).pipe(
        catchError(() => {
          return of();
        }),
        tap(({ data: customizations }) => {
  
          if (customizations) {
            customizations = customizations.reduce((acc: any, customization: Customization) => {
              if (acc[customization.type]) {
                acc[customization.type].push(customization);
              } else {
                acc[customization.type] = [ customization ];
              }

              return acc;
            }, {})

            patchState({ customizations });
          }
        })
      );
  }

  @Action(IntelligentLoaded)
  intelligentLoaded({ patchState }: StateContext<IntelligentStateModel>, { bool }: IntelligentLoaded) {
    patchState({ loaded: bool });
  }

  @Action(UpdateCustomization)
  updateCustomization({ getState, patchState }: StateContext<IntelligentStateModel>, { affiliateId, customizationId, customizationRequest }: UpdateCustomization) {
    return this.embeddedService.updateCustomization(affiliateId, customizationId, customizationRequest).pipe(
      catchError(() => {
        return of();
      }),
      tap(({ data: customization }) => {
        if (!customization) {
          return;
        }
        let customizations = getState().customizations;
        const { type, id } = customization;

        if (!customizations) {
          patchState({ lastActiveCustomization: customization, customizations: { [type]: [ customization ]}});

          return;
        } else {
          customizations = { ...customizations };
        }
        let customizationTypeArr = customizations ? [ ...customizations[type] ] : [];
        const existingCustomizationIndex = customizationTypeArr.findIndex(c => c.id === id);

        if (existingCustomizationIndex >= 0) {
          customizationTypeArr[existingCustomizationIndex] = customization;
        } else {
          customizationTypeArr.push(customization);
        }

        if (customization.active) {
          customizationTypeArr = customizationTypeArr.map(c => {
            if (c.id !== customization.id && c.active) {
              return { ...c, active: 0 };
            }

            return c;
          })
        }

        customizations[type] = customizationTypeArr;
        patchState({ lastActiveCustomization: customization, customizations });
      })
    );
  }

  @Action(CreateCustomization)
  createCustomization({ getState, patchState }: StateContext<IntelligentStateModel>, { affiliateId, customizationRequest }: CreateCustomization) {  
    return this.embeddedService.postCustomization(affiliateId, customizationRequest).pipe(
      catchError(() => {
        return of();
      }),
      tap(({ data: customization }) => {
        if (!customization) {
          return;
        }
        let customizations = getState().customizations;
        if (!customizations) {
          patchState({ lastActiveCustomization: customization, customizations: { type: [ customization ]}});
          return;
        } else {
          customizations = { ...customizations };
        }
        const { type } = customization;
        const customizationTypeArr = customizations && customizations[type] ? [ ...customizations[type] ] : [];
        customizationTypeArr.push(customization);
        customizations[type] = customizationTypeArr;
        patchState({ lastActiveCustomization: customization, customizations });
      })
    );
  }

  @Action(ClearIntelligentStore)
  clearIntelligentStore({ patchState }: StateContext<IntelligentStateModel>, { }: ClearIntelligentStore) {
      patchState({
        customizations: null,
        loaded: false
      });
  }
}
