import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from 'src/app/interfaces/contact.model';
import { ContactsActions as CA } from '@app/app/store/contacts/contacts.actions';

@Component({
  selector: 'app-edit-owner-dialog',
  templateUrl: './edit-owner-dialog.component.html',
  styleUrls: ['../../new-offer-display/forms/offer-form-styles.scss', '../../../scss/_dialog.scss']
})
export class EditOwnerDialogComponent implements OnInit {

  updatedOwner = {
    first: this.data.contact.first,
    last: this.data.contact.last,
    email: this.data.contact.email,
    percentOwnership: this.data.contact.percentOwnership,
    zipId: this.data.contact.zipId,
    zipExtra: this.data.contact.zipExtra,
    contractSigner: this.data.contact.contractSigner
  };
  updatedOwnerForm: FormGroup;
  zipRegex = /^(\d{5})?$/;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<EditOwnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number, contact: Contact },
  ) { }

  ngOnInit(): void {
    this.updatedOwnerForm = new FormGroup ({
      first: new FormControl(this.updatedOwner.first, [
        Validators.required,
      ]),
      last: new FormControl(this.updatedOwner.last, [
        Validators.required,
      ]),
      email: new FormControl(this.updatedOwner.email, [
        Validators.required,
      ]),
      percentOwnership: new FormControl(this.updatedOwner.percentOwnership, [
        Validators.required,
        Validators.max(100)
      ]),
      zipId: new FormControl(this.updatedOwner.zipId, [
        Validators.required,
        Validators.pattern(this.zipRegex)
      ]),
      zipExtra: new FormControl(this.updatedOwner.zipExtra, []),
      contractSigner: new FormControl(this.updatedOwner.contractSigner, [
        Validators.required,
      ]),
    });
  }

  updateOwner() {
    if (this.updatedOwnerForm.value.zipExtra) {
      this.updatedOwnerForm.value.zipExtra = this.updatedOwnerForm.value.zipExtra.toString();
    }
    if (this.data.contact?.id) {
      this.store.dispatch(new CA.UpdateByDeal(this.data.dealId, this.data.contact.id, this.updatedOwnerForm.value));
    }
    this.close();
  }

  checkError(controlName: string, errorName: string) {
    return this.updatedOwnerForm.controls[controlName].hasError(errorName);
  }

  close() {
    this.dialogRef.close();
  }
}
