@if (newThemeEnabled()) {
    <app-documents
        *ngIf="documents$ | async as documents; else loading"
        [borrowerId]="borrowerId"
        [dealId]="dealId">
    </app-documents>
} @else {
    <app-old-documents
        *ngIf="documents$ | async as documents"
        [dealId]="dealId"
        [documents]="documents">
    </app-old-documents>
}

<ng-template #loading>
  <mat-card appearance="outlined" class="mb-4">
    <h2 class="border-b">Documents</h2>
    <app-dot-loader-scale></app-dot-loader-scale>
  </mat-card>
</ng-template>
