import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';
import { Borrower } from 'src/app/interfaces/borrower.model';
import { PhonePipe } from 'src/app/pipes/phone/phone.pipe';
import { Select, Store } from '@ngxs/store';
import { AdverseActionNotice } from '../../../../interfaces/adverse-action-notice.model';
import { AdverseActionNoticeState } from '../../../../store/adverse-action-notice/adverse-action-notice.state';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { AdverseActionNoticeService } from "src/app/services/adverse-action-notice.services";


@Component({
  selector: 'app-ppp-details',
  templateUrl: './ppp-details.component.html',
  styleUrls: ['../details/details.component.scss']
})
export class PppDetailsComponent implements OnInit, OnChanges {

  // @Select(AdverseActionNoticeState.adverseActionNotice) adverseActionNotice$: Observable<AdverseActionNotice>;
  adverseActionNotice$: Observable<AdverseActionNotice | null> = this.store.select(AdverseActionNoticeState.adverseActionNotice);
  @Input() applicationDetails: ApplicationDetails;
  @Input() applicationFields: any;
  @Input() dealId: number;
  @Input() loanProductCategory: string;

  aanDate: any;
  adverseActionNotice: any;
  borrower: Borrower;
  borrowerValues: any;
  borrowerValuesKeyed: any;
  primaryContact: any;

  revReductionQuarters: any[] = [];
  revReductionQuarterValues: any[] = [];

  isAan = false;
  level = 'warning';
  message = '';

  constructor(private phonePipe: PhonePipe, private store: Store) { }

  ngOnInit(): void {
    this.initializeValues();
    this.adverseActionNotice$.subscribe(data => {
      this.adverseActionNotice = data;
      if (this.adverseActionNotice && this.adverseActionNotice.data && Object.keys(this.adverseActionNotice.data).length) {
        this.message = `Adverse Action sent to ${this.adverseActionNotice.data.email ? this.adverseActionNotice.data.email : 'N/A'} - ${this.adverseActionNotice.data.tdr ? this.adverseActionNotice.data.tdr : ''}`;
        this.aanDate = new Date(this.adverseActionNotice.data.date);
        this.isAan = true;
      }
    });
  }

  initializeValues() {
    this.borrowerValues = this.applicationDetails.borrowerValues;
    this.borrowerValuesKeyed = _.indexBy(this.applicationDetails.borrowerValues, 'alias');
    this.primaryContact = this.applicationDetails.deal.borrower.primary_contact;
    this.borrower = this.applicationDetails.deal.borrower;

    this.saveRevReductionRefQuarters();
  }

  usedReductionQuarter(value: any) {
    return this.revReductionQuarters.indexOf(value) > -1;
  }

  /**
   * Takes in quarter like '1Q 2020'
   * @output string (e.g. 2020 Q1)
   */
  formatQuarterToString(str: string) {
    const parts = str.split(' ');
    const year = parts[1];
    const quarter = parts[0];
    return `${year} ${quarter[1]}${quarter[0]}`;
  }

  /**
   * Takes in quarter like '1Q 2020'
   * @output string (e.g. quarterlyRevenue2020Q1)
   */
  formatQuarterToAlias(str: string) {
    const parts = str.split(' ');
    const year = parts[1];
    const quarter = parts[0];
    return `quarterlyRevenue${year}${quarter[1]}${quarter[0]}`;
  }

  saveRevReductionRefQuarters() {
    if (this.borrowerValuesKeyed.revReductionReferenceQuarter) {
      const refQuarter = this.borrowerValuesKeyed.revReductionReferenceQuarter.value;
      this.revReductionQuarters[0] = refQuarter;

      const refQuarterAlias = this.formatQuarterToAlias(refQuarter);
      const refQuarterAttribute = this.borrowerValuesKeyed[refQuarterAlias];

      this.revReductionQuarterValues[0] = refQuarterAttribute ? refQuarterAttribute.value : '';
    }
    if (this.borrowerValuesKeyed.revReduction2020Quarter) {
      const refQuarter = this.borrowerValuesKeyed.revReduction2020Quarter.value;
      this.revReductionQuarters[1] = refQuarter;

      const refQuarterAlias = this.formatQuarterToAlias(refQuarter);
      const refQuarterAttribute = this.borrowerValuesKeyed[refQuarterAlias];

      this.revReductionQuarterValues[1] = refQuarterAttribute ? refQuarterAttribute.value : '';

    }
  }

  formattedPhone(phone: string) {
    return this.phonePipe.transform(phone, '($1) $2-$3');
  }

  formattedSsn(ssn: string) {
    return ssn.replace(/.(?=.{4,}$)/g, '*');
  }

  formattedLoanPurposeArray(str: string) {
    const loanPurposes = {
      coveredOperationsExpenditures: 'Covered Operations Expenditures',
      coveredPropertyDamage: 'Covered Property Damage',
      coveredSupplierCosts : 'Covered Supplier Costs',
      coveredWorkerProtectionExpenditures: 'Covered Worker Protection Expenditures',
      financeAccountsReceivable: 'Finance Accounts Receivable',
      leaseOrMortgageInterest: 'Lease / Mortgage Interest',
      other: 'Other',
      payroll: 'Payroll Costs',
      utilities: 'Utilities',
    };
    return str.split(',').map(word => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return loanPurposes[word];
    }).join(', ');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('applicationDetails')) {
      this.initializeValues();
    }
  }
}
