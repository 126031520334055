import { HttpEventType } from '@angular/common/http';
import { Component, computed, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventBusService } from '@app/app/services/event-bus.service';
import { ImportsService } from '@app/app/services/imports.service';
import { FetchWithUpdatedParams } from '@app/app/store/lender-imports/import.actions';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-file-import-dialog',
  templateUrl: './file-import-dialog.component.html',
  styleUrls: ['./file-import-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FileImportDialogComponent implements OnInit {

  @ViewChild('uploadButton') uploadButton: any;

  dialogData: any;
  uploadFileDialog: FormGroup;
  entityType: string;
  file: File;

  uploadInProgress = false;

  fileUpload =
  {
    type: '',
    description: '',
    fileName: '',
    presignedUrl: '',
    file: File,
    progress: 0,
    canUnload: false
  };

  entityTypes = [
    {key: 'Businesses', value: 'borrowers'},
    {key: 'Contacts', value: 'contacts'},
    {key: 'Bank Accounts', value: 'bankAccounts'},
    {key: 'Transactions', value: 'transactions'},
  ];

  showLoadingStateOnUploadButton = false;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FileImportDialogComponent>,
    private service: ImportsService,
    private store: Store,
    private eventBusService: EventBusService,
    private _themeService: LendioAngularMaterialThemeService
    ) {
    this.fileUpload = data;
  }

  ngOnInit(): void {
    this.dialogData = this.data;
    this.uploadFileDialog = new FormGroup({
      type: new FormControl(this.fileUpload.type, [
        Validators.required
      ]),
      importDescription: new FormControl(this.fileUpload.description),
      fileName: new FormControl(this.fileUpload.fileName)
    });
  }

  uploadFile() {
    if(!this.uploadInProgress) {
    this.uploadButton.disabled = true;
    this.uploadInProgress = true;
    this.showLoadingStateOnUploadButton = true;
    this.uploadFileToAws();
    this.eventBusService.subscribe(this.eventBusService.types.LatestImportInStateEvent)
      .pipe(
        take(1),
        tap(() => {
          this.dialogData.canUnload = true;
          this.dialogRef.close(this.dialogData);
          this.showLoadingStateOnUploadButton = false;
          this.uploadInProgress = false;
          this.uploadButton.disabled = false;
        })
      ).subscribe();
    }
  }

  fileSelect(e: any) {
    this.fileUpload.fileName = e.target.files[0].name;
    this.file = e.target.files[0];
    this.fileUpload.file = e.target.files[0];
  }


  uploadFileToAws() {
    this.service.getPresignedUrl(this.fileUpload.type, this.fileUpload.fileName, this.fileUpload.description).subscribe(res => {
      this.store.dispatch(new FetchWithUpdatedParams({}, false));
      this.service.uploadFileToS3(res.url, this.file).subscribe(event => {
        if(event.type === HttpEventType.Response) {
          if(event.status === 200) {
            this.fileUpload.canUnload = true;
          }
          if(event.status === 400 || event.status === 403 || event.status === 500) {
            this.dialogRef.close();
            console.error('error uploading file');
          }
        }
        if(event.type === HttpEventType.UploadProgress) {
          if(event?.loaded && event?.total){
            this.fileUpload.progress = Math.round(100 * event.loaded / event.total);
          }
        }
      });
    });
  }

  async getPresignedUrl(fileUpload: any) {
    return await this.service.getPresignedUrl(fileUpload.type, fileUpload.fileName, fileUpload.description).subscribe(res => {
      this.fileUpload.presignedUrl = res.url;
    });
  }
}
