import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-decline-button-smart',
  templateUrl: './decline-button-smart.component.html',
  styleUrls: ['./decline-button-smart.component.scss']
})
export class DeclineButtonSmartComponent implements OnInit {

  @Input() dealId: number;

  constructor() { }

  ngOnInit(): void {}
}
