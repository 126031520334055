import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInWeeks, differenceInYears, fromUnixTime, toDate } from 'date-fns';
import * as _ from 'lodash';
interface TimePeriod {
    durationSeconds: number;
    timeAgoStringSuffix?: string;
    timeAgoStringSuffixPlural?: string;
    abrevTimeAgoSuffix?: string;
    setUpdateTimer: boolean;
    timeAgoString?: string;
    unitOfTime?: string;
    updateDelaySeconds?: number;
}

@Pipe({name: 'timeago', pure: false})
export class TimeAgoPipe implements PipeTransform, OnDestroy {

    private _abbreviated = false;

    private _currentTimer: number|null;

    private _descendingTimePeriods: TimePeriod[] = [
        {
            timeAgoStringSuffix: 'year ago',
            timeAgoStringSuffixPlural: 'years ago',
            abrevTimeAgoSuffix: 'y',
            unitOfTime: 'year',
            durationSeconds: 31536000,
            setUpdateTimer: false
        },
        {
            timeAgoStringSuffix: 'month ago',
            timeAgoStringSuffixPlural: 'months ago',
            abrevTimeAgoSuffix: 'M',
            unitOfTime: 'month',
            durationSeconds: 2678400, // 31 days
            setUpdateTimer: false
        },
        {
            timeAgoStringSuffix: 'week ago',
            timeAgoStringSuffixPlural: 'weeks ago',
            abrevTimeAgoSuffix: 'w',
            unitOfTime: 'week',
            durationSeconds: 604800,
            setUpdateTimer: false
        },
        {
            timeAgoStringSuffix: 'day ago',
            timeAgoStringSuffixPlural: 'days ago',
            abrevTimeAgoSuffix: 'd',
            unitOfTime: 'day',
            durationSeconds: 86400,
            setUpdateTimer: false
        },
        {
            timeAgoStringSuffix: 'hour ago',
            timeAgoStringSuffixPlural: 'hours ago',
            abrevTimeAgoSuffix: 'h',
            unitOfTime: 'hour',
            durationSeconds: 3600,
            setUpdateTimer: true,
            updateDelaySeconds: 30 * 60
        },
        {
            timeAgoStringSuffix: 'minute ago',
            timeAgoStringSuffixPlural: 'minutes ago',
            abrevTimeAgoSuffix: 'm',
            unitOfTime: 'minute',
            durationSeconds: 60,
            setUpdateTimer: true,
            updateDelaySeconds: 30
        },
        {
            timeAgoString: 'Just now',
            durationSeconds: 1,
            setUpdateTimer: true,
            updateDelaySeconds: 30
        }
    ];

    constructor(private _cdRef: ChangeDetectorRef, private _ngZone: NgZone) {}

    transform(value: Date|number|string, abreviated: boolean = false): string {
        if (typeof value === 'undefined') {
            return '';
        }

        this._abbreviated = abreviated;

        // Ensure value is a date object
        value = this.ensureValueIsDate(value);

        // Calculate elapsed seconds
        const elapsedSeconds = this.getElapsedSeconds(value);

        // Determine longest elapsed time period
        const largestElapsedTimePeriod = this.getLargestElapsedTimePeriod(elapsedSeconds);

        // Get an updated string
        const timeAgoString = this.getTimeAgoString(largestElapsedTimePeriod, value);

        // Set a timer for the next update if necessary
        if (largestElapsedTimePeriod.setUpdateTimer && largestElapsedTimePeriod.updateDelaySeconds) {
            this.setTimerForUpdate(largestElapsedTimePeriod.updateDelaySeconds);
        }

        return timeAgoString;
    }

    ensureValueIsDate(value: any): Date|number {
        if (!(value instanceof Date) && typeof value === 'string') {
            return new Date(value);
        } else if(!(value instanceof Date)){
            return toDate(value);
        }
        return value;
    }

    getElapsedSeconds(sinceTime: Date|number) {
        if (!(sinceTime instanceof Date)) {
            return differenceInSeconds(new Date(Date.now()), toDate(sinceTime));
        }
        const now = new Date().getTime();
        const timestamp = new Date(sinceTime).getTime();
        return Math.floor((now - timestamp) / 1000);
    }

    toMoment(sinceTime: Date|number) {
        if (!(sinceTime instanceof Date)) {
            return toDate(sinceTime);
        }

        return sinceTime;
    }

    getLargestElapsedTimePeriod(elapsedSeconds: number): TimePeriod {
        let largestTimePeriod = _(this._descendingTimePeriods).last() as TimePeriod;
        _(this._descendingTimePeriods).some((timePeriod) => {
            if (elapsedSeconds >= timePeriod.durationSeconds) {
                largestTimePeriod = timePeriod;
                return true;
            }
            return false;
        });
        return largestTimePeriod;
    }

    getTimeAgoString(timePeriod: TimePeriod, date: Date|number): string {

        if (timePeriod.timeAgoString) {

            // Return the exact time string if one has been specified
            return timePeriod.timeAgoString;
        }

        if(!(date instanceof Date)){
            date = toDate(date);
        }

        // Determine how many periods have elapsed
        let periodsElapsed = 0;
        switch(timePeriod.unitOfTime){
            case 'year': {
                periodsElapsed = differenceInYears(new Date(Date.now()), date);
                break;
            }
            case 'month': {
                periodsElapsed = differenceInMonths(new Date(Date.now()), date);
                break;
            }
            case 'week': {
                periodsElapsed = differenceInWeeks(new Date(Date.now()), date);
                break;
            }
            case 'day': {
                periodsElapsed = differenceInDays(new Date(Date.now()), date);
                break;
            }
            case 'hour': {
                periodsElapsed = differenceInHours(new Date(Date.now()), date);
                break;
            }
            case 'minute':{
                periodsElapsed = differenceInMinutes(new Date(Date.now()), date);
                break;
            }
        }

        // Get correct suffix for count
        let useSuffix: keyof TimePeriod = 'timeAgoStringSuffix';

        if (periodsElapsed > 1) {
            useSuffix = 'timeAgoStringSuffixPlural';
        }

        if (this._abbreviated) {
            useSuffix = 'abrevTimeAgoSuffix';
        }

        const suffix = timePeriod[useSuffix];

        // Build and return the time string
        return this._abbreviated ? `${periodsElapsed}${suffix}` : `${periodsElapsed} ${suffix}`;
    }

    setTimerForUpdate(seconds: number) {

        // Don't set a timer if we already have one set
        if (this._currentTimer) {
            return;
        }

        // Execute 'setTimeout' outside of angular so the ngZone's bindings don't trigger unnecessary change detection
        this._ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return this._currentTimer = window.setTimeout(() => {
                    this.removeTimer();
                    this._ngZone.run(() => this._cdRef.detectChanges());
                }, seconds * 1000);
            }
        });
    }

    ngOnDestroy(): void {
        this.removeTimer();
    }

    removeTimer() {
        if (this._currentTimer) {
            window.clearTimeout(this._currentTimer);
            this._currentTimer = null;
        }
    }
}
