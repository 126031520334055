import { OpportunitiesGridStateModel } from '@app/app/store/opportunitiesGrid/opportunities-grid-state-model';

export class GetOpportunitiesGridState {
  static readonly type = '[OpportunitiesGridState] GetOpportunitiesGridState';
  constructor() { }
}

export class SaveOpportunitiesGridState {
  static readonly type = '[OpportunitiesGridState], SaveOpportunitiesGridState';
  constructor(public gridState: OpportunitiesGridStateModel) { }
}

export class ClearOpportunitiesGridState {
  static readonly type = '[OpportunitiesGridState] ClearOpportunitiesGridState';
  constructor() { }
}
