@for (display of values; track display) {
    <div class="column-grid justify-between mat-label-medium py-2 business-values-row">
        <div class="column-grid gap-2 place-items-center" [matTooltip]="display.tooltip">
            <span class="mat-label-medium text-lendio-warm-gray-900">{{display.label || ''}}</span>
            @if(!!display.labelIcon) {
            <mat-icon class="text-lendio-warm-gray-400">
                {{ display.labelIcon }}
            </mat-icon>
            }
        </div>
        <span class="mat-body-medium">
            <ng-container [ngSwitch]="display.type">
                <ng-container *ngSwitchCase="'currency'">
                    {{(display.value | currency) || 0 }}
                </ng-container>
                <ng-container *ngSwitchCase="'bool'">
                    {{(display.value ? 'Yes' : 'No') || 'N/A' }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{ display.value || 'N/A' }}
                </ng-container>
            </ng-container>
        </span>
    </div>
}
