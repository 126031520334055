import { Component, OnInit } from '@angular/core';
import { share } from 'rxjs/operators';
import { HijackService } from 'src/app/services/hijack.service';
import * as _ from 'underscore';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-hijack-smart',
  templateUrl: './hijack-smart.component.html',
  styleUrls: ['./hijack-smart.component.scss']
})
export class HijackSmartComponent implements OnInit {

  constructor(private hijackService: HijackService) {
    this._loadPartners = _.debounce(this._loadPartners, 400);
  }

  searchResults: Observable<any> | null;
  partnersSub: Subscription;
  users: Observable<any>;
  usersSub: Subscription;

  ngOnInit(): void {
  }

  search(term: string) {
    if (term && term.length >= 3) {
      this._loadPartners(term);
    }

    if (term === '') {
      if (this.partnersSub) {
        this.partnersSub.unsubscribe();
      }
      this.searchResults = null;
    }
  }

  private _loadPartners(term: string) {
    if (this.partnersSub) {
      this.partnersSub.unsubscribe();
    }
    this.searchResults = this.hijackService.getPartners(term).pipe(share());
    this.partnersSub = this.searchResults.subscribe();
  }

  getUsers(partner: { id: number, type: string }) {
    if (this.usersSub) {
      this.usersSub.unsubscribe();
    }
    this.users = partner.type === 'lender'
        ? this.hijackService.getInstitutionUsers(partner.id).pipe(share())
        : this.hijackService.getAffiliateUsers(partner.id).pipe(share());
    this.usersSub = this.users.subscribe();
  }
}
