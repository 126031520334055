import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { WebhookSubscriptionsResponse } from '../interfaces/webhooks-response.model';

@Injectable({
    providedIn: 'root'
})
export class WebhooksService {
  private url = environment.apiUrl + '/l/v1/internal/webhook-subscriptions';
  private embeddedUrl = environment.apiUrl + '/e/v1/webhook-subscriptions';

  constructor(private httpClient: HttpClient) { }

  getWebhookSubscriptions(isEmbedded: boolean): Observable<WebhookSubscriptionsResponse> {
    return this.httpClient.get<WebhookSubscriptionsResponse>(this.getBaseUrl(isEmbedded));
  }

  getWebhookTypes(isEmbedded: boolean): Observable<any> {
    return this.httpClient.get<any>(this.getBaseUrl(isEmbedded) + '/types');
  }

  createWebhookSubscription({eventId, url}: {eventId: number, url: string}, isEmbedded: boolean): Observable<any> {
    const data = {
      eventId,
      url
    };
    return this.httpClient.post<any>(this.getBaseUrl(isEmbedded) + '/register', data);

  }

  updateWebhookSubscription(id: number, {eventId, url}: {eventId: number, url: string}, isEmbedded: boolean): Observable<any> {
    const data = {eventId, url};
    return this.httpClient.put<any>(this.getBaseUrl(isEmbedded) + `/${id}`, data);
  }
  deleteWebhookSubscription(id: number, isEmbedded: boolean): Observable<any> {
    return this.httpClient.delete<any>(this.getBaseUrl(isEmbedded) + `/${id}`);
  }

  getWebhookTestSchema(isEmbedded: boolean): Observable<any> {
    return this.httpClient.get(this.getBaseUrl(isEmbedded) + '/payloadSchemas');
  }

  testWebhookSubscription(subscriptionId: number, payload: object, isEmbedded: boolean): Observable<any> {
    const payloadJson = JSON.stringify(payload);
    const data = {
      isTest: true,
      subscriptionId,
      payload: payloadJson
    };

    return this.httpClient.post(this.getBaseUrl(isEmbedded) + '/invoke', data);
  }

  private getBaseUrl(isEmbedded: boolean) {
    return isEmbedded ? this.embeddedUrl : this.url;
  }
}
