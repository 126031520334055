import { Component, computed, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EarlyAccessFeatureService } from "@app/app/components/settings/early-access/early-access-feature.service";

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() link: string ;

  saasFeatureBadgeEnabled = computed(() => this._earlyAccessService.earlyAccessShowBadge());

  constructor(
    public router: Router,
    private _earlyAccessService: EarlyAccessFeatureService
  ) { }

}
