import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { RestrictedSliderComponent } from './restricted-slider.component';


@NgModule({
  declarations: [ RestrictedSliderComponent ],
  imports: [
    FormsModule,
    CommonModule,
    MatSliderModule,
  ],
  exports: [
    RestrictedSliderComponent,
  ]
})
export class RestrictedSliderModule { }
