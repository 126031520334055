import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { GetCommercialCreditReport, GetOwnerCreditReport, GetSavedOwnerReports } from '@app/app/store/finances/finances.actions';
import { CreditReportsService } from '@app/app/services/credit-reports.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CommercialCreditReport, ConsumerCreditReport } from '@app/app/interfaces/credit-reports/credit-report.model';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';

export interface FinancesStateModel {
  personalCreditReports: ConsumerCreditReport[];
  commercialCredit: CommercialCreditReport;
  loading: boolean;
  outstandingLoans: OutstandingLoan[];
}

export interface OutstandingLoan {
  position: number;
  lenderName: string;
  balance: number;
  updatedDate: string;
  paidOff: boolean;
}

@State<Partial<FinancesStateModel>>({
  name: 'Finances',
  defaults: {
    loading: false,
    personalCreditReports: [],
    outstandingLoans: [],
  }
})
@Injectable()
export class FinancesState {

  @Selector()
  static ownersCreditReports(state: FinancesStateModel) {
    return state.personalCreditReports;
  }

  @Selector()
  static commercialCredit(state: FinancesStateModel) {
    return state.commercialCredit;
  }

  @Selector()
  static outstandingLoans(state: FinancesStateModel) {
    return state.outstandingLoans;
  }

  @Selector()
  static loading(state: FinancesStateModel) {
    return state.loading;
  }

  @Selector()
  static creditReportByContactId(contactId: number) {
    return createSelector([FinancesState], ({Finances}) => {
      return Finances?.personalCreditReports.find(ocr => ocr.contactId === contactId);
    })
  }

  constructor(
    private creditService: CreditReportsService,
  ) {}

  @Action(GetCommercialCreditReport)
  GetCommercialCreditReport(
    ctx: StateContext<FinancesStateModel>,
    { borrowerId }: GetCommercialCreditReport,
  ) {
    ctx.patchState({ loading: true });
    return this.creditService.getCommercialCreditReport(borrowerId).pipe(
      catchError( err => {
        ctx.patchState({ loading: false });
        return throwError(err);
      }),
      tap( response => {
        const commercialCredit = response.data as CommercialCreditReport;
        ctx.patchState({
          loading: false,
          commercialCredit,
        })
      }),
    );
  }

  @Action(GetOwnerCreditReport)
  GetOwnerCreditReport(
    ctx: StateContext<FinancesStateModel>,
    { contactId }: GetOwnerCreditReport,
  ) {
    ctx.patchState({ loading: true });
    return this.creditService.getOwnerCreditReportByContactId(contactId).pipe(
      catchError( err => {
        ctx.patchState({ loading: false });
        return throwError(err);
      }),
      tap( response => {
        const ownerCredit = response.data as ConsumerCreditReport;
        const byContactId = ocr => ocr.contactId === contactId;
        const personalCreditReports = !!ctx.getState().personalCreditReports.find(byContactId)
          ? updateItem<ConsumerCreditReport>( byContactId, ownerCredit)
          : insertItem<ConsumerCreditReport>(ownerCredit);
        ctx.setState(patch({
          personalCreditReports,
          loading: false,
        }));
      }),
    );
  }

  @Action(GetSavedOwnerReports)
  GetSavedOwnerReports(
    { patchState }: StateContext<FinancesStateModel>,
    { borrowerId }: GetSavedOwnerReports,
  ) {

    patchState({ loading: true });
    return this.creditService.getSavedOwnerCreditReports(borrowerId).pipe(
      catchError( err => {
        patchState({ loading: false });
        return throwError(err);
      }),
      tap( response => {
        patchState({
          personalCreditReports: response?.data?.ownerReports,
          commercialCredit: response?.data?.commercialReport,
          loading: false,
        });
      }),
    );
  }

}
