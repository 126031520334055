@if (collapsed || navMenuCollapsed) {
    <div class="sidenav-user-container">
        <button class="sidenav-user-menu-button"
                #userSettingsMenuTrigger="matMenuTrigger"
                [class.active]="userSettingsMenuTrigger.menuOpen"
                [matMenuTriggerFor]="userSettingsMenu"
                (menuOpened)="userSettingsMenuTrigger.menuOpen"
                (menuClosed)="userSettingsMenuTrigger.menuClosed">
            <mat-icon class="user-avatar-icon" fontSet="material-icons-outlined">perm_identity</mat-icon>
        </button>
        <mat-menu #userSettingsMenu="matMenu"
                  [hasBackdrop]="true">
            <button mat-menu-item (click)="selectSettings()">
                <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                <span>Settings</span>
            </button>
            <button mat-menu-item (click)="selectLogout()">
                <mat-icon>logout</mat-icon>
                <span>Sign out</span>
            </button>
        </mat-menu>
    </div>
} @else {
    <div class="sidenav-user-container">
        <button class="sidenav-user-menu-button"
                #userSettingsMenuTrigger="matMenuTrigger"
                [class.active]="userSettingsMenuTrigger.menuOpen"
                [matMenuTriggerFor]="userSettingsMenu"
                (menuOpened)="userSettingsMenuTrigger.menuOpen"
                (menuClosed)="userSettingsMenuTrigger.menuClosed">
            <mat-icon class="user-avatar-icon"
                      fontSet="material-icons-outlined">
                <span>perm_identity</span>
            </mat-icon>
            <div class="sidenav-container-user-info">
                <span class="sidenav-username">{{ userFullName }}</span>
                @if (organizationName) {
                    <span class="sidenav-institution">{{ organizationName }}</span>
                }
            </div>
            <mat-icon class="user-settings-icon"
                      fontSet="material-icons-outlined">
                <span>settings</span>
            </mat-icon>
        </button>
        @if (saasFeatureBadgeEnabled()) {
            <app-saas-new-feature-badge></app-saas-new-feature-badge>
        }

        <mat-menu #userSettingsMenu="matMenu"
                  [hasBackdrop]="true">
            <ng-template matMenuContent>
                <div [style.width.px]="263">
                    <button mat-menu-item
                            (click)="selectSettings()">
                        <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                        <span>Settings</span>
                    </button>
                    <button mat-menu-item
                            (click)="selectLogout()">
                        <mat-icon>logout</mat-icon>
                        <span>Sign out</span>
                    </button>
                </div>
            </ng-template>
        </mat-menu>
    </div>
}
