import { Component, Input } from '@angular/core';
import { DocumentsTableComponent } from '../documents-table/documents-table.component';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { Select } from '@ngxs/store';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { Observable } from 'rxjs';
import { Document } from '@app/app/interfaces/document.model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  standalone: true,
  imports: [
    DocumentsTableComponent,
    MatCardModule,
    CommonModule
  ],
})
export class DocumentsComponent {
  // We can get docs by deal or borrower. One of these will be undefined.
  // Use the defined id to decide whether the UI should reflect the
  // ApplicationDetails (deal) or BusinessDetails (borrower) view.
  @Input() dealId?: number;
  @Input() borrowerId?: number;

  @Select(DocumentsState.documents) documents$: Observable<Document[]>;
}
