<app-notification-sidenav 
    [notifications]="notifications$ | async"
    [allRead]="allRead$ | async"
    [togglingAllRead]="togglingAllRead$ | async"
    [deletingAll]="deletingAll$ | async"
    (toggle)="close()"
    (bulkDelete)="bulkDelete()"
    (bulkRead)="bulkRead()"
>
</app-notification-sidenav>
