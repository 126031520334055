<p class="text-base font-bold">Industry</p>
<mat-form-field class="w-full !text-sm" *ngIf="!loading">
    <mat-label>Search industries...</mat-label>
    <input type="text" matInput
           autocomplete="off"
           placeholder="Ex. Mechanical contractors"
           #searchBox="matInput"
           [matAutocomplete]="results"
           [(ngModel)]="searchText"
           (keyup)="search(); hasSearched = false;"
           (focus)="hasSearched = false">
    <mat-icon matPrefix
              class="!text-red-500"
              *ngIf="errored"
              matTooltip="There was an error while attempting to retrieve NAICS codes. Please try searching again.">
        warning
    </mat-icon>
    <button mat-button mat-icon-button matSuffix (click)="searchText = ''; hasSearched = false;" *ngIf="searchText">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<app-horizontal-loader *ngIf="loading"></app-horizontal-loader>
<app-horizontal-loader *ngIf="loading"></app-horizontal-loader>
<app-horizontal-loader *ngIf="loading"></app-horizontal-loader>

<mat-autocomplete #results="matAutocomplete">
    <!-- Loading results -->
    <mat-option disabled *ngIf="searching">
        <app-dot-loader-scale></app-dot-loader-scale>
    </mat-option>

    <!-- We have results -->
    <mat-option
            *ngFor="let result of searchResults"
            [matTooltip]="getExamples(result.examples)"
            [matTooltipClass]="'leading-tight whitespace-pre-line'"
            [matTooltipShowDelay]="400"
            (click)="setCode(result.code, result.title)">
        {{ result.title }}
    </mat-option>

    <!-- No results -->
    <mat-option disabled *ngIf="!searching && searchResults && searchResults.length === 0 && hasSearched">
        No results for '{{ searchText }}'
    </mat-option>
</mat-autocomplete>
