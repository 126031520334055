<div style="font-size: 12px" *ngIf="initialized">
  <mat-form-field appearance="fill"
                  [matTooltip]="selectedAttributeOption.mustAllowNull ? 'For some borrowers this value will be blank.' : ''">
    <mat-label [ngStyle]="{'color': labelColor}">
      Attribute
      <mat-icon class="text-yellow-500"
                *ngIf="selectedAttributeOption.mustAllowNull">
        warning
      </mat-icon>
    </mat-label>
    <mat-select #attribute="matSelect"
                [value]="selectedAttributeOptionId"
                (valueChange)="setSelectedAttribute($event)"
                data-cy="qualifier-component-attribute-field-select">
      <ng-container *ngFor="let question of questions">
        <mat-option [value]="question.id"
                    [matTooltip]="question.mustAllowNull ? 'For some borrowers this value will be blank.' : ''"
                    [disabled]="!hasWriteAccess || (disabledAttributeFields && disabledAttributeFields.length > 0 &&
                                disabledAttributeFields.includes(question.alias) || question.alias === 'blank')"
                    data-cy="qualifier-component-attribute-field-option">
          <mat-icon class="text-yellow-500 !text-xs" style="width: 12px; height: 12px; padding: 0; margin-right: 4px"
                    *ngIf="question.mustAllowNull && question.id !== attribute.value">
            warning
          </mat-icon>
          {{ question.shortName }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="ml-3" *ngIf="selectedAttributeOptionId || attribute.value">
    <mat-label [ngStyle]="{'color': labelColor}">Operator</mat-label>
    <mat-select #attrOperator="matSelect"
                [value]="getSelectedOperator()"
                [disabled]="getOperatorDisabled()"
                (valueChange)="setSelectedOperator()"
                data-cy="qualifier-component-operator-field-select">
      <mat-option *ngFor="let op of operatorOptions" [value]="op.value"
                  [disabled]="!hasWriteAccess || (op.value === 'blank' || disableOperatorValue(op.value))"
                  data-cy="qualifier-component-operator-field-option">
        {{ op.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="ml-3" *ngIf="selectedAttributeOptionId || attribute.value">
    <mat-label [ngStyle]="{'color': labelColor}">Value</mat-label>
    <mat-select #attrValue="matSelect"
                *ngIf="valueType === 'displayBlank'"
                [value]="valueFieldOption"
                (valueChange)="setValue()"
                [disabled]="true">
      <mat-option value="blank">
        Blank
      </mat-option>
    </mat-select>
    <mat-select #attrValue="matSelect"
                *ngIf="valueType === 'boolean'"
                [value]="valueFieldOption"
                (valueChange)="setValue()"
                [disabled]="!hasWriteAccess || attrOperator && attrOperator.value === 'blank'">
      <mat-option value="1">
        Yes
      </mat-option>
      <mat-option value="0">
        No
      </mat-option>
    </mat-select>
    <input #attrValue="matInput"
           matInput
           type="string"
           *ngIf="valueType === 'minMax'"
           [value]="valueFieldOption"
           (keypress)="onKeyPressValidateKey($event)"
           (keyup)="onKeyUpFormatValueFieldInput($event)"
           (focus)="onFocusValueFieldInput()"
           (change)="setValue()"
           [disabled]="!hasWriteAccess || attrOperator && attrOperator.value === 'blank'"
           data-cy="qualifier-component-value-input">
    <mat-select #attrValue="matSelect"
                *ngIf="valueType === 'dropdown'"
                multiple
                [value]="valueFieldOption"
                (valueChange)="setValue()"
                [disabled]="attrOperator && attrOperator.value === 'blank'">
      <ng-container *ngIf="selectedAttributeOption.type !== 'states'">
        <mat-option
          *ngFor="let option of selectedAttributeOption.options"
          [value]="option.value"
          [disabled]="!hasWriteAccess">
          {{ option.label }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="selectedAttributeOption.type === 'states'">
        <mat-option *ngFor="let option of states"
                    [disabled]="!hasWriteAccess"
                    [value]="option.id">
          {{ option.name }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!--  When adding a Base Qualifier -->
  <div class="cursor-pointer pl-2 align-middle pb-3 inline-block" *ngIf="hasWriteAccess && !showConfirmDeleteButton && !qualifier">
    <button mat-flat-button color="primary"
            [disabled]="!getCanSave()"
            (click)="addQualifier()"
            data-cy="qualifier-component-save">
      Save
    </button>
    <button *ngIf="showCancelButton()" mat-button color="warn" (click)="cancelClicked()">
      Cancel
    </button>
  </div>

  <!--  When adding an IF Qualifier -->
  <span class="cursor-pointer" *ngIf="hasWriteAccess && qualifier && !showConfirmDeleteButton && !showUpdateButton">
    <button style="vertical-align: super !important;" mat-button color="primary"
            *ngIf="showAndButton && !addingIfQualifier"
            [disabled]="(exceptionGroup.conditions && exceptionGroup.conditions.length === 0) ||
                        (baseQualifiers && baseQualifiers.length === 0)"
            (click)="handleAnd()">
      AND
    </button>
    <span class="text-gray-1000 cursor-pointer pl-1 hover:text-red-600"
          (click)="(showConfirmDeleteButton = true)">
      <mat-icon data-cy="qualifier-component-cancel">cancel</mat-icon>
    </span>
  </span>
  <!--  The cancel warning to confirm a delete, available next to each qualifier -->
  <span class="text-red-400 cursor-pointer pl-3" style="vertical-align: super !important;" *ngIf="showConfirmDeleteButton && !showUpdateButton">
    <button mat-flat-button color="warn"
            (click)="removeClicked()"
            data-cy="qualifier-component-confirm-delete">
      Confirm Delete
    </button>
    <button mat-button color="warn"
            (click)="cancelClicked()"
            data-cy="qualifier-component-cancel-delete">
      Cancel
    </button>
  </span>
  <!-- The buttons used after modifying a qualifier row, available next to each qualifier -->
  <span class="cursor-pointer pl-3" style="vertical-align: super !important;" *ngIf="!showConfirmDeleteButton && showUpdateButton && qualifier">
    <button mat-flat-button color="primary"
            [disabled]="!getCanSave()"
            (click)="emitUpdate()"
            data-cy="qualifier-component-update">
      Update
    </button>
    <button mat-button color="warn" (click)="emitCancelUpdate()">
      Cancel
    </button>
  </span>
</div>
