import { Component, OnInit, Inject, computed } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-upload-contract-dialog',
  templateUrl: './upload-contract-dialog.component.html',
  styleUrls: ['./upload-contract-dialog.component.scss']
})
export class UploadContractDialogComponent implements OnInit {

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number },
    private _themeService: LendioAngularMaterialThemeService
  ) { }

  ngOnInit(): void {}
}
