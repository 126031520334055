<div [class.old-theme-funding-desk-old]="oldThemeEnabled()">
<div class="fd-container" data-cy="contact-funding-desk">
	<div class="flex justify-between pb-0 flex-no-wrap whitespace-no-wrap">
		<h2 class="!m-0">Contact Funding Desk</h2>
		<button mat-flat-button color="primary" class="pull-righcursor-pointer" data-cy="fd-request-btn" (click)="expandStatus === false ? fdPanel.open() : fdPanel.close()">
			{{ expandStatus === false ? 'Add' : 'Close' }}
		</button>
	</div>

	<mat-expansion-panel
		hideToggle="true"
		class="mat-elevation-z0 fd-request"
		[(expanded)]="expandStatus"
		#fdPanel
		(afterExpand)="handleAfterExpand()"
		(afterCollapse)="handleAfterCollapse()"
	>

		<mat-form-field appearance="fill" class="add-new !my-4">
			<textarea
				matInput
				data-cy="fd-request-input"
				placeholder="What can I help you with?"
				[(ngModel)]="newRequest"
				[ngModelOptions]="{standalone: true}"
				#requestTextArea
				class="!px-4"
			></textarea>
		</mat-form-field>

		<div>
			<div class="pull-right">
				<button mat-button color="primary" (click)="cancelComment()" class="cancel-comment">Cancel</button>
				<button
					mat-flat-button
					color="primary"
					type="button"
					data-cy="fd-request-submit-btn"
					[disabled]="newRequest === ''"
					(click)="createNewRequest(newRequest)"
					class="!ml-3"
				>Send</button>
			</div>
		</div>

	</mat-expansion-panel>

	<div *ngIf="fundingDeskRequests && fundingDeskRequests.length > 0" class="divider mt-3"></div>

	<div *ngIf="fundingDeskRequests && fundingDeskRequests.length > 0" class="requests">
		<app-single-request-chain-old
			*ngFor="let request of fundingDeskRequests; let i = index"
			[requestChain]="request"
			[requestIndex]="i"
			(comment)="addNewComment($event)"
		></app-single-request-chain-old>
	</div>
</div>
</div>
