import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from "@angular/material/select";
import { ToDateObjPipeModule } from 'src/app/pipes/toDateObj/to-date-obj-pipe.module';
import { DealTypeModule } from '../../pipes/deal-type/deal-type.module';
import { StatusDisplayModule } from '../../pipes/status-display/status-display.module';
import { MatMenuModule } from '@angular/material/menu';
import { DealStatusDisplayModule } from '../../pipes/deal-status-display/deal-status-display.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from "ag-grid-angular";
import { AgChartsAngularModule } from "ag-charts-angular";
import { DrawerStepStatusService } from 'src/app/services/drawer-step-status.services';
import { ClientMilestonesComponent } from './client-milestones/client-milestones.component';
import { ConfirmGridDeactivateComponent } from './confirm-grid-deactivate/confirm-grid-deactivate.component';
import { OpportunitiesService } from "src/app/services/opportunities.service";
import { RevenueCardComponent } from './revenue-card/revenue-card.component';
import { ClientsComponent } from "@app/app/components/clients/clients.component";
import { BooleanRendererComponent } from "src/app/components/clients/grid-components/boolean-renderer/boolean-renderer.component";
import { GroupHeaderComponent } from "src/app/components/clients/grid-components/group-header/group-header.component";
import { LeadSubmissionModule } from 'src/app/components/lead-submission/lead-submission.module';
import { LoadersModule } from "@app/app/components/loaders/loaders.module";
import { ClientsRoutingModule } from './clients-routing.module';
import { CommissionsTooltipComponent } from './grid-components/tooltips/commissions-tooltip/commissions-tooltip.component';
import { PayoutTooltipComponent } from './grid-components/tooltips/payout-tooltip/payout-tooltip.component';


@NgModule({
  declarations: [
    ClientMilestonesComponent,
    ConfirmGridDeactivateComponent,
    RevenueCardComponent,
    ClientsComponent,
    BooleanRendererComponent,
    GroupHeaderComponent,
    CommissionsTooltipComponent,
    PayoutTooltipComponent,
  ],
  imports: [
    CommonModule,
    ClientsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    AgChartsAngularModule,
    LoadersModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    DealTypeModule,
    StatusDisplayModule,
    DealStatusDisplayModule,
    ToDateObjPipeModule,
    LeadSubmissionModule,
  ],
  exports: [],
  providers: [
    DrawerStepStatusService,
    OpportunitiesService
  ]
})
export class ClientsModule {
}
