import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResendContractButtonComponent } from './resend-contract-button/resend-contract-button.component';
import { ResendContractButtonSmartComponent } from './resend-contract-button-smart/resend-contract-button-smart.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    ResendContractButtonComponent,
    ResendContractButtonSmartComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [
    ResendContractButtonSmartComponent
  ]
})
export class ResendContractButtonModule { }
