import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadContractButtonComponent } from './upload-contract-button/upload-contract-button.component';
import { UploadContractButtonSmartComponent } from './upload-contract-button-smart/upload-contract-button-smart.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    UploadContractButtonComponent,
    UploadContractButtonSmartComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [
    UploadContractButtonSmartComponent
  ]
})
export class UploadContractButtonModule { }
