<div>
    <div class="flex justify-between pb-0 flex-no-wrap whitespace-no-wrap">
        <h2>Lendio reps</h2>
        <button
            mat-button
            (click)="toggleExpand()"
            [color]="'primary'">
            {{ buttonName }}
        </button>
    </div>
    
    @if (expanded && applicationDetails.fundingDesk) {
        <div class="py-3">
            <h3 class="!font-bold mat-body-1">Funding Desk</h3>
            <p class="mat-body-2">{{ applicationDetails.fundingDesk.first }} {{ applicationDetails.fundingDesk.last }}</p>
            <a href="mailto:{{ applicationDetails.fundingDesk.email }}" class="text-blue-500 mat-body-2">{{ applicationDetails.fundingDesk.email }}</a>
        </div>
    }

    @if (expanded) {
        <div class="py-3">
            <h3 class="!font-bold mat-body-1">Lendio Team Lead</h3>
            <p class="mat-body-2">{{applicationDetails.teamLead.fullName}}</p>
            <a href="mailto:{{applicationDetails.teamLead.user.email}}" class="text-blue-500 mat-body-2">{{applicationDetails.teamLead.user.email}}</a>
        </div>
    }
</div>
