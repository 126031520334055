import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { _CustomizationRequest, CustomizationRequest } from 'src/app/interfaces/embedded-customization.model'

@Injectable({
  providedIn: 'root'
})
export class EmbeddedService {

  constructor(
    private http: HttpClient
    ) { }

  // TODO: remove / deprecate embedded-service actions
  getEmbeddedOptions(token: string | number): { [key: string]: HttpHeaders } {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`
      })
    };

    return httpOptions
  }

  // API powered affiliate credentials endpoint
  getEmbeddedCredentials(embeddedAffiliateId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/affiliate/${embeddedAffiliateId}/embedded/credentials`);
  }

  // API powered customization endpoint
  getCustomizations(embeddedAffiliateId: number, type?: string): Observable<any> {
    let url = `${environment.apiUrl}/affiliate/${embeddedAffiliateId}/customizations`;

    if (type) {
      url = `${url}?type=${type}`
    }

    return this.http.get(url);
  }

  // TODO: remove / deprecate embedded-service actions
  createEmbeddedAffiliateUserToken(embeddedAffiliateId: number | string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/affiliate/${embeddedAffiliateId}/embedded/token`, {});
  }

  // TODO: remove / deprecate embedded-service actions
  getPartner(token: string | number, embeddedAffiliateId: number | string): Observable<any> {
    const options = this.getEmbeddedOptions(token)

    return this.http.get(`${environment.embeddedApiUrl}/affiliate/${embeddedAffiliateId}/partner`, options);
  }

  // TODO: remove / deprecate embedded-service actions
  _updateCustomization(token: string | number, embeddedAffiliateId: number | string, customizationId: string | number, customizationRequest: _CustomizationRequest): Observable<any> {
    const options = this.getEmbeddedOptions(token)

    return this.http.put(`${environment.embeddedApiUrl}/affiliate/${embeddedAffiliateId}/customizations/${customizationId}`, customizationRequest, options);
  }

  // API powered customization endpoint
  updateCustomization(embeddedAffiliateId: number, customizationId: number | string, customizationRequest: CustomizationRequest): Observable<any> {
    return this.http.put(`${environment.apiUrl}/affiliate/${embeddedAffiliateId}/customizations/${customizationId}`, customizationRequest);
  }

  // TODO: remove / deprecate embedded-service actions
  _postCustomization(token: string | number, embeddedAffiliateId: number | string, customizationRequest: _CustomizationRequest): Observable<any> {
    const options = this.getEmbeddedOptions(token)

    return this.http.post(`${environment.embeddedApiUrl}/affiliate/${embeddedAffiliateId}/customizations`, customizationRequest, options);
  }

  // API powered customization endpoint
  postCustomization(embeddedAffiliateId: number, customizationRequest: CustomizationRequest): Observable<any> {

    return this.http.post(`${environment.apiUrl}/affiliate/${embeddedAffiliateId}/customizations`, customizationRequest);
  }
}
