import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { OpportunitiesGridStateModel } from "./opportunities-grid-state-model";
import { Injectable } from "@angular/core";
import {
  ClearOpportunitiesGridState,
  GetOpportunitiesGridState,
  SaveOpportunitiesGridState
} from "./opportunities-grid.actions";

@State<OpportunitiesGridStateModel>({
  name: 'opportunitiesGridState',
  defaults: {
    columnState: undefined,
    columnGroupState: undefined,
    filterState: undefined,
    gridStateCached: false
  }
})
@Injectable()
export class OpportunitiesGridState {

  @Selector() static opportunitiesGridState(state: OpportunitiesGridStateModel) {
    return {
      columnState: state.columnState,
      columnGroupState: state.columnState,
      filterState: state.filterState
    };
  }

  constructor(private _store: Store) { }

  @Action(GetOpportunitiesGridState)
  getOpportunitiesGridState({ getState }: StateContext<OpportunitiesGridStateModel>, {}: GetOpportunitiesGridState) {
    if (getState().gridStateCached) {
      return {
        columnState: getState().columnState,
        columnGroupState: getState().columnGroupState,
        filterState: getState().filterState
      };
    }
  }

  @Action(SaveOpportunitiesGridState)
  saveOpportunitiesGridState({ patchState }: StateContext<OpportunitiesGridStateModel>, { gridState }: SaveOpportunitiesGridState) {
    return patchState({
      columnState: gridState.columnState,
      columnGroupState: gridState.columnGroupState,
      filterState: gridState.filterState,
      gridStateCached: true,
    })
  }

  @Action(ClearOpportunitiesGridState)
  clearOpportunitiesGridState({ patchState }: StateContext<OpportunitiesGridStateModel>, {}: ClearOpportunitiesGridState) {
    return patchState({
      columnState: undefined,
      columnGroupState: undefined,
      filterState: undefined,
      gridStateCached: false,
    })
  }

}
