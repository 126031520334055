import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export const passwordMatchesValidator: ValidatorFn = (formControl: AbstractControl): ValidationErrors | null => {
  const password = formControl.get('newPassword');
  const confirmPassword = formControl.get('repeatNewPassword');

  return password?.value === confirmPassword?.value ? null : { passwordDoesNotMatch: true };
};

export function uniqueNameValidator(existingValues: any[], currentName?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const enteredName = control.value;
    const isDuplicate = existingValues.some(value => value.name.toLowerCase() === enteredName.toLowerCase());
    const isCurrent = enteredName.toLowerCase() === currentName?.toLowerCase() || '';
    const isDuplicateError = isDuplicate && !isCurrent

    return isDuplicateError ? { duplicateName: true } : null;
  };
}
