import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-deal-table',
  templateUrl: './deal-table.component.html',
  styleUrls: ['./deal-table.component.scss']
})
export class DealTableComponent implements OnInit {
  @Input() header: string;
  @Input() deals: any = [];

  constructor() { }

  ngOnInit(): void {
  }
}
