import { Component, OnInit, OnDestroy, Input, computed } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Alert } from '../../../interfaces/alert.model';
import { Approval } from 'src/app/interfaces/approval/approval.model';
import { Label } from '../../../interfaces/label.model';
import { PortalUser } from '../../../interfaces/portal-user.model';
import {
  GetApplicationDetails,
  ClearApplicationDetailsStore,
  GetApplicationFields,
} from '../../../store/application-details/application-details.actions';
import { ApplicationDetailsState } from '../../../store/application-details/application-details.state';
import { Observable, Subject } from 'rxjs';
import { ApplicationDetails } from '../../../interfaces/application-details.model';
import { takeUntil } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { CreateNewAlert, DismissAlert } from '../../../store/global-alerts/global-alerts.actions';
import { GlobalAlertsState } from '../../../store/global-alerts/global-alerts.state';
import { GetLabels } from '../../../store/labels/labels.actions';
import { LabelsState } from '../../../store/labels/labels.state';
import { ApprovalsState } from '../../../store/offers/approvals.state';
import { GetPortalUsers } from '../../../store/portal-users/portal-users.actions';
import { PortalUsersState } from '../../../store/portal-users/portal-users-state.service';
import { EventBusService } from '../../../services/event-bus.service';
import { GetAdverseActionNotice } from '../../../store/adverse-action-notice/adverse-action-notice.actions';
import { BusinessState } from '@app/app/store/businesses/business.state';

@Component({
  selector: 'app-old-application-details',
  templateUrl: './old-application-details.component.html',
  styleUrls: ['./old-application-details.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.3 } })),
    ]),
  ],
})
export class OldApplicationDetailsComponent implements OnInit, OnDestroy {
  applicationDetails$: Observable<ApplicationDetails | null> =
    this.store.select(ApplicationDetailsState.applicationDetails);
  applicationFields$: Observable<any> = this.store.select(
    ApplicationDetailsState.applicationFields
  );
  portalUsers$: Observable<PortalUser[] | null> = this.store.select(
    PortalUsersState.portalUsers
  );
  lenderLabels$: Observable<Label[] | null> = this.store.select(
    LabelsState.labels
  );
  alerts$: Observable<Alert[] | null> = this.store.select(
    GlobalAlertsState.alerts
  );

  businessLenderId$: Observable<number | undefined> = this.store.select(BusinessState.businessLenderId);

  approvals$: Observable<Approval[]>;

  @Input() quickViewDealId: number;

  dealId: number;
  loading = true;
  loanProductCategory: string;
  loanProductType: string;
  hasOffers: boolean;
  isQuickView = false;
  isRenewal = false;
  alerts: Alert[];
  selectedIndex = 0;
  page: string;
  link: string;
  renewals: number[] = [0];
  private destroyed$ = new Subject<void>();

  constructor(
    private actRoute: ActivatedRoute,
    private store: Store,
    private eventBusService: EventBusService,
    private router: Router,
  ) {
    if (localStorage.getItem('app.renewals')) {
      this.renewals = JSON.parse(localStorage.getItem('app.renewals') || '');
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.renewals = this.router.getCurrentNavigation()?.extras.state?.renewals
        ? this.router.getCurrentNavigation()?.extras.state?.renewals
        : [];
      localStorage.setItem('app.renewals', JSON.stringify(this.renewals));
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new GetPortalUsers());

    this.applicationDetails$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data) {
          this.hasOffers = data.deal.offers_desc.length > 0;
          this.loanProductCategory = data.deal.loan_product.category.shortName;
          this.loanProductType = data.deal.loan_product.type;
          this.loading = false;
          if (!this.page) {
            this.page = this.titleCase(data.page);
            this.link = data.page;
          }
        }
      });

    if (this.quickViewDealId) {
      this.dealId = this.quickViewDealId;
      this.isQuickView = true;
      this.alerts$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
        if (data) {
          this.alerts = data;
        }
      });
      this.fetchData();
    } else {
      this.actRoute.params.subscribe((params) => {
        this.store.dispatch(new ClearApplicationDetailsStore());
        this.loading = true;
        this.dealId = params.id;
        this.fetchData();
      });
    }

    this.approvals$ = this.store.select(ApprovalsState.forDeal(this.dealId));

    this.eventBusService
      .subscribe(this.eventBusService.types.NewOfferEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => (this.selectedIndex = 2));

    this.isRenewal = this.renewals.includes(Number(this.dealId));
  }

  fetchData() {
    this.store.dispatch(new GetApplicationDetails(this.dealId));
    this.store.dispatch(new GetApplicationFields(this.dealId));
    this.store.dispatch(new GetLabels());
    this.store.dispatch(new GetAdverseActionNotice(this.dealId));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearApplicationDetailsStore());
    this.destroyed$.next();
  }

  dismissAlert(alert: Alert) {
    this.store.dispatch(new DismissAlert(alert));
  }

  smartDocumentError(msg: string) {
    this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: msg
    }));
  }

  goBack() {
    if (this.isRenewal) {
      this.router.navigate([`/renewals/${this.link}`]);
    } else {
      this.router.navigate([`/applications/${this.link}`]);
    }
  }

  titleCase(s: string) {
    return s
      .replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
      .replace(/[_\-]+/g, ' ') // split snake_case and lisp-case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, function (m) {
        return m.toUpperCase();
      }) // title case words
      .replace(/\s+/g, ' ') // collapse repeated whitespace
      .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace
  }
}
