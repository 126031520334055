import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeclineButtonComponent } from './decline-button/decline-button.component';
import { DeclineButtonSmartComponent } from './decline-button-smart/decline-button-smart.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DeclineDialogComponent } from './decline-dialog/decline-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";
import { MatDividerModule } from '@angular/material/divider';
import { OldDeclineDialogComponent } from './decline-dialog/old-decline-dialog.component';

@NgModule({
  declarations: [
    DeclineButtonComponent,
    DeclineButtonSmartComponent,
    DeclineDialogComponent,
    OldDeclineDialogComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    LoadersModule,
    MatDividerModule
  ],
  exports: [
    DeclineButtonSmartComponent
  ]
})
export class DeclineButtonModule { }
