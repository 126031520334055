<ng-container *ngIf="(saasFeaturesLoaded$ | async)">
    <ng-container *ngIf="(hasLenderDashboardFeature$ | async); else LegacyDashboard">
        <app-thoughtspot-liveboard [liveboardId]="lenderLiveboardId"></app-thoughtspot-liveboard>
    </ng-container>
    <ng-template #LegacyDashboard>
        <app-dashboard [lenderStatistics]="lenderStatistics$ | async"
                       [formattedStatistics]="formattedStatistics$ | async">
        </app-dashboard>
    </ng-template>
</ng-container>
