const domain = window.location.hostname;
const expirationMilliseconds = 365 * 24 * 60 * 60 * 1000;// 1 year

export default {
    get (name: string) {
        const cookies = this.getAll();

        return cookies[name];
    },

    set (name: string, value: string) {
        const d = new Date();
        d.setTime(d.getTime() + expirationMilliseconds);
        let expiry = `${d.toUTCString()}`;
        window.document.cookie = `${name}=${value};expires=${expiry};domain=${domain}`;

        return this.get(name);
    },

    getAll () {
        type ReduceReturnType = Record<string, string>;
        return window.document.cookie.split('; ').reduce<ReduceReturnType>((acc, cookie) => {
            let key = cookie.split('=')[0];
            let val = cookie.split('=').slice(1).join('');
            acc[key] = val;
            return acc;
        }, {});
    },

    delete(name: string) {
        window.document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domain}`;
    },
}
