import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ProductTypeOption } from '../../interfaces/product-type-option';

export const productTypeFilters: ProductTypeOption[] = [
  {
    value: 'ach',
    display: 'ACH',
  },
  {
    value: 'sbaPpp',
    display: 'SBA',
  },
  {
    value: 'loc',
    display: 'Business LOC',
  },
  {
    value: 'term',
    display: 'Term'
  },
  {
    value: 'creditCard',
    display: 'Credit Card',
  },
  {
    value: 'sbaPPPSecond',
    display: 'SBA',
  },
  {
    value: 'ercCredit',
    display: 'ERC Credit',
  },
  {
    value: 'equipment',
    display: 'Equipment',
  },
  {
    value: 'sbaStandard',
    display: 'SBA',
  },
  {
    value: 'cre',
    display: 'Commercial Real Estate',
  },
  {
    value: 'personal',
    display: 'Personal',
  },
  {
    value: 'mca',
    display: 'MCA',
  },
  {
    value: 'flexCashflow',
    display: 'Flex Cashflow',
  },
  {
    value: 'factoring',
    display: 'Factoring',
  },
  {
    value: 'abl',
    display: 'Asset Business Loan',
  },
  {
    value: 'ercLoan',
    display: 'ERC Loan',
  },
  {
    value: 'po',
    display: 'Purchase Order',
  },
  {
    value: 'sbaStartup',
    display: 'SBA',
  },
];

@Pipe({
  name: 'dealTypeDisplay'
})
export class ProductTypePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    } else {
      const ProductTypeOption = productTypeFilters.find(el => el.value === value);
      return ProductTypeOption ? ProductTypeOption.display : value;
    }
  }
}

