import { Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { BehaviorSubject, Observable } from "rxjs";

/*
 * TODO: When old theme is completely removed
 *   - Remove all external method calls to this service (ie enable, disable, toggle)
 *   - Remove all external references to the signals newThemeEnabled, and oldThemeEnabled
 *   - Remove conditional logic from all templates
 *   - Remove all content exclusively relying on the old-theme
 *     * Template @else blocks when "@if (newThemeEnabled) {...}  @else {...}"
 *     * Template @if blocks when "@if (!newThemeEnabled)" or "@if (oldThemeEnabled)
 *     * TS logic based on signals (ie if (this._themeService.newThemeEnabled()) {...}
 *  - Remove this service
 *  - Rejoice
 */

@Injectable({
  providedIn: 'root'
})
export class LendioAngularMaterialThemeService {

  private enabledRoutePatterns = [
    /^\/auth\/login/,
    /^\/auth\/mfa-verify/,
    /^\/auth\/reset-password/,
    /^\/auth\/account-locked/,
    /^\/auth\/password-expired/,
  ];

  private state = {
    enabledByRoute: false,
    enabledByUser: false,
  };
  public newThemeEnabled = signal(true);
  public oldThemeEnabled = signal(false);

  private enabledSubject: BehaviorSubject<boolean>;
  private enabledObservable: Observable<boolean>;


  constructor(router: Router) {
    this.enabledSubject = new BehaviorSubject(false);
    this.enabledObservable = this.enabledSubject.asObservable();
    this.enableBasedOnRoute(router);
  }

  public setUserTheme(user: AuthUser): void {
    const themeEnabledState = localStorage.getItem('lendio-angular-material-theme') ?? 'true';
    const cypressTestEmails = ['lpgal@lendio.com', 'pipelineguy@lendio.com', 'test_referral_partner@lendio.com'];
    const isCypressTestUser = cypressTestEmails.some(email => email === user.email);
    const enabled = themeEnabledState === 'true' && !isCypressTestUser;
    this.setEnabledByUser(enabled);
  }

  /**
   * Sets enabledByUser to true.
   */
  enable(): void {
    this.setEnabledByUser(true);
  }

  get enabled(): Observable<boolean> {
    return this.enabledObservable;
  }



  /**
   * Sets enabledByUser to false.
   */
  disable(): void {
    this.setEnabledByUser(false);
  }

  forceDisable(): void {
    this.publish({ value: false });
  }

  /**
   * Toggles enabledByUser.
   */
  toggle(): void {
    this.setEnabledByUser(!this.state.enabledByUser);
  }

  private setEnabledByUser(value: boolean) {
    this.state.enabledByUser = value;
    localStorage.setItem('lendio-angular-material-theme', `${value}`);
    this.publish();
  }

  private setEnabledByRoute(value: boolean) {
    this.state.enabledByRoute = value;
    this.publish();
  }

  private publish(override?: { value: boolean }) {
    const enabled = override ? override.value : this.state.enabledByUser || this.state.enabledByRoute;
    this.updateStylesheet(enabled);
    this.updateThemeClass(enabled);
    this.newThemeEnabled.set(enabled);
    this.oldThemeEnabled.set(!enabled);
    this.enabledSubject.next(enabled);
  }

  private updateStylesheet(enabled: boolean) {
    if (enabled) {
      document.getElementById('lendio-material-angular-theme')?.removeAttribute('disabled');
    } else {
      document.getElementById('lendio-material-angular-theme')?.setAttribute('disabled', '');
    }
  }

  private updateThemeClass(enabled: boolean) {
    const themeClasses: { [key: string]: boolean } = {
      'lendio-angular-material-theme-v1': enabled,
      'old-theme-base-styles': !enabled,
    };
    Object.keys(themeClasses).forEach((themeClass: string) => {
      const themeClassEnabled = themeClasses[themeClass];
      if (themeClassEnabled) {
        if (!document.body.classList.contains(themeClass)) {
          document.body.classList.add(themeClass);
        }
      } else {
        if (document.body.classList.contains(themeClass)) {
          document.body.classList.remove(themeClass);
        }
      }
    });
  }

  private enableBasedOnRoute(router: Router) {
    router.events.forEach((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      const enabled = this.enabledRoutePatterns.some(pattern => {
        return pattern.test(event.urlAfterRedirects);
      });
      this.setEnabledByRoute(enabled);
    });
  }
}
