import { StepStatus } from "src/app/interfaces/lead-step-status.model";
import { LeadStatusSubStep } from "./lead-status-sub-step";

export class LeadStatusStep {
  name: string;
  description: string | null;
  subSteps: LeadStatusSubStep[]
  completedDate: number | null;
  status: StepStatus;
  errorMessage: string | null;

  public constructor(
    name: string,
    description: string | null,
    subSteps: LeadStatusSubStep[],
    completedDate: number | null,
    status: StepStatus,
    errorMessage: string | null
  ) {
    this.name = name;
    this.description = description;
    this.subSteps = subSteps;
    this.completedDate = completedDate;
    this.status = status;
    this.errorMessage = errorMessage;
  }
}
