import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }

  private baseUrl = environment.apiUrl + '/lender-portal';

  sendEmailFromTemplate(
    borrowerId: number,
    dealId: number,
    recipientEmails: string[],
    templateId: number,
    content: string,
    subject: string,
  ): Observable<any> {
    const message = {
      borrowerId,
      dealId,
      recipientEmails,
      templateId,
      content,
      subject
    };
    return this.http.post(`${this.baseUrl}/aan-email`, message);
  }
}
