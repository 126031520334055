import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition, keyframes } from '@angular/animations';

@Component({
    selector: 'app-notification-icon',
    templateUrl: 'notification-icon.component.html',
    styleUrls: ['notification-icon.component.scss'],
    animations: [
        trigger(
          'growInOut', [
            transition(':enter', [
                animate('0.2s', keyframes([
                    style({ transform: 'scale(0)', offset: 0}),
                    style({ transform: 'scale(1.3)', offset: 0.75}),
                    style({ transform: 'scale(1)', offset: 1})
                ])),
            ]),
            transition(':leave', [
                animate('0.2s', keyframes([
                    style({ transform: 'scale(1)', offset: 0}),
                    style({ transform: 'scale(0)', offset: 1}),
                ])),
            ])
          ]
        )
      ],
})
export class NotificationIconComponent {
    @Input() count: number;
    @Input() expanded: boolean;

    @Output() toggle = new EventEmitter();

    constructor() {}

    toggleOpen() {
        this.toggle.emit();
    }
}
