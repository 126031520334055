import { Component, OnInit, Input } from '@angular/core';
import { OffersService } from 'src/app/services/offers.service';
import { take, catchError, tap } from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import { CreateNewAlert } from 'src/app/store/global-alerts/global-alerts.actions';
import {ApplicationDetailsState} from 'src/app/store/application-details/application-details.state';
import {ApplicationDetails} from 'src/app/interfaces/application-details.model';

@Component({
  selector: 'app-resend-contract-button-smart',
  templateUrl: './resend-contract-button-smart.component.html',
  styleUrls: ['./resend-contract-button-smart.component.scss']
})
export class ResendContractButtonSmartComponent implements OnInit {

  @Input() dealId: number;
  @Input() offerId: number;
  // @Select(ApplicationDetailsState.applicationDetails) applicationDetails$: Observable<ApplicationDetails>;
  applicationDetails$: Observable<ApplicationDetails | null> = this.store.select(ApplicationDetailsState.applicationDetails);
  newContractRequested = false;
  pending = false;

  constructor(
    private offersService: OffersService,
    private store: Store
  ) {}

  ngOnInit(): void {}

  resendContract() {
    this.pending = true;
    this.offersService.resendContract(this.dealId, this.offerId).pipe(catchError(err => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to resend contract. Please try again.'
      }));
      this.pending = false;
      return throwError(err);
    }), take(1), tap(() => {
      this.pending = false;
      this.newContractRequested = true;
    })).subscribe();
  }
}
