import { NgModule } from '@angular/core';
import {
  ColumnSortCacheDirective
} from '@app/app/directives/column-sort-cache/column-sort-cache.directive';

@NgModule( {
  declarations: [
    ColumnSortCacheDirective,
  ],
  exports: [
    ColumnSortCacheDirective,
  ]
} )
export class ColumnSortCacheModule {
}
