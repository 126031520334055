<!-- FACTORING OFFER -->
<app-factoring-offer-form
	*ngIf="loanProductCategory == 'Factoring' && !link"
	(newFactoringOffer)="createNewOffer($event)"
	[loading]="loading$ | async"
	[localeCurrencySymbol]='localeCurrencySymbol'
    [useNewTheme]="newThemeEnabled()"
	>
</app-factoring-offer-form>

<!-- REGULAR OFFER -->
<app-offer-form
	*ngIf="loanProductCategory !== 'Factoring' && !link"
	(newOffer)="createNewOffer($event)"
	[loading]="loading$ | async"
	[localeCurrencySymbol]='localeCurrencySymbol'
    [useNewTheme]="newThemeEnabled()"
	[isFundingInterstitial]="isFundingInterstitial"
	>
</app-offer-form>

<!-- OFFER LINK -->
<app-offer-link-form
	*ngIf="link"
	(newOfferLink)="createOfferLink($event)"
	[loading]="loading$ | async"
	[localeCurrencySymbol]='localeCurrencySymbol'
    [useNewTheme]="newThemeEnabled()"
	>
</app-offer-link-form>
