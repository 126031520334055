<mat-card appearance="outlined" class="m-4">
    <mat-card-content>
        <form [formGroup]="newPasswordForm" class="new-theme-typography">
            <div class="flex flex-col">
                <mat-form-field appearance="outline"
                                class="w-4/12 mt-2">
                    <mat-label>Current password</mat-label>
                    <input matInput
                        type="{{ showPassword ? 'text' : 'password' }}"
                        required
                        formControlName="currentPassword"
                        data-cy="current-password-input">
                    <mat-icon matSuffix (click)="showPassword = !showPassword" class="cursor-pointer">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline"
                                class="w-4/12 mt-4">
                    <mat-label>New password</mat-label>
                    <input matInput
                        type="{{ showNewPassword ? 'text' : 'password' }}"
                        required
                        formControlName="newPassword"
                        data-cy="new-password-input">
                    <mat-icon matSuffix (click)="showNewPassword = !showNewPassword" class="cursor-pointer">{{ showNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                    <mat-hint>The new password field must have at least one number, one uppercase letter, and one lower case letter.</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline"
                                class="w-4/12 mt-8">
                    <mat-label>Confirm new password</mat-label>
                    <input matInput
                        type="{{ showNewPassword ? 'text' : 'password' }}"
                        required
                        formControlName="repeatNewPassword"
                        data-cy="repeat-password-input">
                    <mat-icon matSuffix (click)="showNewPassword = !showNewPassword" class="cursor-pointer">{{ showNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </mat-form-field>
            </div>
            <div *ngIf="atLeastOneFieldFilledIn()" class="mt-2">
                <mat-error class="!pb-1" data-cy="current-password-required"
                           *ngIf="checkError('currentPassword', 'required')">The current password field is required
                </mat-error>
                <mat-error class="!pb-1" data-cy="new-password-required" *ngIf="checkError('newPassword', 'required')">The
                    new password field is required
                </mat-error>
                <mat-error class="!pb-1" data-cy="new-password-minlength" *ngIf="checkError('newPassword', 'minlength')">The
                    new password field must be at least 14 characters in length
                </mat-error>
                <mat-error class="!pb-1" data-cy="new-password-pattern" *ngIf="checkError('newPassword', 'pattern')">The new
                    password field must have at least one number, one uppercase letter, and one lower case letter
                </mat-error>
                <mat-error class="!pb-1" data-cy="repeat-password-required"
                           *ngIf="checkError('repeatNewPassword', 'required')">The confirm new password field is required
                </mat-error>
                <mat-error class="!pb-1" data-cy="password-no-match"
                           *ngIf="newPasswordForm.errors && newPasswordForm.errors.passwordDoesNotMatch">The new password
                    and confirm new password fields do not match
                </mat-error>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button
                color="accent"
                class="mt-2"
                data-cy="new-password-submit"
                (click)="submit()"
                type="submit"
                [class.spinner]="submitting"
                [disabled]="newPasswordForm.invalid || (newPasswordForm.errors && newPasswordForm.errors.passwordDoesNotMatch) || submitting">
            <span *ngIf="!submitting; else pending">Save Password</span>
            <ng-template #pending>&nbsp;</ng-template>
        </button>
    </mat-card-actions>
</mat-card>
