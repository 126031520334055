import { EmailTemplate } from '@app/app/interfaces/templates/EmailTemplate.model';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TemplateService } from '@app/app/services/template.service';
import { GetEmailTemplates, SendEmailFromTemplate } from '@app/app/store/templates/template.actions';
import { addMinutes, getUnixTime, isAfter } from 'date-fns';
import { catchError } from 'rxjs/operators';
import { tap, throwError } from 'rxjs';
import { MessageService } from '@app/app/services/message.service';
import {AddSnackbarError, AddSnackbarSuccess} from "@app/app/store/snackbar/snackbar.actions";

export interface TemplateStateModel {
  emailTemplates: EmailTemplate[];
  loading: boolean;
  expires: number;
}

@State<Partial<TemplateStateModel>>({
  name: 'Templates',
})
@Injectable()
export class TemplateState {

  static expirationMinutes: number = 60;

  @Selector()
  static emailTemplates(state: TemplateStateModel) {
    return state.emailTemplates;
  }

  @Selector()
  static loading(state: TemplateStateModel) {
    return state.loading;
  }

  constructor(
    private templateService: TemplateService,
    private messageService: MessageService,
    private store: Store,
  ) {}

  @Action(GetEmailTemplates)
  GetEmailTemplates(
    ctx: StateContext<TemplateStateModel>,
    { }: GetEmailTemplates
  ) {
    const expires = ctx.getState().expires;
    if(!expires || isAfter(new Date(), new Date(expires))) {
      ctx.patchState({
        loading: true,
        expires: getUnixTime(addMinutes(new Date(), TemplateState.expirationMinutes)),
      });

      return this.templateService.getEmailTemplates().pipe(
        catchError(err => {
          ctx.patchState({
            loading: false,
            expires: undefined,
          });
          return throwError(err);
        }),
        tap( response => {
          ctx.patchState({
            emailTemplates: response.data,
            loading: false,
          });
        }),
      )
    }
  }

  @Action(SendEmailFromTemplate)
  SendEmailFromTemplate(
    ctx: StateContext<TemplateStateModel>,
    {
      borrowerId,
      dealId,
      recipientEmails,
      templateId,
      content,
      subject,
    }: SendEmailFromTemplate
  ) {
    ctx.patchState({
      loading: true,
    });

    return this.messageService.sendEmailFromTemplate(
      borrowerId,
      dealId,
      recipientEmails,
      templateId,
      content,
      subject
    ).pipe(
      catchError(err => {
        ctx.patchState({
          loading: false,
        });
        this.store.dispatch( new AddSnackbarError({
          subTitle: 'Unable to send AAN. Please try again, or contact your administrator.',
        }))
        return throwError(err);
      }),
      tap( response => {
        ctx.patchState({
          loading: false
        });
        this.store.dispatch( new AddSnackbarSuccess({
          title: 'Adverse Action Notice sent',
          subTitle: 'Your adverse action notice has been sent successfully. A PDF copy has been generated and documented in the Documents tab for this deal.',
        }));
      })
    )
  }


}
