import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { ContactsActions as CA, ContactsActions } from './contacts.actions';
import { Contact } from '../../interfaces/contact.model';
import { ContactsService } from '@app/app/services/contacts.service';
import { AddSnackbarError, AddSnackbarNotification } from '@app/app/store/snackbar/snackbar.actions';

export class ContactsStateModel {
  contacts: Contact[];
  current: Contact | null;
  loading: boolean = false;
}

@State<ContactsStateModel>({
  name: 'contacts',
  defaults: {
    contacts: [],
    current: null,
    loading: false,
  }
})
@Injectable()

export class ContactsState {

  @Selector()
  static contacts(state: ContactsStateModel) {
    return state.contacts;
  }

  @Selector()
  static current(state: ContactsStateModel) {
    return state.current;
  }

  @Selector()
  static ownerContacts(state: ContactsStateModel) {
    return state.contacts.filter( contact => contact.isPrimary || !!contact.percentOwnership);
  }

  @Selector()
  static primaryContact(state: ContactsStateModel) {
    return state.contacts.find( contact => contact.isPrimary);
  }

  @Selector()
  static loading(state: ContactsStateModel) {
    return state.loading;
  }

  constructor(
    // The main contacts service is contacts by borrower.
    private ContactsService: ContactsService,
    private store: Store
  ) { }

  // Borrower-based contact actions.
  @Action(CA.GetIndex)
  getIndex({ patchState }: StateContext<ContactsStateModel>, { borrowerId }: CA.GetIndex) {
    patchState({
      loading: true,
    });

    return this.ContactsService.getIndex(borrowerId).pipe(
      catchError((err: any) => {
        this.store.dispatch(new AddSnackbarError({
          identifier: 'contactsFetchError',
          subTitle: `Unable to retrieve this business' contacts. Please refresh the page to try again.`,
        }));
        patchState({
          loading: false,
          contacts: []
        })
        return throwError(err);
      }),
      tap((response: any) => {
        patchState({
          loading: false,
          contacts: response.data });
      })
    );
  }

  @Action(CA.Create)
  create({ getState, patchState }: StateContext<ContactsStateModel>, { formData }: CA.Create) {
    patchState({
      loading: true,
    });

    return this.ContactsService.create(formData).pipe(
      // Fail
      catchError((err: any) => {
        return throwError(err);
      }),

      // Success
      tap((response: any) => {
        const state = getState();
        const contact = response.data;
        const entities = [...state.contacts, contact];
        patchState({
          contacts: entities,
          current: contact,
          loading: false,
        });

        // If this new contact is primary, refresh from server since another
        // contact is no longer primary.
        if (contact.isPrimary) {
          this.store.dispatch(new ContactsActions.GetIndex(contact.borrowerId));
        }
      })
    );
  }

  @Action(CA.Update)
  update({ getState, patchState }: StateContext<ContactsStateModel>, { id, formData }: CA.Update) {
    patchState({
      loading: true,
    });

    return this.ContactsService.update(id, formData).pipe(
      catchError((err: any) => {
        return throwError(err);
      }),

      tap((res: any) => {
        // The contacts controller may return a success/200 when it should
        // return a 4XX error, causing the response to arrive here. Check
        // to ensure it's a valid contact response.
        if (!res.data?.id) {
          if (res.data?.zipId) {
            throw new Error('ZIP_ERROR');
          } else {
            throw new Error('SERVER_ERROR');
          }
        }
        const contacts = [...getState().contacts];
        const updatedContactIndex = contacts.findIndex(c => c.id === id);
        contacts[updatedContactIndex] = res.data;
        patchState({
          contacts,
          current: contacts[updatedContactIndex],
          loading: false
        });

        // If this contact is primary, refresh from server since another
        // contact may not be primary.
        if (contacts[updatedContactIndex].isPrimary) {
          this.store.dispatch(new ContactsActions.GetIndex(contacts[updatedContactIndex].borrowerId));
        }
      })
    );
  }

  @Action(CA.Delete)
  delete({ getState, patchState }: StateContext<ContactsStateModel>, { id }: CA.Delete) {
    patchState({
      loading: true,
    });

    return this.ContactsService.delete(id).pipe(
      catchError((err: any) => {
        return throwError(err);
      }),
      tap(() => {
        const currentContacts = [...getState().contacts];
        patchState({
          contacts: currentContacts.filter(c => c.id !== id),
          loading: false,
        });
      })
    );
  }

  // Deal-based contact actions.
  // Legacy so didn't update with the loading option like above.
  @Action(CA.GetIndexByDeal)
  getIndexByDeal({ patchState }: StateContext<ContactsStateModel>, { dealId }: CA.GetIndexByDeal) {
    return this.ContactsService.getIndexByDeal(dealId).pipe(catchError((err: any) => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to retrieve this borrower\'s contacts (business owners). Please refresh the page to try again.'
      }));
      return throwError(err);
    }), tap((response: any) => {
      patchState({ contacts: response.data });
    }));
  }

  @Action(CA.CreateByDeal)
  createByDeal({ getState, patchState }: StateContext<ContactsStateModel>, { dealId, formData }: CA.CreateByDeal) {
    return this.ContactsService.createByDeal(dealId, formData).pipe(catchError((err: any) => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to create new owner. Please refresh the page and try again.'
      }));
      return throwError(err);
    }), tap((response: any) => {
      this.store.dispatch(new CreateNewAlert({
        level: 'success',
        message: 'Owner created successfully!'
      }));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      patchState({ contacts: [...getState().contacts, response.data] });
    }));
  }

  @Action(CA.UpdateByDeal)
  updateByDeal({ getState, patchState }: StateContext<ContactsStateModel>, { dealId, id, formData }: CA.UpdateByDeal) {
    return this.ContactsService.updateByDeal(dealId, id, formData).pipe(catchError((err: any) => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to update owner. Please refresh the page and try again.'
      }));
      return throwError(err);
    }), tap((res: any) => {
      const currentContacts = [...getState().contacts];
      const updatedContactIndex = currentContacts.findIndex(c => c.id === id);
      currentContacts[updatedContactIndex] = res.data;
      patchState({ contacts: currentContacts });
      this.store.dispatch(new CreateNewAlert({
        level: 'success',
        message: 'Contact updated successfully!'
      }));
    }));
  }

  @Action(CA.DeleteByDeal)
  deleteByDeal({ getState, patchState }: StateContext<ContactsStateModel>, { dealId, id }: CA.DeleteByDeal) {
    return this.ContactsService.deleteByDeal(dealId, id).pipe(catchError((err: any) => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to delete owner. Please refresh the page and try again.'
      }));
      return throwError(err);
    }), tap(() => {
      const currentContacts = [...getState().contacts];
      this.store.dispatch(new CreateNewAlert({
        level: 'success',
        message: 'Owner deleted successfully!'
      }));
      patchState({ contacts: currentContacts.filter(c => c.id !== id) });
    }));
  }

  @Action(CA.ClearContactsState)
  clear({ patchState }: StateContext<ContactsStateModel>, { }: CA.ClearContactsState) {
    patchState({ contacts: []});
  }
}
