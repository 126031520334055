import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit, OnChanges {

  @Input() isUploading: boolean;
  @Input() uploadedDocs: FileList;
  @Output() uploadDocs = new EventEmitter<{ files: FileList, category: string }>();
  @Output() deleteDoc = new EventEmitter<number>();
  @ViewChild('fileInput') public fileInput: ElementRef;

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('isUploading') && !changes.isUploading.currentValue && this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  uploadDocuments(documents: FileList): void {
    this.uploadDocs.emit({ files: documents, category: 'contractUnsigned' });
  }

  deleteDocument(documentId: number) {
    this.deleteDoc.emit(documentId);
  }
}
