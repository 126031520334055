// Initially, this store was deal-centric, using a dealID to get contacts
// via the deal's borrower. Instead of updating the component hierarchies to
// pass the borrowerId instead, this will allow getting contacts by either
// the borrower (natural default) or the deal.

import { Contact } from "@app/app/interfaces/contact.model";

// Namespace the actions so we can use succinct action names.

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ContactsActions {

  // Contact actions by borrower (default). Plain `id` is always this entity's
  // id (e.g. contact.id).
  export class GetIndex {
      static readonly type = '[Contacts] GetIndex';
      constructor(public borrowerId: number) {}
  }

  export class Create {
      static readonly type = '[Contacts] Create';
      constructor(public formData: FormData | Contact) {}
  }

  export class Update {
      static readonly type = '[Contacts] Update';
      constructor(public id: number, public formData: FormData | Contact) {}
  }

  export class Delete {
      static readonly type = '[Contacts] Delete';
      constructor(public id: number) {}
  }

  // Contact actions by deal for legacy compatibility.
  export class GetIndexByDeal {
      static readonly type = '[Contacts] GetIndexByDeal';
      constructor(public dealId: number) {}
  }

  export class CreateByDeal {
      static readonly type = '[Contacts] CreateByDeal';
      constructor(public dealId: number, public formData: FormData) {}
  }

  export class UpdateByDeal {
      static readonly type = '[Contacts] UpdateByDeal';
      constructor(public dealId: number, public id: number, public formData: FormData) {}
  }

  export class DeleteByDeal{
      static readonly type = '[Contacts] DeleteByDeal';
      constructor(public dealId: number, public id: number) {}
  }

  // Clear contacts, whether we got them via deal or borrower.
  export class ClearContactsState {
      static readonly type = '[Contacts] ClearContactsState';
      constructor() {}
  }
}
