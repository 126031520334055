import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Offer } from '@app/app/interfaces/offer.model';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Select, Store } from '@ngxs/store';
import { OffersState } from '@app/app/store/offers/offers.state';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { LoadersModule } from '../../loaders/loaders.module';
import { AcceptOffer } from '@app/app/store/offers/offers.actions';

export enum AcceptOfferDialogCloseReason {
  Default = 'default',
  CreateNewOffer = 'createNewOffer',
  AcceptOffer = 'acceptOffer',
  Error = 'error',
}

export interface AcceptOfferDialogCloseData {
  reason: AcceptOfferDialogCloseReason;
  acceptedOffer?: Offer;
}

@Component({
  standalone: true,
  templateUrl: './accept-offer-dialog.component.html',
  styleUrls: ['./accept-offer-dialog.component.scss'],
  imports: [
    CommonModule,
    MatListModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    LoadersModule,
  ],
})
export class AcceptOfferDialogComponent {
  @ViewChild('OfferDetails') offerDetails: MatSelectionList;
  offers: Offer[];
  protected _markAcceptedClicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly _dialogData: { offers: Offer[] },
    public dialogRef: MatDialogRef<AcceptOfferDialogComponent>,
    private _store: Store
  ) {
    this.offers = this._dialogData.offers;
  }

  get offerSelected(): boolean {
    return this.offerDetails?.selectedOptions?.hasValue();
  }

  createNewOffer() {
    const closeData: AcceptOfferDialogCloseData = {
      reason: AcceptOfferDialogCloseReason.CreateNewOffer,
    };
    this.dialogRef.close(closeData);
  }

  closeDialog(): void {
    const closeData: AcceptOfferDialogCloseData = {
      reason: AcceptOfferDialogCloseReason.Default,
    };
    this.dialogRef.close(closeData);
  }

  markOfferAccepted(): void {
    this._markAcceptedClicked = true;

    const selectedOffer: Offer =
      this.offerDetails.selectedOptions.selected[0].value;

    this._store
      .dispatch(new AcceptOffer(selectedOffer.id))
      .pipe(
        catchError((err) => {
          const closeData: AcceptOfferDialogCloseData = {
            reason: AcceptOfferDialogCloseReason.Error,
          };
          this.dialogRef.close(closeData);
          return throwError(() => err);
        }),
        tap(() => {
          const closeData: AcceptOfferDialogCloseData = {
            reason: AcceptOfferDialogCloseReason.AcceptOffer,
            acceptedOffer: selectedOffer,
          };
          this.dialogRef.close(closeData);
        })
      )
      .subscribe();
  }
}
