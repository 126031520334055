import { Component, Input, OnInit, OnChanges, SimpleChanges, computed } from '@angular/core';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { SmallChartData } from 'src/app/interfaces/small-chart-data.model';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";
// component.ts file

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger(
        'fadeInOut', [
            transition(':enter', [
                animate('0.5s', keyframes([
                    style({ opacity: 0, offset: 0}),
                    style({ opacity: 0.667, offset: 0.5}),
                    style({ opacity: 1, offset: 0.75}),
                    style({ offset: 1})
                ])),
            ]),
            transition(':leave', [
                animate('0.5s', keyframes([
                    style({ opacity: 1, offset: 0}),
                    style({ opacity: 0, offset: 1}),
                ])),
            ])
        ]
    )
],
})
export class DashboardComponent implements OnInit, OnChanges {
  @Input() lenderStatistics: { responseRate: any; offerRate: any; offerToCloseRate: any; avgResTime: any; avgNewAppToOffer: any; avgNewAppToClose: number; awaitingResponse: boolean };
  @Input() formattedStatistics: { [x: string]: any; };

  applications: SmallChartData | null;
  responseRate: SmallChartData | null;
  appToOffer: SmallChartData | null;
  offerToClose: SmallChartData | null;
  hoursToResponse: SmallChartData | null;
  hoursToOffer: SmallChartData | null;
  daysToClose: SmallChartData | null;

  hasData: boolean | null = null;

  newThemeEnabled = computed(() => this._themeService.newThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formattedStatistics && changes.formattedStatistics.currentValue) {
      this.initChartData();
      const dataPoints = [
        this.applications,
        this.responseRate,
        this.appToOffer,
        this.offerToClose,
        this.hoursToResponse,
        this.hoursToOffer,
        this.daysToClose
      ];
      this.hasData = !!dataPoints.filter(data => data).length;
    }
  }

  getLatest(formattedStatistics: any[]) {
    return formattedStatistics.slice().reverse()[0];
  }

  getPrevious(formattedStatistics: any[]) {
    return formattedStatistics.slice().reverse()[1];
  }

  getChange(latest: { value: number; }, previous: { value: number; }) {
    return (latest.value - previous.value) / previous.value;
  }

  chartDataConstructor(chartData: any[], name = 'data', suffix = '', average: any = null) {
    if (!chartData) {
      return null;
    }
    const appLatest = this.getLatest(chartData);
    const appPrevious = this.getPrevious(chartData);
    const appChange = this.getChange(appLatest, appPrevious);
    return {
      name,
      suffix,
      latest: appLatest,
      previous: appPrevious,
      change: appChange,
      average,
      ngxChartData: [
        {
          name,
          series: chartData,
        }
      ]
    };
  }

  initChartData() {
    const applications = this.formattedStatistics['appSent-numApplications-8'];
    this.applications = null;
    if (applications) {
      this.applications = this.chartDataConstructor(
          applications,
          'Number of applications',
          '/wk'
      );
    }

    const responseRate = this.formattedStatistics['appSent-responseRate-8'];
    this.responseRate = null;
    if (responseRate){
      this.responseRate = this.chartDataConstructor(
        responseRate,
        'Response rate',
        '%',
        this.lenderStatistics && this.lenderStatistics.responseRate
      );
    }

    const appToOffer = this.formattedStatistics['appSent-offerRate-8'];
    this.appToOffer = null;
    if (appToOffer) {
      this.appToOffer = this.chartDataConstructor(
        appToOffer,
        'App to offer',
        '%',
        this.lenderStatistics && this.lenderStatistics.offerRate
      );
    }

    const offerToClose = this.formattedStatistics['appSent-closeRate-8'];
    this.offerToClose = null;
    if (offerToClose) {
      this.offerToClose = this.chartDataConstructor(
        offerToClose,
        'Offer to close',
        '%',
        this.lenderStatistics && this.lenderStatistics.offerToCloseRate
      );
    }


    const hoursToResponse = this.formattedStatistics['appSent-hoursToResponse-8'];
    this.hoursToResponse = null;
    if (hoursToResponse) {
      this.hoursToResponse = this.chartDataConstructor(
        hoursToResponse,
        'Avg. response time',
        'hours',
        this.lenderStatistics && this.lenderStatistics.avgResTime
      );
    }

    const hoursToOffer = this.formattedStatistics['appSent-hoursToOffer-8'];
    this.hoursToOffer = null;
    if (hoursToOffer) {
      this.hoursToOffer = this.chartDataConstructor(
        hoursToOffer,
        'Avg. time new app to offer',
        'hours',
        this.lenderStatistics && this.lenderStatistics.avgNewAppToOffer
      );
    }

    const daysToClose = this.formattedStatistics['appSent-daysToClose-8'];
    this.daysToClose = null;
    if (daysToClose) {
      this.daysToClose = this.chartDataConstructor(
        daysToClose,
        'Avg. time new app to close',
        'days',
        this.lenderStatistics && Math.abs(this.lenderStatistics.avgNewAppToClose)
      );
    }
  }

}
