import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {

  constructor() { }

  /**
   * this method shoots a blast of confetti in the center of the page if nothing is passed to the method
   * @param sourceElement a reference to an element that you would like to attach the confetti cannon to
   * if an HTML element is sent into the method it will attach the confetti to that element
   * */
  public confetti(sourceElement?: HTMLElement): void {
    const options = {
      angle: 120,
      spread: 80,
      particleCount: 150,
      drift: -2,
      startVelocity: 50,
      ticks: 300,
      origin: { x: 0.5, y: 0.5 },
      disableForReducedMotion: true,
    };

    if (sourceElement) {
      const rect = sourceElement.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      options.origin = {
        x: centerX / window.innerWidth,
        y: centerY / window.innerHeight
      };
    }

    confetti(options);
  }

  private randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
  }
}
