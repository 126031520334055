import { Component, OnInit, Input, computed } from '@angular/core';
import { Offer } from 'src/app/interfaces/offer.model';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-old-offers',
  templateUrl: './old-offers.component.html',
  styleUrls: ['./old-offers.component.scss']
})
export class OldOffersComponent implements OnInit {

  @Input() offers: Offer[];
  @Input() sbaLoanNumber: number;
  @Input() dealId: number;

  localeCurrencySymbol: string | null;
  displayOriginationToggle: boolean;
  originationDisplayDollars: boolean;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());
  constructor(private localeSettingService: LocaleSettingService, private _themeService: LendioAngularMaterialThemeService) {
    this.localeCurrencySymbol = this.localeSettingService.getCurrencySymbol();
  }

  ngOnInit(): void {}

  setOriginationFeeToggleDisplay(toggle: boolean) {
    this.displayOriginationToggle = toggle;
  }

  toggleOriginationFeeDisplayDollars() {
    this.originationDisplayDollars = !this.originationDisplayDollars;
  }

  rateType(offer: Offer) {
    if (offer.factor) {
        return 'factor';
    } else if (offer.interestRate) {
        return 'interestRate';
    } else {
      return 'apr';
    }
  }

  rateTypeName(offer: Offer) {
    if (offer.factor) {
        return 'Factor';
    } else if (offer.interestRate) {
        return 'Interest Rate';
    } else {
        return 'APR';
    }
  }

  paymentFrequencyDisplay(offer: Offer) {
    const paymentFrequencyDisplays = {
      bimonthly: 'Bi-Monthly',
      biweekly: 'Bi-Weekly',
      daily: 'Daily',
      monthly: 'Monthly',
      semimonthly: 'Semi-Monthly',
      weekly: 'Weekly',
      semiweekly: 'Semi-Weekly'
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return paymentFrequencyDisplays[offer.paymentFrequency] || null;
  }
}
