import { Import } from "@app/app/interfaces/import.model";
import { ImportDetailsStateModel } from "./import-details.state";

export class GetImportEntities {
  static readonly type = '[Imports] GetImportEntities';
  constructor(
    public paramChanges: Partial<ImportDetailsStateModel>,
    public importId: number
    ) {}
}

export class GetImportDetails {
  static readonly type = '[Imports] GetImportDetails';
  constructor(
    public importId: number
    ) {}
}

export class SubscribeToImportDetailsStateUpdates {
  static readonly type = '[Imports] SubscribeToImportDetailsStateUpdates';
}

export class UpdateCurrentImport {
  static readonly type = '[Imports] UpdateCurrentImport';
  constructor (
    public incomingImport: Import
  ) {}
}
