<div mat-dialog-title class="new-theme-typography">
  Session Timeout
</div>
<div mat-dialog-content>
  Your session will timeout soon due to inactivity. For your protection, we will automatically sign you out of Partner Portal.
</div>
<div mat-dialog-actions class="justify-end !mt-3 !pb-4">
  <button mat-button (click)="logOut()">
    Sign Out
  </button>
  <button mat-flat-button color="primary" (click)="continueSession()">
    Continue Session
  </button>
</div>
