import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LenderUser } from '../interfaces/lender-user.model';
import { LendioResponse } from '@app/app/interfaces/lendio-response';
import { AuthState } from '../store/auth/auth.state';
import { Store } from '@ngxs/store';

interface TeamMember {
  id: number;
  fullName: string;
}

@Injectable({
  providedIn: 'root',
})

export class LenderUserService {

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  get(): Observable<LenderUser> {
    return this.http.get<LenderUser>('/lender-user');
  }

  getLenderUsers(): Observable<any> {
    const currentUser = this.store.selectSnapshot(AuthState.user);

    return currentUser?.institution?.id ?
           this.http.get(`${environment.apiUrl}/l/v2/internal/lender-users`)
           : currentUser?.affiliate
             ? this.http.get(`${environment.apiUrl}/affiliate/${currentUser?.affiliate.id}/users`)
             : of([]);
  }

  createLenderUser(newLenderUser: Partial<LenderUser>): Observable<LendioResponse> {
    return this.http.post<LendioResponse>(`${environment.apiUrl}/l/v2/internal/lender-users-add`, newLenderUser);
  }

  updateLenderUser(lenderUserId: number, updates: Partial<LenderUser>): Observable<LendioResponse> {
    return this.http.put<LendioResponse>(`${environment.apiUrl}/l/v2/internal/lender-users-edit/${lenderUserId}`, updates);
  }

  deleteLenderUser(lenderUserId: number): Observable<LendioResponse> {
    return this.http.delete<LendioResponse>(`${environment.apiUrl}/l/v2/internal/lender-users-delete/${lenderUserId}`, {});
  }

  /**
   * search based on query parameters
   *
   * @param searchString
   */
  search(searchString: any): Observable<TeamMember[] | []> {
    return this.http.get<TeamMember[] | []>(`/team-member/lazy-search?search=${searchString}`);
  }

  updatePausedWorkflow(lenderUserId: number, meta: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/lender-user/${lenderUserId}/update-paused-workflow`, meta);
  }

  uploadImage(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiUrl}/avatar`, formData);
  }

  deleteImage() {
    return this.http.delete(`${environment.apiUrl}/avatar`);
  }
}
