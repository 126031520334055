import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { environment } from '@app/environments/environment';
import cookies from 'src/app/libs/cookieManager';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth/auth.state';
import { Logout } from '../store/auth/auth.actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldUseHttpOnlyCookie(request)) {
      request = request.clone({
        withCredentials: true
      });
    }
    if (request.url.includes('/login') || request.url.includes('/sign-in')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }

  private shouldUseHttpOnlyCookie(request: HttpRequest<any>): boolean {
    return request.url.includes(environment.apiUrl) ||
      request.url.includes(environment.identityUrl) ||
      request.url.includes('thoughtspot.lendio.com');
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // Log out and redirect to login screen if user has lost authentication with Lendio backend.
    if (
      err.status === 401 &&
      this.store.selectSnapshot(AuthState.user) &&
      (err.url?.includes(environment.apiUrl))
    ) {
      this.store.dispatch(new Logout());
      return of(err);
    }
    if (err.status >= 400 && err.status < 500) {
      return throwError(() => err);
    }
    return throwError(() => err);
  }
}
