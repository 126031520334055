import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TurnDownDialogComponent } from '../turn-down-dialog/turn-down-dialog.component';

@Component({
  selector: 'app-turn-down-button',
  templateUrl: './turn-down-button.component.html',
  styleUrls: ['./turn-down-button.component.scss']
})
export class TurnDownButtonComponent implements OnInit {

  @Input() dealId: number;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {}

  openTurnDownDialog(): void {
    const dialogRef = this.dialog.open(TurnDownDialogComponent, {
      width: '450px',
      data: { dealId: this.dealId }
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

}
