
<div *ngIf="!offers || offers.length === 0; else approvals">
	<p class="!mt-6">This application has no offers or contracts.</p>
</div>
<ng-template #approvals>
	<div class="!mt-6" *ngFor="let offer of offers"
         [class.old-theme-offers]="oldThemeEnabled()">
		<mat-card appearance="outlined" class="offer-card">
			<div class="panel panel-default offer-panel">

				<div class="offer-card-header">
					<span *ngIf="offer.loanProductCategory != 'SBA PPP' && offer.loanProductCategory != 'SBA PPP Second'">
						{{ offer.name }} <span *ngIf="offer.apiLogId">- API Generated</span>
						<span class="text-{{ offer.status }}">{{ offer.status }}</span>
						<span *ngIf="offer.type === 'hard'" class="text-warning">Hard Offer</span>
					</span>
					<span *ngIf="offer.loanProductCategory == 'SBA PPP' || offer.loanProductCategory == 'SBA PPP Second'">
						<div class="row">
							<div class="flex-1 m-auto">
								Contract: {{ offer.received | toDateObj | date:'M/d/yy, h:mm a' }} <span *ngIf="offer.apiLogId">- API Generated</span>
							</div>
							<div class="flex-1 text-right m-auto">
								<app-resend-contract-button-smart [dealId]="dealId" [offerId]="offer.id"></app-resend-contract-button-smart>
							</div>
						</div>
					</span>
				</div>

				<div *ngIf="offer.borrowerDeclined">
					<div class="panel-body panel-alt-background">
						<span class="font-bold text-uppercase">Borrower decline reason:</span>
						<p>{{ offer.declinedReason }}</p>
					</div>
				</div>

				<div class="panel-body offer-panel-body">
					<div *ngIf="!offer.offerLink">

						<!-- FACTORING OFFER -->
						<div *ngIf="offer.loanProductCategory == 'Factoring'">
							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">Facility Amount</span>
									<div>
										{{ offer.amount | currency }}
									</div>
								</div>
								<div class="flex-1 p-1">
									<span class="col-header">Advance Rate</span>
									<div>
										{{ offer.advanceRate }}%
									</div>
								</div>
								<div class="flex-1 p-1">
									<span class="col-header">Min Purchase Fee</span>
									<div>
										{{ offer.minPurchaseFee }}%
									</div>
								</div>
							</div>
							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">Contract Duration</span>
									<div>
										{{ offer.term }} Mo.
									</div>
								</div>
								<div class="flex-1 p-1">
									<span class="col-header">Commission</span>
									<div>
										{{ offer.commission | currency }}
									</div>
								</div>
								<div class="flex-1 p-1">
									<span class="col-header">Points</span>
									<div>
										{{ offer.points }}
									</div>
								</div>
							</div>
							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">Payment Term</span>
                                    {{ offer.paymentTerm | capitalize }}
								</div>
								<div class="flex-1 p-1">
									<span class="col-header">Date Received</span>
									<div>
										{{ offer.received | toDateObj | date:'shortDate' }}
									</div>
								</div>
								<div class="flex-1 p-1 origination-fee-display"
									(mouseover)="setOriginationFeeToggleDisplay(true)"
									(mouseleave)="setOriginationFeeToggleDisplay(false)">
									<span class="col-header">Origination Fee</span>
									<div class="origination-fee" *ngIf="offer.originationFee">
										<span>{{ originationDisplayDollars ? ( offer.originationFee * offer.amount / 100 | currency ) : offer.originationFee + '%' }}</span>
										<a *ngIf="displayOriginationToggle" class="inline-table-toggle" (click)="toggleOriginationFeeDisplayDollars()">{{ originationDisplayDollars ? 'View %' : 'View ' + localeCurrencySymbol }}</a>
									</div>
									<div *ngIf="!offer.originationFee">-</div>
								</div>
							</div>
						</div>

						<!-- PPP CONTRACT/OFFER -->
						<div *ngIf="offer.loanProductCategory == 'SBA PPP' || offer.loanProductCategory == 'SBA PPP Second'">
							<div class="row">
								<div class="flex-1 p-1" *ngIf="offer.amount">
									<span class="col-header">Amount</span>
									<div>
										{{ offer.amount | currency }}
									</div>
								</div>
								<div class="flex-1 p-1" *ngIf="sbaLoanNumber">
									<span class="col-header">SBA Approval #</span>
									<div>
										{{ sbaLoanNumber }}
									</div>
								</div>
								<div class="flex-1 p-1" *ngIf="offer.expiration">
									<span class="col-header">Expiration Date</span>
									<div>
										{{ offer.expiration | toDateObj | date:'shortDate' }}
									</div>
								</div>
							</div>
						</div>

						<!-- REGULAR OFFER -->

						<div *ngIf="offer.loanProductCategory != 'Factoring' && offer.loanProductCategory != 'SBA PPP' && offer.loanProductCategory != 'SBA PPP Second'">
							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">Amount</span>
									<div *ngIf="offer.amount">{{ offer.amount | currency }}</div>
									<div *ngIf="!offer.amount">-</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Payback</span>
									<div *ngIf="offer.payback">{{ offer.payback | currency }}</div>
									<div *ngIf="!offer.payback">-</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Rate Type</span>
									<div>{{ offer.buyRate ? 'Buy Rate' : 'Sell Rate' }}</div>
								</div>
							</div>

							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">{{ rateTypeName(offer) }}</span>
									<div>{{ rateType(offer) ? (offer.buyRate !== null ? offer.buyRate : offer[rateType(offer)]) : '-' }}{{ offer[rateType(offer)] !== null ? (rateType(offer) !== 'factor' ? '%' : '') : '' }}</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Commission</span>
									<div *ngIf="offer.commission">{{ offer.commission | currency }}</div>
									<div *ngIf="!offer.commission">-</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Points</span>
									<div>{{ offer.points ? offer.points : '-' }}</div>
								</div>
							</div>

							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">Num. Payments</span>
									<div>{{ offer.numPayments ? offer.numPayments : '-' }}</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Payment Frequency</span>
									<div>{{ (paymentFrequencyDisplay(offer) && offer.paymentFrequency !== '') ? paymentFrequencyDisplay(offer) : '-' }}</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Payment Amount</span>
									<div *ngIf="offer.paymentAmount">{{ offer.paymentAmount | currency }}</div>
									<div *ngIf="!offer.paymentAmount">-</div>
								</div>
							</div>

							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">Term</span>
									<div>{{ offer.term ? offer.term + 'mo.' : '-' }}</div>
								</div>

								<div class="flex-1 p-1 origination-fee-display" (mouseover)="setOriginationFeeToggleDisplay(true)" (mouseleave)="setOriginationFeeToggleDisplay(false)">
									<span class="col-header">Origination Fee</span>
									<div class="origination-fee" *ngIf="offer.originationFee">
										<div *ngIf="!originationDisplayDollars;then percentTemplate else currencyTemplate"></div>
										<ng-template #currencyTemplate>
											{{ (offer.originationFee * offer.amount / 100) | currency }}
										</ng-template>
										<ng-template #percentTemplate>
											{{ offer.originationFee + '%'  }}
										</ng-template>
										<button
											*ngIf="displayOriginationToggle"
											class="inline-table-toggle"
											(click)="toggleOriginationFeeDisplayDollars()">
											{{ 'View ' + ( originationDisplayDollars ? '%' : localeCurrencySymbol ) }}
										</button>
									</div>
									<div *ngIf="!offer.originationFee">-</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Position</span>
									<div>{{ offer.position ? offer.position : '-' }}</div>
								</div>
							</div>

							<div class="row">
								<div class="flex-1 p-1">
									<span class="col-header">Date Received</span>
									<div *ngIf="offer.received">{{ offer.received | toDateObj | date:'shortDate' }}</div>
									<div *ngIf="!offer.received">-</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Expiration Date</span>
									<div *ngIf="offer.expiration">{{ offer.expiration | toDateObj | date:'shortDate' }}</div>
									<div *ngIf="!offer.expiration">-</div>
								</div>

								<div class="flex-1 p-1">
									<span class="col-header">Net Fund Amount</span>
									<div *ngIf="offer.netFundedAmount">{{ offer.netFundedAmount | currency }}</div>
									<div *ngIf="!offer.netFundedAmount">-</div>
								</div>
							</div>

							<div class="row">
								<div *ngIf="offer.notes" class="flex-1 p-1">
									<span class="col-header">Offer Notes</span>
									<div>{{ offer.notes }}</div>
								</div>
							</div>

							<div class="row">
								<div *ngIf="offer.url" class="flex-1 p-1">
									<div class="offer-url">
										<span class="col-header">
											Offer Acceptance URL
											<span class="icon-16-help" uib-tooltip="This link directs a Borrower to the Lender’s portal where they will perform final steps to accept their offer. This is a one-time use link, so do not visit this page!"></span>
										</span>
										<div style="word-wrap: break-word; word-break: break-all">{{ offer.url }}</div>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div *ngIf="offer.offerLink">
						<div class="row">
							<div class="flex-1 p-1">
								<span class="col-header">Offer link</span>
								<div class="long-word-wrap">
									<a data-cy="offerLink" target="_blank" href="{{offer.offerLink}}">{{ offer.offerLink }}</a>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="flex-1 p-1">
								<span class="col-header">Date Received</span>
								<div>{{ offer.received | toDateObj | date:'shortDate' }}</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</mat-card>
	</div>
</ng-template>
