import { Component, OnInit, Input, Output, EventEmitter, computed } from '@angular/core';
import { EmailSetting } from 'src/app/interfaces/email-setting.model';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeOut, fadeIn } from 'ng-animate';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-old-email-settings',
  templateUrl: './old-email-settings.component.html',
  styleUrls: ['./old-email-settings.component.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, { params: { timing: 0.3 }})
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, { params: { timing: 0.3 }})
      )
    ])
  ],
})
export class OldEmailSettingsComponent implements OnInit {

  @Input() emailSettings: EmailSetting[];
  @Input() emailSettingsUpdateStatus: 'pending' | 'success' | 'error' | null;
  @Output() updateSetting = new EventEmitter<EmailSetting>();

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) { }

  ngOnInit(): void {}

  emitSettingUpdate(setting: EmailSetting) {
    const settingCopy = { ...setting };
    settingCopy.status = !setting.status;
    this.updateSetting.emit(settingCopy);
  }

  emitSettingChildUpdate(setting: EmailSetting) {
    if (setting.children) {
      setting.children.forEach((child, i) => {
        const childCopy = { ...child };
        childCopy.status = !childCopy.status;
        this.updateSetting.emit(childCopy);
      });
    }
  }
}
