export class ActivateDealPageTabEvent {
  constructor(
    public tabName: string,
  ) {
  }
}

export class LatestImportUpdatedEvent {
  constructor() {}
}
