<div data-cy="assign-dropdown"
     class="assignments-display rounded text-sm cursor-pointer p-2 pr-1 flex content-center flex-wrap border border-gray-300 bg-white"
     [matMenuTriggerFor]="assignments"
     (menuClosed)="menuClosed()"
>
    <span class="flex-grow truncate">{{ getDisplay() }}</span>
    <mat-icon class="text-gray-700">arrow_drop_down</mat-icon>
</div>
<mat-menu #assignments="matMenu" (click)="$event.stopPropagation()">
    <ng-template matMenuContent>
        <button mat-button class="!w-full justify-start"
                [color]="selection.isSelected(user) ? 'primary' : ''"
                *ngFor="let user of portalUsers" (click)="selection.toggle(user); $event.stopPropagation()">
            <div class="flex flex-left">
                <mat-icon>
                    {{ selection.isSelected(user) ? 'check_box' : 'check_box_outline_blank'}}
                </mat-icon>
                <span class="pl-3">{{ user.fullName }}</span>
            </div>

        </button>
    </ng-template>
</mat-menu>
