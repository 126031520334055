import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {catchError, tap} from 'rxjs/operators';
import {LenderStatisticsService} from '../../services/lender-statistics.service';
import {GetLenderStatistics} from './lender-statistics.actions';
import {CreateNewAlert} from '../global-alerts/global-alerts.actions';
import {throwError} from 'rxjs';

import * as moment from 'moment';

export class LenderStatisticsStateModel {
    lenderStatistics: {} | null;
    formattedStatistics: {} | null;
    graphData: {}[];
}

@State<LenderStatisticsStateModel>({
    name: 'lenderStatistics',
    defaults: {
        lenderStatistics: null,
        formattedStatistics: null,
        graphData: [
            {
                time: 'appSent',
                graph: 'numApplications',
                weeks: 8,
                isPercent: false
            },
            {
                time: 'appSent',
                graph: 'responseRate',
                weeks: 8,
                isPercent: true
            },
            {
                time: 'appSent',
                graph: 'offerRate',
                weeks: 8,
                isPercent: true
            },
            {
                time: 'appSent',
                graph: 'closeRate',
                weeks: 8,
                isPercent: true
            },
            {
                time: 'appSent',
                graph: 'hoursToResponse',
                weeks: 8,
                isPercent: false
            },
            {
                time: 'appSent',
                graph: 'hoursToOffer',
                weeks: 8,
                isPercent: false
            },
            {
                time: 'appSent',
                graph: 'daysToClose',
                weeks: 8,
                isPercent: false,
                absolute: true
            },
        ]
    }
})
@Injectable()

export class LenderStatisticsState {

    @Selector()
    static lenderStatistics(state: LenderStatisticsStateModel) {
        return state.lenderStatistics;
    }

    @Selector()
    static formattedStatistics(state: LenderStatisticsStateModel) {
        return state.formattedStatistics;
    }

    constructor(
        private lenderStatisticsService: LenderStatisticsService,
        private store: Store
    ) {}

    @Action(GetLenderStatistics)
    getLenderStatistics({ patchState, getState }: StateContext<LenderStatisticsStateModel>, {}: GetLenderStatistics) {
        return this.lenderStatisticsService.getLenderStatistics().pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve dashboard data. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            const graphData = getState().graphData;
            const lenderStatistics = response.data.data;
            lenderStatistics.lostDeals = response.data.lostDeals;
            lenderStatistics.wonDeals = response.data.wonDeals;

            if (lenderStatistics.funnelStats && lenderStatistics.funnelStats.length &&
                lenderStatistics.funnelSpeed && lenderStatistics.funnelSpeed.length) {
                const combinedStats = lenderStatistics.funnelStats.map((funnelStat: any, i: string | number) => {
                    return Object.assign({}, funnelStat, lenderStatistics.funnelSpeed[i]);
                });
                const formattedStatistics = this.formatLenderStatistics(graphData, combinedStats);
                patchState({ lenderStatistics, formattedStatistics });
                return;
            }
            patchState({ lenderStatistics: {}, formattedStatistics: {} });
        }));
    }

    formatLenderStatistics(graphData: any[], lenderStatistics: any[]) {
        if (!lenderStatistics) {
            console.error('No lender statistics data was found.');
            return null;
        }
        if (!graphData || graphData.length === 0) {
            console.error('No graph data was initialized');
            return null;
        }

        const formattedData = graphData.map((data: any) => {
            const timeName = data.time;
            const graphName = data.graph;
            const weeksToKeep = data.weeks || 8;

            const end = moment().subtract(1, 'weeks').startOf('week');
            let current = moment().subtract(weeksToKeep, 'weeks').startOf('week');

            const dataPoints = {};
            lenderStatistics.forEach(week => {
                const start = moment(week[timeName]).startOf('week').format();
                if (data.isPercent) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  dataPoints[start] = week[graphName] * 100;
                } else {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  dataPoints[start] = week[graphName];
                }
            });

            const cleanedData: any = [];
            while (current.diff(end) <= 0) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              let value = dataPoints[current.startOf('week').format()] || 0;
                if (data.absolute) {
                    value = Math.abs(value);
                }
                cleanedData.push({
                    value,
                    name: current.toDate(),
                    weekStart: current.toDate(),
                    weekEnd: current.clone().add(6, 'days').toDate()
                });
                current = current.add(1, 'weeks');
            }

            return {
                data: cleanedData,
                key: `${timeName}-${graphName}-${weeksToKeep}`
            };
        });

      return formattedData.reduce((a, c) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        a[c.key] = c.data;
          return a;
        }, {});
    }
}
