<div data-cy="business-details-contacts" class="grid md:grid-cols-2 lg:grid-cols-3 gap-4 m-4">

    <!-- Contact cards -->
    @for (contact of (contacts$ | async); track contact) {
        <mat-card data-cy="business-details-contact" appearance="outlined" class="business-contacts-card">
            <mat-card-header class="justify-between items-center">
                <mat-card-title>
                    <div class="grid grid-flow-col gap-2 place-items-center">
                        {{contact.first  + ' ' + contact.last}}
                        @if (contact.isPrimary) {
                            <mat-chip class="contacts-page-chip mat-body-2">
                                Primary
                            </mat-chip>
                        }
                    </div>
                </mat-card-title>
                @if (_hasModifyAccess((business$ | async)!)) {
                    <button mat-icon-button
                        data-cy="business-contact-edit-button"
                        class="business-contacts-edit-button"
                        (click)="openEditDialog(contact)">
                        <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
                    </button>
                }
            </mat-card-header>
            <div class="lendio-mat-card-divider"></div>
            <mat-card-content>
                <div class="flex">
                    <table class="grow table table-auto">
                        <tr class="table-row">
                            <td class="label">Email</td>
                            <td class="value">
                                <span>{{ contact.email }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Mobile phone</td>
                            <td class="value">
                                <span>{{ contact.mobilePhone | phone }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Work phone</td>
                            <td class="value">
                                <span>{{ contact.workPhone | phone }}</span>
                                <span *ngIf="contact.workPhoneExt"> ext. {{ contact.workPhoneExt }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Birth-date</td>
                            <td class="value">
                                <span>{{ contact.birthDate | date }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Social security</td>
                            <td class="value">
                                <span *ngIf="contact.ssnLastFour">xxx-xx-{{ contact.ssnLastFour }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Owner?</td>
                            <td class="value">
                                <span>{{ isOwner(contact) ? 'Yes' : 'No' }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Ownership %</td>
                            <td class="value">
                                <span>{{ percentOwnership(contact) }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Job title</td>
                            <td class="value">
                                <span>{{ contact.title }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Address</td>
                            <td class="value multi-line">
                                <span *ngIf="contact?.street">{{ contact.street }}<br /></span>
                                <span *ngIf="contact?.street2">{{ contact.street2 }}<br /></span>
                                <span *ngIf="getCityStateZip(contact)">{{ getCityStateZip(contact) }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    }
    <!-- Add contact card -->
    @if ((business$ | async) !== null && _hasModifyAccess((business$ | async)!)) {
        <button class="add-contact-clickable-card grow flex justify-center items-center" (click)="openEditDialog()" data-cy="business-details-add-contact">
            <div class="flex flex-row items-center gap-2">
                <mat-icon color="primary" fontSet="material-icons">add</mat-icon>
                <div class="mat-body-2">Add contact</div>
            </div>
        </button>
    }


</div>
