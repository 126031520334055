import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
    ClearLabels,
    GetLabels,
    DeleteLabel,
    CreateLabel,
    EditLabel
} from './labels.actions';
import { tap, catchError } from 'rxjs/operators';
import { LabelsService } from '../../services/labels.service';
import { Injectable } from '@angular/core';
import { Label } from '../../interfaces/label.model';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';

export class LabelsStateModel {
    labels: Label[] | null;
    deletedLabels: number[];
    labelsRetrieved: boolean;
}

@State<LabelsStateModel>({
    name: 'labels',
    defaults: {
        labels: null,
        deletedLabels: [],
        labelsRetrieved: false
    }
})
@Injectable()

export class LabelsState {

    @Selector()
    static labels(state: LabelsStateModel) {
        return state.labels;
    }

    @Selector()
    static deletedLabels(state: LabelsStateModel) {
        return state.deletedLabels;
    }

    constructor(
        private labelsService: LabelsService,
        private store: Store
    ) {}

    @Action(GetLabels)
    getLabels({ getState, patchState }: StateContext<LabelsStateModel>, {}: GetLabels) {
        if (!getState().labelsRetrieved) {
            patchState({labelsRetrieved: true});
            return this.labelsService.getLabels().pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve labels. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap(response => {
                patchState({ labels: response.data });
            }));
        }
        return getState().labels;
    }

    @Action(ClearLabels)
    clearLabels({ patchState }: StateContext<LabelsStateModel>, {}: ClearLabels) {
        patchState({ labels: null, labelsRetrieved: false });
    }

    @Action(DeleteLabel)
    deleteLabel({ getState, patchState }: StateContext<LabelsStateModel>, {labelId}: DeleteLabel) {
        return this.labelsService.deleteLabel(labelId).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to delete label. Please try again.'
            }));
            return throwError(err);
        }), tap(() => {
            const currentState = JSON.parse(JSON.stringify(getState().labels));

            if (currentState && Object.keys(currentState).length > 0) {
                // Remove the label from the list of lender labels
                const labelIndex = currentState.findIndex((x: { id: number; }) => x.id === labelId);
                currentState.splice(labelIndex, 1);
                patchState({ labels: currentState});

                // Add the label to the deleted array so we can be sure not to display it in apps list
                const deletedLabels = JSON.parse(JSON.stringify(getState().deletedLabels));
                deletedLabels.push(labelId);
                patchState({ deletedLabels });
            }
        }));
    }

    @Action(CreateLabel)
    createLabel({ getState, patchState }: StateContext<LabelsStateModel>, {label}: CreateLabel) {
        return this.labelsService.createLabel(label).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to create label. Please try again.'
            }));
            return throwError(err);
        }), tap((response) => {
            const currentState = JSON.parse(JSON.stringify(getState().labels));
            const addedLabel: Label = response.data;

            // Add the label to the list of lender labels
            currentState.push(addedLabel);
            currentState.sort((a: { name: number; }, b: { name: number; }) => (a.name > b.name) ? 1 : -1);
            patchState({ labels: currentState });
        }));
    }

    @Action(EditLabel)
    editLabel({ getState, patchState }: StateContext<LabelsStateModel>, {label}: EditLabel) {
        return this.labelsService.editLabel(label).pipe(catchError(err => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to edit label. Please try again.'
            }));
            return throwError(err);
        }), tap(() => {
            const currentState = JSON.parse(JSON.stringify(getState().labels));

            if (currentState && Object.keys(currentState).length > 0) {
                // Update the label in the list of lender labels
                const labelIndex = currentState.findIndex((x: { id: number; }) => x.id === label.id);

                currentState[labelIndex].name = label.name;
                currentState[labelIndex].color = label.color;
                currentState.sort((a: { name: number; }, b: { name: number; }) => (a.name > b.name) ? 1 : -1);
                patchState({ labels: currentState });
            }
        }));
    }
}
