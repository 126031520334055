<div class="m-auto">
    <div *ngIf="!loading; else loadingState">
        <img class="m-auto pb-4" [src]="emptyStateImagePath" />
        <mat-card-title class="text-center">{{label}}</mat-card-title>
        <ng-content select="[messageContent]"></ng-content>
    </div>

    <ng-template #loadingState>
        <app-dot-loader-scale class="mx-auto"></app-dot-loader-scale>
    </ng-template>
</div>
