import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import { ThoughtSpotService } from '@app/app/services/thoughtspot-service';
import {
  Action,
  LiveboardEmbed,
} from '@thoughtspot/visual-embed-sdk';
import {
  ServiceUnavailableError,
  ThoughtspotErrorHandleService
} from "@app/app/components/thoughtspot-liveboard/thoughtspot-error-handle.service";
import {
  ThoughtspotServiceUnavailableComponent
} from "@app/app/components/thoughtspot-liveboard/thoughtspot-service-unavailable/thoughtspot-service-unavailable.component";
import { ThoughtspotReportsState } from '@app/app/store/thoughtspot-reports/thoughtspot-reports.state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  standalone: true,
  imports: [CommonModule, ThoughtspotServiceUnavailableComponent],
  selector: 'app-thoughtspot-liveboard',
  templateUrl: './thoughtspot-liveboard.component.html',
  styleUrls: ['./thoughtspot-liveboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThoughtspotLiveboardComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('tsEmbedElement', { static: false })
  tsEmbedElement: ElementRef<HTMLDivElement>;
  @Input({ required: true }) liveboardId: string;

  private _liveboardEmbed: LiveboardEmbed;
  public tsEmbedSuccess = true;
  public tsServiceUnavailable = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Select(ThoughtspotReportsState.tsAuthenticated) tsAuthenticated$: Observable<boolean>;

  constructor(
    private _tsService: ThoughtSpotService,
    private _titleService: NavPageTitleService,
    private _tsErrorHandler: ThoughtspotErrorHandleService,
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle('Dashboard');
  }

  ngAfterViewInit(): void {
    this.tsAuthenticated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(authed => {
        if (authed) {
          this._embedTSDashboard();
        }
      });
  }

  ngOnDestroy(): void {
    // Stop listening to embed errors when we leave the dashboard.
    if (this._liveboardEmbed) {
      this._liveboardEmbed.off(
        this._tsService.EmbedEvent.Error,
        this._tsErrorHandler.handleError
      );
    }
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Embed ThoughtSpot liveboard and register error handler.
   */
  protected _embedTSDashboard(): void {
    const thoughtSpotDiv = this.tsEmbedElement.nativeElement;
    try {
      this._liveboardEmbed = this._tsService.embedLiveboardDefault({
        liveboardId: this.liveboardId,
        embedDiv: thoughtSpotDiv,
        shouldCallRender: false,
        visibleActions: [
          Action.Present,
          Action.Download,
          Action.DownloadAsPdf,
          Action.DownloadAsPng,
          Action.DownloadAsXlsx,
          Action.DownloadAsCsv,
          Action.ShowUnderlyingData,
          Action.Explore,
          Action.DrillDown,
        ],
      });
    } catch (e: unknown) {
      if (e instanceof ServiceUnavailableError) {
        this._tsErrorHandler.handleError(e.originalError);
        this.showServiceUnavailablePage();
      } else {
        if (typeof e === 'string') {
          // TODO: Report to Rollbar instead
          console.error('Error creating liveboard: ', e);
        } else if (e instanceof Error) {
          // TODO: Report to Rollbar instead
          console.error('Error creating liveboard: ', e.message);
        }
        this.tsEmbedSuccess = false;
      }
    }

    this._liveboardEmbed
      .on(this._tsService.EmbedEvent.Error, this._tsErrorHandler.handleError)
      .render();
  }

  showServiceUnavailablePage(): void {
    this.tsEmbedSuccess = false;
    this.tsServiceUnavailable = true;
  }
}
