import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { catchError, take, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { CreateNewAlert } from 'src/app/store/global-alerts/global-alerts.actions';
import { passwordMatchesValidator } from "src/app/custom-form-validators";

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  submitting = false;
  newPassword = {
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  };
  showPassword = false;
  showNewPassword = false;
  newPasswordForm: FormGroup;
  numberRegex = /\d{1}/;
  lowercaseRegex = /[a-z]{1}/;
  uppercaseRegex = /[A-Z]{1}/;

  constructor(
    private settingsService: SettingsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.newPasswordForm = new FormGroup({
      currentPassword: new FormControl(this.newPassword.currentPassword, [
        Validators.required,
      ]),
      newPassword: new FormControl(this.newPassword.newPassword, [
        Validators.required,
        Validators.minLength(14)
      ]),
      repeatNewPassword: new FormControl(this.newPassword.repeatNewPassword, [
        Validators.required,
      ])
    }, {
      validators: passwordMatchesValidator
    });
  }

  submit() {
    this.submitting = true;
    this.settingsService.newPassword(this.newPasswordForm.value).pipe(catchError((err: any) => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: err?.error?.errors[0]?.message?.newPassword[0]
      }));
      this.resetForm();
      return throwError(err);
    }), take(1), tap(() => {
      this.store.dispatch(new CreateNewAlert({
        level: 'success',
        message: 'Your password has been updated!'
      }));
      this.resetForm();
    })).subscribe();
  }

  atLeastOneFieldFilledIn() {
    return (this.newPasswordForm.value.currentPassword !== ''
      || this.newPasswordForm.value.newPassword !== ''
      || this.newPasswordForm.value.repeatNewPassword !== '');
  }

  checkError(controlName: string, errorName: string) {
    return this.newPasswordForm.controls[controlName].hasError(errorName);
  }

  resetForm() {
    this.newPasswordForm.reset();
    Object.keys(this.newPasswordForm.controls).forEach(key => {
      this.newPasswordForm.controls[key].setErrors(null);
    });
    this.submitting = false;
  }
}
