<ng-container>
    <div class="group-title">
        <div class="inline-block"
             style="padding-right: 17rem !important;"
             [ngClass]="showRenamingInput ? 'text-xs' : 'text-base'"
             data-cy="qualifier-exception-groups-component-group-name">
            <mat-form-field class="!group-name-input-field" appearance="fill" *ngIf="showRenamingInput; else elseBlock">
                <mat-label>Name</mat-label>
                <input #groupName="matInput"
                       matInput
                       placeholder="{{ this.exceptionGroup.name }}"
                       data-cy="qualifier-exception-groups-component-rename-group-input">
            </mat-form-field>
            <ng-template #elseBlock>{{ this.exceptionGroup.name }}</ng-template>
            <span> — </span>
            <div class="inline-block"
                 [ngStyle]="{
                 'color': this.exceptionGroup && this.exceptionGroup.baseExceptionsCounter &&
                          this.exceptionGroup.baseExceptionsCounter > 0 ? '#c8830b' : '#000000'
                          }"
                 data-cy="qualifier-exception-groups-component-base-exceptions-counter">
                {{ this.exceptionGroup.baseExceptionsCounter }} base
                {{this.exceptionGroup.baseExceptionsCounter && this.exceptionGroup.baseExceptionsCounter === 1 ?
                'exception' : 'exceptions'}}
            </div>
        </div>
        <button *ngIf="hasWriteAccess && !showRenamingInput && exceptionGroup && exceptionGroup.id !== 0" class="!text-gray-1000" mat-button color="primary"
                (click)="showRenamingInput = true"
                data-cy="qualifier-exception-groups-component-rename-group">
        Rename Group
        </button>
        <span class="cursor-pointer pl-2" *ngIf="hasWriteAccess && showRenamingInput">
            <button mat-flat-button color="primary"
                    (click)="rename(groupName.value)"
                    data-cy="qualifier-exception-groups-save-new-group-name">
              Save
            </button>
            <button mat-button color="warn" (click)="showRenamingInput = false">
              Cancel
            </button>
        </span>
        <button *ngIf="hasWriteAccess && !showRenamingInput && exceptionGroup && exceptionGroup.id !== 0" class="!text-gray-1000" mat-button color="primary"
                (click)="openDeleteGroupDialog()"
                data-cy="qualifier-exception-groups-component-delete-group">
        Delete Group
        </button>
    </div>

    <div *ngIf="showIfStatementRequirementWarning" class="bg-orange-200 text-yellow-700 rounded px-3 py-1 text-sm">
        Select and save at least one <strong>“IF”</strong> statement in order to add a <strong>“THEN”</strong> statement.
    </div>

    <ng-container *ngIf="exceptionGroup">
        <div class="pt-1">
            <div class="text-gray-1000 py-1 text-sm">IF</div>
            <ng-container *ngIf="exceptionGroup.conditions && exceptionGroup.conditions.length > 0">
                <ng-container *ngFor="let ifConditionQualifier of exceptionGroup.conditions; last as isLast">
                    <app-qualifier *ngIf="ifConditionQualifier"
                                   (dirty)="dirty($event, true)"
                                   (update)="update($event)"
                                   (remove)="isLast && exceptionGroup.conditions.length === 1 ? openDeleteGroupDialog() : remove($event)"
                                   (cancelUpdatesToQualifier) = "cancelQualifierUpdates(ifConditionQualifier, 'if')"
                                   [qualifier]="ifConditionQualifier"
                                   [exceptionGroup]="exceptionGroup"
                                   [questions]="questions"
                                   [addingIfQualifier]="addingIfQualifier"
                                   [showAndButton]="isLast"
                                   (addingIfQualifierChange)="addingIfQualifier = true"
                                   [loanProductId]="loanProductId"
                                   [qualifiers]="qualifiers"
                                   [hasWriteAccess]="hasWriteAccess"
                                   [states]="states"
                                   [disabledAttributeFields]="disabledAttributeFieldsInExceptions"
                                   [disabledOperatorFilterTypes]="disabledOperatorFilterTypesInExceptions"
                                   [showUpdateButton]="showUpdateButtonByCurrentQualifier(ifConditionQualifier.id)">
                    </app-qualifier>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="exceptionGroup.conditions && (exceptionGroup.conditions.length === 0 || addingIfQualifier)">
                <app-qualifier (save)="addQualifierToGroup($event, true)"
                               (cancel)="cancelSavingIfQualifier()"
                               [questions]="questions"
                               [states]="states"
                               [loanProductId]="loanProductId"
                               [qualifiers]="qualifiers"
                               [hasWriteAccess]="hasWriteAccess"
                               [disabledAttributeFields]="disabledAttributeFieldsInExceptions"
                               [disabledOperatorFilterTypes]="disabledOperatorFilterTypesInExceptions"
                               [exceptionGroup]="exceptionGroup">
                </app-qualifier>
            </ng-container>
        </div>
        <div class="pl-6 pb-1">
            <div class="text-gray-1000 pb-1 text-sm">THEN, IN ORDER TO QUALIFY</div>
            <ng-container *ngIf="exceptionGroup.exceptions && exceptionGroup.exceptions.length > 0">
                <ng-container *ngFor="let thenExceptionQualifier of exceptionGroup.exceptions">
                    <app-qualifier *ngIf="thenExceptionQualifier"
                                   (dirty)="dirty($event, false)"
                                   (update)="update($event)"
                                   (remove)="remove($event)"
                                   (cancelUpdatesToQualifier) = "cancelQualifierUpdates(thenExceptionQualifier, 'then')"
                                   [exceptionGroup]="exceptionGroup"
                                   [labelColor]="this.exceptionGroup.baseExceptionsMap.has(thenExceptionQualifier) ?
                                                 '#c8830b' : '#000000'"
                                   [qualifier]="thenExceptionQualifier"
                                   [questions]="questions"
                                   [loanProductId]="loanProductId"
                                   [qualifiers]="qualifiers"
                                   [hasWriteAccess]="hasWriteAccess"
                                   [states]="states"
                                   [disabledAttributeFields]="disabledAttributeFieldsInExceptions"
                                   [disabledOperatorFilterTypes]="disabledOperatorFilterTypesInExceptions"
                                   [showUpdateButton]="showUpdateButtonByCurrentQualifier(thenExceptionQualifier.id)">
                    </app-qualifier>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="exceptionGroup && exceptionGroup.exceptions &&
                                 exceptionGroup.exceptions.length === 0 || addingThenQualifier">
                <app-qualifier (save)="addQualifierToGroup($event, false)"
                               (cancel)="cancelSavingThenQualifier()"
                               [questions]="questions"
                               [states]="states"
                               [loanProductId]="loanProductId"
                               [qualifiers]="qualifiers"
                               [hasWriteAccess]="hasWriteAccess"
                               [disabledAttributeFields]="disabledAttributeFieldsInExceptions"
                               [disabledOperatorFilterTypes]="disabledOperatorFilterTypesInExceptions"
                               [exceptionGroup]="exceptionGroup">
                </app-qualifier>
            </ng-container>
            <ng-container *ngIf="exceptionGroup.exceptions && exceptionGroup.exceptions.length > 0">
                <button class="!pl-0" mat-button color="primary"
                        *ngIf="hasWriteAccess && !addingThenQualifier"
                        [disabled]="exceptionGroup.exceptions.length === 0 || exceptionGroup.exceptions.length === 0"
                        (click)="addingThenQualifier = true"
                        data-cy="qualifier-exception-groups-component-add-then-qualifier-to-group">
                    + Add another "then" statement
                </button>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
