<div>
    <button mat-flat-button 
    	[disabled]="isUploading"
        color="primary" 
        class="!mr-2 !mb-2"
        (click)="fileInput.click()"
        [class.spinner]="isUploading"
    >
        <span *ngIf="isUploading; else import">&nbsp;</span>
        <ng-template #import>
            <span class="text-lg">
                <mat-icon [inline]="true" class="align-middle">cloud_upload</mat-icon>
            </span>
            <span class="ml-2">Import</span>
        </ng-template>
        <input #fileInput type="file" (change)="upload($event.target.files)" class="hidden" />
    </button>
</div>