<div class="font-bold mb-2">{{chartData.name}}</div>
<mat-card appearance="outlined" class="!px-0 !py-0 h-32 overflow-hidden" (mouseenter)="overlay = false"  (mouseleave)="overlay = true">
    <!-- Relative positioned catches/sizes the absolute positioned -->
    <div class="relative h-full w-full">
        <!-- Chart Overlay -->
        <div 
            *ngIf="overlay"
            [@fadeEaseInOut]
            class="absolute flex flex-col items-center justify-around inset-0 text-center z-10"
        >
            <div *ngIf="chartData.average" class="p-4"></div>
            <div class="flex items-start">
                <div class="h-12">
                    <span class="text-bold text-5xl leading-tight">{{ chartData.latest.value | number:'1.0-0' }}</span>
                    <span class="text-sm">{{ chartData.suffix }}</span>
                </div>
                <div class="ml-8 h-full flex flex-col justify-end items-center" 
                    *ngIf="chartData.change && chartData.change < 15 && chartData.change !== 0"
                >
                    <mat-icon *ngIf="chartData.change > 0" class="arrow-icon text-green-500">
                        arrow_drop_up
                    </mat-icon>
                    <span class="leading-none">
                        {{ chartData.change * 100 | number:'1.0-0' }}%
                    </span>
                    <mat-icon *ngIf="chartData.change < 0" class="-mt-1 arrow-icon text-red-500">
                        arrow_drop_down
                    </mat-icon>
                </div>
            </div>
            <div *ngIf="chartData.average" class="w-full mb-2 text-gray-700 font-normal">
                {{ chartData.average * 100 | number:'1.0-0' }}% competitive avg.
            </div>
        </div>

        <!-- Chart must be in absolute positioned element to use grids -->
        <div class="absolute inset-0 -my-2 -mx-5">
            <ngx-charts-area-chart
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [yScaleMin]="yScaleMin"
                [yScaleMax]="yScaleMax"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [timeline]="timeline" 
                [scheme]="colorScheme"
                [results]="chartData.ngxChartData"
                [curve]="curve"
            >
                <ng-template #seriesTooltipTemplate let-model="model">
                    <div class="p-2">
                        <div class="leading-tight">{{ model && model[0] && model[0].name | toDateObj | date : 'shortDate' : locale }}</div>
                        <div class="leading-tight">
                            <span class="font-bold">{{ model && model[0] && model[0].value }}</span>
                            {{ chartData.suffix }}
                        </div>
                    </div>
                </ng-template>
            </ngx-charts-area-chart>
        </div>
    </div>
</mat-card>
