import { State, Action, StateContext, Selector } from '@ngxs/store';
import { CreateNewAlert, DismissAlert, ClearAlertsStore } from './global-alerts.actions';
import { Injectable } from '@angular/core';
import { Alert } from '../../interfaces/alert.model';

export class GlobalAlertsStateModel {
    alerts: Alert[] | null;
}

@State<GlobalAlertsStateModel>({
    name: 'alerts',
    defaults: {
        alerts: null
    }
})
@Injectable()

export class GlobalAlertsState {

    @Selector()
        static alerts(state: GlobalAlertsStateModel) {
        return state.alerts;
    }

    constructor() {}

    @Action(CreateNewAlert)
    createNewAlert({ getState, patchState }: StateContext<GlobalAlertsStateModel>, { alert }: CreateNewAlert) {
        if (getState().alerts) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            patchState({ alerts: [ ...getState().alerts, alert ]});
        } else {
            patchState({ alerts: [ alert ]});
        }
    }

    @Action(DismissAlert)
    dismissAlert({ getState, patchState }: StateContext<GlobalAlertsStateModel>, { alert }: DismissAlert) {
        const alertsCopy = [ ...getState().alerts ];
        const updatedAlerts = alertsCopy.filter(a => {
            return a.level !== alert.level && a.message !== alert.message;
        });
        patchState({ alerts: updatedAlerts });
    }

    @Action(ClearAlertsStore)
    clearAlertsStore({ patchState }: StateContext<GlobalAlertsStateModel>, {}: ClearAlertsStore) {
        patchState({ alerts: null });
    }
}
