import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsState } from 'src/app/store/notifications/notifications.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { NotificationService } from 'src/app/services/notification.service';
import { ForceInitNotificationStore } from 'src/app/store/notifications/notifications.actions';

@Component({
  selector: 'app-notification-icon-smart',
  templateUrl: './icon-smart.component.html',
  styleUrls: ['./icon-smart.component.scss']
})
export class IconSmartComponent implements OnInit, AfterViewInit {
  // @Select(NotificationsState.count) count$: Observable<number>;
  count$: Observable<number> = this.store.select(NotificationsState.count);
  public expanded: boolean;
  @Input() newTheme = false;
  @Output() newNotifications: EventEmitter<Observable<number>> = new EventEmitter();

  constructor(
    private notificationService: NotificationService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new ForceInitNotificationStore());
    this.retrieveExpanded();
  }

  ngAfterViewInit(): void {
    this.newNotifications.emit(this.count$);
  }

  retrieveExpanded() {
      this.expanded = localStorage.getItem('notificationSidenavExpanded') === 'true' ? true : false;
  }

  toggle() {
    this.notificationService.toggle();
    this.retrieveExpanded();
  }
}
