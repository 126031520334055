<div #tsEmbedElement id="ts-embed" *ngIf="tsEmbedSuccess; else errorTemplate"></div>
<ng-template #errorTemplate>
    <ng-container *ngIf="tsServiceUnavailable; else GeneralErrorTemplate">
        <app-thoughtspot-service-unavailable></app-thoughtspot-service-unavailable>
    </ng-container>
    <ng-template #GeneralErrorTemplate>
        <div class="new-theme-typography">
            <div class="mat-subtitle-1">
                Error fetching data, please try again later
            </div>
        </div>
    </ng-template>
</ng-template>

