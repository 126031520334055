<div [@fadeIn] class="auth-base">
    <div *ngIf="(authAlert$ | async) as alert" class="mb-3">
        <app-alert
            [level]="alert.level"
            [message]="alert.message"
            [dismissible]="true"
            [showIcon]="false"
            (dismissed)="dismissAlert()">
        </app-alert>
    </div>
    <ng-container [ngSwitch]="action">
        <mat-card appearance="outlined" class="login-form !p-0">
            <div class="flex border-b border-solid border-gray-300 !mb-4 lendio-logo">
                <img src="assets/images/logo - dark.svg" style="height: 24px" alt="Lendio logo">
            </div>
            <mat-card-content class="!mt-4 !pt-0 !px-6 !pb-6">
                <app-account-locked [@fadeIn] *ngSwitchCase="'account-locked'"></app-account-locked>
                <app-reset-password [@fadeIn] *ngSwitchCase="'reset-password'"></app-reset-password>
                <app-mfa-code-verification [@fadeIn] *ngSwitchCase="'mfa-verify'"></app-mfa-code-verification>
                <app-password-expired [@fadeIn] *ngSwitchCase="'password-expired'"></app-password-expired>
                <app-login *ngSwitchDefault [@fadeIn]></app-login>
            </mat-card-content>
        </mat-card>
    </ng-container>
    <p class="text-center mat-body-1 pt-4 font-normal">
        &copy; {{ getYear() }} Lendio &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="javascript:;" (click)="openTC()" class="cursor-pointer text-blue-400 hover:text-blue-800">Terms & Conditions</a>
    </p>
</div>
