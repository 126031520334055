import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from "@app/app/components/settings/settings.component";
import { EmailSettingsComponent } from "@app/app/components/settings/email-settings/email-settings.component";
import { NewPasswordComponent } from "@app/app/components/settings/new-password/new-password.component";
import { ManageUsersComponent } from "@app/app/components/settings/manage-users/manage-users.component";
import { ManageSbaTokenComponent } from "@app/app/components/settings/manage-sba-token/manage-sba-token.component";
import { NotFoundComponent } from "@app/app/components/errors/not-found/not-found.component";
import { EarlyAccessComponent } from "@app/app/components/settings/early-access/early-access.component";

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      /**
       * DEFAULT ROUTE - Load Notifications
       */
      {
        path: 'notifications',
        component: EmailSettingsComponent,
        title: 'Settings'
      },
      {
        path: 'update-password',
        component: NewPasswordComponent,
        title: 'Settings'
      },
      {
        path: 'users',
        component: ManageUsersComponent,
        title: 'Settings'
      },
      {
        path: 'sba-token',
        component: ManageSbaTokenComponent,
        title: 'Settings'
      },
      {
        path: 'early-access',
        component: EarlyAccessComponent,
        title: 'Settings'
      },
      {
        path: '',
        redirectTo: 'notifications',
        pathMatch: 'full'
      },
      {
        path: '**',
        component: NotFoundComponent,
        title: 'Settings'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SettingsRoutingModule {
}
