import { NgModule } from '@angular/core';
import {DealStatusDisplayPipe} from './deal-status-display.pipe';

@NgModule({
    imports: [],
    exports: [DealStatusDisplayPipe],
    declarations: [DealStatusDisplayPipe],
    providers: [],
})
export class DealStatusDisplayModule { }
