<div>
    <h2 class="mat-headline-4 !m-0 !mb-4">Password expired.</h2>
    <p class="mat-body-1">Please set a new password. Your password must be at least 14 characters long, with at least 1 number and 1 uppercase letter.</p>
</div>
<div>
    <form [formGroup]="expiredPasswordForm" (ngSubmit)="submit()" class="reset-password-form mt-4">
        <!-- old password -->
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Old Password</mat-label>
            <input formControlName="oldPassword"
                required
                name="oldPassword"
                matInput
                type="{{ showOldPassword ? 'text' : 'password' }}">
            <mat-icon matSuffix (click)="showOldPassword = !showOldPassword" class="cursor-pointer">{{ showOldPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>

        <!-- password -->
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Create Password</mat-label>
                <input formControlName="newPassword"
                required
                name="newPassword"
                matInput
                type="{{ showPassword ? 'text' : 'password' }}">
            <mat-icon matSuffix (click)="showPassword = !showPassword" class="cursor-pointer">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>
        <mat-error class="mat-caption pl-2" *ngIf="newPassword?.dirty && newPassword?.errors && newPassword?.errors.minlength" [@fadeIn]>Password must be at least 14 characters.</mat-error>
        <mat-error class="mat-caption pl-2 pb-6" *ngIf="newPassword?.dirty && newPassword?.errors && newPassword?.errors.complexity" [@fadeIn]>Password must contain at least one number, one uppercase letter, and one lowercase letter.</mat-error>

        <!-- confirm password -->
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Repeat Password</mat-label>
            <input formControlName="confirmPassword"
                required
                name="confirmPassword"
                matInput
                type="{{ showConfirmPassword ? 'text' : 'password' }}">
            <mat-icon matSuffix (click)="showConfirmPassword = !showConfirmPassword" class="cursor-pointer">{{ showConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>
        <mat-error class="mat-caption pl-2 pb-5" *ngIf="newPassword?.value !== confirmPassword?.value && newPassword?.dirty && confirmPassword?.dirty" [@fadeIn]>
            Passwords do not match.
        </mat-error>

        <!-- button -->
        <div class="flex justify-end !my-1">
            <button mat-flat-button color="primary" [disabled]="!canSubmit()">
                <span *ngIf="submitting else text" class="spinner">&nbsp;</span>
                <ng-template #text>
                    <span class="mat-body-1">Set Password</span>
                </ng-template>
            </button>
        </div>
    </form>
</div>
