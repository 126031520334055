<div [class.old-theme-section-header]="oldThemeEnabled()">
<mat-card appearance="outlined" *ngIf="applicationDetails.deal" class="section-header" data-cy="section-header">
	<div class="flex-grid">

		<div class="col">
			<h1 class="!m-0">{{ applicationDetails.deal.id }} — {{ applicationDetails.deal.borrower.name }}</h1>
            <div class="loan-product mt-1 mb-4">{{ applicationDetails.deal.loan_product.name }} <span *ngIf="isRenewal" class="text-green-500"><mat-icon class="text-green-500">replay</mat-icon> Renewal</span></div>

			<app-labels
				*ngIf="applicationDetails.deal.lender_labels"
				[lenderLabels]="lenderLabels"
				[labels]="applicationDetails.deal.lender_labels"
				[dealId]="applicationDetails.deal.id">
			</app-labels>
		</div>

		<div class="col align-right text-gray">

			<span class="deal-status" data-cy="section-header-deal-status">
				<span *ngIf="applicationDetails.deal.status == 'awaitingResponse'; else dealStatus">Ready for Lender Review</span>
				<ng-template #dealStatus>{{ formatForDisplay(applicationDetails.deal.status) | titlecase }} </ng-template>
			</span>

			<span class="dead-reason" *ngIf="applicationDetails.deadReason">
				<br />{{ applicationDetails.deadReason }}
			</span>

			<span *ngIf="loanProductCategory !== 'SBA PPP' && loanProductCategory !== 'SBA PPP Second'; else sbaPPP">
				<app-new-offer-button-smart
					*ngIf="shouldDisplayMakeOfferBtn"
					[dealId]="applicationDetails.deal.id"
					[loanProductCategory]="loanProductCategory">
				</app-new-offer-button-smart>
				<app-decline-button-smart
					*ngIf="shouldDisplayDeclineBtn"
					[dealId]="applicationDetails.deal.id"
				></app-decline-button-smart>
			</span>

			<ng-template #sbaPPP>
				<span *ngIf="shouldDisplayPPPActions">
					<app-turn-down-button-smart [dealId]="applicationDetails.deal.id"></app-turn-down-button-smart>
					<app-upload-contract-button-smart [dealId]="applicationDetails.deal.id"></app-upload-contract-button-smart>
				</span>
			</ng-template>

			<div class="assignment">
				<span class="label">
					Assigned to
				</span>
				<span class="dropdown" *ngIf="portalUsers && applicationDetails">
					<app-assignments-smart
							[usersAssigned]="assignmentArray"
							[dealId]="applicationDetails.deal.id"
                            [portalUsers]="portalUsers">
					</app-assignments-smart>
				</span>
			</div>
		</div>
	</div>
</mat-card>
</div>
