export class GetOpportunities {
  static readonly type = '[Opportunities] GetOpportunities';
  constructor() { }
}

export class ClearOpportunitiesStore {
  static readonly type = '[Opportunities] ClearOpportunitiesStore';
  constructor() { }
}

export class DarkLaunchGetOpportunities {
  static readonly type = '[DarkLaunchOpportunities] DarkLaunchGetOpportunities';
  constructor() { }
}

export class ClearDarkLaunchOpportunities {
  static readonly type = '[DarkLaunchOpportunities] ClearDarkLaunchOpportunitiesStore';
  constructor() { }
}
