<div class="funnel-header-wrapper" [class.column-hover]="columnHover">
    <div #label
         class="funnel-header-label"
         (click)="onSortRequested(requestSort, $event)">
        {{ params.displayName }}
    </div>
    @if (params.enableFilterButton) {
        <div #filterButton
             class="ag-header-icon ag-header-cell-filter-button funnel-column-header-button"
             [class.always-visible]="columnMenuVisible || columnFilterActive"
             (click)="onFilterMenuClick()">
            <span class="ag-icon ag-icon-filter"
                  [class.ag-filter-active]="columnFilterActive"
                  role="presentation">
            </span>
        </div>
    }
    <div class="funnel-column-header-button"
         [class.always-visible]="columnMenuVisible || columnSortActive">
        @if (noSort) {
            <div class="ag-header-icon"
                 (click)="onSortRequested('asc', $event)">
                <div class="ag-icon ag-icon-asc funnel-no-sort"></div>
            </div>
        }
        @if (ascSort) {
            <div class="ag-header-icon"
                 (click)="onSortRequested('desc', $event)">
                <div class="ag-icon ag-icon-asc"></div>
            </div>
        }
        @if (descSort) {
            <div class="ag-header-icon"
                 (click)="onSortRequested(null, $event)">
                <div class="ag-icon ag-icon-desc"></div>
            </div>
        }
    </div>
    <div class="funnel-header-spacer"></div>
    @if (params.enableMenu) {
        <div #menuButton
             class="ag-header-icon ag-header-cell-menu-button ag-header-menu-icon funnel-column-header-button horizontal-dots"
             [class.always-visible]="columnMenuVisible"
             (click)="onMenuClicked()">
            <span class="ag-icon ag-icon-menu-alt" role="presentation"></span>
        </div>
    }
</div>
