@if (contractRequest.id) {
    @if (newThemeEnabled()) {
        <mat-card appearance="outlined" class="new-theme-typography lendio-density-1 !p-0 !m-4">
            <mat-card-header class="!flex !flex-row !justify-between !items-center">
                    <mat-card-title>Contract details</mat-card-title>
                    @if((hasInstantContractsFeature$ | async) && (hasTemplate$ | async)) {
                        @if(!(contractSent$ | async)) {
                            <button mat-flat-button
                            (click)="sendLenderContract()"
                            color="primary"
                            [disabled]="loading || disabled"
                            [class.spinner]="loading">
                            {{ esignButtonMessage}}
                        </button>
                        }
                    } @else if(!(hasInstantContractsFeature$ | async) || !(hasTemplate$ | async)){
                        <button mat-flat-button
                            color="primary"
                            (click)="markContractSent(contractRequest)"
                            [disabled]="loading || disabled"
                            [class.spinner]="loading">
                        {{ buttonMessage }}
                        </button>
                    }
                    
            </mat-card-header>
            <div class="lendio-mat-card-divider"></div>
            <mat-card-content>
                <div class="contract-request-card-content-layout">

                    @if (contractRequest.term_set?.type === 'loc') {
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Product type</div>
                            <div class="contract-request-content-value">{{ (loanProductName | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">{{ requestedState }} on</div>
                            <div class="contract-request-content-value">{{ sentDate | date: 'short' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Credit Limit</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.creditLimit | currency) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Term</div>
                            <div class="contract-request-content-value">{{ contractRequest.terms?.term }} {{ (contractRequest.terms?.termType | titlecase) || '-' }}</div>
                        </div>
                        @if (contractRequest.terms?.points) {
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Points</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.points) || '-' }}</div>
                        </div>
                        }
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Initial Draw Amount</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.initialDrawAmount | currency) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Payment Frequency</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.paymentFrequency | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Business Owner Email(s)</div>
                            <div class="contract-request-content-value">{{ formatEmails(contractRequest.signers!) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Best Number for Funding Call</div>
                            <div class="contract-request-content-value">{{ formatPhone(contractRequest.fundingCallPhone!) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Notes from Lendio</div>
                            <div class="contract-request-content-value">{{ contractRequest.noteForLender || '-' }}</div>
                        </div>

                    } @else if (contractRequest.term_set?.type === 'term') {
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Product type</div>
                            <div class="contract-request-content-value">{{ (loanProductName | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">{{ requestedState }} on</div>
                            <div class="contract-request-content-value">{{ sentDate | date: 'short' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Loan Amount</div>
                            <div class="contract-request-content-value">{{ contractRequest.terms?.term }} {{ (contractRequest.terms?.termType | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Term</div>
                            <div class="contract-request-content-value">{{ contractRequest.terms?.term }} {{ (contractRequest.terms?.termType | titlecase) || '-' }}</div>
                        </div>
                        @if (contractRequest.terms?.originationFeeAmount) {
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Origination Fee</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.originationFeeAmount | currency) || '-' }}</div>
                        </div>
                        }
                        @if (contractRequest.terms?.points) {
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Points</div>
                            <div class="contract-request-content-value">{{ contractRequest.terms?.points || '-' }}</div>
                        </div>
                        }
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Payment Frequency</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.paymentFrequency | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Business Owner Email(s)</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.paymentFrequency | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Best Number for Funding Call</div>
                            <div class="contract-request-content-value">{{ formatPhone(contractRequest.fundingCallPhone!) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Notes from Lendio</div>
                            <div class="contract-request-content-value">{{ contractRequest.noteForLender || '-' }}</div>
                        </div>

                    } @else {
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Product type</div>
                            <div class="contract-request-content-value">{{ (loanProductName | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">{{ requestedState }} on</div>
                            <div class="contract-request-content-value">{{ (sentDate | date: 'short') || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Loan Amount</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.amount | currency) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Term</div>
                            <div class="contract-request-content-value">{{ contractRequest.terms?.term }} {{ (contractRequest.terms?.termType | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Points</div>
                            <div class="contract-request-content-value">{{ contractRequest.terms?.points || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Factor Rate</div>
                            <div class="contract-request-content-value">{{ contractRequest.terms?.factorRate || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Payment Frequency</div>
                            <div class="contract-request-content-value">{{ (contractRequest.terms?.paymentFrequency | titlecase) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Business Owner Email(s)</div>
                            <div class="contract-request-content-value">{{ formatEmails(contractRequest.signers!) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Best Number for Funding Call</div>
                            <div class="contract-request-content-value">{{ formatPhone(contractRequest.fundingCallPhone!) || '-' }}</div>
                        </div>
                        <div class="contract-request-content-item">
                            <div class="contract-request-content-label">Notes from Lendio</div>
                            <div class="contract-request-content-value">{{ contractRequest.noteForLender || '-' }}</div>
                        </div>
                    }
                </div>
            </mat-card-content>
        </mat-card>
    } @else {
        <div class="!mt-6 grid divide-y grid-cols-1">
            <div class="card-header">
                <span class="font-bold pl-4">Contract Request</span> ({{ contractRequest?.modified > contractRequest?.sent ? "Updated On" : "Requested On"}}: {{ sentDate | date:'short'}})
                <button mat-flat-button
                        color="primary"
                        class="float-right"
                        (click)="markContractSent(contractRequest)"
                        [disabled]="loading || disabled"
                        [class.spinner]="loading">
                    {{ buttonMessage }}
                </button>
            </div>

            @if (contractRequest.term_set?.type === 'loc') {
                <div class="py-2 px-4"><span class="font-bold">Credit Limit:</span> {{ contractRequest.terms?.creditLimit | currency }}</div>
                <div class="py-2 px-4"><span class="font-bold">Term:</span> {{ contractRequest.terms?.term }} {{ contractRequest.terms?.termType | titlecase }}</div>

            @if (contractRequest.terms?.points) {
                <div class="py-2 px-4"><span class="font-bold">Points:</span> {{ contractRequest.terms?.points }}</div>
            }
            <div class="py-2 px-4"><span class="font-bold">Initial Draw Amount:</span> {{ contractRequest.terms?.initialDrawAmount | currency }}</div>
            <div class="py-2 px-4"><span class="font-bold">Payment Frequency:</span> {{ contractRequest.terms?.paymentFrequency | titlecase }}</div>
            <div class="py-2 px-4"><span class="font-bold">Business Owner Email(s):</span> {{ formatEmails(contractRequest.signers!) }}</div>
            <div class="py-2 px-4"><span class="font-bold">Best Number for Funding Call:</span> {{ formatPhone(contractRequest.fundingCallPhone!) }}</div>
            <div class="py-2 px-4"><span class="font-bold">Notes from Lendio:</span> {{ contractRequest.noteForLender }}</div>

            } @else if (contractRequest.term_set?.type === 'term') {
                <div class="py-2 px-4"><span class="font-bold">Loan Amount:</span> {{ contractRequest.terms?.amount | currency }}</div>
                <div class="py-2 px-4"><span class="font-bold">Term:</span> {{ contractRequest.terms?.term }} {{ contractRequest.terms?.termType | titlecase }}</div>
            @if (contractRequest.terms?.originationFeeAmount) {
                <div class="py-2 px-4"><span class="font-bold">Origination Fee:</span> {{ contractRequest.terms?.originationFeeAmount | currency }}</div>
            }
            @if (contractRequest.terms?.points) {
                <div class="py-2 px-4"><span class="font-bold">Points:</span> {{ contractRequest.terms?.points }}</div>
            }
            <div class="py-2 px-4"><span class="font-bold">Payment Frequency:</span> {{ contractRequest.terms?.paymentFrequency | titlecase }}</div>
            <div class="py-2 px-4"><span class="font-bold">Business Owner Email(s):</span> {{ formatEmails(contractRequest.signers!) }}</div>
            <div class="py-2 px-4"><span class="font-bold">Best Number for Funding Call:</span> {{ formatPhone(contractRequest.fundingCallPhone!) }}</div>
            <div class="py-2 px-4"><span class="font-bold">Notes from Lendio:</span> {{ contractRequest.noteForLender }}</div>

            } @else {
                <div class="py-2 px-4"><span class="font-bold">Loan Amount:</span> {{ contractRequest.terms?.amount | currency }}</div>
                <div class="py-2 px-4"><span class="font-bold">Term:</span> {{ contractRequest.terms?.term }} {{ contractRequest.terms?.termType | titlecase }}</div>
                <div class="py-2 px-4"><span class="font-bold">Points:</span> {{ contractRequest.terms?.points }}</div>
                <div class="py-2 px-4"><span class="font-bold">Factor Rate:</span> {{ contractRequest.terms?.factorRate }}</div>
                <div class="py-2 px-4"><span class="font-bold">Payment Frequency:</span> {{ contractRequest.terms?.paymentFrequency | titlecase }}</div>
                <div class="py-2 px-4"><span class="font-bold">Business Owner Email(s):</span> {{ formatEmails(contractRequest.signers!) }}</div>
                <div class="py-2 px-4"><span class="font-bold">Best Number for Funding Call:</span> {{ formatPhone(contractRequest.fundingCallPhone!) }}</div>
                <div class="py-2 px-4"><span class="font-bold">Notes from Lendio:</span> {{ contractRequest.noteForLender }}</div>
            }
        </div>
    }
} @else {
  <mat-card class="mat-mdc-card-content m-4" appearance="outlined">
    <app-empty-state [type]="emptyType" [label]="emptyLabelContent">
    </app-empty-state>
  </mat-card>
}

