<div class="restricted-radio" [ngClass]="{
    'loading': loading,
    'old-theme-restricted-radio': oldThemeEnabled()
}">
  <mat-radio-group [(ngModel)]="value" aria-label="Select an option">
      <mat-radio-button
        color="primary"
        *ngFor="let option of options;"
        [disabled]="optionDisabled(option) || disabled || loading"
        (change)="valueChange.emit($event.value)"
        [value]="option.value">
        {{ option.label }}
      </mat-radio-button>
  </mat-radio-group>
</div>
