import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Store, Select } from '@ngxs/store';

import { NotificationsState } from 'src/app/store/notifications/notifications.state';
import { PutAllNotificationsRead, DeleteAllNotifications } from 'src/app/store/notifications/notifications.actions';
import { NotificationService } from 'src/app/services/notification.service';
import { Notification } from '../../../../interfaces/notification.model';


import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification-sidenav-smart',
  templateUrl: './sidenav-smart.component.html',
  styleUrls: ['./sidenav-smart.component.scss']
})
export class SidenavSmartComponent implements OnInit {
  // @Select(NotificationsState.notifications) notifications$: Observable<Notification[] | null>;
  notifications$: Observable<Notification[] | null> = this.store.select(NotificationsState.notifications);
  // @Select(NotificationsState.allRead) allRead$: Observable<boolean>;
  allRead$: Observable<boolean> = this.store.select(NotificationsState.allRead);
  // @Select(NotificationsState.deletingAll) deletingAll$: Observable<boolean>;
  deletingAll$: Observable<boolean> = this.store.select(NotificationsState.deletingAll);
  // @Select(NotificationsState.togglingAllRead) togglingAllRead$: Observable<boolean>;
  togglingAllRead$: Observable<boolean> = this.store.select(NotificationsState.togglingAllRead);

  @ViewChild('notificationsidenav') public sidenav: MatDrawer;

  constructor(
    private notificationService: NotificationService,
    private store: Store
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.notificationService.close();
  }

  bulkRead() {
    this.store.dispatch(new PutAllNotificationsRead());
  }

  bulkDelete() {
    this.store.dispatch(new DeleteAllNotifications());
  }
}
