import { State, Action, StateContext, Selector, createSelector, Store, NgxsOnInit } from '@ngxs/store';
import { InitSorts, UpdateSort } from './column-sort.actions';
import { Injectable } from '@angular/core';
import { ColumnSortStateModel } from '@app/app/interfaces/list-sort.model';
import { ColumnSortService } from '@app/app/services/column-sort.service';

@State<ColumnSortStateModel>({
    name: 'columnSorts',
    defaults: {
        sorts: []
    }
})
@Injectable()

export class ColumnSortState implements NgxsOnInit {

    constructor(
        private _columnSortService: ColumnSortService,
        private store: Store
    ) {}

    ngxsOnInit(ctx: StateContext<ColumnSortState>) {
        ctx.dispatch(new InitSorts(window.location.pathname));
    }

    @Selector()
    static sorts(state: ColumnSortStateModel) {
        return state.sorts;
    }

    @Selector()
    static getSortByPath(path: string) {
        return createSelector([ColumnSortState], (state: ColumnSortStateModel) => {
            return state['columnSorts'].sorts.find(s => s.path === path);
        });
    }

    @Action(InitSorts)
    initSorts({getState}: StateContext<ColumnSortStateModel>, { path }: InitSorts) {
        let curSortsByPath = getState().sorts.find(s => s.path === path);
        if(!curSortsByPath) {
            let storedSort = this._columnSortService.getColumnSortByPath(path);
            if(storedSort) {
                this.store.dispatch(new UpdateSort(storedSort));
            }
        }
    }

    @Action(UpdateSort)
    updateSortStore({ getState, patchState }: StateContext<ColumnSortStateModel>, { list }: UpdateSort) {
        const currentSorts = getState().sorts;
        const currentSortIndex = currentSorts.findIndex((x: { path: string; }) => x.path === list.path);
        const sorts = [
            ...currentSorts.slice(0, currentSortIndex),
            list,
            ...currentSorts.slice(currentSortIndex + 1),
        ];
        patchState({ sorts });

        this._columnSortService.setColumnSorts(sorts);
        return list;
    }
}
