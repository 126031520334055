export const StatusConfigMap = {
  active: {
    label: 'Active',
    color: '!bg-lendio-green-400'
  },
  error: {
    label: 'Error',
    color: '!bg-lendio-yellow-400',
  },
  notConnected: {
    label: 'Not Connected',
    color: '!bg-lendio-cool-gray-500',
  }
}
