import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { QualifierValue } from '@app/app/interfaces/qualifier.model';
import { AttributesMap } from '@app/app/services/decisioning.service';
import { AuthUser } from '@app/app/store/auth/auth-user';

@Component( {
  selector: 'app-qualifier-values-table',
  templateUrl: 'qualifier-values-table.component.html',
  styleUrls: ['./qualifier-values-table.component.scss']
} )
export class QualifierValuesTableComponent implements OnInit {

  @Input() attributesMap: AttributesMap;
  @Input() qualifierValues: QualifierValue[];
  @Input() overrideColumn: boolean = false;
  @Output() overrideQualifier = new EventEmitter<QualifierValue>();
  dataSource: MatTableDataSource<QualifierValue>;
  displayedColumns = [
    'qualifier',
    'requirement',
    'actual'
  ];

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.data = this.qualifierValues;
    if (this.overrideColumn === true) {
      this.displayedColumns.push('override');
    }
  }

  /**
   * @param qualifierValue
   * @return void
   */
  overrideQualifierEvent(qualifierValue: QualifierValue): void {
    this.overrideQualifier.emit(qualifierValue);
  }
}
