import { LenderApprovalBenefit } from 'src/app/interfaces/lender-approval-benefits.model';

export class GetLenderApprovalBenefits {
  static readonly type = '[LenderApprovalBenefit] GetLenderApprovalBenefits';
  constructor() {}
}

export class CreateLenderApprovalBenefit {
  static readonly type = '[LenderApprovalBenefit] CreateLenderApprovalBenefit';
  constructor(public title: string, public description: string, public suggestedTitle: string, public suggestedDescription: string) {}
}

export class UpdateLenderApprovalBenefit {
  static readonly type = '[LenderApprovalBenefit] UpdateLenderApprovalBenefit';
  constructor(public id: number, public title: string, public description: string, public suggestedTitle: string, public suggestedDescription: string) {}
}
