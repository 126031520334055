import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  ElementRef, computed
} from '@angular/core';
import { trigger, state, style, animate, transition, useAnimation } from '@angular/animations';
import * as moment from 'moment-timezone';
import { FundingDeskRequest } from 'src/app/interfaces/funding-desk-request.model';
import { fadeIn, fadeOut } from 'ng-animate';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-single-request-chain-old',
  animations: [
    trigger('slideUpDown', [
      state('in', style({height: '*'})),
      transition('* => void', [
        style({height: '*'}),
        animate(150, style({height: 0}))
      ]),
      transition('void => *', [
        style({height: 0}),
        animate(150, style({height: '*'}))
      ])
    ]),
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, {params: {timing: 0.05}})
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, {params: {timing: 0.3}})
      )
    ])
  ],
  templateUrl: 'single-request-chain-old.component.html',
  styleUrls: ['single-request-chain-old.component.scss']
})
export class SingleRequestChainOldComponent implements OnChanges, OnInit {

  @ViewChild('commentTextArea', {static: true})
  commentTextArea: ElementRef;

  @Input() public requestChain: FundingDeskRequest;
  @Input() public requestIndex: number;
  @Output() comment = new EventEmitter<{ description: string, requestId: number }>();

  addNewComment = false;
  moreComments = false;
  comments: any[];
  mostRecentComment: any;
  oldComments: any[];
  newComment = '';
  requestTypeList = {
    lender: 'Lender Request',
    fundingDesk: 'Funding Desk Request',
    fundingManager: 'Funding Manager Request'
  };
  addRequestTypeLabel = false;
  requestType = '';

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) {
  }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
    this.requestType = this.getRequestType();
    this.addRequestTypeLabel = this.isIndividualRequestType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.requestChain) {
      this.comments = changes.requestChain.currentValue.task_comments || [];
      this.mostRecentComment = this.comments.slice(this.comments.length - 1)[0];
      this.oldComments = this.comments.slice(0, this.comments.length - 1);
    }
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }

  createNewComment(description: string) {
    this.comment.emit({description, requestId: this.requestChain.id});
  }

  cancelComment() {
    this.newComment = '';
    this.addNewComment = false;
  }

  isIndividualRequestType() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.requestChain.type === 'QC Task' || this.requestTypeList.hasOwnProperty(this.requestChain.fromRole);
  }

  getRequestType() {
    if (this.requestChain.type === 'QC Task') {
      return 'QC Task';
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.requestTypeList[this.requestChain.fromRole];
  }
}
