<div mat-dialog-title>
    {{ data?.title }}
</div>
<mat-dialog-content>

    <form [formGroup]="formGroup" class="w-full grid">
        <mat-form-field>
            <mat-label>Exerian User ID</mat-label>
            <input matInput [formControlName]="'username'">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [formControlName]="'password'">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Client ID</mat-label>
            <input matInput [formControlName]="'client_id'">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Client Secret</mat-label>
            <input matInput [formControlName]="'client_secret'">
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button mat-button mat-dialog-close="true">Close</button>
    <button mat-flat-button
            [disabled]="!formGroup.valid"
            [color]="'primary'"
            (click)="handleSave()">Save and reconnect</button>
</mat-dialog-actions>
