import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { ApplicationsResponse } from '../interfaces/applications-response.model';
import { ApplicationsSearchResponse } from '../interfaces/applications-search-response.model';
import { map } from 'rxjs/operators';
import { LendioResponse } from '../interfaces/lendio-response';
import { Deal } from '../interfaces/deal.model';

const baseUrl = `${environment.apiUrl}/lender-portal`;

@Injectable({
    providedIn: 'root'
})
export class ApplicationsListService {

    constructor(private http: HttpClient) {}

    // eslint-disable-next-line max-len
    getApplications(section: string = 'new-apps', sortBy: string = 'modified', sortDirection: string = 'desc', page: number = 0, limit: number = 25): Observable<ApplicationsResponse> {
        const sort = sortBy + sortDirection;
        const assignmentsStorage = localStorage.getItem('assignmentFilters');
        const labelStorage = localStorage.getItem('labelFilters');
        const statusStorage = localStorage.getItem('statusFilters');
        const loanProductsStorage = localStorage.getItem('loanProductFilters');
        let assignmentsArray = [];
        let labelsArray = [];
        let statusArray = [];
        let loanProductsArray = [];

        if (assignmentsStorage) {
            assignmentsArray = JSON.parse(assignmentsStorage);
        }

        if (labelStorage) {
            labelsArray = JSON.parse(labelStorage);
        }

        if (statusStorage) {
            statusArray = JSON.parse(statusStorage);
        }

        if (loanProductsStorage) {
            loanProductsArray = JSON.parse(loanProductsStorage);
        }

        const assignments = JSON.stringify(assignmentsArray);
        const labels = JSON.stringify(labelsArray);
        const status = JSON.stringify(statusArray);
        const loanProducts = JSON.stringify(loanProductsArray);

        let url = `${baseUrl}/applications/${section}?sort=${sort}&page=${page}&limit=${limit}&assignedUsers=${assignments}&labels=${labels}&pppStatus=${status}&loanProducts=${loanProducts}&dealsType=new`;
        if (section === 'dead' || section === 'needs-review') {
            url += `&hasAan`
        }

        return this.http.get<ApplicationsResponse>(url);
    }

    // Get total counts for all tabs.
    getTabData() {
        return this.http.get<any>(`${baseUrl}/tab-data?dealsType=new`);
    }

    searchApplications(term: string | number | boolean): Observable<any> {
        const url = `${environment.apiUrl}/l/v2/internal/borrowers/search-old`;
        return this.http.get<any>(url, {
          params: {
            search: term,
            query: encodeURIComponent(JSON.stringify({
              criteria: {},
              sort: { 'borrowers.viewed': 'DESC' },
              limit: 10,
              offset: 0
            }))
          }
        });
    }

    bulkSbaSubmit(dealIds: number[]) {
        const url = `${environment.apiUrl}/deal/sba/submission`;
        return this.http.post(url, { dealIds });
    }
}
