import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { LoadersModule } from '../loaders/loaders.module';

@Component( {
  standalone: true,
  imports: [
    MatCardModule,
    MatProgressSpinnerModule,
    CommonModule,
    LoadersModule
  ],
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: [ './empty-state.component.scss' ]
} )
export class EmptyStateComponent implements OnInit {

  //the type should match the name of the SVG to display after 'empty-' (look in the assets/images/empty-state-svgs folder)
  @Input() type: string = 'documents'; 
  @Input() label: string = 'Nothing found yet!';
  @Input() loading: boolean = false;

  emptyStateImagePath: string;

  constructor() {
  }

  ngOnInit(): void {
    this.updateImagePath(this.type);
  }

  updateImagePath(type: string): void {
    let path = '';
    switch(type){
      case '403':
      case 'approvals':
      case 'businesses':
      case 'dashboard':
      case 'deals':
      case 'decisioning':
      case 'documents':
      case 'events':
      case 'history':
      case 'integrations':
      case 'loan-products':
      case 'offers-contracts':
      case 'team':
      case 'webhooks':
        path = `/assets/images/empty-state-svgs/empty-${type}.svg`;
        break;
      default:
        path = `/assets/images/empty-state-svgs/empty-documents.svg`;
    }
    this.emptyStateImagePath = path;
  }
}
