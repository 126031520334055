import { Injectable } from "@angular/core";
import { addDays, format } from "date-fns";
import categories from './categories.json';
import mcc_codes from './mcc_codes.json';
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { DEMO_BORROWERS, DEMO_CONTACTS, BATCH_ROWS } from "@app/app/components/makecsv/make-csv-constants";
import { v4 as uuidV4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class MakeCsvService {
  contactIndex = 0;
  businessIndex = 0;
  contacts: any[] = [];
  companyPrefixes = ['Acme', 'Global', 'United', 'Apex', 'Innovative'];
  companyDescriptors = ['Solutions', 'Technologies', 'Systems', 'Consulting', 'Industries'];
  companySuffixes = ['Inc.', 'Corp.', 'LLC', 'Co.', 'Group'];
  categoryList = categories.categories;
  codeList = mcc_codes.codes;


  private _generateCompanyName() {
    const prefix = this._generateRandomArrayElement(this.companyPrefixes);
    const descriptor = this._generateRandomArrayElement(this.companyDescriptors);
    const suffix = this._generateRandomArrayElement(this.companySuffixes);
    return `${prefix} ${descriptor} ${suffix}`;
  }

  private _weightedRandomSelection(itemsWithWeights) {
    // Calculate total weight
    let totalWeight = 0;
    for (const item of itemsWithWeights) {
      totalWeight += item.weight;
    }

    // Generate a random number between 0 and total weight
    const randomNumber = Math.random() * totalWeight;

    // Find the item whose weight range contains the random number
    let cumulativeWeight = 0;
    for (const item of itemsWithWeights) {
      cumulativeWeight += item.weight;
      if (randomNumber <= cumulativeWeight) {
        return item.value;
      }
    }
  }

  private _generateRandomInt(min: number, max: number): number {
    const randomNumber = Math.random();
    const range = max - min;
    const adjustedAmount = randomNumber * range + min;
    return Math.floor(adjustedAmount);
  }

  private _generateRandomFixedAmount(min: number, max: number): string {
    const randomNumber = Math.random();
    const range = max - min;
    const adjustedAmount = randomNumber * range + min;
    return adjustedAmount.toFixed(2);
  }

  private _generateRandomPastDate({ minYearsAgo = 1, maxYearsAgo = 30 }) {
    const today = new Date();

    // Generate a random number of years to go back in time
    const yearsAgo = this._generateRandomInt(minYearsAgo, maxYearsAgo);

    // Set the year to be in the past
    const randomPastYear = today.getFullYear() - yearsAgo;
    const randomMonth = this._generateRandomInt(0, 11);
    const randomDay = this._generateRandomInt(1, 28);

    return new Date(randomPastYear, randomMonth, randomDay);
  }

  private _generateRandomDateBetween(from, to) {
    // Ensure the dates are in proper Date object format
    const fromDate = (typeof from === 'string') ? new Date(from) : from;
    const toDate = (typeof to === 'string') ? new Date(to) : to;

    // Get the difference in milliseconds between the dates
    const differenceInMs = toDate.getTime() - fromDate.getTime();

    // Generate a random offset within that range
    const randomOffsetMs = Math.random() * differenceInMs;

    // Add the random offset to the starting date to get the random date
    return new Date(fromDate.getTime() + randomOffsetMs);
  }

  private _generateRandomArrayElement(array) {
    if (array.length === 0) {
      return;
    }
    const randomIndex = this._generateRandomInt(0, (array.length - 1));
    return array[randomIndex];
  }

  private _generateBooleanWithProbability(probability = 0.5) {
    return Math.random() < probability;
  }

  private _generateNumericString({length}) {
    let result = '';

    for (let i = 0; i < length; i += 1) {
      result += this._generateRandomInt(0, 9);
    }

    return result;
  }

  private _subtractDollarAmounts(var1: string, var2: string): string {
    const currency1 = parseFloat(var1.slice(1));
    const currency2 = parseFloat(var2.slice(1));
    const currencySum = currency1 - currency2;
    return currencySum.toFixed(2);
  }

  makeFileName({prefix, dataSetId, name, total}) {
    return `${prefix ? prefix + "_" : ""}${dataSetId || this.timeStr()}_${name}_${total}.csv`;
  }

  timeStr() {
    let now = new Date();
    return format(now, "yyyyMMddHHmmss");
  }

  transactionDescription(amount) {
    if (this._generateBooleanWithProbability(0.3) && Math.abs(amount) > 100) {
      const type = amount > 0 ? 'deposit' : 'withdrawal';
      let location;
      if (type == 'deposit' && this._generateBooleanWithProbability(0.3)) {
        location = `check ${this._generateNumericString({length: 3})}`;
      } else {
        location = this._generateRandomArrayElement(['atm', 'online']);
      }
      return `${location} ${type}`.toUpperCase();
    }
    const companyName = this._generateCompanyName();
    const company = `${amount > 0 ? this._generateRandomArrayElement(['invoice to', 'payment from']) : this._generateRandomArrayElement(['invoice from', 'payment to'])} ${companyName}`;
    const isCardTxn = this._generateBooleanWithProbability(0.75);
    let card = "";
    if (isCardTxn) {
      card = ` card ending ${this._generateNumericString({length: 4})}`;
    }

    return `${company}${card}`.toUpperCase();
  }

  generateBusiness() {
    const business = Object.assign(
      DEMO_BORROWERS[this.businessIndex],
      {
        first: 'JOHN',
        last: 'BIDIGARE',
        email: `adam.michaelson+lendiotest${this._generateNumericString({length: 6})}@lendio.com`,
        businessId: uuidV4()
      }
    );
    this.businessIndex += 1;
    if (this.businessIndex > DEMO_BORROWERS.length - 1) {
      this.businessIndex = 0;
    }
    return business;
  }

  generateContact({parent, prev}) {
    const contact = Object.assign(
      DEMO_CONTACTS[this.contactIndex],
      {
        businessId: parent.businessId,
        contactId: uuidV4(),
        email: !prev ? parent.email : `lendiotest+${this._generateNumericString({length: 12})}@lendio.com`,
        isBeneficialOwner: this._generateBooleanWithProbability(0.5),
        hasOutstandingBusinessLoan: this._generateBooleanWithProbability(0.2),
      });
    this.contactIndex += 1;
    if (this.contactIndex > DEMO_CONTACTS.length - 1) {
      this.contactIndex = 0;
    }
    return contact;
  }

  generateBankAccount({ parent }) {
    return {
      accountId: this._generateRandomInt(100000000, 999999999),
      businessId: parent.businessId,
      purpose: this._weightedRandomSelection([{value: 'business', weight: 10}, {value: 'personal', weight: 1}]),
      type: this._weightedRandomSelection([{value: 'savings', weight: 1}, {value: 'checking', weight: 10}]),
      balance: this._generateRandomFixedAmount(-10000, 200000),
      balanceDate: addDays(new Date(), -2),
      accountNumber: this._generateRandomInt(1000000000, 9999999999),
      openedDate: this._generateRandomPastDate({minYearsAgo: 1, maxYearsAgo: 30}),
      closedDate: this._weightedRandomSelection([{value: null, weight: 100}, {value: this._generateRandomPastDate({minYearsAgo: 1, maxYearsAgo: 10}), weight: 1}]),
    }
  }

  generateTransaction({parent, prev}) {
    let account = parent;
    let minDate, maxDate;
    if (!prev) {
      maxDate = minDate = addDays(new Date(), -3);
    } else {
      maxDate = prev.created;
      minDate = addDays(prev.created, -1);
    }
    let amount = this._generateRandomFixedAmount(-2000, 2000);
    let created = this._generateRandomDateBetween(minDate, maxDate);
    let posted = addDays(created, this._generateRandomInt(0, 1));
    let balanceAfterTransaction = (!prev ? account.balance : this._subtractDollarAmounts(prev.balanceAfterTransaction, prev.amount))
    return {
      transactionId: uuidV4(),
      accountId: account.accountId,
      created,
      posted,
      description: this.transactionDescription(amount),
      amount,
      balanceAfterTransaction,
      category: this._generateRandomArrayElement(this.categoryList),
      mcc: this._generateRandomArrayElement(this.codeList).mcc
    }
  }

  async generateCsv({prefix, count, rowGenerator, name, dataSetId, parentRows, perParentMin, perParentMax, returnAll = false}) {
    rowGenerator = rowGenerator.bind(this);
    if (perParentMin > perParentMax) {
      perParentMax = perParentMin;
    }
    const fileName = this.makeFileName({prefix, dataSetId, name, total: count});
    let allRows: any[] = [];
    let totalRows = 0;
    if (!parentRows) {
      [].push.apply(allRows, await this.batchWriteCsv({
        fileName,
        name,
        totalRows: count,
        rowGenerator,
      }));
      totalRows = allRows.length;
    } else {
      for (let [i, parent] of parentRows.entries()) {
        let max = this._generateRandomInt(perParentMin, perParentMax);
        let rows: any[] | undefined = await this.batchWriteCsv({
          fileName,
          name,
          totalRows: max,
          rowGenerator,
          parent,
          writeHeader: i === 0
        });
        if (rows) {
          totalRows += rows.length;
        }

        if (returnAll) {
          [].push.apply(allRows, rows);
          rows = [];

        } else if (rows?.length) {
          allRows = [...rows];
        }
      }
    }
    const csvString = Papa.unparse(allRows);
    const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, fileName);

    return allRows;
  }

  async batchWriteCsv({totalRows, fileName, name, rowGenerator, writeHeader = true, parent = null}) {
    rowGenerator = rowGenerator.bind(this);
    let allRows: any[] = [];
    let previousRow = null;
    let csvString;
    const batchCount = Math.ceil(totalRows / BATCH_ROWS);
    let remaining = totalRows;
    for (let batchI = 0; batchI < batchCount; batchI += 1) {
      let rows: any[] = [];
      let rowsInBatch = Math.min(remaining, BATCH_ROWS);
      for (let i = 0; i < rowsInBatch; i += 1) {
        const row = rowGenerator({parent, prev: previousRow});
        previousRow = row;
        rows.push(row);
        allRows.push(row);
        remaining -= 1;

        // Write headers after first row is generated
        if (writeHeader && batchI === 0 && i === 0) {
          csvString = Papa.unparse(rows);
          rows = [];
        }
      }

      // Don't write empty lines
      if (rows.length) {
        csvString = Papa.unparse(rows, {header: false});
      }
    }
    if (name === 'contacts' && this.contactIndex < DEMO_CONTACTS.length) {
      this.contacts.push(allRows[0])
    }
    if (!(name === 'contacts')) {
      return allRows;
    } else {
      if (this.contactIndex === DEMO_CONTACTS.length - 1) {
        return this.contacts;
      }
    }

  }

  async generate(opts) {
    this.contacts = [];
    const {
      prefix,
      businessCount = 25,
      contactsPerBusinessMin = 1,
      contactsPerBusinessMax = 1,
      accountsPerBusinessMin = 1,
      accountsPerBusinessMax = 1,
      transactionsPerAccountMin = 5,
      transactionsPerAccountMax = 5,
      generateBusiness = this.generateBusiness,
      generateContact = this.generateContact,
      generateBankAccount = this.generateBankAccount,
      generateTransaction = this.generateTransaction,
    } = opts;

    let dataSetId = this.timeStr();
    let businesses = await this.generateCsv({
      prefix: 'demo',
      count: businessCount,
      rowGenerator: generateBusiness,
      name: 'Business',
      dataSetId: null,
      parentRows: DEMO_BORROWERS,
      perParentMin: 25,
      perParentMax: 25
    });
    await this.generateCsv({
      prefix,
      count: null,
      rowGenerator: generateContact,
      name: 'contacts',
      parentRows: businesses,
      dataSetId,
      perParentMin: contactsPerBusinessMin,
      perParentMax: contactsPerBusinessMax,
    });
    let accounts = await this.generateCsv({
      prefix,
      count: null,
      rowGenerator: generateBankAccount,
      name: 'bankAccounts',
      parentRows: businesses,
      dataSetId,
      perParentMin: accountsPerBusinessMin,
      perParentMax: accountsPerBusinessMax,
      returnAll: true,
    });
    await this.generateCsv({
      prefix,
      count: null,
      rowGenerator: generateTransaction,
      name: 'transactions',
      parentRows: accounts,
      dataSetId,
      perParentMin: transactionsPerAccountMin,
      perParentMax: transactionsPerAccountMax,
    });
  }
}
