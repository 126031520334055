import { Import } from "@app/app/interfaces/import.model";
import { ImportsStateModel } from "./import.state";

export class FetchWithUpdatedParams {
  static readonly type = '[Imports] FetchWithUpdatedParams';
  constructor(
    public paramChanges: Partial<ImportsStateModel>,
    public shouldReloadOnFetch: boolean,
    ) {}
}

export class SubscribeToImportStateUpdates {
  static readonly type = '[Imports] SubscribeToImportStateUpdates';
}

export class UnsubscribeFromImportStateUpdates {
  static readonly type = '[Imports] UnsubscribeFromImportStateUpdates';
}

export class UpdateLatestImport {
  static readonly type = '[Imports] UpdateLatestImport';
  constructor (
    public incomingImport: Import
  ) {}
}
