import { NgModule } from '@angular/core';
import { TimeAgoPipe } from '@app/app/pipes/time-ago/time-ago.pipe';

@NgModule({
    imports: [],
    exports: [TimeAgoPipe],
    declarations: [TimeAgoPipe],
    providers: [],
})
export class TimeAgoModule { }
