import { Component, OnInit, Input, computed } from '@angular/core';
import { Contact } from 'src/app/interfaces/contact.model';
import { CreateOwnerDialogComponent } from '../create-owner-dialog/create-owner-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EditOwnerDialogComponent } from '../edit-owner-dialog/edit-owner-dialog.component';
import { DeleteOwnerDialogComponent } from '../delete-owner-dialog/delete-owner-dialog.component';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss']
})
export class OwnersComponent implements OnInit {

  @Input() contacts: Contact[];
  @Input() dealId: number;

  displayedColumns: string[] = ['first', 'last', '% owned', 'email', 'zip', 'contract signer', 'edit'];

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(public dialog: MatDialog, private _themeService: LendioAngularMaterialThemeService) { }

  ngOnInit(): void { }

  openAddContactDialog() {
    this.dialog.open(CreateOwnerDialogComponent, {
      width: '80%',
      data: { dealId: this.dealId }
    });
  }

  openEditContactDialog(contact: Contact) {
    this.dialog.open(EditOwnerDialogComponent, {
      width: '80%',
      data: {
        dealId: this.dealId,
        contact
      }
    });
  }

  openDeleteContactDialog(contact: Contact) {
    this.dialog.open(DeleteOwnerDialogComponent, {
      width: '500px',
      data: { dealId: this.dealId, contact }
    });
  }
}
