import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Contact } from 'src/app/interfaces/contact.model';
import { ContactsActions as CA } from '@app/app/store/contacts/contacts.actions';

@Component({
  selector: 'app-delete-owner-dialog',
  templateUrl: './delete-owner-dialog.component.html',
  styleUrls: ['../../../scss/_dialog.scss']
})
export class DeleteOwnerDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number, contact: Contact },
    public dialogRef: MatDialogRef<DeleteOwnerDialogComponent>,
    private store: Store
  ) { }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  deleteOwner() {
    if (this.data.contact.id) {
      this.store.dispatch(new CA.DeleteByDeal(this.data.dealId, this.data.contact.id));
    }
    this.close();
  }
}
