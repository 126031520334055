import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LabelsResponse } from '../interfaces/labels-response.model';
import { Label } from '../interfaces/label.model';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {
  private url = environment.apiUrl + '/l/v1/internal/labels/';

  public labels: [Label];

  constructor(private httpClient: HttpClient) {}

  getLabels(): Observable<LabelsResponse> {
    return this.httpClient.get<LabelsResponse>(this.url);
  }

  deleteLabel(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}${id}`);
  }

  createLabel(label: Label): Observable<any> {
    return this.httpClient.post<any>(this.url, {name: label.name, color: label.color});
  }

  editLabel(label: Label): Observable<any> {
    return this.httpClient.put<any>(`${this.url}${label.id}`, {name: label.name, color: label.color});
  }

  detachLabel(dealId: number, labelId: number): Observable<any> {
    const url = environment.apiUrl + `/l/v2/internal/deals/${dealId}/labels/remove`;

    return this.httpClient.put(url, {lenderLabelIds: [labelId]});
  }

  detachMultipleLabels(dealId: number, labelIds: number[]): Observable<any> {
    const url = environment.apiUrl + `/l/v2/internal/deals/${dealId}/labels/remove`;

    return this.httpClient.put(url, {lenderLabelIds: labelIds});
  }

  attachLabel(dealId: number, label: Label): Observable<any> {
    const url = environment.apiUrl + `/l/v2/internal/deals/${dealId}/labels/add`;

    return this.httpClient.put(url, {lenderLabelIds: [label.id]});
  }

  attachMultipleLabels(dealId: number, labels: Label[]): Observable<any> {
    const url = environment.apiUrl + `/l/v2/internal/deals/${dealId}/labels/add`;
    const labelIds: number[] = [];

    labels.forEach((label) => {
      // TODO what if its doesn't exits? Would that cause a problem?
      if (label.id) {
        labelIds.push(label.id);
      }
    });

    return this.httpClient.put(url, {lenderLabelIds: labelIds});
  }
}
