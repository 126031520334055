import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MfaCodeInputComponent } from './mfa-code-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        MfaCodeInputComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        MfaCodeInputComponent
    ]
})
export class MfaCodeInputModule { }
