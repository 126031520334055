import { Component, OnInit, Inject, computed } from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {take} from 'rxjs/operators';
import {CreateNewAlert} from 'src/app/store/global-alerts/global-alerts.actions';
import {Store} from '@ngxs/store';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-turn-down-dialog',
  templateUrl: './turn-down-dialog.component.html',
  styleUrls: ['./turn-down-dialog.component.scss']
})
export class TurnDownDialogComponent implements OnInit {

  declineReasons: any;
  declineReasonsMap = new Map();
  declineReasonSelect = new FormControl();
  subReasonSelect = new FormControl();
  selectedReason: any;
  comment = '';
  loading = false;
  selectedSubReasons: any[] = [];
  miscSubReasons: any = [
    {subReason: 'Fraudulent IRS Documents'},
    {subReason: 'Fraudulent Bank Statements'},
    {subReason: 'Fraudulent ID'},
    {subReason: 'Other watchlists'},
    {subReason: 'On the OFAC watchlist'},
    {subReason: 'Deceased'},
    {subReason: 'UBO\'s are not tied to the business'},
    {subReason: 'Location to business type does not match'},
    {subReason: 'Required Documents Not Provided'},
    {subReason: 'Barred from doing business with the govt'},
    {subReason: 'Defaulted on a govt loan'},
    {subReason: 'Business has an open BK'},
    {subReason: 'Business started after 2/15/2020'},
    {subReason: 'Not a qualifying industry'},
    {subReason: 'Currently in a lawsuit'},
    {subReason: 'Criminal'},
    {subReason: 'Over 500 employees'},
    {subReason: 'Barred from doing business with the govt'},
    {subReason: 'UBOs and Applicant has open BK'},
    {subReason: 'In the last 5 years (from application date) has been convicted of'},
    {subReason: 'Fraud}, Embezzlement}, Bribery'},
    {subReason: 'In the last 12 months (from application date) has been convicted of any felony'},
    {subReason: 'Currently facing any felony charge'},
    {subReason: 'Currently incarcerated/house arrest'},
    {subReason: 'Currently facing incarceration'},
    {subReason: 'Currently on probation for any felony'},
    {subReason: 'Currently on parole for any felony'},
    {subReason: 'Defaulted on a govt loan'},
    {subReason: 'Start Date > 02/15/2020'},
    {subReason: 'SOS inactive or bad standing'},
    {subReason: 'OFAC Listing'},
    {subReason: 'Lender Declined'}
  ];

  subReasons: any = {
    'PPP Suspected Fraud': [
      {subReason: 'Fraudulent IRS Documents'},
      {subReason: 'Fraudulent Bank Statements'},
      {subReason: 'Fraudulent ID'},
      {subReason: 'Other watchlists'},
      {subReason: 'On the OFAC watchlist'},
      {subReason: 'Deceased'},
      {subReason: 'UBO\'s are not tied to the business'},
      {subReason: 'Location to business type does not match'},
      {subReason: 'Lender Declined'}
    ],
    'PPP Does Not Qualify': [
      {subReason: 'Required Documents Not Provided'},
      {subReason: 'Barred from doing business with the govt'},
      {subReason: 'Defaulted on a govt loan'},
      {subReason: 'Business has an open BK'},
      {subReason: 'Business started after 2/15/2020'},
      {subReason: 'Not a qualifying industry'},
      {subReason: 'Currently in a lawsuit'},
      {subReason: 'Criminal'},
      {subReason: 'Over 500 employees'},
      {subReason: 'Barred from doing business with the govt'},
      {subReason: 'UBOs and Applicant has open BK'},
      {subReason: 'In the last 5 years (from application date) has been convicted of'},
      {subReason: 'Fraud}, Embezzlement}, Bribery'},
      {subReason: 'In the last 12 months (from application date) has been convicted of any felony'},
      {subReason: 'Currently facing any felony charge'},
      {subReason: 'Currently incarcerated/house arrest'},
      {subReason: 'Currently facing incarceration'},
      {subReason: 'Currently on probation for any felony'},
      {subReason: 'Currently on parole for any felony'},
      {subReason: 'Defaulted on a govt loan'},
      {subReason: 'Start Date > 02/15/2020'},
      {subReason: 'SOS inactive or bad standing'},
      {subReason: 'OFAC Listing'},
      {subReason: 'Lender Declined'}
    ],
    'PPP SBA Not Approved': [
      {subReason: 'Criminal Record'},
      {subReason: 'Bankruptcy'},
      {subReason: 'OFAC'},
      {subReason: 'Potential Decedent Application'},
      {subReason: 'Inactive Business'},
      {subReason: 'Mismatch of TIN (EIN/SSN)'},
      {subReason: 'Mismatch of Entity Name (Individual or Company)'},
      {subReason: 'In Operation After Feb 15},2020'},
      {subReason: 'Large Number of Employees at Residential Business Address'},
      {subReason: 'Do Not Pay - Death Sources'},
      {subReason: 'Do Not Pay - SAM'},
      {subReason: 'Do Not Pay - TOP and CAIVRS'},
      {subReason: 'Do Not Pay - TOP Education'},
      {subReason: 'Disqualifying Criminal History Identified'},
      {subReason: 'Disqualifying Bankruptcy Identified'},
      {subReason: 'Potential Match to Sanctions List Identified'},
      {subReason: 'Applicant Potentially Deceased'},
      {subReason: 'Dormant Business Identified'},
      {subReason: 'Applicant Tax ID Discrepancy'},
      {subReason: 'Applicant Name Discrepancy'},
      {subReason: 'Disqualifying Business Formation Date Identified'},
      {subReason: 'Large Number of Employees at Residential Location'},
      {subReason: 'Treasury Do Not Pay Analysis'},
      {subReason: 'Lender Declined'}
    ],
    'PPP PLP Number Cancelled/Withdrawn': this.miscSubReasons,
    'PPP New Lender Submission Requested': this.miscSubReasons,
    'PPP AAN Escalation': this.miscSubReasons
  };

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(
    private http: HttpClient,
    private store: Store,
    private _themeService: LendioAngularMaterialThemeService,
    public dialogRef: MatDialogRef<TurnDownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number }
  ) {
  }

  ngOnInit(): void {
    this.getDeclineReasons();
  }

  onDeclineReasonsChange(): void {
    // Reset sub-reason and comment
    this.comment = '';
    this.selectedSubReasons = [];
  }

  getDeclineReasons() {
    this.http.get(`${environment.apiUrl}/l/v2/internal/ppp-decline-reasons`).pipe(take(1)).subscribe((reasons: any) => {
      this.declineReasons = reasons.data;
      this.declineReasons.forEach((reasonData: { reason: any; }) => {
        this.declineReasonsMap.set(reasonData.reason, reasonData);
      });
    });
  }

  submit() {

    this.loading = true;
    const payload = {
      status: this.declineReasonsMap.get(this.selectedReason).status,
      reasons: [{
        reason: this.selectedReason,
        lenderReason: 'No lender-specific reason'
      }],
      subReasons: this.selectedSubReasons,
      otherReason: this.comment
    };

    // eslint-disable-next-line max-len
    this.http.put(`${environment.apiUrl}/l/v2/internal/deals/${this.data.dealId}/status`, payload).pipe(take(1)).subscribe((response: any) => {
      if (response.statusCode === 200) {
        window.location.reload();
      } else {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Failed to turn down this application. Please try again.'
        }));
      }
      this.loading = false;
      this.dialogRef.close();
    });
  }
}
