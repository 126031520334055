import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GetNotificationCount, SafeGetNotifications } from '../store/notifications/notifications.actions';
import { JwtResponse } from '../auth/jwt-response';

// import { RightSidenavComponent } from '../right-sidenav/right-sidenav.component';

@Injectable()
export class NotificationService {
    private sidenav: MatDrawer;
    private countInterval: NodeJS.Timeout | null= null;
    private notificationsInterval: NodeJS.Timeout | null = null;
    private countPollSeconds = 60;
    private notificationsPollSeconds = 60;

    constructor(
        private http: HttpClient,
        private store: Store
    ) {
    }

    public setSidenav(sidenav: MatDrawer) {
        this.sidenav = sidenav;
    }

    public open() {
        localStorage.setItem('notificationSidenavExpanded', 'true');
        this.stopPollingCount();
        this.startPollingNotifications();
        this.announceResize();
        return this.sidenav.open();
    }

    public close() {
        localStorage.setItem('notificationSidenavExpanded', 'false');
        this.startPollingCount();
        this.stopPollingNotifications();
        this.announceResize();
        return this.sidenav.close();
    }

    public toggle(): void {
        if (this.notificationSidenavClosed()) {
            this.open();
            return;
        }
        this.close();
    }

    /* In order for NGXCharts to detect the page changing in size we have to announce it */
    public announceResize() {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
    }

    public notificationSidenavClosed(){
        return localStorage.getItem('notificationSidenavExpanded') === 'false' || !localStorage.getItem('notificationSidenavExpanded');
    }


    public getNotificationCount(): Observable<JwtResponse> {
        return this.http.get<JwtResponse>(`${environment.apiUrl}/lender-portal/notifications?count=1`);
    }

    public getNotifications(): Observable<JwtResponse> {
        return this.http.get<JwtResponse>(`${environment.apiUrl}/lender-portal/notifications`);
    }

    public putNotificationsRead(activityIds: number[]): Observable<JwtResponse> {
        return this.http.put<JwtResponse>(`${environment.apiUrl}/lender-portal/notifications/read`, { activityIds });
    }

    public putNotificationsUnread(activityIds: number[]): Observable<JwtResponse> {
        return this.http.put<JwtResponse>(`${environment.apiUrl}/lender-portal/notifications/unread`, { activityIds });
    }

    public deleteNotifications(activityIds: number[]): Observable<JwtResponse> {
        const body = { activityIds };
        const options = { body };

        return this.http.request<JwtResponse>('delete', `${environment.apiUrl}/lender-portal/notifications`, options);
    }

    public stopPollingNotifications() {
        if (this.notificationsInterval === null) {
            return false;
        }

        clearInterval(this.notificationsInterval);
        this.notificationsInterval = null;
    }

    public startPollingNotifications() {
        if (this.notificationsInterval !== null) {
            return false;
        }

        this.store.dispatch(new SafeGetNotifications());

        this.notificationsInterval = setInterval(() => {
            this.store.dispatch(new SafeGetNotifications());
        }, this.notificationsPollSeconds * 1000);
    }

    public stopPollingCount() {
        if (this.countInterval === null) {
            return false;
        }

        clearInterval(this.countInterval);
        this.countInterval = null;
    }

    public startPollingCount() {
        if (this.countInterval !== null) {
            return false;
        }

        this.store.dispatch(new GetNotificationCount());

        this.countInterval = setInterval(() => {
            this.store.dispatch(new GetNotificationCount());
        }, this.countPollSeconds * 1000);
    }
}
