/**
 * Namespace the actions so we can use succinct action names.
 */

import { Document } from "@app/app/interfaces/document.model";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DocumentsActions {

  // Get a single document by ID.
  export class GetDocument {
    static readonly type = '[Documents] GetDocument';
    constructor(
      public documentId: number,
    ) { }
  }

  // Get documents by borrower.
  export class GetBorrowerDocuments{
    static readonly type = '[Documents] GetBorrowerDocuments';
    constructor(
      public borrowerId: number,
      public borrowerLenderId: number,
    ) { }
  }
  export class Post {
    static readonly type = '[Documents] Post';
    constructor(public file: File, public formData: FormData) { }
  }
  export class Put {
    static readonly type = '[Documents] Put';
    constructor(public document: Document) { }
  }
  export class Delete {
    static readonly type = '[Documents] Delete';
    constructor(public id: number) { }
  }

  // By Deal.
  export class GetDealDocuments {
    static readonly type = '[Documents] GetDealDocuments';
    constructor(
      public dealId: number,
      public borrowerLenderId: number,
    ) { }
  }

  // Document categories for external lenders.
  export class GetDocumentCategories {
    static readonly type = '[Documents] GetDocumentCategories';
    constructor() { }
  }

  export class ClearDocumentsState {
    static readonly type = '[Documents] ClearDocuments';
    constructor() { }
  }

  export class SubscribeToDocumentStateUpdates {
    static readonly type = '[Documents] SubscribeToDocumentStateUpdates';
    constructor(
      public borrowerId: number
    ) { }
  }

  export class UnsubscribeFromDocumentStateUpdates {
    static readonly type = '[Documents] UnsubscribeFromDocumentStateUpdates';
    constructor(
      public borrowerId: number
    ) { }
  }

  export class PatchDocumentState {
    static readonly type = '[Documents] PatchDocumentState';
    constructor(public document: Document) { }
  }
}
