import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NewOfferDialogComponent } from '../../new-offer-dialog/new-offer-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';

@Component({
  selector: 'app-offer-link-form',
  templateUrl: './offer-link-form.component.html',
  styleUrls: ['../offer-form-styles.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, { params: { timing: 0.05 } })
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, { params: { timing: 0.3 } })
      )
    ])
  ]
})
export class OfferLinkFormComponent implements OnInit, OnChanges {

  @Output() newOfferLink = new EventEmitter<{ formData: any }>();
  @Input() loading: boolean;
  @Input() localeCurrencySymbol: string;
  @Input() useNewTheme: boolean;

  submitting = false;
  offer = {
    offerLink: '',
    received: new Date()
  };
  offerForm: FormGroup;
  urlRegEx = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  today: Date = new Date();

  constructor(public dialogRef: MatDialogRef<NewOfferDialogComponent>) { }

  ngOnInit(): void {
    this.offerForm = new FormGroup ({
      offerLink: new FormControl(this.offer.offerLink, [
        Validators.required,
        Validators.pattern(this.urlRegEx)
      ]),
      received: new FormControl(this.offer.received, [
        Validators.required
      ])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === false
      && changes.loading.currentValue === true
    ) {
      this.submitting = true;
    }
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === true
      && changes.loading.currentValue === false
    ) {
      setTimeout(() => this.close(), 0);
    }
  }

  get dateReceivedControl(): AbstractControl {
    return this.offerForm.get('received')!;
  }

  get offerLinkControl(): AbstractControl {
    return this.offerForm.get('offerLink')!;
  }



  close() {
    this.dialogRef.close();
  }

  submit() {
    this.newOfferLink.emit({ formData: this.offerForm.value });
  }

  checkError(controlName: string, errorName: string) {
    return this.offerForm.controls[controlName].hasError(errorName);
  }
}
