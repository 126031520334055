import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return '';
    }
    input = input.trim();

    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }
}
