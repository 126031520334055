<div class="offer-container" #approvalComponent>

    <div>
        <div *ngFor="let benefit of offer?.benefits; let i=index" class="inline-block">
          <div *ngIf="i<3"
            class="
              border
              border-green-600
              bg-green-600
              text-white
              rounded-t-md
              font-thin
              px-2
              py-1
              mr-0.5">
              {{ benefit?.title }}
            <mat-icon
              matTooltip="{{ benefit?.description }}"
              matTooltipPosition="above"
              class="mat-info-icon align-middle">info_outline
            </mat-icon>
        </div>
        </div>
    </div>

    <mat-expansion-panel
        class="mat-elevation-z"
        [ngClass]="offer?.benefits.length > 0 ? 'has-benefits' : ''"
        [expanded]="mainExpanded"
        [togglePosition]="'before'"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
    >
        <mat-expansion-panel-header data-cy="offer-field-container">
                <div *ngIf="isTest">
                    <div
                        class="grow-0 test-indicator-label px-2
                        bg-gray-500 text-white font-bold">
                        Test
                    </div>
                </div>
                <div *ngFor="let key of maxOptionDisplay" class="offer-field" data-cy="offer-field">
                    <div class="text-center offer-field-header text-secondary max-amount-header">
                    {{ key | uppercase }}
                    </div>
                    <div class="text-center font-bold">
                    <div *ngIf="key === 'amount' || key === 'payback'; else noPipe">
                        {{ _maxOption[key] | currency: undefined: 'symbol': '1.0-0' }}
                    </div>
                    <ng-template #noPipe>
                        {{ _maxOption[key] }}
                    </ng-template>
                    </div>
                </div>
        </mat-expansion-panel-header>
        <!-- Content -->
        <div class="relative">
        <div *ngIf="errored" class="error-message">
            <div>
            <p>
                There is currently an integration error with <strong>{{ loanProductLender }}</strong>.
                <span *ngIf="!offer.offerLink">Calculations for this approval are unavailable at this time.</span>
                Please contact Lendio product and development.
            </p>
            <div *ngIf="offer.offerLink">
                To continue calculations for this approval, follow this link:
                <div>
                <a href="{{ offer.offerLink }}" ref="noopener noreferrer nofollow" target="_blank">{{ offer.offerLink }}</a>
                </div>
            </div>
            </div>
        </div>

        <ng-container *ngIf="!readOnly else readOnlyView">
            <div [ngClass]="{'error-blur': errored}" class="flex" *ngIf="!initializing">
            <div class="approval-content pr-3 flex-grow" data-cy="approval-calculator-content">

                <!-- Term -->
                <div class="flex">
                <app-restricted-input
                    class="restricted-input"
                    [label]="'Term'"
                    (valueChange)="changeTerm($event); calculate()"
                    [value]="term"
                    [step]="1"
                    [loading]="loading"
                    [disabled]="disabled || (terms && terms.length <= 1)"
                    [type]="'number'"
                    [min]="minTerm"
                    [max]="maxTerm"
                    data-cy="calc-term-input"
                >
                </app-restricted-input>

                <app-discrete-slider
                    *ngIf="terms && terms.length > 1"
                    class="w-100 flex-grow"
                    (valueChange)="changeTerm($event); calculate()"
                    [loading]="loading"
                    [disabled]="disabled"
                    [thumbLabel]="true"
                    [value]="term"
                    [marks]="terms"
                >
                </app-discrete-slider>
                </div>

                <!-- Frequency -->
                <div class="flex items-center">
                <app-restricted-input
                    class="restricted-input"
                    [label]="'Frequency'"
                    [loading]="loading"
                    [disabled]="disabled || true"
                    [value]="paymentFrequencyFriendly"
                >
                </app-restricted-input>

                <app-restricted-radio
                    class="w-100 ml-1 pt-1"
                    *ngIf="frequencyOptions && frequencyOptions.length > 1"
                    [options]="frequencyOptions"
                    [availableOptions]="availableFrequencies"
                    [disabled]="disabled"
                    [loading]="loading"
                    (valueChange)="changePaymentFreq($event); calculate()"
                    [value]="paymentFrequency"
                ></app-restricted-radio>
                </div>

                <!-- Points -->
                <div class="flex">
                <app-restricted-input
                    class="restricted-input"
                    (valueChange)="changePoints($event); calculate()"
                    [value]="points"
                    [label]="'Points'"
                    [type]="'number'"
                    [loading]="loading"
                    [disabled]="disabled"
                    [step]="1"
                    [min]="currentOptionParams.points.min"
                    [max]="currentOptionParams.points.max"
                    data-cy="calc-points-input"
                >
                </app-restricted-input>

                <app-restricted-slider
                    class="w-100 flex-grow"
                    [updateContinuously]="true"
                    (valueChange)="changePoints($event); calculationType !== 'custom' && calculate();"
                    (mouseup)="calculate()"
                    [value]="points"
                    [showValues]="true"
                    [tickInterval]="0"
                    [thumbLabel]="true"
                    [loading]="loading"
                    [disabled]="disabled"
                    [step]="1"
                    [min]="approvalBoundaries.points.min"
                    [availableMin]="currentOptionParams.points.min"
                    [max]="approvalBoundaries.points.max"
                    [availableMax]="currentOptionParams.points.max"
                >
                </app-restricted-slider>
                </div>

                <!-- Amount -->
                <div class="flex">
                <app-restricted-input
                    class="restricted-input"
                    (valueChange)="changeAmount($event); calculate()"
                    [value]="amount"
                    [fixedDecimal]="2"
                    [type]="'number'"
                    [label]="'Amount'"
                    [loading]="loading"
                    [disabled]="disabled || currentOptionParams.amount.min === availableAmount"
                    [step]="100"
                    [min]="currentOptionParams.amount.min"
                    [max]="currentOptionParams.amount.max"
                    data-cy="calc-amount-input"
                >
                </app-restricted-input>

                <div class="animate-opacity flex-grow" [ngStyle]="{'opacity': currentOptionParams.amount.min !== availableAmount ? 1 : 0}">
                    <app-restricted-slider
                    class="w-100 flex-grow"
                    [updateContinuously]="true"
                    (valueChange)="changeAmount($event); calculationType !== 'custom' && calculate();"
                    (mouseup)="calculate()"
                    [value]="amount"
                    [showValues]="true"
                    [currency]="true"
                    [showCurrencyDecimals]="false"
                    [tickInterval]="0"
                    [thumbLabel]="true"
                    [loading]="loading"
                    [disabled]="disabled || currentOptionParams.amount.min === currentOptionParams.amount.max"
                    [step]="1000"
                    [min]="approvalBoundaries.amount.min"
                    [availableMin]="currentOptionParams.amount.min"
                    [max]="approvalBoundaries.amount.max"
                    [availableMax]="currentOptionParams.amount.max">
                    </app-restricted-slider>
                </div>
                </div>

                <!-- Origination -->
                <div class="flex">
                <app-restricted-input
                    class="restricted-input"
                    [label]="'Origination'"
                    (valueChange)="changeOriginationPercent($event); calculate()"
                    [(value)]="originationPercent"
                    [loading]="loading"
                    [disabled]="disabled || !validOriginationRange"
                    [step]="0.1"
                    [type]="'number'"
                    [min]="curOriginParams.originationPercent.absMin"
                    [max]="curOriginParams.originationPercent.absMax"
                    [suffix]="originationInputSuffix"
                >
                </app-restricted-input>

                <div class="animate-opacity flex-grow" *ngIf="validOriginationRange">
                    <app-restricted-slider
                    class="w-100 flex-grow"
                    [updateContinuously]="true"
                    (valueChange)="changeOriginationPercent($event); calculate();"
                    (mouseup)="calculate()"
                    [(value)]="originationPercent"
                    [availableMax]="curOriginParams.originationPercent.absMax"
                    [availableMin]="curOriginParams.originationPercent.absMin"
                    [showValues]="true"
                    [tickInterval]="0"
                    [strictSteps]="false"
                    [thumbLabel]="true"
                    [customThumbLabel]="originationThumbLabel"
                    [loading]="loading"
                    [disabled]="disabled || !validOriginationRange"
                    [step]="0.1"
                    [min]="curOriginParams.originationPercent.min"
                    [max]="curOriginParams.originationPercent.max">
                    </app-restricted-slider>
                </div>
                </div>
            </div>

            <!-- Calculated Data -->
            <div class="flex approval-bullet-list justify-end" [ngClass]="{'loading': loading}">
                <div class="grid grid-cols-2" *ngFor="let key of optionDataKeys" data-cy="approval-bullet-item">
                    <div class="grid grid-flow-col items-center text-secondary text-right w-50" >
                        {{ key }}
                    </div>
                    <div class="grid gap-2 grid-flow-col ml-3 items-center font-bold w-50 animate-loading">
                        <span>
                            {{ optionData[key] }}
                        </span>
                        <ng-container *ngIf="hasVariablePayment && key === 'Payment'" #variablePayment>
                            <mat-chip fontSet="material-icons-outlined" class="variable-chip">
                                <mat-icon
                                [matTooltip]="'This approval has a variable payment calculated as a percentage of sales.'"
                                class="material-symbols-outlined icon-class align-middle">
                                    info
                                </mat-icon>
                                Variable
                            </mat-chip>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Data Drawer / Additional Data -->
            <div class="approval-data-drawer" [ngClass]="{'active': drawerActive}">
                <!-- Clickable Zone -->
                <div
                class="approval-data-drawer-click-panel"
                *ngIf="!drawerActive"
                (click)="drawerActive = true">
                </div>

                <!-- Clickable X -->
                <div class="data-drawer-icons" (click)="drawerActive = !drawerActive">
                <mat-icon>chevron_left</mat-icon>
                <!-- Second icon to make the 'X' -->
                <mat-icon>chevron_left</mat-icon>
                </div>

                <div class="approval-data-drawer-content">
                <ng-container *ngTemplateOutlet="stipulations"></ng-container>
                </div>

            </div>

            </div>
        </ng-container>

        <ng-template #readOnlyView>
            <div class="grid grid-cols-[30%_1px_1fr] gap-2.5 p-4">
            <ng-container *ngTemplateOutlet="calculated"></ng-container>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="grid">
                <ng-container *ngTemplateOutlet="stipulations"></ng-container>
            </div>
            </div>
        </ng-template>

        </div>

    </mat-expansion-panel>
</div>

<ng-template #calculated>
  <!-- Calculated Data -->
  <div [ngClass]="{'loading': loading}">
    <div class="grid grid-flow-col justify-between" *ngFor="let key of optionDataKeys">
      <span>{{ key }}</span>
      <span>{{ optionData[key] }}</span>
    </div>
  </div>
</ng-template>

<ng-template #stipulations>
  <div class="mb-3">
    <h4 >
      <div class="font-medium text-lg">Stipulations</div>
    </h4>
    <ul [style.listStyle]="'disc'" class="approval-stipulations" >
      <li *ngFor="let nonConditional of requests.nonConditional"> {{ nonConditional }} </li>
      <li *ngFor="let conditional of requests.conditional"
          [ngClass]="{'text-opacity-50': (amount < conditional.minAmount)}">
        {{ conditional.name }} ({{ conditional.minAmount | currency }}+)
      </li>
    </ul>
    <div *ngIf="requests.length == 0">
      None
    </div>
  </div>
  <div class="mb-3" *ngIf="notes.length > 0">
    <h4>
      <div class="font-medium text-lg">Notes</div>
    </h4>
    <span>{{ notes }}</span>
  </div>
  <div>
    <div class="flex justify-between">
        Position
        <div>
            {{ position }}
        </div>
    </div>
    <div class="flex justify-between">
        Net Requirement
        <div>
            {{ netMinimum / 100 | percent }}
        </div>
    </div>

    <div class="flex justify-between">
        Expiration
        <div *ngIf="expires && expires != '0000-00-00 00:00:00'">
            {{ expires | date }}
        </div>
    </div>
    <div class="flex justify-between mb-4">
        Offer Link
        <div class="flex justify-between pl-4 pb-4">
            <a class="text-sm offer-link-button leading-8 flex items-stretch items-end"
                href="{{ _offer.offerLink }}" target="_blank">
                <span class="mr-1">Open</span>
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
    </div>
  </div>
</ng-template>
