
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ImportsService } from '@app/app/services/imports.service';
import { catchError, of, tap } from 'rxjs';
import { GetImportDetails, GetImportEntities, SubscribeToImportDetailsStateUpdates, UpdateCurrentImport } from './import-details.actions';
import { ImportEntity, PaginatedImportDetailsResponse } from '@app/app/interfaces/import-details.model';
import { Import } from '@app/app/interfaces/import.model';
import { PusherService } from '@app/app/services/pusher.service';
import { AuthState } from '../auth/auth.state';


export class ImportDetailsStateModel {
  import: Import | null;
  importEntities: ImportEntity[] | null;
  errors: null;
  loading: boolean;
  page: number;
  total: number;
  last_page: number | null;
  next_page_url: string;
  pageSize: number;
  pageIndex: number;
  prev_page_url: string;
  sortBy: string;
  sortDirection: string;
  filterBy: string;
}

@State<ImportDetailsStateModel>({
  name: 'ImportDetails',
  defaults: {
    import: null,
    importEntities: null,
    errors: null,
    loading: false,
    page: 1,
    last_page: 0,
    total: 0,
    next_page_url: '',
    pageSize: 20,
    pageIndex: 0,
    prev_page_url: '',
    sortBy: 'created',
    sortDirection: 'desc',
    filterBy: ''
  }
})

@Injectable()

export class ImportDetailsState {

  @Selector()
  static filterBy(state: ImportDetailsStateModel) {
      return state.filterBy;
  }

  @Selector()
  static importDetails(state: ImportDetailsStateModel) {
      return state.import;
  }

  @Selector()
  static importEntities(state: ImportDetailsStateModel) {
      return state.importEntities;
  }

  @Selector()
  static created(state: ImportDetailsStateModel) {
      return state.import?.created;
  }

  @Selector()
  static importedBy(state: ImportDetailsStateModel) {
      return state.import?.importedBy;
  }

  @Selector()
  static type(state: ImportDetailsStateModel) {
      return state.import?.type;
  }

  @Selector()
  static status(state: ImportDetailsStateModel) {
      return state.import?.status;
  }

  @Selector()
  static totalRecords(state: ImportDetailsStateModel) {
      return state.import?.totalRecords;
  }

  @Selector()
  static updatedRecords(state: ImportDetailsStateModel) {
      return state.import?.updatedRecords;
  }

  @Selector()
  static importedRecords(state: ImportDetailsStateModel) {
      return state.import?.importedRecords;
  }

  @Selector()
  static skippedRecords(state: ImportDetailsStateModel) {
      return state.import?.skippedRecords;
  }

  @Selector()
  static failedRecords(state: ImportDetailsStateModel) {
      return state.import?.failedRecords;
  }

  @Selector()
  static description(state: ImportDetailsStateModel) {
      return state.import?.description;
  }

  @Selector()
  static nextPageUrl(state: ImportDetailsStateModel) {
      return state.next_page_url;
  }

  @Selector()
  static page(state: ImportDetailsStateModel) {
      return state.page;
  }

  @Selector()
  static pageIndex(state: ImportDetailsStateModel) {
      return state.pageIndex;
  }

  @Selector()
  static pageSize(state: ImportDetailsStateModel) {
      return state.pageSize;
  }

  @Selector()
  static prevPageUrl(state: ImportDetailsStateModel) {
      return state.prev_page_url;
  }

  @Selector()
  static sortBy(state: ImportDetailsStateModel) {
      return state.sortBy;
  }

  @Selector()
  static sortDirection(state: ImportDetailsStateModel) {
      return state.sortDirection;
  }

  @Selector()
  static total(state: ImportDetailsStateModel) {
      return state.total;
  }

  @Selector()
  static loading(state: ImportDetailsStateModel) {
    return state.loading;
  }

  constructor(
      private importsService: ImportsService,
      private store: Store,
      private pusherService: PusherService,
  ) {}

@Action(GetImportDetails)
getImportDetails({ patchState }: StateContext<ImportDetailsStateModel>, {
  importId
}: GetImportDetails) {

  this.store.dispatch(new GetImportEntities({}, importId));

  patchState({loading: true});

    return this.importsService
      .getImportDetails(importId)
      .pipe(catchError((errors) => {
        patchState({errors, loading:false});
        console.error(errors);
        return of('');
      }),
      tap((response: any) => {
        patchState({ import: response.data, loading: false });
    }));
}

@Action(GetImportEntities)
  getImportEntities(
    { getState, patchState }: StateContext<ImportDetailsStateModel>,
    { paramChanges, importId }: GetImportEntities) {

      patchState(paramChanges);

      const { pageIndex, pageSize, sortBy, sortDirection, filterBy } = getState();

      return this.importsService.getImportEntities(
        importId,
        pageIndex,
        pageSize,
        sortBy,
        sortDirection,
        filterBy
        ).pipe(
            catchError((errors) => {
              patchState({errors, loading: false});
              console.error(errors);
              return of('');
            }
          ),
          tap((response: PaginatedImportDetailsResponse) => {
            patchState({
              last_page: response.data.last_page,
              importEntities: response.data.data,
              page: response.data.current_page,
              total: response.data.total,
              next_page_url: response.data.next_page_url
            })
          })
        );
    }

    @Action(UpdateCurrentImport)
    updateCurrentImport(ctx: StateContext<ImportDetailsStateModel>, { incomingImport }: UpdateCurrentImport) {
      ctx.patchState(
        {
          import: incomingImport
        }
      );
    }

    @Action(SubscribeToImportDetailsStateUpdates)
    subscribeToImportDetailsStateUpdates({ }: StateContext<ImportDetailsStateModel>, { }: SubscribeToImportDetailsStateUpdates) {
        this.pusherSubscribe();
    }

    pusherSubscribe() {
      this.pusherService.subscribe({
        name: `import-updated-${this.store.selectSnapshot(AuthState.user)?.institution?.id}`,
        auth: false,
        handler: (event: any, incomingImport: Import) => {
          if(event === 'import-update'){
            this.handlePusherMessage(incomingImport);
          }
          return;
        }
      });
    }

    handlePusherMessage(incomingImport: Import) {
      this.store.dispatch(new UpdateCurrentImport(incomingImport));
    }
}
