import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-credit-box-rules-dialog',
  templateUrl: './confirm-credit-box-rules-dialog.component.html'
})

export class ConfirmCreditBoxRulesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}
