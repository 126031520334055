<mat-card appearance="outlined" class="m-4 new-theme-typography lendio-density-1 inline">
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title></mat-card-title>
        </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
        <button mat-flat-button
                color="accent"
                (click)="generateAll()"
                matTooltip="generate CSV files for businesses, contacts, bank accounts, and transactions">
            Generate Demo Files
        </button>
    </mat-card-content>
</mat-card>
