import { TemplateDialogData } from '@app/app/components/templates/template.dialog.component';
import { PipeTransform } from '@angular/core';
import { addDays, format } from 'date-fns';

export declare interface TemplateResolverPipes {
  [key: string]: Partial<PipeTransform>
}
export declare type TemplateResolverFn = (data: TemplateDialogData, pipes?: TemplateResolverPipes) => string;
export declare interface TemplateResolverMap {
  [key: string]: TemplateResolverFn
}

export const TemplateResolvers: TemplateResolverMap = {
  currentDate: (data) => {
    return format(new Date(), 'MMM dd, yyyy');
  },
  borrowerLegalName: (data) => {
    const borrowerPrimaryContact = data?.business?.primaryContact;
    return borrowerPrimaryContact ? `${borrowerPrimaryContact.first} ${borrowerPrimaryContact.last}`: '';
  },
  firstName: (data) => {
    const borrowerPrimaryContact = data?.business?.primaryContact;
    return borrowerPrimaryContact ? `${borrowerPrimaryContact.first}` : '';
  },
  lastName: (data: TemplateDialogData) => {
    const borrowerPrimaryContact = data?.business?.primaryContact;
    return borrowerPrimaryContact ? `${borrowerPrimaryContact.last}` : '';
  },
  borrowerPhone: (data: TemplateDialogData, pipes) => {
    const phoneVal = data?.business?.phone;
    return phoneVal && pipes?.phonePipe?.transform ? pipes.phonePipe.transform(phoneVal) : '';
  },
  borrowerStreet: (data) => {
    const business = data?.business;
    const street = business?.street || '',
      street2 = business?.street2 ? `${business.street2}</br>` : '';
    return [street, street2].join(' ');
  },
  borrowerCityStateZip: (data) => {
    const business = data?.business;
    const city = business?.city || '',
      state = business?.stateId || '',
      zip = business?.zipId || '';
    return [city, state, zip].join(' ');
  },
  borrowerAddress: (data) => {
    const business = data?.business;
    const street = business?.street || '',
      street2 = business?.street2 ? `${business.street2}</br>` : '',
      city = business?.city || '',
      state = business?.stateId || '',
      zip = business?.zipId || '';

    return `${street}${street2}${city}, ${state} ${zip}`;
  },
  creditScore: (data) => {
    return data.creditReport?.creditScore.toString() || 'N/A';
  },
  creditPullDate: (data) => {
    return data.creditReport?.reportDate || 'N/A';
  },
  creditScoreFactors: (data: TemplateDialogData) => {
    const scoreFactorValues = data.creditReport?.scoreFactors?.map( ({value}) => `<li>${value}</li>` );
    return !!scoreFactorValues?.length ? `<ul>${scoreFactorValues.join('')}</ul>` : '';
  },
  recipientEmail: (data) => {
    return data.recipientEmails?.join(', ') || '';
  }
}

export const futureDateResolver = ($1) => {
  const days = parseInt($1);
  return format(addDays(new Date(), days), 'iiii MMMM dd, yyyy');
}

export const logoResolver = ($src, $width, $height) => {
  const widthString = $width ? `width=${$width}` : '';
  const heightString = $height ? `height=${$height}` : '';
  return `<img src="${$src}" ${widthString} ${heightString} > \n`;
}
