<div *ngIf="(notes$ | async) as notes">
    <div class="flex justify-between pb-0 flex-no-wrap whitespace-no-wrap"
         [class.old-theme-deal-notes]="oldThemeEnabled()">
        <h2>Notes</h2>
        <button
            mat-button
            (click)="openAddNote()"
            [color]="'primary'"
            data-cy="add-note-button">
            Add
        </button>
    </div>

    <div class="p-2 note-container mb-2"
         *ngFor="let note of notes"
         matTooltip="{{(note.created | date:'MMMM d, y \'at\' h:mma') || ''}}"
    >
        <div data-cy="deal-note-text">{{note.content}}</div>
        <div class="mt-2 text-sm italic text-gray-400">
            {{note.lenderUserName}}
        </div>
    </div>
</div>

