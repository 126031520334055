@if (filtersChecked()) {
    <div class="flex flex-col justify-center items-center gap-4">
        <img src="assets/images/empty-state-svgs/empty-history.svg" alt="No history results were found.">
        @if (noDealActivitiesMatchFilters()) {
            <div class="mat-subtitle-2">No history results were found.</div>
            <div class="mat-body-2">Please try adjusting your search or filters.</div>

        } @else {
            <div class="mat-subtitle-2">There are no activities.</div>
        }
    </div>
}
