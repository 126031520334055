import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-user-avatar-component',
    template:
        `<div class="avatar-container">
                    <img *ngIf="showAvatar; else noAvatar" class="avatar avatar-rounded" [src]="avatarUrl" [alt]="'user avatar'" (error)="showAvatar = false">
                    <ng-template #noAvatar>
                        <div class="no-avatar">{{ firstInitial }}{{ lastInitial }}</div>
                    </ng-template>
                </div>`,
    styleUrls: ['user-avatar.component.scss']
})

export class UserAvatarComponent implements OnInit {

    @Input() avatarUrl: string;
    @Input() first: string;
    @Input() last: string;
    @Input() full: string;

    firstInitial: string;
    lastInitial: string;
    showAvatar = false;

    constructor() {}

    ngOnInit() {
        const name = this.full || '';

        if (name && name.split(/\s+/g).length > 1) {
            this.firstInitial = name[0];
            this.lastInitial = name.split(/\s+/g)[1][0];
        }

        if (this.first) {
            this.firstInitial = this.first.charAt(0);
        }

        if (this.last) {
            this.lastInitial = this.last.charAt(0);
        }

        this.showAvatar = this.avatarUrl ? true : false;
    }

    getAvatarUrl() {
        if (this.avatarUrl) {
            return this.avatarUrl;
        }
        return '';
    }
}
