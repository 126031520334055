<div class="p-4 grid">
  <ng-container *ngIf="(decisioning$ | async)?.length && !(decisioningFailed$ | async) else emptyState">
    <ng-container *ngFor="let decision of (decisioning$ | async); first as isFirst">
      <mat-card *ngIf="decision.approvalQualifierValues.length > 0" class="mb-2.5">
        <mat-card-header class="grid grid-flow-col justify-between py-2">
          <mat-card-title>
            <span class="font-semibold text-lg">{{decision.product.name}} - Approval</span>
          </mat-card-title>
          <div class="grid grid-flow-col justify-center gap-2" [ngClass]="{
              'text-green-600': decision.approvalMatchPercentage == 100,
              'text-red-500': decision.approvalMatchPercentage < 100
          }">
            <mat-icon>{{decision.approvalMatchPercentage < 100 ? 'cancel' : 'check_circle' }}</mat-icon>
            <span class="text-base font-bold">{{decision.approvalMatchPercentage < 100 ? 'Disqualified' : 'Qualified' }}</span>
            <span class="text-base font-bold">({{decision.approvalMatchPercentage}}%)</span>
          </div>
        </mat-card-header>
        <app-qualifier-values-table class="px-4 pt-2 pb-4"
          [qualifierValues]="decision.approvalQualifierValues"></app-qualifier-values-table>
      </mat-card>
      <mat-card>
        <mat-card-header class="grid grid-flow-col justify-between py-2">
          <mat-card-title>
            <span class="font-semibold text-lg">{{decision.product.name}} - Preapproval</span>
          </mat-card-title>
          <div class="grid grid-flow-col justify-center gap-2" [ngClass]="{
              'text-green-600': decision.preapprovalMatchPercentage == 100,
              'text-red-500': decision.preapprovalMatchPercentage < 100
          }">
            <mat-icon>{{decision.preapprovalMatchPercentage < 100 ? 'cancel' : 'check_circle' }}</mat-icon>
            <span class="text-base font-bold">{{decision.preapprovalMatchPercentage < 100 ? 'Disqualified' : 'Qualified' }}</span>
            <span class="text-base font-bold">({{decision.preapprovalMatchPercentage > 0 ? decision.preapprovalMatchPercentage : 0}}%)</span>
          </div>
        </mat-card-header>
        <app-qualifier-values-table class="px-4 pt-2 pb-4"
          [qualifierValues]="decision.preapprovalQualifierValues"></app-qualifier-values-table>
      </mat-card>

    </ng-container>
  </ng-container>
</div>

<ng-template #emptyState>
  <mat-card class="mat-mdc-card-content m-4" appearance="outlined">
    <app-empty-state [type]="emptyType" [label]="emptyLabelContent" [loading]="loading$ | async">
      <div class="text-center font-normal text-gray-900 mt-2" messageContent>
        {{emptyMessageContent}}
      </div>
    </app-empty-state>
  </mat-card>
</ng-template>