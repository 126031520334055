import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LendioStatusDisplayComponent } from '@app/app/components/lendio-angular-material-theme/lendio-status-display/lendio-status-display.component';
import { Deal } from '@app/app/interfaces/deal.model';
import { MatCardModule } from '@angular/material/card';
import { DatePipe } from '@angular/common';
import { DEAL_STATUS_OPTIONS_BY_STAGE } from '../deal-status-change-dropdown/deal-status-options-by-stage';
import { StatusOption } from '@app/app/interfaces/status-option';
import { Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component( {
  selector: 'app-deal-simple',
  imports: [
    LendioStatusDisplayComponent,
    MatCardModule,
    DatePipe,
    MatButtonModule,
    RouterLink
  ],
  standalone: true,
  templateUrl: './deal-simple.component.html',
  styleUrls: [ './deal-simple.component.scss' ]
} )
export class DealSimpleComponent implements OnInit, OnDestroy {

  @Input() deal: Deal;

  destroyed$ = new Subject<boolean>();

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroyed$.next( true );
    this.destroyed$.complete();
  }

  /**
   * Use deal’s stage/status to find predefined StatusOption
   * */
  get currentStatusOption(): StatusOption | undefined {
    // TODO: If for whatever reason we ever can’t find a match (which shouldn’t ever happen),
    // we need to handle the undefined case.
    if (DEAL_STATUS_OPTIONS_BY_STAGE.hasOwnProperty(this.deal.stage)) {
      return DEAL_STATUS_OPTIONS_BY_STAGE[this.deal.stage].find(
        (statusOption: StatusOption) => statusOption.value === this.deal.status
      );
    }
    return undefined;
  }

}
