import { NgModule } from '@angular/core';
import { AssignmentDropdownComponent } from '@app/app/components/assignment-dropdown/assignment-dropdown.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AssignmentDropdownComponent
  ],
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
  ],
  exports: [
    AssignmentDropdownComponent,
  ]
})
export class AssignmentDropdownModule {}
