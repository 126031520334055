<div [class.old-theme-loan-product]="oldThemeEnabled()">
<mat-card appearance="outlined" class="!w-full">
  <div id="alert-info" *ngIf="!hasWriteAccess">
    <mat-icon>warning</mat-icon>
    <div id="info-text"><strong>READ ONLY</strong> — <em>Contact the Lender Strategy team for changes.</em></div>
  </div>
  <mat-card-content *ngIf="!errored">
    <div class="text-4xl py-3 font-normal">
      <ng-container *ngIf="loanProduct">
        <app-loan-product-title
            [loanProduct]="loanProduct">
        </app-loan-product-title>
      </ng-container>
      <div class="w-2/5" *ngIf="!loanProduct">
        <app-horizontal-loader></app-horizontal-loader>
        <app-horizontal-loader></app-horizontal-loader>
      </div>
    </div>

    <ng-container *ngIf="!isInactive">
      <span class="overlay-loader"
            *ngIf="addingQualifier || updatingQualifier"><app-dot-loader-scale></app-dot-loader-scale></span>

      <h2 class="font-normal">Qualifications</h2>
      <div class="bg-orange-200 text-yellow-700 rounded px-3 py-1 text-sm"
           *ngIf="loanProduct && loanProduct.qualifiers && loanProduct.qualifiers.length === 0"
           data-cy="loan-product-component-select-min-warning">
        Select the <strong>minimum</strong> qualifications necessary to extend an offer to an applicant.
      </div>

      <div class="text-2xl font-light pt-4 pb-2">Base Qualifications</div>
      <ng-container *ngIf="loanProduct && loanProduct.qualifiers && qualifierQuestions">
        <ng-container *ngIf="loanProduct.qualifiers.length === 0">
          <app-qualifier (save)="createQualifier($event)"
                          (cancel)="cancelSavingQualifier()"
                          [questions]="qualifierQuestions"
                          [states]="states"
                          [loanProductId]="loanProductId"
                          [qualifiers]="loanProduct.qualifiers"
                          [baseQualifiers]="loanProduct.qualifiers"
                          [hasWriteAccess]="hasWriteAccess">

          </app-qualifier>
        </ng-container>
        <ng-container *ngFor="let qualifier of loanProduct.qualifiers; last as isLast">
            <app-qualifier *ngIf="qualifier"
                            (remove)="isLast && loanProduct.qualifiers.length === 1 ?
                                      openDeleteAllDialog($event) : deleteQualifier($event)"
                            (cancelUpdatesToQualifier)="cancelQualifierUpdates(qualifier, 'base')"
                            (dirty)="qualifierIsDirty($event, qualifier.id)"
                            (update)="putQualifier()"
                            [hasWriteAccess]="hasWriteAccess"
                            [disabledAttributeFields]="disabledBaseAttributeFields"
                            [disabledOperatorFilterTypes]="disabledBaseOperatorFilterTypes"
                            [loanProductId]="loanProductId"
                            [qualifier]="qualifier"
                            [baseQualifiers]="loanProduct.qualifiers"
                            [questions]="qualifierQuestions"
                            [states]="states"
                            [showUpdateButton]="showUpdateButtonByCurrentQualifier(qualifier.id)">
            </app-qualifier>
        </ng-container>
        <app-add-base-qualifier (addQualifier)="createQualifier($event)"
                                [hasWriteAccess]="hasWriteAccess"
                                [qualifiers]="loanProduct.qualifiers"
                                [questions]="qualifierQuestions"
                                [states]="states"
                                [loanProductId]="loanProductId"
                                [disabledBaseAttributeFields]="disabledBaseAttributeFields"
                                [disabledBaseOperatorFilterTypes]="disabledBaseOperatorFilterTypes">
        </app-add-base-qualifier>
      </ng-container>
      <div class="w-1/5" *ngIf="!qualifierQuestions">
        <app-horizontal-loader></app-horizontal-loader>
        <app-horizontal-loader></app-horizontal-loader>
      </div>

      <div class="text-2xl font-light pt-12">Exceptions</div>
      <div class="text-gray-1000 py-1 font-light text-sm">
          These rules override the base qualification requirements.
          <br>Each group is mutually exclusive, meaning if a borrower meets at least one exception group, they will qualify.
      </div>
      <span *ngIf="showBaseQualificationRequirementWarning">
        <div class="bg-orange-200 text-yellow-700 rounded px-3 py-1 text-sm">
          Select and save at least one base qualification in order to add an exception group.
        </div>
      </span>
      <ng-container *ngIf="loanProduct && loanProduct.qualifiers && qualifierQuestions && loanProduct.qualifier_exception_groups">
        <app-qualifier-exception-groups *ngFor="let exceptionGroup of loanProduct.qualifier_exception_groups; let i = index"
                                        (addQualifier)="createQualifier($event)"
                                        (dirtyQualifier)="qualifierIsDirty($event, $event.data.id)"
                                        (updateQualifier)="putQualifier($event)"
                                        (removeQualifier)="deleteQualifier($event)"
                                        (renameGroup)="putExceptionGroup($event)"
                                        (cancelUpdatesToQualifier)="cancelQualifierUpdates($event, 'partOfExceptionGroup')"
                                        (cancel)="cancelSavingQualifier()"
                                        (removeExceptionGroup)="deleteExceptionGroup($event)"
                                        (showBaseQualificationRequirementWarningChange)="showBaseQualificationRequirementWarning = $event"
                                        [qualifiers]="loanProduct.qualifiers"
                                        [exceptionGroup]="exceptionGroup"
                                        [qualifierExceptionGroups]="loanProduct.qualifier_exception_groups"
                                        [exceptionGroupIndex]="i"
                                        [questions]="qualifierQuestions"
                                        [states]="states"
                                        [loanProductId]="loanProductId"
                                        [hasWriteAccess]="hasWriteAccess"
                                        [dirtyQualifierId]="dirtyQualifierId"
                                        [modifiedQualifier]="modifiedQualifier"
                                        [baseQualifiers]="loanProduct.qualifiers"
                                        [(showBaseQualificationRequirementWarning)]="showBaseQualificationRequirementWarning">
        </app-qualifier-exception-groups>
      </ng-container>
      <button mat-flat-button color="primary"
              *ngIf="hasWriteAccess && !addingQualifierExceptionGroup"
              (click)="createExceptionGroup()"
              [disabled]="(loanProduct && loanProduct.qualifiers && loanProduct.qualifiers.length === 0) || updatingQualifier ||
                          addingQualifier || updatingQualifierExceptionGroup || addingQualifierExceptionGroup"
              data-cy="loan-product-component-add-exception-group">
        Add Exception Group
      </button>

      <div class="text-2xl font-light pt-12">Credit Box Rules</div>
      <div class="text-gray-1000 py-1 font-light text-sm">
        This On/Off toggle will include or exclude this product in filtration for the submission strategy.
      </div>
      <div class="py-1 text-md">Include in submission strategy</div>
      <mat-slide-toggle color="primary"
        [(ngModel)]="isIncludedInSubmissionStrategy"
        (change)="openConfirmCreditBoxRulesDialog($event.checked)"
        [disabled]="!hasLoanProductWriteAccess"
        data-cy="loan-product-component-include-in-submission-strategy-toggle"
      >
        <div [ngClass]="isIncludedInSubmissionStrategy == true ? 'text-green-500' : 'text-red-500'"
             data-cy="loan-product-component-include-in-submission-strategy-value">{{isIncludedInSubmissionStrategy ? "On" : "Off"}}</div>
      </mat-slide-toggle>

      <app-restriction-note *ngIf="loanProduct?.hasOwnProperty('restrictionNotes')"
          (saveRestrictionNote)="updateRestrictionNotes($event)"
          [hasLoanProductWriteAccess]="hasLoanProductWriteAccess"
          [restrictionNotes]="loanProduct?.restrictionNotes"></app-restriction-note>

      <div class="text-2xl font-light pt-12">Required Information</div>
      <div class="bg-orange-200 text-yellow-700 rounded px-3 py-1 text-sm">
        Select the <strong>minimum</strong> information questions necessary to extend an offer to an applicant.
      </div>
      <div class="pt-3 flex flex-auto">
        <div class="flex-col w-1/3">
          <div class="font-bold border-gray-600 border-b">Owner Info</div>
          <app-required-information
              *ngIf="loanProductQuestions && infoQuestions"
              (requirementRemoved)="removeRequirement($event)"
              (requirementsAdded)="addRequirements($event)"
              (requiredForOfferToggled)="toggleRequiredForOffer($event)"
              [loanProductQuestions]="loanProductQuestions"
              [questions]="infoQuestions"
              [displayedSection]="'ownerInfo'"
              [hasWriteAccess]="hasWriteAccess"
              [dialogSections]="['ownerInfo']">
          </app-required-information>
          <div class="mt-4 py-1 w-full justify-center text-center" *ngIf="!loanProductQuestions || !infoQuestions">
            <app-dot-loader-scale></app-dot-loader-scale>
          </div>
        </div>
        <div class="flex-col w-1/3 mx-4">
          <div class="font-bold border-gray-600 border-b">Business Info</div>
          <app-required-information
              *ngIf="loanProductQuestions && infoQuestions"
              (requirementRemoved)="removeRequirement($event)"
              (requirementsAdded)="addRequirements($event)"
              (requiredForOfferToggled)="toggleRequiredForOffer($event)"
              [loanProductQuestions]="loanProductQuestions"
              [questions]="infoQuestions"
              [hasWriteAccess]="hasWriteAccess"
              [displayedSection]="'businessInfo'"
              [dialogSections]="['businessInfo']">
          </app-required-information>
          <div class="mt-4 py-1 w-full justify-center text-center" *ngIf="!loanProductQuestions || !infoQuestions">
            <app-dot-loader-scale></app-dot-loader-scale>
          </div>
        </div>
        <div class="flex-col w-1/3">
          <div class="font-bold border-gray-600 border-b">Documents</div>
          <div class="text-base font-medium pt-4">Required for Offer</div>
          <app-required-information
              *ngIf="requiredForContractOffer && infoQuestions"
              (requirementRemoved)="removeRequirement($event)"
              (requirementsAdded)="addRequirements($event)"
              (requiredForOfferToggled)="toggleRequiredForOffer($event)"
              [showButton]="false"
              [hasWriteAccess]="hasWriteAccess"
              [loanProductQuestions]="requiredForContractOffer"
              [questions]="infoQuestions"
              [displayedSection]="'documentsRequiredForOffer'"
              [dialogSections]="['documentsRequiredForOffer']">
          </app-required-information>
          <div class="mt-4 py-1 w-full justify-center text-center" *ngIf="!loanProductQuestions || !infoQuestions">
            <app-dot-loader-scale></app-dot-loader-scale>
          </div>
          <div class="text-base font-medium pt-4">Required for Contract</div>
          <app-required-information
              *ngIf="requiredForContractOffer && infoQuestions"
              (requirementRemoved)="removeRequirement($event)"
              (requirementsAdded)="addRequirements($event)"
              (requiredForOfferToggled)="toggleRequiredForOffer($event)"
              [loanProductQuestions]="requiredForContractOffer"
              [questions]="infoQuestions"
              [hasWriteAccess]="hasWriteAccess"
              [displayedSection]="'documentsRequiredForContract'"
              [dialogSections]="['documentsRequiredForOffer', 'documentsRequiredForContract']">
          </app-required-information>
          <div class="mt-4 py-1 w-full justify-center text-center" *ngIf="!loanProductQuestions || !infoQuestions">
            <app-dot-loader-scale></app-dot-loader-scale>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isInactive">
      <div class="text-gray-700">
        This loan product is inactive.
      </div>
    </ng-container>
  </mat-card-content>
  <mat-card-content *ngIf="errored">
    <h2>Unknown error</h2>
    An unknown error has occurred.
    Please <button mat-button color="primary" (click)="getLoanProduct(); getQuestions();">Try Again</button>.
  </mat-card-content>
</mat-card>
</div>
