import { Injectable } from '@angular/core';
import { StepStatus } from 'src/app/interfaces/lead-step-status.model';
import { Milestones } from 'src/app/interfaces/milestones.model';
import { StateDetails } from 'src/app/interfaces/state-details.model';
import { LeadStatusStep } from '../components/clients/drawer-step-status/lead-status-step';
import { LeadStatusSubStep } from '../components/clients/drawer-step-status/lead-status-sub-step';
import { MilestonesData } from '../components/clients/drawer-step-status/milestones-data';

const BORROWER_STEPS = [
  'Lead Claimed',
  'Attempted',
  'In Contact',
  'App Submitted'
];

const DEAL_STEPS = [
  'Deal In Progress',
  'Funded',
];

@Injectable()
export class DrawerStepStatusService {
  public getStatusStepsForClient(milestonesData: MilestonesData, borrowerOnly=false): LeadStatusStep[] {
    if (!milestonesData.hasOwnProperty('milestones')) {
      return [];
    }
    let milestones: Milestones = milestonesData.milestones;
    let stateDetails: StateDetails = milestonesData.stateDetails;
    let isInactive: boolean = stateDetails?.borrowerStage === 'inactive';
    let reason: string | null = stateDetails.opportunityStatusReason;
    let steps: LeadStatusStep[] = [];

    // Lead Claimed
    let hasStarted: boolean = !!milestones.started;
    let startedStatus: StepStatus;
    let startedErrorMessage: string | null = null;
    if (hasStarted) {
      startedStatus = StepStatus.COMPLETE;
    } else if (isInactive) {
      startedStatus = StepStatus.ERROR;
      startedErrorMessage = reason;
    } else {
      startedStatus = StepStatus.NOT_STARTED;
    }
    steps.push(new LeadStatusStep('Lead Claimed', 'Your client’s file has been received by our funding manager', [], milestones.started, startedStatus, startedErrorMessage));

    // Attempted
    let hasAttempted: boolean = !!milestones.attempted;
    let attemptedStatus: StepStatus;
    let attemptedErrorMessage: string | null = null;
    if (hasAttempted) {
      attemptedStatus = StepStatus.COMPLETE;
    }
      // This pattern is used on the rest of the steps.  If it is inactive but the previous step is complete,
    // then this step is the reason it is inactive so show the error message
    else if (hasStarted && isInactive) {
      attemptedStatus = StepStatus.ERROR;
      attemptedErrorMessage = reason;
    } else {
      attemptedStatus = StepStatus.NOT_STARTED;
    }
    steps.push(new LeadStatusStep('Attempted', 'A Lendio Funding Manager has attempted contact with your client', [], milestones.attempted, attemptedStatus, attemptedErrorMessage));

    // In Contact
    let hasContacted: boolean = !!milestones.contacted;
    let contactedStatus: StepStatus;
    let contactedErrorMessage: string | null = null;
    let description: string = 'Our Lendio Funding Manager is now in contact with your client';

    if (hasContacted) {
      contactedStatus = StepStatus.COMPLETE;
    } else if (hasAttempted && isInactive) {
      contactedStatus = StepStatus.ERROR;
      contactedErrorMessage = reason;
      description = "Our Lendio Funding Manager no longer has communication with your client.";
    } else {
      contactedStatus = StepStatus.NOT_STARTED;
    }
    steps.push(new LeadStatusStep('In Contact', description, [], milestones.contacted, contactedStatus, contactedErrorMessage));

    // App Submitted
    let hasSubmitted: boolean = !!milestones.submitted;
    let submittedStatus: StepStatus;
    let submittedErrorMessage: string | null = null;
    if (hasSubmitted) {
      submittedStatus = StepStatus.COMPLETE;
    } else if (contactedStatus === StepStatus.COMPLETE && isInactive) {
      submittedStatus = StepStatus.ERROR;
      submittedErrorMessage = reason;
    } else {
      submittedStatus = StepStatus.NOT_STARTED;
    }
    steps.push(new LeadStatusStep('App Submitted', 'Your client’s loan application has been submitted to underwriting', [], milestones.submitted, submittedStatus, submittedErrorMessage));

    // Deal In Progress
    let hasAccepted: boolean = !!milestones.offerAccepted;
    let acceptedStatus: StepStatus;
    let acceptedErrorMessage: string | null = null;
    if (hasAccepted) {
      acceptedStatus = StepStatus.COMPLETE;
    } else if (milestones.submitted || milestones.offerReceived || milestones.offerAccepted) {
      acceptedStatus = StepStatus.IN_PROGRESS;
    } else {
      acceptedStatus = StepStatus.NOT_STARTED;
    }
    // This is the only step with sub-steps.  They work just like steps each using their own timestamp to determine if it is complete.
    let inProgressSubSteps: LeadStatusSubStep[] = []
    inProgressSubSteps.push(new LeadStatusSubStep('Underwriting is reviewing your client’s application', !!milestones.submitted));
    inProgressSubSteps.push(new LeadStatusSubStep('Your client has received at least one loan offer (and may receive more)', !!milestones.offerReceived));
    inProgressSubSteps.push(new LeadStatusSubStep('Your client has accepted one loan offer', !!milestones.offerAccepted));

    steps.push(new LeadStatusStep('Deal In Progress', null, inProgressSubSteps, milestones.offerAccepted, acceptedStatus, acceptedErrorMessage));

    // Funded
    let hasFunded: boolean = !!milestones.funded;
    let fundedStatus: StepStatus;
    let fundedErrorMessage: string | null = null;
    if (hasFunded) {
      fundedStatus = StepStatus.FUNDED;
    } else if (hasAccepted && isInactive) {
      fundedStatus = StepStatus.ERROR;
      fundedErrorMessage = reason;
    } else {
      fundedStatus = StepStatus.NOT_STARTED;
    }
    steps.push(new LeadStatusStep('Funded', 'Your client has received their funds', [], milestones.funded, fundedStatus, fundedErrorMessage));

    const borrowerSteps = steps.filter(step => BORROWER_STEPS.includes(step.name));
    const dealSteps = steps.filter(step => DEAL_STEPS.includes(step.name));

    return borrowerOnly ? borrowerSteps : dealSteps;
  }
}
