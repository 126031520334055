<div class="fd-container new-theme-typography lendio-density-1"
     data-cy="contact-funding-desk"
     [class.old-theme-funding-desk]="oldThemeEnabled()">
	<div class="flex justify-between pb-0 flex-no-wrap whitespace-no-wrap">
		<h2 data-cy="funding-desk-title">Funding desk requests</h2>
		<button mat-button color="primary" class="pull-righcursor-pointer" data-cy="fd-request-btn" (click)="expandStatus === false ? fdPanel.open() : fdPanel.close()">
			{{ expandStatus === false ? 'Add' : 'Close' }}
		</button>
	</div>

	<mat-expansion-panel
		hideToggle="true"
		class="mat-elevation-z0 fd-request"
		[(expanded)]="expandStatus"
		#fdPanel
		(afterExpand)="handleAfterExpand()"
		(afterCollapse)="handleAfterCollapse()"
	>
		<h3 class="!m-0">New request</h3>
		<mat-form-field appearance="outline" class="add-new !my-4">
			<textarea
				matInput
				data-cy="fd-request-input"
				placeholder="What can we help you with?"
				[(ngModel)]="newRequest"
				[ngModelOptions]="{standalone: true}"
				#requestTextArea
			></textarea>
		</mat-form-field>

		<div>
			<div class="pull-right">
				<button mat-button color="primary" (click)="cancelComment()">Cancel</button>
				<button
					mat-flat-button
					color="primary"
					type="button"
					data-cy="fd-request-submit-btn"
					[disabled]="newRequest === ''"
					(click)="createNewRequest(newRequest)"
					class="!ml-3"
				>Send</button>
			</div>
		</div>

	</mat-expansion-panel>

	<div *ngIf="fundingDeskRequests && fundingDeskRequests.length > 0" class="requests">
		<app-single-request-chain
			*ngFor="let request of fundingDeskRequests; let i = index"
			[requestChain]="request"
			[requestIndex]="i"
			(comment)="addNewComment($event)"
		></app-single-request-chain>
	</div>
</div>
