import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';
import { AdverseActionNotice } from '../interfaces/adverse-action-notice.model';

const postUrl = `${environment.apiUrl}/l/v2/internal/deals/adverse-action-notice`;

@Injectable({
    providedIn: 'root'
})
export class AdverseActionNoticeService {
    constructor(private http: HttpClient) {}
    postAdverseActionNotice(dealId: number): Observable<AdverseActionNotice> {
        return this.http.post<AdverseActionNotice>(`${postUrl}`, {dealId});
    }
}
