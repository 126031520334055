import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth/auth.state';

export const lendioOnlyGuard: CanActivateFn = () => {
  const store = inject(Store);
  return (
    store.selectSnapshot(AuthState.user)!.email!.includes('@lendio.com')
  );
};
