import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Ensure users enter a full SSN when editing for an existing contact.
 *
 * We don't load the full SSN in the browser, just the last four. Users that
 * can add/update SSNs only see XXX-XX-1234. The first five digits are zeroes
 * when loaded from the server. If they modify the SSN (just last four), it's
 * sent to the server as 000001235.
 */
export function ssnValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // See if first three digits are 000 but only after a change (dirty).
    const invalidSsn = control.dirty && control.value.substr(0,3) == "000";
    // Add additional checks if you want here. If any fail, invalidSsn should
    // be true.
    return invalidSsn ? { invalidSsn: { value: control.value }} : null;
  };
}
