import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { DiscreteSliderComponent } from './discrete-slider.component';


@NgModule({
  declarations: [ DiscreteSliderComponent ],
  imports: [
    FormsModule,
    CommonModule,
    MatSliderModule,
  ],
  exports: [
    DiscreteSliderComponent,
  ]
})
export class DiscreteSliderModule { }
