import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewOfferSmartComponent } from './new-offer-smart/new-offer-smart.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NewOfferDialogComponent } from './new-offer-dialog/new-offer-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FactoringOfferFormComponent } from './forms/factoring-offer-form/factoring-offer-form.component';
import { OfferFormComponent } from './forms/offer-form/offer-form.component';
import { OfferLinkFormComponent } from './forms/offer-link-form/offer-link-form.component';
import { DocumentUploadModule } from '../document-upload/document-upload.module';
import { AlertModule } from '../alert/alert.module';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    NewOfferSmartComponent,
    NewOfferDialogComponent,
    FactoringOfferFormComponent,
    OfferFormComponent,
    OfferLinkFormComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatDialogModule,
        DocumentUploadModule,
        AlertModule,
        LoadersModule,
        MatIconModule
    ],
  exports: [
    NewOfferSmartComponent
  ]
})
export class NewOfferModule { }
