import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { RenewalsResponse } from '../interfaces/renewals-response.model';
import { RenewalsSearchResponse } from '../interfaces/renewals-search-response.model';

const baseUrl = `${environment.apiUrl}/lender-portal`;

@Injectable({
    providedIn: 'root'
})

export class RenewalsListService {

    constructor(private http: HttpClient) {}

    // eslint-disable-next-line max-len
    getRenewals(section: string = 'new-apps', sortBy: string = 'modified', sortDirection: string = 'desc', page: number = 0, limit: number = 25): Observable<RenewalsResponse> {
        const sort = sortBy + sortDirection;
        const assignmentsStorage = localStorage.getItem('assignmentFilters');
        const labelStorage = localStorage.getItem('labelFilters');
        const statusStorage = localStorage.getItem('statusFilters');
        const loanProductsStorage = localStorage.getItem('loanProductFilters');
        let assignmentsArray = [];
        let labelsArray = [];
        let statusArray = [];
        let loanProductsArray = [];

        if (assignmentsStorage) {
            assignmentsArray = JSON.parse(assignmentsStorage);
        }

        if (labelStorage) {
            labelsArray = JSON.parse(labelStorage);
        }

        if (statusStorage) {
            statusArray = JSON.parse(statusStorage);
        }

        if (loanProductsStorage) {
            loanProductsArray = JSON.parse(loanProductsStorage);
        }

        const assignments = JSON.stringify(assignmentsArray);
        const labels = JSON.stringify(labelsArray);
        const status = JSON.stringify(statusArray);
        const loanProducts = JSON.stringify(loanProductsArray);

        let url = `${baseUrl}/applications/${section}?sort=${sort}&page=${page}&limit=${limit}&assignedUsers=${assignments}&labels=${labels}&pppStatus=${status}&loanProducts=${loanProducts}&dealsType=renewal`;
        if (section === 'dead' || section === 'needs-review') {
            url = url + `&hasAan`
        }

        return this.http.get<RenewalsResponse>(url);
    }

    getTabData() {
        return this.http.get<any>(`${baseUrl}/tab-data?dealsType=renewal`);
    }

    searchApplications(term: string | number | boolean): Observable<RenewalsSearchResponse> {
        const url = `${environment.apiUrl}/l/v2/internal/borrowers/search`;

        return this.http.get<RenewalsSearchResponse>(url, {params: {name: encodeURIComponent(term)}});
    }

    bulkSbaSubmit(dealIds: number[]) {
        const url = `${environment.apiUrl}/deal/sba/submission`;
        return this.http.post(url, { dealIds });
    }
}
