import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportButtonSmartComponent } from './import-button/import-button-smart/import-button-smart.component';
import { ImportButtonComponent } from './import-button/import-button/import-button.component';
import { Routes, RouterModule } from '@angular/router';
import { DataListForgivenessSmartComponent } from './data-list-forgiveness/data-list-forgiveness-smart/data-list-forgiveness-smart.component';
import { DataListForgivenessComponent } from './data-list-forgiveness/data-list-forgiveness/data-list-forgiveness.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { ToDateObjPipeModule } from '../../pipes/toDateObj/to-date-obj-pipe.module';
import { ForgivenessImportPageComponent } from './forgiveness-import/forgiveness-import.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import {
  LendioPaginatorComponent
} from "@app/app/components/lendio-angular-material-theme/lendio-paginator/lendio-paginator.component";


const routes: Routes = [
  {
    path: 'forgive',
    component: ForgivenessImportPageComponent
  }
];

@NgModule({
  declarations: [
    ImportButtonSmartComponent,
    ImportButtonComponent,
    DataListForgivenessSmartComponent,
    DataListForgivenessComponent,
    ForgivenessImportPageComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    ToDateObjPipeModule,
    RouterModule.forChild(routes),
    LendioPaginatorComponent
  ]
})
export class CsvPageModule { }
