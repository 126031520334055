<app-upload-contract
	[dealId]="dealId"
	(newPPPContract)="uploadPPPContract($event)"
	(newPPPContractSave)="uploadPPPContract($event)"
	[loading]="loading$ | async"
	[sbaLoanNumber]="sbaLoanNumber"
	[approvedLoanAmount]="approvedLoanAmount"
	[expiration]="expiration"
	[localeCurrencySymbol]="localeCurrencySymbol">
</app-upload-contract>
