<span
	*ngIf="newContractRequested"
	class="text-green-600 mr-2 font-bold"
	[@fadeIn]>
	New Contract Queued
</span>
<div [class.old-theme-resend-contract-button]="oldThemeEnabled()">
<button
	mat-flat-button
	class="resend-contract-btn"
	(click)="resendContract()"
	[class.spinner]="pending"
	[disabled]="newContractRequested || pending">
	{{
		pending ? '&nbsp;' :
		dealStatus === 'offerAccepted' ? 'Send Contract' :
		'Resend Contract'
	}}
</button>
</div>
