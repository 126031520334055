<div class="lendio-density-1">
    <mat-form-field class="w-full"
                    appearance="outline"
                    floatLabel="always">
        <mat-label>Assigned To:</mat-label>
        <mat-select data-cy="assignment-dropdown-select"
                    placeholder="Unassigned"
                    [multiple]="true"
                    [value]="selection.selected"
                    (selectionChange)="selectionChanged($event)">
            @for (user of portalUsers; track user.id) {
                <mat-option data-cy="assignment-dropdown-option"
                            [value]="user.id">
                    {{ user.fullName }}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>
