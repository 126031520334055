<mat-card appearance="outlined" class="m-4">
    <div *ngIf="currentSbaToken" class="current-sba-token">
        <span class="font-semibold">Current SBA Token <em>(partially hidden for security)</em>:</span> {{ currentSbaToken }}
    </div>
    <mat-card-header>
        <mat-card-title>Enter New SBA Token</mat-card-title>
    </mat-card-header>
        <form [formGroup]="sbaTokenForm">
            <mat-card-content>
                <mat-form-field appearance="outline" floatLabel="always" class="w-1/2">
                    <mat-label>New SBA token</mat-label>
                    <input matInput type="text" required formControlName="sbaToken">
                </mat-form-field>
                <div class="flex-1" *ngIf="atLeastOneFieldFilledIn()">
                    <mat-error *ngIf="checkError('sbaToken', 'pattern')">The SBA Token entered does not match the required format</mat-error>
                    <mat-error *ngIf="checkError('sbaToken', 'required')">This field is required</mat-error>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <div class="flex-1 text-right">
                    <button mat-flat-button color="primary" (click)="submit()" type="submit" [class.spinner]="submitting" [disabled]="sbaTokenForm.invalid || sbaTokenForm.errors || submitting">
                        <span *ngIf="!submitting; else pending">Submit</span>
                        <ng-template #pending>&nbsp;</ng-template>
                    </button>
                </div>
            </mat-card-actions>
        </form>
</mat-card>
