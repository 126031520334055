import { NgModule } from '@angular/core';
import { PluckPipe } from '@app/app/pipes/pluck/pluck.pipe';

@NgModule({
  declarations: [
    PluckPipe,
  ],
  exports: [
    PluckPipe,
  ]
})
export class PluckPipeModule { }
