import { NgModule } from '@angular/core';
import {StatusDisplayPipe} from './status-display';

@NgModule({
    imports: [],
    exports: [StatusDisplayPipe],
    declarations: [StatusDisplayPipe],
    providers: [],
})
export class StatusDisplayModule { }
