import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { PortalUser } from '@app/app/interfaces/portal-user.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Store } from '@ngxs/store';
import { AssignUserToDeal, UnassignUserFromDeal } from '@app/app/store/application-details/application-details.actions';

@Component({
  selector: 'app-assignment-dropdown',
  templateUrl: './assignment-dropdown.component.html',
  styleUrls: ['./assignment-dropdown.component.scss']
})
export class AssignmentDropdownComponent implements OnInit {

  @Input() dealId: number;
  @Input() portalUsers: PortalUser[];
  @Input() usersAssigned: number[] = [];
  selection: SelectionModel<number>;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.selection = new SelectionModel(true, this.usersAssigned);
  }

  /**
   * Assign/unassign a lender user on this deal.
   *
   * While we're dealing with lists of assign/unassigned users, this will
   * only assign/unassign one user each time a checkbox is clicked.
   *
   * @param value Array of selected user ids
   */
  selectionChanged( { value }: MatSelectChange ) {
    const userToUnassign = this.usersAssigned.filter((id) => !value.includes(id));
    if (userToUnassign.length) {
      const idIndex = this.usersAssigned.indexOf(userToUnassign[0]);
      this.usersAssigned.splice(idIndex, 1);
      this.store.dispatch(new UnassignUserFromDeal(this.dealId, userToUnassign[0]));
    }

    // Check value for users not in usersAssigned to assign.
    const userToAssign = value.filter((id: number) => !this.usersAssigned.includes(id));
    if (userToAssign.length) {
      this.usersAssigned.push(userToAssign[0]);
      this.store.dispatch(new AssignUserToDeal(this.dealId, userToAssign[0]));
    }
  }
}
