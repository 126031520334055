<button class="new-theme-typography early-access-select-button" (click)="handleClick(FeatureToggle)">
    <div class="early-access-selection" [class.last]="last" [class.locked]="autoEnrolled && (feature.alias !== autoEnrollmentAlias)">
        <div class="flex flex-row justify-between items-center">
            <mat-checkbox #FeatureToggle [disabled]="autoEnrolled && (feature.alias !== autoEnrollmentAlias)" [checked]="!feature.disabled" (toggle)="handleFeatureChange($event)" (change)="handleFeatureChange($event)" (click)="$event.stopPropagation();">
                <span class="early-access-checkbox-label">{{ feature.heading }}</span>
            </mat-checkbox>
            @if(!feature.userEnrollment || feature.alias === autoEnrollmentAlias) {
                <app-early-access-label [featureType]="feature.type"></app-early-access-label>
            }
        </div>
        <div class="early-access-feature-subtexts">
            @for (subtext of feature.subtexts; track subtext) {
                <div class="early-access-subtext-wrapper">
                    <div class="early-access-subtext">
                        {{ subtext }}
                    </div>
                </div>
            }
        </div>
    </div>
</button>
