<ng-container *ngIf="clientName; else noSelection">
<div class="borrower-drawer-details" (click)="$event.stopPropagation(); $event.preventDefault()">
    <div class="borrower-name-and-image">
        <mat-icon color="primary" class="borrower-icon">account_box</mat-icon>
        <div class="borrower-name">
            {{ clientName }}
        </div>
    </div>
    <div class="borrower-status-container">
        <div class="borrower-drawer-section-title" *ngIf="viewState === 'borrower' && statusSteps.length">Application Status</div>
        <div class="steps" *ngFor="let step of statusSteps">
            <div class="step-container">
                <div class="status-image-container">
                    <mat-icon class="status-icon" *ngIf="step.status === 'complete'" color="primary">check_circle</mat-icon>
                    <mat-icon class="status-icon" *ngIf="step.status === 'error'" color="warn">error_icon</mat-icon>
                    <img class="mat-icon-status-image" *ngIf="step.status === 'in-progress'" src="assets/images/blue-empty-circle.svg">
                    <img class="mat-icon-status-image" *ngIf="step.status === 'funded'" src="assets/images/celebrate.svg">
                </div>
                <div class="borrower-checklist-item" [ngClass]="step.status">
                    <div class="step-always-showing-data">
                        <div class="step-title">
                            {{ step.name }}
                        </div>
                        <div *ngIf="step.completedDate" class="step-date">
                            {{ step.completedDate * 1000 | date: 'MM/dd/yyyy' }}
                        </div>
                        <div *ngIf="step.status === 'in-progress' && !step.errorMessage" class="step-date">
                            In Progress
                        </div>
                        <div *ngIf="step.errorMessage" class="step-error-message">
                            {{ step.errorMessage }}
                        </div>
                    </div>
                    <div *ngIf="step.description && (step.status === 'funded' || step.status === 'not-started')" class="step-description">
                        {{ step.description }}
                    </div>
                    <div *ngIf="step.status === 'in-progress' || step.status === 'not-started'">
                        <div class="sub-step" *ngFor="let subStep of step.subSteps; last as lastStep">
                            <div class="sub-step-image-and-line">
                                <img class="sub-status-image" *ngIf="subStep.isComplete && step.status === 'in-progress'" src="assets/images/blue-checkmark.svg">
                                <img class="sub-status-image" *ngIf="!subStep.isComplete && step.status === 'in-progress'" src="assets/images/blue-empty-circle.svg">
                                <img class="sub-status-image" *ngIf="step.status === 'not-started'" src="assets/images/grey-empty-circle.svg">
                                <div *ngIf="!lastStep" class="sub-status-vertical-line"></div>
                            </div>
                            <div class="sub-step-description">
                                {{ subStep.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>
<ng-template #noSelection>
        <div class="borrower-status-container no-selection">
            <span>Please Select a row to view application status</span>
        </div>
</ng-template>
