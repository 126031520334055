import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-borrower-status-indicator',
  templateUrl: './borrower-status-indicator.component.html',
  styleUrls: ['./borrower-status-indicator.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorrowerStatusIndicatorComponent {
  @Input() stage: string;
  @Input() status: string;

  formatForDisplay(words: string) {
    return words.replace(/([a-z](?=[A-Z]))/g, '$1 ');
  }
}
