import { State, Action, StateContext, Selector, createSelector, Store, NgxsOnInit } from '@ngxs/store';
import { DeletePathFilter, InitFilters, SetPathFilter, UpdatePathFilters } from './filters.actions';
import { Injectable } from '@angular/core';
import { FiltersStateModel } from '@app/app/interfaces/filters.model';
import { FilterService } from '@app/app/services/filters.service';

@State<FiltersStateModel>({
    name: 'pathFilters',
    defaults: {
        filters: []
    }
})
@Injectable()

export class FiltersState implements NgxsOnInit {

    constructor(
        private _filterService: FilterService,
        private store: Store
    ) {}

    ngxsOnInit(ctx: StateContext<FiltersState>) {
        ctx.dispatch(new InitFilters(window.location.pathname));
    }

    @Selector()
    static filters(state: FiltersStateModel) {
        return state.filters;
    }

    @Selector()
    static getFiltersByPath(path: string) {
        return createSelector([FiltersState], (state: FiltersStateModel) => {
            return state['pathFilters'].filters.filter(fs => fs.path === path);
        });
    }

    @Action(InitFilters)
    initFilters(ctx: StateContext<FiltersStateModel>, { path }: InitFilters) {
        let curFiltersByPath = ctx.getState().filters.filter(fs => fs.path === path);
        if(!curFiltersByPath || curFiltersByPath.length < 1) {
            let storedFilters = this._filterService.getFiltersByPath(path);
            if(storedFilters && storedFilters.length > 0) {
                this.store.dispatch(new UpdatePathFilters(storedFilters));
            }
        }
    }

    @Action(DeletePathFilter)
    deletePathFilter(ctx: StateContext<FiltersStateModel>, { filter }: DeletePathFilter) {
        const currentFilters = ctx.getState().filters;
        let filters = currentFilters.filter((x: { path: string, name: string }) => (x.path !== filter.path && x.name !== filter.name));
        ctx.patchState({ filters });
        this._filterService.deletePathFilter(filter);
    }

    @Action(SetPathFilter)
    setPathFilter(ctx: StateContext<FiltersStateModel>, { filter }: SetPathFilter) {
        const currentFilters = ctx.getState().filters;
        const currentFilterIndex = currentFilters.findIndex((x: { path: string, name: string }) => (x.path === filter.path && x.name === filter.name ));
        const filters = [
            ...currentFilters.slice(0, currentFilterIndex),
            filter,
            ...currentFilters.slice(currentFilterIndex + 1),
        ];
        ctx.patchState({ filters });
        this._filterService.setPathFilter(filter);
        return filter;
    }

    @Action(UpdatePathFilters)
    updateFiltersStore(ctx: StateContext<FiltersStateModel>, { payload }: UpdatePathFilters) {
        return payload.map(filter => {
            return ctx.dispatch(new SetPathFilter(filter));
        });
    }
}
