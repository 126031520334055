import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment-timezone';

import { Notification } from 'src/app/interfaces/notification.model';

@Component({
  selector: 'app-notification-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss', '../../styles.temp.scss']
})
export class ItemComponent implements OnInit {
  @Input() notification: Notification;
  @Input() deleting: boolean;
  @Input() togglingRead: boolean;

  @Output() toggleRead = new EventEmitter();
  @Output() delete = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
  }

  itemDelete() {
    if (!this.deleting) {
      this.delete.emit();
    }
  }

  itemToggleReadAndRedirect() {
    if (!this.togglingRead) {
      this.toggleRead.emit(true);
    }
  }

  itemToggleRead() {
    if (!this.togglingRead) {
      this.toggleRead.emit(false);
    }
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }

}
