<div class="grid pb-2" *appLet="(creditReport$ | async) as creditReport">
    <div [ngClass]="{'opacity-[.3]': !creditReport?.creditScore}">
        <div class="grid grid-cols-5">
            <div *ngIf="!!creditReport?.creditScore else notPulledLabel" class="grid justify-center" [style]="{'grid-column': creditRangeIndex$ | async }">
                <div data-cy="credit-score" class="mat-headline-6 !mb-0">
                    {{creditReport?.creditScore}}
                </div>
                <div class="text-center">
                    <mat-icon class="material-symbols-outlined icon-rotate-90">
                        play_arrow
                    </mat-icon>
                </div>
            </div>
            <ng-template #notPulledLabel>
                <div class="grid grid-flow-col place-items-center h-[63.5px]">
                    <span class="text-lendio-cool-gray-300 font-normal">Not pulled</span>
                </div>
            </ng-template>
        </div>
        <div class="grid grid-cols-5">
            <ng-container *ngFor="let increment of creditScoresRangeIncrements">
                <section class="text-center mat-label-small-bold">
                    <div class="h-4 mb-3 {{increment.colorClass}}"></div>
                    <div class="">{{increment.label}}</div>
                    <div class="text-lendio-cool-gray-300">
                        {{increment.low}} - {{increment.high}}
                    </div>
                </section>
            </ng-container>
        </div>
    </div>
    <div *appLet="(financesStateLoading$ | async) as financesLoading"
        class="grid grid-cols-[1fr_auto_auto] gap-4 mt-10 place-items-center lendio-density-1">
        <div class="mat-caption text-lendio-cool-gray-300 w-full">Last Pull - {{creditReport?.reportDate | date:'MMM dd, yyyy'}}</div>
        <a *ngIf="!!creditReport?.creditScore"
           mat-stroked-button
           target="_blank"
           rel="noopener noreferrer"
           (click)="viewReport()"
           [disabled]="!(creditReportDocument$ | async) || financesLoading">
            View report
        </a>
        <button mat-flat-button
                [color]="'primary'"
                [disabled]="!(canPullCredit$ | async)"
                (click)="doPullCredit()">
            Pull credit
        </button>
    </div>
</div>
