import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DashboardResponse } from '../interfaces/dashboard-response.model';


@Injectable({
    providedIn: 'root'
})
export class LenderStatisticsService {
    private url = environment.apiUrl + '/l/v2/internal/dashboard';

    constructor(private httpClient: HttpClient) {}

    getLenderStatistics(): Observable<DashboardResponse> {
        return this.httpClient.get<DashboardResponse>(this.url);
    }
}
