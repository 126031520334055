<div [class.old-theme-lead-submission]="oldThemeEnabled()">
    <div *ngIf="submissionState == 'form'">
        <div class="flex justify-between" >
            <h2 mat-dialog-title class="!px-6 !pt-3 !mb-0" data-cy="decline-modal-header"><strong>New Lead</strong></h2>
            <button class="h-1 text-lg p-5" (click)="setState('cancel')"><mat-icon>close</mat-icon></button>
        </div>
        <app-lead-submission-form (state)="setState($event)"></app-lead-submission-form>
    </div>
    <div *ngIf="submissionState == 'cancel'">
        <h2 mat-dialog-title class="!px-6 !pt-3" data-cy="decline-modal-header">Attention <mat-icon class="status-icon !my-auto" color="warn">error_icon</mat-icon></h2>
        <mat-dialog-content>
            <p>Are you sure you would like to cancel this new lead submission?</p>
        </mat-dialog-content>
        <mat-dialog-actions align="start">
            <button mat-stroked-button class="lead-submit-button !mb-2 !px-6" color="primary" (click)="setState('form')">No</button>
            <button mat-raised-button class="lead-submit-button !ml-3 !mb-2 !px-6" color="primary" mat-dialog-close>Yes</button>
        </mat-dialog-actions>
    </div>
    <div *ngIf="submissionState == 'success'">
        <div class="flex justify-between">
            <h2 mat-dialog-title class="!px-6 !pt-3 !mb-0" data-cy="success-modal-header">Submission Successful!</h2>
            <button mat-dialog-close class="h-1 text-lg p-5"><mat-icon>close</mat-icon></button>
        </div>
        <mat-dialog-content>
            <p>We have successfully received your client's business details. Check back shortly to review the leads you have submitted once ownership is confirmed. Please refer to your partnership agreement for ownership terms and conditions.</p>
            <img class="mat-icon-success-image mx-auto !pb-3" src="assets/images/success-submission-illustration.svg">
        </mat-dialog-content>
    </div>
</div>
