// Typed as any because the return type is raw JS and Typescript doesn't understand that it's extendable
import Quill from 'quill';
const BlockBlotBase = Quill.import('blots/block') as any;

/**
 * Parchment Blot class used to register the default element
 * used for content in the Quill editor - by registering this class with
 * Quill we can add our theme typography classes to the elements that make
 * up the HTML of the Quill editor display
 */
export class LendioDivBlotBlock extends BlockBlotBase {
  static blotName = 'lendioDivBlotBlock';
  static tagName = 'div';
  static create(){
    let node = super.create();
    node.setAttribute('class','mat-body-2');
    return node;
  }
}
