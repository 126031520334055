<div [class.old-theme-decline-dialog]="oldThemeEnabled()">
	<h2 mat-dialog-title class="!p-4" data-cy="decline-modal-header">Decline Application</h2>
	<mat-dialog-content>
		<div class="loading" *ngIf="loading; else ready">
			<app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
		</div>
		<ng-template #ready>
			<div class="my-4">Please tell us why you're declining this application.</div>
			<mat-form-field
				class="w-full"
				appearance="outline"
				[floatLabel]="'always'"
			>
				<mat-label>Decline Reason</mat-label>
				<mat-select
					placeholder="Select reason(s) for decline"
					[formControl]="declineReasonSelect"
					multiple
					[(value)]="selectedReasons"
					data-cy="decline-reason-select">
					<mat-option
						*ngFor="let reason of declineReasons; let i = index"
						[value]="reason.reason"
						attr.data-cy="reason-{{i}}"
					>{{ reason.reason }}</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="my-4">Additional Notes</div>
			<mat-form-field class="decline-comment-text w-full" appearance="fill">
				<textarea
					rows="5"
					matInput
					data-cy="declineComment"
					placeholder="Notes"
					[(ngModel)]="comment"
					[ngModelOptions]="{standalone: true}"
					data-cy="additional-notes-input"
				></textarea>
			</mat-form-field>
		</ng-template>
	</mat-dialog-content>
	<mat-divider class="!mt-4"></mat-divider>
	<mat-dialog-actions align="end">
		<button mat-stroked-button class="!mt-4" mat-dialog-close>Cancel</button>
		<button
			mat-flat-button
			color="primary"
			class="!ml-3 !mt-4"
			[disabled]="(selectedReasons && selectedReasons.length === 0) || loading"
			(click)="submit()"
			[class.spinner]="loading"
			data-cy="decline-app-submit-btn">
			{{ loading ? '&nbsp;' : 'Confirm' }}
		</button>
	</mat-dialog-actions>
</div>
