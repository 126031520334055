import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ContractRequestsService } from 'src/app/services/contract-requests.service';
import { GetContractRequestOffers, ClearContractRequestStore } from './contract-requests.actions';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';

export class  ContractRequestsStateModel {
    contractRequests: any[];
    loading: boolean;
}

@State<ContractRequestsStateModel>({
    name: 'contractRequests',
    defaults: {
        contractRequests: [],
        loading: false
    }
})
@Injectable()

export class ContractRequestsState
{
    @Selector() static contractRequests(state: ContractRequestsStateModel) {
        return state.contractRequests;
    }

    @Selector() static loading(state: ContractRequestsStateModel) {
        return state.loading;
    }

    constructor(
        private contractRequestsService: ContractRequestsService,
        private store: Store
    ) {}

    @Action(GetContractRequestOffers)
    GetContractRequestOffers({ patchState }: StateContext<ContractRequestsStateModel>, { dealId }: GetContractRequestOffers) {
        return this.contractRequestsService.getContractRequestOffers(dealId).pipe(catchError((err: any) => {
            this.store.dispatch(new CreateNewAlert({
                level: 'error',
                message: 'Unable to retrieve contract requests. Please refresh the page to try again.'
            }));
            return throwError(err);
        }), tap((response: any) => {
            patchState({ contractRequests: response.data });
        }));
    }

    @Action(ClearContractRequestStore)
    clearContractRequestsStore({ patchState }: StateContext<ContractRequestsStateModel>, {}: ClearContractRequestStore) {
        patchState({contractRequests: undefined});
    }

}
