import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  computed
} from '@angular/core';
import {
    trigger,
    transition,
    useAnimation,
    group,
    style,
    animate
} from '@angular/animations';
import { slideOutUp, fadeOut } from 'ng-animate';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";
import { Observable } from "rxjs";

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger(
            'animation', [
                transition(
                    ':enter', [
                        style({
                            overflow: 'hidden',
                            height: '0px',
                        }),
                        animate('0.3s ease-in', style({ height: '*' }))
                    ]
                ),
                transition(
                    ':leave', [
                        style({
                            overflow: 'hidden',
                            height: '*'
                        }),
                        group([
                            animate('0.3s 0.3s ease-out', style({ height: '0px' })),
                            useAnimation(slideOutUp, { params: { timing: 0.3 } }),
                            useAnimation(fadeOut, { params: { timing: 0.3 } }),
                        ])
                    ]
                )
            ]
        )
    ]
})
export class AlertComponent implements OnInit, OnDestroy {

    @Input() level: 'success' | 'error' | 'warning' | 'info' = 'info';
    @Input() message: string;
    @Input() dismissDelay: number;
    @Input() dismissible = false;
    @Input() isAan: boolean;
    @Input() aanDate: string;
    @Input() showIcon: boolean = true;
    @Output() dismissed = new EventEmitter<void>();

    newThemeActive$: Observable<boolean>;

    isOpen = false;
    timeout: any;
    icons = {
        success: 'check-square',
        error: 'bomb',
        warning: 'exclamation-triangle',
        info: 'info-circle',
    };

    oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

    constructor(private cdRef: ChangeDetectorRef, private _themeService: LendioAngularMaterialThemeService) { }

    ngOnInit() {
        if (this.dismissDelay) {
            this.timeout = setTimeout(() => {
                this.dismiss();
            }, this.dismissDelay);
        }

        setTimeout(() => {
            this.isOpen = true;
            this.cdRef.markForCheck();
        }, 300);
    }

    get buttonTheme(): string {
      return this.level === 'error'
        ? 'warn'
        : '';
    }

    ngOnDestroy() {
        clearTimeout(this.timeout);
    }

    dismiss() {
        this.isOpen = false;
        this.cdRef.markForCheck();
    }

    onAnimationEvent() {
        if (!this.isOpen) {
            this.dismissed.emit();
        }
    }
}
