import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Breadcrumb } from '../interfaces/breadcrumb.model';

@Injectable({
  providedIn: 'root'
})


// This service will allow you to pass in an array of Breadcrumb object(s) to build a Breadcrumbs path
// e.g. { path: '/administration/imports', label: 'Administration' }
// The breadcrumbs will persist unless you set the breadcrumbs to an empty array in the components onDestroy method
// e.g. this.breadcrumbService.setBreadcrumbs([]);
// see import-details.component.ts to see it in practice.

export class BreadcrumbService {
  private _breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs$ = this._breadcrumbs.asObservable();

  constructor() { }

  setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    let _crumbs: Breadcrumb[] = [];
    breadcrumbs.forEach(breadcrumbs => {
      _crumbs.push({ path: breadcrumbs.path, label: breadcrumbs.label });
    });

    this._breadcrumbs.next(_crumbs);
  }

  getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.breadcrumbs$;
  }
}
