import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { LoanProductResponse } from '../interfaces/loan-product-response.model';
import { QuestionsResponse } from '../interfaces/questions-response.model';
import { RequiredInfoResponse } from '../interfaces/required-information.model';
import { StatesResponse } from '../interfaces/states-response.model';
import {QualifierExceptionGroupResponse} from 'src/app/interfaces/qualifier-exception-group-response.model';
import { LoanProductQuestion } from '../interfaces/loan-product-question.model';
import { RemoveRequirementPayload } from '../components/loan-product/required-information/required-information.component';
import {Qualifier} from "src/app/interfaces/qualifier.model";
import {QualifierUnsaved} from "src/app/interfaces/qualifier-unsaved.model";

@Injectable({
  providedIn: 'root'
})
export class LoanProductService {

  constructor(private http: HttpClient) {
  }

  getLoanProductInfo(loanProductId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/l/v2/internal/loan-product/${loanProductId}`);
  }

  getLoanProduct(loanProductId: number): Observable<LoanProductResponse> {
    return this.http.get<LoanProductResponse>(`${environment.apiUrl}/matching/loan-product/${loanProductId}`);
  }

  getLoanProductCategory(loanProductCategoryId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/loan-product-category/${loanProductCategoryId}`);
  }

  getQuestions(): Observable<QuestionsResponse> {
    return this.http.get<QuestionsResponse>(`${environment.apiUrl}/matching/questions`);
  }

  getRequiredInformation(section: string): Observable<RequiredInfoResponse> {
    return this.http.get<RequiredInfoResponse>(`${environment.apiUrl}/matching/loan-product-questions/required/${section}`);
  }

  getStates(): Observable<StatesResponse> {
    return this.http.get<StatesResponse>(`${environment.bpApiUrl}/state`);
  }

  patchLoanProduct(loanProductId: number, payload: object): Observable<any> {
    return this.http.patch<any>(`${environment.apiUrl}/loan-product/${loanProductId}`, payload);
  }

  putQuestions(loanProductId: number, payload: LoanProductQuestion[]): Observable<any> {
    return this.http.put(`${environment.apiUrl}/matching/loan-product/${loanProductId}/questions`, payload);
  }

  deleteQuestions(loanProductId: number, payload: RemoveRequirementPayload): Observable<any> {
    return this.http.put(`${environment.apiUrl}/matching/loan-product/${loanProductId}/delete-questions`, payload);
  }

  postQualifierExceptionGroup(lenderId: number, loanProductId: number, name?: string): Observable<QualifierExceptionGroupResponse> {
    return this.http.post<QualifierExceptionGroupResponse>(
      `${environment.apiUrl}/matching/qualifiers/exception-groups`,
      {
        lenderId,
        loanProductId,
        name
      }
    );
  }

  putQualifierExceptionGroup(payload: { name: any; }, exceptionGroupId: number): Observable<any> {
    return this.http.put(`${environment.apiUrl}/matching/qualifiers/exception-groups/${exceptionGroupId}`, payload);
  }

  deleteQualifierExceptionGroup(groupId: number): Observable<any> {
    return this.http.delete( `${environment.apiUrl}/matching/qualifiers/exception-groups/${groupId}`);
  }

  postQualifier(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/matching/qualifiers/`, payload);
  }

  putQualifier(payload: Qualifier | QualifierUnsaved): Observable<any> {
    return this.http.put(`${environment.apiUrl}/matching/qualifiers/${payload.id}`, payload);
  }

  deleteQualifier(qualifierId: number): Observable<any> {
    return this.http.delete( `${environment.apiUrl}/matching/qualifiers/${qualifierId}`);
  }
}
