import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotLoaderComponent } from './dot-loader/dot-loader.component';
import { HorizontalLoaderComponent } from './horizontal-loader/horizontal-loader.component';
import {DotLoaderScaleComponent} from "@app/app/components/loaders/dot-loader-scale/dot-loader-scale.component";



@NgModule({
  declarations: [
    DotLoaderComponent,
    DotLoaderScaleComponent,
    HorizontalLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DotLoaderComponent,
    DotLoaderScaleComponent,
    HorizontalLoaderComponent
  ]
})
export class LoadersModule { }
