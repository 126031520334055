import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { ContactsActions as CA } from '../../../store/contacts/contacts.actions';
import { ContactsState } from '@app/app/store/contacts/contacts.state';
import { Observable } from 'rxjs';
import { Contact } from 'src/app/interfaces/contact.model';

@Component({
  selector: 'app-owners-smart',
  templateUrl: './owners-smart.component.html',
  styleUrls: ['./owners-smart.component.scss']
})
export class OwnersSmartComponent implements OnInit {

  // @Select(ContactsState.contacts) contacts$: Observable<Contact[]>;
  contacts$: Observable<Contact[] | null> = this.store.select(ContactsState.contacts);
  @Input() dealId: number;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new CA.GetIndexByDeal(this.dealId));
  }
}
