<div mat-dialog-title>Record data</div>
<div mat-dialog-content>
    <div class="grid grid-cols-3 grid-rows-3 grid-flow-row gap-4">
        <div
            class="justify-between items-center overflow-y-visible break-words"
            *ngFor="let item of data | keyvalue"
        >
            <div class="font-bold">{{ item.key }}</div>
            <div>{{ item.value || '-' }}</div>
        </div>
    </div>
</div>
    <mat-divider class="!mt-4"></mat-divider>
    <div mat-dialog-actions class="!pt-4" [align]="'end'">
        <button tabindex="-1" mat-stroked-button mat-dialog-close color="accent">Close</button>
    </div>
