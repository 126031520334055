@if (isOpen) {
    <div @animation
         (@animation.done)="onAnimationEvent()"
         data-cy="lendio-alert">
        <mat-card appearance="outlined"
                  [class.old-theme-alert]="oldThemeEnabled()"
                  [ngClass]="[
                '!py-2',
                '!px-4',
                'lendio-alert',
                'lendio-alert--' + level
              ]">
            @if (showIcon) {
                <i class="lendio-alert__icon fa fa-lg fa-{{ icons[level] }}"></i>
            }
            <div class="lendio-alert__message">
                <div [innerHtml]="message"></div>
                <div *ngIf="isAan">{{ aanDate | date:'MM/dd/yyyy, h:mm a' }}</div>
            </div>
            @if (dismissible) {
                <div class="lendio-density-1 lendio-alert__dismiss">
                    <button mat-icon-button (click)="dismiss()" [color]="buttonTheme">
                        <mat-icon [color]="buttonTheme">close</mat-icon>
                    </button>
                </div>
            }
        </mat-card>
    </div>
}
