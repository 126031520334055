export class GetDealNotes {
  public static type = '[DealNotes] GetDealNotes';
  constructor(
    public dealId: number,
  ) {}
}

export class AddDealNote {
  public static type = '[DealNotes] AddDealNote';
  constructor(
    public dealId: number,
    public content: string,
  ) {}
}

export class ClearDealNotesStore {
  public static type = '[DealNotes] ClearDealNotesStore';
  constructor() {}
}
