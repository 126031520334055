import { Component, OnInit, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GetFundingDeskRequests, CreateFundingDeskRequest, CreateFundingDeskRequestComment } from 'src/app/store/funding-desk/funding-desk-requests.actions';
import { Observable } from 'rxjs';
import { FundingDeskRequest } from 'src/app/interfaces/funding-desk-request.model';
import { FundingDeskRequestsState } from 'src/app/store/funding-desk/funding-desk-requests.state';

@Component({
  selector: 'app-funding-desk-smart',
  templateUrl: './funding-desk-smart.component.html',
  styleUrls: ['./funding-desk-smart.component.scss']
})
export class FundingDeskSmartComponent implements OnInit {

  // @Select(FundingDeskRequestsState.fundingDeskRequests) fundingDeskRequests$: Observable<FundingDeskRequest[]>;
  fundingDeskRequests$: Observable<FundingDeskRequest[]> = this.store.select(FundingDeskRequestsState.fundingDeskRequests);

  @Input() dealId: number;
  @Input() newTheme: boolean;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new GetFundingDeskRequests(this.dealId));
  }

  createNewRequest(request: { description: string, type: string }) {
    this.store.dispatch(new CreateFundingDeskRequest(this.dealId, request.description, request.type));
  }

  createNewComment(comment: { description: string, requestId: number }) {
    this.store.dispatch(new CreateFundingDeskRequestComment(this.dealId, comment.requestId, comment.description));
  }
}
