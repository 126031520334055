import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "@app/environments/environment";
import {Observable} from "rxjs";
import {LoginResponse} from "./login-response";

export interface IdentityServiceResponse {
  status: string;
  statusCode: number;
  data?: any | MfaResponseData;
  errors: string[];
}

interface MfaResponseData {
  status: string,
  factorsAllowed: string[],
  enrolledFactors: string[],
  transactionId: string,
  mfaSuccessLifespan?: number,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  identityUrl: string = environment.identityUrl;
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  identityLogin(email: string, password: string): Observable<IdentityServiceResponse> {
    return this.http.post<IdentityServiceResponse>(`${this.identityUrl}/auth/sign-in`, {
      identifier: email,
      password,
      tenantId: 1,
    });
  }

  identityLogout(): Observable<IdentityServiceResponse> {
    return this.http.post<IdentityServiceResponse>(`${environment.identityUrl}/logout`, {});
  }

  getUser(): Observable<LoginResponse> {
    return this.http.get<LoginResponse>(`${this.apiUrl}/lpauthorization/user`);
  }

  resetPassword(email: string, token: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.apiUrl}/lender-portal/reset-password`, {email, token, password});
  }

  hijack(userId: number): Observable<LoginResponse> {
    return this.http.get<LoginResponse>(`${this.apiUrl}/l/v2/internal/lpauthorization/hijack/${userId}`);
  }

  forgotPassword(email: string) {
    return this.http.post(`${this.apiUrl}/lender-portal/forgot-password`, {email});
  }

  mfaChallenge(transactionId: string, resend = false): Observable<IdentityServiceResponse> {
    const url = resend
      ? `${environment.identityUrl}/auth/transaction/challenge/email?resend=true`
      : `${environment.identityUrl}/auth/transaction/challenge/email`;
    return this.http.post<IdentityServiceResponse>(url, {transactionId});
  }

  verifyMfaCode(verificationData: {code: string, transactionId: string, rememberDevice: boolean}): Observable<IdentityServiceResponse> {
    return this.http.post<IdentityServiceResponse>(`${environment.identityUrl}/auth/transaction/verify/email`, verificationData);
  }

  resetExpiredPassword(passwordData: {oldPassword: string, newPassword: string, confirmPassword: string, transactionId: string}): Observable<IdentityServiceResponse> {
    return this.http.post<IdentityServiceResponse>(`${environment.identityUrl}/auth/transaction/reset-expired-password`, passwordData);
  }
}
