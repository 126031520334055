import { Integration, IntegrationActivity } from '@app/app/interfaces/integration.model';
import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { IntegrationsService } from '@app/app/services/integrations.service';
import { catchError, tap, throwError } from 'rxjs';
import { GetIntegrationActivities, GetIntegrationDetail } from '@app/app/store/integrations/integrations.actions';
import {
  LendioPaginationAttributes
} from '@app/app/components/lendio-angular-material-theme/lendio-paginator/lendio-paginator.interface';
import { get, pick } from 'lodash';
import { AddSnackbarError } from '@app/app/store/snackbar/snackbar.actions';

export interface IntegrationActivitiesStateModel extends LendioPaginationAttributes {
  integration: Integration;
  activities: IntegrationActivity[];
  currentPage: number;
  pageSize: number;
  pageSizeOptions: number[];
  integrationLoading: boolean;
  activitiesLoading: boolean;
}

@State<Partial<IntegrationActivitiesStateModel>> ({
  name: 'IntegrationActivities',
  defaults: {
    pageSize: 15,
    currentPage: 1,
    total: 0,
    pageSizeOptions: [15, 25, 50],
    activitiesLoading: false,
  }
})
@Injectable()
export class IntegrationActivitiesState {

  @Selector()
  static integration(state: IntegrationActivitiesStateModel) {
    return state.integration;
  }

  @Selector()
  static activities(state: IntegrationActivitiesStateModel) {
    return state.activities;
  }

  @Selector()
  static selectorFromKey(key: keyof IntegrationActivitiesStateModel) {
    return createSelector([this], ({IntegrationActivities}) => {
      return get(IntegrationActivities, key);
    });
  }

  @Selector()
  static pagination(state: IntegrationActivitiesStateModel) {
    return pick(state, [
        'currentPage',
        'from',
        'to',
        'total',
        'last_page',
        'per_page',
        'next_page_url',
        'prev_page_url',
      ]);
  }

  constructor(
    private integrationsService: IntegrationsService,
    private store: Store,
  ) {}

  @Action( GetIntegrationDetail )
  GetIntegrationDetail(
    ctx: StateContext<IntegrationActivitiesStateModel>,
    { customerIntegrationId }: GetIntegrationActivities
  ) {
    const prePatch = customerIntegrationId === ctx.getState()?.integration?.customerIntegrationId
      ? { integrationLoading: true }
      : {
        integrationLoading: true,
        activities: [],
        currentPage: 1,
      };
    ctx.patchState(prePatch);

    return this.integrationsService.getIntegrationDetail(customerIntegrationId)
      .pipe(
        catchError( e => {
          ctx.patchState({
            integrationLoading: false,
          });
          this.store.dispatch( new AddSnackbarError({
            subTitle: 'Error fetching Integration details',
          }));
          return throwError(e);
        }),
        tap( response => {
          ctx.patchState({
            integration: response.data as Integration,
            integrationLoading: false,
          })
        })
      );
  }

  @Action( GetIntegrationActivities )
  GetIntegrationActivities(
    ctx: StateContext<IntegrationActivitiesStateModel>,
    { customerIntegrationId, currentPage, pageSize,  shouldReloadOnFetch = false }: GetIntegrationActivities
  ) {
    if (shouldReloadOnFetch) {
      ctx.patchState({
        activitiesLoading: true,
      });
    } else {
      ctx.patchState({
        activitiesLoading: false,
      });
    }

    return this.integrationsService.getIntegrationActivities(customerIntegrationId, currentPage, pageSize)
      .pipe(
        catchError( e => {
          ctx.patchState({
            activitiesLoading: false,
          });
          this.store.dispatch( new AddSnackbarError({
            subTitle: 'Error fetching Integration activities',
          }));
          return throwError(e);
        }),
        tap( response => {
          const { current_page, from, to, total, last_page, per_page, next_page_url, prev_page_url } = response.data;
          ctx.patchState({
            activities: response.data.data,
            currentPage: current_page,
            from,
            to,
            total,
            last_page,
            per_page,
            next_page_url,
            prev_page_url,
            activitiesLoading: false,
          })
        })
      );
  }
}
