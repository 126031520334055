import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadSubmissionButtonComponent } from './lead-submission-button/lead-submission-button.component';
import { LeadSubmissionDialogComponent } from './lead-submission-dialog/lead-submission-dialog.component';
import { LeadSubmissionFormComponent } from './lead-submission-form/lead-submission-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AlertModule } from '../alert/alert.module';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    LeadSubmissionButtonComponent,
    LeadSubmissionDialogComponent,
    LeadSubmissionFormComponent
  ],
  imports: [
    AlertModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    LoadersModule
  ],
  exports: [
    LeadSubmissionButtonComponent
  ]
})
export class LeadSubmissionModule { }
