<div class="new-theme-typography relative">
<div mat-dialog-title>Confirm accepted offer details</div>
    <button mat-icon-button
            class="!absolute !top-5 !right-4"
            (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <div mat-dialog-content>
        <ng-container *ngIf="_markAcceptedClicked; else offersList">
            <div class="py-10">
                <app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
            </div>
        </ng-container>

        <ng-template #offersList>
            <div class="mat-body-1">Please select the offer that has been accepted</div>
            <mat-selection-list [multiple]="false"
                                #OfferDetails
                                class="lendio-data-detail-list"
                                color="primary">
                <mat-list-option *ngFor="let offer of offers; let i = index;"
                                 [togglePosition]="'before'"
                                 [value]="offer">
                    <span matListItemTitle class="lendio-data-detail-list-item-title pt-[3px]">{{ 'Offer ' + (i + 1) }}</span>
                    <div class="lendio-data-detail-list-row">
                        <div class="lendio-list-detail">
                            <div  class="lendio-list-detail-label h-5">Amount</div>
                            <div class="lendio-detail-value h-5"
                                 [ngClass]="{'text-center': !offer.amount}">
                                {{ (offer.amount | currency) || '-' }}
                            </div>
                        </div>
                        <div class="lendio-list-detail">
                            <div  class="lendio-list-detail-label h-5">APR</div>
                            <div class="lendio-detail-value h-5"
                                 [ngClass]="{'text-center': !offer.apr}">
                                {{ (offer.apr | number: '1.0-2') || '-' }}<span *ngIf="offer.apr">%</span>
                            </div>
                        </div>
                        <div class="lendio-list-detail">
                            <div  class="lendio-list-detail-label h-5">Payment Freq.</div>
                            <div class="lendio-detail-value h-5"
                                 [ngClass]="{'text-center': !offer.paymentFrequency}">
                                {{ offer.paymentFrequency || '-' }}
                            </div>
                        </div>
                    </div>
                </mat-list-option>
            </mat-selection-list>
        </ng-template>
    </div>

    <div class="accept-offer-controls">
        <button mat-stroked-button color="accent" (click)="createNewOffer()">Create new offer</button>
        <div class="flex flex-row items-center gap-2">
            <button mat-button color="primary" (click)="closeDialog()">Cancel</button>
            <button mat-raised-button color="primary"
                    [disabled]="!offerSelected || _markAcceptedClicked"
                    (click)="markOfferAccepted()">
                <span>Mark accepted</span>
            </button>
        </div>
    </div>
</div>
