import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {WebhookSubscription} from '../../interfaces/webhooks.model';
import {
  CreateWebhookSubscription,
  DeleteWebhookSubscription,
  GetWebhookSubscriptions,
  GetWebhookTestSchema,
  GetWebhookTypes,
  ResetWebhookTest,
  TestWebhookSubscription,
  UpdateWebhookSubscription
} from './webhooks.actions';
import { Injectable } from '@angular/core';
import { WebhooksService } from 'src/app/services/webhooks.service';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';

export class WebhooksStateModel {
  subscriptions: WebhookSubscription[];
  webhookTypes: [];
  schemas: {} | null;
  testStatus: string;
  testResponse: {
    requestPayload: any;
    responseBody: any;
    responseStatusCode: number;
    event: string;
  } | null;
}

@State<WebhooksStateModel>({
  name: 'webhookSubscriptions',
  defaults: {
    subscriptions: [],
    webhookTypes: [],
    schemas: null,
    testStatus: 'init',
    testResponse: null
  }
})
@Injectable()
export class WebhooksState {

  @Selector()
  static subscriptions(state: WebhooksStateModel) {
    return state.subscriptions;
  }

  @Selector()
  static webhookTypes(state: WebhooksStateModel){
    return state.webhookTypes;
  }

  @Selector()
  static testSchema(state: WebhooksStateModel){
    return (id: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return state.schemas === null ? {} : state.schemas[id];
    };
  }

  @Selector()
  static testResponse(state: WebhooksStateModel){
    return state.testResponse;
  }

  @Selector()
  static testStatus(state: WebhooksStateModel){
    return state.testStatus;
  }

  @Selector()
  static usedEvents(state: WebhooksStateModel): {id: string, name: string}[]{
    const webhookMap = {};
    state.subscriptions.forEach((sub) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      webhookMap[sub.eventId] = sub.event;
    });

    return Object.keys(webhookMap).map(hook => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {id: hook, name: webhookMap[hook]};
    });

  }

  constructor(
    private store: Store,
    private webhooksService: WebhooksService
  ) { }

  @Action(GetWebhookSubscriptions)
  getSubscriptions({ patchState }: StateContext<WebhooksStateModel>, event:GetWebhookSubscriptions) {
    return this.webhooksService.getWebhookSubscriptions(event.isEmbedded).pipe(catchError(err => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Unable to fetch webhooks. Please refresh the page to try again.'
      }));
      return throwError(err);
    }), tap(response => {
          patchState({
          subscriptions: response.data?.map(sub => {
            return { id: sub.id, eventId: sub.eventId, url: sub.url, event: sub.webhookType };
          }) || []
        });
    }));
  }

  @Action(CreateWebhookSubscription)
  createSubscription({ dispatch }: StateContext<WebhooksStateModel>, event:CreateWebhookSubscription){

    return this.webhooksService.createWebhookSubscription({
      eventId: event.eventId,
      url: event.url},
      event.isEmbedded).pipe(
        catchError(err => {
          this.store.dispatch(new CreateNewAlert({
            level: 'error',
            message: 'Failed to create webhook. Please try again.'
          }));
          return throwError(err);
        }), tap(() => {
          return dispatch(new GetWebhookSubscriptions(event.isEmbedded));
        })
      );

  }

  @Action(UpdateWebhookSubscription)
  updateSubscription({ dispatch }: StateContext<WebhooksStateModel>, event:UpdateWebhookSubscription){
    return this.webhooksService.updateWebhookSubscription(event.id, {
      eventId: event.eventId,
      url: event.url
    },
    event.isEmbedded).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Failed to update webhook. Please try again.'
        }));
        return throwError(err);
      }), tap(() => {
        return dispatch(new GetWebhookSubscriptions(event.isEmbedded));
      })
    );

  }

  @Action(DeleteWebhookSubscription)
  deleteSubscription({ getState, patchState }: StateContext<WebhooksStateModel>, event: DeleteWebhookSubscription) {
    return this.webhooksService.deleteWebhookSubscription(event.subscription.id, event.isEmbedded).pipe(catchError(err => {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
          message: 'Failed to delete webhook. Please try again.'
      }));
      return throwError(err);
      }), tap(() => {
        const subscriptions = getState().subscriptions;
        patchState({
          subscriptions: subscriptions.filter(sub => sub.id !== event.subscription.id)
        });
      }));
  }

  @Action(GetWebhookTypes)
  getWebhookTypes({ patchState }: StateContext<WebhooksStateModel>, event: GetWebhookTypes) {
    return this.webhooksService.getWebhookTypes(event.isEmbedded).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Failed to get webhooks. Please try again'
        }));
        return throwError(err);
      }), tap(response => {
        patchState({
          webhookTypes: response.data
        });
      })
    );
  }

  @Action(GetWebhookTestSchema)
  getWebhookTestSchema({ patchState }: StateContext<WebhooksStateModel>, event: GetWebhookTestSchema){
    return this.webhooksService.getWebhookTestSchema(event.isEmbedded).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Failed to get webhooks. Please try again'
        }));
        return throwError(err);
      }),
      tap((response) => {
        patchState({
          schemas: response.data.payloadSchemas
        });
      })
    );
  }

  @Action(TestWebhookSubscription)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  testWebhookSubscription({ patchState, getState }: StateContext<WebhooksStateModel>, event: TestWebhookSubscription) {
    const state = getState();
    patchState({
      testStatus: 'loading'
    });
    return this.webhooksService.testWebhookSubscription(event.subscriptionId, event.payload, event.isEmbedded).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Failed to Process Test'
        }));
        return throwError(err);
      }),
      tap((response) => {
        const subscription = state.subscriptions.filter((sub) => sub.id === event.subscriptionId)[0];

        const data = {
          event: subscription.event,
          url: subscription.url,
          requestPayload: response.data.requestPayload,
          responseBody: response.data.responseBody,
          responseStatusCode: response.data.responseStatusCode,
        };

        patchState({
          testStatus: 'received',
          testResponse: data
        });
      })
    );
  }

  @Action(ResetWebhookTest)
  resetWebhookTest({ patchState }: StateContext<WebhooksStateModel>){
    return patchState({
      testStatus: 'init'
    });

  }

}
