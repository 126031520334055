<div class="p-4 grid grid-cols-3 gap-4">
    <mat-card appearance="outlined"
              class="!p-0"
              *ngFor="let integration of (integrations$ | async)">
        <mat-card-header *appLet="statusMap[integration.status] as statusConfig">
            <div class="column-grid justify-between place-items-center w-full px-4 pt-4 pb-6">
                <mat-card-title>{{ integration.name }}</mat-card-title>
                <mat-chip class="!rounded-full !h-6 {{ statusConfig.color }}">{{ statusConfig.label }}</mat-chip>
            </div>
        </mat-card-header>
        <div class="relative w-full">
            <mat-divider></mat-divider>
        </div>
        <mat-card-content class="new-theme-typography">
            <div class="column-grid justify-between w-full place-items-baseline pt-6">
                <span class="text-sm font-normal text-lendio-cool-gray-300">
                    Last sync:
                    <span *ngIf="!!integration.lastSyncDate else notSynced">{{ integration.lastSyncDate | timeago }}</span>
                    <ng-template #notSynced>---</ng-template>
                </span>
                <button mat-button
                        [attr.data-testid]="'view-integration-' + integration.alias"
                        *ngIf="integration.enabled"
                        [color]="'primary'" [routerLink]="['/administration/integrations', integration.customerIntegrationId]">
                    View Integration
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
