import { Component, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LeadSubmissionDialogComponent } from '../lead-submission-dialog/lead-submission-dialog.component';

@Component({
  selector: 'app-lead-submission-button',
  templateUrl: './lead-submission-button.component.html',
  styleUrls: ['./lead-submission-button.component.scss']
})
export class LeadSubmissionButtonComponent implements OnInit {

    @Output() state: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void { }

  openLeadSubmissionDialog(): void {
    const dialogRef = this.dialog.open(LeadSubmissionDialogComponent, {
      width: '450px',
      data: { }
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
