<span mat-dialog-close class="close-dialog-circle">
    <mat-icon>close</mat-icon>
</span>
<h3 mat-dialog-title class="!p-4">{{ myLabel.id ? 'Edit Label' : 'Create Label' }}</h3>
<div [class.old-theme-edit-dialog]="oldThemeEnabled()">
<div mat-dialog-content>
    <form class="label-form" #labelForm="ngForm">
        <mat-form-field data-cy="save-label-name" class="example-full-width">
            <mat-label>Label Name</mat-label>
            <input matInput [maxLength]="20" placeholder="Ex. My Label" name="name" [(ngModel)]="myLabel.name">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Color</mat-label>
            <mat-select [(ngModel)]="myLabel.color" [value]="myLabel.color" name="color">
                <mat-option *ngFor="let color of colors" [value]="color.value" >
                    {{color.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="pb-4">
            <button mat-flat-button data-cy="save-label" color="primary" (click)="$event.stopPropagation(); save()">Save Label</button>
        </div>

        <button *ngIf="myLabel.id" data-cy="delete-label" mat-flat-button color="warn" class="mt-2" (click)="$event.stopPropagation(); delete()">Delete Label</button>
    </form>
</div>
</div>
