import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { ErrorHandler, Injectable, inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { of, tap, throwError, delay } from 'rxjs';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { AddBusiness, FetchWithUpdatedParams, SetPageIndex, SetCurrentBusiness, GetBusinessCount } from './businesses-list.actions';
import { Business } from '@app/app/interfaces/business.model';
import { BusinessesListService } from '@app/app/services/businesses-list.service';
import { patch } from '@ngxs/store/operators';
import { BusinessesResponse, BusinessesCountResponse } from '@app/app/interfaces/businesses-response.model';

export interface BusinessesListStateModel {
  totalCount?: number;
  count: number;
  sort: string;
  businesses: Business[];
  urlPostfix: string;
  current: Business | null;
  loading: boolean;
  filterBy: string;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  errors: any;
  countRequest: string; // exclude[default] only, include
  countInitialized: boolean;
}

@State<Partial<BusinessesListStateModel>>({
  name: 'businessesList',
  defaults: {
    totalCount: 0,
    count: 0,
    sort: 'desc',
    businesses: [],
    urlPostfix: '',
    current: null,
    loading: false,
    filterBy: '',
    pageIndex: 0,
    pageSize: 25,
    sortBy: 'name',
    sortDirection: 'desc',
    errors: null,
    countRequest: 'exclude',
    countInitialized: false,
  },
})
@Injectable()
export class BusinessesListState {
  constructor(
    private _businessesService: BusinessesListService,
    private _store: Store
  ) {}

  @Selector()
  static businesses(state: BusinessesListStateModel) {
    return state.businesses;
  }

  @Selector()
  static current(state: BusinessesListStateModel) {
    return state.current;
  }

  @Selector()
  static filterBy(state: BusinessesListStateModel) {
      return state.filterBy;
  }

  @Selector()
  static loading(state: BusinessesListStateModel) {
    return state.loading;
  }

  @Selector()
  static pageIndex(state: BusinessesListStateModel) {
      return state.pageIndex;
  }

  @Selector()
  static pageSize(state: BusinessesListStateModel) {
      return state.pageSize;
  }

  @Selector()
  static sortBy(state: BusinessesListStateModel) {
      return state.sortBy;
  }

  @Selector()
  static sortDirection(state: BusinessesListStateModel) {
      return state.sortDirection;
  }

  @Selector()
  static totalCount(state: BusinessesListStateModel) {
      return state.totalCount || 0;
  }

  @Selector()
  static countInitialized(state: BusinessesListStateModel) {
    return state.countInitialized;
  }

  @Action(FetchWithUpdatedParams)
  fetchWithUpdatedParams(
    ctx: StateContext<BusinessesListStateModel>,
    { paramChanges, shouldReloadOnFetch = false}: FetchWithUpdatedParams) {

      if(shouldReloadOnFetch) {
        ctx.patchState({ loading: true });
      } else {
        const state = ctx.getState();
        // We will need to reload if the total count is set and is greater than the current page count regardless of forced reload
        if(state.totalCount) {
          ctx.patchState({ loading: (state.totalCount > state.count) });
        }
      }

      ctx.patchState(paramChanges);

      const { pageIndex, pageSize, sortBy, sortDirection, filterBy, countRequest } = ctx.getState();

      return this._businessesService.getBusinesses({
        sortBy,
        sortDirection,
        pageIndex,
        pageSize,
        filterBy,
        countRequest
      }).pipe(
        // TODO: Move away from deprecated tap signature.
        // Use the catchError operator outside of tap to deal with errors instead.
        tap((response: BusinessesResponse) => {
            const {
              businesses,
              pageIndex,
              pageSize,
              count,
              sort,
              urlPostfix
            } = response.data

            if(countRequest && countRequest != 'exclude') {
              const totalCount = response.data.totalCount;
              ctx.patchState({ totalCount });
            }

            ctx.setState(
              patch({
                loading: false,
                businesses,
                pageIndex,
                pageSize,
                count,
                sort,
                urlPostfix
              })
            );
          },
          catchError((err) => {
            ctx.patchState({
              loading: false,
              errors: err,
              businesses: [],
            });
            this._store.dispatch(new CreateNewAlert({
              level: 'error',
              message: 'Unable to fetch businesses. Please refresh the page to try again.'
            }));
            inject(ErrorHandler).handleError(err);
            return of('');
          }),
          )
      );
  }

  @Action(GetBusinessCount)
  getBusinessesCount(
    ctx: StateContext<BusinessesListStateModel>,
    filterBy?: string
  ) {
    return this._businessesService.getBusinesses({ countRequest: 'only', filterBy: '' }).pipe(
      // TODO: Move away from deprecated tap signature.
      // Use the catchError operator outside of tap to deal with errors instead.
      tap((response: BusinessesCountResponse) => {
        const totalCount = response.data.totalCount;
        const countInitialized = true;
        ctx.patchState({ totalCount, countInitialized });
      },
      catchError((err) => {
        ctx.patchState({
          loading: false,
          errors: err,
          totalCount: 0,
        });
        this._store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to fetch businesses count.'
        }));
        inject(ErrorHandler).handleError(err);
        return of('');
      }),
      )
    )
  }

  @Action(SetCurrentBusiness)
  setCurrentBusiness(
    { getState, patchState }: StateContext<BusinessesListStateModel>, { id }: SetCurrentBusiness
  ) {
    const state = getState();
    if (state.businesses != null) {
      const found = state.businesses.find(b => b.id == id);
      if (found) {
        patchState({ current: found });
      }
    }
  }

  @Action(SetPageIndex)
  setPageIndex({ patchState }: StateContext<BusinessesListStateModel>, { pageIndex }: SetPageIndex) {
      patchState({ pageIndex });
  }

  @Action(AddBusiness)
  addBusiness(
    ctx: StateContext<BusinessesListStateModel>,
    { borrower }: AddBusiness,
  ) {
    ctx.patchState({ loading: true });

    const currentBusinesses = ctx.getState().businesses as Business[];

    return this._businessesService.addBusiness(borrower).pipe(
      catchError((err: any) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      }),
      tap((response: any) => {
        if(response.status == 'success'){
          let curTotalCount = ctx.getState().totalCount || 0;
          // TODO: once we figure out a good way to get counts separately we can remove the countRequest: include
          const params = {
            countRequest: 'include'
          };
          this._store.dispatch(new FetchWithUpdatedParams(params, false));
          // since we are not getting updated totalCount by default lets increment
          ctx.patchState({totalCount: curTotalCount++});
        }
        ctx.setState(
          patch({
            businesses: [...currentBusinesses, response.data],
            loading: false
          })
        )
      })
    );
  }
}
