import { NgModule } from '@angular/core';
import { PhonePipe } from './phone.pipe';

@NgModule({
    imports: [],
    exports: [PhonePipe],
    declarations: [PhonePipe],
    providers: [PhonePipe],
})
export class PhoneModule { }
