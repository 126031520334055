<div [class.old-theme-owners]=oldThemeEnabled()>
    <mat-table [dataSource]="contacts">
        <ng-container matColumnDef="first">
            <mat-header-cell *matHeaderCellDef> First </mat-header-cell>
            <mat-cell *matCellDef="let contact"> {{ contact.first }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="last">
            <mat-header-cell *matHeaderCellDef> Last </mat-header-cell>
            <mat-cell *matCellDef="let contact"> {{ contact.last }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="% owned">
            <mat-header-cell *matHeaderCellDef> % Owned </mat-header-cell>
            <mat-cell *matCellDef="let contact"> {{ contact.percentOwnership }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
            <mat-cell *matCellDef="let contact"> {{ contact.email }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="zip">
            <mat-header-cell *matHeaderCellDef> Zip </mat-header-cell>
            <mat-cell *matCellDef="let contact"> {{ contact.zipId }}<span *ngIf="contact.zipExtra">-{{ contact.zipExtra }}</span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="contract signer">
            <mat-header-cell *matHeaderCellDef> Contract Signer </mat-header-cell>
            <mat-cell *matCellDef="let contact"> {{ contact.contractSigner ? 'Yes' : 'No' }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let contact" class="flex-right">
                <mat-icon class="!text-gray-600 cursor-pointer" (click)="openEditContactDialog(contact)">create</mat-icon>
                <mat-icon *ngIf="!contact.isPrimary" class="!text-gray-600 !ml-2 cursor-pointer" (click)="openDeleteContactDialog(contact)">delete</mat-icon>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div class="mt-3">
        <button mat-raised-button color="primary" (click)="openAddContactDialog()">Add New Owner</button>
    </div>
</div>
