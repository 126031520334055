import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-old-dynamic-borrower-values',
  templateUrl: './old-dynamic-borrower-values.component.html',
  styleUrls: ['../details.component.scss']
})
export class OldDynamicBorrowerValuesComponent implements OnInit {

  @Input() applicationFields: any;
  cleanedFields: any[] = [];
  skip = [
      'creditScore',
      'financeAmount',
      'annualRevenue',
      'annual_profits',
      'average_monthly_sales',
      'ownerBirthDate',
      'owner_street_address',
      'owner_city',
      'owner_state',
      'owner_zip',
      'bankruptcy',
      'ownerPercent',
      'doingBusinessAsName',
      'federalstate_tax_id',
      'entity_type',
      'lendioIndustry',
      'state_of_incorporation',
      'business_start_date',
      'loanPurpose',
      'user.email',
      'user.first',
      'user.last',
      'borrower.name',
      'borrower.phone',
      'borrower.street',
      'borrower.street2',
      'borrower.city',
      'borrower.stateId',
      'borrower.zipId',
    ];

  constructor() { }

  ngOnInit(): void {
    this.applicationFields?.forEach((item: { alias: string; value: string; }) => {
      if (item === null || item.alias === undefined){
        // skip
      } else {
        if (item.alias === 'contact.ssn' && item.value !== null) {
          item = { ...item };
          item.value = this.formatSSN(item.value);
        }
        if (this.skip.indexOf(item.alias) === -1) {
          this.cleanedFields.push(item);
        }
      }
    });
  }

  formatSSN(ssn: string) {
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  }
}
