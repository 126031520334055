import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class CsvService {

    constructor(private httpClient: HttpClient) {}

    getApplicationsExports(): Observable<any> {
        const url = environment.apiUrl + '/l/v2/internal/csv-documents?scope=';
        return this.httpClient.get<any>(url + 'applications');
    }

    exportApplications(): Observable<any> {
        const url = environment.apiUrl + '/l/v2/internal/applications/csv?scope=';
        return this.httpClient.get<any>(url + 'applications');
    }

    postForgivenessImport(formData: FormData): Observable<any> {
        const url = environment.apiUrl + '/l/v2/internal/loan-forgiveness';
        return this.httpClient.post<any>(url, formData);
    }

    getForgivenessDocuments(institutionId: string | number): Observable<any> {
        const url = environment.apiUrl + '/l/v2/internal/loan-forgiveness?institutionId=' + institutionId;
        return this.httpClient.get<any>(url);
    }
}
