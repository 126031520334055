import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThoughtspotLiveboardModel } from '../interfaces/thoughtspot-liveboard.model';
import { Observable, map, of } from 'rxjs';
const TS_REPORT_TAG_ID: string = '50899f8d-04c7-4af3-9bca-b232b7dd90b8';

@Injectable()
export class ThoughtspotReportsService {
  constructor(private http: HttpClient) {}

  private readonly _tsUrl = 'https://thoughtspot.lendio.com/api/rest/2.0/metadata/search';
  private readonly _basePayload = {
    metadata: [
      {
        type: 'LIVEBOARD',
      },
    ],
    include_auto_created_objects: false,
    include_dependent_objects: false,
    include_details: false,
    include_headers: true,
    include_hidden_objects: false,
    include_incomplete_objects: false,
    include_visualization_headers: false,
    record_offset: 0,
    record_size: 100,
    include_stats: false,
  };
  protected readonly lenderPayload = {
    ...this._basePayload,
    tag_identifiers: ['LENDER']
  };
  protected readonly affiliatePayload = {
    ...this._basePayload,
    tag_identifiers: ['AFFILIATE']
  };
  protected readonly partnerPayload = {
    ...this._basePayload,
    tag_identifiers: ['PARTNER']
  };

  getLenderReportList(): any {
    // TODO: Put in an interface for the response instead of using "any".
    // ThoughtSpot calls it a MetadataSearchResponse but I don't know if
    //  they provide a TS type for it.

    return this.http.post<any[]>(this._tsUrl, this.lenderPayload).pipe(
      map((metadataSearchResponseItem) => metadataSearchResponseItem
        .filter((r) => r.metadata_header.tags.some((tag: any) => tag.id === TS_REPORT_TAG_ID))
        .map((r) => {
          return {
            id: r.metadata_id,
            name: r.metadata_name,
            created: new Date(r.metadata_header.created),
            modified: new Date(r.metadata_header.modified),
            createdBy: r.metadata_header.authorName.includes('@lendio.com')
              ? 'Lendio'
              : r.metadata_header.authorName ?? '-',
          };
        })
      )
    );
  }

  getAffiliateReportList(): Observable<ThoughtspotLiveboardModel[]> {
    return this.http.post<any[]>(this._tsUrl, this.affiliatePayload).pipe(
      map((metadataSearchResponseItem) => metadataSearchResponseItem
        .filter((response) => response.metadata_header.tags.some((tag: any) => tag.id === TS_REPORT_TAG_ID))
        .map((report) => {
          return {
            id: report.metadata_id,
            name: report.metadata_name,
            created: new Date(report.metadata_header.created),
            modified: new Date(report.metadata_header.modified),
            createdBy: report.metadata_header.authorName.includes('@lendio.com')
              ? 'Lendio'
              : report.metadata_header.authorName ?? '-',
          };
        })
      )
    );
  }

  getPartnerReportList(): Observable<ThoughtspotLiveboardModel[]> {
    return this.http.post<any[]>(this._tsUrl, this.partnerPayload).pipe(
      map((metadataSearchResponseItem) => metadataSearchResponseItem
        .filter((response) => response.metadata_header.tags.some((tag: any) => tag.id === TS_REPORT_TAG_ID))
        .map((report) => {
          return {
            id: report.metadata_id,
            name: report.metadata_name,
            created: new Date(report.metadata_header.created),
            modified: new Date(report.metadata_header.modified),
            createdBy: report.metadata_header.authorName.includes('@lendio.com')
              ? 'Lendio'
              : report.metadata_header.authorName ?? '-',
          };
        })
      )
    );
  }
}
