import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Import, PaginatedImportsResponse } from '@app/app/interfaces/import.model';
import { Injectable } from '@angular/core';
import { ImportsService } from '@app/app/services/imports.service';
import { catchError, of, tap } from 'rxjs';
import { FetchWithUpdatedParams, SubscribeToImportStateUpdates, UnsubscribeFromImportStateUpdates, UpdateLatestImport } from './import.actions';
import { PusherService } from '@app/app/services/pusher.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { EventBusService } from '@app/app/services/event-bus.service';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
import { Router } from '@angular/router';
import { AuthState } from '../auth/auth.state';


export interface ImportsStateModel {
  imports: Import[]
  mostRecentImport: Import | null;
  loading: boolean;
  errors: any;
  page: number;
  lastPage: number;
  total: number;
  last_page: number;
  next_page_url: string;
  pageSize: number;
  pageIndex: number;
  prev_page_url: string;
  sortBy: string;
  sortDirection: string;
  filterBy: string;
}

@State<Partial<ImportsStateModel>>({
  name: 'Imports',
  defaults: {
    imports: [],
    loading: false,
    mostRecentImport: null,
    errors: null,
    page: 1,
    lastPage: 0,
    total: 0,
    last_page: 0,
    next_page_url: '',
    pageSize: 20,
    pageIndex: 0,
    prev_page_url: '',
    sortBy: 'created',
    sortDirection: 'desc',
    filterBy: ''
  }
})

@Injectable()

export class ImportsState {
  @Selector()
  static mostRecentImport(state: ImportsStateModel) {
      return state.mostRecentImport;
  }

  @Selector()
  static loading(state: ImportsStateModel) {
      return state.loading;
  }

  @Selector()
  static filterBy(state: ImportsStateModel) {
      return state.filterBy;
  }

  @Selector()
  static imports(state: ImportsStateModel) {
      return state.imports;
  }

  @Selector()
  static nextPageUrl(state: ImportsStateModel) {
      return state.next_page_url;
  }

  @Selector()
  static page(state: ImportsStateModel) {
      return state.page;
  }

  @Selector()
  static pageIndex(state: ImportsStateModel) {
      return state.pageIndex;
  }

  @Selector()
  static pageSize(state: ImportsStateModel) {
      return state.pageSize;
  }

  @Selector()
  static prevPageUrl(state: ImportsStateModel) {
      return state.prev_page_url;
  }

  @Selector()
  static sortBy(state: ImportsStateModel) {
      return state.sortBy;
  }

  @Selector()
  static sortDirection(state: ImportsStateModel) {
      return state.sortDirection;
  }

  @Selector()
  static total(state: ImportsStateModel) {
      return state.total;
  }

  @Selector()
  static latestImportStillProcessing(state: ImportsStateModel) {
    if(state.mostRecentImport !== null){
      return !['failed', 'complete'].includes(state.mostRecentImport?.status);
    }
    return false;
  }

  label: string = '';
  importStatus: string = '';

  constructor(
      private importsService: ImportsService,
      private pusherService: PusherService,
      private store: Store,
      private eventBusService: EventBusService,
      private snackbarService: LendioSnackbarService,
      private _router: Router,
  ) {}

  @Action(FetchWithUpdatedParams)
  fetchWithUpdatedParams(
    { getState, patchState }: StateContext<ImportsStateModel>,
    { paramChanges, shouldReloadOnFetch = false}: FetchWithUpdatedParams) {

      if(shouldReloadOnFetch) {
        patchState({ loading: true });
      } else {
        patchState({ loading: false });
      }

      patchState(paramChanges);

      const { pageIndex, pageSize, sortBy, sortDirection, filterBy } = getState();

      return this.importsService.getImports(
        pageIndex,
        pageSize,
        sortBy,
        sortDirection,
        filterBy
        ).pipe(
          tap((response: PaginatedImportsResponse) => {
            patchState({
              loading: false,
              imports: response.data.data.data,
              page: response.data.data.current_page,
              lastPage: response.data.data.last_page,
              total: response.data.data.total,
              next_page_url: response.data.data.next_page_url,
              mostRecentImport: response.data.mostRecentImport,
            });
            this.eventBusService.publish(new this.eventBusService.types.LatestImportInStateEvent);
          },
          catchError((err) => {
            patchState({
              loading: false,
              errors: err,
              imports: [],
            });
            console.error(err);
            return of('');
          }),
          )
        );
  }

  @Action(SubscribeToImportStateUpdates)
  subscribeToImportStateUpdates({ }: StateContext<ImportsStateModel>, { }: SubscribeToImportStateUpdates) {
    this.pusherSubscribe();
  }

  @Action(UpdateLatestImport)
  updateLatestImport(ctx: StateContext<ImportsStateModel>, { incomingImport }: UpdateLatestImport) {
    ctx.setState(
      patch<ImportsStateModel>({
        imports: updateItem<Import>(x => x.id === incomingImport.id, incomingImport),
        mostRecentImport: incomingImport,
      })
    );
  }

  @Action(UnsubscribeFromImportStateUpdates)
  unsubscribeFromImportStateUpdates({ }: StateContext<ImportsStateModel>, { }: UnsubscribeFromImportStateUpdates) {
    this.pusherUnsubscribe();
  }

  pusherSubscribe() {
    this.pusherService.subscribe({
      name: `import-updated-${this.store.selectSnapshot(AuthState.user)?.institution?.id}`,
      auth: false,
      handler: (event: any, incomingImport: Import) => {
        if(event === 'import-update'){
          this.handlePusherMessage(incomingImport);
        }
        if(incomingImport.status === 'complete' || incomingImport.status === 'failed') {
          this.triggerSnackbar(incomingImport);
        }
        return;
      }
    });
  }


  pusherUnsubscribe() {
    this.pusherService.unsubscribe({
      name: `import-updated-${this.store.selectSnapshot(AuthState.user)?.institution?.id}`,
      auth: false,
      handler: () => {
        console.dir('unsubscribed');
      }
    });
  }

  handlePusherMessage(incomingImport: Import) {
    this.store.dispatch(new UpdateLatestImport(incomingImport));
  }

  triggerSnackbar(incomingImport: Import) {
    switch(incomingImport.status) {
      case 'complete':
          this.label = 'done'
          this.importStatus = 'completed'
          break
      case 'failed':
          this.label = 'warning_amber'
          this.importStatus = 'failed'
          break
      default:
          break
    }
    this.snackbarService.open(
      {
        title: `Import ${incomingImport.status}`,
        duration: 5000,
        message: `Your import has ${this.importStatus}`,
        canDismiss: true,
        prefix:
        {
          type: 'mat-icon',
          matIconColor: 'white',
          matIconLabel: this.label,
          backgroundColor: '#7A8799'
        },
        action:
        {
          cb: () => {
            this._router.navigate([`administration/import/${incomingImport.id}`]);
          },
          label: 'View'
        }
      });
  }
}
