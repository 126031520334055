import * as Rollbar from 'rollbar';

import {
    Injectable,
    Inject,
    InjectionToken,
    ErrorHandler
} from '@angular/core';
import { environment } from '../environments/environment';

const rollbarConfig = {
    accessToken: environment.rollbarAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: environment.production ? 'Live' : 'Dev',
        client: {
            javascript: {
                source_map_enabled: true, // true by default
                code_version: environment.GIT_COMMIT,
                guess_uncaught_frames: true
            }
        },
        custom: {
            tenant: environment.TENANT_NAME ? environment.TENANT_NAME : 'Lendio'
        }
    }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

    handleError(err: any): void {
        if (!err) {
            return;
        }

        if (!environment.production) {
            console.error(err);
        }

        if(!err.originalError && err.message){
            this.rollbar.error(err.message, err);
        }else{
            this.rollbar.error(err.originalError || err);
        }
    }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}
