import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { ResetPassword } from '@app/app/store/auth/auth.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
          useAnimation(fadeIn, { params: { timing: 0.5 }})
      )
    ])
  ],
})
export class ResetPasswordComponent implements OnInit {

  givenEmail: string;
  givenToken: string;
  showForm = true;
  submitted = false;
  submitting = false;
  resetForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', {
      validators: [
        Validators.email,
        Validators.required,
      ],
      updateOn: 'blur'
    }),
    token: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'change'
    }),
    password: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(14),
        complexityValidator(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/)
      ],
      updateOn: 'blur'
    })
  });

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private store: Store,
  ) {}

  ngOnInit(): void {
    const queryEmail = this.route.snapshot.queryParamMap.get('email');
    const queryToken = this.route.snapshot.queryParamMap.get('token');

    this.resetForm.addControl('passwordConfirm', new FormControl('', {
      validators: [
        equalToValidator(this.password!)
      ],
      updateOn: 'change'
    }));

    if (queryEmail && queryToken) {
      this.givenEmail = queryEmail;
      this.givenToken = queryToken;

      this.resetForm.setValue({
        email: this.givenEmail,
        token: this.givenToken,
        password: '',
        passwordConfirm: ''
      });
    } else {
      this.showForm = false;
    }
  }

  canSubmit(): boolean {
    return this.resetForm.valid && !this.submitting && !this.submitted;
  }

  get token() {
    return this.resetForm.get('token');
  }

  get email() {
    return this.resetForm.get('email');
  }

  get password() {
    return this.resetForm.get('password');
  }

  get passwordConfirm() {
    return this.resetForm.get('passwordConfirm');
  }

  submit() {
    if (!this.canSubmit()) {
      return;
    }

    this.submitting = true;

    this.store.dispatch(new ResetPassword(this.email!.value, this.token!.value, this.password!.value)).subscribe({
      next: () => {
        this.submitted = true;
      },
      error: () => {
        this.submitting = false;
      },
    });
  }
}

export function equalToValidator(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    return control.value !== otherControl.value ? {equalTo: false} : null;
  };
}

export function complexityValidator(expression: RegExp): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const complex = expression.test(control.value);
    return !complex ? {complexity: {value: control.value}} : null;
  };
}
