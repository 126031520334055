import { Component, computed, OnInit } from '@angular/core';
import { PortalUser } from 'src/app/interfaces/portal-user.model';
import { MatDialog } from '@angular/material/dialog';
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { DeleteUserDialogComponent } from '../delete-user-dialog/delete-user-dialog.component';
import { Observable } from "rxjs";
import { PortalUsersState } from "@app/app/store/portal-users/portal-users-state.service";
import { Store } from "@ngxs/store";
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {
  newThemeEnabled = computed(() => this._themeService.newThemeEnabled());
  portalUsers$: Observable<PortalUser[] | null> = this._store.select(PortalUsersState.portalUsers);
  displayedColumns: string[] = ['name', 'email', 'edit'];
  dataSource: Observable<PortalUser[] | null>;
  currentUserId: number | undefined;

  constructor(
    public dialog: MatDialog,
    private _store: Store,
    private _themeService: LendioAngularMaterialThemeService
  ) {
    this.dataSource = this.portalUsers$;
  }

  ngOnInit(): void {
    this.currentUserId = this._store.selectSnapshot(AuthState.user)?.id;
  }

  openAddUserDialog() {
    this.dialog.open(EditUserDialogComponent, {
      width: '524px',
      panelClass: 'old-theme-dialog',
      data: { portalUser: null }
    });
  }

  openNewAddUserDialog() {
    this.dialog.open(EditUserDialogComponent, {
      width: '500px',
      data: { portalUser: null }
    });
  }

  userIsSelf(PortalUser: PortalUser): boolean {
    return (PortalUser.id === this.currentUserId);
  }

  openEditUserDialog(portalUser: PortalUser) {
    this.dialog.open(EditUserDialogComponent, {
      width: '524px',
      panelClass: 'old-theme-dialog',
      data: { portalUser }
    });
  }

  openNewEditUserDialog(portalUser: PortalUser) {
    this.dialog.open(EditUserDialogComponent, {
      width: '500px',
      data: { portalUser }
    });
  }

  get canManageUsers(): boolean {
    return this._store.selectSnapshot(AuthState.user)?.permittedTo('lpxManageLenderUsers') ?? false;
  }

  canDeleteUser(portalUser: PortalUser): boolean {
    if (!this.canManageUsers) {
      return false;
    }

    if (portalUser.id === this.currentUserId) {
      return false;
    }

    return true;
  }

  openDeleteUserDialog(portalUser: PortalUser) {
    if (portalUser.id == this.currentUserId) {
      return;
    }

    this.dialog.open(DeleteUserDialogComponent, {
      width: '500px',
      panelClass: 'old-theme-dialog',
      data: { portalUser }
    });
  }

  openNewDeleteUserDialog(portalUser: PortalUser) {
    if (portalUser.id == this.currentUserId) {
      return;
    }

    this.dialog.open(DeleteUserDialogComponent, {
      width: '500px',
      data: { portalUser }
    });
  }
}
