import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UpdateAssignments } from '../../../store/applications-list/applications-list.actions';
import { PortalUser } from '../../../interfaces/portal-user.model';
import { AssignUserToDeal, UnassignUserFromDeal } from '@app/app/store/application-details/application-details.actions';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-assignments-smart',
  templateUrl: './assignments-smart.component.html',
  styleUrls: ['./assignments-smart.component.scss']
})
export class AssignmentsSmartComponent {

  @Input() portalUsers: PortalUser[];
  @Input() usersAssigned: number[] = [];
  @Input() dealId: number;

  constructor(private store: Store) {}

  /**
   * Assign/unassign a lender user on this deal.
   *
   * In the legacy application list view, user may assign/unassign multiple
   * before this fires.
   *
   * @param PortalUser newAssignments Array of selected user ids
   */
  assignmentsChanged(newAssignments: PortalUser[]) {
    let dispatchArray: any[] = [];
    // this.usersAssigned is immutable. UpdateAssignments below will update it.
    const tmpUsersAssigned = [...this.usersAssigned];

    // Remove unassignments.
    this.usersAssigned.forEach((userId) => {
      const index = newAssignments.findIndex((user) => user.id === userId);
      if (index === -1) {
        const idIndex = tmpUsersAssigned.indexOf(userId);
        tmpUsersAssigned.splice(idIndex, 1);
        dispatchArray.push(this.store.dispatch(new UnassignUserFromDeal(this.dealId, userId)));
      }
    });

    // Add new assignments.
    newAssignments.forEach((user) => {
      if (!tmpUsersAssigned.includes(user.id)) {
        tmpUsersAssigned.push(user.id);
        dispatchArray.push(this.store.dispatch(new AssignUserToDeal(this.dealId, user.id)));
      }
    });

    if (dispatchArray.length) {
      // See non-deprecated version of forkJoin as of 1/2024. Put array in [].
      // https://rxjs.dev/deprecations/array-argument
      forkJoin([dispatchArray]).subscribe({
        complete: () => {
          this.store.dispatch(new UpdateAssignments(tmpUsersAssigned, this.dealId))
        }
      });
    }
  }
}
