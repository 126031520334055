import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/environments/environment.prod';
import { map } from 'rxjs';
import { EmailTemplate } from '@app/app/interfaces/templates/EmailTemplate.model';
import { LendioResponse } from '@app/app/interfaces/lendio-response';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  baseUrl = environment.apiUrl;
  constructor(
    private http: HttpClient,
  ) {}

  getEmailTemplates() {
    return this.http.get<LendioResponse<EmailTemplate[]>>(`${this.baseUrl}/lender-portal/templates`).pipe(map(response => response));
  }
}
