<div class="pt-2 pl-2"
     [class.old-theme-old-email-settings]="oldThemeEnabled()">
	<div class="mt-2 align-middle text-2xl">
		<span
			*ngIf="emailSettingsUpdateStatus"
			[ngClass]="{'text-green-600': emailSettingsUpdateStatus === 'success', 'text-red-600': emailSettingsUpdateStatus === 'error'}"
			[@fadeIn]
			[@fadeOut]>
			<span *ngIf="emailSettingsUpdateStatus === 'success'">
				<span class="fa fa-lg fa-check-square mr-1"></span>
				Successfully updated!
			</span>
			<span *ngIf="emailSettingsUpdateStatus === 'error'">
				<span class="fa fa-lg fa-bomb mr-1"></span>
				There was an error. Please refresh and try again.
			</span>
		</span>
	</div>

	<div class="settings-email-notification mt-5">
		<div *ngFor="let setting of emailSettings" class="settings-row">
			<div *ngIf="setting.value !== 'contractIn'">
				<label id="settings-notification-label-{{setting.value}}">
					<mat-checkbox
						color="primary"
						(change)="emitSettingUpdate(setting)"
						value="setting.status"
						[checked]="setting.status"
						[disabled]="emailSettingsUpdateStatus === 'pending'">
						<span class="text-lg font-bold">{{ setting.label }}</span>
					</mat-checkbox>
				</label>
				<div [ngClass]="{ 'collapsed': !setting.status }" class="pl-8 mt-1 mb-6 collapse-section flex-height-animated">
					<div class="expand-in" *ngFor="let child of setting.children">
						<label id="settings-notification-label-{{child.value}}">
							<mat-radio-group>
								<mat-radio-button
									color="primary"
									(change)="emitSettingChildUpdate(setting)"
									value="child.status"
									[checked]="child.status"
									[disabled]="emailSettingsUpdateStatus === 'pending'">
									{{ child.label }}
								</mat-radio-button><br />
							</mat-radio-group>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
