import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LenderApprovalBenefitsResponse } from '../interfaces/lender-approval-benefits-response.model';

@Injectable({
    providedIn: 'root'
})
export class LenderApprovalBenefitsService {
  private url = environment.apiUrl + '/l/v2/internal/benefits';

  constructor(private httpClient: HttpClient) { }

  getLenderApprovalBenefits(): Observable<LenderApprovalBenefitsResponse> {
    return this.httpClient.get<LenderApprovalBenefitsResponse>(this.url);
  }

  createLenderApprovalBenefits({title, description, suggestedTitle, suggestedDescription}: {title: string, description: string, suggestedTitle: string, suggestedDescription: string}): Observable<any> {
    const data = {
      title,
      description,
      suggestedTitle,
      suggestedDescription,
    };
    return this.httpClient.post<any>(this.url, data);

  }

  updateLenderApprovalBenefits({id, title, description, suggestedTitle, suggestedDescription}: {id: number, title: string, description: string, suggestedTitle: string, suggestedDescription: string}): Observable<any> {
    const data = {
      id,
      title,
      description,
      suggestedTitle,
      suggestedDescription,
    };
    return this.httpClient.put<any>(this.url + `/${id}`, data);
  }
}
