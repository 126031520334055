<h2 mat-dialog-title class="!p-4">Add New Owner</h2>
<mat-dialog-content>
	<form [formGroup]="newOwnerForm">
		<div class="flex">
			<div class="flex-1 !p-2">
				<p class="col-header">First Name</p>
				<mat-form-field appearance="fill" class="w-full">
					<input matInput type="text" required formControlName="first">
					<mat-error *ngIf="checkError('first', 'required')">First name is required</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-1 !p-2">
				<p class="col-header">Last Name</p>
				<mat-form-field appearance="fill" class="w-full">
					<input matInput type="text" required formControlName="last">
					<mat-error *ngIf="checkError('last', 'required')">Last name is required</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-1 !p-2">
				<p class="col-header">Email</p>
				<mat-form-field appearance="fill" class="w-full">
					<input matInput required formControlName="email" email>
					<mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error>
					<mat-error *ngIf="checkError('email', 'email')">Must be a valid email address</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="flex">
			<div class="flex-1 !p-2">
				<p class="col-header">% Owned</p>
				<mat-form-field appearance="fill" class="w-full">
					<input matInput type="number" required formControlName="percentOwnership">
					<mat-error *ngIf="checkError('percentOwnership', 'required')">% owned is required</mat-error>
					<mat-error *ngIf="checkError('percentOwnership', 'max')">% owned cannot be greater than 100</mat-error>
				</mat-form-field>
			</div>
            <div class="flex-1 !p-2">
                <p class="col-header">Zip</p>
                <mat-form-field appearance="fill" class="w-full">
                    <input matInput type="number" required formControlName="zipId" minlength="5" maxlength="5">
                    <mat-error *ngIf="checkError('zipId', 'required')">Zip is required</mat-error>
                    <mat-error *ngIf="checkError('zipId', 'pattern')">Zip must contain no more or less than 5 digits</mat-error>
                </mat-form-field>
            </div>
			<div class="flex-1 !p-2">
				<p class="col-header">Zip Ext. (Optional)</p>
				<mat-form-field appearance="fill" class="w-full">
					<input matInput type="number" formControlName="zipExtra">
				</mat-form-field>
			</div>
			<div class="flex-1 !p-2">
				<p class="col-header">Contract Signer?</p>
				<mat-checkbox
					formControlName="contractSigner"
					color="primary"
					value="contractSigner">
				</mat-checkbox>
			</div>
		</div>
		<div class="flex">
			<div class="flex-1 text-right">
				<button mat-button (click)="close()">Cancel</button>
				<button mat-flat-button color="primary" (click)="addNewOwner()" class="!ml-2" [disabled]="newOwnerForm.invalid">Create Owner</button>
			</div>
		</div>
	</form>
</mat-dialog-content>
