import { NgModule } from '@angular/core';
import { DecisioningComponent } from '@app/app/components/decisioning/decisioning.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  QualifierValuesTableComponent
} from '@app/app/components/decisioning/qualifiers/qualifier-values-table.component';
import {
  DecisioningOverridesDialogComponent
} from '@app/app/components/decisioning/overrides/decisioning-overrides-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { DecisioningService } from '@app/app/services/decisioning.service';
import { EmptyStateComponent } from '@app/app/components/empty-state/empty-state.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { TooltipIfClampedDirective } from '@app/app/directives/tooltip-if-clamped/tooltip-if-clamped.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule( {
  declarations: [
    DecisioningComponent,
    QualifierValuesTableComponent,
    DecisioningOverridesDialogComponent,
  ],
    imports: [
        CommonModule,
        EmptyStateComponent,
        MatExpansionModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatCheckboxModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatChipsModule,
        TooltipIfClampedDirective,
        FormsModule,
        ReactiveFormsModule,
    ],
  exports: [
    DecisioningComponent,
    QualifierValuesTableComponent,
    DecisioningOverridesDialogComponent,
  ],
  providers: [
    DecisioningService,
  ],
} )
export class DecisioningModule {
}
