<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="qualifier">
    <th mat-header-cell class="w-1/3" *matHeaderCellDef>Qualifier</th>
    <td mat-cell *matCellDef="let qualifierValue">
      <div class="cell-no-wrap" [matTooltip]="qualifierValue.qualifierLabel" tooltipIfClamped>
        <strong>{{qualifierValue.qualifierLabel}}</strong>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="requirement">
    <th mat-header-cell class="w-1/3" *matHeaderCellDef>Requirement</th>
    <td mat-cell *matCellDef="let qualifierValue">
      <div class="cell-no-wrap" [matTooltip]="qualifierValue.requirementLabel" tooltipIfClamped>
        {{qualifierValue.requirementLabel}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actual">
    <th mat-header-cell class="w-1/3" *matHeaderCellDef>Actual</th>
    <td mat-cell *matCellDef="let qualifierValue">
      <ng-container *ngIf="qualifierValue.override else noOverride">
          <ng-container *ngIf="qualifierValue.override.type === 'override' else exclusion">
            <div class="grid grid-flow-col items-center gap-2 grid-cols-[auto_auto_1fr]">
              <mat-chip
                class='lendio-chip-label'
                [class.red-solid]="qualifierValue.status === 'rejected'"
                [matTooltip]="'Original value: ' + (qualifierValue.override.oldBvValue ? qualifierValue.override.oldBvValue : qualifierValue.actualValue) +
                  '\nOverridden by: ' + (qualifierValue.override.createdByUserName ? qualifierValue.override.createdByUserName : 'Unknown') +
                  '\nReason: ' + qualifierValue.override.reason"
                [matTooltipClass]="'whitespace-pre-line'">
                {{qualifierValue.status === 'rejected' ? "Invalid override" : "Current override"}}
              </mat-chip>
              <div class="leading-none grid grid-flow-col items-center gap-2" [matTooltip]="qualifierValue.tooltip">
                {{qualifierValue.actualValue}}
                <mat-icon *ngIf="qualifierValue.tooltip && qualifierValue.actualValue === 'Missing'" class="material-symbols-outlined">info</mat-icon>
              </div>
            </div>
          </ng-container>
          <ng-template #exclusion>
              <div class="grid grid-flow-col items-center gap-2 grid-cols-[auto_auto_1fr]">
                  <mat-chip
                      class='lendio-chip-label warm-gray-solid'
                      [matTooltip]="'Original value: ' + ((!qualifierValue.qualifierField.includes('.') && qualifierValue.override.oldBvValue) ? qualifierValue.override.oldBvValue : qualifierValue.actualValue) +
                        '\nExcluded by: ' + (qualifierValue.override.createdByUserName ? qualifierValue.override.createdByUserName : 'Unknown') +
                        '\nReason: ' + qualifierValue.override.reason"
                      [matTooltipClass]="'whitespace-pre-line'">
                      Excluded
                  </mat-chip>
              </div>
          </ng-template>
      </ng-container>
      <ng-template #noOverride>
        <div class="grid grid-flow-col items-center gap-2 grid-cols-[auto_auto_1fr]">
          <mat-icon [ngClass]="{
                    'text-green-600': qualifierValue.status === 'accepted',
                    'text-red-500': qualifierValue.status === 'rejected' && qualifierValue.actualValue !== 'Missing',
                    'text-gray-400': qualifierValue.status === 'rejected' && qualifierValue.actualValue == 'Missing'
                }">
            fiber_manual_record
          </mat-icon>
          <div class="leading-none grid grid-flow-col items-center gap-2" [matTooltip]="qualifierValue.tooltip">
            {{qualifierValue.actualValue}}
            <mat-icon *ngIf="qualifierValue.tooltip && qualifierValue.actualValue === 'Missing'" class="material-symbols-outlined">info</mat-icon>
          </div>
          <span class="justify-self-end">
            <ng-container
              *ngIf="(qualifierValue.sourcesMatch || qualifierValue.usedVerifiedSources) && qualifierValue.actualValue && qualifierValue.actualValue !== 'Missing' && qualifierValue.fromVerifiedSource">
              <mat-icon
                [matTooltip]="'Verified by: ' + qualifierValue.verifiedSourceLabel + ' on ' + qualifierValue.verifiedSourceDate"
                [color]="'primary'">verified</mat-icon>
            </ng-container>
            <ng-container *ngIf="!qualifierValue.sourcesMatch && qualifierValue.verifiedSourceLabel !== 'N/A' && qualifierValue.requiredSource !== 1 && !qualifierValue.usedVerifiedSources">
              <mat-icon class="material-symbols-outlined"
                [matTooltip]="'This data was not provided by ' + qualifierValue.verifiedSourceLabel + ' and therefore disqualified the customer.'">info</mat-icon>
            </ng-container>
          </span>
        </div>
      </ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="override">
    <th mat-header-cell class="w-1/3" *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let qualifierValue" class="lendio-density-1">
      <button
        *ngIf="!qualifierValue.override"
        mat-button
        [color]="'primary'"
        (click)="overrideQualifierEvent(qualifierValue)"
      >
        Override
      </button>
      <button
        *ngIf="qualifierValue.override"
        mat-button
        [color]="'primary'"
        (click)="overrideQualifierEvent(qualifierValue)"
      >
        Edit
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
