import {Component, OnInit, Inject} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {CreateNewAlert} from 'src/app/store/global-alerts/global-alerts.actions';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Contact} from 'src/app/interfaces/contact.model';
import {ContactsState} from '@app/app/store/contacts/contacts.state';
import {ContactsActions as CA} from '@app/app/store/contacts/contacts.actions';

@Component({
  selector: 'app-create-owner-dialog',
  templateUrl: './create-owner-dialog.component.html',
  styleUrls: ['../../new-offer-display/forms/offer-form-styles.scss', '../../../scss/_dialog.scss']
})
export class CreateOwnerDialogComponent implements OnInit {

  // @Select(ContactsState.contacts) contacts$: Observable<Contact[]>;
  contacts$: Observable<Contact[] | null> = this.store.select(ContactsState.contacts);

  newOwner: any = {
    first: '',
    last: '',
    email: '',
    percentOwnership: null,
    zipId: null,
    zipExtra: null,
    contractSigner: false
  };

  newOwnerForm: FormGroup;
  zipRegex = /^(\d{5})?$/;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<CreateOwnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number },
  ) {
  }

  ngOnInit(): void {
    this.newOwnerForm = new FormGroup({
      first: new FormControl(this.newOwner.first, [
        Validators.required,
      ]),
      last: new FormControl(this.newOwner.last, [
        Validators.required,
      ]),
      email: new FormControl(this.newOwner.email, [
        Validators.required,
      ]),
      percentOwnership: new FormControl(this.newOwner.percentOwnership, [
        Validators.required,
        Validators.max(100)
      ]),
      zipId: new FormControl(this.newOwner.zipId, [
        Validators.required,
        Validators.pattern(this.zipRegex)
      ]),
      zipExtra: new FormControl(this.newOwner.zipExtra, []),
      contractSigner: new FormControl(this.newOwner.contractSigner, [
        Validators.required,
      ]),
    });
  }

  addNewOwner() {
    if (!this.checkForDuplicate(this.newOwnerForm.value)) {
      this.store.dispatch(new CA.CreateByDeal(this.data.dealId, this.newOwnerForm.value));
      this.close();
    }
  }

  checkForDuplicate(newOwner: any) {
    let currentContacts: Contact[] | null;
    this.contacts$.pipe(take(1)).subscribe(contacts => {
      currentContacts = contacts;
    });
    const duplicate = currentContacts!.findIndex(c => c.email === newOwner.email);
    if (duplicate !== -1) {
      this.store.dispatch(new CreateNewAlert({
        level: 'error',
        message: 'Duplicate. An owner with this email address already exists.'
      }));
      this.close();
      return true;
    }
    return false;
  }

  checkError(controlName: string, errorName: string) {
    return this.newOwnerForm.controls[controlName].hasError(errorName);
  }

  close() {
    this.dialogRef.close();
  }
}
