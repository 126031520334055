<!-- Loading spinner -->
@if (businessesLoading$ | async) {
    <div class="overlay-loader">
        <app-dot-loader-scale></app-dot-loader-scale>
    </div>
} @else {
    <div [class.old-theme-businesses]="oldThemeEnabled()">
<mat-card class="overflow-hidden m-4"
          appearance="outlined"
          #businessTable>
    <mat-card-content>
        <div class="flex flex-row items-center py-1 new-theme-typography lendio-density-1">
            @if ((totalCount$ | async) || (totalCount$ | async) === 0) {
                <span class="mat-subtitle-2 !m-0" data-cy="business-count">
                    {{ (totalCount$ | async) | number }}
                    business{{ (totalCount$ | async) === 1 ? "" : "es" }}
                </span>
            }
            @if ((selection.selected.length)) {
                <span class="mat-subtitle-2 !font-normal !my-0 !mx-1 text-lendio-warm-gray-400">
                    @if (selectAll) {
                    (all
                    } @else {
                    ({{ selection.selected.length | number }}
                    }
                    selected)
                </span>
            }
            <div class="flex flex-row flex-grow justify-end items-center">
                <!-- Active filter -->
                <ng-container *ngIf="filterIndicatorText !== ''">
                    <div class="flex flex-row active-filter-indicator">
                        <mat-icon class="blue-bolt-icon" fontIcon="bolt"></mat-icon>
                        <div class="text-sm">{{ filterIndicatorText }}</div>
                    </div>
                    <button
                        (click)="clearTableFilter()"
                        class="remove-active-filter-button"
                    >
                        <mat-icon fontIcon="close" class="close-icon"></mat-icon>
                    </button>
                </ng-container>
                <!-- Filter button -->
                <button
                    *ngIf="hasBizOppFilterFeature$ | async"
                    mat-stroked-button
                    color="accent"
                    [mat-menu-trigger-for]="filterMenu"
                    [disabled]="filterIndicatorText !== ''"
                >
                    <mat-icon>filter_list</mat-icon>Filter
                </button>
                <div class="!pl-2">
                    <button mat-flat-button color="primary" (click)="addBusinessForm()">
                        Add
                    </button>
                </div>
            </div>
        </div>

        <!-- Export button -->
        @if (hasBizOppFilterFeature$ | async) {
            <div class="lendio-density-1">
                <button
                    mat-stroked-button
                    color="accent"
                    (click)="exportToCsv()"
                    [disabled]="exportButtonDisabled()"
                    class="mb-4">
                    Export
                </button>
            </div>
        }

        <!-- Table -->
        <table mat-table
            *ngIf="(businessesDataSource$ | async)?.length !== 0; else noRecords"
            [dataSource]="(businessesDataSource$ | async)"
            lendioColumnSortCache
            matSort
            [matSortActive]="(sortBy$ | async)"
            [matSortDirection]="(sortDirection$ | async)"
            (matSortChange)="handleSortEvent($event)"
            #table="matTable">
            <!-- Checkbox -->
            <ng-container *ngIf="hasBizOppFilterFeature$ | async" matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="
                            (selection.hasValue() && isAllSelected()) ||
                            (selection.hasValue() && !isAllSelected())
                        "
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        color="primary"
                        data-cy="select-all-businesses"
                    >
                    </mat-checkbox>
                </th>
                <td mat-cell class="!w-8" *matCellDef="let business">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(business) : null"
                        [checked]="selectionIds.includes(business.id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Name -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let business">
                    {{ business.name }}
                </td>
            </ng-container>

            <!-- Owner -->
            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="last">Owner</th>
                <td mat-cell *matCellDef="let business">
                    {{ (business.first ?? "") + " " + (business.last ?? "") }}
                </td>
            </ng-container>

            <!-- Stage: Status -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
                    <strong>Stage:&nbsp;</strong> Status
                </th>
                <td mat-cell *matCellDef="let business">
                    {{ businessStageStatus(business) }}
                </td>
            </ng-container>

            <!-- Last activity -->
            <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="activity">
                    Last activity
                </th>
                <td mat-cell *matCellDef="let business">
                    {{ statusDisplayPipe.transform(business.activityType) }}
                    <div class="leading-tight text-gray-500">
                        <span
                            [matTooltip]="activityToolTip(business)"
                            matTooltipPosition="left"
                            matTooltipClass="business-tooltip text-left"
                            aria-label="Last activity for business">
                            {{ business.lastActivity?.ago }}
                        </span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; let business; columns: displayedColumns"
                [class]="(hasBusinessDetailsFeature$ | async) ? 'business-row' : ''"
                (click)="handleRowClick($event, business.id)"
            ></tr>
            <tr class="no-data-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    No data
                </td>
            </tr>
        </table>

        <div *ngIf="(businessesLoading$ | async)"
            [ngStyle]="{'top.px': tableYPosition}"
            id="progress-loader"
            class="w-full h-full flex-wrap justify-content justify-items-center align-items-center absolute left-0 px-4">
            <mat-progress-bar class="block min-w-full" color="primary" mode="indeterminate"></mat-progress-bar>
            <div class="bg-white h-full w-full block opacity-75"></div>
        </div>
        <lendio-paginator #paginator
            (page)="handlePageEvent($event)"
            [length]="(totalCount$ | async)!"
            [pageSize]="(pageSize$ | async)!"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="(pageIndex$ | async)!"
            [showFirstLastButtons]="true">
        </lendio-paginator>
    </mat-card-content>
</mat-card>

<!-- Filter mat-menu -->
<mat-menu #filterMenu class="businesses-filter-menu">
    <!-- Opportunities -->
    <button mat-menu-item
            (click)="applyFilter({label: 'Opportunities', value: 'opportunities'})"
            class="businesses-filter-menu-item">
        <mat-icon matPrefix class="blue-bolt-icon alt">bolt</mat-icon>
        <div class="text-sm">Opportunities</div>
        <div class="text-xs pre-qualified">See who’s pre-qualified</div>
    </button>
    <!-- Not eligible -->
    <button mat-menu-item
            (click)="applyFilter({label: 'Not eligible', value: 'notEligible'})"
            class="businesses-filter-menu-item">
        <mat-icon matPrefix class="blue-bolt-icon alt">bolt</mat-icon>
        <div class="text-sm">Not eligible</div>
        <div class="text-xs pre-qualified">See who’s not qualified</div>
    </button>
</mat-menu>


<!-- Lender has no businesses -->
<ng-template #noRecords>
    <mat-card class="m-4" appearance="outlined">
        <mat-card-header>
            <mat-card-title>{{ (businesses$ | async)?.length }} businesses</mat-card-title>
        </mat-card-header>
        <div class="lendio-mat-card-divider"></div>
        <mat-card-content>
            <div class="m-auto">
                <img class="m-auto pb-4" src="/assets/images/FAQ_Illo 1.svg" />
                <div class="text-center font-normal text-gray-400">
                    You haven’t imported or added any businesses yet.
                    <p class="text-center text-sm text-lendio-warm-gray-400">
                        To get started, go to
                        <a class="import-link" routerLink="/administration/import">Import</a>.
                    </p>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>
</div>
}
