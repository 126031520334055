<!-- Padding is required to show shadows off mat cards -->
<div class="overflow-x-auto p-1">
    <!-- Min width here is a hack to make the table have a scrollbar at small sizes but maintain proportions -->
    <div class="flex" style="min-width: 596px;">
        <div class="w-8/12 font-bold self-center">
            {{ header }}
        </div>
        <div class="w-4/12 text-center bg-gray-200 p-2 rounded-t-md">
            Competitor Offer
        </div>
    </div>

    <!-- Min width here is a hack to make the table have a scrollbar at small sizes but maintain proportions -->
    <mat-card appearance="outlined" class="!p-0 overflow-hidden" style="min-width: 596px;">
        <table class="w-full text-left">
            <thead class="bg-gray-300">
                <tr>
                    <th class="px-6 py-4 w-3/12">Business Name</th>
                    <th class="px-6 py-4">Rate</th>
                    <th class="px-6 py-4">Amount</th>
                    <th class="px-6 py-4">Type</th>
                    <th class="px-6 py-4 w-2/12">Size</th>
                    <th class="px-6 py-4 w-2/12">Type</th>
                </tr>
            </thead>
            <tr *ngFor="let deal of deals">
                <td class="px-6 py-4">
                    {{ deal.name }}
                </td>
                <td class="px-6 py-4">
                    {{ deal.yourRate }}
                </td>
                <td class="px-6 py-4">
                    {{ deal.yourAmount }}
                </td>
                <td class="px-6 py-4">
                    {{ deal.yourCategory }}
                </td>
                <td class="px-6 py-4 bg-gray-200">
                    {{ deal.competitorAmount }}
                </td>
                <td class="px-6 py-4 bg-gray-200">
                    {{ deal.competitorCategory }}
                </td>
            </tr>
        </table>
    </mat-card>
</div>