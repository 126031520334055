import { Injectable } from '@angular/core';
import { throttle } from 'underscore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isLoading = new BehaviorSubject<boolean>(false);
  public setLoading: (value: boolean) => void;

  private delayTime = 2000;

  constructor() {
    this.setLoading = throttle((value: boolean) => {
      this.isLoading.next(value);
    }, this.delayTime);
  }
}
