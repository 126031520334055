<div class="notification-drawer" data-cy="notificationDrawer">
    <div
        class="notification-drawer__header flex-col flex justify-between pt-4 px-4 border-gray-300 border-b w-full gap-y-2"
    >
        <div
            class="notification-drawer__header__title flex flex-row justify-between items-center w-full"
        >
            <div class="notification-drawer__header__title__title-gear flex flex-row gap-x-4">
                <span class="mat-subtitle-1">Notifications</span>
                <a
                    href="javascript:;"
                    (click)="router.navigate(['/settings/notifications'])"
                    aria-label="Go to notification settings"
                    class="text-cool-gray-300 flex items-center"
                    ><mat-icon class="material-symbols-outlined h-5 w-5">settings</mat-icon></a
                >
            </div>
            <a
                href="javascript:;"
                (click)="toggleOpen()"
                class="notification-drawer__header__close cursor-pointer block"
            >
                <span class="flex items-center p-2"><mat-icon class="h-3.5 w-3.5">close</mat-icon></span>
            </a>
        </div>
        <div
            class="notification-drawer__header__bulk-actions flex flex-row w-full justify-end py-1.5"
        >
            <a
                href="javascript:;"
                *ngIf="
                    (notifications && notifications.length > 0 && !allRead)
                "
                (click)="markAllRead()"
                [ngClass]="{
                    'cursor-pointer text-blue-400 hover:text-blue-600':
                        !togglingAllRead,
                    'text-gray-400 cursor-default': togglingAllRead
                }"
                class="notification-drawer__header__title__action focus:text-blue-700 focus:outline-none px-4 py-1.5 text-sm"
            >
                Mark all as read
            </a>
            <a
                href="javascript:;"
                *ngIf="
                    (notifications && notifications.length > 0)
                "
                (click)="deleteAll()"
                [ngClass]="{
                    'cursor-pointer text-blue-400 hover:text-blue-600':
                        !deletingAll,
                    'text-gray-400 cursor-default': deletingAll
                }"
                class="notification-drawer__header__title__action focus:text-blue-700 focus:outline-none px-4 py-1.5 text-sm"
            >
                Clear all
            </a>
        </div>
    </div>

    <div class="notification-drawer__content">
        <div
            [@fadeInOutFloat]
            *ngIf="!notifications || notifications.length === 0"
            class="notification-drawer__content__message p-8 mat-body-1 text-cool-gray-400 bg-white text-center"
        >
            <div *ngIf="notifications">It's all good –<br />You're all caught up.</div>
            <app-dot-loader-scale
                *ngIf="!notifications"
                class="mx-auto"
            ></app-dot-loader-scale>
        </div>

        <div
            *ngFor="let notification of notifications; trackBy: trackByFn"
            [@fadeSlideInOut]
        >
            <app-notification-item-smart [notification]="notification">
            </app-notification-item-smart>
        </div>
    </div>
</div>
