import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { ApplicationsListService } from '../../../services/applications-list.service';
import { throttle } from 'lodash';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';

interface SearchResult {
  borrower: any;
  primaryContactName: string;
  businessName: string;
}

@Component({
  selector: 'app-applications-search',
  templateUrl: './applications-search.component.html',
  styleUrls: ['./applications-search.component.scss']
})
export class ApplicationsSearchComponent implements OnInit {

  searchTerm: string | number | boolean;
  searchResults: SearchResult[];
  hasSearched = false;
  searching = false;
  errored = false;
  newSearch = false;

  constructor(
    private applicationsService: ApplicationsListService,
    private store: Store,
  ) {
    this.search = throttle(this.search, 300);
  }
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary'

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
    this.newSearch = (this.store.selectSnapshot(AuthState.user)?.permittedTo('lpxNewBusinessSearch.read') ?? false);
  }

  search() {
    if (this.searchTerm &&
        typeof this.searchTerm === 'string' &&
        this.searchTerm.length >= 3) {
      this.searching = true;
      this.applicationsService.searchApplications(this.searchTerm).pipe(take(1)).subscribe((res) => {
        this.searching = false;
        this.hasSearched = true;
        this.errored = false;

        if (res.data && res.data.length === 0) {
          this.searchResults = [];
        } else {
          this.searchResults = res.data.map((result: any) => {
            const routerLink = this.newSearch ? `businesses/${result?.id}` : `deal/${result?.dealId}`
            return {
              result,
              routerLink,
              primaryContactName: result.cname,
              businessName: result.name,
            }
          });
        }
      }, (res) => {
        this.searchResults = [];
        this.searching = false;
        this.errored = true;
        this.hasSearched = true;
      });
    }
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }

}
