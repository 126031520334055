<h2 mat-dialog-title class="!p-4">{{componentValues.title}}</h2>
<mat-dialog-content>
	<form [formGroup]="portalUserForm">
		<div class="flex">
			<div class="flex-1 !p-2">
                @if (newThemeEnabled()) {
                    <p class="col-header">First Name</p>
                }
				<mat-form-field [appearance]="(newThemeEnabled()) ? 'outline' : 'fill'"
                                floatLabel="always"
                                class="w-full">
                    @if (newThemeEnabled()) {
                        <mat-label>First Name</mat-label>
                    }
					<input matInput type="text" required formControlName="first" data-cy="user-first-input">
					<mat-error data-cy="user-first-required" *ngIf="checkError('first', 'required')">First name is required</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-1 !p-2">
                @if (newThemeEnabled()) {
                    <p class="col-header">Last Name</p>
                }
				<mat-form-field [appearance]="(newThemeEnabled()) ? 'outline' : 'fill'"
                                floatLabel="always"
                                class="w-full">
                    @if (newThemeEnabled()) {
                        <mat-label>Last Name</mat-label>
                    }
					<input matInput
                           type="text"
                           required
                           formControlName="last"
                           data-cy="user-last-input">
					<mat-error data-cy="user-last-required" *ngIf="checkError('last', 'required')">Last name is required</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="flex" [class.mt-6]="newThemeEnabled()">
			<div class="flex-1 !p-2">
                @if (newThemeEnabled()) {
                    <p class="col-header">Email</p>
                }
				<mat-form-field [appearance]="(newThemeEnabled()) ? 'outline' : 'fill'"
                                floatLabel="always"
                                class="w-full">
                    @if (newThemeEnabled()) {
                        <mat-label>Email</mat-label>
                    }
					<input matInput required formControlName="email" email data-cy="user-email-input">
					<mat-error data-cy="user-email-required" *ngIf="checkError('email', 'required')">Email is required</mat-error>
					<mat-error data-cy="user-email-valid" *ngIf="checkError('email', 'email')">Must be a valid email address</mat-error>
                    <mat-error data-cy="user-email-dupe" *ngIf="isDupeUser">This email is tied to another user</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-1 !p-2">
                <span class="col-header flex items-center">
                    <mat-checkbox
                        [checked]="portalUser.manageLenderUsers"
                        formControlName="manageLenderUsers"
                        color="primary"
                        data-cy="user-manage-checkbox">
                    </mat-checkbox>
                    Admin User <mat-icon style="font-size:20px;" class="text-label-500 ml-1">star</mat-icon>
                </span>
                <span class="leading-2 text-xs text-gray-500">Admins can add and edit users and manage user permissions</span>
			</div>
		</div>
		<div *ngIf="isEmbedded" class="mx-2 mb-8 border-solid border-t border-gray-300 ">
			<h2 class="pt-5 text-gray-500">Embedded options</h2>
			<div class="flex">
				<div class="flex-1">
					<span class="col-header flex items-center">Manage styles </span>
					<mat-checkbox
						[checked]="portalUser.manageStyles"
						formControlName="manageStyles"
						color="primary">
					</mat-checkbox>
				</div>
				<div class="!pl-4 flex-1">
					<span class="col-header flex items-center">Manage legal </span>
					<mat-checkbox
						[checked]="portalUser.manageLegal"
						formControlName="manageLegal"
						color="primary">
					</mat-checkbox>
				</div>
				<div class="!pl-4 flex-1">
					<span class="col-header flex items-center">Manage webhooks </span>
					<mat-checkbox
						[checked]="portalUser.manageWebhooks"
						formControlName="manageWebhooks"
						color="primary">
					</mat-checkbox>
				</div>
			</div>
		</div>
		<div class="flex">
			<div class="flex-1 text-right">
				<button mat-button (click)="close()">Cancel</button>
				<button
					mat-flat-button
					color="primary"
					(click)="handleSubmit()"
					[disabled]="portalUserForm.invalid"
					class="!ml-2"
					data-cy="edit-user-submit">
					{{componentValues.submit}}
				</button>
			</div>
		</div>
	</form>
</mat-dialog-content>
