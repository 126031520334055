import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { PortalUser } from '../../../interfaces/portal-user.model';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit, OnChanges {

  @Input() portalUsers: PortalUser[];
  @Input() usersAssigned: number[];
  @Output() assignmentChange = new EventEmitter<PortalUser[]>();

  selection = new SelectionModel<PortalUser>(true, []);

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  initialize() {
    this.selection.clear();

    if (this.usersAssigned) {
      this.usersAssigned.forEach((userId) => {
        const user = this.getPortalUser(userId);

        if (user) {
          this.selection.select(user);
        }
      });
    }
  }

  getPortalUser(portalUserId: number): PortalUser | null{
    const index = this.portalUsers.findIndex(x => x.id === portalUserId);

    if (index !== -1) {
      return this.portalUsers[index];
    }

    return null;
  }

  getDisplay(): string {
    let others: string;

    switch (this.selection.selected.length) {
      case 0:
        return 'Unassigned';
      case 1:
        others = '';
        break;
      case 2:
        others = ' + 1 other';
        break;
      default:
        others = ' + ' + (this.selection.selected.length - 1) + ' others';
        break;
    }

    if (this.isAssignedToMe()) {
      return 'You' + others;
    }

    return this.selection.selected[0].fullName + others;
  }

  isAssignedToMe(): boolean {
    let assignedToMe = false;

    this.selection.selected.forEach((user) => {
      if (user.id === this.store.selectSnapshot(AuthState.user)?.id) {
        assignedToMe = true;
      }
    });

    return assignedToMe;
  }

  menuClosed() {
    this.assignmentChange.emit(this.selection.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.portalUsers || changes.usersAssigned) {
      this.initialize();
    }
  }
}
