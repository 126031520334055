<div class="lendio-paginator-wrapper">
    <div class="lendio-paginator-section" [class.no-buttons]="pages.length <= 1">
        <button class="lendio-page-nav-button"
                (click)="previousPage()"
                [disabled]="pageIndex === 0">
            <mat-icon class="lendio-page-nav-icon"
                      fontSet="material-icons-outlined">
                <span>arrow_back_ios</span>
            </mat-icon>
        </button>
        <ng-container *ngIf="showBeforeEllipse">
            <button class="lendio-page-button first"
                    (click)="emitPageEvent(0)">
                <span>1</span>
            </button>
            <span class="truncated-ellipse">&hellip;</span>
        </ng-container>
        <button class="lendio-page-button"
                *ngFor="let page of getPaginatorPages();
                        let first = first;
                        let last = last"
                [class.active-page]="pageIndex === page"
                [ngClass]="{
                    'first': !showBeforeEllipse && first,
                    'last': !showAfterEllipse && last
                }"
                (click)="emitPageEvent(page)">
            {{ page + 1 }}
        </button>
        <ng-container *ngIf="showAfterEllipse">
            <span class="truncated-ellipse">&hellip;</span>
            <button class="lendio-page-button last"
                    [class.active-page]="pageIndex === pages.length - 1"
                    (click)="emitPageEvent(pages.length - 1)">
                <span>{{ pages.length }}</span>
            </button>
        </ng-container>
        <button class="lendio-page-nav-button"
                (click)="nextPage()"
                [disabled]="pageIndex === pages.length - 1">
            <mat-icon class="lendio-page-nav-icon"
                      fontSet="material-icons-outlined">
                <span>arrow_forward_ios</span>
            </mat-icon>
        </button>
    </div>
    <div class="lendio-paginator-section rows">
        <div class="row-count-select">
            <div class="row-select-text">Rows per page</div>
            <mat-form-field appearance="outline" class="lendio-paginator-select">
                <mat-select [ngModel]="pageSize"
                            hideSingleSelectionIndicator
                            (ngModelChange)="_changePageSize($event)">
                    <mat-option *ngFor="let size of pageSizeOptions"
                                class="paginator-option"
                                [value]="size">
                        {{ size }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="row-select-text">{{ pageIndex + 1 }} of {{ pages.length === 0 ? 1 : pages.length }}</div>
        </div>
    </div>
</div>
