import { NgModule } from '@angular/core';
import { ToDateObjPipe } from './to-date-obj.pipe';

@NgModule({
    imports: [],
    exports: [ToDateObjPipe],
    declarations: [ToDateObjPipe],
    providers: [ToDateObjPipe],
})
export class ToDateObjPipeModule { }
