import { Directive, ViewContainerRef, OnInit, OnDestroy } from "@angular/core";
import { Store } from '@ngxs/store';
import { MatSort, Sort } from "@angular/material/sort";
import { ListSortData } from "@app/app/interfaces/list-sort.model";
import { ColumnSortState } from "@app/app/store/column-sort/column-sort.state";
import { UpdateSort } from "@app/app/store/column-sort/column-sort.actions";
import { Observable, Subject, filter, takeUntil } from "rxjs";

@Directive({
    selector: '[lendioColumnSortCache]',
})

export class ColumnSortCacheDirective implements OnInit, OnDestroy {

    sort$: Observable<ListSortData>;
    private destroyed$: Subject<void> = new Subject<void>();
    private attrs;

    constructor(
        private el: MatSort,
        private store: Store,
        private _viewContainerRef: ViewContainerRef
    ) {
        this.attrs = this._viewContainerRef.element?.nativeElement?.attributes;
    }

    ngOnInit(): void {
        this.sort$ = this.store.select(ColumnSortState.getSortByPath(this.pathName));
        if (this.matSort) {
            this.matSort.pipe(
                filter(sort => !!sort),
                takeUntil(this.destroyed$)
            ).subscribe(s => {
                this.el.active = s.active || this.attrs.matsortactive?.value;
                this.el.direction = s.direction || this.attrs.matsortdirection?.value;
            });
        }
        this.el.sortChange.subscribe((sort: Sort) => {
            this.store.dispatch(new UpdateSort({
                path: this.pathName,
                active: sort.active || '',
                direction: sort.direction || ''
            }));
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    get matSort(): Observable<ListSortData> {
        return this.sort$;
    }

    get pathName(): string {
        return window.location.pathname;
    }

}
