<!-- [notifications]="notifications$ | async" -->
<ng-container *ngIf="newTheme; else OldTheme">
    <button mat-icon-button
            (click)="toggle()">
        <mat-icon color="accent"
                  [class.notification-badge-empty]="!!(count$ | async)"
                  fontSet="material-icons-outlined">
            <span>notifications_active</span>
        </mat-icon>
    </button>
</ng-container>
<ng-template #OldTheme>
    <app-notification-icon
        [count]="(count$ | async)!"
        [expanded]="expanded"
        (toggle)="toggle()"
    ></app-notification-icon>
</ng-template>
