import { LenderUser } from '@app/app/interfaces/lender-user.model';

export class GetLenderUser {
  static readonly type = '[LenderUser] GetLenderUser';
  constructor() {}
}

export class GetLenderUsers {
  static readonly type = '[LenderUser] GetLenderUsers';
  constructor() {}
}

export class CreateLenderUser {
  static readonly type = '[LenderUser] CreateLenderUser';
  constructor(
    public newLenderUser: Partial<LenderUser>
  ) {}
}

export class DeleteLenderUser {
  static readonly type = '[LenderUser] DeleteLenderUser';
  constructor(
    public userId: number,
  ) {}
}

export class UpdateLenderUser {
  static readonly type = '[LenderUser] UpdateLenderUser';
  constructor( public userId: number, public settings: {} ) {}
}

export class GoOnline {
  static readonly type = '[LenderUser] GoOnline';
  constructor( public userId: number ) {}
}

export class GoOffline {
  static readonly type = '[LenderUser] GoOffline';
  constructor( public userId: number ) {}
}

export class UpdatePausedWorkflow {
  static readonly type = '[LenderUser] UpdatePausedWorkflow';
  constructor( public userId: number, public meta: {} ) {}
}
