import { FilterData } from '@app/app/interfaces/filters.model';

export class DeletePathFilter {
    static readonly type = '[Filters] DeletePathFilter';
    constructor(
        public filter: FilterData
    ) {}
}

export class InitFilters {
    static readonly type = '[Filters] InitFilters';
    constructor(
        public path: string
    ) {}
}

export class SetPathFilter {
    static readonly type = '[Filters] SetPathFilter';
    constructor(
        public filter: FilterData
    ) {}
}


export class UpdatePathFilters {
    static readonly type = '[Filters] UpdatePathFilters';
    constructor(
        public payload: FilterData[]
    ) {}
}
