import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, computed } from '@angular/core';
import { FundingDeskRequest } from 'src/app/interfaces/funding-desk-request.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-funding-desk-old',
  templateUrl: './funding-desk-old.component.html',
  styleUrls: ['./funding-desk-old.component.scss']
})
export class FundingDeskOldComponent implements OnInit {

  @ViewChild('fdPanel', { static: true })
  fdPanel: MatExpansionPanel;

  @ViewChild('requestTextArea', { static: true })
  requestTextArea: ElementRef;

  @Input() fundingDeskRequests: FundingDeskRequest[];
  @Output() createNew = new EventEmitter<{ description: string, type: string }>();
  @Output() newComment = new EventEmitter<{ description: string, requestId: number }>();

  newRequest = '';
  type = '';
  expandStatus = true;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) {}

  ngOnInit(): void {}

  createNewRequest(description: string) {
    this.createNew.emit({description, type: this.type});
    this.newRequest = '';
    this.expandStatus = false;
  }

  addNewComment(comment: { description: string, requestId: number }) {
    this.newComment.emit(comment);
  }

  cancelComment() {
    this.expandStatus = false;
    this.newRequest = '';
    this.requestTextArea.nativeElement.blur();
  }

  handleAfterExpand(): void {
    this.requestTextArea.nativeElement.focus();
  }

  handleAfterCollapse(): void {
    this.requestTextArea.nativeElement.blur();
  }
}
