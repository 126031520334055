import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { take } from 'rxjs/operators';
import { NaicsSearchService } from 'src/app/services/naics-search.service';
import * as _ from 'underscore';
import { NaicsObject } from 'src/app/interfaces/naics-object.model';

@Component({
  selector: 'app-naics-search',
  templateUrl: './naics-search.component.html',
  styleUrls: ['./naics-search.component.scss']
})
export class NaicsSearchComponent implements OnInit {

  @ViewChild('searchBox') searchBox: MatInput;

  @Output() codeChange = new EventEmitter<number>();

  @Input()
  get code() {
    return this.naicsCode;
  }

  set code(val) {
    this.naicsCode = val;
    this.codeChange.emit(this.naicsCode);
  }

  naicsCode: number;
  loading = true;
  searchText: string;
  searchResults: NaicsObject[] = [];
  searching = false;
  hasSearched = false;
  errored = false;

  constructor(private naicsSearch: NaicsSearchService) {
    this.search = _.debounce(this.search, 600);
  }

  ngOnInit(): void {
    if (this.code && this.code.toString().length === 6) {
      this.naicsSearch.searchByCode(this.code).pipe(take(1)).subscribe((res) => {
        // If we find a match, throw in the text
        if (res && 'data' in res && res.data.hasOwnProperty('code')) {
          this.searchText = res.data.title;
        }

        this.loading = false;
        this.errored = false;
      }, (err) => {
        // If the request errors, just let the text be blank
        this.loading = false;
        this.errored = true;
      });
    } else {
      // Invalid code, might be beneficial to show an error state
      this.loading = false;
    }
  }

  search(): void {
    if (this.searchText.length >= 3) {
      this.searching = true;
      this.hasSearched = true;
      this.naicsSearch.searchByTerms(this.searchText).pipe(take(1)).subscribe((res) => {
        this.searchResults = res.data;
        this.searching = false;
        this.errored = false;
      }, (err) => {
        this.searching = false;
        this.errored = true;
      });
    }
  }

  getExamples(examples: any[]): string {
    if (!examples) {
      return '';
    }

    let response = 'Examples:\n\n';

    examples.forEach((example) => {
      response += example + '\n';
    });

    return response;
  }

  setCode(code: number, text: string): void {
    this.searchText = text;
    this.code = code;
    this.searchResults = [];
  }

}
