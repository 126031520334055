import { getLocaleCurrencyCode, getLocaleCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocaleSettingService {

    private locale: string;

    constructor() {
        this.locale = environment.locale;
    }

    getLocale() {
        return this.locale;
    }

    getCurrencyCode() {
        return getLocaleCurrencyCode(this.locale);
    }

    getCurrencySymbol() {
        return getLocaleCurrencySymbol(this.locale);
    }
}

