import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, map } from 'rxjs';

export interface PartnersResponse {
  status: string;
  statusCode: number;
  data: {
    id: number,
    name: string,
    type: string,
  }[];

}
export interface LenderUsersAdminResponse {
  status: string;
  statusCode: number;
  data: {
    first: string,
    last: string,
    id: number,
    gravatar: string,
  }[];
}

export interface AffiliateUsersResponse {
  status: string;
  statusCode: number;
  data: {
    id: number,
    first: string,
    last: string,
    fullName: string,
    user: {
      email: string,
    },
    permissions: string[],
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class HijackService {

  constructor(private http: HttpClient) { }

  getPartners(name: string): Observable<PartnersResponse> {
    return this.http.get<PartnersResponse>(`${environment.apiUrl}/lender-portal/partners`, {params: {name}});
  }

  getInstitutionUsers(lenderId: number): Observable<LenderUsersAdminResponse> {
    return this.http.get<LenderUsersAdminResponse>(`${environment.apiUrl}/l/v2/internal/lender-users-admin/${lenderId}`);
  }

  getAffiliateUsers(affiliateId: number): Observable<AffiliateUsersResponse> {
    return this.http.get<AffiliateUsersResponse>(`${environment.apiUrl}/affiliate/${affiliateId}/users`)
      .pipe(map(this._removeUsersWithoutPermission));
  }

  private _removeUsersWithoutPermission(response: AffiliateUsersResponse): AffiliateUsersResponse {
    response.data = response.data.filter(user => user.permissions.includes('partnerPortalAccess'));
    return response;
  }
}
