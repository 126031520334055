import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EmailSetting } from '../interfaces/email-setting.model';
import { NewPassword } from '../interfaces/new-password.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService{

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getEmailNotificationSettings(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/l/v2/internal/settings/email-notifications`);
  }

  updateEmailSetting(setting: EmailSetting) {
    return this.http.put(`${this.apiUrl}/l/v2/internal/settings/email-notifications`, setting);
  }

  newPassword(newPass: NewPassword) {
    return this.http.put(`${this.apiUrl}/l/v2/internal/settings/reset-password`, newPass);
  }

  newSbaToken(sbaToken: string, lenderId: number) {
    return this.http.put(`${this.apiUrl}/lender/${lenderId}`, sbaToken);
  }
}
