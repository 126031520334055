import { Label } from '../../interfaces/label.model';

export class GetApplications {
    static readonly type = '[ApplicationsList] GetApplications';
    constructor(public section: string = 'new-apps',
                public sortBy: string = 'created',
                public sortDirection: string = 'desc',
                public page: number = 0,
                public limit: number = 25) {}
}

export class MarkAsQueued {
    static readonly type = '[ApplicationsList] MarkAsQueued';
    constructor(public submitList: number[]) {}
}

export class UnmarkAsQueued {
    static readonly type = '[ApplicationsList] UnmarkAsQueued';
    constructor(public submitList: number[]) {}
}

export class GetTabData {
    static readonly type = '[ApplicationsList] GetTabData';
    constructor(){}
}

export class SetSection {
    static readonly type = '[ApplicationsList] SetSection';
    constructor(public section: string) {}
}

export class SetPageNumber {
    static readonly type = '[ApplicationsList] SetPageNumber';
    constructor(public pageNumber: number) {}
}

export class ClearApplications {
    static readonly type = '[ApplicationsList] ClearApplications';
    constructor() {}
}

export class DetachLabel {
    static readonly type = '[ApplicationsList] DetachLabel';
    constructor(public labelId: number, public dealId: number) {}
}

export class DetachMultipleLabels {
    static readonly type = '[ApplicationsList] DetachMultipleLabels';
    constructor(public labelIds: number[], public dealId: number) {}
}

export class AttachLabel {
    static readonly type = '[ApplicationsList] AttachLabel';
    constructor(public label: Label, public dealId: number) {}
}

export class AttachMultipleLabels {
    static readonly type = '[ApplicationsList] AttachMultipleLabels';
    constructor(public labels: Label[], public dealId: number) {}
}

export class UpdateAssignments {
    static readonly type = '[ApplicationsList] UpdateAssignments';
    constructor(public newAssignments: number[], public dealId: number) {}
}
