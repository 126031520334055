<div [class.old-theme-turn-down-dialog]="oldThemeEnabled()">
	<h2 mat-dialog-title class="!p-4">Turn Down PPP App</h2>
	<mat-dialog-content>
		<div class="loading" *ngIf="loading; else ready">
			<app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
		</div>
		<ng-template #ready>
			<p>Please tell us why you're turning this application down.</p>
			<mat-form-field class="decline-reasons-select">
				<mat-label>Select a reason...</mat-label>
				<mat-select [formControl]="declineReasonSelect" [(value)]="selectedReason"  (selectionChange)="onDeclineReasonsChange()" data-cy="decline-reason-select">
					<mat-option
						*ngFor="let reason of declineReasons; let i = index"
						[value]="reason.reason"
						attr.data-cy="reason-{{i}}"
					>{{ reason.reason }}</mat-option>
				</mat-select>
			</mat-form-field>

			<div *ngIf="subReasons[selectedReason]">
				<p>Select 1 or more reasons for {{ selectedReason }}</p>
				<mat-form-field class="decline-reasons-select">
					<mat-label>Select a reason...</mat-label>
					<mat-select multiple [formControl]="subReasonSelect" [(value)]="selectedSubReasons" data-cy="sub-reason-select">
						<mat-option
							*ngFor="let reason of subReasons[selectedReason]; let i = index"
							[value]="reason.subReason"
							attr.data-cy="sub-reason-{{i}}"
						>{{ reason.subReason }}</mat-option>
					</mat-select>
				</mat-form-field>

				<p>Additional Notes (optional)</p>
				<mat-form-field appearance="fill" class="decline-comment-text">
					<textarea
						rows="5"
						matInput
						placeholder="Notes"
						[(ngModel)]="comment"
						[ngModelOptions]="{standalone: true}"
					></textarea>
				</mat-form-field>
			</div>
		</ng-template>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button class="!mb-2" mat-dialog-close="">Cancel</button>
		<button
			mat-flat-button
			color="warn"
			class="!ml-3 !mb-2"
			[disabled]="(subReasons[selectedReason] && selectedSubReasons.length == 0) || !selectedReason || loading"
			(click)="submit()"
			[class.spinner]="loading">
			{{ loading ? '&nbsp;' : 'Turn Down' }}
		</button>
	</mat-dialog-actions>
</div>
