import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/auth.state';
import { environment } from 'src/environments/environment';
import { TrackingPayload } from 'src/app/interfaces/tracking.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class TrackingService {
    private baseApi = `${environment.apiUrl}`;

    constructor(
        private httpClient: HttpClient,
        private store: Store,
        ) { }
    /**
     * Post form events to kinesis stream
     * @param {string} event - name of the event.
     */
    logEvent(event: string, payload: object = {}): Observable<any> {
        const eventName = `partner.${event}`;
        const url = `${this.baseApi}/kinesis/event`
        const authUser = this.store.selectSnapshot(AuthState.user);
        const defaultPayload: TrackingPayload = {
            user: {
                calculatedPermissions: authUser?.calculatedPermissions,
                email: authUser?.email,
                first: authUser?.first,
                last: authUser?.last,
                fullName: authUser?.fullName,
                role: authUser?.role,
                tenantId: authUser?.tenantId,
                permissionsList: authUser?.permissionsList,
                isPartner: authUser?.isPartner(),
                isLender: authUser?.isLender(),
                hasEmbeddedChildAffiliate: authUser?.hasEmbeddedChildAffiliate(),
                hasIntelligentChildAffiliate: authUser?.hasIntelligentChildAffiliate()
            }
        };

        payload = {
            ...payload,
            ...defaultPayload
        };

        const requestBody = {
            visitorId: this.getVisitorId(),
            userId: authUser?.id,
            lenderId: authUser?.institution?.id,
            affiliateId: authUser?.affiliate?.id ?? null,
            context: this.getContext(),
            payload
        };
        
        const options = { 
            params: new HttpParams().set('event', eventName),
            reportProgress: true
        };

        return this.httpClient.post(url, requestBody, options);
    }

    getContext() {
        return {
            locale: window.navigator.language,
            userAgent: window.navigator.userAgent,
            page: {
                hash: window.location.hash,
                path: window.location.pathname,
                referrer: window.document.referrer,
                search: window.document.location.search,
                title: window.document.title,
                url: window.document.URL,
            },
            screen: {
                height: window.screen.height,
                width: window.screen.width,
            },
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }


    getVisitorId() {
        const anonymousIdName = `${environment.environment}_lendioPartnerPortalVisitorId`;
        const existingId = window.localStorage.getItem(anonymousIdName);
        const prepend: string = environment.environment;

        if (existingId) {
            return existingId;
        }
            
        const randomId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function(c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
            }
        );
    
        const id = environment.production ? randomId : `${prepend}-${randomId}`;
        window.localStorage.setItem(anonymousIdName, id);
    
        return id;
    }
}