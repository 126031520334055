import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TurnDownButtonComponent } from './turn-down-button/turn-down-button.component';
import { TurnDownButtonSmartComponent } from './turn-down-button-smart/turn-down-button-smart.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TurnDownDialogComponent } from './turn-down-dialog/turn-down-dialog.component';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";

@NgModule({
  declarations: [
    TurnDownButtonSmartComponent,
    TurnDownButtonComponent,
    TurnDownDialogComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    LoadersModule,
    MatInputModule
  ],
  exports: [
    TurnDownButtonSmartComponent
  ]
})
export class TurnDownButtonModule { }
