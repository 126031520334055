import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OldDeclineDialogComponent } from '../decline-dialog/old-decline-dialog.component';

@Component({
  selector: 'app-decline-button',
  templateUrl: './decline-button.component.html',
  styleUrls: ['./decline-button.component.scss']
})
export class DeclineButtonComponent implements OnInit {

  @Input() dealId: number;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDeclineDialog(): void {
    const dialogRef = this.dialog.open(OldDeclineDialogComponent, {
      width: '450px',
      data: { dealId: this.dealId }
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
