<div [class.old-theme-loc-term]="oldThemeEnabled()">
    <div class="offer-container" #LocTermApprovalComponent>
        <!-- TODO: Maybe refactor this to better responsive layout? -->
        <!-- [ngClass]="{'tiny': width < 900}" (window:resize)="onResize(false)"> -->
    
        <div>
            <div *ngFor="let benefit of offer?.benefits; let i=index" class="inline-block">
                <div *ngIf="i<3" class="
                  border
                  border-green-600
                  bg-green-600
                  text-white
                  rounded-t-md
                  font-thin
                  px-2
                  py-1
                  mr-0.5">
                    {{ benefit?.title }}
                    <mat-icon matTooltip="{{ benefit?.description }}" matTooltipPosition="above"
                        class="mat-info-icon align-middle">info_outline
                    </mat-icon>
                </div>
            </div>
        </div>
    
        <mat-expansion-panel class="mat-elevation-z" [ngClass]="offer?.benefits.length > 0 ? 'has-benefits' : ''"
            [expanded]="initializeExpanded" [togglePosition]="'before'">
            <mat-expansion-panel-header>
                    <div *ngIf="offer.isTest" class="flex items-center justify-center">
                        <div class="grow-0 test-indicator-label px-2
                            bg-gray-500 text-white font-bold">
                            Test
                        </div>
                    </div>
                    <!-- LIMIT -->
                    <div class="offer-field" data-cy="offer-field">
                        <div *ngIf="calculationType === 'loc'">
                            <div class="text-center offer-field-header text-secondary max-amount-header">LIMIT</div>
                        </div>
                        <div *ngIf="calculationType === 'term'">
                            <div class="text-center offer-field-header text-secondary max-amount-header">AMOUNT</div>
                        </div>
    
                        <div class="text-center font-bold">
                            {{ maxOption?.limits.drawAmount.max | currency:'USD':'symbol':'1.0-0' }}
                        </div>
                    </div>
                    <!-- TERM -->
                    <div class="offer-field" data-cy="offer-field">
                        <div class="text-center offer-field-header text-secondary max-amount-header">TERM</div>
                        <div class="text-center font-bold">{{ maxOption?.term }}</div>
                    </div>
                    <!-- CENTS / $ -->
                    <div class="offer-field" data-cy="offer-field">
                        <div class="text-center offer-field-header text-secondary max-amount-header"><span class="font-mono">~</span> CENTS / $</div>
                        <div class="text-center font-bold">{{ staticCentsOnTheDollar }}</div>
                    </div>
                    <!-- APR -->
                    <div class="offer-field" data-cy="offer-field">
                        <div class="text-center offer-field-header text-secondary max-amount-header">INTEREST RATE</div>
                        <div class="text-center font-bold">{{ this.annualRate | number : '1.2-2' }}%</div>
                    </div>
                    <!-- STIPS -->
                    <div class="offer-field" data-cy="offer-field">
                        <div class="text-center offer-field-header text-secondary max-amount-header">STIPS</div>
                        <div class="text-center font-bold">{{ stipulationCount }}</div>
                    </div>
                    <!-- POINTS -->
                    <div class="offer-field" data-cy="offer-field">
                        <div class="text-center offer-field-header text-secondary header max-amount-header">POINTS</div>
                        <div class="text-center font-bold">{{ maxOption?.limits.points.max }}</div>
                    </div>
            </mat-expansion-panel-header>
    
            <!-- Content -->
            <div class="relative">
                <div *ngIf="errored" class="error-message">
                    <div>
                        <p>
                            There is currently an integration error with <strong>{{ offer.loanProductLender }}</strong>.
                            <span *ngIf="!offer.offerLink">Calculations for this approval are unavailable at this
                                time.</span>
                            Please contact Lendio product and development.
                        </p>
                        <div *ngIf="offer.offerLink">
                            To continue calculations for this approval, follow this link:
                            <div>
                                <a href="{{ offer.offerLink }}" ref="noopener noreferrer nofollow" target="_blank">{{
                                    offer.offerLink }}</a>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div [ngClass]="{'error-blur': errored}" *ngIf="!initializing" class="flex flex-row">
                    <div class="flex-col w-9/12 p-4">
                        <div class="flex flex-row">
                            <div class="w-9/12">
                                <!-- Term -->
                                <div class="flex">
                                    <app-restricted-input class="restricted-input" [label]="'Term'"
                                        (valueChange)="changeTerm($event)" [value]="selectedTerm" [step]="1"
                                        [disabled]="disabled || (availableTerms.length <= 1)" [type]="'number'" [min]="minTerm"
                                        [max]="maxTerm" data-cy="calc-term-input">
                                    </app-restricted-input>
    
                                    <app-discrete-slider *ngIf="availableTerms.length > 1" class="w-100 flex-grow"
                                        (valueChange)="changeTerm($event)" [disabled]="disabled" [thumbLabel]="true"
                                        [value]="selectedTerm" [marks]="availableTerms">
                                    </app-discrete-slider>
                                </div>
    
                                <!-- Frequency -->
                                <div class="flex align-items-center">
                                    <app-restricted-input class="restricted-input" [label]="'Frequency'" [disabled]="true"
                                        [value]="paymentFrequencyDisplay">
                                    </app-restricted-input>
    
                                    <app-restricted-radio class="w-100 ml-1 pt-1" *ngIf="frequencyOptions.length > 1"
                                        [options]="frequencyOptions" [availableOptions]="termRestrictedFrequencyOptions"
                                        [disabled]="disabled" (valueChange)="changePaymentFreq($event)"
                                        [value]="selectedPaymentFrequency">
                                    </app-restricted-radio>
                                </div>
                                <!-- Draw Amount -->
                                <div class="flex align-items-center">
                                    <app-restricted-input class="restricted-input" [label]="'Draw Amount'"
                                        [value]="selectedDrawAmount" [disabled]="disabled" [type]="'number'" [min]="minDrawAmount"
                                        [max]="maxDrawAmount" [step]="100" (valueChange)="changeDrawAmount($event)">
                                    </app-restricted-input>
    
                                    <app-restricted-slider class="w-100 flex-grow" [updateContinuously]="true"
                                        (mouseup)="calculate()" [value]="selectedDrawAmount" [showValues]="true" [currency]="true"
                                        [showCurrencyDecimals]="false" [tickInterval]="0" [thumbLabel]="true" [disabled]="disabled"
                                        [step]="1000" [min]="minDrawAmount" [max]="maxDrawAmount" [availableMax]="maxDrawAmount"
                                        (valueChange)="changeDrawAmount($event)">
                                    </app-restricted-slider>
                                </div>
    
                                <!-- Payoff Months -->
                                <div class="flex">
                                    <app-restricted-input class="restricted-input" [ngClass]="{'early-payoff': isEarlyPayoff}"
                                        [label]="'Payoff Months'" (valueChange)="changePayoffMonths($event)"
                                        [value]="selectedPayoffMonths" [step]="1"
                                        [disabled]="disabled || (availablePayoffMonths.length <= 1)" [type]="'number'"
                                        [min]="minPayoff" [max]="maxPayoff" data-cy="calc-payoff-input">
                                    </app-restricted-input>
    
                                    <app-restricted-slider *ngIf="availablePayoffMonths.length > 1" class="w-100 flex-grow"
                                        [ngClass]="{'early-payoff': isEarlyPayoff}" [updateContinuously]="true"
                                        [value]="selectedPayoffMonths" [showValues]="true" [currency]="false"
                                        [showCurrencyDecimals]="false" [tickInterval]="1" [thumbLabel]="true" [disabled]="disabled"
                                        [step]="1" [min]="minPayoff" [max]="maxPayoff" [availableMax]="selectedTerm"
                                        (valueChange)="changePayoffMonths($event)">
                                    </app-restricted-slider>
                                </div>
                            </div>
                            <div>
                                <div class="flex flex-col approval-bullet-list mt-1">
                                    <!-- TODO: Option Data Bullet Entries here -->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <!-- Limit -->
                                        <div class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Limit</div>
                                        <div class="flex-none min-w-[60px] mr-3 text-left font-bold animate-loading">
                                            {{ maxOption?.limits.drawAmount.max | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </div>
                                    <!-- Draw Amount -->
                                    <!-- Depending on the if the approval is 'term' or 'loc', we display a different label-->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div *ngIf="calculationType === 'loc'"
                                            class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Draw Amount</div>
                                        <div *ngIf="calculationType === 'term'"
                                            class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Amount</div>
                                        <div class="flex-none min-w-[60px] mr-3 text-left font-bold animate-loading">
                                            {{ selectedDrawAmount | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </div>
                                    <!-- Annual Rate (Interest Rate) -->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Interest Rate</div>
                                        <div class="flex-none -min-w-[60px] mr-3 text-left font-bold animate-loading">{{
                                            this.annualRate| number : '1.2-2' }}%</div>
                                    </div>
                                    <!-- Periodic Rate -->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">{{ periodicLabel
                                            }} Rate</div>
                                        <div class="flex-none -min-w-[60px] mr-3 text-left font-bold animate-loading">{{
                                            periodicRate | number : '1.2-4' }}%</div>
                                    </div>
                                    <!-- Payment -->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Payment</div>
                                        <div class="flex-none min-w-[60px] mr-3 text-left font-bold animate-loading">
                                            {{ paymentAmount | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </div>
                                    <!-- Disbursement -->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Disbursement
                                        </div>
                                        <div class="flex-none min-w-[60px] mr-3 text-left font-bold animate-loading">
                                            {{ disbursement | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </div>
                                    <!-- Draw Fee -->
                                    <!-- Depending on the if the approval is 'term' or 'loc', we display a different label-->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div *ngIf="calculationType === 'loc'"
                                            class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Draw Fee</div>
                                        <div *ngIf="calculationType === 'term'"
                                            class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Origination Fee</div>
                                        <div class="flex-none min-w-[70px] mr-3 text-left font-bold animate-loading">
                                            {{ drawFee | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </div>
                                    <!-- Commission -->
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap">Commission</div>
                                        <div class="flex-none min-w-[70px] mr-3 text-left font-bold animate-loading">
                                            {{ commission | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </div>
                                    <div class="flex pb-3" data-cy="approval-bullet-item">
                                        <div class="flex-none w-[120px] mr-3 text-secondary text-right text-nowrap"><span class="font-mono">~</span> APR</div>
                                        <div class="flex-none min-w-[60px] mr-3 text-left font-bold animate-loading">{{ apr
                                            }}%</div>
                                    </div>
                            </div>
                        </div>
                    </div>
                        <div style="border: 1px solid #e5e7eb; border-radius: 4px;">
                            <table mat-table class="w-full" [dataSource]="paybackDataSource">
                                <ng-container matColumnDef="payoffType">
                                    <mat-header-cell *matHeaderCellDef class="!max-w-[250px] !text-sm "></mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="!max-w-[250px] !text-sm">{{ element.payoffType }}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="payback">
                                    <mat-header-cell *matHeaderCellDef class="!max-w-[250px] !text-sm !text-white">
                                        <span class="font-mono">~</span>&nbsp;Payback
                                    </mat-header-cell>
                                    <mat-cell  *matCellDef="let element" class="!max-w-[250px] !text-sm">{{ element.payback | currency:'USD':'symbol':'1.0-0' }}&nbsp;&nbsp;&nbsp;
                                        <div *ngIf="element.payoffType==='Selected Payoff:' && isEarlyPayoff" class="delta-payoff">
                                            <mat-icon class="mat-icon-arrow">arrow_downward</mat-icon>{{ paybackChanged | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="costOfCapital">
                                    <mat-header-cell *matHeaderCellDef class="!max-w-[250px] !text-sm !text-white">
                                        <span class="font-mono">~</span>&nbsp;Cost of Capital
                                    </mat-header-cell>
                                    <mat-cell  *matCellDef="let element" class="!max-w-[250px] !text-sm">{{ element.costOfCapital | currency:'USD':'symbol':'1.0-0' }}&nbsp;&nbsp;&nbsp;
                                        <div *ngIf="element.payoffType==='Selected Payoff:' && isEarlyPayoff" class="delta-payoff">
                                            <mat-icon class="mat-icon-arrow">arrow_downward</mat-icon>{{ costOfCapitalChanged | currency:'USD':'symbol':'1.0-0' }}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="cents">
                                    <mat-header-cell *matHeaderCellDef class="!max-w-[250px] !text-sm !text-white">
                                        <span class="font-mono">~</span>&nbsp;Cents/$
                                    </mat-header-cell>
                                    <mat-cell  *matCellDef="let element" class="!max-w-[250px] !text-sm">{{ element.cents }}&nbsp;&nbsp;&nbsp;
                                        <div *ngIf="element.payoffType==='Selected Payoff:' && isEarlyPayoff" class="delta-payoff">
                                            <mat-icon class="mat-icon-arrow">arrow_downward</mat-icon>{{ centsOnTheDollarChanged }}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="payments">
                                    <mat-header-cell *matHeaderCellDef class="!w-[100px] !text-sm !text-white">
                                        <span># Payments</span>
                                    </mat-header-cell>
                                    <mat-cell  *matCellDef="let element" class="!w-[100px] !text-sm">{{ element.payments }}&nbsp;&nbsp;&nbsp;
                                        <div *ngIf="element.payoffType==='Selected Payoff:' && isEarlyPayoff" class="delta-payoff">
                                            <mat-icon class="mat-icon-arrow">arrow_downward</mat-icon>{{ numberOfPaymentsChanged }}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row style="background-color:#03a9f4" *matHeaderRowDef="displayedColumns" ></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns">
                                </mat-row>
                            </table>
                        </div>
                    </div>
                    <!-- Data Drawer / Additional Data -->
                    <div class="approval-data-drawer active w-3/12">
    
                        <!-- flex flex-col justify-between w-[280px] -->
                        <div class="approval-data-drawer-content">
                            <div class="mb-3">
                                <h4>
                                    <div class="font-medium text-lg">Stipulations</div>
                                </h4>
                                <ul [style.listStyle]="'disc'" class="approval-stipulations">
                                    <li *ngFor="let nonConditional of offer.requestNames?.nonConditional">{{ nonConditional
                                        }}</li>
                                    <li *ngFor="let conditional of offer.requestNames?.conditional"
                                        [ngClass]="{'text-opacity-50': (amount < conditional.minAmount)}">
                                        {{ conditional.name }} ({{ conditional.minAmount | currency }}+)
                                    </li>
                                </ul>
                                <div *ngIf="!offer.requestNames">None</div>
                            </div>
    
                            <div class="mb-3" *ngIf="(offer.notes?.length) || 0 > 0">
                                <h4>
                                    <div class="font-medium text-lg">Notes</div>
                                </h4>
                                <span> {{ offer.notes }} </span>
                            </div>
    
                            <div class="">
                                <div class="flex justify-between">
                                    Position
                                    <div>{{ offer.position }}</div>
                                </div>
    
                                <div class="flex justify-between">
                                    Net Requirement
                                    <div>{{ offer.netMinimum / 100 | percent }}</div>
                                </div>
    
                                <div class="flex justify-between">
                                    Expiration
                                    <div>{{ offer.expires | date }}</div>
                                </div>
                                <div class="flex justify-between">
                                    Offer Link
                                    <div class="flex justify-between px-4 pb-4">
                                        <a class="text-sm offer-link-button leading-8" mat-icon-button
                                            href="{{ _offer.offerLink }}" target="_blank">
                                            Open
                                            <mat-icon>open_in_new</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>

