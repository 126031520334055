import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LendioResponse, ObsLendioResponse } from '@app/app/interfaces/lendio-response';
import { environment } from '@app/environments/environment';
import { SearchResponseResult } from '@app/app/interfaces/search/search-result.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private http: HttpClient,
  ) {}

  search(term: string): ObsLendioResponse<SearchResponseResult[]> {
    const url = `${environment.apiUrl}/l/v2/internal/borrowers/search`;
    return this.http.get<LendioResponse>(url, {
      params: {
        search: term,
        query: encodeURIComponent(JSON.stringify({
          criteria: {},
          sort: { 'borrowers.viewed': 'DESC' },
          limit: 10,
          offset: 0
        }))
      }
    });
  }
}
