import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AccountLockedComponent } from './account-locked/account-locked.component';
import { AuthBaseComponent } from './auth-base/auth-base.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TermsModule } from '../components/terms/terms.module';
import { ExpirationDialogComponent } from './expiration-dialog/expiration-dialog.component';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { AlertModule } from '../components/alert/alert.module';
import {MfaCodeVerificationComponent} from "@app/app/auth/mfa/mfa-code-verification.component";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MfaCodeInputModule } from '../components/mfa-code-input/mfa-code-input.module';
import { PasswordExpiredComponent } from './password-expired/password-expired.component';


@NgModule({
  declarations: [
    AccountLockedComponent,
    AuthBaseComponent,
    ExpirationDialogComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordDialogComponent,
    MfaCodeVerificationComponent,
    PasswordExpiredComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    LoadersModule,
    TermsModule,
    MatDialogModule,
    AlertModule,
    MfaCodeInputModule,
  ],
  exports: [
    AccountLockedComponent,
    AuthBaseComponent,
    LoginComponent,
    ResetPasswordComponent,
    PasswordExpiredComponent,
  ],
})
export class AuthModule {
    constructor() {}
}
