@if (form && !hideEditModal) {
<form [formGroup]="form" data-cy="edit-business-dialog" class="lendio-density-1">
    <div class="flex justify-between items-center pr-4">
        <h2 mat-dialog-title class="!pb-4" data-cy="decline-modal-header">Edit overview</h2>
        <button mat-icon-button
                (click)="cancel($event)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="!p-0">

        <mat-accordion multi="false">
            <mat-expansion-panel
                class="!rounded-none"
                [expanded]="openSection == 'business'"
                (opened)="sectionOpened('business')"
                (closed)="sectionClosed('business')">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Business information
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="grid grid-flow-col grid-cols-2 gap-4 mb-4">
                    <div class="flex flex-col grid-rows gap-4 content-start">
                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Primary contact</mat-label>
                            <input matInput formControlName="primaryContact"
                                type="text"
                                data-cy="primary-contact"
                                matTooltip="You can edit this field in the Contacts section."
                            >
                        </mat-form-field>

                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Doing business as</mat-label>
                            <input matInput formControlName="226" type="text" data-cy="doing-business-as-name">
                        </mat-form-field>

                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pr-1">
                                <mat-label>State of incorporation</mat-label>
                                <mat-select formControlName="232" data-cy="state-of-incorporation" [style.width]="220">
                                    <mat-select-trigger>
                                        {{ form.get('232')?.value }}
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let option of stateOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pl-1">
                                <mat-label>Entity type</mat-label>
                                <mat-select formControlName="230" data-cy="entity-type">
                                    <mat-option *ngFor="let option of entityTypeOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>

                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Industry type</mat-label>
                            <mat-select formControlName="732" data-cy="lendio-industry">
                                <mat-option *ngFor="let option of lendioIndustryOptions" [value]="option?.value">{{ option.label }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- NAICS autocomplete start. -->
                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Industry NAICS</mat-label>
                            <input type="text" matInput formControlName="naicsSearchText" data-cy="industry-naics-label"
                                   autocomplete="off"
                                   placeholder="Ex: Mechanical contractors"
                                   [(ngModel)]="searchText"
                                   [matAutocomplete]="results"
                                   (keyup)="search(); hasSearched = false;"
                                   (focus)="hasSearched = false">
                            <mat-icon matPrefix
                                      class="!text-red-500"
                                      *ngIf="errored"
                                      matTooltip="There was an error while attempting to retrieve NAICS codes. Please try searching again.">
                                warning
                            </mat-icon>
                            <button mat-icon-button
                                    matSuffix
                                    (click)="searchText = ''; hasSearched = false;"
                                    *ngIf="searchText" class="!mr-2">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>

                        <mat-autocomplete #results="matAutocomplete">

                            <!-- We have results -->
                            <mat-option
                                    *ngFor="let result of searchResults"
                                    [matTooltip]="getExamples(result.examples)"
                                    [matTooltipClass]="'leading-tight whitespace-pre-line'"
                                    [matTooltipShowDelay]="400"
                                    (click)="setCode(result.code, result.title)">
                                {{ result.code + ' - ' + result.title }}
                            </mat-option>

                            <!-- No results -->
                            <mat-option disabled *ngIf="!searching && searchResults && searchResults.length === 0 && hasSearched">
                                No results for '{{ searchText }}'
                            </mat-option>
                        </mat-autocomplete>
                        <!-- NAICS autocomplete end. -->

                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pr-1">
                                <mat-label>Business start date</mat-label>
                                <input matInput
                                       [matDatepicker]="businessStartDatePicker"
                                       formControlName="238"
                                       data-cy="business-start-date"
                                       [max]="todayDate">
                                <mat-datepicker-toggle matSuffix [for]="businessStartDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #businessStartDatePicker></mat-datepicker>
                            </mat-form-field>

                            <!-- This date is not editable. -->
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pl-1">
                                <mat-label>Date added to Lendio</mat-label>
                                <input matInput formControlName="borrowerCreated" data-cy="borrower-created">
                            </mat-form-field>
                        </span>

                    </div>
                    <div class="flex flex-col grid-rows gap-4 content-start">
                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Website</mat-label>
                            <input matInput formControlName="228"
                                placeholder="Ex: yourname.com"
                                type="text"
                                data-cy="business-website"
                            >
                            <mat-error *ngIf="form.get('228')?.errors">
                                Please enter this format: yourname.com
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Phone</mat-label>
                            <input matInput formControlName="borrowerPhone"
                                type="text"
                                data-cy="borrower-phone"
                                mask="(000) 000-0000"
                                placeholder="Ex: 1234567890"
                            >
                            <mat-error *ngIf="form.get('borrowerPhone')?.errors">
                                Please enter 10 digits
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Street address 1</mat-label>
                            <input matInput formControlName="borrowerStreet" type="text" data-cy="borrower-street">
                        </mat-form-field>

                        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                            <mat-label>Street address 2</mat-label>
                            <input matInput formControlName="borrowerStreet2" type="text" data-cy="borrower-street2">
                        </mat-form-field>

                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-6/12 pr-1">
                                <mat-label>City</mat-label>
                                <input matInput formControlName="borrowerCity" type="text" data-cy="borrower-city">
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-3/12 pl-1 pr-1">
                                <mat-label>State</mat-label>
                                <mat-select formControlName="borrowerState" data-cy="borrower-state" panelWidth="min-content">
                                    <mat-select-trigger>
                                        {{ form.get('borrowerState')?.value }}
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let option of stateOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-3/12 pl-1">
                                <mat-label>Zip code</mat-label>
                                <input matInput formControlName="borrowerZip"
                                    type="text"
                                    placeholder="Ex: 12345"
                                    mask="00000"
                                    [validation]="true"
                                    data-cy="borrower-zip"
                                >
                                <mat-error *ngIf="form.get('borrowerZip')?.errors">
                                    Please enter 5 digits
                                </mat-error>
                            </mat-form-field>
                        </span>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel
                class="!rounded-none"
                [expanded]="openSection == 'operations'"
                (opened)="sectionOpened('operations')"
                (closed)="sectionClosed('operations');">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Operational details
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="grid grid-flow-col grid-cols-2 gap-4 mb-4">
                    <div class="flex flex-col grid-rows gap-4 content-start">
                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/3 pr-1">
                                <mat-label>No of employees</mat-label>
                                <input matInput formControlName="658" type="number" data-cy="number-of-employees">
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/3 pl-1 pr-1">
                                <mat-label>Place of business</mat-label>
                                <mat-select formControlName="234" data-cy="business-location-type">
                                    <mat-option *ngFor="let option of businessLocationTypeOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/3 pl-1">
                                <mat-label>Non-profit</mat-label>
                                <mat-select formControlName="725" data-cy="nonprofit">
                                    <mat-option *ngFor="let option of yesNoOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>

                        <span>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pr-1">
                                <mat-label>Primary customer</mat-label>
                                <mat-select formControlName="86" data-cy="primary-customer">
                                    <mat-option *ngFor="let option of primaryCustomerOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pl-1">
                                <mat-label>Seasonal</mat-label>
                                <mat-select formControlName="704" data-cy="seasonal-business">
                                    <mat-option *ngFor="let option of yesNoOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>

                    </div>
                    <div class="flex flex-col grid-rows gap-4 content-start">

                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pr-1">
                                <mat-label>Accounting software</mat-label>
                                <mat-select formControlName="727" data-cy="use-accounting-software">
                                    <mat-option *ngFor="let option of yesNoOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pl-1">
                                <mat-label>Location ownership</mat-label>
                                <mat-select formControlName="676" data-cy="business-property-type-own-rent">
                                    <mat-option *ngFor="let option of businessPropertyTypeOwnRentOptions" [value]="option?.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>

                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pr-1">
                                <mat-label>Landlord name</mat-label>
                                <input matInput formControlName="244" type="text" data-cy="property-owner-name">
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pl-1">
                                <mat-label>Landlord phone number</mat-label>
                                <input matInput type="text" formControlName="246" data-cy="property-owner-phone"
                                    mask="(000) 000-0000"
                                    placeholder="Ex: 1234567890"
                                >
                                <mat-error *ngIf="form.get('246')?.errors">
                                    Please enter 10 digits
                                </mat-error>
                            </mat-form-field>
                        </span>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel
                class="!rounded-none"
                [expanded]="openSection == 'ownership'"
                (opened)="sectionOpened('ownership')"
                (closed)="sectionClosed('ownership')">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Ownership & legal
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="grid grid-flow-col grid-cols-2 gap-4 mb-4">
                    <div class="flex flex-col grid-rows gap-4 content-start">
                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pr-1">
                                <mat-label>Additional beneficiaries</mat-label>
                                <input matInput formControlName="806" type="number" data-cy="beneficiary-owners">
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pl-1">
                                <mat-label>Additional managing partners</mat-label>
                                <input matInput formControlName="807" type="number" data-cy="managing-partners">
                            </mat-form-field>
                        </span>

                        <span>
                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pr-1">
                                <mat-label>UCC secured party</mat-label>
                                <input matInput formControlName="712" type="text" data-cy="ucc-secured-party">
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2 pl-1">
                                <mat-label>UCC filing date</mat-label>
                                <input matInput [matDatepicker]="uccFilingDatePicker" formControlName="714" data-cy="ucc-filing-date" [max]="today">
                                <mat-datepicker-toggle matSuffix [for]="uccFilingDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #uccFilingDatePicker></mat-datepicker>
                            </mat-form-field>
                        </span>
                    </div>
                    <div class="flex flex-col grid-rows gap-4 content-start">
                        <mat-form-field appearance="outline" floatLabel="auto" class="w-1/2">
                            <mat-label>Fed/State Tax ID</mat-label>
                            <input matInput formControlName="236"
                                type="text"
                                mask="00-0000000"
                                [validation]="true"
                                data-cy="federalstate-tax-id"
                                placeholder="Ex: 123456789"
                            >
                            <mat-error *ngIf="form.get('236')?.errors">
                                Please enter 9 digits
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <mat-error *ngIf="saveError">
            {{ saveError }}
        </mat-error>
        <button mat-stroked-button class="!mt-4 !ml-6" (click)="cancel($event)">
            Cancel
        </button>
        <button mat-flat-button color="primary" class="!ml-4 !mt-4"
            data-cy="business-details-overview-submit-btn"
            [disabled]="!form.dirty || !form.valid"
            (click)="save(form)"
        >
            Save changes
        </button>
    </mat-dialog-actions>
</form>
}
