import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { SettingsComponent } from './settings.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { NewPasswordComponent } from './new-password/new-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsRoutingModule } from 'src/app/components/settings/settings-routing.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { DeleteUserDialogComponent } from './delete-user-dialog/delete-user-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ManageSbaTokenComponent } from './manage-sba-token/manage-sba-token.component';
import {LoadersModule} from "@app/app/components/loaders/loaders.module";
import { OldEmailSettingsComponent } from './old-email-settings/old-email-settings.component';
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { EarlyAccessComponent } from "@app/app/components/settings/early-access/early-access.component";


@NgModule({
  declarations: [
    EmailSettingsComponent,
    OldEmailSettingsComponent,
    SettingsComponent,
    NewPasswordComponent,
    ManageUsersComponent,
    EditUserDialogComponent,
    DeleteUserDialogComponent,
    ManageSbaTokenComponent,
  ],
    imports: [
        CommonModule,
        SettingsRoutingModule,
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        LoadersModule,
        MatIconModule,
        MatDialogModule,
        MatTableModule,
        MatOptionModule,
        MatSelectModule,
        EarlyAccessComponent
    ],
})
export class SettingsModule { }
