type LicenseGetter = (() => string) | null;

class AgGridLicenceManager {
  private readonly _AG_LICENSE = 'Using_this_{AG_Grid}_Enterprise_key_{AG-057027}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Lendio}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Lendio_Lending_Platform}_only_for_{11}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Lendio_Lending_Platform}_need_to_be_licensed___{Lendio_Lending_Platform}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_April_2025}____[v3]_[01]_MTc0NTAxNzIwMDAwMA==76473ae4d454c41563c728d489fd505e';

  handleLicenseDist(getter: LicenseGetter) {
    return {
      applyLicense: function () {
        // eslint-disable-next-line prefer-rest-params
        return getter!.apply(this, arguments);
      },
      removeLicense: function () {
        getter = null;
      }
    }
  }

  getAgGridLicense: LicenseGetter = () => this._AG_LICENSE;

  manageLicense = this.handleLicenseDist(this.getAgGridLicense);
}

export const agGridLicense = new AgGridLicenceManager().manageLicense;
