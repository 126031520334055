import { Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface DisplayValue {
  label: string;
  value: any;
  tooltip?: string;
  labelIcon?: string;
  type?: string;
}

export interface DisplayValuesMap {
  [key: string]: DisplayValue;
}

@Component({
  selector: 'app-business-values',
  templateUrl: 'business-values.component.html',
  styleUrls: ['business-values.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    MatTooltipModule,
    MatIconModule,
  ],
})
export class BusinessValuesComponent {

  @Input() values: DisplayValue[];
}
