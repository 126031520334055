<div *ngIf="business$ | async as business" class="grid md:grid-cols-2 lg:grid-cols-3 gap-4 m-4 business-details-page">

    <!-- Business information -->
    <mat-card appearance="outlined" class="business-overview-card">
        <mat-card-header class="justify-between items-center">
            <mat-card-title>Business information</mat-card-title>
            @if (_hasModifyAccess(business)) {
                <button mat-icon-button
                        data-cy="business-info-edit-button"
                        class="business-overview-edit-button"
                        (click)="openEditDialog('business', business)">
                    <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                </button>
            }
        </mat-card-header>
        <div class="lendio-mat-card-divider"></div>
        <mat-card-content>
            <div class="grow" data-cy="business-details-overview-business">
                <div class="flex">
                    <table class="grow table table-auto">
                        <tr class="table-row">
                            <td class="label">Primary contact</td>
                            <td class="value">
                                <span>{{ (business.primaryContact?.first ?? '') + ' ' + (business.primaryContact?.last ?? '') }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Doing business as</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['226'].value }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label multi-line">State of incorporation</td>
                            <td class="value">
                                <span>{{ getSelectLabel('states', business.borrowerValues['232'].value) }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Entity type</td>
                            <td class="value">
                                <span>{{ getSelectLabel('230', business.borrowerValues['230'].value) }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Industry type</td>
                            <td class="value multi-line">
                                <span>{{ getSelectLabel('732', business.borrowerValues['732'].value) }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Industry NAICS</td>
                            <td class="value multi-line">
                                <span>{{ naicsCodeLabel }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label multi-line">Business start date</td>
                            <td class="value">
                                <span>{{ (business.borrowerValues['238'].value | date) ?? ''  }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label multi-line">Date added to Lendio</td>
                            <td class="value">
                                <span>{{ (business.created | date)  ?? '' }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Website</td>
                            <td class="value">
                                @if (business.borrowerValues['228'].value) {
                                    @if (business.borrowerValues['228'].value !== 'N/A') {
                                        <span>
                                            <a [href]="getUrl(business.borrowerValues['228'].value)"
                                               target="_blank"
                                               class="business-info-anchor">
                                                {{ business.borrowerValues['228'].value }}
                                            </a>
                                        </span>
                                    } @else {
                                        <span>{{ business.borrowerValues['228'].value }}</span>
                                    }
                                }
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Phone</td>
                            <td class="value">
                                <span>{{ (business.phone | phone) ?? ''  }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Address</td>
                            <td class="value multi-line">
                                <span>{{ business.street ?? '' }}</span><br />
                                <span *ngIf="business.street2">{{ business.street2 ?? '' }}<br /></span>
                                <span>{{ (business.city ?? '') + ', ' + (business.stateId ?? '') + ' ' + (business.zipId ?? '') }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <!-- Operational details -->
    <mat-card appearance="outlined" class="business-overview-card">
        <mat-card-header class="justify-between items-center">
            <mat-card-title>Operational details</mat-card-title>
            @if (_hasModifyAccess(business)) {
                <button mat-icon-button
                        data-cy="operational-details-edit-button"
                        class="business-overview-edit-button"
                        (click)="openEditDialog('operations', business)">
                    <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                </button>
            }
        </mat-card-header>
        <div class="lendio-mat-card-divider"></div>
        <mat-card-content>
            <div class="grow" data-cy="business-details-overview-operational">
                <div class="flex">
                    <table class="grow table table-auto">
                        <tr class="table-row">
                            <td class="label">Number of employees</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['658'].value }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Place of business</td>
                            <td class="value">
                                <span>{{ getSelectLabel('234', business.borrowerValues['234'].value) }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Primary customer</td>
                            <td class="value">
                                <span>{{ getSelectLabel('86', business.borrowerValues['86'].value) }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Nonprofit</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['725']?.value ? (business.borrowerValues['725'].value == '0' ? 'No' : 'Yes') : '' }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Seasonal business</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['704']?.value ? (business.borrowerValues['704'].value == '0' ? 'No' : 'Yes') : '' }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Accounting software</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['727']?.value ? (business.borrowerValues['727'].value == '0' ? 'No' : 'Yes') : '' }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Location ownership</td>
                            <td class="value">
                                <span>{{ getSelectLabel('676', business.borrowerValues['676'].value) }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Landlord</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['244'].value }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Landlord phone number</td>
                            <td class="value">
                                <span>{{ (business.borrowerValues['246'].value | phone) ?? '' }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <!-- Ownership & legal -->
    <mat-card appearance="outlined" class="business-overview-card">
        <div class="grow" data-cy="business-details-overview-ownership">
            <mat-card-header class="justify-between items-center">
                <mat-card-title>Ownership & legal</mat-card-title>
                @if (_hasModifyAccess(business)) {
                    <button mat-icon-button
                            data-cy="ownership-legal-edit-button"
                            class="business-overview-edit-button"
                            (click)="openEditDialog('ownership', business)">
                        <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                    </button>
                }
            </mat-card-header>
            <div class="lendio-mat-card-divider"></div>
            <mat-card-content>
                <div class="flex">
                    <table class="grow table table-auto">
                        <tr class="table-row">
                            <td class="label">Additional beneficiaries</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['806'].value }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Additional managing partners</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['807'].value }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">UCC secured party</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['712'].value }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">UCC filing date</td>
                            <td class="value">
                                <span>{{ (business.borrowerValues['714'].value | date) ?? ''  }}</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td class="label">Fed/State Tax ID</td>
                            <td class="value">
                                <span>{{ business.borrowerValues['236'].value | mask: '00-0000000'}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-card-content>
        </div>
    </mat-card>

</div>
