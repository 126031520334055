import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, computed } from '@angular/core';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

//

@Component({
  selector: 'app-discrete-slider',
  templateUrl: './discrete-slider.component.html',
  styleUrls: ['./discrete-slider.component.scss']
})
export class DiscreteSliderComponent implements OnInit, OnChanges {
  @Input() marks: number[] = [];
  @Input() value: number;

  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() thumbLabel = false;
  @Input() maxTicks = 10;

  @Output() valueChange = new EventEmitter<number>();

  sliderValue: number = 0;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private _themeService: LendioAngularMaterialThemeService) {
  }

  ngOnInit() {
    this.marks = this.marks.sort((a, b) => a - b);
    this.changeSliderValue(this.value);
    this.changeSelectedValue(this.sliderValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    /* When the value is updated outside of the component */
    const valueChange = changes && changes.value && changes.value.currentValue;
    if (valueChange !== undefined) {
      this.changeSliderValue(valueChange);
      this.valueChange.emit(this.value);
    }

    /* When the marks are updated outside of the component */
    const marksChange = changes && changes.marks && changes.marks.currentValue;
    if (marksChange !== undefined) {
      this.marks = marksChange.sort((a: number, b: number) => a - b);
      this.changeSliderValue(this.value);
      this.valueChange.emit(this.value);
    }
  }

  changeSelectedValue(value: number) {
    this.value = this.marks[value];
    this.valueChange.emit(this.value);
  }

  changeSliderValue(value: number) {
    if (!this.marks || this.marks.length <= 0) {
      return;
    }

    const closest = this.marks.reduce((prev: any, curr: any) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));
    const index = this.marks.indexOf(closest);

    if (index >= 0) {
      this.sliderValue = index;
      this.value = this.marks[index];
    }
  }

  thumbLabelDisplay = (value: number) => {
    return this.marks.length ? this.marks[value] : value;
  };

}
