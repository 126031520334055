import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { DealTypeOption } from '../../interfaces/deal-type-option';

export const dealTypeFilters: DealTypeOption[] = [
  {
    value: 'renewal',
    display: 'Renewal',
  },
  {
    value: 'new',
    display: 'New Lead',
  },
  {
    value: 'draw',
    display: 'Draw',
  },
  {
    value: 'renewalGraduation',
    display: 'Renewal Graduation'
  },
  {
    value: 'none',
    display: '',
  },
];

@Pipe({
  name: 'dealTypeDisplay'
})
export class DealTypePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    } else {
      const dealTypeOption = dealTypeFilters.find(el => el.value === value);
      return dealTypeOption ? dealTypeOption.display : _.startCase(_.camelCase(value));
    }
  }
}

