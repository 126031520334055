import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { Approval } from '../../../interfaces/approval/approval.model';
import { ApprovalOption } from '../../../interfaces/approval/approval-option.model';
import { CurrencyPipe } from '@angular/common';
import { invert } from 'lodash';
import { ApprovalAdjustment } from '../../../interfaces/approval/approval-adjustment.model';
//import { PusherService } from '../../../services/shared/pusher.service';
import { ApprovalCustomOption } from '../../../interfaces/approval/approval-custom-option.model';
// @ts-ignore: Ignoring because we don't provide types in this library below
import * as ApprovalCalculatorUtility from '@lendiodevs/lendio-js-utilities/lib/approval/ApprovalCalculator.utility.js';
import { Calculator, configureCalculator } from '@lendiodevs/lendio-js-utilities';
import { ApprovalCalculatorStateModel, ApprovalsState } from '../../../store/offers/approvals.state';
import { Observable } from 'rxjs';
import {map, take, takeUntil } from 'rxjs/operators';
import {Select, Store } from '@ngxs/store';
import { SetApprovalCalculator } from '../../../store/offers/approvals.actions';
import { Subject } from 'rxjs';
import {LenderUserState} from '../../../store/lender-user/lender-user.state';
import {LenderUser} from '../../../interfaces/lender-user.model';
import { formatISO } from 'date-fns';

@Component({
  selector: 'app-old-approval',
  templateUrl: './old-approval.component.html',
  styleUrls: ['./old-approval.component.scss']
})
export class OldApprovalComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  drawerActive = false;
  panelOpenState = false;
  mainExpanded = false;
  hasChanges = false;
  ready = false;
  initializing = true;
  width: number;

  optionData: object;
  optionDataKeys: string[];
  options: ApprovalOption[];
  customOptions: ApprovalCustomOption[];
  maxOptionDisplay: string[];
  loanProductLender: string;
  loanProductName: string;
  hasVariablePayment: boolean;
  isTest: boolean;

  FREQ_OPTIONS = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Semi-monthly', value: 'semiMonthly' },
    { label: 'Monthly', value: 'monthly' },
  ];
  FREQ_MAP: {[key: string]: any}  = {
    'daily': 1,
    'weekly': 7,
    'semiMonthly': 14,
    'monthly': 30,
  };

  id: number;
  term: number;
  maxTerm: number;
  minTerm: number;
  terms: number[];

  calculationType: string;
  customApproval: ApprovalCustomOption;

  /*
   Param boundaries representing the min and max values for each param
   across all given approval options. Used to show that there is availability
   outside the limits of the currently selected option.
   */
  approvalBoundaries: {[key: string]: any} = {
    amount: {min:0,max:0},
    points: {min:0,max:0},
    term: {min:0,max:0},
  };

  /*
    Param specific limits for the currently selected approval option
   */
  curOriginParams = {
    originationAmount: {
      min:0,absMin:0,max:0,absMax:0
    },
    originationPercent: {
      min:0,absMin:0,max:0,absMax:0
    },
    originationThreshold: {
      min:0,max:0
    },
    originationLimitsAreEqual: false
  };

  currentOptionParams = {
    amount: {min:0,max:0},
    points: {min:0,max:0},
  };

  // Loan Amounts
  amount: number;
  availableAmount: number;
  withheldAmount: number;
  payoffAmount: number;

  // Points
  points: number;
  availablePoints: number;
  pointsTradable: boolean;

  // Payment and frequencies
  paymentFrequency: string;
  paymentFrequencyFriendly: string;
  frequencyOptions: object[];
  availableFrequencies: any[];

  // Origination
  originationPercent: number;
  originationAmount: number;
  originationThumbLabel: string | boolean;
  originationInputSuffix: string;
  validOriginationRange: boolean;

  // More data display
  factor: number;
  buyRate: number;
  payback: number;
  payment: number;
  variablePercentOfRevenue: any;
  payments: number;
  commission: number;
  disbursement: number;
  requests: any;

  notes: string;
  expires: string;
  position: number;
  netMinimum: number;

  @Input() loading: boolean;
  @Input() channel: string;
  @Input() errored: boolean;
  @Input() initializeExpanded = false;
  @Input() disabled = false;
  @Input() readOnly: boolean = false;
  @Output() approvalAdjusted = new EventEmitter <ApprovalAdjustment> ();
  @Output() error = new EventEmitter<number>();
  @Output() pusherPayloadReceived = new EventEmitter<number>();
  @Select(LenderUserState.lenderUser) lenderUser$: Observable<LenderUser>;

  // configurable approval calculator
  calculator: Calculator;
  canViewOfferLinks$: Observable<boolean>;
  destroyed$ = new Subject<boolean>();
  calculatorStorage$: Observable<ApprovalCalculatorStateModel>;
  calculatorStorageKeys = [
    'term',
    'paymentFrequency',
    'points',
    'amount',
    'originationPercent',
  ];

  _maxOption: any;
  private _offer: Approval;


  @Input() set offer(v: Approval) {
    this._offer = v;

    this.requests = v.requestNames || [];
    this.notes = v.notes || '';
    this.netMinimum = v.netMinimum;
    this.position = v.position;
    this.expires = v.expires;
    this.id = v.id;
    this.loanProductLender = v.loanProductLender;
    this.loanProductName = v.loanProductName;
    this.hasVariablePayment = v.hasVariablePayment;
    this.isTest = !!v.isTest;

    this.calculationType = v.calculationType;
    this.pointsTradable = v.pointsTradable;

    this.customOptions = v.customOptions;
    this.options = v.options as ApprovalOption[];
    this.withheldAmount = v.withheldAmount;
    this.payoffAmount = v.payoffAmount;

    if (!this.options && !this.customOptions) {
      return;
    }

    this.calculatorStorage$ = this.store.select(ApprovalsState.approvalCalculator(v.id));
    this.calculatorStorage$.pipe(take(1)).subscribe((value) => {
      this.initialize();
      this.loadFromCalculatorStorage(value);
      this.calculate();
    });

  }

  constructor(
    private currencyPipe: CurrencyPipe,
    private store: Store,
  ) { }

  loadFromCalculatorStorage(value: any) {
    if(value) {
      this.calculatorStorageKeys.forEach( (attr: any) => {
        // @ts-ignore: ignoring because we can assume the attr's match
        this[attr] = value[attr];
      });
    }
  }

  updateCalculatorStorage() {
    const { term, paymentFrequency, points, amount, originationPercent } = this;
    const calculatorState = {
      approvalId: this._offer.id,
      lastUpdated: formatISO(new Date(Date.now())),
      term,
      paymentFrequency,
      points,
      amount,
      originationPercent,
    };
    this.store.dispatch(new SetApprovalCalculator(calculatorState));
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    if (this.initializeExpanded) {
      this.panelOpenState = true;
      this.mainExpanded = true;
    }
    this.onResize(true);

    this.canViewOfferLinks$ = this.lenderUser$.pipe(
        takeUntil(this.destroyed$),
        map(lenderUser => lenderUser.permissions.includes('viewApprovalOfferLinks') && !!this.offer.offerLink)
      );
  }

  ngAfterViewInit(): void {
    this.ready = true;
    this.hasChanges = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  initialize() {
    if (this.calculationType === 'custom') {
      this.customInitialize();
      this.initializing = false;
      return;
    }

    [
      'term',
      'points',
      'amount',
    ].forEach( (param: any) => {
      this.approvalBoundaries[param] = this.getParamLimits(this.options, param);
    });

    this.options.forEach((o) => {
      if(o.variablePercentOfRevenue !== null) {
        this.variablePercentOfRevenue = o.variablePercentOfRevenue;
      }
    })

    this.calculator = this.initializeCalculator();

    this.maxTerm = this.options.reduce((max, o) => o.term > max ? o.term : max, 0);
    this.minTerm = this.options.reduce((min, o) => o.term < min ? o.term : min, 9999999999999);

    const maxPeriod = this.options
      .filter(option => option.term === this.maxTerm)
      .reduce((max, o) =>
        this.FREQ_MAP[o.paymentFrequency] > this.FREQ_MAP[max] ? o.paymentFrequency : max, 'daily'
      );

    const maxOption = this.options.find(o => o.term === this.maxTerm && o.paymentFrequency === maxPeriod);
    if (!maxOption) {
      return;
    }
    const maxOptionTerm = maxOption.term;
    const maxOptionFreq = maxOption.paymentFrequency;
    const maxOptionMaxAmount = maxOption.limits.amount.max;
    const maxOptionMaxPoints = maxOption.limits.points.max;
    const maxOriginationPercent = maxOption.limits.originationPercent.max;

    this.availableAmount = maxOptionMaxAmount;
    this.availablePoints = maxOptionMaxPoints;

    const maxBuyrateConditional = maxOption.customCalculatorParams?.pointConditionalBuyrateThreshold;
    const maxBuyrateConditional2 = maxOption.customCalculatorParams?.pointConditionalBuyrateThreshold2;
    const maxBuyRate = (maxOption !== undefined && maxOptionMaxPoints !== undefined) ?
      this.calculator.buyRate(
        maxOption.buyRate,
        maxOptionMaxPoints,
        {
          pointConditionalBuyrateThreshold: maxBuyrateConditional,
          pointConditionalBuyrateThreshold2: maxBuyrateConditional2
        }
      ) : 0;

    const calculationType = this.calculationType;
    const roundingStrategy = this.offer.loanProductRoundingStrategy;

    this.changePaymentFreq(maxOptionFreq);
    this.calculateAndSetOriginationParams(maxOption);

    const { pointsTradable, originationPercent } = this;
    this.factor = ApprovalCalculatorUtility.getFactorRate({
      pointsTradable: pointsTradable,
      buyRate: maxBuyRate,
      points: maxOptionMaxPoints,
      maxOriginationPercent: maxOriginationPercent,
      originationPercent: originationPercent,
    });

    const maxCalculatedOptionAmount =
    ApprovalCalculatorUtility.getOptionAvailableAmount({option: maxOption, calculationType, factor: this.factor, roundingStrategy});

    this.term = maxOptionTerm;
    this.amount = maxCalculatedOptionAmount;
    this.points = maxOptionMaxPoints;

    this.payback = ApprovalCalculatorUtility.getPaybackAmount({
      factor: this.factor,
      amount: this.amount,
    });

    const maxOptionPayback  = this.payback;
    const maxOptionFactor = this.factor;

    this.terms = this.options.reduce(
      (terms: any, opt) => !terms.includes(opt.term) ? terms.concat([opt.term]) : terms,
    []);
    this.frequencyOptions = this.FREQ_OPTIONS.filter(
      (freqOpt) => this.options.some(opt => opt.paymentFrequency === freqOpt.value)
    );

    this._maxOption = {
      amount: maxCalculatedOptionAmount,
      points: maxOptionMaxPoints,
      factor: maxOptionFactor,
      payback: maxOptionPayback,
      term: maxOptionTerm,
      stips: this.requests.conditional.length + this.requests.nonConditional.length
    };
    this.maxOptionDisplay = Object.keys(this._maxOption);

    this.initializing = false;
  }

  getParamLimits(options: any, param:any ) {
    return options.reduce((res: any, option: any) => {
      const optionParam = option[param] || option.limits[param];
      const optionMinValue = typeof optionParam === 'object' ?
          optionParam.min :
          optionParam;
      const optionMaxValue = typeof optionParam === 'object' ?
        optionParam.max :
        optionParam;
      return {
        min: Math.min(optionMinValue, res.min),
        max: Math.max(optionMaxValue, res.max)
      };
    },{
      min: 9999999999999,
      max: 0
    });
  }

  customInitialize() {
    if (!this.customOptions || !this.customOptions.length) {
      return;
    }
    this.customApproval = this.customOptions[0];

    if (!this.customApproval) {
      return;
    }
    this.id = this.customApproval.approvalId;
    // Term, payment, and frequency
    this.term = this.customApproval.term - 0; // Ensure term is a number to prevent re-calculation on initialization
    this.payment = this.customApproval.payment;
    this.payments = this.customApproval.payments;
    this.paymentFrequency = this.customApproval.frequency;
    this.paymentFrequencyFriendly = this.FREQ_OPTIONS.find(fo => fo.value === this.customApproval.frequency)?.label || 'Error';
    this.availableFrequencies = this.customApproval.frequencyOptions;

    // Points
    this.points = this.toFixedNumber(this.customApproval.points, 1, 10);
    this.availablePoints = this.customApproval.pointsMax;
    this.approvalBoundaries.points.min = this.toFixedNumber(this.customApproval.pointsMin, 1, 10);
    this.approvalBoundaries.points.max = this.toFixedNumber(this.customApproval.absPointsMax, 1, 10);

    // Loan Amounts
    this.amount = Math.floor(this.customApproval.amount);
    this.approvalBoundaries.amount.min = this.customApproval.amountMin;
    this.approvalBoundaries.amount.max = this.customApproval.absAmountMax;
    this.availableAmount = this.customApproval.amountMax;
    this.factor = this.customApproval.factorRate;
    this.payback = this.customApproval.payback;
    this.disbursement = this.customApproval.disbursement;

    // Origination
    this.originationPercent = this.toFixedNumber(this.customApproval.origination, 1, 10);
    this.curOriginParams.originationPercent.min = this.toFixedNumber(this.customApproval.originationMin, 1, 10);
    this.curOriginParams.originationPercent.max = this.toFixedNumber(this.customApproval.absOriginationMax, 1, 10);

    // Assuming terms and frequency options are mapped to arrays
    this.terms = this.customApproval.absTerms || [];
    this.maxTerm = Math.max(...this.terms);
    this.minTerm = Math.min(...this.terms);

    this.frequencyOptions = this.customApproval.absFrequencyOptions.map(freqOpt => {
      const opt = this.FREQ_OPTIONS.find(fo => fo.value === freqOpt);
      if (opt) {
        return opt;
      }
      return { label: freqOpt, value: freqOpt };
    });


    const maxOptionTerm = this.customApproval.term;
    const maxOptionMaxAmount = this.customApproval.amountMax;
    const maxOptionMaxPoints = this.toFixedNumber(this.customApproval.pointsMax, 1, 10);
    const maxOptionFactor = this.toFixedNumber(this.customApproval.factorRate, 3, 10);
    const maxOptionPayback = this.customApproval.payback;

    // Calculated Fields
    this.originationAmount = this.amount * this.originationPercent / 100;
    this.commission = this.amount * this.points / 100;

    this._maxOption = {
      amount: maxOptionMaxAmount,
      points: maxOptionMaxPoints,
      factor: maxOptionFactor,
      payback: maxOptionPayback,
      term: maxOptionTerm,
      stips: this.requests.conditional.length + this.requests.nonConditional.length
    };
    this.maxOptionDisplay = Object.keys(this._maxOption);

    this.formatAndSetOptionData();
  }

  calculate() {
    if (this.calculationType === 'custom') {
      this.customCalculate();
      return;
    }

    this.availableFrequencies = ApprovalCalculatorUtility.getAvailablePaymentFrequencies(this.options, this.term);

    const possibleOptions = this.options.filter(opt => opt.term === this.term);
    const possiblePayFreqs = possibleOptions.map(opt => opt.paymentFrequency);
    const newPaymentFrequency = ApprovalCalculatorUtility.getNearestPaymentFrequency(this.paymentFrequency, possiblePayFreqs);
    this.changePaymentFreq(newPaymentFrequency);

    const option = this.options.find(o =>
      o.term === this.term &&
      o.paymentFrequency === this.paymentFrequency
    );
    if (!option) {
      this.initialize();
      return;
    }

    this.calculateAndSetOriginationParams(option);

    const buyrateConditional = option.customCalculatorParams?.pointConditionalBuyrateThreshold;
    const buyrateConditional2 = option.customCalculatorParams?.pointConditionalBuyrateThreshold2;
    this.buyRate = this.calculator.buyRate(
      option.buyRate,
      this.points,
      {
        pointConditionalBuyrateThreshold: buyrateConditional,
        pointConditionalBuyrateThreshold2: buyrateConditional2
      }
    );

    const maxOriginationPercent = this.curOriginParams.originationPercent.max;

    const { pointsTradable, points, buyRate, originationPercent } = this;
    this.factor = ApprovalCalculatorUtility.getFactorRate({
      pointsTradable: pointsTradable,
      buyRate: buyRate,
      points: points,
      maxOriginationPercent: maxOriginationPercent,
      originationPercent: originationPercent,
    });
    const optionLimits = option.limits;
    const { calculationType, factor } = this;
    const roundingStrategy = this.offer.loanProductRoundingStrategy;
    this.currentOptionParams = {
      amount: {
        min: optionLimits.amount.min,
        max: ApprovalCalculatorUtility.getOptionAvailableAmount({option, calculationType, factor, roundingStrategy})
      },
      points: {
        min: optionLimits.points.min,
        max: optionLimits.points.max,
      }
    };

    this.validateSelectedAmount();

    this.payback = ApprovalCalculatorUtility.getPaybackAmount({
      factor: this.factor,
      amount: this.amount,
    });

    this.payments = ApprovalCalculatorUtility.getPaymentsNumber(option.payments);
    this.payment = ApprovalCalculatorUtility.getPaymentAmount({
      payback: this.payback,
      payments: this.payments,
    });
    this.commission = ApprovalCalculatorUtility.getCommission({
      amount: this.amount,
      points: this.points,
    });
    this.availablePoints = optionLimits.points.max;
    this.points = this.points > this.availablePoints ?
      this.availablePoints :
      this.points;
    // set disbursement after origination limit adjustments have been made
    this.disbursement = this.amount - this.originationAmount - this.withheldAmount - this.payoffAmount;
    this.formatAndSetOptionData();
    this.updateCalculatorStorage();
  }

  calculateAndSetOriginationParams(option: any) {
    this.curOriginParams = ApprovalCalculatorUtility.getOriginationParams(option.limits, this.amount);
    this.setIsValidOriginationRange();
    this.calculateAndSetOriginationValues(option);
  }


  validateSelectedAmount() {
    this.amount = this.amount > this.currentOptionParams.amount.max ?
      this.currentOptionParams.amount.max :
      this.amount < this.currentOptionParams.amount.min ?
        this.currentOptionParams.amount.min :
        this.amount;
  }

  /**
   * Defines whether or not the current min & max origination
   * is a valid range, which determines whether or not to display the
   * origination slider and allow changes to the origination input
   */
  setIsValidOriginationRange() {
    const originationPercent = this.curOriginParams.originationPercent;
    const validAbsoluteMin = (originationPercent.absMin >= originationPercent.min) &&
      (originationPercent.absMin <= originationPercent.max);
    const validAbsoluteMax = (originationPercent.absMax >= originationPercent.min) &&
      (originationPercent.absMax <= originationPercent.max);
    this.validOriginationRange = validAbsoluteMin &&
      validAbsoluteMax &&
      !this.curOriginParams.originationLimitsAreEqual;
  }

  calculateAndSetOriginationValues(option: any) {
    const currentValue = this.originationPercent;
    const max = this.curOriginParams.originationPercent.absMax,
      min = this.curOriginParams.originationPercent.absMin;

    if (currentValue) {
      this.originationPercent = currentValue > max ? max :
        currentValue < min ? min :
          currentValue;
    } else {
      this.originationPercent = max;
    }

    if ((option.limits.originationAmount.min && option.limits.originationAmount.max) &&
    (option.limits.originationAmount.min === option.limits.originationAmount.max)) {
      this.originationAmount = option.limits.originationAmount.max;
    } else{
      this.originationAmount = this.toFixedNumber((this.originationPercent / 100) * this.amount, 2, 10);
      const minDiff = Math.abs(this.amount - this.curOriginParams.originationThreshold?.min);
      const maxDiff = Math.abs(this.amount - this.curOriginParams.originationThreshold?.max);

      if (this.originationPercent === max && maxDiff < minDiff) {
        this.originationAmount = this.curOriginParams.originationAmount.absMax;

      }
      if (this.originationPercent === min && minDiff < maxDiff) {
        this.originationAmount = this.curOriginParams.originationAmount.absMin;
      }

    }

    this.formatOriginationThumbLabel();
    this.formatOriginationInputSuffix();
  }

  formatAndSetOptionData() {
    this.optionData = {
      'Term':             this.term,
      'Amount':           this.currency(this.amount),
      'Factor Rate':      this.toFixedNumber(this.factor, 3, 10),
      'Payback':          this.currency(this.payback),
      '# Payments':       Math.floor(this.payments),
      'Payment':          this.currency(this.payment),
      '% of Sales':     this.toFixedNumber(this.variablePercentOfRevenue, 3, 10) + '%',
      'Origination Fee':  this.currency(this.originationAmount),
      'Disbursement':     this.currency(this.disbursement),
      'Commission':       this.currency(this.commission)
    };

    if(!this.hasVariablePayment) {
      delete (this.optionData as any)['% of Sales'];
    }

    this.optionDataKeys = Object.keys(this.optionData);
  }

  formatOriginationThumbLabel() {
    const valueIsMax = this.originationPercent === this.curOriginParams.originationPercent.absMax;
    const valueIsMin = this.originationPercent === this.curOriginParams.originationPercent.absMin;
    const suffix = valueIsMax ? this.curOriginParams.originationAmount.max :
      valueIsMin ? this.curOriginParams.originationAmount.min :
        false;
    this.originationThumbLabel = suffix ? `%${this.originationPercent}  (${this.currency(suffix)})` :
      false;
  }

  formatOriginationInputSuffix() {
    const originationPercent = this.curOriginParams.originationPercent;
    const atMin = this.originationPercent === originationPercent.absMin && originationPercent.absMin !== originationPercent.min;
    const atMax = this.originationPercent === originationPercent.absMax && originationPercent.absMax !== originationPercent.max;
    const minThresh = this.curOriginParams.originationThreshold?.min,
      maxThresh = this.curOriginParams.originationThreshold?.max;
    const atThreshold = this.amount <= minThresh || (this.amount >= maxThresh && maxThresh !== 0);
    this.originationInputSuffix = atThreshold && atMin || atMax ?
      '(' + this.currencyPipe.transform(this.originationAmount, 'USD', 'symbol', '1.0-0') + ')' :
      '%';
  }

  customCalculate() {
    if (!this.ready) {
      return;
    }

    this.originationAmount = this.amount * this.originationPercent / 100;
    this.commission = this.amount * this.points / 100;
    this.formatAndSetOptionData();
    if (this.hasChanges) {
      this.getAdjustedApproval();
    }
    this.hasChanges = false;
  }

  getAdjustedApproval() {
    this.approvalAdjusted.emit(
      {
        approvalId: this.id,
        amount: this.amount,
        term: this.term,
        points: this.points,
        frequency: this.paymentFrequency,
        origination: this.originationPercent
      }
    );
  }

  changePoints(pointsChanged: number) {
    if (this.points === pointsChanged) {
      this.hasChanges = false;
      return;
    }
      this.hasChanges = true;
      this.points = pointsChanged;
  }

  changeAmount(amountChanged: number) {
    if (this.points === amountChanged) {
      this.hasChanges = false;
      return;
    }
      this.hasChanges = true;
      this.amount = amountChanged;
  }

  changeOriginationPercent(originationPercentChanged: number) {
    if (this.points === originationPercentChanged) {
      this.hasChanges = false;
      return;
    }
      this.hasChanges = true;
      this.originationPercent = originationPercentChanged;
  }

  changePaymentFreq(freq: string, initial = false) {
    if (this.paymentFrequency === freq) {
      return;
    }
    if (!this.initializing) {
      this.hasChanges = true;
    }
    this.paymentFrequencyFriendly = this.FREQ_OPTIONS.find(fr => fr.value === freq)?.label || freq;
    this.paymentFrequency = freq;
  }

  changeTerm(term: number) {
    if (this.term === term) {
      return;
    }
    if (!this.initializing) {
      this.hasChanges = true;
    }
    this.term = this.terms.reduce(function(prev, curr) {
      return (Math.abs(curr - term) < Math.abs(prev - term) ? curr : prev);
    });
  }

  toFixedNumber(num: number, digits: number, base: number) {
    const pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
  }

  currency(value: any) {
    const floorValue = Math.floor(value);
    return this.currencyPipe.transform(floorValue, undefined, 'symbol', '1.0-0');
  }

  onResize(initializeDrawer: any) {
    const appApproval = document.getElementsByClassName('offer-container')[0] as HTMLElement;
    if (!appApproval || !appApproval.offsetWidth) {
      this.width = window.innerWidth - 655;
      if (initializeDrawer && this.width > 900) { this.drawerActive = true; }
      return;
    }

    this.width = appApproval.offsetWidth;
    if (initializeDrawer && this.width > 900) { this.drawerActive = true; }
  }

  get maxOption() {
    return this._maxOption;
  }
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get offer() {
    return this._offer;
  }


  /**
   * Initialize the Approval Calculator
   */
  private initializeCalculator() {
    const { buyRate = undefined } = this.offer.calculatorConfig || {};

    // configure calculator
    return configureCalculator({ buyRate });
  }


  private handlePusherMessage(eventName: string, data: any): void {
    if (!data) {
      this.error.emit(this.id);
      this.pusherPayloadReceived.emit(this.id);

      // Should test this out and provide an error state if this ever actually happens
      return;
    }

    if (eventName !== 'update') {
      return;
    }

    this.factor = data.factorRate;
    this.payback = data.payback;
    this.payments = data.payments;
    this.payment = data.payment;
    this.disbursement = data.disbursement;

    this.currentOptionParams.amount.min = data.limits.amount.min;
    this.currentOptionParams.amount.max = data.limits.amount.max;
    this.availableAmount = data.limits.amount.max;
    this.currentOptionParams.points.min = data.limits.points.min;
    this.currentOptionParams.points.max = data.limits.points.max;
    this.availablePoints = data.limits.points.max;
    this.curOriginParams.originationPercent.min = this.toFixedNumber(data.limits.origination.min, 1, 10);
    this.curOriginParams.originationPercent.max = this.toFixedNumber(data.limits.origination.max, 1, 10);

    this.frequencyOptions = data.limits.frequencyOptions.map((freqOpt: any) => {
      const opt = this.FREQ_OPTIONS.find(fo => fo.value === freqOpt);
      if (opt) {
        return opt;
      }
      return { label: freqOpt, value: freqOpt };
    });

    this.formatAndSetOptionData();
    this.pusherPayloadReceived.emit(this.id);
  }

}
