<ng-container *ngIf="tableDataSource$ | async as tableDataSource">
    <table mat-table
           [dataSource]="tableDataSource"
           matSort
           matSortActive="name"
           matSortDirection="asc"
           class = "report-table">
        <!-- Name -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let report">
                {{ report.name }}
            </td>
        </ng-container>

        <!-- Created By -->
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created by</th>
            <td mat-cell *matCellDef="let report">{{ report.createdBy }}</td>
        </ng-container>

        <!-- Date Created -->
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date created</th>
            <td mat-cell *matCellDef="let report">
                {{ report.created | date : "MMMM d, Y" }}
            </td>
        </ng-container>

        <!-- Date Modified -->
        <ng-container matColumnDef="modified">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date modified</th>
            <td mat-cell *matCellDef="let report">
                {{ report.modified | date : "MMMM d, Y" }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"
            class="partner-report-row"
        ></tr>
    </table>
</ng-container>
