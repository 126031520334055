import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PortalUsersResponse } from '../interfaces/portal-users-response.model';
import { NewPortalUser } from '../interfaces/new-portal-user.model';
import { AuthState } from '../store/auth/auth.state';
import { Store } from '@ngxs/store';

@Injectable({
    providedIn: 'root'
})
export class PortalUsersService {

    constructor(
        private httpClient: HttpClient,
        private store: Store
    ) {}

    getPortalUsers(): Observable<PortalUsersResponse> | null | void {
        const currentUser = this.store.selectSnapshot(AuthState.user);

        if (currentUser?.institution?.id) {
            return this.httpClient.get<PortalUsersResponse>(`${environment.apiUrl}/l/v2/internal/lender-users`);
        } else if (currentUser?.affiliate) {
            return this.httpClient.get<PortalUsersResponse>(`${environment.apiUrl}/affiliate/${currentUser.affiliate.id}/users`);
        }
        // TODO: Find cases for when we would ever not have an institution or an affiliate in partner/lender portal? For now return;
        else {
            return;
        }
    }

    createLenderUser(newLenderUser: NewPortalUser): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/l/v2/internal/lender-users-add`, newLenderUser);
    }

    updateLenderUser(lenderUserId: number, updates: any): Observable<any> {
        return this.httpClient.put(`${environment.apiUrl}/l/v2/internal/lender-users-edit/${lenderUserId}`, updates);
    }

    deleteLenderUser(lenderUserId: number): Observable<any> {
        return this.httpClient.delete(`${environment.apiUrl}/l/v2/internal/lender-users-delete/${lenderUserId}`, {});
    }

    createAffiliateUser(affiliateId: number | null | undefined, newPortalUser: NewPortalUser): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/affiliate/${affiliateId}/users`, newPortalUser);
    }

    updateAffiliateUser(affiliateId: number | null | undefined, portalUserId: number, updates: any): Observable<any> {
        return this.httpClient.put(`${environment.apiUrl}/affiliate/${affiliateId}/users/${portalUserId}`, updates);
    }

    deleteAffiliateUser(affiliateId: number | null | undefined, portalUserId: number): Observable<any> {
        return this.httpClient.delete(`${environment.apiUrl}/affiliate/${affiliateId}/users/${portalUserId}`, {});
    }
}
