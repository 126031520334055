<div style="max-width: 372px">
    <h1 mat-dialog-title>
        Reset Password
    </h1>
    <div mat-dialog-content class="!pb-2">
        <p class="mat-body-2">Please provide your email address and we’ll send you instructions on how to reset your password.</p>
        <form [formGroup]="form" (ngSubmit)="submit()" class="forgot-password-form !mt-6">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Email address</mat-label>
                <input formControlName="email"
                    required
                    data-cy="forgot-password-email-input"
                    name="email"
                    id="forgot-password-email"
                    matInput
                    placeholder="Ex. email@domain.com"
                    email
                    type="email">
                <mat-icon matPrefix fontSet="material-icons-outlined">mail</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-stroked-button (click)="cancel()">Cancel</button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="!canSubmit()"
            (click)="submit()"
            class="ml-2"
            data-cy="forgot-password-submit-btn"
            >Send</button>
    </div>
</div>
