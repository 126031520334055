import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { GridStateModel } from '@app/app/interfaces/grid-state.model';
import { Injectable } from '@angular/core';
import {
  ClearFunnelGridState,
  GetFunnelGridState,
  SaveFunnelGridState
} from '@app/app/store/funnel-deals-grid/funnel-deals-grid.actions';

@State<GridStateModel>({
  name: 'funnelGridState',
  defaults: {
    gridState: undefined,
    gridStateCached: false
  }
})
@Injectable()
export class FunnelGridState {

  @Selector() static funnelGridState({ gridState, gridStateCached }: GridStateModel) {
    return { gridState };
  }

  @Action(GetFunnelGridState)
  getFunnelGridState({ getState }: StateContext<GridStateModel>, {}: GetFunnelGridState) {
    if (getState().gridStateCached) {
      return {
        gridState: getState().gridState,
      };
    } else {
      // TODO: v2 get state from api
    }
  }

  @Action(SaveFunnelGridState)
  saveFunnelGridState({ patchState }: StateContext<GridStateModel>, { gridState }: SaveFunnelGridState) {
    return patchState({
      gridState: gridState.gridState,
      gridStateCached: true,
    })
  }

  @Action(ClearFunnelGridState)
  clearFunnelGridState({ patchState }: StateContext<GridStateModel>, {}: ClearFunnelGridState) {
    return patchState({
      gridState: undefined,
      gridStateCached: false,
    })
  }

}
