import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// Interface for data you can provide, with default values if not provided.
export interface ConfirmDialogData {
  title: string,
  description: string,
  cancelLabel: string,
  cancelStyles: string,
  cancelButtonClass?: string;
  confirmLabel: string,
  confirmStyles: string,
  confirmButtonClass?: string;
  confirmButtonColor?: string;
  width: string,
  customContent: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

/**
 * Generic confirmation dialog. Emits a 'cancelled' or 'confirmed' event.
 *
 * See edit-dialog-component for example of use.
 *
 * Uses "new" theme (as of 10/2023) but otherwise customizable for any
 * confirm or cancel experience.
 */
export class ConfirmDialogComponent {
  @ViewChild('confirmButtonRef') confirmButtonRef: MatButton;
  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  ngOnInit(): void {
    // Provide default dialog values if not included in injected data.
    // *ButtonClasses override existing.
    this.data = Object.assign({
      title: "Are you sure?",
      description: "This will cancel your changes.",
      cancelLabel: "Cancel",
      cancelStyles: "",
      confirmLabel:"Confirm",
      confirmStyles: "",
      width: '350px'
    }, this.data);

  }

  ngAfterViewInit(): void {
    // Default the focus to the Confirm button. Otherwise it focuses on
    // Cancel in an active/hover style we don't want.
    this.confirmButtonRef.focus();
  }

  // Tells parent component that cancel was chosen.
  cancel() {
    this.onCancel.emit()
  }

  // Tells parent component that confirm was chosen.
  confirm() {
    this.onConfirm.emit()
  }
}
