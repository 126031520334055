import { JwtResponse } from '../auth/jwt-response';

export class RequiredInformation {

  hiddenFields = [
    'user.email',
    'borrower.name'
  ];

  ownerInfo: RequiredInfoObject = {
    title: 'Owner Info',
    compoundFields: [
      {
        display: 'Borrower Name',
        fields: [
          'user.first',
          'user.last',
        ]
      },
      {
        display: 'Owner Address',
        fields: [
          'owner_street_address',
          'owner_city',
          'owner_state',
          'owner_zip',
        ]
      }
    ],
    fields: [
      'user.email',
      'creditScore',
      'homeOwnershipType',
      'ownerBirthDate',
      'annual_personal_income',
      'contact.ssn',
      'owner_years_at_residence',
    ],
    designations: null
  };

  businessInfo: RequiredInfoObject = {
    title: 'Business Info',
    compoundFields: [
      {
        display: 'AR Aging',
        fields: [
          'accountsReceivable30Days',
          'accountsReceivable60Days',
          'accountsReceivable90Days',
          'accountsReceivable90DaysPlus',
        ]
      },
      {
        display: 'Business Address',
        fields: [
          'borrower.street',
          'borrower.city',
          'borrower.stateId',
          'borrower.zipId',
        ]
      }
    ],
    fields: [
      'borrower.name',
      'beneficiaryOwners',
      'managingPartners',
      'number_of_employees',
      'financeAmount',
      'computed.assetPercentOfLoan',
      'authorizedOwner',
      'computed.worstBalance',
      'computed.bankruptcyDischargeYears',
      'computed.businessDebtAmount',
      'borrower.phone',
      'collateralTypes',
      'computed.avgCreditsAmount',
      'computed.creditsCountAvg',
      'doingBusinessAsName',
      'user.email',
      'entity_type',
      'federalstate_tax_id',
      'hasDownPayment',
      'franchise',
      'veteran_status',
      'leasemortgage_payment',
      'computed.monthlyDebtPayments',
      'average_monthly_sales',
      'computed.avgNegativeCount',
      'nonprofit',
      'computed.worstNSF',
      'computed.ownerAgeYears',
      'business_location_type',
      'computed.revenuePercentOfLoan',
      'computed.taxIdOrSsn',
      'businessWebsite',
    ],
    designations: null,
  };

  documentsRequiredForOffer: RequiredInfoObject = {
    title: 'Required for Offer',
    compoundFields: null,
    fields: [
      'balance_sheet',
      'bank_statement',
      'business_tax_returns',
      'debt_schedule',
      'mtdTransactions',
      'personal_financial_statement',
      'personal_tax_returns',
      'pl_current_year',
      'pppApplicationSigned',
      'pppApplicationUnsigned',
      'pppBankStatement',
      'pppCorporateReturn',
      'pppDocuments',
      'pppIncomeExpense',
      'pppIRS1099',
      'pppIRS940',
      'pppIRS941',
      'pppIRS944',
      'pppPayrollProcessor',
      'pppScheduleC',
    ],
    designations: [
      {
        field: 'requiredForOffer',
        value: true
      }
    ]
  };

  documentsRequiredForContract: RequiredInfoObject = {
    title: 'Required for Contract',
    compoundFields: null,
    fields: [
      'articles_of_incorporation',
      'balance_sheet',
      'bank_statement',
      'business_licenses',
      'business_tax_returns',
      'business_verification',
      'businessLeaseLandlordSubordination',
      'businessLeaseOptionToRenew',
      'businessMortgageOrLeaseStatement',
      'customer_invoice',
      'customer_list',
      'debt_schedule',
      'drivers_license',
      'equipment_info',
      'insuranceDocumentation',
      'llc_operating_agreement_or_corp_bylaws',
      'llcMembersResolutionToBorrow',
      'mtdTransactions',
      'open_invoice_report',
      'personal_tax_returns',
      'personalMortgageOrLeaseStatement',
      'pl_current_year',
      'pppApplicationSigned',
      'pppApplicationUnsigned',
      'pppBankStatement',
      'pppCorporateReturn',
      'pppDocuments',
      'pppIncomeExpense',
      'pppIRS1099',
      'pppIRS940',
      'pppIRS941',
      'pppIRS944',
      'pppPayrollProcessor',
      'pppScheduleC',
      'sbaForm1919',
      'voided_check',
    ],
    designations: [
      {
        field: 'requiredForContract',
        value: true
      }
    ]
  };
}

export interface RequiredInfoObject {
  title: string;
  compoundFields: CompoundField[] | null;
  fields: string[];
  designations: Designation[] | null;
}

export interface CompoundField {
  display: string;
  fields: any[];//string[];
}

export interface Designation {
  field: string;
  value: any;
}

export interface RequiredInfoResponse extends JwtResponse {
  data: RequiredInformation;
  errors: any;
  status: string;
  statusCode: number;
}
