<ng-container *ngIf="offers$ | async as offers; else loading">
    @if (newThemeEnabled()) {
        <app-offers [offers]="offers"
                    [sbaLoanNumber]="sbaLoanNumber"
                    [dealId]="dealId"
                    [loanProductCategory]="loanProductCategory"
                    [tenantId]="tenantId"
                    >
        </app-offers>
    } @else {
        <app-old-offers [offers]="offers"
                        [sbaLoanNumber]="sbaLoanNumber"
                        [dealId]="dealId">
        </app-old-offers>
    }
</ng-container>

<ng-template #loading>
    <app-dot-loader-scale></app-dot-loader-scale>
</ng-template>
