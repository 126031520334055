import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SbaStatusService {

  constructor(private httpClient: HttpClient) {}

  saveStatusInfo(payload: {}): Observable<any> {
    const url = environment.apiUrl + '/l/v2/internal/borrower-value-lp';

    return this.httpClient.post<any>(url, payload);
  }

  submitToSba(dealId: number, allowResubmit: boolean = false): Observable<any> {
    const url = environment.apiUrl + `/deal/sba/submission`;
    return this.httpClient.post<any>(url, { dealIds: [dealId], allowResubmit });
  }

  withdraw(dealId: number): Observable<any> {
    return this.httpClient.delete<any>(environment.apiUrl + `/deal/sba/submission/${dealId}`);
  }
}
