import { Component, OnInit, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { CreatePPPOffer } from 'src/app/store/offers/offers.actions';
import { Observable } from 'rxjs';
import { OffersState } from 'src/app/store/offers/offers.state';
import { ApplicationDetailsState } from 'src/app/store/application-details/application-details.state';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';
import { take } from 'rxjs/operators';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';

@Component({
  selector: 'app-upload-contract-smart',
  templateUrl: './upload-contract-smart.component.html',
  styleUrls: ['./upload-contract-smart.component.scss']
})
export class UploadContractSmartComponent implements OnInit {

  // @Select(OffersState.loading) loading$: Observable<boolean>;
  loading$: Observable<boolean> = this.store.select(OffersState.loading);
  // @Select(ApplicationDetailsState.applicationDetails) applicationDetails$: Observable<ApplicationDetails>;
  applicationDetails$: Observable<ApplicationDetails | null> = this.store.select(ApplicationDetailsState.applicationDetails);
  @Input() dealId: number;

  localeCurrencySymbol: string | null;
  sbaLoanNumber: number | string;
  approvedLoanAmount: number | string | null | undefined;
  expiration: string | null | undefined;

  constructor(
    private store: Store,
    private localeSettingService: LocaleSettingService
  ) {
    this.localeCurrencySymbol = this.localeSettingService.getCurrencySymbol();
  }

  ngOnInit(): void {
    this.applicationDetails$.pipe(take(1)).subscribe((data: any) => {
      if (data && data.deal) {
        this.approvedLoanAmount = data.deal.sbaStatus ? Number(data.deal.sbaStatus.loanAmount) : null;
        this.sbaLoanNumber = data.deal.sbaStatus && data.deal.sbaStatus.sbaLoanNumber ? data.deal.sbaStatus.sbaLoanNumber : '';
        this.expiration = data.deal.offers[0] ? data.deal.offers[0].expiration : '';
      }
    });
  }

  uploadPPPContract(pppOffer: any) {
    this.store.dispatch(new CreatePPPOffer(this.dealId, pppOffer));
  }
}
