
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import { OldApprovalComponent } from './old-approval/old-approval.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ApprovalSmartComponent } from './approval-smart/approval-smart/approval-smart.component';
import { CommonModule } from '@angular/common';
import { RestrictedRadioModule } from '../restricted-radio/restricted-radio.module';
import { RestrictedSliderModule } from '../restricted-slider/restricted-slider.module';
import { RestrictedInputModule } from '../restricted-input/restricted-input.module';
import { DiscreteSliderModule } from '../discrete-slider/discrete-slider.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { OldLocTermApprovalComponent } from './old-loc-term-approval/old-loc-term-approval.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { AchApprovalsComponent } from './ach-approvals/ach-approvals.component';
import { EmptyStateComponent } from '@app/app/components/empty-state/empty-state.component';

@NgModule({
  declarations: [
    ApprovalsComponent,
    AchApprovalsComponent,
    OldApprovalComponent,
    ApprovalSmartComponent,
    OldLocTermApprovalComponent
  ],
  exports: [ApprovalSmartComponent],
  imports: [
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    MatTableModule,
    MatCardModule,
    MatMenuModule,
    MatSelectModule,
    CommonModule,
    RestrictedRadioModule,
    RestrictedSliderModule,
    RestrictedInputModule,
    DiscreteSliderModule,
    MatChipsModule,
    RouterModule,
    EmptyStateComponent,
  ]
})
export class ApprovalsModule { }
