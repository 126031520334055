import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { LendioResponse } from '../interfaces/lendio-response';
import { GetFunnelDealsParams } from '../store/funnel-deals/funnel-deals.actions';
import { FunnelDeal } from '../interfaces/funnel-deal.model';

export interface FunnelDealsResponse {
  deals: FunnelDeal[];
  allDealsRetrieved: boolean;
}

@Injectable({ providedIn: 'root' })
export class FunnelDealsService {
  constructor(private http: HttpClient) {}

  public getFunnelDeals(params: GetFunnelDealsParams) {
    const httpParams = new HttpParams()
      .set('limit', params.limit.toString())
      .set('offset', params.offset.toString())
      .set('checkAan', params.checkAan ? '1' : '0');

    return this.http.get<LendioResponse<FunnelDealsResponse>>(
      `${environment.apiUrl}/lender-portal/applications/funnel`,
      { params: httpParams }
    );
  }
}
