import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetScope, ClearScopeStore } from '@app/app/store/scopes/scope.actions';
import { ScopeService } from '@app/app/services/scope.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Scope } from '@app/app/interfaces/scope.model';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';

export interface ScopeStateModel {
  scopes: {
    [key: string]: Scope
  },
  pendingScopes: Array<string>,
  loading: boolean;
}

@State<Partial<ScopeStateModel>>({
  name: 'Scopes',
  defaults: {
    scopes: {},
    pendingScopes: [],
  }
})
@Injectable()
export class ScopeState {

  @Selector()
  static saasRoles(state: ScopeStateModel) {
    return state.scopes.lenderSaas?.roles || [];
  }

  constructor(
    private scopeService: ScopeService,
  ) {}

  @Action(GetScope)
  getScope(
    ctx: StateContext<ScopeStateModel>,
    { scopeAlias }: GetScope,
  ) {
    const current = ctx.getState(),
      hasScope = !!current.scopes[scopeAlias],
      scopePending = current.pendingScopes.includes(scopeAlias)
    if(!hasScope && !scopePending) {
      ctx.setState(
        patch({
          loading: true,
          pendingScopes: insertItem(scopeAlias)
        })
      );
      return this.scopeService.getScope(scopeAlias)
        .pipe(
          catchError( (err: any) => {
            ctx.setState(
              patch({
                loading: true,
                pendingScopes: removeItem( alias => alias === scopeAlias)
              })
            );
            return throwError(err);
          }),
          tap( ({ data }) => {
            ctx.setState(
              patch({
                loading: false,
                scopes: patch({
                  [scopeAlias]: data as Scope
                }),
                pendingScopes: removeItem( alias => alias === scopeAlias)
              })
            );
          })
        )
    }
  }

  @Action(ClearScopeStore)
  clearScopeStore(
    ctx: StateContext<ScopeStateModel>,
  ) {
    ctx.setState(patch({
      scopes: {}
    }));
  }
}
