<button
	[disabled]="isUploading"
	mat-flat-button
	(click)="fileInput.click()"
	class="!mr-2 !mb-2 upload-btn"
	[class.spinner]="isUploading">
	<span *ngIf="isUploading; else upload">&nbsp;</span>
	<ng-template #upload>Upload Doc(s)</ng-template>
</button>
<input 
	#fileInput
	type="file"
	(change)="uploadDocuments($event.target.files)"
	class="hidden"
	multiple
/>
<mat-chip-listbox *ngIf="uploadedDocs" class="document-list">
	<mat-chip-option
		*ngFor="let doc of uploadedDocs"
		color="accent"
		[removable]="true"
		(removed)="deleteDocument(doc.id)">
		{{ doc.name }}
		<mat-icon matChipRemove>cancel</mat-icon>
	</mat-chip-option>
</mat-chip-listbox>
