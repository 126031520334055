import { Component, Input, OnInit } from '@angular/core';
import { Esigner } from '@app/app/interfaces/esigner.model';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-esign-status',
  templateUrl: './esign-status.component.html',
  styleUrls: ['./esign-status.component.scss']
})
export class EsignStatusComponent {

  @Input() dealId: number;
  @Input() signers: Esigner[];
  
  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }
}
