import { Component, OnDestroy, OnInit } from '@angular/core';
import { Integration, IntegrationActivity } from '@app/app/interfaces/integration.model';
import { StatusConfigMap } from '@app/app/components/integrations/integrations-status-config-map.model';
import {combineLatest, filter, map, Observable, Subject} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IntegrationsState } from '@app/app/store/integrations/integrations.state';
import {take, takeUntil} from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { MatTableDataSource } from '@angular/material/table';
import {
  LendioPagination
} from '@app/app/components/lendio-angular-material-theme/lendio-paginator/lendio-paginator.interface';
import {IntegrationActivitiesState} from "@app/app/store/integrations/integration-activities.state";
import {GetIntegrationActivities, GetIntegrationCredentials} from '@app/app/store/integrations/integrations.actions';
import { PageEvent } from '@angular/material/paginator';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { NavPageTitleService } from '@app/app/services/nav-page-title.service';
import {MatDialog} from "@angular/material/dialog";
import {
  CredentialsDialogComponent
} from "@app/app/components/integrations/credentials-dialog/credentials-dialog.component";
import {AuthState} from "@app/app/store/auth/auth.state";

@Component({
  selector: 'app-integrations-activity-table',
  templateUrl: 'integrations-activity-table.component.html',
  styleUrls: ['integrations-activity-table.component.scss'],
})
export class IntegrationsActivityTableComponent implements OnInit, OnDestroy, LendioPagination {
  destroyed$ = new Subject<boolean>();
  credentialsLoading$: Observable<boolean> = this.store.select( IntegrationsState.credentialsLoading );
  canManageIntegrationCredentials$: Observable<boolean> = this.store.select( AuthState.userHasPermission('lpxIntegrationCredentials.write') );
  integration$: Observable<Integration>;
  integrationActivities$: Observable<IntegrationActivity[]> = this.store.select( IntegrationActivitiesState.activities );
  activitiesDataSource$: Observable<MatTableDataSource<IntegrationActivity>>;
  pageSize$: Observable<number> = this.store.select( IntegrationActivitiesState.selectorFromKey('per_page') );
  pageSizeOptions$: Observable<number[]> = this.store.select( IntegrationActivitiesState.selectorFromKey('pageSizeOptions') );
  pageIndex$: Observable<number> = this.store.select( IntegrationActivitiesState.selectorFromKey('currentPage') );
  total$: Observable<number> = this.store.select( IntegrationActivitiesState.selectorFromKey('total') );
  activitiesLoading$: Observable<boolean> = this.store.select( IntegrationActivitiesState.selectorFromKey('activitiesLoading') );
  isLoaded: boolean;
  showFirstLastButtons: boolean = true;
  statusMap = StatusConfigMap;
  integrationId: number;
  columns = [
    'action',
    'transactionId',
    'dealId',
    'status',
    'timestamp',
  ];

  constructor(
    private bcs: BreadcrumbService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private store: Store,
    private titleService: NavPageTitleService,
  ) {}

  ngOnInit(): void {
    this.isLoaded = false;
    this.route.paramMap.pipe(
      takeUntil(this.destroyed$)
    ).subscribe( params => {
      const integrationId = Number(params.get('id'));
      if(!!integrationId && integrationId !== this.integrationId) {
        this.integrationId = integrationId;
        this.store.dispatch( new GetIntegrationActivities(this.integrationId, 0, 15, true) );
        this.integration$ = this.store.select( IntegrationsState.byId(this.integrationId) );
        combineLatest([
          this.integration$,
          this.canManageIntegrationCredentials$,
        ]).pipe(
          takeUntil(this.destroyed$),
        ).subscribe(([integration, canManageIntegrationCredentials]) => {
          integration?.name && this.titleService.setTitle(integration.name);
          this.store.dispatch( new GetIntegrationCredentials(this.integrationId) );
          canManageIntegrationCredentials && this.store.dispatch( new GetIntegrationCredentials(this.integrationId) );
        });
      }
    });

    this.activitiesDataSource$ = this.integrationActivities$.pipe(
      takeUntil(this.destroyed$),
      filter( activities => !!activities?.length ),
      map(activities => {
        return new MatTableDataSource(activities);
      }),
    );
    this.isLoaded = true;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.bcs.setBreadcrumbs([]);
  }

  handlePaginationEvent($event: PageEvent): void {
    this.store.dispatch( new GetIntegrationActivities(this.integrationId, $event.pageIndex, $event.pageSize, false) );
  }

  showCredentialsDialog(): void {
    this.integration$.pipe(
      take(1),
    ).subscribe( customerIntegration => {
      this.dialog.open( CredentialsDialogComponent, {
        width: '500px',
        disableClose: true,
        data: {
          title: 'Update Experian commercial credentials',
          type: 'create',
          customerIntegration,
        },
      });
    });
  }
}
