<div
    class="show-hidden-children grid auto-rows-auto item-grid justify-between items-center py-4 border-b border-gray-300 bg-white hover:bg-gray-100 gap-y-1"
    data-cy="notificationItem"
>
    <a
        href="javascript:;"
        attr.aria-label="Mark notification {{
            notification.read ? 'unread' : 'read'
        }}"
        class="px-3 cursor-pointer grid-area-dot"
        [class]="{ invisible: notification.read && !togglingRead }"
        (click)="itemToggleRead()"
        data-cy="notificationItemIndicator"
    >
        <div
            class="rounded-full w-2 h-2 transition linear duration-200"
            [ngClass]="{
                'border-2 border-gray-400 hover:bg-gray-400':
                    notification.read && !togglingRead,
                'bg-red-400 hover:bg-blue-300 border-0':
                    !notification.read && !togglingRead,
                'bg-gray-400 border-0': togglingRead
            }"
        ></div>
    </a>

    <a
        href="javascript:;"
        attr.aria-label="Go to {{ notification.borrowerName }} detail page"
        (click)="itemToggleReadAndRedirect()"
        class="grid-area-title w-full overflow-hidden leading-snug"
        data-cy="notificationItemDetails"
    >
        <div class="truncate text-base font-semibold">
            {{ notification.title ?? notification.borrowerName }}
        </div>
    </a>
    <div
        class="text-sm font-normal grid-area-content"
        [ngSwitch]="notification.activityType"
    >
        <!-- Note: Quick switch case, pipe would likely be preferred if there are ever more than 4 -->
        <span *ngSwitchCase="'dealCreated'"> Submitted To Lender </span>
        <span *ngSwitchCase="'documentAttached'"> Document Attached </span>
        <span *ngSwitchCase="'requestCommentAdded'"> Comment Added </span>
        <span *ngSwitchCase="'requestCompleted'"> Request Completed </span>
        <span *ngSwitchCase="'sbaApproved'"> SBA Approved </span>
        <span *ngSwitchDefault>
            {{ notification.activityType }}
        </span>
    </div>

    <div class="text-lendio-gray-400 grid-area-timestamp text-sm font-normal">
        {{ timeAgo(notification.created) }}
    </div>
    <a
        href="javascript:;"
        aria-label="Delete notification"
        class="flex justify-center self-start opacity-0 focus:opacity-100 cursor-pointer text-gray-500 hover:text-black transition linear duration-200 grid-area-x"
        (click)="itemDelete()"
    >
        <div class="h-6 w-6"
            ><mat-icon *ngIf="!deleting; else loading">close</mat-icon>
            <ng-template #loading><mat-spinner [diameter]="24"></mat-spinner></ng-template>
        </div>
    </a>
</div>
