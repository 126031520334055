<app-alert
	*ngFor="let alert of alerts"
	[level]="alert.level"
	[message]="alert.message"
	[dismissible]="true"
></app-alert>

<div *ngIf="submitting" class="loading text-center" [@fadeIn] [@fadeOut]>
	<h2>Uploading Contract...</h2>
	<app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
</div>

<form [formGroup]="uploadContractForm" [ngClass]="(submitting ? 'hidden' : '')">
	<div class="row">
		<div class="col">
			<p class="col-header">Total Loan Amount ({{ localeCurrencySymbol }})</p>
			<div class="form-field-container">
				<mat-form-field appearance="fill" class="w-full">
					<span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
					<input matInput type="number" formControlName="amount" required>
					<mat-error *ngIf="checkError('amount', 'required')">Amount is required</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="col">
			<p class="col-header">SBA Loan Number</p>
			<div class="form-field-container">
				<mat-form-field appearance="fill" class="w-full">
					<input matInput type="text" formControlName="sbaApproval" required>
					<mat-error *ngIf="checkError('sbaApproval', 'required')">SBA Loan Number is required</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="col">
			<p class="col-header">Expiration</p>
			<mat-form-field appearance="fill" class="w-full">
				<input matInput [matDatepicker]="dateReceivedPicker3" required formControlName="expiration">
				<mat-datepicker-toggle matSuffix [for]="dateReceivedPicker3"></mat-datepicker-toggle>
				<mat-datepicker #dateReceivedPicker3></mat-datepicker>
				<mat-error *ngIf="checkError('expiration', 'required')">Expiration is required</mat-error>
			</mat-form-field>
		</div>
	</div>

	<div class="row">
		<div class="col text-left">
			<app-document-upload-smart
				(documentUploaded)="attachDocumentId($event)"
				(documentDeleted)="detachDocumentId($event)"
				(documentError)="showAlert($event)"
			></app-document-upload-smart>
		</div>
		<div class="col text-right">
			<button mat-button class="cancel-new-offer" (click)="close()">Cancel</button>
			<button type="submit" mat-button class="upload-btn"
					(click)="save()"
					[class.spinner]="submitting"
					[disabled]="uploadContractForm.invalid || submitting">
				{{ submitting ? '&nbsp;' : 'Save Draft' }}
			</button>
			<button type="submit" mat-button class="send-offer"
					(click)="submit()"
					[class.spinner]="submitting"
					[disabled]="uploadContractForm.invalid || submitting">
				{{ submitting ? '&nbsp;' : 'Send Contract' }}
			</button>
		</div>
	</div>
</form>
