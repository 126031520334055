import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import * as _ from 'lodash';
import { Approval } from '../../interfaces/approval/approval.model';//
import {
  GetApprovals,
  GetApprovalsCount,
  SetApprovalCalculator,
  GetApprovalCalculatorStorage, ClearApprovalsStore,
} from './approvals.actions';
import { OffersService } from '../../services/offers.service';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { differenceInDays } from 'date-fns';

export class ApprovalsStateModel {
  approvals: Approval[] | null;
  approvalsCount: number;
  adjustedApproval: Approval | null;
  approvalCalculators: ApprovalCalculatorStateModel[];
  loading: boolean;
}
//NOTE can I put "any" for approvalID
export interface ApprovalCalculatorStateModel {
  approvalId: any;
  term: number;
  paymentFrequency: string;
  points: number;
  amount: number;
  originationPercent: number;
  lastUpdated: string;
}

@State<ApprovalsStateModel>({
	name: 'Approvals',
	defaults: {
    approvals: null,
    approvalsCount: 0,
    adjustedApproval: null,
    approvalCalculators: [],
    loading: false,
	}
})
@Injectable()

export class ApprovalsState {

  @Selector()
	static approvals(state: ApprovalsStateModel) {
    return state.approvals;
  }

  @Selector()
  static approvalsCount(state: ApprovalsStateModel) {
      return state.approvalsCount;
  }

  @Selector()
  static adjustedApproval(state: ApprovalsStateModel) {
      return state.adjustedApproval;
  }

  @Selector()
  static approvalCalculator(approvalId: number) {
    return createSelector([ApprovalsState], state => {
      return state.Approvals.approvalCalculators.filter( (calc: any) => calc.approvalId === approvalId)[0] || false;
    });
  }

  @Selector()
  static forDeal(dealId: number) {
    return createSelector([ApprovalsState], state => {
      return state.Approvals.approvals.filter( (approval: any) => approval.dealId === dealId);
    });
  }

  @Selector()
  static loading(state: ApprovalsStateModel) {
    return state.loading;
  }

  calculatorStateTTLDays = 14;

  constructor(
    private approvalsService: OffersService,
  ) {}

  /**
   * @param dealId
   */
	@Action(GetApprovals)
	getApprovals({ patchState }: StateContext<ApprovalsStateModel>, { dealId }: GetApprovals) {
    patchState({
      approvals: null,
      loading: true,
    });
    this.approvalsService.getApprovalsByDealId(dealId)
      .pipe(take(1)).subscribe((unsortedApprovals) => {
        const approvals = unsortedApprovals.data?.sort(this.approvalsSort);
        patchState({
          approvals,
          loading: false,
        });
      });
  }

  @Action(GetApprovalsCount)
	getApprovalsCount({ getState, patchState }: StateContext<ApprovalsStateModel>, { borrowerId }: GetApprovalsCount) {
    patchState({ approvalsCount: 0 });
    this.approvalsService.getOffersCount(borrowerId).pipe(take(1))
      .subscribe((res) => {
        const approvalsCount = res;
        if (approvalsCount && typeof approvalsCount === 'number') {
          patchState({ approvalsCount });
        }
      });
  }

  @Action(SetApprovalCalculator)
  setApprovalCalculator({getState, patchState}: StateContext<ApprovalsStateModel>, {approvalCalculatorState}: SetApprovalCalculator) {
    const state = getState();
    const rest = state.approvalCalculators.filter( (calc: any) => calc.approvalId !== approvalCalculatorState.approvalId);
    patchState({
      approvalCalculators: [
        ...rest,
        approvalCalculatorState
      ]
    });
    this.setApprovalCalculatorsStorage(getState());
  }

  @Action(GetApprovalCalculatorStorage)
  getApprovalCalculatorsStorage({patchState}: StateContext<ApprovalsStateModel>) {
    const storageVal = JSON.parse(localStorage.getItem('approvalCalculatorsState') || '[]');
    const now = new Date();
    const prunedStorage = storageVal.filter((calc: any) => {
      const calcLastUpdated = new Date(calc.lastUpdated);
      return differenceInDays(calcLastUpdated, now) < this.calculatorStateTTLDays;
    });
    patchState({
      approvalCalculators: prunedStorage
    });
  }

  @Action(ClearApprovalsStore)
  clearApprovalsStore(
    { setState }: StateContext<ApprovalsStateModel>,
    { }: ClearApprovalsStore,
  ):void {
    setState({
      approvals: null,
      approvalsCount: 0,
      adjustedApproval: null,
      approvalCalculators: [],
      loading: false,
    });
  }

  setApprovalCalculatorsStorage(state: ApprovalsStateModel) {
    const storageVal = JSON.stringify(state.approvalCalculators);
    localStorage.setItem('approvalCalculatorsState', storageVal);
  }

  pusherSubscribe(channel: string) {
  }

  pusherUnsubscribe(dealId: number) {
  }

  approvalsSort(a :any, b: any) {
    const nameA = String(a.loanProductLender).toLowerCase();
    const nameB = String(b.loanProductLender).toLowerCase();
    const typeA = String(a.loanProductType).toLowerCase();
    const typeB = String(b.loanProductType).toLowerCase();

    if (nameA > nameB) { return 1;  }
    if (nameA < nameB) { return -1; }
    if (typeA > typeB) { return -1; }
    if (typeA < typeB) { return 1;  }
    return 1;
  }

}
