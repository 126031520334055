import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import {
    ForceInitNotificationStore,
    InitNotificationStore,
    ForceGetNotifications,
    SafeGetNotifications,
    GetNotificationCount,
    ClearNotificationStore,
    GetNotificationAllRead,
    PutAllNotificationsRead,
    PutNotificationRead,
    PutNotificationUnread,
    DeleteAllNotifications,
    DeleteNotification,
    SetNotificationDeleting,
    SetNotificationTogglingRead
} from './notifications.actions';

import { Notification } from '../../interfaces/notification.model';
import { throwError } from 'rxjs';
import { AddSnackbarError } from '@app/app/store/snackbar/snackbar.actions';

export class  NotificationsStateModel {
    notifications: Notification[] | null;
    count: number;
    initialized: boolean;
    allRead: boolean;
    togglingAllRead: boolean;
    deletingAll: boolean;
    togglingRead: object;
    deleting: object;
}

@State<NotificationsStateModel>({
    name: 'notifications',
    defaults: {
        notifications: null,
        count: 0,
        initialized: false,
        allRead: false,
        togglingAllRead: false,
        deletingAll: false,
        togglingRead: {},
        deleting: {}
    }
})
@Injectable()

export class NotificationsState {
    @Selector()
        static notifications(state: NotificationsStateModel) {
        return state.notifications;
    }

    @Selector()
        static count(state: NotificationsStateModel) {
        return state.count;
    }

    @Selector()
        static allRead(state: NotificationsStateModel) {
        return state.allRead;
    }

    @Selector()
        static togglingAllRead(state: NotificationsStateModel) {
        return state.togglingAllRead;
    }

    @Selector()
        static deletingAll(state: NotificationsStateModel) {
        return state.deletingAll;
    }

    @Selector()
        static togglingRead(state: NotificationsStateModel) {
        return state.togglingRead;
    }

    @Selector()
        static deleting(state: NotificationsStateModel) {
        return state.deleting;
    }

    constructor(
        private notificationsService: NotificationService,
        private store: Store
    ) {}

    @Action(SafeGetNotifications)
    safeGetNotifications({ patchState }: StateContext<NotificationsStateModel>, { }: SafeGetNotifications) {
        const sideNavExpanded = localStorage.getItem('notificationSidenavExpanded') === 'true';
        if (sideNavExpanded) {
            return this.notificationsService.getNotifications().pipe(catchError((err: any) => {
                this.store.dispatch( new AddSnackbarError({
                  identifier: 'safeGetNotificationsError',
                  subTitle: 'Unable to retrieve notifications. Please refresh the page to try again.',
                }));
                return throwError(err);
            }), tap((response: any) => {
                patchState({ notifications: response.data });
                this.store.dispatch(new GetNotificationAllRead());
            }));
        }
        return false;
    }

    @Action(ForceGetNotifications)
    forceGetNotifications({ patchState }: StateContext<NotificationsStateModel>, { }: ForceGetNotifications) {
        return this.notificationsService.getNotifications().pipe(catchError((err: any) => {
          this.store.dispatch( new AddSnackbarError({
            identifier: 'forceGetNotificationsError',
            subTitle: 'Unable to retrieve notifications. Please refresh the page to try again.',
          }));
            return throwError(err);
        }), tap((response: any) => {
            patchState({ notifications: response.data });
            this.store.dispatch(new GetNotificationAllRead());
        }));
    }

    @Action(GetNotificationCount)
    getNotificationCount({ patchState }: StateContext<NotificationsStateModel>, { }: GetNotificationCount) {
        return this.notificationsService.getNotificationCount().pipe(catchError((err: any) => {
          this.store.dispatch( new AddSnackbarError({
            identifier: 'getNotificationCountError',
            subTitle: 'Unable to retrieve notification count.',
          }));
            return throwError(err);
        }), tap((response: any) => {
            patchState({ count: response.data });
        }));
    }

    @Action(InitNotificationStore)
    initNotificationStore({ getState, patchState }: StateContext<NotificationsStateModel>, { }: InitNotificationStore) {
        if (!getState().initialized) {
            this.store.dispatch(new SafeGetNotifications());
            this.store.dispatch(new GetNotificationCount());
            patchState({ initialized: true });
        }
    }

    @Action(ForceInitNotificationStore)
    forceInitNotificationStore({ patchState }: StateContext<NotificationsStateModel>, { }: ForceInitNotificationStore) {
        this.store.dispatch(new SafeGetNotifications());
        this.store.dispatch(new GetNotificationCount());
        patchState({ initialized: true });
    }

    @Action(ClearNotificationStore)
    clearNotifications({ patchState }: StateContext<NotificationsStateModel>, {}: ClearNotificationStore) {
        patchState({ notifications: null, count: 0 });
    }

    @Action(GetNotificationAllRead)
    getNotificationAllRead({ getState, patchState }: StateContext<NotificationsStateModel>, {}: GetNotificationAllRead) {
        const notifications = getState().notifications;
        const allRead = notifications!.filter((notification: any) => !notification.read).length === 0;
        patchState({ allRead });
    }

    @Action(PutAllNotificationsRead)
    putAllNotificationsRead({ getState, patchState }: StateContext<NotificationsStateModel>, {}: PutAllNotificationsRead) {
        patchState({ togglingAllRead: true });
        const oldNotifications = getState().notifications;

        if (getState().allRead) {
            console.error('No activities are unread for [putAllNotificationsRead]');
            return;
        }

        const unreadActivityIds = oldNotifications && oldNotifications
            .filter((notification: any) => !notification.read)
            .map((notification: any) => notification.activityId);

        if (!unreadActivityIds) {
            console.error('No unread activity ids for [putAllNotificationsRead]');
            return;
        }

        return this.notificationsService.putNotificationsRead(unreadActivityIds).pipe(
            catchError((err: any) => {
              this.store.dispatch( new AddSnackbarError({
                identifier: 'putNotificationsReadError',
                subTitle: 'Unable to save notifications as read. Please refresh the page and try again.',
              }));
                return throwError(err);
            }),
            tap(() => {
                const notifications = oldNotifications.map((notification: any) => {
                    const newNotification = Object.assign({}, notification);
                    newNotification.read =  Date.now().toString();
                    return newNotification;
                });
                patchState({ notifications, togglingAllRead: false });
                this.store.dispatch(new GetNotificationAllRead());
            },
            (res: any) => {
                patchState({ togglingAllRead: false });
            }
        ));
    }

    @Action(PutNotificationUnread)
    putNotificationUnread({ getState, patchState }: StateContext<NotificationsStateModel>, { activityId }: PutNotificationUnread) {
        this.store.dispatch(new SetNotificationTogglingRead(activityId, true));
        const oldNotifications = getState().notifications;

        const notifications = oldNotifications?.map((notification: any) => {
            if (notification.activityId === activityId) {
                const newNotification = Object.assign({}, notification);
                newNotification.read =  null;
                return newNotification;
            }
            return notification;
        });

        const activityIds = [ activityId ];

        return this.notificationsService.putNotificationsUnread(activityIds).pipe(
            catchError((err: any) => {
              this.store.dispatch( new AddSnackbarError({
                identifier: 'putNotificationUnreadError',
                subTitle: 'Unable to save notification as unread. Please refresh the page and try again.',
              }));
                return throwError(err);
            }),
            tap(() => {
                patchState({ notifications });
                this.store.dispatch(new SetNotificationTogglingRead(activityId, false));
                this.store.dispatch(new GetNotificationAllRead());
            },
            (res: any) => {
                this.store.dispatch(new SetNotificationTogglingRead(activityId, false));
            })
        );
    }

    @Action(PutNotificationRead)
    putNotificationRead({ getState, patchState }: StateContext<NotificationsStateModel>, { activityId }: PutNotificationRead) {
        this.store.dispatch(new SetNotificationTogglingRead(activityId, true));
        const oldNotifications = getState().notifications;

        const notifications = oldNotifications?.map((notification: any) => {
            if (notification.activityId === activityId) {
                const newNotification = Object.assign({}, notification);
                newNotification.read =  Date.now().toString();
                return newNotification;
            }
            return notification;
        });

        const activityIds = [ activityId ];

        return this.notificationsService.putNotificationsRead(activityIds).pipe(
            catchError((err: any) => {
                this.store.dispatch(new AddSnackbarError({
                  identifier: 'putNotificationReadError',
                  subTitle: 'Unable to save notification as read. Please refresh the page and try again.'
                }));
                return throwError(err);
            }),
            tap(() => {
                patchState({ notifications });
                this.store.dispatch(new SetNotificationTogglingRead(activityId, false));
                this.store.dispatch(new GetNotificationAllRead());
            },
            (res: any) => {
                this.store.dispatch(new SetNotificationTogglingRead(activityId, false));
            })
        );
    }

    @Action(DeleteAllNotifications)
    deleteNotifications({ getState, patchState }: StateContext<NotificationsStateModel>, { }: DeleteAllNotifications) {
        patchState({ deletingAll: true });
        const notifications = getState().notifications;
        const activityIds = notifications && notifications
            .map((notification: any) => notification.activityId);

        return this.notificationsService.deleteNotifications(activityIds!).pipe(
            catchError((err: any) => {
                this.store.dispatch(new AddSnackbarError({
                  identifier: 'deleteNotificationsError',
                  subTitle: 'Unable to delete notifications. Please refresh the page and try again.'
                }));
                return throwError(err);
            }),
            tap(() => {
                patchState({ deletingAll: false, notifications: [] });
            },
            (res: any) => {
                patchState({ deletingAll: false });
            }),
        );
    }

    @Action(DeleteNotification)
    deleteNotification({ getState, patchState }: StateContext<NotificationsStateModel>, { activityId }: DeleteNotification) {
        this.store.dispatch(new SetNotificationDeleting(activityId, true));
        const notifications = getState().notifications;

        const newNotifications = notifications!.filter((notification: any) => notification.activityId !== activityId);

        const activityIds = [ activityId ];

        return this.notificationsService.deleteNotifications(activityIds).pipe(
            catchError((err: any) => {
                this.store.dispatch(new AddSnackbarError({
                  identifier: 'deleteNotificationError',
                  subTitle: 'Unable to delete notification. Please refresh the page and try again.'
                }));
                return throwError(err);
            }),
            tap(() => {
                patchState({ notifications: newNotifications });
                this.store.dispatch(new SetNotificationDeleting(activityId, false));
            },
            (res: any) => {
                this.store.dispatch(new SetNotificationDeleting(activityId, false));
            }
            )
        );
    }

    @Action(SetNotificationDeleting)
    setNotificationDeleting({ getState, patchState }: StateContext<NotificationsStateModel>,
                            { activityId, bool }: SetNotificationDeleting) {
        const oldDeleting = getState().deleting;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (oldDeleting[activityId] === bool) { return; }
        const deleting = { ...oldDeleting };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      deleting[activityId] = bool;
        patchState({ deleting });
    }

    @Action(SetNotificationTogglingRead)
    setNotificationTogglingRead({ getState, patchState }: StateContext<NotificationsStateModel>,
                                { activityId, bool }: SetNotificationTogglingRead) {
        const oldTogglingRead = getState().togglingRead;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (oldTogglingRead[activityId] === bool) { return; }
        const togglingRead = { ...oldTogglingRead };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      togglingRead[activityId] = bool;
        patchState({ togglingRead });
    }
}
