import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationService } from 'src/app/services/notification.service';

import { NotificationIconComponent } from './icon/icon/notification-icon.component';
import { IconSmartComponent } from './icon/icon-smart/icon-smart.component';
import { SidenavComponent } from './sidenav/sidenav/sidenav.component';
import { SidenavSmartComponent } from './sidenav/sidenav-smart/sidenav-smart.component';
import { ItemComponent } from './item/item/item.component';
import { ItemSmartComponent } from './item/item-smart/item-smart.component';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import {LoadersModule} from "@app/app/components/loaders/loaders.module";
import { SnackbarManagerComponent } from '@app/app/components/notifications/snackbar/snackbar-manager.component';
import { SnackbarCardComponent } from '@app/app/components/notifications/snackbar/snackbar-card.component';
import { MatCardModule } from '@angular/material/card';
import { LetDirectiveModule } from '@app/app/directives/let/let.directive.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    NotificationIconComponent,
    IconSmartComponent,
    SidenavComponent,
    SidenavSmartComponent,
    ItemComponent,
    ItemSmartComponent,
    SnackbarCardComponent,
    SnackbarManagerComponent,
  ],
    imports: [
        CommonModule,
        MatIconModule,
        LoadersModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatCardModule,
        LetDirectiveModule,
        MatProgressBarModule,
    ],
  exports: [
    IconSmartComponent,
    SidenavComponent,
    SidenavSmartComponent,
    ItemComponent,
    ItemSmartComponent,
    SnackbarCardComponent,
    SnackbarManagerComponent,
  ],
  providers: [
    NotificationService
  ]
})
export class NotificationsModule { }
