import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ClearAuthAlert, GetAuthUser, Logout, SetAuthAlert} from '@app/app/store/auth/auth.actions';
import {Store} from '@ngxs/store';
import {catchError, of, switchMap, take, tap} from 'rxjs';
import {AuthState} from '@app/app/store/auth/auth.state';
import {
  LendioSnackbarAction,
  LendioSnackBarOptions,
  LendioSnackBarPrefix
} from '@app/app/components/lendio-angular-material-theme/lendio-snackbar/lendio-snackbar-options';
import {MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {LendioSnackbarService} from '@app/app/services/lendio-snackbar.service';
import {ForgotPasswordDialogComponent} from '../forgot-password-dialog/forgot-password-dialog.component';
import {Alert} from '@app/app/interfaces/alert.model';
import { AuthService, IdentityServiceResponse } from '../auth.service';
import { Router } from '@angular/router';
import { LoadingService } from '@app/app/services/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  firstVisit = true;
  loading = false;
  showPassword = false;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private _snackbarService: LendioSnackbarService,
    private auth: AuthService,
    private router: Router,
    private loadingService: LoadingService,
  ) {
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [
          Validators.email,
          Validators.required,
        ],
        updateOn: 'change'
      }),
      password: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(6)
        ],
        updateOn: 'change'
      }),
    });
    const loginAlert = localStorage.getItem('LoginAlert');
    if (loginAlert) {
      this.store.dispatch(new SetAuthAlert({
        level: 'error',
        message: loginAlert,
      }));
      localStorage.removeItem('LoginAlert');
    }
  }

  ngAfterViewInit(): void {
    const authenticated = this.store.selectSnapshot(AuthState.user);
    if (!authenticated && this.firstVisit) {
      this.displayNotice();
      this.firstVisit = false;
    }
  }

  public displayNotice(
    action?: LendioSnackbarAction,
    actionLabel?: string,
    canDismiss?: boolean,
    duration?: number,
    horizontalPosition?: MatSnackBarHorizontalPosition,
    message?: string,
    prefix?: LendioSnackBarPrefix,
    title?: string,
    verticalPosition?: MatSnackBarVerticalPosition
  ) {
    if (!message) {
      message = 'Please login to continue.';
    }
    if (!duration && !canDismiss) {
      duration = 3000;
    }
    if (!horizontalPosition) {
      horizontalPosition = 'end';
    }
    if (!verticalPosition) {
      verticalPosition = 'bottom'
    }
    const snackbarOptions = {
      action,
      actionLabel,
      canDismiss,
      duration,
      horizontalPosition,
      message,
      prefix,
      title,
      verticalPosition
    } as LendioSnackBarOptions;
    this._snackbarService.open(snackbarOptions);
  }

  login(event?: KeyboardEvent) {
    if (event) {
      event.preventDefault();
    }
    if (!this.canSubmit()) {
      return;
    }
    this.loading = true;
    this.store.dispatch(new ClearAuthAlert());
    this.auth.identityLogin(this.loginForm.value.email, this.loginForm.value.password).pipe(
      take(1),
      switchMap(async (response: IdentityServiceResponse) => this._handleLogin(response)),
      catchError((error) => {
        this.store.dispatch(new Logout());
        this.loading = false;
        let errorMsg = this.buildErrorMessage(error);
        if (['Access to this application has been locked due to inactivity', 'Password expired'].includes(errorMsg)) {
          window.location.href = 'auth/account-locked';
        }
        this.store.dispatch(new SetAuthAlert({
          level: 'error',
          message: errorMsg
        }));
        throw error;
      })).subscribe();
  }

  private _handleLogin(response: IdentityServiceResponse) {
    switch (response.data?.status) {
      case 'mfaRequired':
        return this.auth.mfaChallenge(response.data.transactionId)
          .pipe(take(1))
          .subscribe({
            next: () => {
              return this.router.navigate(['/auth/mfa-verify'], {
                queryParams: {
                  transactionId: response.data.transactionId,
                  mfaSuccessLifespan: response.data.mfaSuccessLifespan,
                }
              });
            },
            error: (error) => {
              this.loading = false;
              const message = error.error?.errors?.find((e: string) => e === 'Max send attempts reached')
                ? 'MFA code verification attempt limit reached. Please try again later.'
                : error.error?.errors?.find((e: string) => e === 'Too many requests')
                  ? 'You may only login once every 30 seconds. Try again in a moment.'
                  : 'An unknown error occurred. Try again in a moment.'
              this.store.dispatch(new SetAuthAlert({
                level: 'error',
                message,
              }));
            }
          });
      case 'success':
        this.loadingService.setLoading(true);
        return this.store.dispatch(new GetAuthUser());
      case 'passwordExpired':
        return this.router.navigate(['/auth/password-expired'], {
          queryParams: {
            transactionId: response.data.transactionId,
          }
        });
      default:
        this.store.dispatch(new Logout());
        this.loading = false;
        this.store.dispatch(new SetAuthAlert({
          level: 'error',
          message: 'Login failed. Please try again.'
        }));
        break;
    }
  }

  private buildErrorMessage(res) {
    if (((Array.isArray(res.error?.errors) && res.error.errors.length > 1) ?? false)) {
      return 'Login failed with multiple errors: ' + (res.error.errors as []).reduce(
        this.concatErrors(),
        '',
      );
    } else if ((typeof res.error?.errors?.[0]?.message === 'string') ?? false) {
      return res.error.errors[0].message;
    } else if ((typeof res.error?.errors?.[0] === 'string') ?? false) {
      return res.error.errors[0];
    } else {
      return 'Unknown login error';
    }
  }

  private concatErrors() {
    return (accumulatedMessage: string, error: { message: string }) => {
      const prefix = accumulatedMessage === '' ? accumulatedMessage : `${accumulatedMessage}, `;
      return `${prefix} ${error.message.replace(/\.$/, '')}`
    };
  }

  canSubmit() {
    return (this.loginForm.valid && !this.loading);
  }

  forgotPasswordDialog(event): void {
    event.preventDefault();
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent);
    dialogRef.afterClosed()
      .subscribe({
        next: result => {
          if (result) {
            const alert: Alert = {
              level: 'success',
              message: 'We\'ve sent you an email with instructions on how to create a new password.',
            };
            this.store.dispatch(new SetAuthAlert(alert));
          }
        }
      });
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
