import { WebhookSubscription } from 'src/app/interfaces/webhooks.model';

export class GetWebhookSubscriptions {
  static readonly type = '[WebhookSubscription] GetWebhookSubscriptions';
  constructor(public isEmbedded: boolean) {}
}

export class GetWebhookTypes {
  static readonly type = '[WebhookSubscription] GetWebhookTypes';
  constructor(public isEmbedded: boolean) {}
}

export class GetWebhookTestSchema {
  static readonly type = '[WebhookSubscription] GetWebhookTestSchema';
  constructor(public isEmbedded: boolean) {}
}

export class CreateWebhookSubscription {
  static readonly type = '[WebhookSubscription] CreateWebhookSubscription';
  constructor(public eventId: number, public url: string, public isEmbedded: boolean) {}
}

export class UpdateWebhookSubscription {
  static readonly type = '[WebhookSubscription] UpdateWebhookSubscription';
  constructor(public id: number, public eventId: number, public url: string, public isEmbedded: boolean) {}
}

export class TestWebhookSubscription {
  static readonly type = '[WebhookSubscription] TestWebhookSubscription';
  constructor(public subscriptionId: number, public payload: any, public isEmbedded: boolean) {}
}

export class DeleteWebhookSubscription {
    static readonly type = '[WebhookSubscription] DeleteWebhookSubscription';
    constructor(public subscription: WebhookSubscription, public isEmbedded: boolean) { }
}

export class ResetWebhookTest {
  static readonly type = '[WebhookSubscription] ResetWebhookTest';
  constructor(public isEmbedded: boolean){}
}
