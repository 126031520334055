<div class="lendio-snack-bar" #lendioSnackBar [class.tall-snack]="tallSnack">
    <div *ngIf="prefixType">
        <div class="lendio-snack-bar-prefix"
            [ngClass]="prefixType"
            [ngStyle]="prefixBackgroundColor
            ? {'background-color': prefixBackgroundColor }
            : null">
            <ng-container [ngSwitch]="prefixType">
                <ng-container *ngSwitchCase="'avatar'">
                    <img [src]="prefixImgSrc" alt="">
                </ng-container>
                <ng-container *ngSwitchCase="'image'">
                    <img [src]="prefixImgSrc" alt="">
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                    <span>{{ prefixLabel }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'mat-icon'">
                    <mat-icon fontSet="material-symbols-outlined"
                              [ngStyle]="prefixMatIconColor
                                ? {'color': prefixMatIconColor }
                                : null">
                        {{ prefixMatIconLabel }}
                    </mat-icon>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="lendio-snack-bar-text">
        <div class="lendio-snack-bar-title" *ngIf="title">
            {{ title | sentenceCase}}
        </div>
        <div class="lendio-snack-bar-message">
            {{ message ? message : '' | sentenceCase }}
        </div>
    </div>
    <div class="lendio-snack-bar-actions">
        <button class="lendio-snack-bar-action-button"
                *ngIf="actionLabel"
                (click)="snackBarAction()">
            <span class="action-button-label">{{ actionLabel }}</span>
        </button>
        <button class="lendio-snack-bar-dismiss-button"
                *ngIf="canDismiss"
                (click)="snackBarDismiss()">
            <img src="assets/images/snack-bar-close.png" alt="">
        </button>
    </div>
</div>
