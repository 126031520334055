import { Directive, ElementRef, Input, AfterViewInit, NgZone } from '@angular/core';
import { fromEvent, takeUntil } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appMatchMenuWidth]',
})
export class MatchMenuWidthDirective implements AfterViewInit {
  @Input() trigger: any;

  constructor(private _el: ElementRef, private _ngZone: NgZone) {}

  ngAfterViewInit(): void {
    if (this.trigger) {
      const menuTrigger = this._el.nativeElement;
      const resizeObserver = new ResizeObserver(entries => {
        this._ngZone.run(() => {
          this.trigger.style.width = menuTrigger.offsetWidth + 'px';
        });
      });
      resizeObserver.observe(menuTrigger);
      fromEvent(this._el.nativeElement, 'destroy')
        .pipe(takeUntil(this._ngZone.onStable))
        .subscribe(() => resizeObserver.disconnect());
    }
  }
}
