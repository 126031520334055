import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportsComponent } from "@app/app/components/lender-imports/imports/imports.component";
import { ImportDetailsComponent } from "@app/app/components/lender-imports/import-details/import-details.component";

const routes: Routes = [
  {
    path: '',
    component: ImportsComponent,
    title: 'Administration'
  },
  {
    path: ':id',
    component: ImportDetailsComponent,
    title: 'Import Details'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ImportsRoutingModule {
}
