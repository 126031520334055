import { NgModule } from '@angular/core';
import { CapitalizePipe } from './capitalize.pipe';

@NgModule({
    imports: [],
    exports: [CapitalizePipe],
    declarations: [CapitalizePipe],
    providers: [CapitalizePipe],
})
export class CapitalizePipePipeModule { }
