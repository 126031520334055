<app-alert
	*ngFor="let alert of alerts"
	[level]="alert.level"
	[message]="alert.message"
	[dismissible]="true">
</app-alert>

<div *ngIf="submitting" class="loading text-center" [@fadeIn] [@fadeOut]>
    <h2>Submitting Offer...</h2>
    <app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
</div>

<form [formGroup]="offerForm" [ngClass]="(submitting ? 'hidden' : '')">
    <ng-container *ngIf="useNewTheme; else OldThemeForm">
        <div class="lendio-density-1 flex flex-col gap-8">
            <div class="flex flex-row items-center gap-4">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Facility amount</mat-label>
                    <span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
                    <input matInput type="number" formControlName="amount" required data-cy="amount-input">
                    <mat-error data-cy="amount-required"
                               *ngIf="amountControl.invalid && (amountControl.dirty || amountControl.touched)">
                        Amount is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Advance rate</mat-label>
                    <input type="number" matInput formControlName="advanceRate" required data-cy="advance-rate-input">
                    <span matSuffix class="pr-2">%</span>
                    <mat-error data-cy="advance-rate-required"
                               *ngIf="advanceRateControl.invalid && (advanceRateControl.dirty || advanceRateControl.touched)">
                        Advance rate is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex flex-row items-center gap-4">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Min. purchase fee</mat-label>
                    <input matInput type="number" formControlName="minPurchaseFee" required data-cy="min-purchase-fee-input">
                    <span matSuffix class="pr-2">%</span>
                    <mat-error data-cy="min-purchase-fee-required"
                               *ngIf="minPurchaseFeeControl.invalid && (minPurchaseFeeControl.dirty || minPurchaseFeeControl.touched)">
                        Min purchase fee is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Contract duration</mat-label>
                    <input matInput type="number" formControlName="term" required data-cy="term-input">
                    <span matSuffix>Months</span>
                    <mat-error data-cy="term-required"
                               *ngIf="termControl.invalid && (termControl.dirty || termControl.touched)">
                        Term is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex flex-row items-center gap-4">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Commission</mat-label>
                    <span matPrefix class="pl-2">{{ localeCurrencySymbol }}&nbsp;</span>
                    <input matInput type="number" formControlName="commission" data-cy="commission-input">
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Points</mat-label>
                    <input matInput type="number" formControlName="points" data-cy="points-input">
                </mat-form-field>
            </div>
            <div class="flex flex-row items-center gap-4">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Payment term</mat-label>
                    <mat-select formControlName="paymentTerm" data-cy="payment-term-select">
                        <mat-option *ngFor="let term of paymentTerms; let i = index" [value]="term.value" attr.data-cy="payment-term-{{i}}">{{ term.display }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Date received</mat-label>
                    <input matInput [matDatepicker]="dateReceivedPicker1" formControlName="received" required data-cy="received-input" [max]="today">
                    <mat-datepicker-toggle matSuffix [for]="dateReceivedPicker1"></mat-datepicker-toggle>
                    <mat-datepicker #dateReceivedPicker1></mat-datepicker>
                    <mat-error data-cy="received-required"
                               *ngIf="receivedControl.invalid && (receivedControl.dirty || receivedControl.touched)">
                        Date received is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex flex-row items-center gap-4">
                <mat-form-field appearance="outline" class="width-75">
                    <mat-label>Origination fee</mat-label>
                    <input matInput type="number" formControlName="originationFee" data-cy="origination-fee-input">
                </mat-form-field>
                <mat-form-field appearance="outline" class="width-25">
                    <mat-label>Fee type</mat-label>
                    <mat-select [(value)]="originationFeeType">
                        <mat-option [value]="localeCurrencySymbol">{{ localeCurrencySymbol }}</mat-option>
                        <mat-option [value]="'%'">%</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex flex-row items-center gap-4">
                <mat-form-field appearance="outline" class="additional-terms">
                    <mat-label>Additional terms (optional)</mat-label>
                    <textarea
                        matInput
                        [(ngModel)]="additionalTerms"
                        [ngModelOptions]="{standalone: true}"
                        data-cy="additional-terms-input">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-row justify-between items-center mt-4">
            <span class="mat-body-2"><span class="align-sub">*</span> required fields</span>
            <div>
                <button mat-button class="cancel-new-offer" (click)="close()" class="mr-2">Cancel</button>
                <button mat-flat-button
                        color="primary"
                        class="send-offer"
                        (click)="submit()"
                        data-cy="factoring-offer-submit-btn"
                        [class.spinner]="submitting"
                        [disabled]="offerForm.invalid || submitting">
                    {{ submitting ? '&nbsp;' : 'Add offer' }}
                </button>
            </div>
        </div>
    </ng-container>

    <!-- old theme -->
    <ng-template #OldThemeForm>
        <div class="row row-spacing">
            <div class="col">
                <p class="col-header">Facility Amount ({{ localeCurrencySymbol }})</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
                        <input matInput type="number" formControlName="amount" required data-cy="amount-input">
                        <mat-error data-cy="amount-required" *ngIf="checkError('amount', 'required')">Amount is required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col">
                <p class="col-header">Advance Rate (%)</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <input type="number" matInput formControlName="advanceRate" required data-cy="advance-rate-input">
                        <span matSuffix>%</span>
                        <mat-error data-cy="advance-rate-required" *ngIf="checkError('advanceRate', 'required')">Advance rate is required</mat-error>
                        <mat-error data-cy="advance-rate-max" *ngIf="checkError('advanceRate', 'max')">Advance rate must be 100% or lower</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col">
                <p class="col-header">Min Purchase Fee (%)</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <input matInput type="number" formControlName="minPurchaseFee" required data-cy="min-purchase-fee-input">
                        <span matSuffix>%</span>
                        <mat-error data-cy="min-purchase-fee-required" *ngIf="checkError('minPurchaseFee', 'required')">Min purchase fee is required</mat-error>
                        <mat-error data-cy="min-purchase-fee-max" *ngIf="checkError('minPurchaseFee', 'max')">Min purchase fee must be 100% or lower</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col">
                <p class="col-header">Contract Duration (Mo)</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <input matInput type="number" formControlName="term" required data-cy="term-input">
                        <span matSuffix>Months</span>
                        <mat-error data-cy="term-required" *ngIf="checkError('term', 'required')">Term is required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col">
                <p class="col-header">Commission ({{ localeCurrencySymbol }})</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <span matPrefix>{{ localeCurrencySymbol }}&nbsp;</span>
                        <input matInput type="number" formControlName="commission" data-cy="commission-input">
                    </mat-form-field>
                </div>
            </div>
            <div class="col">
                <p class="col-header">Points</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <input matInput type="number" formControlName="points" data-cy="points-input">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col">
                <p class="col-header">Payment Term</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <mat-select formControlName="paymentTerm" data-cy="payment-term-select">
                            <mat-option *ngFor="let term of paymentTerms; let i = index" [value]="term.value" attr.data-cy="payment-term-{{i}}">{{ term.display }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col">
                <p class="col-header">Date Received</p>
                <div class="form-field-container">
                    <mat-form-field appearance="fill" class="w-full">
                        <input matInput [matDatepicker]="dateReceivedPicker1" formControlName="received" required data-cy="received-input">
                        <mat-datepicker-toggle matSuffix [for]="dateReceivedPicker1"></mat-datepicker-toggle>
                        <mat-datepicker #dateReceivedPicker1></mat-datepicker>
                        <mat-error data-cy="received-required" *ngIf="checkError('received', 'required')">Date received is required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col origination-fee-display">
                <p class="col-header">Origination Fee</p>
                <div class="form-field-container">
                    <mat-form-field class="width-25">
                        <mat-select [(value)]="originationFeeType">
                            <mat-option [value]="localeCurrencySymbol">{{ localeCurrencySymbol }}</mat-option>
                            <mat-option [value]="'%'">%</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="width-75">
                        <input matInput type="number" formControlName="originationFee" data-cy="origination-fee-input">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="col-header">Additional Terms (Optional)</p>
                <mat-form-field appearance="fill" class="additional-terms">
                    <textarea
                        matInput
                        [(ngModel)]="additionalTerms"
                        [ngModelOptions]="{standalone: true}"
                        data-cy="additional-terms-input">
                    </textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col text-left">
                <app-document-upload-smart (documentError)="showAlert($event)"></app-document-upload-smart>
            </div>
            <div class="col text-right">
                <button mat-button class="cancel-new-offer" (click)="close()">Cancel</button>
                <button mat-button class="send-offer" (click)="submit()" data-cy="factoring-offer-submit-btn" [class.spinner]="submitting" [disabled]="offerForm.invalid || submitting">
                    {{ submitting ? '&nbsp;' : 'Send Offer' }}
                </button>
            </div>
        </div>
    </ng-template>
</form>

