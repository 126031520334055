@if (viewReady()) {
    <div class="absolute grid gap-2 right-[32px] bottom-[32px] z-[9001]">
        <app-snackbar-card
            *ngFor="let notification of (temporaryNotifications$ | async); trackBy: track" [notification]="notification">
        </app-snackbar-card>
        <ng-container *ngIf="(permanentNotifications$ | async)?.length">
            <app-snackbar-card
                *ngFor="let permNotification of (permanentNotifications$ | async); trackBy: track" [notification]="permNotification">
            </app-snackbar-card>
        </ng-container>
    </div>
}
