import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';
import { FunnelEventsService } from '@app/app/services/funnel-events.service';

export const funnelDarkLaunchGuard: CanActivateFn = (route, state) => {
  const isE2EUser = inject(Store).selectSnapshot(AuthState.user)?.isE2EUser();

  return !!(inject(FunnelEventsService).funnelEnabled() || isE2EUser);
};
