import { Component, computed, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DocumentsActions as DA } from '@app/app/store/documents/documents.actions';
import { DocumentsState } from '../../../store/documents/documents.state';
import { Document } from '../../../interfaces/document.model';
import { LendioAngularMaterialThemeService } from '../../lendio-angular-material-theme/lendio-angular-material-theme.service';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { ApplicationDetailsState } from '@app/app/store/application-details/application-details.state';
import { BusinessActions } from '@app/app/store/businesses/business.actions';

@Component({
  selector: 'app-documents-smart',
  templateUrl: './documents-smart.component.html',
  styleUrls: ['./documents-smart.component.scss']
})
export class DocumentsSmartComponent implements OnInit {

  // Note: Containing component (ApplicationDetails or BusinessDetails) should
  // dispatch to fetch the documents. We just use selectors to get it from
  // the store.
  documents$: Observable<Document[]> = this.store.select(DocumentsState.documents);

  newThemeEnabled = computed(() => this._themeService.newThemeEnabled());
  @Input() borrowerId?: number;
  @Input() dealId?: number;
  @Input() borrowerLenderId: number;
  @Output() smartDocumentError = new EventEmitter<string>();

  constructor(
    private store: Store,
    private _themeService: LendioAngularMaterialThemeService
  ) { }

  ngOnInit(): void {

    // This looks like a duplicate of code in application-details.component
    // fetchData(), which it is. Both the old and new app details components
    // use this documents-smart comp, but the old doesn't proactively fetch
    // tab data (for counts) until you click a tab, hence, in the old theme,
    // this hasn't happened until now.
    if (this.borrowerId || this.dealId) {
      if (this.borrowerId && !this.dealId) {
        this.store.dispatch(new DA.GetBorrowerDocuments(this.borrowerId, this.borrowerLenderId));
      } else if (this.dealId) {
        const business = this.store.selectSnapshot(BusinessState.business);
        const appDetails = this.store.selectSnapshot(ApplicationDetailsState.applicationDetails);

        if (!business?.id || business.id !== appDetails?.deal?.borrowerId) {
          this.store.dispatch(new DA.ClearDocumentsState());
          if (appDetails?.deal?.borrowerId) {
            this.store.dispatch(new BusinessActions.GetBusiness(appDetails.deal.borrowerId)).subscribe({
              // Get document after we're sure we have a business' lenderId.
              next: (state) => {
                // TODO: remove this while killing EntityState pattern
                const lenderId = state.business?.lenderId;
                this.store.dispatch(new DA.GetDealDocuments(appDetails.deal.id, lenderId))
              }
            });
          }
        }
      } else {
        // Problem. We don't have either ID. This shouldn't be possible.
        this.smartDocumentError.emit("No valid ID available to retrieve documents.");
      }
    }
  }
}
