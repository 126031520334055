import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NewOfferDialogComponent } from '../../new-offer-dialog/new-offer-dialog.component';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { OfferToSubmit } from '../../../../interfaces/offer-to-submit.model';
import { Alert } from 'src/app/interfaces/alert.model';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeOut, fadeIn } from 'ng-animate';
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-factoring-offer-form',
  templateUrl: './factoring-offer-form.component.html',
  styleUrls: ['../offer-form-styles.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, { params: { timing: 0.05 } })
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, { params: { timing: 0.3 } })
      )
    ])
  ]
})
export class FactoringOfferFormComponent implements OnInit, OnChanges, OnDestroy {
  @Output() newFactoringOffer = new EventEmitter<OfferToSubmit>();
  @Input() loading: boolean;
  @Input() localeCurrencySymbol: string;
  @Input() useNewTheme: boolean;
  private _nukeSubs: Subject<void> = new Subject<void>();

  amountSub: Subscription = new Subscription();
  advanceRateSub: Subscription = new Subscription();
  minPurchaseFeeSub: Subscription = new Subscription();
  termSub: Subscription = new Subscription();
  commissionSub: Subscription = new Subscription();
  pointsSub: Subscription = new Subscription();
  originationFeeSub: Subscription = new Subscription();

  today: Date = new Date();

  paymentTerms = [
    { display: 'Net 15', value: 'net 15' },
    { display: 'Net 30', value: 'net 30' },
    { display: 'Net 40', value: 'net 40' },
    { display: 'Net 50', value: 'net 50' },
    { display: 'Net 60', value: 'net 60' },
    { display: 'Net 70', value: 'net 70' },
    { display: 'Net 80', value: 'net 80' },
    { display: 'Net 90', value: 'net 90' },
    { display: 'Net 90+', value: 'net 90+' }
  ];
  additionalTerms: '';
  originationFeeType = '%';
  offer = {
    amount: '',
    advanceRate: '',
    minPurchaseFee: '',
    term: '',
    commission: '',
    points: '',
    paymentTerm: '',
    received: new Date(),
    originationFee: ''
  };
  offerForm: FormGroup;
  alerts: Alert[] = [];
  submitting = false;

  constructor(public dialogRef: MatDialogRef<NewOfferDialogComponent>) { }

  ngOnInit(): void {
    this.offerForm = new FormGroup ({
      amount: new FormControl(this.offer.amount, [
        Validators.required
      ]),
      advanceRate: new FormControl(this.offer.advanceRate, [
        Validators.required,
        Validators.max(100)
      ]),
      minPurchaseFee: new FormControl(this.offer.minPurchaseFee, [
        Validators.required,
        Validators.max(100)
      ]),
      term: new FormControl(this.offer.term, [
        Validators.required
      ]),
      commission: new FormControl(this.offer.commission),
      points: new FormControl(this.offer.points),
      paymentTerm: new FormControl(this.offer.paymentTerm),
      received: new FormControl(this.offer.received, [
        Validators.required
      ]),
      originationFee: new FormControl(this.offer.originationFee),
    });
    this.amountSub = this.amountControl.valueChanges
      .pipe(takeUntil(this._nukeSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.amountControl));

    this.advanceRateSub = this.advanceRateControl.valueChanges
      .pipe(takeUntil(this._nukeSubs))
      .subscribe((value) => this.handleMaxNumberInput(value, this.advanceRateControl));

    this.minPurchaseFeeSub = this.minPurchaseFeeControl.valueChanges
      .pipe(takeUntil(this._nukeSubs))
      .subscribe((value) => this.handleMaxNumberInput(value, this.minPurchaseFeeControl));

    this.termSub = this.termControl.valueChanges
      .pipe(takeUntil(this._nukeSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.termControl));

    this.commissionSub = this.commissionControl.valueChanges
      .pipe(takeUntil(this._nukeSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.commissionControl));

    this.pointsSub = this.pointsControl.valueChanges
      .pipe(takeUntil(this._nukeSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.pointsControl));

    this.originationFeeSub = this.originationFeeControl.valueChanges
      .pipe(takeUntil(this._nukeSubs))
      .subscribe((value) => this.handleNegativeInput(value, this.originationFeeControl));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === false
      && changes.loading.currentValue === true
    ) {
      this.submitting = true;
    }
    if (
      changes.hasOwnProperty('loading')
      && changes.loading.previousValue === true
      && changes.loading.currentValue === false
    ) {
      setTimeout(() => this.close(), 0);
    }
  }

  ngOnDestroy(): void {
    this._nukeSubs.next();
    this._nukeSubs.complete();
  }

  get amountControl(): AbstractControl {
    return this.offerForm.get('amount')!;
  }

  get advanceRateControl(): AbstractControl {
    return this.offerForm.get('advanceRate')!;
  }

  get minPurchaseFeeControl(): AbstractControl {
    return this.offerForm.get('minPurchaseFee')!;
  }

  get termControl(): AbstractControl {
    return this.offerForm.get('term')!;
  }

  get commissionControl(): AbstractControl {
    return this.offerForm.get('commission')!;
  }

  get pointsControl(): AbstractControl {
    return this.offerForm.get('points')!;
  }

  get receivedControl(): AbstractControl {
    return this.offerForm.get('received')!;
  }

  get originationFeeControl(): AbstractControl {
    return this.offerForm.get('originationFee')!;
  }

  handleNegativeInput(value: number, control: AbstractControl): void {
    if (value < 0) {
      control.setValue(0);
    }
  }

  handleMaxNumberInput(value: number, control: AbstractControl): void {
    if (value > 100) {
      control.setValue(100);
    }
    if (value < 0) {
      control.setValue(0);
    }
  }


  submit() {
    this.newFactoringOffer.emit({
      formData: this.offerForm.value,
      additionalTerms: this.additionalTerms,
      originationFeeType: this.originationFeeType,
      loanProductCategory: 'factoring'
    });
  }

  close() {
    this.dialogRef.close();
  }

  checkError(controlName: string, errorName: string) {
    return this.offerForm.controls[controlName].hasError(errorName);
  }

  showAlert(alert: { level: string, message: string }) {
    this.alerts.push(alert as Alert);
  }
}
