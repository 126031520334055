import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostBinding,
  ViewChild, computed,
} from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import { CurrencyPipe } from '@angular/common';
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";

@Component({
  selector: 'app-restricted-slider',
  templateUrl: './restricted-slider.component.html',
  styleUrls: ['./restricted-slider.component.scss']
})
export class RestrictedSliderComponent implements OnInit, OnChanges {
  @Input() availableMax: number;
  @Input() availableMin: number;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() value: number;
  @Input() strictSteps = true;
  @Input() tickInterval: number;
  @Input() updateContinuously = true;

  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() currency: boolean;
  @Input() showCurrencyDecimals = true;
  @Input() showValues: boolean;
  @Input() thumbLabel: boolean;
  @Input() customThumbLabel: string;

  @ViewChild('sliderEl') sliderEl: MatSlider;

  @Output() valueChange = new EventEmitter<number>();

  @HostBinding('style.--slider-thumb-label-offset') get sliderThumbLabelOffset() {
    let offset = 29.5;
    if(this.sliderEl && this.customThumbLabel) {
      const thumbLabelEl = this.sliderEl._elementRef.nativeElement.querySelectorAll('.mat-slider-thumb-label')[0];
      offset = thumbLabelEl ?
        Math.floor(thumbLabelEl.clientWidth / 2) + 2.5
        : offset;
    }
    return `-${offset}px`;
  }

  roundedMin: number;
  width: number;
  minRestriction: number;
  maxRestriction: number;
  thumbLabelOffset: number;
  minLabel: string | null;
  maxLabel: string | null;

  oldThemeEnabled = computed(() => this._themeService.oldThemeEnabled());

  constructor(private currencyPipe: CurrencyPipe, private _themeService: LendioAngularMaterialThemeService) {}

  ngOnInit() {
    this.width = 100;
    this.setParams();
    this.setRoundedMin();
    this.formatMinMax();
  }

  roundHalfEven(num: any) {
    const decCheck = num - Math.floor(num);
    if (decCheck === .5) {
      return (2 * Math.round(num / 2));
    } else {
      return Math.round(num);
    }
  }

  setParams() {
    const min = this.availableMin || this.min;
    const max = this.availableMax || this.max;
    this.minRestriction = min <= this.min ?
      0 :
      (min / this.max) * 100;
    this.maxRestriction = max >= this.max ?
      0 :
      ((this.max - max) / this.max) * 100;

    this.width = 100 - (this.minRestriction + this.maxRestriction);
    // set available min and max conditionally so they can be used as the confining params of the slider
    this.availableMin = min;
    this.availableMax = max;
  }

  setRoundedMin() {
    if (this.strictSteps) {
      const min = this.availableMin || this.min;
      const multiplier = 100;
      const remainder = ((min * multiplier) % (this.step * multiplier)) / multiplier;
      const outOfStep = this.step > 0 && remainder > 0;
      if (outOfStep) {
        this.roundedMin = ((min * multiplier) - (remainder * multiplier)) / multiplier;
      } else {
        this.roundedMin = min;
      }
    }
  }

  emitChange($event: any) {
    const value = $event.value;
    if (value < this.min) {
      this.valueChange.emit(this.min);
      this.value = this.min;
      return;
    }

    this.valueChange.emit(value);
  }

  ngOnChanges(changes: any) {
    this.formatMinMax();
    const availableChange = changes && changes.available && changes.available.currentValue;
    if (availableChange !== undefined && this.value > availableChange) {
      this.value = availableChange;
      this.valueChange.emit(this.value); // Emit change when available change changes the value
    }

    const minChange = changes && changes.min && changes.min.currentValue;
    const maxChange = changes && changes.max && changes.max.currentValue;
    const availableLimitChange = (changes && changes.availableMin && changes.availableMin.currentValue) ||
      (changes && changes.availableMax && changes.availableMax.currentValue);
    if (minChange || maxChange || availableLimitChange) {
      if (this.value < this.min) {
        this.value = this.min;
        this.valueChange.emit(this.value);
      }
      this.setRoundedMin();
      this.setParams();
    }
  }

  formatMinMax() {
    const decimalFormat = this.showCurrencyDecimals ? '1.2-2' : '1.0-0';
    const min = this.min || 0;
    const max = this.max || 0;
    this.minLabel = this.currency ? this.currencyPipe.transform(min, 'USD', 'symbol', decimalFormat) :
      min.toString();
    this.maxLabel = this.currency ? this.currencyPipe.transform(max, 'USD', 'symbol', decimalFormat) :
      max.toString();
  }

  thumbLabelDisplay = (value: number) => {
    return this.customThumbLabel || value;
  };

}
