import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthState } from '../store/auth/auth.state';
import { Store } from '@ngxs/store';

export const authGuard: CanActivateFn = (_, state) => {
  const store = inject(Store);
  if (store.selectSnapshot(AuthState.user)) {
    return true;
  }
  sessionStorage.setItem('redirect', state.url);
  return inject(Router).parseUrl('/auth/login');
};
