import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { RestrictedRadioComponent } from './restricted-radio.component';


@NgModule({
  declarations: [ RestrictedRadioComponent ],
  imports: [
    FormsModule,
    CommonModule,
    MatRadioModule,
  ],
  exports: [
    RestrictedRadioComponent,
  ]
})
export class RestrictedRadioModule { }
