import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule, Routes } from '@angular/router';
import { LoanProductService } from '../../services/loan-product.service';
import { LoadersModule } from '../loaders/loaders.module';
import { LoanProductComponent } from './loan-product.component';
import { LoanProductTitleComponent } from './loan-product-title/loan-product-title.component';
import { AddBaseQualifierComponent } from './add-base-qualifier/add-base-qualifier.component';
import { QualifierComponent } from './qualifier/qualifier.component';
import { RequiredInformationComponent } from './required-information/required-information.component';
import { RestrictionNoteComponent } from './restriction-note/restriction-note.component';
import { AddQuestionDialogComponent } from './add-question-dialog/add-question-dialog.component';
import { QualifierExceptionGroupsComponent } from './qualifier-exception-groups/qualifier-exception-groups.component';
import { DeleteGroupDialogComponent } from './delete-group-dialog/delete-group-dialog.component';
import { DeleteAllQualifiersDialogComponent } from './delete-all-qualifiers-dialog/delete-all-qualifiers-dialog.component';
import { ConfirmCreditBoxRulesDialogComponent } from './confirm-credit-box-rules-dialog/confirm-credit-box-rules-dialog.component';
import { BaseQualificationReqWarningComponent } from './base-qualification-req-warning/base-qualification-req-warning.component';

const routes: Routes = [
  {
    path: ':id',
    component: LoanProductComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    LoanProductComponent,
    LoanProductTitleComponent,
    AddBaseQualifierComponent,
    QualifierComponent,
    RequiredInformationComponent,
    RestrictionNoteComponent,
    AddQuestionDialogComponent,
    QualifierExceptionGroupsComponent,
    DeleteGroupDialogComponent,
    DeleteAllQualifiersDialogComponent,
    ConfirmCreditBoxRulesDialogComponent,
    BaseQualificationReqWarningComponent
  ], imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatCardModule,
    LoadersModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
    MatSlideToggleModule,
    LoadersModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ],
  providers: [
    LoanProductService,
  ]
})
export class LoanProductModule {}
