<app-notification-item
    [notification]="notification"
    [deleting]="
        (deleting$ | async)[notification.activityId] || 
        (deletingAll$ | async)
    "
    [togglingRead]="
        (togglingRead$ | async)[notification.activityId] || 
        (togglingAllRead$ | async)
    "
    (toggleRead)="toggleRead($event)"
    (delete)="delete()"
></app-notification-item>