import { CustomizationRequest, _CustomizationRequest } from 'src/app/interfaces/embedded-customization.model'

export class ClearIntelligentStore {
	static readonly type = '[Intelligent] ClearIntelligentStore';
	constructor() {}
}

export class GetCustomizations {
	static readonly type = '[Intelligent] GetIntelligentCustomizations';
	constructor(public affiliateId: number, public type?: string) {}
}

export class GetCustomization {
	static readonly type = '[Intelligent] GetIntelligentCustomization';
	constructor(public affiliateId: number, public customizationId: number) {}
}

export class CreateCustomization {
	static readonly type = '[Intelligent] CreateIntelligentCustomization';
	constructor(public affiliateId: number, public customizationRequest: CustomizationRequest) {}
}

export class UpdateCustomization {
	static readonly type = '[Intelligent] UpdateIntelligentCustomization';
	constructor(public affiliateId: number, public customizationId: string | number, public customizationRequest: CustomizationRequest) {}
}

export class IntelligentLoaded {
	static readonly type = '[Intelligent] IntelligentLoaded';
	constructor(public bool: boolean) {}
}
