export class SafeGetNotifications {
    static readonly type = '[Notification] SafeGetNotifications';
    constructor() {}
}

export class ForceGetNotifications {
    static readonly type = '[Notification] ForceGetNotifications';
    constructor() {}
}

export class GetNotificationCount {
    static readonly type = '[Notification] GetNotificationCount';
    constructor() {}
}

export class InitNotificationStore {
    static readonly type = '[Notification] InitNotificationStore';
    constructor() {}
}

export class ForceInitNotificationStore {
    static readonly type = '[Notification] ForceInitNotificationStore';
    constructor() {}
}

export class ClearNotificationStore {
    static readonly type = '[Notification] ClearNotificationStore';
    constructor() {}
}

export class GetNotificationAllRead {
    static readonly type = '[Notification] GetNotificationAllRead';
    constructor() {}
}

export class PutAllNotificationsRead {
    static readonly type = '[Notification] PutAllNotificationsRead';
    constructor() {}
}

export class DeleteAllNotifications {
    static readonly type = '[Notification] DeleteAllNotifications';
    constructor() {}
}

export class PutNotificationRead {
    static readonly type = '[Notification] PutNotificationRead';
    constructor(public activityId: number) {}
}

export class PutNotificationUnread {
    static readonly type = '[Notification] PutNotificationUnread';
    constructor(public activityId: number) {}
}

export class DeleteNotification {
    static readonly type = '[Notification] DeleteNotification';
    constructor(public activityId: number) {}
}

export class SetNotificationDeleting {
    static readonly type = '[Notification] SetNotificationDeleting';
    constructor(public activityId: number, public bool: boolean) {}
}

export class SetNotificationTogglingRead {
    static readonly type = '[Notification] SetNotificationTogglingRead';
    constructor(public activityId: number, public bool: boolean) {}
}
