<ng-container>
  <h2 mat-dialog-title>{{overrideDialogTitle}}</h2>
  <mat-dialog-content class="!pt-0">
    <ng-container [ngSwitch]="currentOverrideScreen">
      <ng-container *ngSwitchCase="'overrideList'">
        <app-qualifier-values-table class="px-4 pt-2 pb-4"
          [qualifierValues]="rejectedQualifierValues" 
          [overrideColumn]="true"
          (overrideQualifier)="overrideQualifier($event)">
        </app-qualifier-values-table>
      </ng-container>
      <ng-container *ngSwitchCase="'overrideQualifier'">
        <div class="grid grid-flow-col justify-between border-b border-solid border-gray-300 py-2">
          <strong>Required</strong>
          <div class="ml-8">{{ currentOverrideQualifierValue.requirementLabel }}</div>
        </div>
        <ng-container *ngIf="!currentOverrideQualifierValue?.override?.oldBvValue else editOverride">
          <div class="grid grid-flow-col justify-between border-b border-solid border-gray-300 py-2">
            <strong>Actual</strong>
            <div class="grid grid-flow-col items-center grid-cols-[auto_auto_1fr]">
              <div class="leading-none grid grid-flow-col items-center gap-2" [matTooltip]="currentOverrideQualifierValue.tooltip">
                {{ currentOverrideQualifierValue.actualValue }}
                <mat-icon *ngIf="currentOverrideQualifierValue.tooltip && currentOverrideQualifierValue.actualValue === 'Missing'" class="material-symbols-outlined">info</mat-icon>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #editOverride>
          <div class="grid grid-flow-col justify-between border-b border-solid border-gray-300 py-2">
            <strong>Original</strong>
            <div class="grid grid-flow-col items-center grid-cols-[auto_auto_1fr]">
              <div>{{ currentOverrideQualifierValue.override.oldBvValue }}</div>
            </div>
          </div>
          <div class="grid grid-flow-col justify-between border-b border-solid border-gray-300 py-2">
            <strong>Current override</strong>
            <div class="grid grid-flow-col items-center grid-cols-[auto_auto_1fr]">
              <div class="leading-none grid grid-flow-col items-center gap-2" [matTooltip]="currentOverrideQualifierValue.tooltip">
                {{ currentOverrideQualifierValue.actualValue }}
                <mat-icon *ngIf="currentOverrideQualifierValue.tooltip && currentOverrideQualifierValue.actualValue === 'Missing'" class="material-symbols-outlined">info</mat-icon>
              </div>
            </div>
          </div>
        </ng-template>
        <div *ngIf="overridable" class="mt-2">
          <mat-checkbox color="primary" class="mb-2"
            [checked]="provideAlternativeValue" 
            (click)="toggleProvideAlternativeValue()"
            [disabled]="disableAlternativeValueCheckbox"
          >
            <span class="mat-body-1">Provide alternative value</span>
          </mat-checkbox>
          <div *ngIf="provideAlternativeValue" class="pb-4">
            <!-- FIELD TYPE: {{ attributesMap[currentOverrideQualifierValue.qualifierField].type }} -->
            <ng-container [ngSwitch]="attributesMap[currentOverrideQualifierValue.qualifierField].type">
              <mat-form-field *ngSwitchCase="'date-input'" appearance="outline" class="w-full">
                <mat-label>{{ currentOverrideQualifierValue.qualifierLabel }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="alternativeValueDatePicker"
                  placeholder="Choose a date"
                  mask="M0/d0/0000"
                  [leadZeroDateTime]="true"
                  [value]="alternativeValueDate.value"
                  [(ngModel)]="alternativeValueDate.value">
                <mat-datepicker-toggle matSuffix [for]="alternativeValueDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #alternativeValueDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field *ngSwitchCase="'select'" appearance="outline" class="w-full">
                <mat-label>{{ currentOverrideQualifierValue.qualifierLabel }}</mat-label>
                <mat-select required [value]="alternativeValue" [(ngModel)]="alternativeValue">
                    <mat-option *ngFor="let option of attributesMap[currentOverrideQualifierValue.qualifierField].options" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngSwitchCase="'multiselect'" appearance="outline" class="w-full">
                <mat-label>{{ currentOverrideQualifierValue.qualifierLabel }}</mat-label>
                <mat-select required [value]="alternativeValue" [multiple]="true" [(ngModel)]="alternativeValue">
                    <mat-option *ngFor="let option of attributesMap[currentOverrideQualifierValue.qualifierField].options" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngSwitchDefault appearance="outline" class="w-full">
                  <input matInput
                    [(ngModel)]="alternativeValue"
                    id="alternativeValue"
                    [type]="(attributesMap[currentOverrideQualifierValue.qualifierField].type == 'number' || attributesMap[currentOverrideQualifierValue.qualifierField].type == 'currency') ? 'number' : 'text'">
                  <mat-hint>Add new value to replace the original value above.</mat-hint>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
        <div>
          <div class="pb-4">Please provide an override reason to continue</div>
          <mat-form-field appearance="outline" class="w-full">
            <input matInput
              [(ngModel)]="overrideReason"
              id="overrideReason"
              type="text">
            <mat-hint>Help others understand why this qualifier is being overridden.</mat-hint>
          </mat-form-field>
          <div class="py-4" *ngIf="overridable else exclusion">
            By overriding, the new value you provide will be used when decisioning runs again.<br/>
            This change will be recorded, and the qualifier status will be updated accordingly.<br/>
            You can revert this change if necessary.
          </div>
          <ng-template #exclusion>
            <div class="py-4">
              By excluding this value, it will not be considered when decisioning runs again.<br/>
              This change will be recorded.<br/>
              You can revert this change if necessary.
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'cancelOverrides'">
        Are you sure you would like to cancel this override?<br/>
        By cancelling you will lose all progress on current<br/>
        overrides and will have to start over.
      </ng-container>
    </ng-container>
  </mat-dialog-content>
  <div class="grid grid-flow-col px-6 pb-4">
    <ng-container [ngSwitch]="currentOverrideScreen">
      <ng-container *ngSwitchCase="'overrideList'">
        <div *ngIf="(overrideCount === rejectedQualifierValues.length)" class="justify-self-start confirm-override-message mr-3">
          By saving these changes, decisioning will run again to<br/>
          assess qualifications of this business for this product.
        </div>
        <div class="grid grid-flow-col justify-end">
          <button
            mat-button
            [color]="'primary'"
            class="mr-2"
            (click)="cancelOverrides()"
          >
            Cancel overrides
          </button>
          <button
            mat-flat-button
            mat-dialog-close
            [color]="'primary'"
            [disabled]="!(overrideCount === rejectedQualifierValues.length)"
            (click)="saveAndPostOverrides()"
          >
            Save overrides
          </button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'overrideQualifier'">
        <div class="grid grid-flow-col justify-end">
          <button
            mat-button
            [color]="'primary'"
            class="mr-2"
            (click)="toOverridesList()"
          >
            Back
          </button>
          <button
            mat-flat-button
            [color]="'primary'"
            [disabled]="!(overrideReason && ((provideAlternativeValue && (alternativeValue || alternativeValueDate)) || !provideAlternativeValue))"
            (click)="overrideCurrentQualifier()"
          >
            Override current qualifier
          </button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'cancelOverrides'">
        <div class="grid grid-flow-col justify-end">
          <button
            mat-button
            [color]="'primary'"
            class="mr-2"
            (click)="toOverridesList()"
          >
            Back to overrides
          </button>
          <button
            mat-flat-button
            mat-dialog-close
            [color]="'primary'"
            (click)="resetQualifierValues()"
          >
            Cancel overrides
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
