import { EmailSetting } from 'src/app/interfaces/email-setting.model';

export class GetEmailSettings {
    static readonly type = '[Settings] GetEmailSettings';
    constructor() {}
}

export class UpdateEmailSetting {
    static readonly type = '[Settings] UpdateEmailSetting';
    constructor(public setting: EmailSetting) {}
}

export class UpdatePendoInitialized {
    static readonly type = '[Settings] UpdatePendoInitialized';
    constructor(public value: Boolean) {}
}

export class DismissEmailSettingsUpdateStatus {
    static readonly type = '[Settings] DismissEmailSettingsUpdateStatus';
    constructor() {}
}

export class ClearSettingsStore {
    static readonly type = '[Settings] ClearSettingsStore';
    constructor() {}
}
