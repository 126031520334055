import { NgModule } from '@angular/core';
import { DefaultValuePipe } from './default-value.pipe';

@NgModule({
    imports: [],
    exports: [DefaultValuePipe],
    declarations: [DefaultValuePipe],
    providers: [DefaultValuePipe],
})
export class DefaultValuePipeModule { }
