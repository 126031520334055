<div class="font-bold mb-2">
    Apps awaiting response
</div>
<div [class.old-theme-awaiting-response-table]="oldThemeEnabled()">
<mat-card appearance="outlined" class="!px-0 !py-0">
    <div class="flex justify-between items-center">
        <div class="text-6xl leading-none p-4 w-1/4 text-center">
            {{ awaitingResponse ? awaitingResponse.length : 0 }}
        </div>
        <div class="p-4 flex-grow text-gray-700">
            <span *ngIf="awaitingResponse && awaitingResponse.length && awaitingResponse.length < 40">
                Lenders that respond in the first 20 minutes are 5 times as likely to close the deal.
            </span>
            <span *ngIf="awaitingResponse && awaitingResponse.length && awaitingResponse.length >= 40">
                Lenders with more than 40 apps waiting for a response have a lower close rate.
            </span>
            <span *ngIf="awaitingResponse && !awaitingResponse.length">
                Great job! You've responded to all of your applications. Hold tight. We will send&nbsp;you&nbsp;more.
            </span>
        </div>
    </div>
    <div class="border-t border-gray-400 pt-3 h-40 funnel-table">
        <table class="w-full text-sm font-light">
            <ng-template ngFor let-waiting let-i="index" [ngForOf]="awaitingResponse">
                <!-- Only load in most recent 4 -->
                <tr *ngIf="i < 4">
                    <td class="w-2/3 truncate pb-3 px-4" style="max-width: 1px;">
                        <a [attr.href]="'/deal/' + waiting.id" class="leading-tight hover:text-gray-600">
                            {{ waiting.name }}
                        </a>
                    </td>
                    <td class="w-1/3 pb-3 px-4" [attr.title]="waiting.modified">
                        {{ timeAgo(waiting.modified) }}
                    </td>
                </tr>
            </ng-template>
        </table>
    </div>
</mat-card>
</div>
