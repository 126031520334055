import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpBackend } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Import, PaginatedImportsResponse } from '../interfaces/import.model';
import { PaginatedImportDetailsResponse } from '../interfaces/import-details.model';

@Injectable({
    providedIn: 'root'
})
export class ImportsService {

    private baseUrl = environment.apiUrl + '/l/v2/internal';

    constructor(
      private httpClient: HttpClient,
      private handler: HttpBackend,
) {}

    getImports(
      pageIndex: number,
      pageSize: number,
      sortBy: string,
      sortDirection: string,
      filterBy: string): Observable<PaginatedImportsResponse> {
        const params =
        {
          'page': pageIndex + 1,
          'limit': pageSize,
          'sortBy': sortBy,
          'sortDirection': sortDirection,
          'filterBy': filterBy
        };

        return this.httpClient.get<PaginatedImportsResponse>(`${this.baseUrl}/imports`, {params});
      }

    getImportDetails(importId: number): Observable<Import> {
        return this.httpClient.get<Import>(`${this.baseUrl}/imports/${importId}`);
    }

    getImportEntities(
      importId: number,
      pageIndex: number,
      pageSize: number,
      sortBy: string,
      sortDirection: string,
      filterBy: string): Observable<PaginatedImportDetailsResponse> {
        const params =
        {
          'page': pageIndex + 1,
          'limit': pageSize,
          'sortBy': sortBy,
          'sortDirection': sortDirection,
          'filterBy': filterBy
        };
        return this.httpClient.get<PaginatedImportDetailsResponse>(`${this.baseUrl}/imports/${importId}/records`, {params});
    }

    getPresignedUrl(
      dataType: string,
      fileName: string,
      description: string
    ){
      const body =
        {
          'dataType': dataType,
          'fileName': fileName,
          'description': description,
        };
      return this.httpClient.post<any>(`${this.baseUrl}/imports`, body);
    }

    uploadFileToS3(url: string, file: any) {
        const headers = new HttpHeaders({});
        const backendClient = new HttpClient(this.handler);
        const req = new HttpRequest<any>('PUT', url, file, {
          reportProgress: true,
          headers: headers
        });
        return backendClient.request(req);
    }
}
