import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EmailSetting } from 'src/app/interfaces/email-setting.model';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeOut, fadeIn } from 'ng-animate';
import { Observable } from "rxjs";
import { Store } from "@ngxs/store";
import { SettingsState } from "@app/app/store/settings/settings.state";
import { DismissEmailSettingsUpdateStatus, UpdateEmailSetting } from "@app/app/store/settings/settings.actions";
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, { params: { timing: 0.3 }})
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, { params: { timing: 0.3 }})
      )
    ])
  ],
})
export class EmailSettingsComponent implements OnInit, AfterViewInit {

  emailSettings$: Observable<EmailSetting[]> = this._store.select(SettingsState.emailSettings);
  emailSettingsUpdateStatus$: Observable<any> = this._store.select(SettingsState.emailSettingsUpdateStatus);
  timeout: ReturnType<typeof setTimeout>;
  message = '';
  notificationSettingsDataSource: EmailSetting[] = [];
  columns = [
    {
      columnDef: 'label',
      header: 'Type',
      cell: (setting: EmailSetting) => setting
    },
    {
      columnDef: 'children[0]',
      header: 'All',
      cell: (setting: EmailSetting) => `${setting.children ? setting.children[0] : ''}`
    },
    {
      columnDef: 'children[1]',
      header: 'Only Mine',
      cell: (setting: EmailSetting) => `${setting.children?.length > 1 ? setting.children[1] : ''}`
    },
  ]
  displayedColumns = this.columns.map(column => column.columnDef);

  constructor(
    private _snackbarService: LendioSnackbarService,
    private _store: Store,

  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.emailSettings$.subscribe(settings => {
      this.notificationSettingsDataSource = settings;
    });
  }

  onSettingChange(setting: EmailSetting) {
    const settingCopy = { ...setting };
    settingCopy.status = !setting.status;
    this.dispatchSettingChange(settingCopy);
    this.emailSettingsUpdateStatus$.subscribe(e => {
      this.message = e === 'error'
        ? 'There was an error. Please refresh and try again.'
        : 'Successfully updated!';
    });

    this._snackbarService.open({
      message: this.message,
      canDismiss: true,
      duration: 4000
    });
  }

  onSettingChildChange(setting: EmailSetting) {
    if (setting.children) {
      setting.children.forEach((child, i) => {
        const childCopy = { ...child };
        childCopy.status = !childCopy.status;
        this.dispatchSettingChange(childCopy);
      });
    }
  }

  dispatchSettingChange(setting: EmailSetting) {
    this._store.dispatch(new UpdateEmailSetting(setting));
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._store.dispatch(new DismissEmailSettingsUpdateStatus());
    }, 5000);
  }

}
