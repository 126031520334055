import { Component, OnInit, Input, computed } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  GetDealOffers
} from 'src/app/store/offers/offers.actions';
import { OffersState } from 'src/app/store/offers/offers.state';
import { Observable } from 'rxjs';
import { Offer } from 'src/app/interfaces/offer.model';
import { ApplicationDetailsState } from 'src/app/store/application-details/application-details.state';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';
import { take } from 'rxjs/operators';
import { LendioAngularMaterialThemeService } from '../../lendio-angular-material-theme/lendio-angular-material-theme.service';

@Component({
  selector: 'app-offers-smart',
  templateUrl: './offers-smart.component.html',
  styleUrls: ['./offers-smart.component.scss'],
})
export class OffersSmartComponent implements OnInit {
  offers$: Observable<Offer[] | null> = this.store.select(OffersState.offers);
  applicationDetails$: Observable<ApplicationDetails | null> =
    this.store.select(ApplicationDetailsState.applicationDetails);

  newThemeEnabled = computed(() => this._themeService.newThemeEnabled());
  @Input() dealId: number;
  @Input() loanProductCategory: string;
  @Input() tenantId: number;

  sbaLoanNumber: number;

  constructor(
    private store: Store,
    private _themeService: LendioAngularMaterialThemeService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetDealOffers(this.dealId));
    this.applicationDetails$.pipe(take(1)).subscribe((data) => {
      if (data) {
        this.sbaLoanNumber = Number(data.deal.sbaLoanNumber);
      }
    });
  }
}
