import { SnackbarNotification } from '@app/app/interfaces/snackbar-notification.model';

export class AddSnackbarSuccess {
  static readonly type = '[Snackbar] AddSnackbarSuccess';
  constructor(
    public notification: Partial<SnackbarNotification>,
  ) {}
}

export class AddSnackbarError {
  static readonly type = '[Snackbar] AddSnackbarError';
  constructor(
    public error: Partial<SnackbarNotification>,
  ) {}
}

export class AddSnackbarNotification {
  static readonly type = '[Snackbar] AddSnackbarNotification';
  constructor(
    public notification: SnackbarNotification,
  ) {}
}

export class ClearAllSnackbarNotifications {
  static readonly type = '[Snackbar] ClearAllSnackbarNotifications';
  constructor() {}
}

export class ClearSnackbarNotification {
  static readonly type = '[Snackbar] ClearSnackbarNotification';
  constructor(
    public identifier: string,
  ) {}
}

export class ExpireSnackbarNotification {
  static readonly type = '[Snackbar] ExpireSnackbarNotification';
  constructor(
    public identifier: string,
  ) {}
}

export class UpdateSnackbarNotification {
  static readonly type = '[Snackbar] UpdateSnackbarNotification';
  constructor(
    public identifier: string,
    public updates: Partial<SnackbarNotification>,
  ) {}
}
