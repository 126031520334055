import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, take } from 'rxjs';
import { EditContactDialogComponent } from './edit-contact-dialog/edit-contact-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormOptionsService } from '@app/app/services/form-options.service';
import { ContactsState } from '@app/app/store/contacts/contacts.state';
import { Contact } from '@app/app/interfaces/contact.model';
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";
import { BusinessState } from '@app/app/store/businesses/business.state';
import { Business, BusinessAccessLevel } from '@app/app/interfaces/business.model';
import { BorrowerValue } from '@app/app/interfaces/borrower-value.model';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})

/**
 * Business Details - Contacts tab.
 */
export class ContactsComponent {

  // This is the business/borrower ID.
  @Input() id: number;
  borrowerValuePercentOwnership: BorrowerValue | undefined;
  loading$ = this.store.select(ContactsState.loading);
  contacts$: Observable<any> = this.store.select(ContactsState.contacts);
  @Select(BusinessState.business) business$: Observable<Business>;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private _snackbarService: LendioSnackbarService,
    private formOptionsService: FormOptionsService,
  ){
    this.borrowerValuePercentOwnership = this.store.selectSnapshot( BusinessState.borrowerValueByAlias('percentOwnership'));
  }

  // Open the add/edit dialog.
  openEditDialog(contact?: Contact) {
    // Determine the formMode based on existence of a contact ID from server.
    const formMode = contact?.id ? 'Edit' : 'Add';

    const dialogRef = this.dialog.open(EditContactDialogComponent, {
      width: '960px',
      data: { borrowerId: this.id, contact, formMode },
      disableClose: true,
      autoFocus: false
    });

    // Listen to dialog events for create and update success.
    dialogRef.componentInstance.onUpdateSuccess.subscribe(() => {
      // Now get the refreshed business and do other stuff.
      const current = this.store.selectSnapshot(ContactsState.current);
      const message = current
        ? current.first + ' ' + current.last + ' successfully updated.'
        : 'Changes have been saved.';
      // Show snackbar confirming update success.
      this._snackbarService.open({
        message,
        canDismiss: true,
        duration: 3000,
      });
    });

    dialogRef.componentInstance.onCreateSuccess.subscribe(() => {
      // Now get the refreshed business and do other stuff.
      const current = this.store.selectSnapshot(ContactsState.current);
      const message = "New contact has been saved."
      // Show snackbar confirming create success.
      this._snackbarService.open({
        message,
        canDismiss: true,
        duration: 3000,
      });
    });

    dialogRef.componentInstance.onDeleteSuccess.subscribe(() => {
      // Now get the refreshed business and do other stuff.
      const current = this.store.selectSnapshot(ContactsState.current);
      const message = "Contact has been deleted."
      // Show snackbar confirming delete success.
      this._snackbarService.open({
        message,
        canDismiss: true,
        duration: 3000,
      });
    });
  }

  /**
   * Get select option labels (e.g. Utah) from values (e.g. UT).
   *
   * Uses form-options-service.ts for central place for common lists.
   *
   * @param string key - The borrower attribute or other list identifier.
   * @param string value - The value we want to exchange for a label.
   */
  getSelectLabels(key: string, value: string) {
    let list: Array<{label: string, value: string}> = [];
    switch(key) {
      case 'states':
        list = this.formOptionsService.states();
        break;
      case 'yesNo':
        list = this.formOptionsService.yesNo();
        break;
    }

    // Now lookup the label from the value in the list.
    for(const item in list) {
      if (list[item].value == value) {
        return list[item].label;
      }
    }
  }

  isOwner(contact: Contact): boolean {
    return !!this.percentOwnership(contact)
  }

  /**
   * Default value for a primaryContact created through Lead class / 
   * application signup is NULL.  The initial value collected for 
   * percentOwnership is a BorrowerValue.  In the case that a primaryContact
   * has no percentOwnership value, let's default to the BV.
   */
  percentOwnership(contact: Contact): number {
    // Let's respect the possibility of 0 and not default to the borrowerValue.
    if (typeof contact.percentOwnership === 'number') {
      return contact.percentOwnership;
    }

    const primaryContactPercentage = this.borrowerValuePercentOwnership?.value ?? '0';

    return contact.isPrimary ? parseInt(primaryContactPercentage) : 0;
  }
  
  /**
   * Formatted CSZ so we don't do things like have a dangling comma when
   * there's no state, etc.
   */
  getCityStateZip(contact: Contact): string {
    let csz = contact?.city ?? '';
    if (contact?.stateId) {
      // If no city (i.e. csz is empty string), don't add comma.
      csz += (csz ? ', ' : '');
      csz += contact.stateId;
    }
    if (contact?.zipId) {
      // If no city or state, no leading space.
      csz += (csz ? ' ' : '');
      csz += contact.zipId;
    }
    return csz;
  }

  protected _hasModifyAccess(business?: Business) {
    return (
      business?.id == this.id &&
      business?.accessLevel === BusinessAccessLevel.Modify
    );
  }

}
