import { Milestones } from "src/app/interfaces/milestones.model";
import { StateDetails } from "src/app/interfaces/state-details.model";
import { OpportunityDetails } from "src/app/interfaces/opportunity-details.model";

export class MilestonesData {
  clientName: string | null;
  milestones: Milestones;
  stateDetails: StateDetails;

  public constructor(opportunity: OpportunityDetails) {
    if (opportunity) {
      this.clientName = opportunity.borrowerName;
      this.milestones = {
        started: opportunity.firstAttemptedAtTimestamp,
        attempted: opportunity.firstAttemptedAtTimestamp,
        contacted: opportunity.firstContactedAtTimestamp,
        submitted: opportunity.docPrepAtTimestamp,
        appSent: opportunity.applicationCompletedAtTimestamp,
        offerReceived: opportunity.offerReceivedAtTimestamp,
        offerAccepted: opportunity.offerAcceptedAtTimestamp,
        funded: opportunity.dealClosedAtTimestamp
      };
      this.stateDetails = {
        opportunityStatusReason: opportunity.opportunityStatusReason,
        borrowerStage: opportunity.borrowerStage
      };
    }
  }
}
