import { EarlyAccessFeature } from '@app/app/interfaces/saas-feature.model'
export class GetSaasFeatures {
    static readonly type = '[SaasFeatures] GetSaasFeatures';
}

export class ClearSaasFeaturesStore {
    static readonly type = '[SaasFeatures] ClearSaasFeaturesStore';
}
export class UpdateEarlyAccessFeatureEnrollments {
    static readonly type = '[SaasFeatures] UpdateEarlyAccessFeatureEnrollments';
    constructor(
      public features: EarlyAccessFeature[]
    ) {}
  }
