import { Component, OnInit, Input } from '@angular/core';
import * as shape from 'd3-shape';
import { trigger, transition, style, animate } from '@angular/animations';
import { SmallChartData } from '../../../interfaces/small-chart-data.model';
import { LocaleSettingService } from 'src/app/services/locale-setting-service';

@Component({
  selector: 'app-small-line-chart',
  templateUrl: './small-line-chart.component.html',
  styleUrls: ['./small-line-chart.component.scss'],
  animations: [
    trigger(
      'fadeEaseInOut',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.1s ease-out', style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.1s ease-out', style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class SmallLineChartComponent implements OnInit {
  @Input() chartData: SmallChartData;
  @Input() percent: boolean;
  overlay = true;
  locale: string;

  // options
  legend = false;
  showLabels = true;
  animations = true;
  xAxis = false;
  yAxis = false;
  showYAxisLabel = false;
  showXAxisLabel = false;
  xAxisLabel = 'Date';
  yAxisLabel: string;
  yScaleMax: number | undefined;
  yScaleMin: number | undefined = undefined;
  timeline = false;
  curve: any = shape.curveMonotoneX;
  colorScheme = {
    domain: [
      '#bbe6fa',
      '#4299E1',
      '#9F7AEA',
      '#ED8936',
      '#F56565',
      '#276749',
      '#81E6D9',
    ]
  };

  constructor(private localeSettingService: LocaleSettingService) {
    this.locale = this.localeSettingService.getLocale();
  }

  ngOnInit(): void {
    this.yAxisLabel = this.chartData.name;
    this.yScaleMax = this.percent ? 100 : undefined;
  }

}
