import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

/**
 * Generic file input that supports a file-upload button or drag-n-drop
 * for one or multiple files.
 *
 * Use the inputs to customize for the context (e.g. uploading images,
 * documents, etc.). Client code should then handle the selected files,
 * processing them according to the file type, situation, etc.
 */
@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  // This ref is the html input of type file that allows 1+ uploads depending
  // on fileCount input.
  @ViewChild('fileInputRef') fileInputRef: ElementRef;
  @Output() fileInput: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileListInput: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() fileRemoved: EventEmitter<any> = new EventEmitter<any>();
  // Comma separated list of valid file/mime types.
  @Input() requiredFileTypes: Array<string> = ['image/png'];
  // Max size of each file in bytes. Defaults to 50KB.
  @Input() fileSizeLimit: number = 51200;
  // Number of files the user can drop/select/upload at one time. This will
  // determine whether we handle chosen files as File or FileList.
  @Input() fileCount: number = 1;
  // Optional label to treat this like a form input. If not provided, we're
  // probably showing it in a modal.
  @Input() label?: string = '';
  // Whether to show an inline label at the top-left of the input.
  @Input() inline: boolean = true;
  // Text at top of drop area.
  @Input() fileSelectText: string = "Click to choose, or drag file";
  // Text on blue button.
  @Input() buttonText: string = "Select file";

  // Selected files.
  selection?: FileList;

  // By default, users can select/upload one file at a time. Using the
  // fileCount input, client code can specify up to 25. If client code tries
  // to specify more or if a user drops more than this maxFileCount, we'll
  // prevent it and let them know.
  // Note: API enforces max doc uploads of 50 per user per 5 minutes.
  maxFileCount: number = 25;
  errorMessage = '';
  loading: boolean = false;

  constructor() {}

  // React to dropped files.
  handleFileDrop(selection: FileList) {
    this.loading = true;
    this.prepareSelection(selection);
  }

  // React to dialog selected files. Fires once per selected file if multi.
  handleFileBrowse(selection: FileList) {
    this.loading = true;
    this.prepareSelection(selection);
  }

  // This only works for single file inputs. FileList is readonly and we can't
  // "unselect" one item. The UI for removing a selected item should only
  // appear when this.inline is true and this.fileCount is 1.
  removeFile(f: File | undefined | null = null) {
    this.fileInputRef.nativeElement.value = '';
    this.selection = undefined;
    this.loading = false;
    this.fileRemoved.emit();
  }

  prepareSelection(selection: FileList) {
    // Expose this to the template by setting to a public component var.
    this.selection = selection;

    // Check and prepare 1+ files.
    // Enforce file count and max file count.
    Array.from(selection).forEach((f) => {
      if (selection?.length > this.fileCount || selection?.length > this.maxFileCount) {
        this.cycleError('fileCount');
        this.removeFile();
        return;
      }
      if (f.size && f.size > this.fileSizeLimit) {
        this.cycleError('fileSize');
        this.removeFile(f);
        return;
      }
      if (this.requiredFileTypes.indexOf(f.type) < 0) {
        this.cycleError('fileType');
        this.removeFile(f);
        return;
      }
      this.fileInput.emit(f);
    }) ;
  }

  formatBytes(bytes: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + sizes[i];
  }

  formatFileTypes(fileTypes: Array<string> = []): string {
    const extensions = fileTypes.map((ft) => {
      const ext = ft.split('/');
      // Second element should be the extension (e.g. application/pdf = pdf).
      return '.' + ext[1];
    });
    return extensions.join(', ');
  }

  cycleError(errorType: 'fileType' | 'fileSize' | 'fileCount'): void {
    this.loading = false;
    if (errorType === 'fileType') {
      // Make user-friendly display of accepted file extensions.
      this.errorMessage = 'File must be ' + this.formatFileTypes(this.requiredFileTypes);
    } else if (errorType === 'fileSize') {
      this.errorMessage = 'File cannot exceed ' + this.formatBytes(this.fileSizeLimit);
    } else if (errorType === 'fileCount') {
      if (this.fileCount > 1) {
        this.errorMessage = 'You may upload up to ' + this.fileCount + ' files at a time';
      } else {
        this.errorMessage = 'You may upload 1 file at a time';
      }
    } else {
      this.errorMessage = 'There was a problem with the selected file(s)';
    }

    setTimeout(() => {
      this.errorMessage = '';
    }, 5000);
  }
}

